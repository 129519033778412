import React from 'react'
import { Avatar, PlainTextViewer } from '@mondra/ui-components'
import { IClassNameProps } from '@mondra/ui-components/@types'
import classNames from 'classnames'
import { formatDateDistance } from '../../../utils/dateUtils'
import { INoteItem } from '../../../types/types'


interface INotesListItemProps extends IClassNameProps {
    note: INoteItem
    onClick: (note: INoteItem) => void
    selected?: INoteItem
}

export function NotesListItem({ className, note, onClick, selected }: INotesListItemProps) {
    const active = selected?.id === note?.id

    const handleClick = () => {
        onClick && onClick(note)
    }

    return (
        <li>
            <div
                className={classNames(
                    'flex flex-row justify-start items-start',
                    'w-full h-full p-4 gap-x-4 overflow-x-hidden',
                    'hover:bg-gray-100',
                    active ? 'bg-gray-100 cursor-default' : 'bg-white cursor-pointer',
                    className
                )}
                role="button"
                tabIndex={0}
                onClick={handleClick}
            >
                <div className="flex flex-row justify-start items-center gap-x-2">
                    {!note.isThreadRead && <div className="rounded-full w-2 h-2 bg-grade-A" />}
                    <Avatar name={note.userName} className="w-6 h-6" singleChar />
                </div>
                <div className="flex flex-col flex-1 gap-y-1 truncate">
                    <div className="text-base text-gray-900 font-semibold truncate">
                        New note from {note.userName}
                    </div>
                    <PlainTextViewer
                        className="text-sm text-gray-900 mb-1 truncate"
                        rawDataString={note.description}
                    />
                    <div className="text-sm text-gray-400" title={note.threadUpdatedDate}>
                        {formatDateDistance(note.threadUpdatedDate)}
                    </div>
                </div>
            </div>
        </li>
    )
}
