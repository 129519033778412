import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { IProductFilters } from '../types/types'
import { getQueryObject, stringifyQuery } from '../utils/queryString'


export function useFiltersByUrl(defaultFilters?: IProductFilters) {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (!isEmpty(defaultFilters)) {
            setFilters({ ...defaultFilters })
        }
    }, [])

    const filters = useMemo<IProductFilters>(() => getQueryObject(location.search), [location])

    const setFilters = useCallback(
        (filters: IProductFilters) => {
            const search = stringifyQuery(location.search, {
                ...filters,
            })

            history.push({
                pathname: location.pathname,
                search,
            })
        },
        [history, location]
    )

    return {
        filters,
        setFilters,
    }
}
