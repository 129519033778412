const colors = {
    A: {
        bg: 'bg-grade-A',
        desc: 'text-white text-opacity-75',
        text: 'text-white',
    },
    'A+': {
        bg: 'bg-grade-A+',
        desc: 'text-white text-opacity-75',
        text: 'text-white',
    },
    B: {
        bg: 'bg-grade-B',
        desc: 'text-white text-opacity-75',
        text: 'text-white',
    },
    C: {
        bg: 'bg-grade-C',
        desc: 'text-emerald-800',
        text: 'text-emerald-900',
    },
    CertificationExpired: {
        bg: 'bg-pink-900',
        desc: 'text-white text-opacity-85',
        text: 'text-white',
    },
    Certified: {
        bg: 'bg-primary-500',
        desc: 'text-white text-opacity-85',
        text: 'text-white',
    },
    D: {
        bg: 'bg-grade-D',
        desc: 'text-amber-700',
        text: 'text-amber-900',
    },
    Draft: {
        bg: 'bg-gray-500',
        desc: 'text-white text-opacity-85',
        text: 'text-white',
    },
    E: {
        bg: 'bg-grade-E',
        desc: 'text-organe-700',
        text: 'text-orange-900',
    },
    error: {
        bg: 'bg-pink-900',
        desc: 'text-white',
        text: 'text-white text-opacity-85',
    },
    F: {
        bg: 'bg-grade-F',
        desc: 'text-orange-200',
        text: 'text-orange-50',
    },
    G: {
        bg: 'bg-grade-G',
        desc: 'text-red-200',
        text: 'text-red-50',
    },
    noGrade: {
        bg: 'bg-gray-400',
        desc: 'text-white text-opacity-75',
        text: 'text-white',
    },
    none: {
        bg: 'bg-gray-900',
        desc: 'text-white text-opacity-85',
        text: 'text-white',
    },
    requested: {
        bg: 'bg-gray-900',
        desc: 'text-white text-opacity-85',
        text: 'text-white',
    },
} as any

export const getGradeHeaderColor = (grade?: string) => {
    const score = (grade || 'none').replace('-', 'none')
    return colors[score] || colors.none
}

export default colors
