import React, { Fragment, useCallback, useState } from 'react'
import { Button, Input, Label } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { FormRow, FormLoader } from '../../components'
import { SaTypeRadio } from '../../components/SaTypeRadio'
import { SUPPLIED_PRODUCT_LABELS } from '../../constants'
import { IFormChanges, IIngredientCollection, IProduct, SA_TYPE } from '../../types/types'
import { isSuppliedProductType } from '../../utils'
import ProductSlideover from '../product/ProductSlideover'
import ProduceSlideover from '../produce/ProduceSlideover'
import SpecialIngredient from '../special-ingredient/SpecialIngredientSlideover'
import IngredientCollectionItems from './IngredientCollectionItems'
import SwitchIngredientTypeDialog from './SwitchIngredientTypeDialog'


interface IIngredientFormProps {
    companyId: string
    errors: any
    formId: string
    ingredient: IIngredientCollection
    loading?: boolean
    onAddSuppliedProduct: (product: IProduct) => void
    onChange: (changes: IFormChanges) => void
    onSubmit: (event: React.FormEvent) => void
}

export default function IngredientForm({
    companyId,
    errors = {},
    formId,
    ingredient,
    loading = false,
    onAddSuppliedProduct,
    onChange,
    onSubmit,
}: IIngredientFormProps) {
    const [ingredientTypeWarning, setIngredientTypeWarning] = useState('')
    const [slideoverType, setSlideoverType] = useState<SA_TYPE>()

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        onChange({ [name]: value })
    }, [])

    const handleSaType = (saType: SA_TYPE) => {
        if (ingredient?.ingredientCollectionMetadata?.length > 0) {
            setIngredientTypeWarning(saType)
        } else {
            onChange({ saType })
        }
    }

    const handleAddSupplied = useCallback(() => {
        setSlideoverType(ingredient.saType)
    }, [ingredient])

    const onSlideoverClose = useCallback(() => {
        setSlideoverType(undefined)
    }, [])

    const handleCloseIngredientTypeWarning = useCallback(() => {
        setIngredientTypeWarning('')
    }, [])

    const handleConfirmIngredientTypeWarning = () => {
        onChange({ ingredientCollectionMetadata: [], saType: ingredientTypeWarning })
        setIngredientTypeWarning('')
    }

    if (loading || isEmpty(ingredient)) {
        return <FormLoader />
    }

    return (
        <Fragment>
            <form id={formId} className="pt-2" onSubmit={onSubmit} noValidate>
                <FormRow showBottomBorder>
                    <Label
                        className="col-span-1"
                        helpText="e.g.Brown sugar"
                        htmlFor="ingredientName"
                    >
                        Ingredient name
                    </Label>
                    <Input
                        className="col-span-1"
                        id="ingredientName"
                        name="name"
                        required
                        invalid={errors.name ? undefined : false}
                        iconType={errors.name ? 'warningFilled' : undefined}
                        iconClassName="text-red-600"
                        value={ingredient.name}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow className="space-y-4">
                    <Label htmlFor="ingredientType">Ingredient type</Label>
                    <SaTypeRadio
                        id="ingredientType"
                        label="Ingredient collection types"
                        selected={ingredient.saType}
                        onChange={handleSaType}
                    />
                </FormRow>

                <div className="px-4 md:px-6 py-6 bg-gray-50 space-y-1">
                    <h4 className="text-lg text-gray-900 font-medium">Add product suppliers</h4>
                    <p className="text-sm text-gray-500">
                        Add your suppliers and the name of the product they supply to you. If you
                        have multiple suppliers of this ingredient, click add supplied product
                        button below to more suppliers.
                    </p>
                </div>
                <FormRow className="border-b border-gray-200">
                    <IngredientCollectionItems
                        saType={ingredient.saType}
                        ingredients={ingredient.ingredientCollectionMetadata}
                        onChange={onChange}
                    />
                </FormRow>
                <div className="flex items-center justify-end px-4 md:px-6 py-4">
                    <Button variant="secondary" iconType="add" onClick={handleAddSupplied}>
                        Add {SUPPLIED_PRODUCT_LABELS[ingredient.saType].text}
                    </Button>
                </div>
                <SwitchIngredientTypeDialog
                    saType={ingredient.saType}
                    open={!isEmpty(ingredientTypeWarning)}
                    onConfirm={handleConfirmIngredientTypeWarning}
                    onClose={handleCloseIngredientTypeWarning}
                />
            </form>
            <ProductSlideover
                companyId={companyId}
                allowExisting
                disableSaType
                isSuppliedProduct
                saType={ingredient.saType}
                isOpen={isSuppliedProductType(slideoverType)}
                onClose={onSlideoverClose}
                onSaved={onAddSuppliedProduct}
            />
            <ProduceSlideover
                companyId={companyId}
                allowExisting
                isOpen={slideoverType === SA_TYPE.Produce}
                onClose={onSlideoverClose}
                onSaved={onAddSuppliedProduct}
            />
            <SpecialIngredient
                companyId={companyId}
                allowExisting
                isOpen={slideoverType === SA_TYPE.SpecialIngredient}
                onClose={onSlideoverClose}
                onSaved={onAddSuppliedProduct}
            />
        </Fragment>
    )
}
