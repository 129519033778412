import React, { useContext } from 'react'

import { CopyToClipboard, Menu, MoreMenuButton, MenuList, MenuItem } from '../../components'
import ArchiveProduct from '../../components/ArchiveProduct'
import { UserContext } from '../../contexts/UserProvider'
import { isOwner, isMondraAdmin } from '../../hooks/userRoles'
import { ISupplierCellProps } from './SupplierNameCell'


export default function SuppliersActions({ data: supplier, context }: ISupplierCellProps) {
    const { refetch, companyId } = context
    const { currentUser } = useContext(UserContext)
    const canShowArchived = isOwner(currentUser) || isMondraAdmin(currentUser)
    const supplierId = supplier.supplierId

    return (
        <Menu>
            <MoreMenuButton />
            <MenuList style={{ width: 'max-content' }} ariaLabelledby="Supplier action menu">
                {canShowArchived && (
                    <ArchiveProduct
                        apiNode="suppliers"
                        id={supplierId}
                        name={supplier.name}
                        companyId={companyId}
                        isArchived={supplier.isArchived}
                        refresh={refetch}
                    />
                )}
                <MenuItem>
                    <CopyToClipboard infoText="Supplier id copied" text={supplierId}>
                        Copy Supplier id
                    </CopyToClipboard>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
