export const supplyChainVizData: any = {
    data1: {
        product: {
            id: 'd50b399b-69e6-4ab3-9b1d-08d963a2ed3d',
            nodeId: '37d3c5fd-20f8-45d6-8d1c-320229a77cbb',
            parentId: '00000000-0000-0000-0000-000000000000',
            name: 'Burger Bread',
            isSpecialIngredient: false,
            supplierId: null,
            supplierName: null,
            companyName: 'Alauddin Co',
            companyId: '60473540-1a80-42b8-a572-f50955523ab7',
            rootParentId: '00000000-0000-0000-0000-000000000000',
            isProduce: false,
            saState: 'Certified',
            saType: 'Product',
            ecoImpacts: null,
            state: 'certified',
            productDetails: 'complete',
            ingredientDetails: 'incomplete',
            processingDetails: 'complete',
            packagingDetails: 'complete',
            retailDetails: null,
            storageDetails: 'complete',
            ingredientsRequired: true,
            processingRequired: true,
            packagingRequired: true,
            retailRequired: false,
            storageRequired: true,
            quantity: null,
            measureUnit: 'g',
            originCountryCode: 'IND',
            ecoImpactMetascore: '451.940000000000000',
            productMetadata: null,
            site: {
                id: 'aad2dd77-23e8-48bb-7f29-08d963a11e8a',
                name: 'Alan Co India',
                displayId: null,
                siteId: '',
                companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                companyName: 'Alauddin Co',
                addressId: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                address: {
                    id: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                    streetAddress: 'Naraina Industrial Area',
                    city: 'New Delhi',
                    pinCode: null,
                    state: 'Delhi',
                    country: 'India',
                    countryCode: 'IND',
                    countryCodeISOAlpha2: 'IN',
                    countryCodeISOAlpha3: 'IND',
                    countryId: 104,
                    createdBy: null,
                    createdDate: '0001-01-01T00:00:00+00:00',
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00+00:00',
                    isArchived: false,
                    isFullStreetAddress: true,
                    latitude: '28.64616',
                    longitude: '77.14768',
                },
                linkedCount: 1,
                createdBy: 'admin@mondra.com',
                createdDate: '2021-08-20T06:09:50.2910254+00:00',
                updatedBy: 'admin@mondra.com',
                updatedDate: '2021-08-20T06:09:50.2910199+00:00',
                totalProductsProductionInKg: 12.0,
                isArchived: false,
            },
            transport: [
                {
                    id: '0d33534e-592a-424c-72ea-08d97cecb76b',
                    impactId: null,
                    linkedProductId: 'd50b399b-69e6-4ab3-9b1d-08d963a2ed3d',
                    transportProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                    calcId: null,
                    sourceSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                    destinationSiteId: 'aad2dd77-23e8-48bb-7f29-08d963a11e8a',
                    createdBy: 'admin@mondra.com',
                    createdDate: '2021-09-21T10:46:20.353616+00:00',
                    updatedBy: 'admin@mondra.com',
                    updatedDate: '2021-10-12T06:59:14.8420013+00:00',
                    isArchived: false,
                    transportingProduct: true,
                    noTransportReason: '',
                    transportLegs: [
                        {
                            id: 'aff5be4a-8bcc-4b3b-c6f2-08d97ced0c36',
                            transportMode: null,
                            distance: 2500.0,
                            distanceUnit: 'KM',
                            impactId: null,
                            countryId: null,
                            transportActivityId: '0d33534e-592a-424c-72ea-08d97cecb76b',
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-09-21T10:46:20.3536432+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-10-12T06:59:14.8427892+00:00',
                            isArchived: false,
                        },
                    ],
                },
            ],
            suppliers: [],
            produce: [],
        },
        processing: null,
        packaging: null,
        storage: null,
        retail: null,
        ingredients: [
            {
                id: '46264159-729f-4073-8a61-08d963a2b8b4',
                nodeId: '1ccb87a5-9353-484e-bd6d-0043d021bef6',
                parentId: '37d3c5fd-20f8-45d6-8d1c-320229a77cbb',
                name: 'White Bread Collection',
                isSpecialIngredient: false,
                supplierId: null,
                supplierName: null,
                companyName: 'Alauddin Co',
                companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                rootParentId: '1ccb87a5-9353-484e-bd6d-0043d021bef6',
                isProduce: false,
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [
                    {
                        id: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                        nodeId: '626d89ee-3b27-4957-a808-131f2be89772',
                        parentId: '1ccb87a5-9353-484e-bd6d-0043d021bef6',
                        name: 'White Bread',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Alauddin Co',
                        companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                        rootParentId: '626d89ee-3b27-4957-a808-131f2be89772',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: false,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                            name: 'Tom test',
                            displayId: null,
                            siteId: '0001Tom',
                            companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                            companyName: 'Able & Cole Ltd.',
                            addressId: '8bdaf111-3da5-4824-3f21-08d9835608bd',
                            address: {
                                id: '8bdaf111-3da5-4824-3f21-08d9835608bd',
                                streetAddress: 'New Cross',
                                city: 'London',
                                pinCode: null,
                                state: 'ENG',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '51.47357',
                                longitude: '-0.02924',
                            },
                            linkedCount: 1,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-09-29T14:32:57.1663548+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-09-29T14:32:57.1604005+00:00',
                            totalProductsProductionInKg: 0.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                impactId: null,
                                linkedProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                                transportProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                calcId: null,
                                sourceSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                destinationSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-09-21T10:43:58.0357592+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-21T11:15:43.2659666+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: 'Not required',
                                transportLegs: [
                                    {
                                        id: 'e64c661b-97cb-4c7a-e896-08d98d4e0510',
                                        transportMode: null,
                                        distance: 200.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 4,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-10-12T07:00:48.1223284+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.269569+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: '16401049-bfde-46d8-0e5d-08d9b3c7fa9d',
                                        transportMode: null,
                                        distance: 12.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 46,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-11-30T07:48:55.0930925+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.2695739+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: '2fc402a0-6504-4738-8da4-08d9c4733aa3',
                                        transportMode: null,
                                        distance: 3500.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-21T11:15:43.2695752+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.2695746+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                            {
                                id: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                impactId: null,
                                linkedProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                                transportProductId: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-11-30T06:09:33.2247386+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-01T04:29:19.1923301+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: '58cb09ae-d47d-4efa-0e5c-08d9b3c7fa9d',
                                        transportMode: null,
                                        distance: 300.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 235,
                                        transportActivityId: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-11-30T06:09:33.2319109+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-01T04:29:19.1956408+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: 'e14f3bc4-92fd-4884-00ed-08d9b483244b',
                                        transportMode: null,
                                        distance: 10.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-01T04:29:19.1956456+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-01T04:29:19.195645+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                        nodeId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        parentId: '626d89ee-3b27-4957-a808-131f2be89772',
                        name: 'Wheat Flour',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Able & Cole Ltd.',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                            name: 'Alan Co',
                            displayId: null,
                            siteId: null,
                            companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                            companyName: 'Alauddin Co',
                            addressId: '5b36f693-a41c-4230-f85a-08d93ab82268',
                            address: {
                                id: '5b36f693-a41c-4230-f85a-08d93ab82268',
                                streetAddress: '123, Abby Road',
                                city: 'Grimsby',
                                pinCode: 'DN396YW',
                                state: 'Lincolnshire',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: 'admin@mondra.com',
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: false,
                                latitude: null,
                                longitude: null,
                            },
                            linkedCount: 1,
                            createdBy: null,
                            createdDate: '2021-06-29T05:14:48.7408551+00:00',
                            updatedBy: null,
                            updatedDate: '0001-01-01T00:00:00+00:00',
                            totalProductsProductionInKg: 120.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '285ed2e1-0a23-449e-4c08-08d9ba6c4c8c',
                                impactId: null,
                                linkedProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                transportProductId: '9072cd5f-ea31-40e2-2528-08d9613b5764',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-12-08T17:00:55.3120786+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-08T17:00:55.3129492+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: 'a5ac60aa-57f6-41e1-614f-08d9ba6c4c8d',
                                        transportMode: null,
                                        distance: 2113.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 2,
                                        transportActivityId: '285ed2e1-0a23-449e-4c08-08d9ba6c4c8c',
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-08T17:00:55.3129966+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-08T17:00:55.3129977+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                            {
                                id: '84f123a7-7d7a-4a77-4c09-08d9ba6c4c8c',
                                impactId: null,
                                linkedProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                transportProductId: 'ed3bb902-7c76-4330-3301-08d962147fac',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-12-08T17:02:02.2995413+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-08T17:02:02.299564+00:00',
                                isArchived: false,
                                transportingProduct: false,
                                noTransportReason: '',
                                transportLegs: [],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                        nodeId: 'ed8a39f1-7d4d-48f9-b547-9c5219ec3275',
                        parentId: '626d89ee-3b27-4957-a808-131f2be89772',
                        name: 'White Wheat Flour',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Able & Cole Ltd.',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: 'ed8a39f1-7d4d-48f9-b547-9c5219ec3275',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: 'c0c2f011-8c38-4dac-090c-08d9621bf6cb',
                            name: 'All Grains Bakery Warehouse',
                            displayId: null,
                            siteId: 'AGB-12',
                            companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                            companyName: 'All Grains Bakery',
                            addressId: 'db641285-63c9-4782-2566-08d9621bf6cb',
                            address: {
                                id: 'db641285-63c9-4782-2566-08d9621bf6cb',
                                streetAddress: '',
                                city: 'London',
                                pinCode: null,
                                state: 'ENG',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '51.50015',
                                longitude: '-0.12624',
                            },
                            linkedCount: 1,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-08-18T07:44:09.3773019+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-08-18T07:44:09.377293+00:00',
                            totalProductsProductionInKg: 0.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '7bfa26b8-e9b1-429f-a95e-08d9d021a6e0',
                                impactId: null,
                                linkedProductId: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                                transportProductId: '48570acd-2a7c-4631-e49b-08d9621aebe1',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: 'c0c2f011-8c38-4dac-090c-08d9621bf6cb',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2022-01-05T08:02:00.1459849+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2022-01-05T08:02:00.1461911+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: 'db068f4d-9190-4803-6166-08d9d021a6e6',
                                        transportMode: null,
                                        distance: 134253.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '7bfa26b8-e9b1-429f-a95e-08d9d021a6e0',
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2022-01-05T08:02:00.1462216+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2022-01-05T08:02:00.1462226+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                ],
                produce: [
                    {
                        id: 'ed3bb902-7c76-4330-3301-08d962147fac',
                        nodeId: 'bf9c677d-0c5f-4e09-9e97-77da48871e44',
                        parentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        name: 'Fresh Wheat',
                        isSpecialIngredient: false,
                        supplierId: '14632f1a-5d93-46d3-80d6-3c5538782f51',
                        supplierName: 'Grain supplies EU',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '9072cd5f-ea31-40e2-2528-08d9613b5764',
                        nodeId: 'a0d4e88d-a0c2-4d5f-8dc1-985aff79b56b',
                        parentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        name: 'Wheat',
                        isSpecialIngredient: false,
                        supplierId: 'c240270b-7ca7-4889-84d9-9253da1ebe2e',
                        supplierName: 'All Grains Bakery',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        isProduce: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [
                            {
                                code: null,
                                name: null,
                                scientificTerm: null,
                                measureUnit: null,
                                impactScore: 218.517,
                                contributionPercentage: 0.0,
                                stage: null,
                            },
                            {
                                code: null,
                                name: null,
                                scientificTerm: null,
                                measureUnit: null,
                                impactScore: 69.518,
                                contributionPercentage: 0.0,
                                stage: null,
                            },
                            {
                                code: null,
                                name: null,
                                scientificTerm: null,
                                measureUnit: null,
                                impactScore: 0.564,
                                contributionPercentage: 0.0,
                                stage: null,
                            },
                            {
                                code: null,
                                name: null,
                                scientificTerm: null,
                                measureUnit: null,
                                impactScore: 0.012,
                                contributionPercentage: 0.0,
                                stage: null,
                            },
                        ],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'complete',
                        packagingDetails: 'complete',
                        retailDetails: null,
                        storageDetails: 'complete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GB',
                        ecoImpactMetascore: '2886.110000000000000',
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '934d2ba0-2879-41c3-e000-08d963a02fcf',
                        nodeId: 'c3bf9f49-f9ac-41de-89ff-80aa8f4b0990',
                        parentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        name: 'Chicken Breast',
                        isSpecialIngredient: false,
                        supplierId: '3076be01-a1b3-4ce5-819c-94d55d706ca9',
                        supplierName: 'Food engineers Ltd.',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        isProduce: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'IN',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: 'cc3eee09-2d9f-4b1c-dfff-08d963a02fcf',
                        nodeId: '2e4386f8-eda1-4b36-81df-2f156d7adf20',
                        parentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        name: 'Raw Chicken',
                        isSpecialIngredient: false,
                        supplierId: '84959fa9-3f5c-4543-91f8-4e889863ea79',
                        supplierName: 'Cluk Cluk UK',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        isProduce: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '48570acd-2a7c-4631-e49b-08d9621aebe1',
                        nodeId: '7828de1b-3c70-4924-a69f-1aa4d2f130f7',
                        parentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        name: 'Fresh Wheat',
                        isSpecialIngredient: false,
                        supplierId: '9d5ba423-6bde-43e9-a962-8c1b1893f7b2',
                        supplierName: 'Food engineers Ltd. (transferred from Sandwich Co.)',
                        companyName: 'All Grains Bakery',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: '5eb6cbfa-cf9a-49bf-ad61-2d652846eeb2',
                        isProduce: false,
                        canRemove: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GB',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '2e80348d-5013-41ae-e49a-08d9621aebe1',
                        nodeId: '2eeb4bcd-3f15-4f8b-b439-aff049c5400f',
                        parentId: 'ed8a39f1-7d4d-48f9-b547-9c5219ec3275',
                        name: 'Wheat Grain',
                        isSpecialIngredient: false,
                        supplierId: 'b2eecc82-9a1d-412c-a3c4-bbee74126eeb',
                        supplierName: 'Baking supplies Ltd. (transferred from Sandwich Co.)',
                        companyName: 'All Grains Bakery',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: 'ed8a39f1-7d4d-48f9-b547-9c5219ec3275',
                        isProduce: false,
                        canRemove: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GB',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                ],
            },
            {
                id: 'd64b9c4e-254d-4046-6b0b-08d9a02663e7',
                nodeId: '7a7ddefd-1da3-4e83-95de-f930b3c4fb62',
                parentId: '37d3c5fd-20f8-45d6-8d1c-320229a77cbb',
                name: 'Water',
                isSpecialIngredient: true,
                supplierId: null,
                supplierName: null,
                companyName: null,
                companyId: null,
                rootParentId: '7a7ddefd-1da3-4e83-95de-f930b3c4fb62',
                isProduce: false,
                canRemove: false,
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [],
                produce: [],
            },
        ],
    },
    noIngredients: {
        product: {
            productName: 'chicken salad Sandwitch',
            companyName: 'Sandwich Hub Ltd',
            quantity: '160g',
            id: 'product',
        },
        processing: {
            id: 'proceesing',
            title: 'Processing',
        },
        packaging: {
            id: 'packaging',
            title: 'Packaging',
        },
        storage: {
            id: 'storage',
            title: 'Storage',
        },
        retail: {
            id: 'retail',
            title: 'Retail',
        },
        ingredients: [],
    },
    noProduce: {
        product: {
            id: '706e51c9-5c43-4fe7-7a1b-08d96934d395',
            parentId: '00000000-0000-0000-0000-000000000000',
            productName: 'My Own Product',
            supplierName: null,
            companyName: 'Milky Stuff Ltd',
            companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
            rootParentId: '00000000-0000-0000-0000-000000000000',
            saState: 'Draft',
            saType: 'Product',
            ecoImpacts: null,
            state: 'blank',
            productDetails: 'complete',
            ingredientDetails: 'incomplete',
            processingDetails: 'incomplete',
            packagingDetails: null,
            retailDetails: null,
            ingredientsRequired: true,
            processingRequired: true,
            packagingRequired: true,
            retailRequired: true,
            quantity: null,
            measureUnit: 'g',
            originCountryCode: null,
            ecoImpactMetascore: null,
            productMetadata: null,
            site: {
                id: '48df6d25-4ab6-4df1-3347-08d9741e909e',
                name: 'Test 123',
                displayId: null,
                siteId: 'TEST-123',
                companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
                companyName: 'Milky Stuff Ltd',
                addressId: 'cbefb1e7-c884-4cee-6bde-08d9741e90a3',
                address: {
                    id: 'cbefb1e7-c884-4cee-6bde-08d9741e90a3',
                    streetAddress: '',
                    city: 'London',
                    pinCode: null,
                    state: 'Baloda Bazar (Taluk), Baloda Bazar, Chhattisgarh, India',
                    country: 'India',
                    countryCode: 'IN',
                    countryId: 104,
                    createdBy: null,
                    createdDate: '0001-01-01T00:00:00+00:00',
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00+00:00',
                    isArchived: false,
                    isFullStreetAddress: false,
                    latitude: '51.50015',
                    longitude: '-0.12624',
                },
                linkedCount: 0,
                createdBy: 'admin@mondra.com',
                createdDate: '2021-09-10T05:48:07.3150467+00:00',
                updatedBy: 'admin@mondra.com',
                updatedDate: '2021-10-13T07:37:58.8426892+00:00',
                totalProductsProductionInKg: 33.0,
                isArchived: false,
            },
            transport: [],
            suppliers: [],
            produce: [],
        },
        processing: null,
        packaging: null,
        storage: null,
        retail: null,
        ingredients: [
            {
                id: '48de1754-65c3-4d94-10d1-08d960c9a271',
                parentId: '706e51c9-5c43-4fe7-7a1b-08d96934d395',
                productName: 'Grape juice concentrate',
                supplierName: null,
                companyName: 'Milky Stuff Ltd',
                companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
                rootParentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                produce: [
                    {
                        id: 'actuall-produce-placeholder',
                        parentId: '7ec6a226-a9f0-44a4-2523-08d9613b5764',
                        productName: 'Actuall Produce',
                        type: 'produce',
                    },
                ],
                packagingRequired: false,
                retailRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [
                    {
                        id: 'de568e14-fd89-4e5c-2522-08d9613b5764',
                        parentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                        productName: 'Grape juice concentrate',
                        supplierName: 'Unknown',
                        companyName: 'Milky Stuff Ltd',
                        companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
                        rootParentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                        saState: 'Certified',
                        saType: 'Product',
                        ecoImpacts: [
                            {
                                code: 'EIID001',
                                name: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
                                scientificTerm: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
                                measureUnit: 'g',
                                impactScore: 388.497,
                                stage: null,
                            },
                            {
                                code: 'EIID002',
                                name: 'Water Scarcity (eq)',
                                scientificTerm: 'Water Scarcity (eq)',
                                measureUnit: 'L',
                                impactScore: 79.269,
                                stage: null,
                            },
                            {
                                code: 'EIID003',
                                name: 'Eutrophication (PO43-)',
                                scientificTerm: 'Eutrophication (PO43-)',
                                measureUnit: 'g',
                                impactScore: 1.199,
                                stage: null,
                            },
                            {
                                code: 'EIID004',
                                name: 'Biodiversity (sp.yr10<sup>14</sup>)',
                                scientificTerm: 'Biodiversity (sp.yr10<sup>14</sup>)',
                                measureUnit: '',
                                impactScore: 0.123,
                                stage: null,
                            },
                        ],
                        state: 'certified',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'complete',
                        packagingDetails: null,
                        retailDetails: null,
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: true,
                        retailRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: 'A',
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '12368e14-fd89-4e5c-2522-08d9613b5764',
                        parentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                        productName: 'Grape juice 2',
                        supplierName: 'Unknown',
                        companyName: 'Milky Stuff Ltd',
                        companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
                        rootParentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                        saState: 'Certified',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'certified',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'complete',
                        packagingDetails: null,
                        retailDetails: null,
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: true,
                        retailRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: 'A',
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '3c40c55e-8336-4c8f-2524-08d9613b5764',
                        parentId: 'de568e14-fd89-4e5c-2522-08d9613b5764',
                        productName: 'Pea protein isolate',
                        supplierName: 'Unknown',
                        companyName: 'Milky Stuff Ltd',
                        companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
                        rootParentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                        saState: null,
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: null,
                        retailDetails: null,
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '7ec6a226-a9f0-44a4-2523-08d9613b5764',
                        parentId: 'de568e14-fd89-4e5c-2522-08d9613b5764',
                        productName: 'Oat Syrup',
                        supplierName: 'Unknown',
                        companyName: 'Milky Stuff Ltd',
                        companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
                        rootParentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                        saState: null,
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: null,
                        retailDetails: null,
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '4566a226-a9f0-44a4-2523-08d9613b5764',
                        parentId: '12368e14-fd89-4e5c-2522-08d9613b5764',
                        productName: 'Oat Syrup',
                        supplierName: 'Unknown',
                        companyName: 'Milky Stuff Ltd',
                        companyId: '5f055c8a-37b5-4066-bec2-2b0681a87098',
                        rootParentId: '48de1754-65c3-4d94-10d1-08d960c9a271',
                        saState: null,
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: null,
                        retailDetails: null,
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                ],
            },
        ],
    },
    data2: {
        product: {
            id: 'd50b399b-69e6-4ab3-9b1d-08d963a2ed3d',
            nodeId: '06985c05-e447-4cfe-a88d-96b99efff885',
            parentId: '00000000-0000-0000-0000-000000000000',
            name: 'Burger Bread',
            isSpecialIngredient: false,
            supplierId: null,
            supplierName: null,
            companyName: 'Alauddin Co',
            companyId: '60473540-1a80-42b8-a572-f50955523ab7',
            rootParentId: '00000000-0000-0000-0000-000000000000',
            isProduce: false,
            saState: 'Certified',
            saType: 'Product',
            ecoImpacts: [
                {
                    code: 'EIID001',
                    name: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
                    scientificTerm: null,
                    measureUnit: 'g',
                    impactScore: 3885.0,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 3885.0,
                    per100g: 388.5,
                    perServing: 388.5,
                    perProduct: 582.75,
                },
                {
                    code: 'EIID002',
                    name: 'Water Scarcity (eq)',
                    scientificTerm: null,
                    measureUnit: 'L',
                    impactScore: 792.7,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 792.7,
                    per100g: 79.27,
                    perServing: 79.27,
                    perProduct: 118.905,
                },
                {
                    code: 'EIID003',
                    name: 'Eutrophication (PO43-)',
                    scientificTerm: null,
                    measureUnit: 'g',
                    impactScore: 12.0,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 12.0,
                    per100g: 1.2,
                    perServing: 1.2,
                    perProduct: 1.8,
                },
                {
                    code: 'EIID004',
                    name: 'Biodiversity (sp.yr10<sup>14</sup>)',
                    scientificTerm: null,
                    measureUnit: '',
                    impactScore: 1.2,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 1.2,
                    per100g: 0.12,
                    perServing: 0.12,
                    perProduct: 0.18,
                },
            ],
            state: 'certified',
            productDetails: 'complete',
            ingredientDetails: 'incomplete',
            processingDetails: 'complete',
            packagingDetails: 'incomplete',
            retailDetails: null,
            storageDetails: 'incomplete',
            ingredientsRequired: true,
            processingRequired: true,
            packagingRequired: true,
            retailRequired: false,
            storageRequired: true,
            quantity: null,
            measureUnit: 'g',
            originCountryCode: 'IND',
            ecoImpactMetascore: 'A',
            productMetadata: null,
            site: {
                id: 'aad2dd77-23e8-48bb-7f29-08d963a11e8a',
                name: 'Alan Co India',
                displayId: null,
                siteId: '',
                companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                companyName: 'Alauddin Co',
                addressId: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                address: {
                    id: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                    streetAddress: 'Naraina Industrial Area',
                    city: 'New Delhi',
                    pinCode: null,
                    state: 'Delhi',
                    country: 'India',
                    countryCode: 'IND',
                    countryCodeISOAlpha2: 'IN',
                    countryCodeISOAlpha3: 'IND',
                    countryId: 104,
                    createdBy: null,
                    createdDate: '0001-01-01T00:00:00+00:00',
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00+00:00',
                    isArchived: false,
                    isFullStreetAddress: true,
                    latitude: '28.64616',
                    longitude: '77.14768',
                },
                linkedCount: 1,
                createdBy: 'admin@mondra.com',
                createdDate: '2021-08-20T06:09:50.2910254+00:00',
                updatedBy: 'admin@mondra.com',
                updatedDate: '2021-08-20T06:09:50.2910199+00:00',
                totalProductsProductionInKg: 12.0,
                isArchived: false,
            },
            transport: [
                {
                    id: '0d33534e-592a-424c-72ea-08d97cecb76b',
                    impactId: null,
                    linkedProductId: 'd50b399b-69e6-4ab3-9b1d-08d963a2ed3d',
                    transportProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                    calcId: null,
                    sourceSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                    destinationSiteId: 'aad2dd77-23e8-48bb-7f29-08d963a11e8a',
                    createdBy: 'admin@mondra.com',
                    createdDate: '2021-09-21T10:46:20.353616+00:00',
                    updatedBy: 'system',
                    updatedDate: '2022-03-17T13:55:51.835807+00:00',
                    isArchived: false,
                    transportingProduct: true,
                    noTransportReason: '',
                    transportLegs: [
                        {
                            id: 'aff5be4a-8bcc-4b3b-c6f2-08d97ced0c36',
                            transportMode: null,
                            distance: 2501.0,
                            distanceUnit: 'KM',
                            impactId: null,
                            countryId: null,
                            transportActivityId: '0d33534e-592a-424c-72ea-08d97cecb76b',
                            salesActivityId: null,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-09-21T10:46:20.3536432+00:00',
                            updatedBy: 'system',
                            updatedDate: '2022-03-17T13:55:51.8360139+00:00',
                            isArchived: false,
                        },
                    ],
                },
            ],
            suppliers: [],
            produce: [],
        },
        processing: null,
        packaging: null,
        storage: null,
        retail: null,
        ingredients: [
            {
                id: '46264159-729f-4073-8a61-08d963a2b8b4',
                nodeId: '80ecb16a-3d4f-4ecf-904a-87d809c0fa81',
                parentId: '06985c05-e447-4cfe-a88d-96b99efff885',
                name: 'White Bread Collection',
                isSpecialIngredient: false,
                supplierId: null,
                supplierName: null,
                companyName: 'Alauddin Co',
                companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                rootParentId: '80ecb16a-3d4f-4ecf-904a-87d809c0fa81',
                isProduce: false,
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [
                    {
                        id: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                        nodeId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        parentId: '80ecb16a-3d4f-4ecf-904a-87d809c0fa81',
                        name: 'White Bread',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Alauddin Co',
                        companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                        rootParentId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: false,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                            name: 'Tom test',
                            displayId: null,
                            siteId: '0001Tom',
                            companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                            companyName: 'Able & Cole Ltd.',
                            addressId: '8bdaf111-3da5-4824-3f21-08d9835608bd',
                            address: {
                                id: '8bdaf111-3da5-4824-3f21-08d9835608bd',
                                streetAddress: 'New Cross',
                                city: 'London',
                                pinCode: null,
                                state: 'ENG',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '51.47357',
                                longitude: '-0.02924',
                            },
                            linkedCount: 1,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-09-29T14:32:57.1663548+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-09-29T14:32:57.1604005+00:00',
                            totalProductsProductionInKg: 0.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                impactId: null,
                                linkedProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                                transportProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                calcId: null,
                                sourceSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                destinationSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-09-21T10:43:58.0357592+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-21T11:15:43.2659666+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: 'Not required',
                                transportLegs: [
                                    {
                                        id: 'e64c661b-97cb-4c7a-e896-08d98d4e0510',
                                        transportMode: null,
                                        distance: 200.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 4,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-10-12T07:00:48.1223284+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.269569+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: '16401049-bfde-46d8-0e5d-08d9b3c7fa9d',
                                        transportMode: null,
                                        distance: 12.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 46,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-11-30T07:48:55.0930925+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.2695739+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: '2fc402a0-6504-4738-8da4-08d9c4733aa3',
                                        transportMode: null,
                                        distance: 3500.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-21T11:15:43.2695752+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.2695746+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                            {
                                id: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                impactId: null,
                                linkedProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                                transportProductId: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-11-30T06:09:33.2247386+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-01T04:29:19.1923301+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: '58cb09ae-d47d-4efa-0e5c-08d9b3c7fa9d',
                                        transportMode: null,
                                        distance: 300.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 235,
                                        transportActivityId: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-11-30T06:09:33.2319109+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-01T04:29:19.1956408+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: 'e14f3bc4-92fd-4884-00ed-08d9b483244b',
                                        transportMode: null,
                                        distance: 10.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-01T04:29:19.1956456+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-01T04:29:19.195645+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                        nodeId: '857dc001-0af8-47c9-a609-6e3a08c892c8',
                        parentId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        name: 'Processing: Wheat Flour',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Able & Cole Ltd.',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '857dc001-0af8-47c9-a609-6e3a08c892c8',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'complete',
                        packagingDetails: 'complete',
                        retailDetails: null,
                        storageDetails: 'complete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                            name: 'Alan Co',
                            displayId: null,
                            siteId: null,
                            companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                            companyName: 'Alauddin Co',
                            addressId: '5b36f693-a41c-4230-f85a-08d93ab82268',
                            address: {
                                id: '5b36f693-a41c-4230-f85a-08d93ab82268',
                                streetAddress: '123, Abby Road',
                                city: 'Grimsby',
                                pinCode: 'DN396YW',
                                state: 'Lincolnshire',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: 'admin@mondra.com',
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: false,
                                latitude: null,
                                longitude: null,
                            },
                            linkedCount: 1,
                            createdBy: null,
                            createdDate: '2021-06-29T05:14:48.7408551+00:00',
                            updatedBy: null,
                            updatedDate: '0001-01-01T00:00:00+00:00',
                            totalProductsProductionInKg: 120.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '285ed2e1-0a23-449e-4c08-08d9ba6c4c8c',
                                impactId: null,
                                linkedProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                transportProductId: '9072cd5f-ea31-40e2-2528-08d9613b5764',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-12-08T17:00:55.3120786+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-08T17:00:55.3129492+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: 'a5ac60aa-57f6-41e1-614f-08d9ba6c4c8d',
                                        transportMode: null,
                                        distance: 2113.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 2,
                                        transportActivityId: '285ed2e1-0a23-449e-4c08-08d9ba6c4c8c',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-08T17:00:55.3129966+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-08T17:00:55.3129977+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                            {
                                id: '84f123a7-7d7a-4a77-4c09-08d9ba6c4c8c',
                                impactId: null,
                                linkedProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                transportProductId: 'ed3bb902-7c76-4330-3301-08d962147fac',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-12-08T17:02:02.2995413+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-08T17:02:02.299564+00:00',
                                isArchived: false,
                                transportingProduct: false,
                                noTransportReason: '',
                                transportLegs: [],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                        nodeId: 'b815acd7-a0b7-4712-adb7-a87761fcff70',
                        parentId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        name: 'White Wheat Flour',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Able & Cole Ltd.',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: 'b815acd7-a0b7-4712-adb7-a87761fcff70',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: 'c0c2f011-8c38-4dac-090c-08d9621bf6cb',
                            name: 'All Grains Bakery Warehouse',
                            displayId: null,
                            siteId: 'AGB-12',
                            companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                            companyName: 'All Grains Bakery',
                            addressId: 'db641285-63c9-4782-2566-08d9621bf6cb',
                            address: {
                                id: 'db641285-63c9-4782-2566-08d9621bf6cb',
                                streetAddress: '',
                                city: 'London',
                                pinCode: null,
                                state: 'ENG',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '51.50015',
                                longitude: '-0.12624',
                            },
                            linkedCount: 1,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-08-18T07:44:09.3773019+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-08-18T07:44:09.377293+00:00',
                            totalProductsProductionInKg: 0.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '7bfa26b8-e9b1-429f-a95e-08d9d021a6e0',
                                impactId: null,
                                linkedProductId: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                                transportProductId: '48570acd-2a7c-4631-e49b-08d9621aebe1',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: 'c0c2f011-8c38-4dac-090c-08d9621bf6cb',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2022-01-05T08:02:00.1459849+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2022-01-05T08:02:00.1461911+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: 'db068f4d-9190-4803-6166-08d9d021a6e6',
                                        transportMode: null,
                                        distance: 134253.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '7bfa26b8-e9b1-429f-a95e-08d9d021a6e0',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2022-01-05T08:02:00.1462216+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2022-01-05T08:02:00.1462226+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                ],
                produce: [
                    {
                        id: 'ed3bb902-7c76-4330-3301-08d962147fac',
                        nodeId: 'f0caa142-3452-4c52-9b64-cf3e73bcb010',
                        parentId: '857dc001-0af8-47c9-a609-6e3a08c892c8',
                        name: 'Fresh Wheat',
                        isSpecialIngredient: false,
                        supplierId: '14632f1a-5d93-46d3-80d6-3c5538782f51',
                        supplierName: 'Grain supplies EU',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '857dc001-0af8-47c9-a609-6e3a08c892c8',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '9072cd5f-ea31-40e2-2528-08d9613b5764',
                        nodeId: '72f74ddb-a7cf-4fd2-a41d-dcf0edaf76f8',
                        parentId: '857dc001-0af8-47c9-a609-6e3a08c892c8',
                        name: 'Wheat',
                        isSpecialIngredient: false,
                        supplierId: 'c240270b-7ca7-4889-84d9-9253da1ebe2e',
                        supplierName: 'All Grains Bakery',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '857dc001-0af8-47c9-a609-6e3a08c892c8',
                        isProduce: false,
                        saState: 'Certified',
                        saType: 'Produce',
                        ecoImpacts: [
                            {
                                code: 'EIID001',
                                name: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
                                scientificTerm: null,
                                measureUnit: 'g',
                                impactScore: 2185.2,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 2185.2,
                                per100g: 218.52,
                                perServing: 0.0,
                                perProduct: 2185.2,
                            },
                            {
                                code: 'EIID002',
                                name: 'Water Scarcity (eq)',
                                scientificTerm: null,
                                measureUnit: 'L',
                                impactScore: 695.2,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 695.2,
                                per100g: 69.52,
                                perServing: 0.0,
                                perProduct: 695.2,
                            },
                            {
                                code: 'EIID003',
                                name: 'Eutrophication (PO43-)',
                                scientificTerm: null,
                                measureUnit: 'g',
                                impactScore: 5.6,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 5.6,
                                per100g: 0.56,
                                perServing: 0.0,
                                perProduct: 5.6,
                            },
                            {
                                code: 'EIID004',
                                name: 'Biodiversity (sp.yr10<sup>14</sup>)',
                                scientificTerm: null,
                                measureUnit: '',
                                impactScore: 0.1,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 0.1,
                                per100g: 0.01,
                                perServing: 0.0,
                                perProduct: 0.1,
                            },
                        ],
                        state: 'certified',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: 'A',
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '48570acd-2a7c-4631-e49b-08d9621aebe1',
                        nodeId: '333f4280-6ed5-47e8-9595-411f688b00fa',
                        parentId: 'b815acd7-a0b7-4712-adb7-a87761fcff70',
                        name: 'Fresh Wheat',
                        isSpecialIngredient: false,
                        supplierId: '9d5ba423-6bde-43e9-a962-8c1b1893f7b2',
                        supplierName: 'Food engineers Ltd. (transferred from Sandwich Co.)',
                        companyName: 'All Grains Bakery',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: 'b815acd7-a0b7-4712-adb7-a87761fcff70',
                        isProduce: false,
                        canRemove: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '2e80348d-5013-41ae-e49a-08d9621aebe1',
                        nodeId: 'd5d53807-1c47-4d47-8d2c-4ba837f091ae',
                        parentId: 'b815acd7-a0b7-4712-adb7-a87761fcff70',
                        name: 'Wheat Grain',
                        isSpecialIngredient: false,
                        supplierId: 'b2eecc82-9a1d-412c-a3c4-bbee74126eeb',
                        supplierName: 'Baking supplies Ltd. (transferred from Sandwich Co.)',
                        companyName: 'All Grains Bakery',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: 'b815acd7-a0b7-4712-adb7-a87761fcff70',
                        isProduce: false,
                        canRemove: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: 'c9db16f9-0e3a-4e7e-a511-08da18765e3f',
                        nodeId: '63206980-7e10-4661-81dd-c026970fab09',
                        parentId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        name: 'Roots',
                        isSpecialIngredient: false,
                        supplierId: '5ed1496c-891c-4a90-96d1-060d91c59a57',
                        supplierName: 'Unknown company',
                        companyName: 'Able & Cole Ltd.',
                        companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                        rootParentId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '996c4092-6f5a-425d-a512-08da18765e3f',
                        nodeId: '84cfe536-b60f-4b48-9e7f-1ad0733600c1',
                        parentId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        name: 'Patato',
                        isSpecialIngredient: false,
                        supplierId: '5ed1496c-891c-4a90-96d1-060d91c59a57',
                        supplierName: 'Unknown company',
                        companyName: 'Able & Cole Ltd.',
                        companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                        rootParentId: 'ec66e84c-6459-48bd-be98-f4547fd78da4',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                ],
            },
            {
                id: 'd64b9c4e-254d-4046-6b0b-08d9a02663e7',
                nodeId: '48c22d62-f3d6-483e-9a67-2a005dfdc357',
                parentId: '06985c05-e447-4cfe-a88d-96b99efff885',
                name: 'Water',
                isSpecialIngredient: true,
                supplierId: null,
                supplierName: null,
                companyName: null,
                companyId: null,
                rootParentId: '48c22d62-f3d6-483e-9a67-2a005dfdc357',
                isProduce: false,
                canRemove: false,
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [],
                produce: [],
            },
        ],
    },
    data3: {
        product: {
            id: '953c2152-b91e-4354-e561-08da17b55a98',
            nodeId: 'cf2f929c-5404-4730-8ab5-7ca3e46fa220',
            parentId: '00000000-0000-0000-0000-000000000000',
            name: 'Fixed Lines',
            isSpecialIngredient: false,
            supplierId: null,
            supplierName: null,
            companyName: 'Kili',
            companyId: '23937044-3782-47e3-a4c8-17e257faf312',
            rootParentId: '00000000-0000-0000-0000-000000000000',
            isProduce: false,
            saState: 'Draft',
            saType: 'Product',
            ecoImpacts: [],
            state: 'review',
            productDetails: 'complete',
            ingredientDetails: 'complete',
            processingDetails: 'complete',
            packagingDetails: 'complete',
            retailDetails: null,
            storageDetails: 'incomplete',
            ingredientsRequired: true,
            processingRequired: true,
            packagingRequired: true,
            retailRequired: false,
            storageRequired: false,
            quantity: null,
            measureUnit: 'g',
            originCountryCode: 'UKR',
            ecoImpactMetascore: null,
            productMetadata: null,
            site: {
                id: 'd0a6b6f3-8ad1-4956-219a-08da0b61e5f1',
                name: 'Ternopil Centre',
                displayId: null,
                siteId: 'TC',
                companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                companyName: 'Kili',
                addressId: 'fdc8e7a1-7ec1-4c81-6840-08da0b61e5f3',
                address: {
                    id: 'fdc8e7a1-7ec1-4c81-6840-08da0b61e5f3',
                    streetAddress: '',
                    city: 'Ternopilska miska hromada',
                    pinCode: null,
                    state: 'Ternopil Oblast',
                    country: 'Ukraine',
                    countryCode: 'UKR',
                    countryCodeISOAlpha2: 'UA',
                    countryCodeISOAlpha3: 'UKR',
                    countryId: 233,
                    createdBy: null,
                    createdDate: '0001-01-01T00:00:00+00:00',
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00+00:00',
                    isArchived: false,
                    isFullStreetAddress: true,
                    latitude: '49.56064',
                    longitude: '25.59867',
                },
                linkedCount: 1,
                createdBy: 'system',
                createdDate: '2022-03-21T17:45:15.5797099+00:00',
                updatedBy: 'system',
                updatedDate: '2022-03-21T17:45:15.5797208+00:00',
                totalProductsProductionInKg: 1.0,
                isArchived: false,
            },
            transport: [
                {
                    id: '1fd3aa7a-a1bd-4ee4-edab-08da17b90814',
                    impactId: null,
                    linkedProductId: '953c2152-b91e-4354-e561-08da17b55a98',
                    transportProductId: '5f21558c-9883-4d04-e562-08da17b55a98',
                    calcId: null,
                    sourceSiteId: null,
                    destinationSiteId: 'd0a6b6f3-8ad1-4956-219a-08da0b61e5f1',
                    createdBy: 'system',
                    createdDate: '2022-04-06T10:34:29.7647014+00:00',
                    updatedBy: 'system',
                    updatedDate: '2022-04-06T10:52:19.5762463+00:00',
                    isArchived: false,
                    transportingProduct: true,
                    noTransportReason: '',
                    transportLegs: [
                        {
                            id: 'c0ec8da3-5856-4908-75b0-08da17b90825',
                            transportMode: null,
                            distance: 80.0,
                            distanceUnit: 'KM',
                            impactId: null,
                            countryId: 233,
                            transportActivityId: '1fd3aa7a-a1bd-4ee4-edab-08da17b90814',
                            salesActivityId: null,
                            createdBy: 'system',
                            createdDate: '2022-04-06T10:34:29.7826597+00:00',
                            updatedBy: 'system',
                            updatedDate: '2022-04-06T10:52:19.57628+00:00',
                            isArchived: false,
                        },
                    ],
                },
            ],
            suppliers: [],
            produce: [],
        },
        processing: null,
        packaging: null,
        storage: null,
        retail: null,
        ingredients: [
            {
                id: '3581cb6c-9970-4bbf-2772-08da17b7797d',
                nodeId: 'fcad3899-aa75-485a-a39c-ebb34a0d5698',
                parentId: 'cf2f929c-5404-4730-8ab5-7ca3e46fa220',
                name: 'IFL1',
                isSpecialIngredient: false,
                supplierId: null,
                supplierName: null,
                companyName: 'Kili',
                companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                rootParentId: 'fcad3899-aa75-485a-a39c-ebb34a0d5698',
                isProduce: false,
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [
                    {
                        id: '45b718ea-4fe4-4e38-e565-08da17b55a98',
                        nodeId: '88e6af24-0755-428b-adbb-c46d1ca2e11e',
                        parentId: 'fcad3899-aa75-485a-a39c-ebb34a0d5698',
                        name: 'FLT',
                        isSpecialIngredient: false,
                        supplierId: '27f1e8ad-dee2-49c5-f506-08d9e7e16bb9',
                        supplierName: 'TestSupp',
                        companyName: 'Kili',
                        companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                        rootParentId: 'fcad3899-aa75-485a-a39c-ebb34a0d5698',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'UKR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: '85113e47-3378-4af5-2199-08da0b61e5f1',
                            name: 'Lviv Store House',
                            displayId: null,
                            siteId: 'LSH',
                            companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                            companyName: 'Kili',
                            addressId: '36fdc388-6471-481f-683f-08da0b61e5f3',
                            address: {
                                id: '36fdc388-6471-481f-683f-08da0b61e5f3',
                                streetAddress: '',
                                city: 'Lvivska miska hromada',
                                pinCode: null,
                                state: 'Lviv Oblast',
                                country: 'Ukraine',
                                countryCode: 'UKR',
                                countryCodeISOAlpha2: 'UA',
                                countryCodeISOAlpha3: 'UKR',
                                countryId: 233,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '49.83968',
                                longitude: '24.02972',
                            },
                            linkedCount: 1,
                            createdBy: 'system',
                            createdDate: '2022-03-21T17:40:32.3702419+00:00',
                            updatedBy: 'system',
                            updatedDate: '2022-03-21T17:41:47.8732226+00:00',
                            totalProductsProductionInKg: 3.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: 'f2d95507-8f61-4a34-edac-08da17b90814',
                                impactId: null,
                                linkedProductId: '45b718ea-4fe4-4e38-e565-08da17b55a98',
                                transportProductId: '8b4bba01-fd16-4927-e564-08da17b55a98',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '85113e47-3378-4af5-2199-08da0b61e5f1',
                                createdBy: 'system',
                                createdDate: '2022-04-06T10:59:48.6167667+00:00',
                                updatedBy: 'system',
                                updatedDate: '2022-04-06T10:59:48.6172387+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: '665a82bd-5db2-4693-75b1-08da17b90825',
                                        transportMode: null,
                                        distance: 50.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 233,
                                        transportActivityId: 'f2d95507-8f61-4a34-edac-08da17b90814',
                                        salesActivityId: null,
                                        createdBy: 'system',
                                        createdDate: '2022-04-06T10:59:48.6176544+00:00',
                                        updatedBy: 'system',
                                        updatedDate: '2022-04-06T10:59:48.617659+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                ],
                produce: [
                    {
                        id: '8b4bba01-fd16-4927-e564-08da17b55a98',
                        nodeId: '97061bb6-aacd-415d-8310-76cdbfce3c68',
                        parentId: '88e6af24-0755-428b-adbb-c46d1ca2e11e',
                        name: 'FLC2',
                        isSpecialIngredient: false,
                        supplierId: '27f1e8ad-dee2-49c5-f506-08d9e7e16bb9',
                        supplierName: 'TestSupp',
                        companyName: 'Kili',
                        companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                        rootParentId: 'fcad3899-aa75-485a-a39c-ebb34a0d5698',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'UKR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '5f21558c-9883-4d04-e562-08da17b55a98',
                        nodeId: 'c800fccf-68dc-461b-b316-42c2717152bb',
                        parentId: '88e6af24-0755-428b-adbb-c46d1ca2e11e',
                        name: 'FLC1',
                        isSpecialIngredient: false,
                        supplierId: '27f1e8ad-dee2-49c5-f506-08d9e7e16bb9',
                        supplierName: 'TestSupp',
                        companyName: 'Kili',
                        companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                        rootParentId: 'fcad3899-aa75-485a-a39c-ebb34a0d5698',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'UKR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                ],
            },
            {
                id: '0760fee9-bde7-4c32-2773-08da17b7797d',
                nodeId: '45f5562a-a707-4262-9eb1-f1c2bb9763b1',
                parentId: 'cf2f929c-5404-4730-8ab5-7ca3e46fa220',
                name: 'IFL2',
                isSpecialIngredient: false,
                supplierId: null,
                supplierName: null,
                companyName: 'Kili',
                companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                rootParentId: '45f5562a-a707-4262-9eb1-f1c2bb9763b1',
                isProduce: false,
                saState: null,
                saType: 'Produce',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [],
                produce: [
                    {
                        id: '8b4bba01-fd16-4927-e564-08da17b55a98',
                        nodeId: 'd04b3c4d-9e12-4885-b2ef-b4882f4fc976',
                        parentId: '45f5562a-a707-4262-9eb1-f1c2bb9763b1',
                        name: 'FLC2',
                        isSpecialIngredient: false,
                        supplierId: '27f1e8ad-dee2-49c5-f506-08d9e7e16bb9',
                        supplierName: 'TestSupp',
                        companyName: 'Kili',
                        companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                        rootParentId: '45f5562a-a707-4262-9eb1-f1c2bb9763b1',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'UKR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '5f21558c-9883-4d04-e562-08da17b55a98',
                        nodeId: '36ea9353-9c13-4e4e-af64-739756c64006',
                        parentId: '45f5562a-a707-4262-9eb1-f1c2bb9763b1',
                        name: 'FLC1',
                        isSpecialIngredient: false,
                        supplierId: '27f1e8ad-dee2-49c5-f506-08d9e7e16bb9',
                        supplierName: 'TestSupp',
                        companyName: 'Kili',
                        companyId: '23937044-3782-47e3-a4c8-17e257faf312',
                        rootParentId: '45f5562a-a707-4262-9eb1-f1c2bb9763b1',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'UKR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                ],
            },
        ],
    },
    data4: {
        product: {
            productName: 'Product with 250 nodes in supply chain',
            quantity: '160g',
            id: 'product',
        },
        processing: {
            id: 'proceesing',
            title: 'Processing',
        },
        packaging: {
            id: 'packaging',
            title: 'Packaging',
        },
        storage: {
            id: 'storage',
            title: 'Storage',
        },
        retail: {
            id: 'retail',
            title: 'Retail',
        },
        ingredients: [
            {
                productName: 'White Bread',
                category: 'Bread',
                id: '1',
                suppliers: [
                    {
                        id: 'sup51',
                        ingName: 'sup 51',
                        productName: 'sup 51',
                        parentId: 'sup52',
                    },
                    {
                        id: 'sup52',
                        ingName: 'sup 52',
                        productName: 'sup 52',
                        parentId: 'sup53',
                    },

                    {
                        id: 'sup53',
                        ingName: 'sup 53',
                        productName: 'sup 53',
                        parentId: 'sup54',
                    },
                    {
                        id: 'sup54',
                        ingName: 'sup 54',
                        productName: 'sup 54',
                        parentId: '1',
                    },
                    {
                        id: 'sup55',
                        ingName: 'sup 55',
                        productName: 'sup 55',
                        parentId: 'sup56',
                    },
                    {
                        id: 'sup56',
                        ingName: 'Sup 56',
                        productName: 'Sup 56',
                        parentId: 'sup57',
                    },
                    {
                        id: 'sup57',
                        ingName: 'Sup 57',
                        productName: 'Sup 57',
                        parentId: 'sup58',
                    },
                    {
                        id: 'sup58',
                        ingName: 'sup 58',
                        productName: 'sup 58',
                        parentId: 'sup59',
                    },
                    {
                        id: 'sup59',
                        ingName: 'sup 59',
                        productName: 'sup 59',
                        parentId: '1',
                    },
                    {
                        id: 'sup60',
                        ingName: 'sup 60',
                        productName: 'sup 60',
                        parentId: 'sup61',
                    },
                    {
                        id: 'sup61',
                        ingName: 'sup 61',
                        productName: 'sup 61',
                        parentId: 'sup62',
                    },
                    {
                        id: 'sup62',
                        ingName: 'sup 62',
                        productName: 'sup 62',
                        parentId: '1',
                    },

                    {
                        id: 'sup63',
                        ingName: 'sup 63',
                        productName: 'sup 63',
                        parentId: 'sup64',
                    },
                    {
                        id: 'sup64',
                        ingName: 'sup 64',
                        productName: 'sup 64',
                        parentId: 'sup65',
                    },
                    {
                        id: 'sup65',
                        ingName: 'sup 65',
                        productName: 'sup 65',
                        parentId: 'sup66',
                    },
                    {
                        id: 'sup66',
                        ingName: 'Sup 66',
                        productName: 'Sup 66',
                        parentId: 'sup67',
                    },
                    {
                        id: 'sup67',
                        ingName: 'Sup 67',
                        productName: 'Sup 67',
                        parentId: '1',
                    },
                    {
                        id: 'sup68',
                        ingName: 'sup 68',
                        productName: 'sup 68',
                        parentId: 'sup69',
                    },
                    {
                        id: 'sup69',
                        ingName: 'sup 69',
                        productName: 'sup 59',
                        parentId: 'sup70',
                    },
                    {
                        id: 'sup70',
                        ingName: 'sup 70',
                        productName: 'sup 70',
                        parentId: 'sup71',
                    },
                    {
                        id: 'sup71',
                        ingName: 'sup 71',
                        productName: 'sup 71',
                        parentId: 'sup72',
                    },
                    {
                        id: 'sup72',
                        ingName: 'sup 72',
                        productName: 'sup 72',
                        parentId: '1',
                    },

                    {
                        id: 'sup73',
                        ingName: 'sup 73',
                        productName: 'sup 73',
                        parentId: 'sup74',
                    },
                    {
                        id: 'sup74',
                        ingName: 'sup 74',
                        productName: 'sup 74',
                        parentId: 'sup75',
                    },
                    {
                        id: 'sup75',
                        ingName: 'sup 75',
                        productName: 'sup 75',
                        parentId: 'sup76',
                    },
                    {
                        id: 'sup76',
                        ingName: 'Sup 76',
                        productName: 'Sup 76',
                        parentId: 'sup77',
                    },
                    {
                        id: 'sup77',
                        ingName: 'Sup 77',
                        productName: 'Sup 77',
                        parentId: 'sup78',
                    },
                    {
                        id: 'sup78',
                        ingName: 'sup 78',
                        productName: 'sup 78',
                        parentId: 'sup79',
                    },
                    {
                        id: 'sup79',
                        ingName: 'sup 79',
                        productName: 'sup 79',
                        parentId: '1',
                    },
                    {
                        id: 'sup80',
                        ingName: 'sup 80',
                        productName: 'sup 80',
                        parentId: 'sup81',
                    },
                    {
                        id: 'sup81',
                        ingName: 'sup 81',
                        productName: 'sup 81',
                        parentId: 'sup82',
                    },
                    {
                        id: 'sup82',
                        ingName: 'sup 82',
                        productName: 'sup 82',
                        parentId: 'sup83',
                    },

                    {
                        id: 'sup83',
                        ingName: 'sup 83',
                        productName: 'sup 83',
                        parentId: 'sup84',
                    },
                    {
                        id: 'sup84',
                        ingName: 'sup 84',
                        productName: 'sup 84',
                        parentId: 'sup85',
                    },
                    {
                        id: 'sup85',
                        ingName: 'sup 85',
                        productName: 'sup 85',
                        parentId: 'sup86',
                    },
                    {
                        id: 'sup86',
                        ingName: 'Sup 86',
                        productName: 'Sup 86',
                        parentId: '1',
                    },
                    {
                        id: 'sup87',
                        ingName: 'Sup 87',
                        productName: 'Sup 87',
                        parentId: 'sup88',
                    },
                    {
                        id: 'sup88',
                        ingName: 'sup 88',
                        productName: 'sup 88',
                        parentId: 'sup89',
                    },
                    {
                        id: 'sup89',
                        ingName: 'sup 89',
                        productName: 'sup 89',
                        parentId: 'sup90',
                    },
                    {
                        id: 'sup90',
                        ingName: 'sup 90',
                        productName: 'sup 90',
                        parentId: 'sup91',
                    },
                    {
                        id: 'sup91',
                        ingName: 'sup 91',
                        productName: 'sup 91',
                        parentId: '1',
                    },
                    {
                        id: 'sup92',
                        ingName: 'sup 92',
                        productName: 'sup 92',
                        parentId: 'sup93',
                    },

                    {
                        id: 'sup93',
                        ingName: 'sup 93',
                        productName: 'sup 93',
                        parentId: 'sup94',
                    },
                    {
                        id: 'sup94',
                        ingName: 'sup 94',
                        productName: 'sup 94',
                        parentId: 'sup95',
                    },
                    {
                        id: 'sup95',
                        ingName: 'sup 95',
                        productName: 'sup 95',
                        parentId: 'sup96',
                    },
                    {
                        id: 'sup96',
                        ingName: 'Sup 96',
                        productName: 'Sup 96',
                        parentId: 'sup97',
                    },
                    {
                        id: 'sup97',
                        ingName: 'Sup 97',
                        productName: 'Sup 97',
                        parentId: '1',
                    },
                    {
                        id: 'sup98',
                        ingName: 'sup 98',
                        productName: 'sup 98',
                        parentId: 'sup99',
                    },
                    {
                        id: 'sup99',
                        ingName: 'sup 99',
                        productName: 'sup 59',
                        parentId: 'sup100',
                    },
                    {
                        id: 'sup100',
                        ingName: 'sup 100',
                        productName: 'sup 100',
                        parentId: 'sup101',
                    },
                    {
                        id: 'sup101',
                        ingName: 'sup 101',
                        productName: 'sup 101',
                        parentId: '1',
                    },
                    {
                        id: 'sup102',
                        ingName: 'sup 102',
                        productName: 'sup 102',
                        parentId: 'sup103',
                    },

                    {
                        id: 'sup103',
                        ingName: 'sup 103',
                        productName: 'sup 103',
                        parentId: 'sup104',
                    },
                    {
                        id: 'sup104',
                        ingName: 'sup 104',
                        productName: 'sup 104',
                        parentId: 'sup105',
                    },
                    {
                        id: 'sup105',
                        ingName: 'sup 105',
                        productName: 'sup 105',
                        parentId: '1',
                    },
                    {
                        id: 'sup106',
                        ingName: 'Sup 106',
                        productName: 'Sup 106',
                        parentId: 'sup107',
                    },
                    {
                        id: 'sup107',
                        ingName: 'Sup 107',
                        productName: 'Sup 107',
                        parentId: 'sup108',
                    },
                    {
                        id: 'sup108',
                        ingName: 'sup 108',
                        productName: 'sup 108',
                        parentId: 'sup109',
                    },
                    {
                        id: 'sup109',
                        ingName: 'sup 109',
                        productName: 'sup 109',
                        parentId: 'sup110',
                    },
                    {
                        id: 'sup110',
                        ingName: 'sup 110',
                        productName: 'sup 110',
                        parentId: 'sup111',
                    },
                    {
                        id: 'sup111',
                        ingName: 'sup 111',
                        productName: 'sup 111',
                        parentId: '1',
                    },
                    {
                        id: 'sup112',
                        ingName: 'sup 112',
                        productName: 'sup 112',
                        parentId: 'sup113',
                    },

                    {
                        id: 'sup113',
                        ingName: 'sup 113',
                        productName: 'sup 113',
                        parentId: 'sup114',
                    },
                    {
                        id: 'sup114',
                        ingName: 'sup 114',
                        productName: 'sup 114',
                        parentId: 'sup115',
                    },
                    {
                        id: 'sup115',
                        ingName: 'sup 115',
                        productName: 'sup 115',
                        parentId: '1',
                    },
                    {
                        id: 'sup116',
                        ingName: 'Sup 116',
                        productName: 'Sup 116',
                        parentId: 'sup117',
                    },
                    {
                        id: 'sup117',
                        ingName: 'Sup 117',
                        productName: 'Sup 117',
                        parentId: 'sup118',
                    },
                    {
                        id: 'sup118',
                        ingName: 'sup 118',
                        productName: 'sup 118',
                        parentId: 'sup119',
                    },
                    {
                        id: 'sup119',
                        ingName: 'sup 119',
                        productName: 'sup 119',
                        parentId: 'sup120',
                    },
                    {
                        id: 'sup120',
                        ingName: 'sup 120',
                        productName: 'sup 120',
                        parentId: '1',
                    },

                    {
                        id: 'sup121',
                        ingName: 'sup 121',
                        productName: 'sup 121',
                        parentId: 'sup122',
                    },
                    {
                        id: 'sup122',
                        ingName: 'sup 122',
                        productName: 'sup 122',
                        parentId: 'sup123',
                    },

                    {
                        id: 'sup123',
                        ingName: 'sup 123',
                        productName: 'sup 123',
                        parentId: 'sup124',
                    },
                    {
                        id: 'sup124',
                        ingName: 'sup 124',
                        productName: 'sup 124',
                        parentId: 'sup125',
                    },
                    {
                        id: 'sup125',
                        ingName: 'sup 125',
                        productName: 'sup 125',
                        parentId: 'sup126',
                    },
                    {
                        id: 'sup126',
                        ingName: 'Sup 126',
                        productName: 'Sup 126',
                        parentId: 'sup127',
                    },
                    {
                        id: 'sup127',
                        ingName: 'Sup 127',
                        productName: 'Sup 127',
                        parentId: '1',
                    },
                    {
                        id: 'sup128',
                        ingName: 'sup 128',
                        productName: 'sup 128',
                        parentId: 'sup129',
                    },
                    {
                        id: 'sup129',
                        ingName: 'sup 129',
                        productName: 'sup 129',
                        parentId: 'sup130',
                    },
                    {
                        id: 'sup130',
                        ingName: 'sup 130',
                        productName: 'sup 130',
                        parentId: 'sup131',
                    },
                    {
                        id: 'sup131',
                        ingName: 'sup 131',
                        productName: 'sup 131',
                        parentId: 'sup132',
                    },
                    {
                        id: 'sup132',
                        ingName: 'sup 122',
                        productName: 'sup 132',
                        parentId: '1',
                    },

                    {
                        id: 'sup133',
                        ingName: 'sup 133',
                        productName: 'sup 133',
                        parentId: 'sup134',
                    },
                    {
                        id: 'sup134',
                        ingName: 'sup 134',
                        productName: 'sup 134',
                        parentId: 'sup135',
                    },
                    {
                        id: 'sup135',
                        ingName: 'sup 135',
                        productName: 'sup 135',
                        parentId: 'sup136',
                    },
                    {
                        id: 'sup136',
                        ingName: 'Sup 136',
                        productName: 'Sup 136',
                        parentId: 'sup137',
                    },
                    {
                        id: 'sup137',
                        ingName: 'Sup 137',
                        productName: 'Sup 137',
                        parentId: 'sup138',
                    },
                    {
                        id: 'sup138',
                        ingName: 'sup 138',
                        productName: 'sup 138',
                        parentId: 'sup139',
                    },
                    {
                        id: 'sup139',
                        ingName: 'sup 139',
                        productName: 'sup 139',
                        parentId: '1',
                    },
                    {
                        id: 'sup140',
                        ingName: 'sup 140',
                        productName: 'sup 140',
                        parentId: 'sup141',
                    },

                    {
                        id: 'sup141',
                        ingName: 'sup 141',
                        productName: 'sup 141',
                        parentId: 'sup142',
                    },
                    {
                        id: 'sup142',
                        ingName: 'sup 142',
                        productName: 'sup 142',
                        parentId: 'sup143',
                    },

                    {
                        id: 'sup143',
                        ingName: 'sup 143',
                        productName: 'sup 143',
                        parentId: 'sup144',
                    },
                    {
                        id: 'sup144',
                        ingName: 'sup 144',
                        productName: 'sup 144',
                        parentId: '1',
                    },
                    {
                        id: 'sup145',
                        ingName: 'sup 145',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup146',
                    },
                    {
                        id: 'sup146',
                        ingName: 'Sup 146',
                        productName: 'Sup 146',
                        parentId: 'sup147',
                    },
                    {
                        id: 'sup147',
                        ingName: 'Sup 147',
                        productName: 'Sup 147',
                        parentId: '1',
                    },
                    {
                        id: 'sup148',
                        ingName: 'sup 148',
                        productName: 'sup 148',
                        parentId: 'sup149',
                    },
                    {
                        id: 'sup149',
                        ingName: 'sup 149',
                        productName: 'sup 149',
                        parentId: 'sup150',
                    },
                    {
                        id: 'sup150',
                        ingName: 'sup 150',
                        productName: 'sup 150',
                        parentId: 'sup151',
                    },
                    {
                        id: 'sup151',
                        ingName: 'sup 151',
                        productName: 'sup 151',
                        parentId: 'sup152',
                    },
                    {
                        id: 'sup152',
                        ingName: 'sup 152',
                        productName: 'sup 152',
                        parentId: 'sup153',
                    },

                    {
                        id: 'sup153',
                        ingName: 'sup 153',
                        productName: 'sup 153',
                        parentId: 'sup154',
                    },
                    {
                        id: 'sup154',
                        ingName: 'sup 154',
                        productName: 'sup 154',
                        parentId: 'sup155',
                    },
                    {
                        id: 'sup155',
                        ingName: 'sup 155',
                        productName: 'sup 155',
                        parentId: '1',
                    },
                    {
                        id: 'sup156',
                        ingName: 'Sup 156',
                        productName: 'Sup 156',
                        parentId: 'sup157',
                    },
                    {
                        id: 'sup157',
                        ingName: 'Sup 157',
                        productName: 'Sup 157',
                        parentId: '1',
                    },
                    {
                        id: 'sup158',
                        ingName: 'sup 158',
                        productName: 'sup 158',
                        parentId: '1',
                    },
                    {
                        id: 'sup159',
                        ingName: 'sup 159',
                        productName: 'sup 159',
                        parentId: 'sup160',
                    },
                    {
                        id: 'sup160',
                        ingName: 'sup 160',
                        productName: 'sup 160',
                        parentId: 'sup161',
                    },

                    {
                        id: 'sup161',
                        ingName: 'sup 161',
                        productName: 'sup 161',
                        parentId: 'sup162',
                    },
                    {
                        id: 'sup162',
                        ingName: 'sup 162',
                        productName: 'sup 162',
                        parentId: 'sup163',
                    },

                    {
                        id: 'sup163',
                        ingName: 'sup 163',
                        productName: 'sup 163',
                        parentId: 'sup164',
                    },
                    {
                        id: 'sup164',
                        ingName: 'sup 164',
                        productName: 'sup 164',
                        parentId: 'sup165',
                    },
                    {
                        id: 'sup165',
                        ingName: 'sup 165',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup166',
                    },
                    {
                        id: 'sup166',
                        ingName: 'Sup 166',
                        productName: 'Sup 166',
                        parentId: '1',
                    },
                    {
                        id: 'sup167',
                        ingName: 'Sup 167',
                        productName: 'Sup 167',
                        parentId: 'sup168',
                    },
                    {
                        id: 'sup168',
                        ingName: 'sup 168',
                        productName: 'sup 168',
                        parentId: 'sup169',
                    },
                    {
                        id: 'sup169',
                        ingName: 'sup 169',
                        productName: 'sup 169',
                        parentId: 'sup170',
                    },
                    {
                        id: 'sup170',
                        ingName: 'sup 170',
                        productName: 'sup 170',
                        parentId: 'sup171',
                    },
                    {
                        id: 'sup171',
                        ingName: 'sup 171',
                        productName: 'sup 171',
                        parentId: 'sup172',
                    },
                    {
                        id: 'sup172',
                        ingName: 'sup 172',
                        productName: 'sup 172',
                        parentId: '1',
                    },

                    {
                        id: 'sup173',
                        ingName: 'sup 173',
                        productName: 'sup 173',
                        parentId: 'sup174',
                    },
                    {
                        id: 'sup174',
                        ingName: 'sup 174',
                        productName: 'sup 174',
                        parentId: 'sup175',
                    },
                    {
                        id: 'sup175',
                        ingName: 'sup 175',
                        productName: 'sup 175',
                        parentId: '1',
                    },
                    {
                        id: 'sup176',
                        ingName: 'Sup 176',
                        productName: 'Sup 176',
                        parentId: 'sup177',
                    },
                    {
                        id: 'sup177',
                        ingName: 'Sup 177',
                        productName: 'Sup 177',
                        parentId: 'sup178',
                    },
                    {
                        id: 'sup178',
                        ingName: 'sup 178',
                        productName: 'sup 178',
                        parentId: 'sup179',
                    },
                    {
                        id: 'sup179',
                        ingName: 'sup 179',
                        productName: 'sup 179',
                        parentId: 'sup180',
                    },
                    {
                        id: 'sup180',
                        ingName: 'sup 180',
                        productName: 'sup 180',
                        parentId: 'sup181',
                    },

                    {
                        id: 'sup181',
                        ingName: 'sup 181',
                        productName: 'sup 181',
                        parentId: 'sup182',
                    },
                    {
                        id: 'sup182',
                        ingName: 'sup 182',
                        productName: 'sup 182',
                        parentId: '1',
                    },

                    {
                        id: 'sup183',
                        ingName: 'sup 183',
                        productName: 'sup 183',
                        parentId: 'sup184',
                    },
                    {
                        id: 'sup184',
                        ingName: 'sup 184',
                        productName: 'sup 184',
                        parentId: 'sup185',
                    },
                    {
                        id: 'sup185',
                        ingName: 'sup 185',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup186',
                    },
                    {
                        id: 'sup186',
                        ingName: 'Sup 186',
                        productName: 'Sup 186',
                        parentId: '1',
                    },
                    {
                        id: 'sup187',
                        ingName: 'Sup 187',
                        productName: 'Sup 187',
                        parentId: 'sup188',
                    },
                    {
                        id: 'sup188',
                        ingName: 'sup 188',
                        productName: 'sup 188',
                        parentId: 'sup189',
                    },
                    {
                        id: 'sup189',
                        ingName: 'sup 189',
                        productName: 'sup 189',
                        parentId: 'sup190',
                    },
                    {
                        id: 'sup190',
                        ingName: 'sup 190',
                        productName: 'sup 190',
                        parentId: '1',
                    },
                    {
                        id: 'sup191',
                        ingName: 'sup 191',
                        productName: 'sup 191',
                        parentId: 'sup192',
                    },
                    {
                        id: 'sup192',
                        ingName: 'sup 192',
                        productName: 'sup 192',
                        parentId: 'sup193',
                    },

                    {
                        id: 'sup193',
                        ingName: 'sup 193',
                        productName: 'sup 193',
                        parentId: 'sup194',
                    },
                    {
                        id: 'sup194',
                        ingName: 'sup 194',
                        productName: 'sup 194',
                        parentId: '1',
                    },
                    {
                        id: 'sup195',
                        ingName: 'sup 195',
                        productName: 'Sup 195',
                        parentId: 'sup196',
                    },
                    {
                        id: 'sup196',
                        ingName: 'Sup 196',
                        productName: 'Sup 196',
                        parentId: 'sup197',
                    },
                    {
                        id: 'sup197',
                        ingName: 'Sup 197',
                        productName: 'Sup 197',
                        parentId: 'sup198',
                    },
                    {
                        id: 'sup198',
                        ingName: 'sup 198',
                        productName: 'sup 198',
                        parentId: '1',
                    },
                    {
                        id: 'sup199',
                        ingName: 'sup 199',
                        productName: 'sup 199',
                        parentId: 'sup200',
                    },
                    {
                        id: 'sup200',
                        ingName: 'sup 200',
                        productName: 'sup 200',
                        parentId: 'sup201',
                    },

                    {
                        id: 'sup201',
                        ingName: 'sup 201',
                        productName: 'sup 201',
                        parentId: 'sup202',
                    },
                    {
                        id: 'sup202',
                        ingName: 'sup 202',
                        productName: 'sup 202',
                        parentId: 'sup203',
                    },

                    {
                        id: 'sup203',
                        ingName: 'sup 203',
                        productName: 'sup 203',
                        parentId: '1',
                    },
                    {
                        id: 'sup204',
                        ingName: 'sup 204',
                        productName: 'sup 204',
                        parentId: 'sup205',
                    },
                    {
                        id: 'sup205',
                        ingName: 'sup 205',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup206',
                    },
                    {
                        id: 'sup206',
                        ingName: 'Sup 206',
                        productName: 'Sup 206',
                        parentId: 'sup207',
                    },
                    {
                        id: 'sup207',
                        ingName: 'Sup 207',
                        productName: 'Sup 207',
                        parentId: 'sup208',
                    },
                    {
                        id: 'sup208',
                        ingName: 'sup 208',
                        productName: 'sup 208',
                        parentId: '1',
                    },
                    {
                        id: 'sup209',
                        ingName: 'sup 209',
                        productName: 'sup 209',
                        parentId: 'sup210',
                    },
                    {
                        id: 'sup210',
                        ingName: 'sup 210',
                        productName: 'sup 210',
                        parentId: 'sup211',
                    },
                    {
                        id: 'sup211',
                        ingName: 'sup 211',
                        productName: 'sup 51',
                        parentId: 'sup212',
                    },
                    {
                        id: 'sup212',
                        ingName: 'sup 212',
                        productName: 'sup 52',
                        parentId: 'sup213',
                    },

                    {
                        id: 'sup213',
                        ingName: 'sup 213',
                        productName: 'sup 213',
                        parentId: 'sup214',
                    },
                    {
                        id: 'sup214',
                        ingName: 'sup 214',
                        productName: 'sup 214',
                        parentId: '1',
                    },
                    {
                        id: 'sup215',
                        ingName: 'sup 215',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup216',
                    },
                    {
                        id: 'sup216',
                        ingName: 'Sup 216',
                        productName: 'Sup 216',
                        parentId: 'sup217',
                    },
                    {
                        id: 'sup217',
                        ingName: 'Sup 217',
                        productName: 'Sup 217',
                        parentId: 'sup218',
                    },
                    {
                        id: 'sup218',
                        ingName: 'sup 218',
                        productName: 'sup 218',
                        parentId: 'sup219',
                    },
                    {
                        id: 'sup219',
                        ingName: 'sup 219',
                        productName: 'sup 59',
                        parentId: '1',
                    },
                    {
                        id: 'sup220',
                        ingName: 'sup 220',
                        productName: 'sup 220',
                        parentId: 'sup221',
                    },

                    {
                        id: 'sup221',
                        ingName: 'sup 221',
                        productName: 'sup 221',
                        parentId: 'sup222',
                    },
                    {
                        id: 'sup222',
                        ingName: 'sup 222',
                        productName: 'sup 222',
                        parentId: 'sup223',
                    },

                    {
                        id: 'sup223',
                        ingName: 'sup 223',
                        productName: 'sup 223',
                        parentId: 'sup224',
                    },
                    {
                        id: 'sup224',
                        ingName: 'sup 224',
                        productName: 'sup 224',
                        parentId: '1',
                    },
                    {
                        id: 'sup225',
                        ingName: 'sup 225',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup226',
                    },
                    {
                        id: 'sup226',
                        ingName: 'Sup 226',
                        productName: 'Sup 226',
                        parentId: 'sup227',
                    },
                    {
                        id: 'sup227',
                        ingName: 'Sup 227',
                        productName: 'Sup 227',
                        parentId: 'sup228',
                    },
                    {
                        id: 'sup228',
                        ingName: 'sup 228',
                        productName: 'sup 228',
                        parentId: '1',
                    },
                    {
                        id: 'sup229',
                        ingName: 'sup 229',
                        productName: 'sup 229',
                        parentId: 'sup230',
                    },
                    {
                        id: 'sup230',
                        ingName: 'sup 230',
                        productName: 'sup 230',
                        parentId: 'sup231',
                    },
                    {
                        id: 'sup231',
                        ingName: 'sup 231',
                        productName: 'sup 51',
                        parentId: 'sup232',
                    },
                    {
                        id: 'sup232',
                        ingName: 'sup 232',
                        productName: 'sup 52',
                        parentId: 'sup233',
                    },

                    {
                        id: 'sup233',
                        ingName: 'sup 233',
                        productName: 'sup 233',
                        parentId: '1',
                    },
                    {
                        id: 'sup234',
                        ingName: 'sup 234',
                        productName: 'sup 234',
                        parentId: 'sup235',
                    },
                    {
                        id: 'sup235',
                        ingName: 'sup 235',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup236',
                    },
                    {
                        id: 'sup236',
                        ingName: 'Sup 236',
                        productName: 'Sup 236',
                        parentId: 'sup237',
                    },
                    {
                        id: 'sup237',
                        ingName: 'Sup 237',
                        productName: 'Sup 237',
                        parentId: 'sup238',
                    },
                    {
                        id: 'sup238',
                        ingName: 'sup 238',
                        productName: 'sup 238',
                        parentId: '1',
                    },
                    {
                        id: 'sup239',
                        ingName: 'sup 239',
                        productName: 'sup 59',
                        parentId: 'sup240',
                    },
                    {
                        id: 'sup240',
                        ingName: 'sup 240',
                        productName: 'sup 240',
                        parentId: 'sup241',
                    },

                    {
                        id: 'sup241',
                        ingName: 'sup 241',
                        productName: 'sup 241',
                        parentId: 'sup242',
                    },
                    {
                        id: 'sup242',
                        ingName: 'sup 242',
                        productName: 'sup 242',
                        parentId: 'sup243',
                    },

                    {
                        id: 'sup243',
                        ingName: 'sup 243',
                        productName: 'sup 243',
                        parentId: 'sup244',
                    },
                    {
                        id: 'sup244',
                        ingName: 'sup 244',
                        productName: 'sup 244',
                        parentId: '1',
                    },
                    {
                        id: 'sup245',
                        ingName: 'sup 245',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup246',
                    },
                    {
                        id: 'sup246',
                        ingName: 'Sup 246',
                        productName: 'Sup 246',
                        parentId: 'sup247',
                    },
                    {
                        id: 'sup247',
                        ingName: 'Sup 247',
                        productName: 'Sup 247',
                        parentId: '1',
                    },
                    {
                        id: 'sup248',
                        ingName: 'sup 248',
                        productName: 'sup 248',
                        parentId: 'sup249',
                    },
                    {
                        id: 'sup249',
                        ingName: 'sup 249',
                        productName: 'sup 249',
                        parentId: 'sup250',
                    },
                    {
                        id: 'sup250',
                        ingName: 'sup 250',
                        productName: 'sup 250',
                        parentId: 'sup251',
                    },
                    {
                        id: 'sup251',
                        ingName: 'sup 251',
                        productName: 'sup 251',
                        parentId: 'sup252',
                    },
                    {
                        id: 'sup252',
                        ingName: 'sup 252',
                        productName: 'sup 252',
                        parentId: '1',
                    },

                    {
                        id: 'sup253',
                        ingName: 'sup 253',
                        productName: 'sup 253',
                        parentId: 'sup254',
                    },
                    {
                        id: 'sup254',
                        ingName: 'sup 254',
                        productName: 'sup 254',
                        parentId: 'sup255',
                    },
                    {
                        id: 'sup255',
                        ingName: 'sup 255',
                        productName: 'sup 255',
                        parentId: 'sup256',
                    },
                    {
                        id: 'sup256',
                        ingName: 'Sup 256',
                        productName: 'Sup 256',
                        parentId: 'sup257',
                    },
                    {
                        id: 'sup257',
                        ingName: 'Sup 257',
                        productName: 'Sup 257',
                        parentId: '1',
                    },
                    {
                        id: 'sup258',
                        ingName: 'sup 258',
                        productName: 'sup 258',
                        parentId: 'sup259',
                    },
                    {
                        id: 'sup259',
                        ingName: 'sup 259',
                        productName: 'sup 59',
                        parentId: '1',
                    },
                    {
                        id: 'sup260',
                        ingName: 'sup 260',
                        productName: 'sup 260',
                        parentId: 'sup261',
                    },
                    {
                        id: 'sup261',
                        ingName: 'sup 261',
                        productName: 'sup 261',
                        parentId: 'sup262',
                    },
                    {
                        id: 'sup262',
                        ingName: 'sup 262',
                        productName: 'sup 262',
                        parentId: '1',
                    },
                    {
                        id: 'sup263',
                        ingName: 'sup 263',
                        productName: 'sup 263',
                        parentId: 'sup264',
                    },
                    {
                        id: 'sup264',
                        ingName: 'sup 264',
                        productName: 'sup 264',
                        parentId: 'sup265',
                    },
                    {
                        id: 'sup265',
                        ingName: 'sup 265',
                        productName: 'sup 265',
                        parentId: '1',
                    },
                    {
                        id: 'sup266',
                        ingName: 'sup 266',
                        productName: 'sup 266',
                        parentId: 'sup267',
                    },
                    {
                        id: 'sup267',
                        ingName: 'sup 267',
                        productName: 'sup 267',
                        parentId: 'sup268',
                    },
                    {
                        id: 'sup268',
                        ingName: 'sup 268',
                        productName: 'sup 268',
                        parentId: '1',
                    },
                    {
                        id: 'sup269',
                        ingName: 'sup 269',
                        productName: 'sup 269',
                        parentId: 'sup270',
                    },
                    {
                        id: 'sup270',
                        ingName: 'sup 270',
                        productName: 'sup 270',
                        parentId: 'sup271',
                    },
                    {
                        id: 'sup271',
                        ingName: 'sup 271',
                        productName: 'sup 271',
                        parentId: 'sup272',
                    },
                    {
                        id: 'sup272',
                        ingName: 'sup 272',
                        productName: 'sup 272',
                        parentId: '1',
                    },
                    {
                        id: 'sup273',
                        ingName: 'sup 273',
                        productName: 'sup 273',
                        parentId: 'sup274',
                    },
                    {
                        id: 'sup274',
                        ingName: 'sup 274',
                        productName: 'sup 274',
                        parentId: '1',
                    },
                    {
                        id: 'sup275',
                        ingName: 'sup 275',
                        productName: 'sup 275',
                        parentId: 'sup276',
                    },
                    {
                        id: 'sup276',
                        ingName: 'sup 276',
                        productName: 'sup 276',
                        parentId: 'sup277',
                    },
                    {
                        id: 'sup277',
                        ingName: 'sup 277',
                        productName: 'sup 277',
                        parentId: 'sup278',
                    },
                    {
                        id: 'sup278',
                        ingName: 'sup 278',
                        productName: 'sup 278',
                        parentId: '1',
                    },
                ],
                produce: [
                    {
                        id: 'produce1',
                        productName: 'Wheat',
                        parentId: 'sup51',
                    },
                    {
                        id: 'produce2',
                        productName: 'Yeast',
                        parentId: 'sup55',
                    },
                    {
                        id: 'produce3',
                        productName: 'Sugar Cane',
                        parentId: 'sup60',
                    },
                    {
                        id: 'produce4',
                        productName: 'unknown',
                        parentId: 'sup63',
                    },
                    {
                        id: 'produce5',
                        productName: 'Yeast',
                        parentId: 'sup68',
                    },
                    {
                        id: 'produce6',
                        productName: 'Sugar beets',
                        parentId: 'sup73',
                    },
                    {
                        id: 'produce7',
                        productName: 'Sugar beets',
                        parentId: 'sup80',
                    },
                    {
                        id: 'produce8',
                        productName: 'Sugar beets',
                        parentId: 'sup87',
                    },
                    {
                        id: 'produce9',
                        productName: 'Sugar beets',
                        parentId: 'sup92',
                    },
                    {
                        id: 'produce10',
                        productName: 'Sugar beets',
                        parentId: 'sup98',
                    },
                    {
                        id: 'produce11',
                        productName: 'Wheat',
                        parentId: 'sup102',
                    },
                    {
                        id: 'produce12',
                        productName: 'Yeast',
                        parentId: 'sup106',
                    },
                    {
                        id: 'produce13',
                        productName: 'Sugar Cane',
                        parentId: 'sup112',
                    },
                    {
                        id: 'produce14',
                        productName: 'unknown',
                        parentId: 'sup116',
                    },
                    {
                        id: 'produce15',
                        productName: 'Yeast',
                        parentId: 'sup121',
                    },
                    {
                        id: 'produce16',
                        productName: 'Sugar beets',
                        parentId: 'sup128',
                    },
                    {
                        id: 'produce17',
                        productName: 'Sugar beets',
                        parentId: 'sup133',
                    },
                    {
                        id: 'produce18',
                        productName: 'Sugar beets',
                        parentId: 'sup140',
                    },
                    {
                        id: 'produce19',
                        productName: 'Sugar beets',
                        parentId: 'sup145',
                    },
                    {
                        id: 'produce20',
                        productName: 'Sugar beets',
                        parentId: 'sup148',
                    },
                    {
                        id: 'produce21',
                        productName: 'Wheat',
                        parentId: 'sup156',
                    },
                    {
                        id: 'produce22',
                        productName: 'Yeast',
                        parentId: 'sup158',
                    },
                    {
                        id: 'produce23',
                        productName: 'Sugar Cane',
                        parentId: 'sup159',
                    },
                    {
                        id: 'produce24',
                        productName: 'unknown',
                        parentId: 'sup167',
                    },
                    {
                        id: 'produce25',
                        productName: 'Yeast',
                        parentId: 'sup173',
                    },
                    {
                        id: 'produce26',
                        productName: 'Sugar beets',
                        parentId: 'sup176',
                    },
                    {
                        id: 'produce27',
                        productName: 'Sugar beets',
                        parentId: 'sup183',
                    },
                    {
                        id: 'produce28',
                        productName: 'Sugar beets',
                        parentId: 'sup187',
                    },
                    {
                        id: 'produce29',
                        productName: 'Sugar beets',
                        parentId: 'sup191',
                    },
                    {
                        id: 'produce30',
                        productName: 'Sugar beets',
                        parentId: 'sup195',
                    },
                    {
                        id: 'produce31',
                        productName: 'Wheat',
                        parentId: 'sup199',
                    },
                    {
                        id: 'produce32',
                        productName: 'Yeast',
                        parentId: 'sup204',
                    },
                    {
                        id: 'produce33',
                        productName: 'Sugar Cane',
                        parentId: 'sup209',
                    },
                    {
                        id: 'produce34',
                        productName: 'unknown',
                        parentId: 'sup215',
                    },
                    {
                        id: 'produce35',
                        productName: 'Yeast',
                        parentId: 'sup220',
                    },
                    {
                        id: 'produce36',
                        productName: 'Sugar beets',
                        parentId: 'sup225',
                    },
                    {
                        id: 'produce37',
                        productName: 'Sugar beets',
                        parentId: 'sup229',
                    },
                    {
                        id: 'produce38',
                        productName: 'Sugar beets',
                        parentId: 'sup234',
                    },
                    {
                        id: 'produce39',
                        productName: 'Sugar beets',
                        parentId: 'sup239',
                    },
                    {
                        id: 'produce40',
                        productName: 'Sugar beets',
                        parentId: 'sup245',
                    },
                    {
                        id: 'produce41',
                        productName: 'Wheat',
                        parentId: 'sup248',
                    },
                    {
                        id: 'produce42',
                        productName: 'Yeast',
                        parentId: 'sup253',
                    },
                    {
                        id: 'produce43',
                        productName: 'Sugar Cane',
                        parentId: 'sup258',
                    },
                    {
                        id: 'produce44',
                        productName: 'unknown',
                        parentId: 'sup260',
                    },
                    {
                        id: 'produce45',
                        productName: 'Yeast',
                        parentId: 'sup263',
                    },
                    {
                        id: 'produce46',
                        productName: 'Sugar beets',
                        parentId: 'sup266',
                    },
                    {
                        id: 'produce47',
                        productName: 'Sugar beets',
                        parentId: 'sup269',
                    },
                    {
                        id: 'produce48',
                        productName: 'Sugar beets',
                        parentId: 'sup273',
                    },
                    {
                        id: 'produce49',
                        productName: 'Sugar beets',
                        parentId: 'sup275',
                    },
                ],
            },
        ],
    },
    // data5: missing produce nodes
    data5: {
        product: {
            id: 'd50b399b-69e6-4ab3-9b1d-08d963a2ed3d',
            nodeId: '127f696e-3386-4665-b933-5c6fa1ca5a32',
            parentId: '00000000-0000-0000-0000-000000000000',
            name: 'Burger Bread',
            isSpecialIngredient: false,
            supplierId: null,
            supplierName: null,
            companyName: 'Alauddin Co',
            companyId: '60473540-1a80-42b8-a572-f50955523ab7',
            rootParentId: '00000000-0000-0000-0000-000000000000',
            isProduce: false,
            saState: 'Certified',
            saType: 'Product',
            ecoImpacts: [
                {
                    code: 'EIID001',
                    name: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
                    scientificTerm: null,
                    measureUnit: 'g',
                    impactScore: 3885.0,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 3885.0,
                    per100g: 388.5,
                    perServing: 388.5,
                    perProduct: 582.75,
                },
                {
                    code: 'EIID002',
                    name: 'Water Scarcity (eq)',
                    scientificTerm: null,
                    measureUnit: 'L',
                    impactScore: 792.7,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 792.7,
                    per100g: 79.27,
                    perServing: 79.27,
                    perProduct: 118.905,
                },
                {
                    code: 'EIID003',
                    name: 'Eutrophication (PO43-)',
                    scientificTerm: null,
                    measureUnit: 'g',
                    impactScore: 12.0,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 12.0,
                    per100g: 1.2,
                    perServing: 1.2,
                    perProduct: 1.8,
                },
                {
                    code: 'EIID004',
                    name: 'Biodiversity (sp.yr10<sup>14</sup>)',
                    scientificTerm: null,
                    measureUnit: '',
                    impactScore: 1.2,
                    contributionPercentage: 0.0,
                    stage: null,
                    perKg: 1.2,
                    per100g: 0.12,
                    perServing: 0.12,
                    perProduct: 0.18,
                },
            ],
            state: 'certified',
            productDetails: 'complete',
            ingredientDetails: 'incomplete',
            processingDetails: 'complete',
            packagingDetails: 'incomplete',
            retailDetails: null,
            storageDetails: 'incomplete',
            ingredientsRequired: true,
            processingRequired: true,
            packagingRequired: true,
            retailRequired: false,
            storageRequired: true,
            quantity: null,
            measureUnit: 'g',
            originCountryCode: 'IND',
            ecoImpactMetascore: 'A',
            productMetadata: null,
            site: {
                id: 'aad2dd77-23e8-48bb-7f29-08d963a11e8a',
                name: 'Alan Co India',
                displayId: null,
                siteId: '',
                companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                companyName: 'Alauddin Co',
                addressId: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                address: {
                    id: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                    streetAddress: 'Naraina Industrial Area',
                    city: 'New Delhi',
                    pinCode: null,
                    state: 'Delhi',
                    country: 'India',
                    countryCode: 'IND',
                    countryCodeISOAlpha2: 'IN',
                    countryCodeISOAlpha3: 'IND',
                    countryId: 104,
                    createdBy: null,
                    createdDate: '0001-01-01T00:00:00+00:00',
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00+00:00',
                    isArchived: false,
                    isFullStreetAddress: true,
                    latitude: '28.64616',
                    longitude: '77.14768',
                },
                linkedCount: 2,
                createdBy: 'admin@mondra.com',
                createdDate: '2021-08-20T06:09:50.2910254+00:00',
                updatedBy: 'admin@mondra.com',
                updatedDate: '2021-08-20T06:09:50.2910199+00:00',
                totalProductsProductionInKg: 12.0,
                isArchived: false,
            },
            transport: [
                {
                    id: '0d33534e-592a-424c-72ea-08d97cecb76b',
                    impactId: null,
                    linkedProductId: 'd50b399b-69e6-4ab3-9b1d-08d963a2ed3d',
                    transportProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                    calcId: null,
                    sourceSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                    destinationSiteId: 'aad2dd77-23e8-48bb-7f29-08d963a11e8a',
                    createdBy: 'admin@mondra.com',
                    createdDate: '2021-09-21T10:46:20.353616+00:00',
                    updatedBy: 'system',
                    updatedDate: '2022-03-17T13:55:51.835807+00:00',
                    isArchived: false,
                    transportingProduct: true,
                    noTransportReason: '',
                    transportLegs: [
                        {
                            id: 'aff5be4a-8bcc-4b3b-c6f2-08d97ced0c36',
                            transportMode: null,
                            distance: 2501.0,
                            distanceUnit: 'KM',
                            impactId: null,
                            countryId: null,
                            transportActivityId: '0d33534e-592a-424c-72ea-08d97cecb76b',
                            salesActivityId: null,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-09-21T10:46:20.3536432+00:00',
                            updatedBy: 'system',
                            updatedDate: '2022-03-17T13:55:51.8360139+00:00',
                            isArchived: false,
                        },
                    ],
                },
            ],
            suppliers: [],
            produce: [],
        },
        processing: null,
        packaging: null,
        storage: null,
        retail: null,
        ingredients: [
            {
                id: '46264159-729f-4073-8a61-08d963a2b8b4',
                nodeId: '31158c2e-9152-428f-b20b-5517240b5406',
                parentId: '127f696e-3386-4665-b933-5c6fa1ca5a32',
                name: 'White Bread Collection',
                isSpecialIngredient: false,
                supplierId: null,
                supplierName: null,
                companyName: 'Alauddin Co',
                companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                rootParentId: '31158c2e-9152-428f-b20b-5517240b5406',
                isProduce: false,
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [
                    {
                        id: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                        nodeId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        parentId: '31158c2e-9152-428f-b20b-5517240b5406',
                        name: 'White Bread',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Alauddin Co',
                        companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                        rootParentId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: false,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                            name: 'Tom test',
                            displayId: null,
                            siteId: '0001Tom',
                            companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                            companyName: 'Able & Cole Ltd.',
                            addressId: '8bdaf111-3da5-4824-3f21-08d9835608bd',
                            address: {
                                id: '8bdaf111-3da5-4824-3f21-08d9835608bd',
                                streetAddress: 'New Cross',
                                city: 'London',
                                pinCode: null,
                                state: 'ENG',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '51.47357',
                                longitude: '-0.02924',
                            },
                            linkedCount: 1,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-09-29T14:32:57.1663548+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-09-29T14:32:57.1604005+00:00',
                            totalProductsProductionInKg: 0.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                impactId: null,
                                linkedProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                                transportProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                calcId: null,
                                sourceSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                destinationSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-09-21T10:43:58.0357592+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-21T11:15:43.2659666+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: 'Not required',
                                transportLegs: [
                                    {
                                        id: 'e64c661b-97cb-4c7a-e896-08d98d4e0510',
                                        transportMode: null,
                                        distance: 200.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 4,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-10-12T07:00:48.1223284+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.269569+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: '16401049-bfde-46d8-0e5d-08d9b3c7fa9d',
                                        transportMode: null,
                                        distance: 12.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 46,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-11-30T07:48:55.0930925+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.2695739+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: '2fc402a0-6504-4738-8da4-08d9c4733aa3',
                                        transportMode: null,
                                        distance: 3500.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '7d7d3c78-b231-4505-72e9-08d97cecb76b',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-21T11:15:43.2695752+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-21T11:15:43.2695746+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                            {
                                id: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                impactId: null,
                                linkedProductId: 'c711b67a-b47a-4808-9b1c-08d963a2ed3d',
                                transportProductId: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '82e3f813-b1a2-43c0-b035-08d9835607f9',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-11-30T06:09:33.2247386+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-01T04:29:19.1923301+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: '58cb09ae-d47d-4efa-0e5c-08d9b3c7fa9d',
                                        transportMode: null,
                                        distance: 300.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 235,
                                        transportActivityId: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-11-30T06:09:33.2319109+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-01T04:29:19.1956408+00:00',
                                        isArchived: false,
                                    },
                                    {
                                        id: 'e14f3bc4-92fd-4884-00ed-08d9b483244b',
                                        transportMode: null,
                                        distance: 10.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '62728246-524e-47d7-d0da-08d9b3c7fa93',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-01T04:29:19.1956456+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-01T04:29:19.195645+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                        nodeId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        parentId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        name: 'Processing: Wheat Flour',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Able & Cole Ltd.',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'complete',
                        packagingDetails: 'complete',
                        retailDetails: null,
                        storageDetails: 'complete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                            name: 'Alan Co',
                            displayId: null,
                            siteId: null,
                            companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                            companyName: 'Alauddin Co',
                            addressId: '5b36f693-a41c-4230-f85a-08d93ab82268',
                            address: {
                                id: '5b36f693-a41c-4230-f85a-08d93ab82268',
                                streetAddress: '123, Abby Road',
                                city: 'Grimsby',
                                pinCode: 'DN396YW',
                                state: 'Lincolnshire',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: 'admin@mondra.com',
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: false,
                                latitude: null,
                                longitude: null,
                            },
                            linkedCount: 1,
                            createdBy: null,
                            createdDate: '2021-06-29T05:14:48.7408551+00:00',
                            updatedBy: null,
                            updatedDate: '0001-01-01T00:00:00+00:00',
                            totalProductsProductionInKg: 120.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '285ed2e1-0a23-449e-4c08-08d9ba6c4c8c',
                                impactId: null,
                                linkedProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                transportProductId: '9072cd5f-ea31-40e2-2528-08d9613b5764',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-12-08T17:00:55.3120786+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-08T17:00:55.3129492+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: 'a5ac60aa-57f6-41e1-614f-08d9ba6c4c8d',
                                        transportMode: null,
                                        distance: 2113.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 2,
                                        transportActivityId: '285ed2e1-0a23-449e-4c08-08d9ba6c4c8c',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2021-12-08T17:00:55.3129966+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2021-12-08T17:00:55.3129977+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                            {
                                id: '84f123a7-7d7a-4a77-4c09-08d9ba6c4c8c',
                                impactId: null,
                                linkedProductId: '356a0335-d0cd-4e03-dffe-08d963a02fcf',
                                transportProductId: 'ed3bb902-7c76-4330-3301-08d962147fac',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: '0c0a24c2-a460-451a-b8ec-08d93ab82256',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2021-12-08T17:02:02.2995413+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2021-12-08T17:02:02.299564+00:00',
                                isArchived: false,
                                transportingProduct: false,
                                noTransportReason: '',
                                transportLegs: [],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '99d4e5c2-9204-490d-c531-08d9b4c3d67c',
                        nodeId: '5376aed2-df0c-4836-b288-aa3b21da6486',
                        parentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        name: 'Test Unknown Product',
                        isSpecialIngredient: false,
                        supplierId: '5ed1496c-891c-4a90-96d1-060d91c59a57',
                        supplierName: 'Unknown company',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'IND',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: 'aad2dd77-23e8-48bb-7f29-08d963a11e8a',
                            name: 'Alan Co India',
                            displayId: null,
                            siteId: '',
                            companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                            companyName: 'Alauddin Co',
                            addressId: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                            address: {
                                id: '739c6b35-2fb4-4d7a-9b07-08d963a11e8a',
                                streetAddress: 'Naraina Industrial Area',
                                city: 'New Delhi',
                                pinCode: null,
                                state: 'Delhi',
                                country: 'India',
                                countryCode: 'IND',
                                countryCodeISOAlpha2: 'IN',
                                countryCodeISOAlpha3: 'IND',
                                countryId: 104,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '28.64616',
                                longitude: '77.14768',
                            },
                            linkedCount: 2,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-08-20T06:09:50.2910254+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-08-20T06:09:50.2910199+00:00',
                            totalProductsProductionInKg: 12.0,
                            isArchived: false,
                        },
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '3d9b5354-b7d0-4886-c532-08d9b4c3d67c',
                        nodeId: '215423d2-4a9f-42ad-9b82-861e8ee4f16d',
                        parentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        name: 'Test Unknown Product 2',
                        isSpecialIngredient: false,
                        supplierId: '5ed1496c-891c-4a90-96d1-060d91c59a57',
                        supplierName: 'Unknown company',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        isProduce: false,
                        saState: null,
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'complete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: null,
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                        nodeId: '65433aae-1aee-429e-a1b7-10de0907bed2',
                        parentId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        name: 'White Wheat Flour',
                        isSpecialIngredient: false,
                        supplierId: null,
                        supplierName: null,
                        companyName: 'Able & Cole Ltd.',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: '65433aae-1aee-429e-a1b7-10de0907bed2',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Product',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'complete',
                        ingredientDetails: 'complete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: 'g',
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: {
                            id: 'c0c2f011-8c38-4dac-090c-08d9621bf6cb',
                            name: 'All Grains Bakery Warehouse',
                            displayId: null,
                            siteId: 'AGB-12',
                            companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                            companyName: 'All Grains Bakery',
                            addressId: 'db641285-63c9-4782-2566-08d9621bf6cb',
                            address: {
                                id: 'db641285-63c9-4782-2566-08d9621bf6cb',
                                streetAddress: '',
                                city: 'London',
                                pinCode: null,
                                state: 'ENG',
                                country: 'United Kingdom of Great Britain and Northern Ireland',
                                countryCode: 'GBR',
                                countryCodeISOAlpha2: 'GB',
                                countryCodeISOAlpha3: 'GBR',
                                countryId: 235,
                                createdBy: null,
                                createdDate: '0001-01-01T00:00:00+00:00',
                                updatedBy: null,
                                updatedDate: '0001-01-01T00:00:00+00:00',
                                isArchived: false,
                                isFullStreetAddress: true,
                                latitude: '51.50015',
                                longitude: '-0.12624',
                            },
                            linkedCount: 1,
                            createdBy: 'admin@mondra.com',
                            createdDate: '2021-08-18T07:44:09.3773019+00:00',
                            updatedBy: 'admin@mondra.com',
                            updatedDate: '2021-08-18T07:44:09.377293+00:00',
                            totalProductsProductionInKg: 0.0,
                            isArchived: false,
                        },
                        transport: [
                            {
                                id: '7bfa26b8-e9b1-429f-a95e-08d9d021a6e0',
                                impactId: null,
                                linkedProductId: 'a13ad450-1f97-42e4-9b1b-08d963a2ed3d',
                                transportProductId: '48570acd-2a7c-4631-e49b-08d9621aebe1',
                                calcId: null,
                                sourceSiteId: null,
                                destinationSiteId: 'c0c2f011-8c38-4dac-090c-08d9621bf6cb',
                                createdBy: 'admin@mondra.com',
                                createdDate: '2022-01-05T08:02:00.1459849+00:00',
                                updatedBy: 'admin@mondra.com',
                                updatedDate: '2022-01-05T08:02:00.1461911+00:00',
                                isArchived: false,
                                transportingProduct: true,
                                noTransportReason: '',
                                transportLegs: [
                                    {
                                        id: 'db068f4d-9190-4803-6166-08d9d021a6e6',
                                        transportMode: null,
                                        distance: 134253.0,
                                        distanceUnit: 'KM',
                                        impactId: null,
                                        countryId: 3,
                                        transportActivityId: '7bfa26b8-e9b1-429f-a95e-08d9d021a6e0',
                                        salesActivityId: null,
                                        createdBy: 'admin@mondra.com',
                                        createdDate: '2022-01-05T08:02:00.1462216+00:00',
                                        updatedBy: 'admin@mondra.com',
                                        updatedDate: '2022-01-05T08:02:00.1462226+00:00',
                                        isArchived: false,
                                    },
                                ],
                            },
                        ],
                        suppliers: [],
                        produce: [],
                    },
                ],
                produce: [
                    {
                        id: 'ed3bb902-7c76-4330-3301-08d962147fac',
                        nodeId: '3fdc2e5b-d491-47df-b964-6ddddca3a2da',
                        parentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        name: 'Fresh Wheat',
                        isSpecialIngredient: false,
                        supplierId: '14632f1a-5d93-46d3-80d6-3c5538782f51',
                        supplierName: 'Grain supplies EU',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        isProduce: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: true,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'ATG',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '9072cd5f-ea31-40e2-2528-08d9613b5764',
                        nodeId: 'fb11cf62-f535-4869-8cbe-727576de1fa0',
                        parentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        name: 'Wheat',
                        isSpecialIngredient: false,
                        supplierId: 'c240270b-7ca7-4889-84d9-9253da1ebe2e',
                        supplierName: 'All Grains Bakery',
                        companyName: 'Alauddin Co',
                        companyId: '60473540-1a80-42b8-a572-f50955523ab7',
                        rootParentId: '511b212b-66ed-40a0-a9bf-173ccc4976f9',
                        isProduce: false,
                        saState: 'Certified',
                        saType: 'Produce',
                        ecoImpacts: [
                            {
                                code: 'EIID001',
                                name: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
                                scientificTerm: null,
                                measureUnit: 'g',
                                impactScore: 2185.2,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 2185.2,
                                per100g: 218.52,
                                perServing: 0.0,
                                perProduct: 2185.2,
                            },
                            {
                                code: 'EIID002',
                                name: 'Water Scarcity (eq)',
                                scientificTerm: null,
                                measureUnit: 'L',
                                impactScore: 695.2,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 695.2,
                                per100g: 69.52,
                                perServing: 0.0,
                                perProduct: 695.2,
                            },
                            {
                                code: 'EIID003',
                                name: 'Eutrophication (PO43-)',
                                scientificTerm: null,
                                measureUnit: 'g',
                                impactScore: 5.6,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 5.6,
                                per100g: 0.56,
                                perServing: 0.0,
                                perProduct: 5.6,
                            },
                            {
                                code: 'EIID004',
                                name: 'Biodiversity (sp.yr10<sup>14</sup>)',
                                scientificTerm: null,
                                measureUnit: '',
                                impactScore: 0.1,
                                contributionPercentage: 0.0,
                                stage: null,
                                perKg: 0.1,
                                per100g: 0.01,
                                perServing: 0.0,
                                perProduct: 0.1,
                            },
                        ],
                        state: 'certified',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: 'A',
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '48570acd-2a7c-4631-e49b-08d9621aebe1',
                        nodeId: '20f3cca4-2844-44ca-b725-30bdab7a2828',
                        parentId: '65433aae-1aee-429e-a1b7-10de0907bed2',
                        name: 'Fresh Wheat',
                        isSpecialIngredient: false,
                        supplierId: '9d5ba423-6bde-43e9-a962-8c1b1893f7b2',
                        supplierName: 'Food engineers Ltd. (transferred from Sandwich Co.)',
                        companyName: 'All Grains Bakery',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: '65433aae-1aee-429e-a1b7-10de0907bed2',
                        isProduce: false,
                        canRemove: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '2e80348d-5013-41ae-e49a-08d9621aebe1',
                        nodeId: '3af80531-e7fb-4879-b527-e27b3fac4cbb',
                        parentId: '65433aae-1aee-429e-a1b7-10de0907bed2',
                        name: 'Wheat Grain',
                        isSpecialIngredient: false,
                        supplierId: 'b2eecc82-9a1d-412c-a3c4-bbee74126eeb',
                        supplierName: 'Baking supplies Ltd. (transferred from Sandwich Co.)',
                        companyName: 'All Grains Bakery',
                        companyId: 'b6c77d5b-4e5f-494a-aac4-afc268082729',
                        rootParentId: '65433aae-1aee-429e-a1b7-10de0907bed2',
                        isProduce: false,
                        canRemove: false,
                        saState: null,
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'blank',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: true,
                        packagingRequired: true,
                        retailRequired: false,
                        storageRequired: true,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: 'c9db16f9-0e3a-4e7e-a511-08da18765e3f',
                        nodeId: '944d8b01-4db9-413b-aebe-563339d38e8c',
                        parentId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        name: 'Roots',
                        isSpecialIngredient: false,
                        supplierId: '5ed1496c-891c-4a90-96d1-060d91c59a57',
                        supplierName: 'Unknown company',
                        companyName: 'Able & Cole Ltd.',
                        companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                        rootParentId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                    {
                        id: '996c4092-6f5a-425d-a512-08da18765e3f',
                        nodeId: '884afebc-fab5-487a-a1bd-84a506074486',
                        parentId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        name: 'Patato',
                        isSpecialIngredient: false,
                        supplierId: '5ed1496c-891c-4a90-96d1-060d91c59a57',
                        supplierName: 'Unknown company',
                        companyName: 'Able & Cole Ltd.',
                        companyId: 'fcf5c2de-073a-4586-8078-8948199c0b08',
                        rootParentId: '75269d7a-9a93-46af-a1c1-479ace96712d',
                        isProduce: false,
                        canRemove: false,
                        saState: 'Draft',
                        saType: 'Produce',
                        ecoImpacts: [],
                        state: 'review',
                        productDetails: 'incomplete',
                        ingredientDetails: 'incomplete',
                        processingDetails: 'incomplete',
                        packagingDetails: 'incomplete',
                        retailDetails: null,
                        storageDetails: 'incomplete',
                        ingredientsRequired: false,
                        processingRequired: false,
                        packagingRequired: false,
                        retailRequired: false,
                        storageRequired: false,
                        quantity: null,
                        measureUnit: null,
                        originCountryCode: 'GBR',
                        ecoImpactMetascore: null,
                        productMetadata: null,
                        site: null,
                        transport: [],
                        suppliers: [],
                        produce: [],
                    },
                ],
            },
            {
                id: 'd64b9c4e-254d-4046-6b0b-08d9a02663e7',
                nodeId: 'c1a6dcd3-954b-4a85-bcfb-2135c163c2a8',
                parentId: '127f696e-3386-4665-b933-5c6fa1ca5a32',
                name: 'Water',
                isSpecialIngredient: true,
                supplierId: null,
                supplierName: null,
                companyName: null,
                companyId: null,
                rootParentId: 'c1a6dcd3-954b-4a85-bcfb-2135c163c2a8',
                isProduce: false,
                canRemove: false,
                saState: null,
                saType: 'Product',
                ecoImpacts: null,
                state: 'blank',
                productDetails: null,
                ingredientDetails: null,
                processingDetails: null,
                packagingDetails: null,
                retailDetails: null,
                storageDetails: null,
                ingredientsRequired: false,
                processingRequired: false,
                packagingRequired: false,
                retailRequired: false,
                storageRequired: false,
                quantity: null,
                measureUnit: null,
                originCountryCode: null,
                ecoImpactMetascore: null,
                productMetadata: null,
                site: null,
                transport: null,
                suppliers: [],
                produce: [],
            },
        ],
    },
    data6: {
        product: {
            productName: 'chicken salad Sandwitch',
            quantity: '160g',
            id: 'product',
        },
        processing: {
            id: 'proceesing',
            title: 'Processing',
        },
        packaging: {
            id: 'packaging',
            title: 'Packaging',
        },
        storage: {
            id: 'storage',
            title: 'Storage',
        },
        retail: {
            id: 'retail',
            title: 'Retail',
        },
        ingredients: [
            {
                productName: 'White Bread',
                category: 'Bread',
                id: '1',
                suppliers: [
                    {
                        id: 'sup1',
                        ingName: 'White Bread',
                        productName: 'Baker Co',
                        parentId: '1',
                    },
                    {
                        id: 'sup2',
                        ingName: 'White Bread',
                        productName: 'BreadsNI Ltd',
                        parentId: '1',
                    },
                    {
                        id: 'sup3',
                        ingName: 'Wheat flour',
                        productName: 'Millers Ltd',
                        parentId: 'sup1',
                    },
                    {
                        id: 'sup4',
                        ingName: 'Wheat',
                        productName: 'Grain Aggregator Ltd',
                        parentId: 'sup3',
                    },
                    {
                        id: 'sup5',
                        ingName: 'Wheat flour',
                        productName: 'BreadsNI Ltd',
                        parentId: 'sup2',
                    },
                    {
                        id: 'sup6',
                        ingName: 'Yeast',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup2',
                    },
                    {
                        id: 'sup17',
                        ingName: 'Yeast Sub',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup6',
                    },
                    {
                        id: 'sup18',
                        ingName: 'Yeast1 Sub',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup6',
                    },
                    {
                        id: 'sup19',
                        ingName: 'Yeast Sub - Sub',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup18',
                    },
                    {
                        id: 'sup20',
                        ingName: 'Yeast Tier 5',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup21',
                    },
                    {
                        id: 'sup21',
                        ingName: 'Yeast Tier 4',
                        productName: 'BakingSupplies Inc',
                        parentId: 'sup18',
                    },
                    {
                        id: 'sup7',
                        ingName: 'Sugar',
                        productName: 'Sweetdreams Ltd',
                        parentId: 'sup2',
                    },
                    {
                        id: 'sup8',
                        ingName: 'Sugar',
                        productName: 'Honeypot Inc',
                        parentId: 'sup7',
                    },
                    {
                        id: 'sup9',
                        ingName: 'Unknown',
                        productName: 'unknown',
                        parentId: 'sup1',
                    },
                    {
                        id: 'sup10',
                        ingName: 'Unknown',
                        productName: 'unknown',
                        parentId: 'sup1',
                    },
                ],
                produce: [
                    {
                        id: 'produce1',
                        productName: 'Wheat',
                        parentId: 'sup4',
                    },
                    {
                        id: 'produce2',
                        productName: 'Yeast',
                        parentId: 'sup9',
                    },
                    {
                        id: 'produce3',
                        productName: 'Sugar Cane',
                        parentId: 'sup10',
                    },
                    {
                        id: 'produce4',
                        productName: 'unknown',
                        parentId: 'sup5',
                    },
                    {
                        id: 'produce5',
                        productName: 'Yeast',
                        parentId: 'sup17',
                    },
                    {
                        id: 'produce13',
                        productName: 'Yeast 1',
                        parentId: 'sup18',
                    },
                    {
                        id: 'produce14',
                        productName: 'Yeast Tier 4',
                        parentId: 'sup19',
                    },
                    {
                        id: 'produce15',
                        productName: 'Yeast Tier 5',
                        parentId: 'sup20',
                    },
                    {
                        id: 'produce6',
                        productName: 'Sugar beets',
                        parentId: 'sup8',
                    },
                ],
            },
            {
                productName: 'Chicken Breast',
                category: 'Meat',
                id: '2',
                suppliers: [
                    {
                        id: 'sup11',
                        ingName: 'Chicken 1',
                        productName: 'Chik-Chik',
                        parentId: '2',
                    },
                ],
                produce: [
                    {
                        id: 'produce7',
                        productName: 'Chicken',
                        parentId: 'sup11',
                    },
                ],
            },
            {
                productName: 'Tomato Slices',
                category: 'tomato',
                id: '3',
                suppliers: [
                    {
                        id: 'sup12',
                        ingName: 'Tomato Slices 1',
                        productName: "Veg 'r' Us",
                        parentId: '3',
                    },
                ],
                produce: [
                    {
                        id: 'produce8',
                        productName: 'Tomato',
                        parentId: 'sup12',
                    },
                ],
            },
            {
                productName: 'Lettuce',
                category: 'lettuce',
                id: '4',
                suppliers: [
                    {
                        id: 'sup13',
                        ingName: 'Tomato Slices 1',
                        productName: 'Vegorama',
                        parentId: '4',
                    },
                ],
                produce: [
                    {
                        id: 'produce9',
                        productName: 'Lettuce',
                        parentId: 'sup13',
                    },
                ],
            },
            {
                productName: 'Mayonnaise',
                category: 'mayo',
                id: '5',
                suppliers: [
                    {
                        id: 'sup14',
                        ingName: 'Mayonnate 1',
                        productName: 'Spreads UK',
                        parentId: '5',
                    },
                    {
                        id: 'sup15',
                        ingName: 'Mayonnate 2',
                        productName: 'Spreads UK',
                        parentId: '5',
                    },
                    {
                        id: 'sup16',
                        ingName: 'Mayonnate 3',
                        productName: 'Spreads UK',
                        parentId: '5',
                    },
                ],
                produce: [
                    {
                        id: 'produce10',
                        productName: 'Mayo 1',
                        parentId: 'sup14',
                    },
                    {
                        id: 'produce11',
                        productName: 'Mayo 2',
                        parentId: 'sup15',
                    },
                    {
                        id: 'produce12',
                        productName: 'Mayo 3',
                        parentId: 'sup16',
                    },
                ],
            },
        ],
    },
}
