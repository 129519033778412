import React, { useContext } from 'react'
import { Button } from '@mondra/ui-components'
import { useHistory } from 'react-router-dom'
import toLower from 'lodash/fp/toLower'
import getOr from 'lodash/fp/getOr'
import classNames from 'classnames'
import { ProductSettingContext } from '../contexts/ProductSettingProvider'
import { SlideoverContext } from '../contexts/SlideoverProvider'

import { SUPPLY_CHAIN_NODE_TYPES, URLs } from '../constants'
import { IProduct, NodeState, NodeType } from '../types/types'
import getURL from '../utils/getURL'

import StatusIcon from '../components/StatusIcon'


const stages = [
    {
        iconState: `${SUPPLY_CHAIN_NODE_TYPES.ingredient}s`,
        label: 'Recipe',
        type: NodeType.RECIPE,
    },
    {
        iconState: SUPPLY_CHAIN_NODE_TYPES.processing,
        label: 'Processing',
        type: NodeType.PROCESSING,
    },
    {
        iconState: SUPPLY_CHAIN_NODE_TYPES.packaging,
        label: 'Packaging',
        type: NodeType.PACKAGING,
    },
    {
        iconState: SUPPLY_CHAIN_NODE_TYPES.storage,
        label: 'Storage',
        type: NodeType.STORAGE,
    },
    {
        iconState: SUPPLY_CHAIN_NODE_TYPES.retail,
        label: 'Sale',
        type: NodeType.SALE,
    },
]

interface ISupplyChainSummaryProps {
    companyId: string
    productId: string
    product: IProduct
}

export default function SupplyChainSummary({
    companyId,
    product,
    productId,
}: ISupplyChainSummaryProps) {
    const history = useHistory()
    const { readOnly } = useContext(ProductSettingContext)
    const { openSlideover } = useContext(SlideoverContext)

    function handleViewSupplyChain() {
        history.push(getURL(URLs.Pages.CONTRIBUTION_TREE, { companyId, productId }))
    }

    const getNodeIcon = (type: string): NodeState => {
        const required = getOr(false, `${type}Required`, product)
        if (!required && `${SUPPLY_CHAIN_NODE_TYPES.ingredient}s` !== type) {
            return 'none'
        }
        switch (type) {
            case `${SUPPLY_CHAIN_NODE_TYPES.ingredient}s`:
                return toLower(product?.productMetadata?.ingredientStatus) as NodeState
            case SUPPLY_CHAIN_NODE_TYPES.processing:
                return toLower(product?.productMetadata?.processing) as NodeState
            case SUPPLY_CHAIN_NODE_TYPES.packaging:
                return toLower(product?.productMetadata?.packaging) as NodeState
            case SUPPLY_CHAIN_NODE_TYPES.storage:
                return toLower(product?.productMetadata?.storage) as NodeState
            case SUPPLY_CHAIN_NODE_TYPES.retail:
                return toLower(product?.productMetadata?.sale) as NodeState
            default:
                return 'none'
        }
    }

    return (
        <div className="overflow-hidden">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center flex-wrap sm:flex-nowrap">
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                    Supply Chain Summary
                </h3>
                <div className="flex-shrink-0">
                    <Button type="button" variant="primary" onClick={handleViewSupplyChain}>
                        View contribution tree
                    </Button>
                </div>
            </div>
            <div className="relative flex flex-col lg:flex-row shadow border border-gray-300 rounded-lg">
                <div className="flex flex-row md:flex-col w-full">
                    <div className="text-sm font-medium bg-gray-50 text-gray-700 border-b border-gray-300 w-full rounded-tr-lg text-center py-4">
                        Product{' '}
                        {product.site?.name ? (
                            <span className="text-primary-600">
                                {product.site?.name}, {product.site?.address?.country}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="flex flex-col md:flex-row w-full items-end bg-white rounded-br-lg py-6">
                        {stages.map((stage: any, index: number) => {
                            return (
                                <div
                                    key={stage.type}
                                    className={classNames(
                                        'w-full md:w-1/2 text-center flex flex-col items-center',
                                        index < stages.length - 1 && 'border-r border-gray-300'
                                    )}
                                >
                                    <div className="flex items-center justify-center w-full">
                                        <StatusIcon
                                            type={stage.type}
                                            state={getNodeIcon(stage.iconState)}
                                        />
                                    </div>
                                    <div className="text-center w-full py-4">
                                        <Button
                                            variant="ghost"
                                            className="justify-center"
                                            disabled={readOnly}
                                            size="md"
                                            onClick={() => openSlideover(stage.type)}
                                        >
                                            {stage.label}
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
