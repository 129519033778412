import React, { useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { Tabs, Notification } from '@mondra/ui-components'
import useProductActivity from '../../hooks/useProductActivity'
import { IBPaECConfig, IProcessingActivity } from '../../types/stages'
import { LockActivityContext } from '../../contexts/LockActivityContextProvider'
import { Row } from '../../components'
import { hasError } from '../../utils'
import { prepareActivityData } from '../../utils/activity'
import { NodeType } from '../../types/types'
import { ISlideoverFormProps } from '../component-slideover/ComponentSlideover'
import { defaultActivityData } from '../components/ActivityForm'
import ActivityTab, { IActivityError } from './ActivityTab'
import BatchProductTab, { IBatchProductError } from './BatchProductTab'


const defaultBpaecConfig: IBPaECConfig = {
    bPaecByProducts: [],
    isEnabled: false,
    isPriceValueInPercent: true,
    netBatchOutputWeight: 0,
    percentValuePerKg: 0,
    priceValuePerKg: 0,
    valueInKg: 0,
    waterEvaporation: 0,
    waterEvaporationInKg: 0,
}

const defaultFormData: IProcessingActivity = {
    ...defaultActivityData,
    bPaecConfig: defaultBpaecConfig,
}

const ERROR_NO_SITE = 'You must specify a site for where your products are processed.'
const ERROR_NO_ACTIVITY = 'Please set processing activity and overall losses first.'

export default function ProcessingForm({
    companyId,
    formId,
    onClose,
    onFinished,
    product,
    setDisableSave,
    setSaving,
    updateProductCustomProps,
}: ISlideoverFormProps) {
    const [formData, setFormData] = useState<IProcessingActivity>(defaultFormData)
    const [isRequired, setRequired] = useState<boolean>(product.processingRequired)
    const [setSelected, setSelectedTab] = useState<number>(0)
    const isSiteEmpty = isEmpty(product.site?.address)
    const [error, setError] = useState<IActivityError & IBatchProductError>({})
    const { isLocked, setDisabled, setIsLoading, setIsLocked, setIsSaving } = useContext(
        LockActivityContext
    )

    useEffect(() => {
        setRequired(product.processingRequired)
    }, [product.processingRequired])

    useEffect(() => {
        setDisableSave(hasError(error))
    }, [error])

    const onSaved = () => {
        if (isRequired !== product.processingRequired) {
            updateProductCustomProps({ processingRequired: isRequired })
        } else {
            onFinished && onFinished()
        }
    }

    // Processing Activity
    const { loading, activity, saving, saveActivity } = useProductActivity<IProcessingActivity>({
        activityCode: formData?.dataPlatformActivityCode,
        autoFetch: !isEmpty(product?.id),
        companyId,
        onError: onClose,
        onSaved,
        productId: product.id,
        stage: NodeType.PROCESSING,
    })

    useEffect(() => {
        setIsLoading(loading)
        setIsLocked(Boolean(activity.isLocked))
    }, [loading, activity.isLocked])

    useEffect(() => {
        handleActivityChange({ isLocked })
    }, [isLocked])

    useEffect(() => {
        setSaving(saving)
        setIsSaving(saving)
    }, [saving])

    useEffect(() => {
        setDisabled(!isRequired)
    }, [isRequired])

    useEffect(() => {
        setDisabled(isEmpty(formData.selectedActivity))
    }, [formData.selectedActivity])

    useEffect(() => {
        handleBPaEcChange({ isEnabled: isRequired })
    }, [activity, isRequired])

    useEffect(() => {
        setDisableSave(loading)
    }, [loading])

    useEffect(() => {
        handleActivityChange({
            ...activity,
        })
    }, [activity])

    const validateForm = () => {
        if (!isRequired) {
            return true
        }

        if (isSiteEmpty) {
            onSaved()
            return
        }

        if (hasError(error)) {
            return false
        }

        return true
    }

    const handleSave = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        if (!validateForm()) {
            return
        }

        setSaving(true)

        // Prepare Processing Activity Data
        const { selectedActivity, ...activityData } = formData
        const postDataActivity = prepareActivityData({
            activity: {
                ...activityData,
                disposal: '',
                foodLossesPercentage: null, // don't need food losses anyway in procesing
            },
            product,
            selectedActivity,
            stage: NodeType.PROCESSING,
        })

        saveActivity(postDataActivity)
    }

    const disabled = !isRequired || loading || saving || isSiteEmpty

    const handleActivityChange = (changes: Partial<IProcessingActivity>) => {
        setFormData((data: IProcessingActivity) => ({
            ...data,
            ...changes,
        }))
    }

    const handleBPaEcChange = (changes: Partial<IBPaECConfig>) => {
        setFormData((data: IProcessingActivity) => ({
            ...data,
            bPaecConfig: {
                ...(data.bPaecConfig || {}),
                ...changes,
            },
        }))
    }

    return (
        <form id={formId} className="divide-y divide-gray-200" onSubmit={handleSave}>
            <div className="w-full mb-4">
                <Tabs
                    manual
                    onChange={setSelectedTab}
                    selectedIndex={setSelected}
                    tabs={[
                        {
                            className: 'w-full',
                            id: '1',
                            label: (
                                <div className="w-full h-7 mt-3 relative box-border flex">
                                    Processing activity and overall losses
                                </div>
                            ),
                            panel: (
                                <div className="p-0 divide-y divide-gray-200">
                                    <ErrorRow
                                        description={ERROR_NO_SITE}
                                        show={isSiteEmpty && isRequired}
                                    />
                                    <ActivityTab
                                        activity={activity}
                                        companyId={companyId}
                                        disabled={disabled}
                                        error={error}
                                        isLocked={isLocked}
                                        isRequired={isRequired}
                                        product={product}
                                        formData={formData}
                                        setRequired={setRequired}
                                        setError={setError}
                                        onChange={handleActivityChange}
                                    />
                                </div>
                            ),
                        } as any,
                        {
                            className: 'w-full',
                            id: '2',
                            label: <div className="w-full h-7 mt-3">By-product</div>,
                            panel: (
                                <div className="p-0">
                                    <ErrorRow
                                        description={ERROR_NO_SITE}
                                        show={isSiteEmpty && isRequired}
                                    />
                                    <ErrorRow
                                        description={ERROR_NO_ACTIVITY}
                                        show={
                                            isEmpty(formData.selectedActivity)
                                            && formData.bPaecConfig.isEnabled
                                        }
                                    />
                                    <BatchProductTab
                                        activityDisabled={disabled || isLocked}
                                        product={product}
                                        error={error}
                                        foodLossesPercentage={formData.foodLossesPercentage || ''}
                                        formData={formData.bPaecConfig || defaultBpaecConfig}
                                        onChange={handleBPaEcChange}
                                        setDisableSave={setDisableSave}
                                        setError={setError}
                                    />
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </form>
    )
}

interface IErrorRowProps {
    description: string
    show: boolean
}

function ErrorRow({ description, show }: IErrorRowProps) {
    return show ? (
        <Row>
            <Notification type="error" description={description} />
        </Row>
    ) : null
}
