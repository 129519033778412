import React, { useMemo } from 'react'
import { Icon, Button } from '@mondra/ui-components'
import { makeStyles } from '@material-ui/core/styles'
import reduce from 'lodash/fp/reduce'
import map from 'lodash/fp/map'
import { Row } from '../../components'
import Tooltip from '../../components/LightTooltip'
import { IProduct, NodeType } from '../../types/types'


const useStyle = makeStyles(() => ({
    tooltip: {
        maxHeight: 500,
        maxWidth: 400,
        overflowY: 'auto',
    },
}))

export interface ProductInfoTooltipProps {
    disabled?: boolean
    product: IProduct
}

export default function ProductInfoTooltip({ product, disabled = false }: ProductInfoTooltipProps) {
    const iconComp = <Button iconType="resources" isOnlyIcon iconClass="text-primary-500" />
    const classes = useStyle()

    const tooltipData = useMemo(() => {
        return reduce(
            (acc, act) => {
                return reduce(
                    (resAcc, res) => {
                        const resActivities = resAcc[res.dataPlatformResourceCode]
                            ? resAcc[res.dataPlatformResourceCode].activities
                            : []
                        return {
                            ...resAcc,
                            [res.dataPlatformResourceCode]: {
                                activities: [
                                    ...resActivities,
                                    {
                                        activityName: act.dataPlatformActivityName,
                                        code: act.dataPlatformActivityCode,
                                        id: act.dataPlatformActivityCode,
                                        type: act.stage,
                                    },
                                ],
                                code: res.dataPlatformResourceCode,
                                id: res.dataPlatformResourceCode,
                                resourceName: res.resourceName,
                            },
                        }
                    },
                    acc,
                    act.resources
                )
            },
            {} as any,
            product.activities
        )
    }, [product.activities])

    return disabled ? (
        iconComp
    ) : (
        <Tooltip
            interactive={true}
            enterDelay={500}
            enterNextDelay={500}
            classes={{
                tooltip: classes.tooltip,
            }}
            title={
                <div className="bg-white rounded-lg shadow-lg">
                    <form className="divide-y divide-gray-200 text-gray-700">
                        <div className="rounded-t-lg p-4 bg-white">
                            <div className="rounded-t-lg text-base leading-6 font-semibold">
                                {product.name} activity resources
                            </div>
                        </div>
                        {map((d: any) => {
                            return (
                                <Row key={d.id} className="gap-y-2">
                                    <div className="text-sm leading-5 font-semibold">
                                        {d.resourceName}
                                    </div>
                                    {map((a: any) => {
                                        return (
                                            <div
                                                key={a.id}
                                                className="text-sm leading-5 font-normal flex flex-row gap-x-2 items-center"
                                            >
                                                {getIcon(a.type)}
                                                <div>{a.activityName}</div>
                                            </div>
                                        )
                                    }, d.activities)}
                                </Row>
                            )
                        }, tooltipData)}
                    </form>
                </div>
            }
        >
            {iconComp}
        </Tooltip>
    )
}

function getIcon(type: string) {
    if (type === NodeType.PACKAGING) {
        return (
            <div className="w-8 h-8 bg-amber-500 p-1 flex items-center justify-center text-white">
                <Icon type="packaging" />
            </div>
        )
    }
    if (type === NodeType.PROCESSING) {
        return (
            <div className="w-8 h-8 bg-cyan-500 p-1 flex items-center justify-center text-white">
                <Icon type="processing" />
            </div>
        )
    }
    if (type === NodeType.STORAGE) {
        return (
            <div className="w-8 h-8 bg-red-500 p-1 flex items-center justify-center text-white">
                <Icon type="storage" />
            </div>
        )
    }
    return <div />
}
