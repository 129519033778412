import React, { useEffect, useMemo, useState } from 'react'
import { Button, SearchInput } from '@mondra/ui-components'
import { EntityTypeEnum, IProduct, NodeType } from '../../types/types'
import slideoverCloseStyles from '../../constants/slideoverCloseStyles'
import { ISlideoverProps } from '../../types/slideover-types'
import Slideover from '../components/Slideover'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { Grid } from '../../components/grid/Grid'
import { ASC, URLs } from '../../constants'
import { serverSideGridProps } from '../../constants/grid'
import { useGridControls } from '../../hooks/useGridControls'
import { ingredientColumns } from './ingredientColumns'


const defaultColDef = { filter: false, sortable: false, suppressMenu: true }

export interface IIngredientLookup {
    id: string
    name: string
}

interface IIngredientTableSlideoverProps extends Omit<ISlideoverProps, 'type'> {
    allowExisting?: boolean
    onAdd: (selected: IIngredientLookup[]) => void
    product: IProduct
}

export function IngredientTableSlideover({
    companyId,
    isOpen,
    onAdd,
    onClose,
    product,
}: IIngredientTableSlideoverProps) {
    const [selected, setSelected] = useState<IIngredientLookup[]>([])
    const [renderGrid, setRenderGrid] = useState(false) // to fix animation flicker

    const [filters, setFilters] = useState({
        direction: ASC,
        includeWater: true,
        location: product?.site?.address?.countryCodeISOAlpha3 || '',
        Order: 'name',
        withSuppliedProducts: false,
    })

    const { deSelectAllRows, setGridApi } = useGridControls()

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setRenderGrid(true)
            }, 500)
        } else {
            setRenderGrid(false)
        }
    }, [isOpen])

    const urlOptions = useMemo(() => ({ companyId }), [companyId])

    const onSearch = (searchText: string) => {
        setFilters(f => ({
            ...f,
            name: searchText,
        }))
    }

    const handleCancelSelected = () => {
        setSelected([])
        deSelectAllRows()
    }

    const handleClose = () => {
        handleCancelSelected()
        onClose && onClose(NodeType.INGREDIENT_TABLE)
    }

    const handleAddClick = () => {
        onAdd && onAdd(selected)
        handleClose()
    }

    const toggleItem = (checked: boolean, id: string, data: IIngredientLookup) => {
        setSelected((existing: IIngredientLookup[]) => {
            if (checked) {
                return [...existing, data]
            }
            return existing.filter(s => s.id !== id)
        })
    }

    return (
        <Slideover
            wider="max-w-3xl"
            isOpen={isOpen}
            type={NodeType.INGREDIENT_TABLE}
            title={`Add ingredients to ${product.name}`}
            headerProps={{
                iconProps: slideoverCloseStyles as any,
            }}
            footerProps={{
                className: 'w-full flex flex-row items-center justify-between',
                hideShadow: true,
            }}
            footerChildren={
                <div className="flex justify-start items-center gap-x-2 bg-gray-200 rounded-sm px-2 h-8">
                    <div className="text-sm">{`${selected.length} selected`}</div>
                    {selected.length > 0 && (
                        <Button
                            isOnlyIcon
                            variant="ghost"
                            size="sm"
                            iconType="close"
                            onClick={handleCancelSelected}
                        />
                    )}
                </div>
            }
            formId="ingredient-table-form"
            onClose={handleClose}
            onSaveButtonClick={handleAddClick}
            saveButtonText="Add"
        >
            <GridPageLayout>
                <SearchInput
                    name="customer"
                    className="!h-12 !max-h-12 !border-transparent z-10 !text-sm"
                    placeholder="Search ingredients..."
                    onSearch={onSearch}
                />
                {renderGrid && (
                    <Grid
                        columnDefs={ingredientColumns}
                        context={{
                            toggleItem,
                        }}
                        defaultColDef={defaultColDef}
                        filters={filters}
                        entityType={EntityTypeEnum.INGREDIENT}
                        onApiAvailable={setGridApi}
                        url={URLs.IngredientCollection.GET_ALL}
                        urlOptions={urlOptions}
                        {...serverSideGridProps}
                    />
                )}
            </GridPageLayout>
        </Slideover>
    )
}
