import React, { useState } from 'react'
import { Button, Label } from '@mondra/ui-components'
import classNames from 'classnames'
import includes from 'lodash/fp/includes'
import {
    Link,
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom'
import { Breadcrumb, Breadcrumbs, Dropdown, HomeBreadcrumb, Toolbar } from '../../components'
import { SUPPLY_CHAIN_SORT_OPTIONS, URLs } from '../../constants'
import { IParamProps } from '../../types/types'
import getURL from '../../utils/getURL'
import { IProductNode } from '../../types/supply-chain-types'
import useQuery from '../../hooks/useQuery'
import TileMenuProvider from './TileMenuProvider'
import SupplyChainViz from './supply-chain/SupplyChainViz'
import ContributionTree from './contribution-tree/ContributionTree'

//48px is header height
const VIZ_HEIGHT = 'calc(100vh - (56px + 32px + 65px))'
const routes = {
    contributionTree: 'contribution-tree',
    supplyChain: 'supply-chain',
}

export default function SupplyChain() {
    const [product, setProduct] = useState<IProductNode>()
    const [sortOption, setSortOption] = useState({ id: 'carbon', label: 'Carbon' })

    const { companyId = '', id }: IParamProps = useParams()
    const { path: parentPath, url } = useRouteMatch()
    const { pathname } = useLocation()
    const history = useHistory()
    const queryParams = useQuery()

    const isCTView = includes(routes.contributionTree, pathname)

    const handleViewChange = (event: any) => {
        const { dataset } = event.currentTarget

        history.push({
            pathname: `${url}/${dataset.tabpath}`,
            search: queryParams.toString(),
        })
    }
    const handleSortChange = (sort: any) => {
        setSortOption(sort)
    }
    return (
        <TileMenuProvider product={product}>
            <div className="px-6 pt-8 pb-4">
                <Toolbar
                    breadcrumb={
                        <Breadcrumbs>
                            <HomeBreadcrumb />
                            <Breadcrumb>
                                <Link
                                    to={getURL(URLs.Pages.PRODUCTS, {
                                        companyId,
                                    })}
                                >
                                    Products
                                </Link>
                            </Breadcrumb>
                            <Breadcrumb>
                                <Link
                                    to={getURL(URLs.Pages.PRODUCT_OVERVIEW, {
                                        companyId,
                                        productId: id,
                                    })}
                                >
                                    {!product?.name ? 'Loading...' : product?.name}
                                </Link>
                            </Breadcrumb>
                            {isCTView ? (
                                <Breadcrumb>Contribution Tree</Breadcrumb>
                            ) : (
                                <Breadcrumb>Supply Chain</Breadcrumb>
                            )}
                        </Breadcrumbs>
                    }
                    right={
                        <div className="flex flex-row items-center justify-end gap-2">
                            {!isCTView && (
                                <div className="flex justify-center items-center gap-2">
                                    <Label>Sort by</Label>
                                    <Dropdown
                                        selected={sortOption}
                                        options={SUPPLY_CHAIN_SORT_OPTIONS}
                                        onChange={handleSortChange}
                                        optionsContainerClass="w-36"
                                    />
                                </div>
                            )}
                            <Button
                                isOnlyIcon
                                data-tabpath={routes.supplyChain}
                                iconType="listBoxes"
                                title="Supply Chain View"
                                onClick={handleViewChange}
                                variant={!isCTView ? 'primary' : 'secondary'}
                                className={classNames(
                                    'hover:bg-primary-500 hover:text-white p-2 rounded-md',
                                    !isCTView && 'bg-primary-500 text-white'
                                )}
                            />
                            <Button
                                isOnlyIcon
                                data-tabpath={routes.contributionTree}
                                variant={isCTView ? 'primary' : 'secondary'}
                                iconType="chartBarFloating"
                                title="Contribution Tree View"
                                onClick={handleViewChange}
                                className={classNames(
                                    'hover:bg-primary-500 hover:text-white p-2 rounded-md',
                                    isCTView && 'bg-primary-500 text-white'
                                )}
                            />
                        </div>
                    }
                    title={isCTView ? 'Contribution Tree' : 'Supply Chain'}
                />
                <div
                    className="relative flex flex-col w-full h-full items-stretch z-0 overflow-y-auto"
                    style={{
                        maxHeight: VIZ_HEIGHT,
                        minHeight: VIZ_HEIGHT,
                    }}
                >
                    <Switch>
                        <Route exact path={[parentPath, `${parentPath}/${routes.supplyChain}`]}>
                            <SupplyChainViz
                                companyId={companyId}
                                productId={id}
                                setProduct={setProduct}
                                sortOption={sortOption.id}
                            />
                        </Route>
                        <Route exact path={`${parentPath}/${routes.contributionTree}`}>
                            <ContributionTree
                                companyId={companyId}
                                productId={id}
                                setProduct={setProduct}
                            />
                        </Route>
                    </Switch>
                </div>
            </div>
        </TileMenuProvider>
    )
}
