import React from 'react'

import { significantFigureFormatter } from '../utils/numberFormatter'
import getImpactMeasureUICopy from '../utils/getImpactMeasureUICopy'
import { IEcoImpact } from '../types/ecoImpacts'


interface EcoImpactTableProps {
    bio: IEcoImpact
    eutro: IEcoImpact
    ghg: IEcoImpact
    water: IEcoImpact
}

export function EcoImpactTable({ ghg, eutro, water, bio }: EcoImpactTableProps) {
    const { per100g: ghgPer100 } = ghg
    const { per100g: waterPer100 } = water
    const { per100g: eutroPer100 } = eutro
    const { per100g: bioPer100 } = bio
    const { carbonMeasure, waterUsageMeasure, eutroMeasure, bioMeasure } = getImpactMeasureUICopy()

    return (
        <div className="w-full">
            <div className="font-medium text-gray-800 mb-1">Impacts</div>
            <table className="w-full font-medium">
                <tbody className="leading-5 divide-y divider-gray-200">
                    <tr className="h-9">
                        <td className="text-gray-900 w-4/12">{carbonMeasure.label}:</td>
                        <td className="text-gray-500  w-5/12">
                            {significantFigureFormatter(ghgPer100)} {carbonMeasure.unit}{' '}
                            {carbonMeasure.measure}.
                        </td>
                    </tr>
                    <tr className="h-9">
                        <td className="text-gray-900 w-4/12">{waterUsageMeasure.label}:</td>
                        <td className="text-gray-500 w-5/12">
                            {significantFigureFormatter(waterPer100)} {waterUsageMeasure.unit}{' '}
                            {waterUsageMeasure.measure}
                        </td>
                    </tr>
                    <tr className="h-9">
                        <td className="text-gray-900 w-4/12">{eutroMeasure.label}:</td>
                        <td className="text-gray-500 w-5/12">
                            {significantFigureFormatter(eutroPer100)} {eutroMeasure.unit}{' '}
                            {eutroMeasure.measure}
                        </td>
                    </tr>
                    <tr className="h-9">
                        <td className="text-gray-900 w-4/12">{bioMeasure.label}:</td>
                        <td className="text-gray-500 w-5/12">
                            {significantFigureFormatter(bioPer100)}{' '}
                            <span title="Species Loss Index">{bioMeasure.measure}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
