import React, { useState } from 'react'
import classNames from 'classnames'
import { formatDistance } from 'date-fns'
import map from 'lodash/fp/map'
import { Icon } from '@mondra/ui-components'
import { getGradeHeaderColor } from '../constants/gradeHeaderColors'
import { IIngredientSuppliedProduct, CertificateStateEnum } from '../types/types'
import getImpactFromCollection from '../utils/getImpactFromCollection'
import getImpactMeasureUICopy from '../utils/getImpactMeasureUICopy'
import { significantFigureFormatter } from '../utils/numberFormatter'
import findImpact from '../utils/findImpact'
import { measureTitles, SLI_DESC } from '../constants/ecoImpactMeasures'
import { ImpactMeasureType } from '../types/ecoImpacts'
import Tooltip from './LightTooltip'
import Accordion from './Accordion'
import { ImpactScoreCellProps } from './ImpactScoreCell'
import CertificateStatus from './CertificateStatus'


interface ImpactScoreMultipleProps
    extends Omit<ImpactScoreCellProps, 'ecoImpacts' | 'score' | 'state'> {
    impactCollection: IIngredientSuppliedProduct[]
    name: string
}

export default function ImpactScoreMultiple({
    bgColor,
    bgHoverColor,
    component,
    className,
    disabled,
    hideGrade = false,
    hideIcon = false,
    iconColor = 'text-amber-500',
    iconSize = 24,
    impactCollection,
    loading,
    measureType = 'per100g',
    name,
    onMouseEnter,
    onMouseOut,
    shortGradeLabel = false,
}: ImpactScoreMultipleProps) {
    return (
        <Tooltip
            className={disabled || loading ? 'pointer-events-none' : ''}
            interactive={true}
            enterDelay={500}
            enterNextDelay={500}
            title={
                <EcoImpactTooltipMultiContent
                    measureType={measureType}
                    impactCollection={impactCollection}
                    name={name}
                />
            }
        >
            <div className={classNames('flex w-min items-center cursor-default h-full', className)}>
                {!hideIcon
                    && (component ? (
                        component
                    ) : (
                        <div
                            className={classNames(
                                iconColor,
                                bgColor,
                                bgColor
                                    && 'p-2 rounded-full motion-safe:transition-colors duration-200',
                                bgHoverColor
                            )}
                        >
                            {loading ? (
                                <Icon
                                    type="spinnerThird"
                                    className="text-primary-500 animate-spin"
                                />
                            ) : (
                                <Icon
                                    className="text-current"
                                    type="impactMulti"
                                    size={iconSize}
                                    onMouseEnter={onMouseEnter}
                                    onMouseOut={onMouseOut}
                                    onBlur={onMouseOut}
                                />
                            )}
                        </div>
                    ))}
                {!hideGrade && (
                    <div className="rounded-full border border-gray-800 ml-1 text-xs text-center">
                        <div className="rounded-full border-r border-t border-b border-gray-800 -my-px mr-0.5">
                            <div className="rounded-full border-r border-t border-b border-gray-800 -my-px mr-0.5">
                                <div className="px-2 py-px">
                                    {shortGradeLabel ? 'M' : 'Multiple'}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Tooltip>
    )
}

interface IEcoImpactTooltipMultiContent {
    impactCollection: IIngredientSuppliedProduct[]
    measureType?: ImpactMeasureType
    name: string
}

const EcoImpactTooltipMultiContent = ({
    impactCollection,
    measureType = 'per100g',
    name,
}: IEcoImpactTooltipMultiContent) => {
    const [expanded, setExpanded] = useState(impactCollection[0]?.id || '')
    const impactState = getImpactFromCollection(impactCollection)
    const color = getGradeHeaderColor(impactState.state)
    const impactMeasures = getImpactMeasureUICopy()

    const handleClick = (id: any) => () => {
        setExpanded((e: any) => (e === id ? '' : id))
    }

    return (
        <div className="bg-white rounded-lg shadow-lg w-72">
            <div className={`rounded-t-lg p-4 ${color.bg}`}>
                <div className={`text-lg font-medium ${color.text}`}>
                    {impactState.state === CertificateStateEnum.CertificationExpired
                        ? 'Expired'
                        : impactState.state}{' '}
                    impacts
                </div>
                <div className={`text-xs font-medium ${color.desc}`}>
                    {impactState.expiry
                        && !isNaN(impactState.expiry.getTime())
                        && `Expiring in ${formatDistance(impactState.expiry, new Date())}`}
                </div>
                <div className="text-xs">{measureTitles[measureType]}</div>
            </div>
            <div className="p-2">
                <div className="text-lg text-gray-900 font-medium p-2">{name}</div>
                {map(product => {
                    return (
                        <Accordion
                            key={product.id}
                            open={expanded === product.id}
                            heading={product.name}
                            onClick={handleClick(product.id)}
                        >
                            <ImpactContent
                                measures={impactMeasures}
                                ecoImpacts={product.ecoImpacts}
                                score={product.ecoImpactMetascore}
                                state={product.saState}
                            />
                        </Accordion>
                    )
                }, impactCollection)}
            </div>
        </div>
    )
}

const ImpactContent = ({ ecoImpacts, measures, score, state }: any) => {
    const { carbonMeasure, waterUsageMeasure, eutroMeasure, bioMeasure } = measures
    const { ghg, water, eutro, bio } = findImpact(ecoImpacts)
    const impactTitle = state === CertificateStateEnum.Certified ? state : 'Un-certified'

    return (
        <div className="border-b">
            <div className="p-2 border-b text-gray-800">
                <div className="text-sm font-bold flex flex-row justify-between items-center gap-2">
                    <div>
                        {impactTitle} impacts {score}
                    </div>
                    <CertificateStatus hideLabel status={state || 'Draft'} />
                </div>
            </div>
            <div className="px-2">
                <div className="border-b py-2">
                    <div className="text-sm font-medium text-gray-700 grid grid-cols-3">
                        <div>{carbonMeasure.label}:</div>
                        <div className="text-right w-24">
                            {significantFigureFormatter(ghg?.per100g)}
                        </div>
                        <div className="text-xs ml-6 text-gray-500">
                            {carbonMeasure.unit} {carbonMeasure.measure}
                        </div>
                    </div>
                </div>

                <div className="border-b py-2">
                    <div className="text-sm font-medium text-gray-700 grid grid-cols-3">
                        <div className="w-24">{waterUsageMeasure.label}:</div>
                        <div className="text-right w-24">
                            {significantFigureFormatter(water?.per100g)}
                        </div>
                        <div className="text-xs ml-6 text-gray-500">
                            {waterUsageMeasure.unit} {waterUsageMeasure.measure}
                        </div>
                    </div>
                </div>

                <div className="border-b py-2">
                    <div className="text-sm font-medium text-gray-700 grid grid-cols-3">
                        <div className="w-24 whitespace-nowrap">{eutroMeasure.label}:</div>
                        <div className="text-right w-24">
                            {significantFigureFormatter(eutro?.per100g)}
                        </div>
                        <div className="text-xs ml-6 text-gray-500 whitespace-nowrap">
                            {eutroMeasure.unit} {eutroMeasure.measure}
                        </div>
                    </div>
                </div>

                <div className="py-2">
                    <div className="text-sm font-medium text-gray-700 grid grid-cols-3">
                        <div className="w-24">{bioMeasure.label}:</div>
                        <div className="text-right w-24">
                            {significantFigureFormatter(bio?.per100g)}
                        </div>
                        <div
                            className="text-xs ml-6 text-gray-500"
                            title={SLI_DESC}
                            style={{ marginTop: '2px' }}
                        >
                            {bioMeasure.measure}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
