import React, { useCallback, useMemo } from 'react'
import { GridReadyEvent, ColDef, SortChangedEvent, ColumnState } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import isEmpty from 'lodash/fp/isEmpty'
import head from 'lodash/fp/head'
import { ParentSize } from '@visx/responsive'
import { METHOD_GET, PAGE_SIZE } from '../../constants'
import { IfetchBaseClient } from '../../utils/fetchClient'
import useAuth from '../../hooks/useAuth'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { EntityTypeEnum } from '../../types/types'
import { createDataSource } from './createDataSource'
import { NoRowsOverlay } from './NoRowsOverlay'
import { LoadingOverlay } from './LoadingOverlay'
import { LoadingCell } from './LoadingCell'


interface IGrid<T = any> extends AgGridReactProps, Omit<IfetchBaseClient, 'url'> {
    entityType?: EntityTypeEnum
    filters?: any
    isExternalFilter?: boolean
    onApiAvailable?: (api: any) => void
    rowData?: T[]
    setFilters?: (filters: any) => void
    url?: string
    urlOptions?: any
}

const GRID_DEFAULT_COL_DEF: ColDef = {
    filter: true,
    flex: 1,
}

export const Grid = <T extends unknown>({
    body,
    baseUrlPrefix,
    blockLoadDebounceMillis = 500,
    domLayout = 'normal',
    defaultColDef,
    entityType,
    filters,
    gridOptions,
    isExternalFilter = true,
    method = METHOD_GET,
    onApiAvailable,
    pagination,
    setFilters,
    url = '',
    urlOptions,
    serverSideDatasource,
    ...restProps
}: IGrid<T>) => {
    const { acquireToken } = useAuth()
    const { showError } = useTopCenterToast()
    //TODO: Need to enable this to set filter model
    // const [gridApi, setGridApi] = useState<GridApi>()
    const onGridReady = useCallback(
        ({ api, columnApi }: GridReadyEvent) => {
            // setGridApi(api)
            /**
             * TODO: Setting filters is not triggering getRows API call
             * and not receiving filterModel via getRows params
             */
            if (!isEmpty(filters) && filters.order) {
                const [colId, sort] = filters.order
                const defaultColumnStates = columnApi.getColumnState()
                const newColumnState = defaultColumnStates.map((colState: ColumnState) =>
                    colState.colId === colId ? { ...colState, sort } : colState
                )
                columnApi.applyColumnState({
                    state: newColumnState,
                })
            }
            if (onApiAvailable) {
                onApiAvailable(api)
            }
        },
        [onApiAvailable]
    )

    /**
     * TODO: Setting filters is not triggering getRows API call
     * and not receiving filterModel via getRows params
     * get the filter model using gridApi.getFilterModel()
     * incase any filters being set by Ag Grid
     */
    // useEffect(() => {
    //     const finalFilters = omit(['order'], filters)
    //     if (!isEmpty(finalFilters)) {
    //         gridApi?.setFilterModel({ ...finalFilters, entityType })
    //         gridApi?.onFilterChanged()
    //     }
    // }, [entityType, filters, gridApi])

    const dataSource = useMemo(
        () =>
            !isEmpty(url)
                ? createDataSource({
                    acquireToken,
                    baseUrlPrefix,
                    body,
                    filters: {
                        ...filters,
                        entityType,
                    },
                    method,
                    pageSize: PAGE_SIZE,
                    showError,
                    url,
                    urlOptions,
                })
                : serverSideDatasource,
        [body, entityType, filters, method, serverSideDatasource, url, urlOptions]
    )

    const allGridOptions = useMemo(() => {
        return {
            ...(isExternalFilter && {
                isExternalFilterPresent: () => true,
            }),
            rowClass: 'bg-white',
            suppressCellFocus: true,
            suppressContextMenu: true,
            suppressMenuHide: false,
            ...(gridOptions && gridOptions),
        }
    }, [gridOptions])

    const paginationOptions = useMemo(
        () =>
            pagination
                ? { cacheBlockSize: PAGE_SIZE, pagination: true, paginationPageSize: PAGE_SIZE }
                : {},
        [pagination]
    )

    function handleSortChange(event: SortChangedEvent) {
        const colStates = event.columnApi.getColumnState()
        const sortModels = colStates
            .filter((colState: ColumnState) => colState.sort)
            .map((colState: ColumnState) => [colState.colId, colState.sort])
        const sortModel = head(sortModels)
        setFilters
            && setFilters({
                order: sortModel,
            })
    }

    const gridDefaultColDef = useMemo(
        () => ({
            ...GRID_DEFAULT_COL_DEF,
            ...defaultColDef,
        }),
        []
    )

    return (
        <ParentSize className="flex-grow">
            {(parent: any) => (
                <div
                    className="ag-theme-alpine ag-theme-mondra flex-grow flex flex-col overflow-hidden"
                    style={{
                        height: domLayout === 'normal' ? `${parent.height}px` : '100%',
                        width: '100%',
                    }}
                >
                    <AgGridReact
                        animateRows={true}
                        blockLoadDebounceMillis={blockLoadDebounceMillis}
                        defaultColDef={gridDefaultColDef}
                        domLayout={domLayout}
                        gridOptions={allGridOptions}
                        loadingCellRenderer={LoadingCell}
                        loadingOverlayComponent={LoadingOverlay}
                        noRowsOverlayComponent={NoRowsOverlay}
                        onGridReady={onGridReady}
                        onSortChanged={handleSortChange}
                        rowModelType="serverSide"
                        serverSideDatasource={dataSource}
                        suppressRowClickSelection
                        rowHeight={48}
                        {...restProps}
                        {...paginationOptions}
                    />
                </div>
            )}
        </ParentSize>
    )
}
