import React from 'react'
import { Icon } from '@mondra/ui-components'
import classNames from 'classnames'
import { getGradeHeaderColor } from '../constants/gradeHeaderColors'
import { CertificateStateEnum, IClassNameProps } from '../types/types'
import { IEcoImpact, ImpactMeasureType } from '../types/ecoImpacts'

import findImpact from '../utils/findImpact'
import EcoImpactTooltip from './EcoImpactTooltipContent'
import Tooltip from './LightTooltip'


export interface ImpactScoreCellProps extends IClassNameProps {
    bgColor?: string
    bgHoverColor?: string
    component?: any
    customMeasureType?: string
    disabled?: boolean
    ecoImpacts: IEcoImpact[]
    hideGrade?: boolean
    hideIcon?: boolean
    iconColor?: string
    iconSize?: number
    loading?: boolean
    measureType?: ImpactMeasureType
    onMouseEnter?: any
    onMouseOut?: any
    score: string
    shortGradeLabel?: boolean
    state: CertificateStateEnum
}

export default function ImpactScoreCell({
    bgColor,
    bgHoverColor,
    className,
    component,
    customMeasureType,
    disabled = false,
    ecoImpacts,
    hideGrade = false,
    hideIcon = false,
    iconColor = 'text-gray-700',
    iconSize = 24,
    loading,
    measureType = 'per100g',
    onMouseEnter,
    onMouseOut,
    score,
    shortGradeLabel = false,
    state,
}: ImpactScoreCellProps) {
    const color = getGradeHeaderColor(score)
    const { ghg, water, eutro, bio } = findImpact(ecoImpacts)
    let classes = iconColor

    if (bgColor) {
        classes = `${classes} ${bgColor} px-1 py-2 rounded-full motion-safe:transition-colors duration-200`
    }
    if (bgHoverColor) {
        classes = `${classes} hover:${bgHoverColor}`
    }

    return (
        <Tooltip
            className={disabled || loading ? 'pointer-events-none' : ''}
            interactive={true}
            enterDelay={500}
            enterNextDelay={500}
            title={
                <EcoImpactTooltip
                    bioImpact={bio?.[measureType]}
                    carbonImpact={ghg?.[measureType]}
                    pollutionImpact={eutro?.[measureType]}
                    waterImpact={water?.[measureType]}
                    measureType={measureType}
                    customMeasureType={customMeasureType}
                    score={score}
                    state={state}
                />
            }
        >
            <div
                className={classNames(
                    'flex justify-start items-center cursor-pointer h-full',
                    disabled && 'opacity-50',
                    className
                )}
            >
                {!hideIcon
                    && (component ? (
                        component
                    ) : (
                        <div className={classes}>
                            {loading ? (
                                <Icon
                                    type="spinnerThird"
                                    className="text-primary-500 animate-spin"
                                    size={iconSize}
                                />
                            ) : (
                                <Icon
                                    type="impact"
                                    size={iconSize}
                                    onMouseEnter={onMouseEnter}
                                    onMouseOut={onMouseOut}
                                    onBlur={onMouseOut}
                                />
                            )}
                        </div>
                    ))}
                {!hideGrade && score && (
                    <div
                        className={`rounded-full px-2 py-1 ml-2 text-xs text-white text-center ${color.bg}`}
                    >
                        <span className={`whitespace-nowrap ${color.text}`}>
                            {shortGradeLabel ? score : `Grade ${score}`}
                        </span>
                    </div>
                )}
            </div>
        </Tooltip>
    )
}
