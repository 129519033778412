import React from 'react'
import { Row } from '../../components'
import { IResourceSource } from '../../types/stages'
import InfoTooltip from '../components/InfoTooltip'


export interface ResourcesInfoTooltipProps {
    disabled?: boolean
    resource?: IResourceSource
}

export default function ResourcesInfoTooltip({
    disabled = false,
    resource,
}: ResourcesInfoTooltipProps) {
    return (
        <InfoTooltip disabled={disabled}>
            <form className="divide-y divide-gray-200 text-gray-700">
                <div className={'rounded-t-lg p-4 bg-white'}>
                    <div className={'ml-2 rounded-t-lg text-base leading-6 font-semibold'}>
                        {resource?.resourceName}
                    </div>
                </div>
                <Row>
                    <div className={'text-sm leading-5 font-semibold'}>Resource source</div>
                    <div className="mt-2 text-sm leading-5 font-normal">
                        {resource?.resourceSourceName}
                    </div>
                </Row>
                <Row>
                    <div className={'text-sm leading-5 font-semibold'}>Process name</div>
                    <div className="mt-2 text-sm leading-5 font-normal">
                        {resource?.processName}
                    </div>
                </Row>
            </form>
        </InfoTooltip>
    )
}
