import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import ProductName from '../../components/ProductName'


const ProductNameCell = ({ context, data }: ICellRendererParams) => {
    return <ProductName companyId={context.companyId} product={data} />
}

export default ProductNameCell
