import isEmpty from 'lodash/fp/isEmpty'
import { IProduct } from '../types/types'
import { YOUR_COMPANY, UNKNOWN } from '../constants'


export function getProductSuppliedByName(product: IProduct) {
    if (isEmpty(product)) return ''

    if (isEmpty(product.supplierId)) {
        // Your own Product
        return product.companyName || YOUR_COMPANY
    }
    return product.supplierName || UNKNOWN
}

export function getProductManagedByName(product: IProduct) {
    return product?.companyName || YOUR_COMPANY
}
