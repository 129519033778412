import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react'
import { Button, Textarea } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import { Dropdown, Row } from '../../components'
import { SlideoverContext } from '../../contexts/SlideoverProvider'
import { MAX_DEC, URLs, WASTE_WATER, WATER } from '../../constants'
import useFetch from '../../hooks/useFetch'
import getURL from '../../utils/getURL'
import { evaluateInputValue, setValidationError } from '../../utils'
import {
    ActivityStage,
    IActivityRefDataOption,
    IActivityRefDataResource,
    IStageActivity,
} from '../../types/stages'
import { IProduct, NodeType } from '../../types/types'
import ActivityInfoTooltip from './ActivityInfoTooltip'
import ActivityResource from './ActivityResource'


export const defaultActivityData: IStageActivity = {
    customUsageReason: '',
    dataPlatformActivityCode: '',
    disposal: '',
    durationInHours: 0,
    foodLossesPercentage: 0,
    resources: [],
    selectedActivity: {} as IActivityRefDataOption,
    siteId: '',
    totalProductionUsagePerKg: 0,
    totalProductionUsageUnit: 'kg',
}

export interface IActivityFormError {
    activity?: boolean
}

interface IActivityFormProps {
    activity?: IStageActivity
    companyId: string
    description?: string
    disabled?: boolean
    error?: IActivityFormError
    formData: IStageActivity
    isLocked?: boolean
    onChange: (changes: Partial<IStageActivity>) => void
    product: IProduct
    resourcesHeading?: string
    setError: Dispatch<SetStateAction<IActivityFormError>>
    stage: ActivityStage
    title?: string
    setActivityLosses?: Dispatch<SetStateAction<boolean>>
    usageUnitText?: string
}

export default function ActivityForm({
    activity,
    companyId,
    disabled,
    description,
    error,
    formData,
    isLocked,
    onChange,
    product,
    resourcesHeading,
    setActivityLosses,
    setError,
    stage,
    title,
    usageUnitText = 'per kg product',
}: IActivityFormProps) {
    const { openSlideover } = useContext(SlideoverContext)
    const isEdit = !isEmpty(activity?.id)

    const { data, loading } = useFetch<IActivityRefDataOption[]>({
        cache: true,
        defaultRes: [],
        url: getURL(URLs.Activity.LEGS, { companyId, type: stage }),
    })

    const activities: IActivityRefDataOption[] = useMemo(() => {
        return flow(
            map((option: IActivityRefDataOption) => ({
                ...option,
                id: option.code.toString(),
                label: option.activityName,
            })),
            sortBy(i => i.label)
        )(data)
    }, [data])

    useEffect(() => {
        if (!isEmpty(activity) && !isEmpty(activities)) {
            const selected = find(['code', activity?.dataPlatformActivityCode], activities)
            const selectedActivity: IActivityRefDataOption | undefined = selected
                ? {
                    ...selected,
                    resources: map((r: IActivityRefDataResource) => {
                        const rActivity = find(
                            ['dataPlatformResourceCode', r.code],
                            activity?.resources
                        )
                        if (rActivity) {
                            return {
                                ...r,
                                usagePerKg: rActivity.usagePerKg,
                            }
                        }
                        return r
                    }, selected?.resources),
                }
                : undefined
            onChange({
                selectedActivity,
            })
        }
    }, [activity, activities])

    const handleActivityChange = (act?: IActivityRefDataOption) => {
        if (act) {
            onChange({
                dataPlatformActivityCode: act?.code,
                foodLossesPercentage: act?.lossInPercentage,
                selectedActivity: act,
            })
        } else {
            onChange({
                ...defaultActivityData,
                dataPlatformActivityCode: activity?.dataPlatformActivityCode,
            })
        }
        setValidationError(act?.id || '', 'activity', error, setError)
    }

    const handleUsageChange = (event: any) => {
        onChange({
            customUsageReason: event.target.value,
        })
    }

    const handleUsagePerKgChange = (resCode: string) => (event: any) => {
        onChange({
            resources: (formData?.resources || []).map(r => {
                if (r.dataPlatformResourceCode === resCode) {
                    return {
                        ...r,
                        usagePerKg: evaluateInputValue(event, MAX_DEC),
                    }
                }
                return r
            }),
        })
    }

    const handleOpenResources = () => {
        openSlideover(NodeType.SITE_RESOURCE, { site: product.site })
    }
    const inputResource = useMemo(() => filter(r => !r.isOutput, formData?.resources), [
        formData?.resources,
    ])
    const outputResource = useMemo(
        () => filter(r => r.isOutput && r.isForWaste, formData?.resources),
        [formData?.resources]
    )
    const emissionResource = useMemo(() => filter(r => r.isEmissions, formData?.resources), [
        formData?.resources,
    ])

    useEffect(() => {
        if (setActivityLosses) {
            !isEmpty(outputResource) ? setActivityLosses(false) : setActivityLosses(true)
        }
        if (!isEmpty(outputResource) || !isEmpty(outputResource)) {
            const wasteInKg = [...outputResource, ...emissionResource].reduce(
                (waste: number, res: any) =>
                    res?.resourceName === WASTE_WATER || res?.resourceName === WATER
                        ? waste
                        : waste + +res.quantity,
                0
            )
            const { batchInputWeight } = product || {}
            onChange({
                foodLossesPercentage: (wasteInKg * 100) / batchInputWeight,
            })
        }
    }, [outputResource, emissionResource])

    return (
        <React.Fragment>
            {title && (
                <Row className="bg-gray-50">
                    <div className="text-lg font-medium text-gray-900">{title}</div>
                    <div className="text-sm text-gray-500 font-normal">{description}</div>
                </Row>
            )}

            <Row>
                <div className="text-sm font-medium text-gray-700 mb-2">
                    Select {stage} activity:
                </div>

                <div className="flex flex-row items-center gap-4">
                    <Dropdown
                        containerClass="flex-grow"
                        disabled={disabled || loading}
                        selected={formData?.selectedActivity}
                        options={activities}
                        valid={!error?.activity}
                        groupKey="activityGroup"
                        placeholder={loading ? 'Loading...' : `Select primary ${stage}`}
                        type="Autocomplete"
                        onChange={handleActivityChange}
                    />
                    <ActivityInfoTooltip
                        disabled={disabled}
                        activity={formData?.selectedActivity}
                    />
                </div>
            </Row>

            <Row className="text-sm font-medium text-gray-700">
                {resourcesHeading
                    || 'Selected activity consumes the following site resources per kg of product:'}
            </Row>

            <ActivityResource
                disabled={disabled || isLocked}
                handleUsagePerKgChange={handleUsagePerKgChange}
                resources={inputResource}
                type="Input resources"
                usageUnitText={usageUnitText}
            />
            {!isEmpty(outputResource) && (
                <ActivityResource
                    disabled={disabled || isLocked}
                    handleUsagePerKgChange={handleUsagePerKgChange}
                    resources={outputResource}
                    type="Output waste resources"
                    usageUnitText={usageUnitText}
                />
            )}
            {!isEmpty(emissionResource) && (
                <ActivityResource
                    disabled={disabled || isLocked}
                    handleUsagePerKgChange={handleUsagePerKgChange}
                    resources={emissionResource}
                    type="Output emissions"
                    usageUnitText={usageUnitText}
                />
            )}

            <Row>
                <div className="mb-1 text-sm text-gray-700 font-normal">Custom usage reason</div>
                <Textarea
                    className="w-full"
                    disabled={disabled || isLocked}
                    value={formData.customUsageReason}
                    onChange={handleUsageChange}
                />
                <div className="flex items-center justify-end mt-4">
                    <Button
                        variant="secondary"
                        disabled={
                            disabled
                            || isLocked
                            || !isEdit
                            || formData.dataPlatformActivityCode !== formData.selectedActivity?.code
                            || isEmpty(product?.site?.id)
                        }
                        onClick={handleOpenResources}
                    >
                        Open site resource
                    </Button>
                </div>
            </Row>
        </React.Fragment>
    )
}
