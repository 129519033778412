import React from 'react'
import classNames from 'classnames'


interface IFormRowProps extends React.ComponentPropsWithoutRef<'div'> {
    showBottomBorder?: boolean
}
export default function FormRow({
    className = 'grid items-center grid-cols-2 gap-x-4',
    showBottomBorder,
    children,
}: IFormRowProps) {
    return (
        <div
            className={classNames(
                'px-4 md:px-6 py-4',
                showBottomBorder && 'border-b border-gray-200',
                className
            )}
        >
            {children}
        </div>
    )
}
