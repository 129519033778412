import React, { useState } from 'react'
import { ConfirmDialog, ErrorLabel, RadioGroup } from '@mondra/ui-components'


const PRIVATE = 'Private'
const PUBLIC = 'Public'

const visibilityOptions = [
    {
        description: 'You are the only one able to view product certificate.',
        label: 'Private',
        value: PRIVATE,
    },
    {
        description:
            'This controls visibility of your product’s certificate in the Foundation Earth Product Directory.',
        label: 'Public',
        value: PUBLIC,
    },
]

export default function ProductVisibility({
    disabled,
    isPrivateCertificate,
    onChange,
    noGrade,
}: any) {
    const [showConfirm, setShowConfirm] = useState(false)

    function handleVisibilityChange(visibility: string) {
        const isPrivate = visibility === PRIVATE
        if (isPrivate) {
            onChange({
                isPrivateCertificate: isPrivate,
            })
        } else {
            setShowConfirm(true)
        }
    }

    return (
        <>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                    htmlFor="isPrivateCertificate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                    Product certification visibility
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <RadioGroup
                        showRadio
                        orientation="vertical"
                        variant="list"
                        label="Certification visibility options"
                        name="isPrivateCertificate"
                        options={visibilityOptions}
                        value={isPrivateCertificate ? PRIVATE : PUBLIC}
                        onChange={handleVisibilityChange}
                        disabled={disabled}
                    />
                    {noGrade && (
                        <ErrorLabel>
                            Certificate is forced to Private as it is un-graded.
                        </ErrorLabel>
                    )}
                </div>
            </div>
            <ConfirmDialog
                open={showConfirm && isPrivateCertificate}
                onConfirm={() => {
                    onChange({
                        isPrivateCertificate: false,
                    })
                    setShowConfirm(false)
                }}
                onClose={() => setShowConfirm(false)}
                primaryBtnText={'Confirm'}
                title="Product visibility warning"
                variant="warn"
            >
                By clicking accept your product will be made available to the public, check your
                contracts before you proceed.
            </ConfirmDialog>
        </>
    )
}
