import React from 'react'
import { Icon } from '@mondra/ui-components'
import { useTopCenterToast } from '../hooks/useTopCenterToast'


export default function CopyToClipboard({ infoText = 'Copied', text, children }: any) {
    const { showInfo } = useTopCenterToast()

    const handleCopy = () => {
        navigator.clipboard.writeText(text)
        showInfo({ description: infoText })
    }

    return (
        <div
            className="inline-flex items-center justify-center cursor-pointer"
            role="button"
            onClick={handleCopy}
            title={text}
        >
            {children} <Icon type="copy" size={14} className="ml-2" />
        </div>
    )
}
