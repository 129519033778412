import React from 'react'
import { ColDef } from 'ag-grid-community'
import { Tooltip, formatSignificantDigits } from '@mondra/ui-components'
import { round } from '../../../utils/numberFormatter'
import { IEcoImpactIdType } from '../../../types/ecoImpacts'
import ecoImpactMeasures from '../../../constants/ecoImpactMeasures'
import { DataQualityScoreCell } from './DataQualityScoreCell'
import ImpactColumn from './ImpactColumn'
import { DQSHeader } from './DQSHeader'


export const ContributionColumnDefs = [
    { field: 'id', hide: true },
    {
        cellClass: 'ag-center-cell',
        cellRenderer: DataQualityScoreCell,
        field: 'dataQualityScore.score',
        headerClass: 'ag-center-header flex justify-center',
        headerComponent: DQSHeader,
        maxWidth: 250,
        minWidth: 180,
        suppressMenu: true,
    },
    {
        cellClass: 'text-center',
        cellRenderer: ({ context, data }: any) => (
            <div className="mt-2 flex justify-center">
                <ImpactColumn data={data} impactCode={context.impactCode} />
            </div>
        ),
        field: 'impactPercentage',
        headerClass: 'ag-center-header',
        headerName: 'Stage Impacts',
        maxWidth: 250,
        minWidth: 180,
        suppressMenu: true,
    },
    {
        cellClass: 'ag-right-cell',
        cellRenderer: ({ data }: any) => {
            return (
                <div>
                    {data.impactPercentage === undefined
                        ? '-'
                        : `${round(data.impactPercentage || 0, 2)}%`}
                </div>
            )
        },
        field: 'impactValue',
        headerClass: 'ag-right-header',
        headerName: 'Contribution',
        maxWidth: 250,
        minWidth: 150,
        suppressMenu: true,
    },
    {
        cellClass: 'impactvalue ag-right-cell',
        cellRenderer: ({ context, data }: any) => {
            const unit = ecoImpactMeasures[context.impactCode as IEcoImpactIdType].unit || ''
            return (
                <div className="ag-right-cell" title="">
                    <Tooltip size="sm" content={`${data.impactValue} ${unit}`}>
                        {data.impactValue
                            ? `${formatSignificantDigits(data.impactValue)} ${unit}`
                            : '-'}
                    </Tooltip>
                </div>
            )
        },
        field: 'impactValue',
        headerClass: 'ag-right-header',
        headerComponent: ({ context }: any) => (
            <>
                <div>Impact</div>
                <div className="text-gray-600">
                    ({ecoImpactMeasures[context.impactCode as IEcoImpactIdType]?.measure || ''})
                </div>
            </>
        ),
        maxWidth: 350,
        minWidth: 180,
    },
] as Array<ColDef>
