import React, { useContext } from 'react'
import { Menu, MenuList, MoreMenuButton } from '../../components'
import ArchiveProduct from '../../components/ArchiveProduct'
import { UserContext } from '../../contexts/UserProvider'
import { useCompanyId } from '../../hooks/useCompanyId'
import { isOwner, isMondraAdmin } from '../../hooks/userRoles'
import { SA_TYPE } from '../../types/types'


interface IIngredientActionsProps {
    node: any
    refetch(): void
}

export default function IngredientActions({ node, refetch }: IIngredientActionsProps) {
    const companyId = useCompanyId()
    const { currentUser } = useContext(UserContext)
    const canShowArchived = isOwner(currentUser) || isMondraAdmin(currentUser)
    const isProduce = node.saType === SA_TYPE.Produce

    // Return empty if there is no menu item
    if (!canShowArchived || node.isWater) {
        return <div />
    }

    return (
        <>
            <Menu>
                <MoreMenuButton />
                <MenuList
                    style={{ right: 0, width: 'max-content' }}
                    ariaLabelledby={isProduce ? 'Produce action menu' : 'Product action menu'}
                >
                    {canShowArchived && (
                        <ArchiveProduct
                            apiNode="ingredientcollections"
                            id={node.id}
                            name={node.name}
                            companyId={companyId}
                            isArchived={node.isArchived}
                            refresh={refetch}
                        />
                    )}
                </MenuList>
            </Menu>
        </>
    )
}
