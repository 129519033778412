import React, { useEffect, useMemo, useState } from 'react'
import { RadioGroup, Input, Button, Label, Icon, Tooltip } from '@mondra/ui-components'
import forEach from 'lodash/fp/forEach'
import reduce from 'lodash/fp/reduce'
import toUpper from 'lodash/fp/toUpper'
import { MAX_DEC } from '../../constants'
import { BATCH_INPUT_WEIGHT, INGREDIENT } from '../../constants/toastNotifications'
import { ISlideoverFormProps } from '../component-slideover/ComponentSlideover'
import Row from '../../components/Row'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { evaluateInputValue } from '../../utils'
import { parseNumber, round } from '../../utils/numberFormatter'
import { ProductDetailTypes } from '../../types/types'
import useProductDetails from '../../hooks/useProductDetails'
import { PageLoader } from '../../components'
import useProduct from '../../hooks/useProduct'
import {
    IngredientTableSlideover,
    IIngredientLookup,
} from '../ingredient-table/IngredientTableSlideover'
import IngredientsList from './IngredientsList'


export enum BatchInputType {
    PERCENT = '%',
    KG = 'kg',
}

export interface IIngredientItem {
    specialIngredientCategoryCode?: string
    dataPlatformRefId?: string
    ingredientId: string
    ingredientName: string
    inputQuantity: number
    inputQuantityPercentage: number
    isDPSpecialIngredient: boolean
    isIngredientArchived: boolean
    isSpecialIngredient: boolean
    productId: string
}

export interface IError {
    valid: boolean
}

const options = [
    {
        description: '',
        label: BatchInputType.PERCENT,
        value: BatchInputType.PERCENT,
    },
    {
        description: '',
        label: BatchInputType.KG,
        value: BatchInputType.KG,
    },
]

export const defaultIngredient: IIngredientItem = {
    ingredientId: '',
    ingredientName: '',
    inputQuantity: 0,
    inputQuantityPercentage: 0,
    isDPSpecialIngredient: false,
    isIngredientArchived: false,
    isSpecialIngredient: false,
    productId: '',
    specialIngredientCategoryCode: undefined,
}

export default function RecipeForm({
    companyId,
    formId,
    onFinished,
    product,
    setDisableSave,
    setSaving,
}: ISlideoverFormProps) {
    const [batchInputType, setBatchInputType] = useState<BatchInputType>(
        toUpper(product?.productBatchInputType) === 'KG'
            ? BatchInputType.KG
            : BatchInputType.PERCENT
    )
    const [batchInputWeight, setBatchInputWeight] = useState<number>(product?.batchInputWeight)
    const [addIngOpen, setAddIngOpen] = useState(false)
    const [addedIngredients, setAddedIngredients] = useState<IIngredientItem[]>(
        product?.productMetadata?.ingredients || []
    )
    const { showError } = useTopCenterToast()
    const inPercent = batchInputType === BatchInputType.PERCENT

    const onMetadataUpdate = () => {
        updateProductCustomProps({
            batchInputWeight: +batchInputWeight,
            productBatchInputType:
                batchInputType === BatchInputType.PERCENT ? 'Percentage' : BatchInputType.KG,
        })
    }

    const { updateProductCustomProps, updateProductMetadata } = useProduct({
        autoFetch: false,
        companyId,
        onCustomPropsUpdate: onFinished,
        onError: () => setSaving(false),
        onMetadataUpdate,
        productId: product?.id,
    })

    const { details: ingredientsData, loading: loadingIngredientData } = useProductDetails({
        companyId,
        productId: product?.id,
        type: ProductDetailTypes.METADATA,
    })

    useEffect(() => {
        setBatchInputType(
            toUpper(product?.productBatchInputType) === 'KG'
                ? BatchInputType.KG
                : BatchInputType.PERCENT
        )
        setBatchInputWeight(product?.batchInputWeight)
        setAddedIngredients(product?.productMetadata?.ingredients || [])
    }, [product])

    useEffect(() => {
        setAddedIngredients(ingredientsData?.ingredients || [])
    }, [ingredientsData])

    const remainingAllocation = useMemo(() => {
        const added = reduce(
            (acc: any, i: IIngredientItem) => {
                return {
                    kg: acc.kg + parseNumber(i.inputQuantity),
                    percent: acc.percent + parseNumber(i.inputQuantityPercentage),
                }
            },
            { kg: 0, percent: 0 },
            addedIngredients
        )
        return {
            kg:
                round(100 - added.percent, 2) === 0
                    ? 0
                    : round(batchInputWeight - added.kg, MAX_DEC),
            percent: round(added.percent, MAX_DEC),
        }
    }, [batchInputWeight, addedIngredients])

    useEffect(() => {
        setDisableSave(batchInputWeight <= 0)
    }, [remainingAllocation, batchInputWeight])

    const validateForm = () => {
        if (batchInputWeight <= 0) {
            showError({ description: BATCH_INPUT_WEIGHT })
            return false
        }

        if (addedIngredients.length === 0) {
            showError({ description: INGREDIENT.ADD })
            return false
        }

        let valid = true
        forEach((ing: IIngredientItem) => {
            const qty = parseNumber(ing.inputQuantityPercentage)
            if (qty <= 0 || qty > 100) {
                valid = false
            }
        }, addedIngredients)

        return valid
    }

    const handleSave = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        if (!validateForm()) {
            return
        }
        setSaving(true)
        updateProductMetadata({ ingredients: addedIngredients })
    }

    function handleWeightChange(event: any) {
        setBatchInputWeight(evaluateInputValue(event, MAX_DEC))
    }

    function handleAddIngredients() {
        setAddIngOpen(true)
    }

    const onSlideoverClose = () => {
        setAddIngOpen(false)
    }

    const onIngredientAdd = (selected: IIngredientLookup[]) => {
        const newIngredients = selected
            .filter(newIng => !addedIngredients.find(ing => ing.ingredientId === newIng.id))
            .map(newIng => ({
                ...defaultIngredient,
                ingredientId: newIng.id,
                ingredientName: newIng.name,
                productId: product.id,
            }))

        setAddedIngredients((ing: any) => {
            return [...ing, ...newIngredients]
        })
    }

    const handleRemove = (index: number) => () => {
        setAddedIngredients(ings => {
            const rest = [...ings]
            rest?.splice(index, 1)
            return rest
        })
    }

    return (
        <React.Fragment>
            <form id={formId} className="divide-y divide-gray-200" onSubmit={handleSave}>
                <Row className="grid-cols-3 py-0 my-4">
                    <div className="col-span-2">
                        <Label helpText="Select ingredients and set batch input weights or percentages. Use the toggle to input by percentage or weight.">
                            Ingredients and amounts used
                        </Label>
                    </div>
                    <div className="col-span-1 flex justify-end">
                        <RadioGroup
                            orientation="horizontal"
                            optionContentClassName="px-4 py-2"
                            options={options}
                            value={batchInputType}
                            onChange={setBatchInputType}
                        />
                    </div>
                </Row>
                <Row className="grid-cols-3 py-0">
                    <div className="col-span-1">
                        <Label>Batch input weight</Label>
                    </div>
                    <div className="col-span-1 my-4">
                        {inPercent ? (
                            <Input
                                invalid={!(batchInputWeight > 0)}
                                className="w-11/12 text-right"
                                addonTitle={BatchInputType.KG}
                                type="number"
                                disabled={false}
                                value={batchInputWeight}
                                onChange={handleWeightChange}
                                step="any"
                            />
                        ) : (
                            <div className="w-full text-right pr-8 my-2">
                                <Label>{`${round(batchInputWeight, MAX_DEC)} ${
                                    BatchInputType.KG
                                }`}</Label>
                            </div>
                        )}
                    </div>
                </Row>
                <Row className="grid-cols-2 py-0">
                    <div className="col-span-1">
                        <Label helpText="Click add to add and select your ingredient">
                            Ingredients amount used (%)
                        </Label>
                    </div>
                    <div className="col-span-1 my-4 flex justify-end items-center gap-4">
                        <Label>Add ingredient</Label>
                        <Button
                            isOnlyIcon
                            iconType="add"
                            size="md"
                            variant="primary"
                            disabled={inPercent && batchInputWeight <= 0}
                            onClick={handleAddIngredients}
                        />
                    </div>
                </Row>
                <IngredientsList
                    addedIngredients={addedIngredients || []}
                    companyId={companyId}
                    batchInputWeight={batchInputWeight}
                    setBatchInputWeight={setBatchInputWeight}
                    inPercent={inPercent}
                    onRemove={handleRemove}
                    setAddedIngredients={setAddedIngredients}
                />
                {inPercent && (
                    <div className="px-4 md:px-6 py-2 grid grid-cols-12 bg-gray-100 text-sm font-medium text-gray-700">
                        <div className="col-span-7 mt-1">Total yet to allocation</div>
                        <div className="col-span-2 flex items-center justify-end pr-4">
                            <div
                                className="col-span-1 mt-1 text-left truncate"
                                title={remainingAllocation.kg.toString()}
                            >
                                {remainingAllocation.kg}
                            </div>
                            <div className="col-span-2 mt-1 ml-1">{BatchInputType.KG}</div>
                        </div>
                        <div className="col-span-2 px-4 mt-1 text-left whitespace-nowrap flex items-center gap-x-2">
                            <span>
                                {`${round(100 - remainingAllocation.percent, 2)} ${
                                    BatchInputType.PERCENT
                                }`}
                            </span>
                            {remainingAllocation.kg !== 0 && (
                                <Tooltip
                                    isTriggerPlain
                                    size="md"
                                    className="col-span-1 text-right"
                                    content="Total allocation is not 100%"
                                >
                                    <Icon className="text-red-500" type="warningFilled" />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}
                <div />
            </form>
            <IngredientTableSlideover
                companyId={companyId}
                isOpen={addIngOpen}
                onAdd={onIngredientAdd}
                onClose={onSlideoverClose}
                product={product}
            />
            <PageLoader show={loadingIngredientData} centered className="mt-4" />
        </React.Fragment>
    )
}
