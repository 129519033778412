import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageTitle } from '../../../components/PageTitle'
import { INoteItem } from '../../../types/types'
import { NotesContext } from '../../../contexts/NotesProvider'
import { NotesListPanel } from './NotesListPanel'
import { NotesContentPanel } from './NotesContentPanel'


export function DashboardNotes() {
    const { companyId }: any = useParams()
    const [selectedNote, setSelectedNote] = useState<INoteItem>()

    const { notes, loading, refetch } = useContext(NotesContext)

    useEffect(() => {
        if (notes && notes.length > 0) {
            setSelectedNote((n?: INoteItem) => {
                if (n && n.id && notes.some(item => item.id === n.id)) {
                    return n
                } else {
                    return notes[0]
                }
            })
        }
    }, [notes])

    return (
        <div className="flex-grow flex flex-col h-full">
            <PageTitle className="!m-0 flex-shrink-0" title="Notes" />
            <div className="bg-white border border-gray-300 divide-x flex flex-row flex-grow overflow-hidden">
                <NotesListPanel
                    className="flex-1 max-w-[320px]"
                    notes={notes}
                    loading={loading}
                    selected={selectedNote}
                    onClick={setSelectedNote}
                />
                <NotesContentPanel
                    className="flex-2"
                    companyId={companyId}
                    selectedNote={selectedNote}
                    refetchParent={refetch}
                />
            </div>
        </div>
    )
}
