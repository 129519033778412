import { useMemo } from 'react'
import qs from 'qs'
import _filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import negate from 'lodash/fp/negate'
import useFetch from '../hooks/useFetch'
import { ASC, PAGE_SIZE, YOUR_COMPANY, URLs, YOUR_SUPPLIER_ID } from '../constants'
import getURL from '../utils/getURL'
import { isUnknownSupplier } from '../utils'
import { ISupplier, IProductFilters } from '../types/types'


interface ISuppliersFetchParams {
    autoFetch?: boolean
    companyId: string
    companyName?: string
    filter?: any
    filters?: IProductFilters
    includeUnknownSupplier?: boolean
    includeYourCompany?: boolean
    page?: number
    query?: any
}

export default function useSuppliers({
    autoFetch = true,
    companyId,
    companyName,
    filter,
    filters,
    includeUnknownSupplier = true,
    includeYourCompany = false,
    page,
    query,
}: ISuppliersFetchParams) {
    const filterQuery = useMemo(() => {
        const q: string[] = []
        if (!isEmpty(filter)) {
            if (!isEmpty(filter['name'])) {
                q.push(`name~=${filter['name']}`)
            }
        }
        return q.join(';')
    }, [filter])

    const queryObj: any = {
        Filter: filterQuery,
        includeUnknownSupplier,
        Order: query && query.Order ? `${query.Order};${query.direction}` : `name;${ASC}`,
        PageNumber: page,
        PageSize: PAGE_SIZE,
        supplierType: filters?.archived ? 'archive' : 'active',
    }

    const queryString = qs.stringify(queryObj, {
        addQueryPrefix: true,
    })

    const { data, refetch: refetchSuppliers, loading: suppliersLoading } = useFetch({
        autoFetch: autoFetch,
        errMessage: 'Failed to fetch suppliers',
        url: getURL(URLs.Supplier.GET_ALL, { companyId }, queryString),
    })

    const suppliers = getOr([], 'data', data)
    const pageNumber = getOr(0, 'pageNumber', data)
    const pageSize = getOr(0, 'pageSize', data)
    const totalCount = getOr(0, 'totalCount', data)
    const totalPages = getOr(0, 'totalPages', data)

    const supplierList: ISupplier[] = useMemo(() => {
        if (isEmpty(suppliers)) {
            return []
        }

        const unknown = find(isUnknownSupplier, suppliers)
        const filteredSuppliers = _filter(negate(isUnknownSupplier), suppliers)
        const sortedSuppliers = !isEmpty(unknown)
            ? [unknown, ...filteredSuppliers]
            : filteredSuppliers

        if (includeYourCompany) {
            const yourCompany = {
                companyLinkId: '',
                displayId: 'your_company',
                legalName: YOUR_COMPANY,
                name: `${YOUR_COMPANY} (${companyName})`,
                supplierId: YOUR_SUPPLIER_ID,
            }
            return [yourCompany, ...sortedSuppliers]
        }
        return sortedSuppliers
    }, [suppliers])

    return {
        pageNumber,
        pageSize,
        refetchSuppliers,
        suppliers: supplierList,
        suppliersLoading,
        totalCount,
        totalPages,
    }
}
