import React, { useCallback, useContext, useState, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'

import { useMultiSelect } from '../../hooks/useMultiSelect'
import { useFiltersByUrl } from '../../hooks/useFiltersByUrl'
import { useGridControls } from '../../hooks/useGridControls'

import { Grid } from '../../components/grid/Grid'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { MultiSelectNotification } from '../../components/MultiSelectNotification'
import { PageHeader } from '../../components/PageHeader'
import { UserContext } from '../../contexts/UserProvider'
import { PAGE_TYPES, PageFilters } from '../../components/PageHeader/PageFilters'
import { SideloadCertificateContext } from '../../contexts/SideloadCertificateProvider'
import { URLs } from '../../constants'
import getURL from '../../utils/getURL'
import { SA_TYPE, EntityTypeEnum, IProduct } from '../../types/types'
import ProductSlideover from '../../slideovers/product/ProductSlideover'
import ProduceSlideover from '../../slideovers/produce/ProduceSlideover'
import { serverSideGridProps } from '../../constants/grid'
import { ProductBulkActions } from '../Products/ProductBulkActions'
import SpecialIngredient from '../../slideovers/special-ingredient/SpecialIngredientSlideover'
import ProductCloneDialog from '../Products/ProductCloneDialog'
import { suppliedProductColumns } from './SuppliedProductsColumns'


export function SuppliedProducts() {
    const { ids: selectedIds, resetSelectedIds, toggleAllItems, toggleItem } = useMultiSelect()
    const [slideoverType, setSlideoverType] = useState<SA_TYPE>()
    const [editProduceId, setEditProduceId] = useState<string>('')
    const [editSplIngId, setSplIngId] = useState<string>('')
    const [cloneProduct, setCloneProduct] = useState<IProduct>()

    const { openSideload } = useContext(SideloadCertificateContext)

    const { company } = useContext(UserContext)
    const companyId = getOr('', 'id', company)

    const { filters, setFilters } = useFiltersByUrl()
    const { deSelectAllRows, setGridApi, refreshGrid } = useGridControls()

    const handleRefetch = useCallback(() => {
        refreshGrid()
    }, [refreshGrid])

    const handleRefetchForBulk = useCallback(() => {
        resetSelectedIds()
        deSelectAllRows()
        handleRefetch()
    }, [handleRefetch, resetSelectedIds])

    const setSideLoadProductId = useCallback((productId: string) => {
        openSideload({
            onSuccess: handleRefetch,
            url: getURL(URLs.Certificate.SIDELOAD, { companyId }, `?productId=${productId}`),
        })
    }, [])

    const handleFilterChange = useCallback(
        (filter: any) => {
            setFilters({
                name: filter,
            })
        },
        [setFilters]
    )

    const onAddProduct = useCallback(() => {
        setSlideoverType(SA_TYPE.Product)
    }, [])

    const onAddProduce = useCallback(() => {
        setSlideoverType(SA_TYPE.Produce)
    }, [])

    const onAddSplIngredient = useCallback(() => {
        setSlideoverType(SA_TYPE.SpecialIngredient)
    }, [])

    const handleFilterSubmit = (filters: any) => {
        setFilters(filters)
    }

    const onEditProduce = (id: string) => {
        setSlideoverType(SA_TYPE.Produce)
        setEditProduceId(id)
    }

    const onEditSplIngredient = (id: string) => {
        setSlideoverType(SA_TYPE.SpecialIngredient)
        setSplIngId(id)
    }

    const onSlideoverClose = useCallback(() => {
        setSlideoverType(undefined)
        setEditProduceId('')
        setSplIngId('')
    }, [])

    const handleCancelSelected = () => {
        toggleAllItems(false, selectedIds)
        deSelectAllRows()
        refreshGrid()
    }

    if (isEmpty(companyId)) {
        return <Redirect to="/no-company" />
    }

    const handleCloneDialogClose = useCallback(() => {
        setCloneProduct(undefined)
    }, [])

    const urlOptions = useMemo(() => ({ companyId }), [companyId])

    return (
        <GridPageLayout isPadding>
            <PageHeader
                title={PAGE_TYPES.SUPPLIED_PRODUCTS}
                primaryAction={onAddProduct}
                secondaryAction={onAddProduce}
                thirdBtnAction={onAddSplIngredient}
                onSearch={handleFilterChange}
                filterList={<PageFilters pageType={PAGE_TYPES.SUPPLIED_PRODUCTS} />}
                onFilterSubmit={handleFilterSubmit}
            />
            <GridPageLayout>
                <Grid
                    columnDefs={suppliedProductColumns}
                    context={{
                        companyId,
                        onProductClone: setCloneProduct,
                        refetch: handleRefetch,
                        selectedIds,
                        setEditProduceId: onEditProduce,
                        setEditSpecialIngredientId: onEditSplIngredient,
                        setSideLoadProductId,
                        toggleAllItems,
                        toggleItem,
                    }}
                    entityType={EntityTypeEnum.PRODUCT}
                    filters={filters}
                    onApiAvailable={setGridApi}
                    setFilters={setFilters}
                    url={URLs.Product.SUPPLIED_PRODUCTS}
                    urlOptions={urlOptions}
                    {...serverSideGridProps}
                />
            </GridPageLayout>
            <ProductSlideover
                companyId={companyId}
                allowExisting={false}
                isSuppliedProduct
                isOpen={slideoverType === SA_TYPE.Product}
                onClose={onSlideoverClose}
                onSaved={handleRefetch}
            />
            <ProduceSlideover
                companyId={companyId}
                allowExisting={false}
                productId={editProduceId}
                isOpen={slideoverType === SA_TYPE.Produce}
                onClose={onSlideoverClose}
                onSaved={handleRefetch}
            />
            <SpecialIngredient
                companyId={companyId}
                allowExisting={false}
                productId={editSplIngId}
                isOpen={slideoverType === SA_TYPE.SpecialIngredient}
                onClose={onSlideoverClose}
                onSaved={handleRefetch}
            />
            <ProductCloneDialog
                companyId={companyId}
                product={cloneProduct}
                open={!isEmpty(cloneProduct)}
                onSaved={handleRefetch}
                onClose={handleCloneDialogClose}
            />
            {!!selectedIds.length && (
                <MultiSelectNotification
                    count={selectedIds.length}
                    onCancel={handleCancelSelected}
                    right={<ProductBulkActions ids={selectedIds} refetch={handleRefetchForBulk} />}
                    title="Supplied Product"
                />
            )}
        </GridPageLayout>
    )
}
