import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { LicenseManager } from 'ag-grid-enterprise'
import Auth0ProviderWithHistory from './auth/Authprovider'

import App from './App'
import * as serviceWorker from './serviceWorker'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'
import './styles/index.css'
import './styles/tailwind.css'
import 'react-day-picker/lib/style.css'


LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE}`)

ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
