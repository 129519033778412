import React from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'


interface INoDataPlaceholderProps extends React.ComponentPropsWithoutRef<'div'> {
    placeholderContainer?: React.ReactNode
}

export const NoDataPlaceholder = ({
    className,
    placeholderContainer = 'No data available',
}: INoDataPlaceholderProps) => {
    return (
        <div
            className={classNames(
                'w-full flex items-center space-x-2',
                'p-4 rounded',
                'bg-gray-100 text-gray-900 font-semibold',
                className
            )}
        >
            <Icon type="warningSquare" /> <span>{placeholderContainer}</span>
        </div>
    )
}

export default NoDataPlaceholder
