import React, { useContext } from 'react'
import classNames from 'classnames'
import Xarrow from 'react-xarrows'
import { checkOwnership } from '../../../utils/supplyChainUtils'
import { TileMenuContext } from '../TileMenuProvider'


export default function ConnectingLines({ line }: any) {
    const { companyId, rootParentId, ...rest } = line
    const { ownership } = useContext(TileMenuContext)
    const showOwnership = checkOwnership(ownership, [companyId, rootParentId])

    return (
        <Xarrow
            {...rest}
            lineColor={showOwnership ? '#0EA5E9' : '#D1D5DB'}
            strokeWidth={showOwnership ? 4 : 2}
            divContainerProps={{
                className: classNames(
                    'motion-safe:transition-all duration-200',
                    showOwnership && 'z-1'
                ),
            }}
            arrowBodyProps={{
                className: 'cursor-pointer motion-safe:transition-colors duration-200',
            }}
        />
    )
}
