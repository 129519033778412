import { URLs } from '../constants'
import getURL from '../utils/getURL'
import useFetch from './useFetch'
import { useCompanyId } from './useCompanyId'


export function useSupplyChainStats() {
    const companyId = useCompanyId()
    const { data: supplyChainStats, loading, refetch: refetchSupplyChainStats } = useFetch({
        errMessage: 'Failed to fetch supply chain stats',
        url: getURL(URLs.DashboardStats.SUPPLY_CHAIN_STATS, { companyId }),
    })
    return {
        refetchSupplyChainStats,
        supplyChainStats,
        supplyChainStatsLoading: loading,
    }
}
