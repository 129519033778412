import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'


interface IExpandContainerProps {
    expanded: boolean
    children: any
}

export default function ExpandContainer({ expanded, children }: IExpandContainerProps) {
    return (
        <AnimatePresence initial={false}>
            {expanded && (
                <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        collapsed: { height: 0, opacity: 0 },
                        open: { height: 'auto', opacity: 1 },
                    }}
                    transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                    <motion.div
                        variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
                        transition={{ duration: 0.3 }}
                    >
                        {children}
                    </motion.div>
                </motion.section>
            )}
        </AnimatePresence>
    )
}
