import React from 'react'
import classNames from 'classnames'
import { IClassNameProps } from '../types/types'
import ButterflyLoader from './ButterflyLoader'


interface IPageLoaderProps extends IClassNameProps {
    centered?: boolean
    sizeClass?: string
    show?: boolean
}

export default function PageLoader({
    centered = false,
    className = '',
    sizeClass = 'w-16 h-16',
    show = true,
}: IPageLoaderProps) {
    if (!show) {
        return null
    }
    return (
        <div
            className={classNames(
                'flex items-center bg-opacity-80 rounded-lg z-toast',
                centered && 'justify-center',
                className
            )}
        >
            <div className={classNames('flex justify-center items-center', sizeClass)}>
                <ButterflyLoader />
            </div>
        </div>
    )
}
