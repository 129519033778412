import React, { useEffect, useState, useContext } from 'react'
import { useDebounce } from 'react-use'
import { Input, Label, Textarea } from '@mondra/ui-components'
import find from 'lodash/fp/find'

import { ProductSettingContext } from '../contexts/ProductSettingProvider'
import { IProduct } from '../types/types'
import ProductVisibility from './ProductVisibility'
import { NutritionEmailLink } from './NutritionInformation'
import ProductImages from './ProductImages'
import ProductAwards from './ProductAwards'


interface ICertSettings {
    product: IProduct
    companyId: string
    updateProduct: any
}

export default function CertSettings({
    product: initialProduct,
    companyId,
    updateProduct,
}: ICertSettings) {
    const { readOnly } = useContext(ProductSettingContext)
    const [product, setProduct] = useState<IProduct>(initialProduct)
    const [productChanged, setProductChanged] = useState<any>()

    useEffect(() => {
        setProduct(initialProduct)
    }, [initialProduct])

    const [,] = useDebounce(
        () => {
            if (productChanged) {
                updateProduct(product)
            }
        },
        1500,
        [productChanged]
    )

    function handleSaveProduct(changes: any) {
        setProduct((p: any) => ({
            ...p,
            ...changes,
        }))
    }

    function handleUpdateProduct() {
        setProductChanged(Date.now())
    }

    function handleSaveUpdateProduct(changes: any) {
        setProduct((p: any) => ({
            ...p,
            ...changes,
        }))

        setProductChanged(Date.now())
    }

    function handleProductImages(images: any) {
        if (images) {
            setProduct((p: any) => ({
                ...p,
                productBannerImageUrl: images.bannerImageUrl,
                productImageUrl: images.imageUrl,
            }))
        }
    }

    const latestCert = find({ id: product?.currentCertificateId }, product?.certificates)

    return (
        <div className="py-4 px-2">
            <div className="divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Certification settings
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Set your certification settings and upload images.
                        </p>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <ProductVisibility
                            disabled={readOnly || latestCert?.noGrade}
                            noGrade={latestCert?.noGrade}
                            isPrivateCertificate={product.isPrivateCertificate}
                            onChange={handleSaveUpdateProduct}
                        />
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <Label
                                htmlFor="productDescription"
                                className="font-medium sm:mt-px sm:pt-2"
                            >
                                Product description to use on your certificate
                            </Label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <Textarea
                                    disabled={readOnly}
                                    name="productDescription"
                                    value={product.description || ''}
                                    onChange={(e: any) => {
                                        const productDescription = e.target.value
                                        handleSaveProduct({ productDescription })
                                    }}
                                    onBlur={handleUpdateProduct}
                                    className="w-full"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <Label htmlFor="productUrl" className="font-medium sm:mt-px sm:pt-2">
                                Product URL
                            </Label>

                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <Input
                                    disabled={readOnly}
                                    name="productUrl"
                                    value={product.productUrl || ''}
                                    onChange={(e: any) => {
                                        const productUrl = e.target.value
                                        handleSaveProduct({ productUrl })
                                    }}
                                    onBlur={handleUpdateProduct}
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <Label
                                htmlFor="servingSizeLabel"
                                className="font-medium sm:mt-px sm:pt-2"
                            >
                                Serving size description
                            </Label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                                <div className="font-medium  text-gray-700 sm:text-sm mr-2">
                                    Per
                                </div>
                                <Input
                                    disabled={readOnly}
                                    name="servingSizeLabel"
                                    id="servingSizeLabel"
                                    min="0"
                                    value={product.servingSizeLabel}
                                    onChange={(e: any) => {
                                        const servingSizeLabel = e.target.value
                                        handleSaveProduct({ servingSizeLabel })
                                    }}
                                    onBlur={handleUpdateProduct}
                                />
                            </div>
                        </div>
                        <ProductImages
                            readOnly={readOnly}
                            companyId={companyId}
                            productId={product.id}
                            onProductImagesChange={handleProductImages}
                        />
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <Label
                                htmlFor="assessmentDescription"
                                className="font-medium sm:mt-px sm:pt-2"
                            >
                                Assessment description
                            </Label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <Textarea
                                    className="w-full"
                                    disabled={readOnly}
                                    name="assessmentDescription"
                                    value={product.assessmentDescription || ''}
                                    onChange={(e: any) => {
                                        const assessmentDescription = e.target.value
                                        handleSaveProduct({ assessmentDescription })
                                    }}
                                    onBlur={handleUpdateProduct}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Certification & Awards
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <ProductAwards
                                    readOnly={readOnly}
                                    companyId={companyId}
                                    productId={product.id}
                                    setProduct={setProduct}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pt-4 sm:pt-6">
                        <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Nutrition information
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                You can optionally include your nutritional table for your product’s
                                certificate page. If you do not populate this the information table
                                will not be shown.
                            </p>
                        </div>
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Email
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <NutritionEmailLink
                                        productName={product.name}
                                        readOnly={readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
