import React, { useCallback, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import isFunction from 'lodash/fp/isFunction'
import some from 'lodash/fp/some'
import { Icon } from '@mondra/ui-components'
import { SubNavTab } from './SubNavTab'

/**
 * TODO: Upgrade react router version in platform and use this component from library
 */
export interface INavTabOption extends Omit<any, 'className'> {
    matchPaths?: string[]
    className?: string | ((props: { isActive: boolean }) => string | undefined)
    children?: ISubNavOption[]
    disabled?: boolean
    label: string | React.ReactChild | ((props: { isActive: boolean }) => string | React.ReactChild)
    search?: string
}
interface INavTabProps {
    childWrapperClass?: string
    heightClass?: string
    option: INavTabOption
}
export type ISubNavOption = Omit<INavTabOption, 'children'>
export function NavTab({
    childWrapperClass,
    heightClass,
    option: { children, className, disabled, label, matchPaths, onClick, to, search },
}: INavTabProps) {
    const hasSubnav = children && children.length > 0
    const { pathname } = useLocation()
    const isParentActive
        = (to && to.includes(pathname)) || some(c => pathname.includes(c), matchPaths)
    const isChildActive = useMemo(() => some(c => c.to.toString().includes(pathname), children), [
        pathname,
        children,
    ])
    const isActive = isParentActive || isChildActive
    const handleClick = useCallback(
        (e: any) => {
            if (disabled || !to) {
                e.preventDefault()
            } else {
                onClick && onClick(e)
            }
        },
        [disabled, to, onClick]
    )
    const navLinkUrl = search ? `${to}${search}` : to
    return (
        <li className="relative group">
            <NavLink
                to={navLinkUrl}
                onClick={handleClick}
                className={classNames(
                    'flex flex-row justify-start items-center w-full no-underline',
                    getFocusStyle({ ringOffset: 'focus-visible:ring-offset-0' }),
                    disabled && 'cursor-not-allowed',
                    heightClass
                )}
            >
                <div
                    className={classNames(
                        'flex flex-row justify-start items-center gap-x-2 px-4 py-2 h-full text-sm font-medium leading-6',
                        isFunction(className) ? className({ isActive }) : className,
                        !isActive && !disabled && tabClasses.label.normal,
                        isActive && tabClasses.label.active,
                        disabled && tabClasses.label.disabled
                    )}
                >
                    {isFunction(label) ? label({ isActive }) : label}
                    {hasSubnav && <Icon type="chevronDown" className="text-current" size={16} />}
                </div>
                <div
                    className={classNames(
                        'absolute w-full bottom-0',
                        !isActive && !disabled && tabClasses.border.normal,
                        isActive && tabClasses.border.active,
                        disabled && tabClasses.border.disabled
                    )}
                />
            </NavLink>
            {hasSubnav && (
                <div className="absolute left-0 hidden group-hover:block">
                    {children && (
                        <SubNavTab options={children as any} className={childWrapperClass} />
                    )}
                </div>
            )}
        </li>
    )
}
const getFocusStyle = (params?: any) => {
    const {
        ringOffset = 'focus-visible:ring-offset-2',
        ringOffsetColor = 'focus-visible:ring-offset-white',
    } = params || {}
    return `focus:outline-hidden focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-violet-600 ${ringOffset} ${ringOffsetColor}`
}
const tabClasses = {
    border: {
        active: 'bg-primary-500 dark:bg-primary-500 h-1',
        disabled: 'bg-gray-300 dark:bg-transparent h-px',
        normal: 'bg-gray-300 dark:bg-transparent dark:group-hover:bg-gray-500 h-px group-hover:h-1',
    },
    label: {
        active: 'text-gray-800 dark:text-gray-100',
        disabled: 'text-gray-300 dark:text-gray-500',
        normal:
            'text-gray-500 dark:text-gray-400 group-hover:text-gray-800 dark:group-hover:text-gray-400 dark:group-hover:bg-gray-800',
    },
}
