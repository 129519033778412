import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/fp/isEmpty'
import { Icon } from '@mondra/ui-components'
import {
    Certificate,
    ICertCardProps,
    IClassNameProps,
    IChildrenProps,
    ProductDetailTypes,
    CertificateStateEnum,
} from '../types/types'
import { orderByLastUpdated } from '../utils/orderbyLastUpdated'
import CertifiedCertCard from '../certificates-history/CertifiedCertCard'
import CertificationExpiredCertCard from '../certificates-history/CertificationExpiredCertCard'
import DraftCertCard from '../certificates-history/DraftCertCard'
import { getGradeHeaderColor } from '../constants/gradeHeaderColors'
import useProductDetails from '../hooks/useProductDetails'


export default function CertHistoryCards({ companyId, productId, supplierId }: any) {
    const { details: certificates, loading } = useProductDetails({
        companyId,
        productId,
        supplierId,
        type: ProductDetailTypes.CERTIFICATES,
    })
    return (
        <div className="py-4 space-y-4 lg:space-y-6">
            <h3 className="text-gray-900 text-xl font-medium">Certification history</h3>
            <div className="space-y-8">
                {loading && <div>Please wait while certificates are being loaded</div>}
                {!loading && isEmpty(certificates) && <div>Product is not yet certified!</div>}
                {certificates?.length > 0
                    && certificates
                        .sort(orderByLastUpdated)
                        .map((cert: Certificate) => <CertHistoryCard key={cert.id} cert={cert} />)}
            </div>
        </div>
    )
}

export function CertHistoryCard({ cert }: ICertCardProps) {
    if (cert.saState === CertificateStateEnum.Certified) {
        return <CertifiedCertCard cert={cert} />
    }

    if (cert.saState === CertificateStateEnum.CertificationExpired) {
        return <CertificationExpiredCertCard cert={cert} />
    }

    return <DraftCertCard cert={cert} />
}

interface ICertCardRowProps extends IClassNameProps, IChildrenProps {}

CertHistoryCards.Row = function Row({ className, children, ...rest }: ICertCardRowProps) {
    return (
        <div className={classNames('flex items-stretch space-x-8', className)} {...rest}>
            {children}
        </div>
    )
}

interface ICertTimelineProps extends IClassNameProps {
    title: string
    /**
     * Date time for time label in YYYY-MM-DDThh:mm:ssTZD - machine readable
     */
    dateTime: string
    /**
     * Date time formatted label for display purpose
     */
    dateTimeDisplay: string
    icon: React.ReactNode
}

CertHistoryCards.CertTimeline = function Row({
    className,
    title,
    dateTime,
    dateTimeDisplay,
    icon,
    ...rest
}: ICertTimelineProps) {
    return (
        <div
            className={classNames(
                'flex items-start justify-end w-1/3 self-stretch relative',
                className
            )}
            {...rest}
        >
            <div className="text-right mr-7">
                <h4 className="text-lg text-primary-500 font-semibold">{title}</h4>
                <time dateTime={dateTime}>{dateTimeDisplay}</time>
            </div>
            <div className="relative">{icon}</div>
            <div className="absolute right-5 top-10 h-full w-0.5 -mr-0.5 bg-gray-200" />
        </div>
    )
}

interface ICertCardBoxProps extends IClassNameProps, IChildrenProps {
    className?: string
    children?: any
}

CertHistoryCards.Box = function CertCardBox({ children, ...rest }: ICertCardBoxProps) {
    return (
        <div className="relative shadow rounded bg-white rounded-t-lg w-2/3" {...rest}>
            {children}
        </div>
    )
}

type BG_KEY = 'none' | 'requested' | 'error' | 'Certified' | undefined

interface IHeaderProps extends React.ComponentProps<'div'> {
    bgKey?: BG_KEY
    title: string
    desc: string
    isSideLoaded?: boolean
    right?: React.ReactNode
}

CertHistoryCards.Header = function Header({
    bgKey = 'none',
    title,
    desc,
    isSideLoaded,
    right,
    ...rest
}: IHeaderProps) {
    const headerColor = getGradeHeaderColor(bgKey)
    return (
        <div
            className={classNames(
                'flex-shrink-0 p-4 sm:px-6 sm:py-4 flex items-center justify-between rounded-t-lg space-x-4',
                headerColor.bg,
                headerColor.text
            )}
            {...rest}
        >
            <div className="flex-1">
                <h5 className="flex flex-row items-center font-medium text-lg">
                    {title}{' '}
                    {isSideLoaded && (
                        <Icon
                            type="fileImport"
                            size={20}
                            className="ml-1 text-lg"
                            title="Sideloaded certificate calculated and loaded by the Mondra team. See history."
                        />
                    )}
                </h5>
                <p className={classNames('text-sm', headerColor.desc)}>{desc || 'Per 100g'}</p>
            </div>
            <div className="flex-shrink-0">{right}</div>
        </div>
    )
}

CertHistoryCards.Body = function Body({ className, children }: any) {
    return (
        <div
            className={classNames(
                'px-4 pt-4 pb-6 my-2 flex flex-col lg:flex-row space-y-4 lg:space-x-1 lg:space-y-0',
                className
            )}
        >
            {children}
        </div>
    )
}
