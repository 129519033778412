import React, { useContext } from 'react'
import intersection from 'lodash/fp/intersection'


interface IFeatureFlags {
    flags: string[]
}

export const FeatureFlagContext = React.createContext<IFeatureFlags>({
    flags: [],
})

export default function FeatureFlagProvider({ flags, children }: any) {
    return <FeatureFlagContext.Provider value={{ flags }}>{children}</FeatureFlagContext.Provider>
}

interface IGlobalFeatureFlag {
    flagOnly: string[]
    children: React.ReactChild | React.ReactNode
}

export function GlobalFeatureFlag({ flagOnly, children }: IGlobalFeatureFlag) {
    const { flags } = useContext(FeatureFlagContext)

    return <>{intersection(flags, flagOnly).length > 0 && children} </>
}

interface IFeatureFlag extends IGlobalFeatureFlag {
    flags: string[]
    children: React.ReactChild | React.ReactNode
}

export function FeatureFlag({ flags, flagOnly, children }: IFeatureFlag) {
    return <>{intersection(flags, flagOnly).length > 0 && children} </>
}
