import React from 'react'
import { Row } from '../../components'
import { ITransportType } from '../../types/stages'
import InfoTooltip from '../components/InfoTooltip'


export interface ImpactScoreCellProps {
    disabled?: boolean
    transportType: ITransportType
}

export default function Information({ disabled = false, transportType }: ImpactScoreCellProps) {
    return (
        <InfoTooltip disabled={disabled}>
            <form className="divide-y divide-gray-200 text-gray-700">
                <div className={'rounded-t-lg p-4 bg-white'}>
                    <div className={'ml-2 rounded-t-lg text-base leading-6 font-semibold'}>
                        {transportType?.name}
                    </div>
                </div>
                <Row>
                    <div className={'text-sm leading-5 font-semibold'}>Operates in</div>
                    <div className="mt-2 text-sm leading-5 font-normal">
                        {transportType?.country}
                    </div>
                </Row>
                <Row>
                    <div className={'text-sm leading-5 font-semibold'}>Medium</div>
                    <div className="mt-2 text-sm leading-5 font-normal">
                        {transportType?.medium}
                    </div>
                </Row>
                <Row>
                    <div className={'text-sm leading-5 font-semibold'}>Condition</div>
                    <div className="mt-2 text-sm leading-5 font-normal">
                        {transportType?.condition}
                    </div>
                </Row>
                <Row>
                    <div className={'text-sm leading-5 font-semibold'}>Short description</div>
                    <div className="mt-2 text-sm leading-5 font-normal">
                        {transportType?.shortDescription}
                    </div>
                </Row>
                <Row>
                    <div className={'text-sm leading-5 font-semibold'}>Process name</div>
                    <div className="mt-2 text-sm leading-5 font-normal">
                        {transportType?.processName}
                    </div>
                </Row>
            </form>
        </InfoTooltip>
    )
}
