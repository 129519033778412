import React, { useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { IProduct, NodeType } from '../../types/types'
import { ITransport } from '../../types/stages'
import getURL from '../../utils/getURL'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import useFetch from '../../hooks/useFetch'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import { TRANSPORT } from '../../constants/toastNotifications'
import { ISlideoverProps } from '../../types/slideover-types'
import { NotesButton } from '../../components'
import Slideover from '../components/Slideover'
import TransportForm from './TransportForm'


const formId = 'transport-form'

interface ITransportProps extends Omit<ISlideoverProps, 'type'> {
    originProduct?: IProduct
    destinationProduct?: IProduct
}

const defaultTransport = {
    id: '',
    linkedProductId: '',
    noTransportReason: '',
    productId: '',
    transportingProduct: false,
    transportLegs: [],
    transportProductId: '',
}

const defaultProduct = {} as IProduct

export default function TransportSlideover({
    companyId,
    isOpen,
    level,
    onClose,
    onSaved,
    originProduct = defaultProduct,
    destinationProduct = defaultProduct,
}: ITransportProps) {
    const [isEdit, setIsEdit] = useState(false)
    const [transport, setTransport] = useState<ITransport>(defaultTransport)
    const { showSuccess } = useTopCenterToast()
    const { data: transportData } = useFetch({
        autoFetch: isOpen && !isEmpty(originProduct.id) && !isEmpty(destinationProduct.id),
        url: getURL(URLs.Transport.GET, {
            companyId,
            id: originProduct?.id,
            productId: destinationProduct.id,
        }),
    })

    useEffect(() => {
        if (!isEmpty(transportData.id)) {
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
        setTransport(transportData)
    }, [transportData])

    const onUpdate = useCallback(() => {
        showSuccess({ description: isEdit ? TRANSPORT.UPDATE : TRANSPORT.CREATE })
        onSaved && onSaved()
        handleClose()
    }, [])

    const url = isEdit
        ? getURL(URLs.Transport.UPDATE, { companyId, id: transport?.id })
        : getURL(URLs.Transport.ADD, { companyId })

    const { saving, update } = useUpdateFormData({
        method: isEdit ? METHOD_PUT : METHOD_POST,
        onUpdate,
        url,
    })

    const handleSubmit = (data: any) => {
        update(data)
    }

    function handleClose() {
        setIsEdit(false)
        setTransport(defaultTransport)
        onClose && onClose(NodeType.TRANSPORT)
    }

    return (
        <Slideover
            wider="max-w-3xl"
            isOpen={isOpen}
            formId={formId}
            level={level}
            title="Configure Transport"
            type={NodeType.TRANSPORT}
            saving={saving}
            onClose={handleClose}
            footerChildren={
                <NotesButton
                    className="relative"
                    stage={NodeType.TRANSPORT}
                    productId={destinationProduct.id || ''}
                />
            }
            footerProps={{
                className: 'flex flex-row justify-between w-full',
            }}
        >
            <TransportForm
                companyId={companyId}
                transport={transport}
                isEdit={isEdit}
                origin={originProduct}
                destination={destinationProduct}
                formId={formId}
                onSubmit={handleSubmit}
            />
        </Slideover>
    )
}
