import { ColDef } from 'ag-grid-community'
import startCase from 'lodash/fp/startCase'
import { SelectRowColDef } from '../../constants/grid'


export const ingredientColumns: ColDef[] = [
    SelectRowColDef,
    {
        field: 'name',
        headerName: 'Ingredient',
    },
    {
        field: 'saType',
        headerName: 'Type',
        maxWidth: 250,
        valueFormatter: ({ value }) => startCase(value),
    },
]
