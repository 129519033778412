import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Autocomplete, FormDialog, Icon, Input, Textarea } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import find from 'lodash/fp/find'
import { getDefaultColor } from '../../utils/colorUtils'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import { SUPPLIER } from '../../constants/toastNotifications'
import useCountries from '../../hooks/useCountries'
import getURL from '../../utils/getURL'
import useFetch from '../../hooks/useFetch'
import { ICountry, ISupplier } from '../../types/types'
import UploadImage from '../../components/UploadImage'
import Image from '../../components/Image'


interface ISupplierPopupProps {
    companyId: string
    edit?: boolean
    onClose(): void
    open: boolean
    refetch(): void
    supplier?: ISupplier
}

const imageTypes = ['image/jpg', 'image/jpeg']

export default function SupplierPopup({
    companyId,
    edit = false,
    onClose,
    open = false,
    refetch,
    supplier,
}: ISupplierPopupProps) {
    const [avatarColor, setAvatarColor] = useState(
        edit ? getDefaultColor(supplier?.avatarColor) : getDefaultColor()
    )
    const [supplierData, setSupplierData] = useState<ISupplier>()
    const [logo, setLogo] = useState(null)
    const [logoUrl, setLogoUrl] = useState(null)
    const [supplierSaved, setSupplierSaved] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState<ICountry>()
    const { countries, loading: loadingCountries } = useCountries({ autoFetch: open })

    useEffect(() => {
        if (!open) {
            setSupplierData(undefined)
            setSelectedCountry(undefined)
            return
        }
        setAvatarColor(edit ? getDefaultColor(supplier?.avatarColor) : getDefaultColor())
    }, [open])

    const { data, loading } = useFetch({
        autoFetch: !isEmpty(supplier?.supplierId),
        defaultRes: {},
        url: getURL(URLs.Supplier.GET, { companyId, supplierId: supplier?.supplierId }),
    })

    useEffect(() => {
        setSupplierData(data)
        const country = find(c => c.id === data?.address?.countryId, countries)
        if (!isEmpty(country)) {
            setSelectedCountry(country)
        }
    }, [data])

    const { showSuccess } = useTopCenterToast()

    const { register, errors, handleSubmit, setValue } = useForm()

    useEffect(() => {
        if (supplierData) {
            setValue('name', supplierData.name)
        }
    }, [supplierData])
    const onSupplierUpdate = (data: any) => {
        setSupplierData(data)
        if (isEmpty(logoUrl)) {
            onLogoUpdate()
        } else {
            setSupplierSaved(true)
        }
    }

    const { saving, update } = useUpdateFormData({
        method: edit ? METHOD_PUT : METHOD_POST,
        onUpdate: onSupplierUpdate,
        url: edit
            ? getURL(URLs.Supplier.UPDATE, { companyId, supplierId: supplierData?.supplierId })
            : getURL(URLs.Supplier.ADD, { companyId }),
    })

    const onLogoUpdate = () => {
        showSuccess({
            description: edit ? SUPPLIER.UPDATE : SUPPLIER.ADD,
        })
        onClose()
        refetch && refetch()
        setLogo(null)
        setLogoUrl(null)
        setSupplierData(undefined)
    }

    const logApiUrl = getURL(URLs.Supplier.ADD_LOGO, {
        companyId,
        supplierId: supplierData?.supplierId,
    })
    const { saving: savinglogo, update: updateLogo } = useUpdateFormData({
        contentType: false,
        method: METHOD_POST,
        onError: err => {
            console.log(err)
        },
        onUpdate: onLogoUpdate,
        url: logApiUrl,
    })

    useEffect(() => {
        if (supplierSaved) {
            if (logo) {
                onSubmit()
            }
            setSupplierSaved(false)
        }
    }, [supplierSaved, logo])

    function submit(data: any) {
        const address = {
            city: data.city,
            country: selectedCountry?.countryName,
            countryCode: setSelectedCountry,
            countryCodeISOAlpha2: selectedCountry?.isoAlpha2,
            countryCodeISOAlpha3: selectedCountry?.isoAlpha3,
            countryId: selectedCountry?.id,
            isArchived: false,
            isFullStreetAddress: true,
            latitude: '',
            longitude: '',
            state: data.state,
            streetAddress: data.street,
        }
        if (data.name.trim()) {
            const updatedSupplier = {
                address,
                avatarColor: avatarColor.color,
                avatarTextColor: avatarColor.text,
                companyId: companyId,
                companyNumber: data.companyNumber,
                description: data.description,
                legalName: data.legalName,
                name: data.name.trim(),
            }
            update(edit ? { ...supplierData, ...updatedSupplier } : updatedSupplier)
        }
    }

    const handleLogoUpload = (event: any) => {
        const file = event.target.files[0]
        setLogo(file)
        setLogoUrl(URL.createObjectURL(file) as any)
    }

    function onSubmit() {
        const formData = new FormData()
        if (logo) {
            formData.append('logo', logo as any)
        }
        updateLogo(formData)
    }

    function onFormClose() {
        setLogo(null)
        setLogoUrl(null)
        onClose()
    }

    const handleCountryChange = useCallback(({ selectedItem }: { selectedItem: ICountry }) => {
        setSelectedCountry(selectedItem)
    }, [])

    return (
        <FormDialog
            formId="supplier-form"
            open={open}
            onClose={onFormClose}
            title={edit ? 'Edit Supplier' : 'Add Supplier'}
            primaryBtnText={saving ? 'Saving...' : edit ? 'Update' : 'Add'}
            primaryBtnDisabled={saving}
        >
            <form id="supplier-form" onSubmit={!saving ? handleSubmit(submit) : () => null}>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="name" className="mt-2">
                        Supplier name <sup>*</sup>
                    </label>
                    <Input
                        disabled={saving || loading}
                        className="col-span-2 w-full"
                        name="name"
                        id="name"
                        defaultValue={supplierData?.name}
                        ref={register({ required: true })}
                    />
                    {errors.name && (
                        <div className="leading-normal text-sm text-red-600 mt-1">
                            Supplier name is required
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="legalName" className="mt-2">
                        Legal name
                    </label>
                    <Input
                        disabled={saving || loading}
                        className="col-span-2 w-full"
                        name="legalName"
                        id="lagelName"
                        defaultValue={supplierData?.legalName}
                        ref={register}
                    />
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="companyNumber" className="mt-2">
                        Company number
                    </label>
                    <Input
                        disabled={saving || loading}
                        className="col-span-2 w-full"
                        name="companyNumber"
                        id="companyNumber"
                        defaultValue={supplierData?.companyNumber}
                        ref={register}
                    />
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="companyName" className="mt-2 flex flex-row items-center">
                        Company Name <Icon type="link" size={18} className="ml-2" />
                    </label>
                    <Input
                        disabled
                        className="col-span-2 w-full"
                        name="companyName"
                        id="companyName"
                        defaultValue={supplierData?.companyName}
                    />
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="legalName" className="mt-2">
                        Description
                    </label>
                    <Textarea
                        id="description"
                        name="description"
                        className="col-span-2 w-full"
                        defaultValue={supplierData?.description}
                        disabled={saving || loading}
                        maxLength={2000}
                        ref={register}
                    />
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="street" className="mt-2">
                        Street
                    </label>
                    <Input
                        disabled={saving || loading}
                        className="col-span-2 w-full"
                        name="street"
                        id="street"
                        defaultValue={supplierData?.address?.streetAddress}
                        ref={register}
                    />
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="city" className="mt-2">
                        City
                    </label>
                    <Input
                        disabled={saving || loading}
                        className="col-span-2 w-full"
                        name="city"
                        id="city"
                        defaultValue={supplierData?.address?.city}
                        ref={register}
                    />
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="state" className="mt-2">
                        State
                    </label>
                    <Input
                        disabled={saving || loading}
                        className="col-span-2 w-full"
                        name="state"
                        id="state"
                        defaultValue={supplierData?.address?.state}
                        ref={register}
                    />
                </div>
                <div className="grid grid-cols-3 mb-4">
                    <label htmlFor="country" className="mt-2">
                        Country
                    </label>
                    <Autocomplete
                        containerClass="col-span-2 w-full"
                        placeholder={loadingCountries ? 'Loading...' : 'Select a country'}
                        selected={selectedCountry}
                        disabled={loadingCountries || loading || saving}
                        onChange={handleCountryChange}
                        options={countries}
                        invalid={Boolean(errors.originCountryCode)}
                    />
                </div>
                <div className="grid grid-cols-3">
                    <label htmlFor="companyName" className="mt-2 flex flex-row items-center">
                        Logo
                    </label>
                    <div>
                        <div className="flex">
                            <div className="w-24 h-24 max-h-full max-w-full rounded border border-gray-200 shadow-sm flex items-center">
                                <Image
                                    url={logoUrl || supplierData?.logoUrl}
                                    defaultUrl="/certificate_product_placeholder.png"
                                    alt="product thumbnail"
                                />
                            </div>
                        </div>

                        <div className="mt-2 flex items-center space-x-2">
                            <UploadImage
                                className="text-left my-1 ml-2"
                                id="productImage2"
                                imageTypes={imageTypes}
                                loading={supplierData?.logoUrl && saving && savinglogo}
                                onUpload={handleLogoUpload}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </FormDialog>
    )
}
