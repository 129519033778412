import { EcoImpactIds, IEcoImpactMeasures } from '../types/ecoImpacts'


export const ecoImpactMeasures: IEcoImpactMeasures = {
    [EcoImpactIds.EIID001]: {
        label: 'Carbon',
        measure: 'CO₂ eq.',
        pieceUnit: 'kg',
        unit: 'g',
    },
    [EcoImpactIds.EIID002]: {
        label: 'Water Usage',
        measure: 'eq.',
        pieceUnit: 'm3',
        unit: 'L',
    },
    [EcoImpactIds.EIID003]: {
        label: 'Water Pollution',
        measure: 'PO₄³⁻ eq.',
        pieceUnit: 'kg',
        unit: 'g',
    },
    [EcoImpactIds.EIID004]: {
        label: 'Biodiversity',
        measure: 'SLI',
        unit: '',
    },
}

export default ecoImpactMeasures

export const SLI_DESC = 'Species Loss Index'

export const defaultImpacts: any = {
    bio: 0,
    carbon: 0,
    eutro: 0,
    water: 0,
}

export const impactNameByCode = {
    [EcoImpactIds.EIID001]: 'carbon',
    [EcoImpactIds.EIID002]: 'water',
    [EcoImpactIds.EIID003]: 'eutro',
    [EcoImpactIds.EIID004]: 'bio',
}

export const impactCodeByName = {
    bio: EcoImpactIds.EIID004,
    carbon: EcoImpactIds.EIID001,
    eutro: EcoImpactIds.EIID003,
    water: EcoImpactIds.EIID002,
}

export const measureTitles = {
    per100g: 'Per 100g',
    perKg: 'Per Kg',
    perPiece: 'Per Piece',
    perProduct: 'Per Product',
    perServing: 'Per Serving',
}
