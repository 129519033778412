import React from 'react'
import { ISlideoverProps } from '../../types/slideover-types'
import { ISite, NodeType } from '../../types/types'
import Slideover from '../components/Slideover'
import { ITransportLeg } from '../../types/stages'
import SalesTransportForm from './SalesTransportForm'


const formId = 'sales-transport-form'

interface ITransportProps extends Omit<ISlideoverProps, 'type'> {
    isEdit: boolean
    productId: string
    transportLegs?: ITransportLeg[]
    refetch: () => void
    setTransportLegs: (t: ITransportLeg[]) => void
    saving: boolean
    originSite: ISite
    rdcSite: string
    companyId: string
    update: (data: any) => void
    sale: any
}

export default function SalesTransportSlideover({
    isEdit,
    isOpen,
    level,
    companyId,
    productId,
    onClose,
    refetch,
    saving,
    originSite,
    rdcSite,
    sale,
}: ITransportProps) {
    function handleClose() {
        onClose(NodeType.SALES_TRANSPORT)
    }

    return (
        <Slideover
            wider="max-w-3xl"
            isOpen={isOpen}
            formId={formId}
            level={level}
            title="Configure sales Transport"
            type={NodeType.SALES_TRANSPORT}
            saving={saving}
            onClose={handleClose}
        >
            <SalesTransportForm
                isEdit={isEdit}
                refetch={refetch}
                originSite={originSite}
                formId={formId}
                rdcSite={rdcSite}
                sale={sale}
                companyId={companyId}
                productId={productId}
                onClose={onClose}
            />
        </Slideover>
    )
}
