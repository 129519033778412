import { Icon } from '@mondra/ui-components'
import React from 'react'
import Tooltip from '../../../components/LightTooltip'


export function DQSHeader() {
    return (
        <Tooltip
            interactive
            enterDelay={500}
            enterNextDelay={500}
            leaveDelay={200}
            disableFocusListener
            placement="right"
            title={
                <div className="bg-white rounded-sm p-4 shadow-lg min-w-[230px] text-sm">
                    <h5 className="text-gray-900 text-sm">Data Quality Score</h5>
                </div>
            }
        >
            <div className="flex items-center justify-center space-x-1 cursor-pointer">
                <div>DQS</div> <Icon type="information" size={16} />
            </div>
        </Tooltip>
    )
}
