import React, { createContext, useContext } from 'react'
import {
    Tabs as RTabs,
    TabList as RTabList,
    Tab as RTab,
    TabPanels as RTabPanels,
    TabPanel as RTabPanel,
    TabsProps,
    TabListProps,
    TabProps,
    TabPanelsProps,
    TabPanelProps,
    TabsOrientation,
} from '@reach/tabs'

import { forwardRefWithAs } from '@reach/utils'
import classNames from 'classnames'


const orientationStyles: Record<string, string> = {
    horizontal: 'flex flex-col',
    vertical: 'flex',
}

export interface ITabContextProps {
    /**
     * Defines the orientation of the tabs
     */
    orientation: TabsOrientation
}

export const TabContext = createContext<ITabContextProps>({
    orientation: TabsOrientation.Horizontal,
})

export const Tabs = forwardRefWithAs<TabsProps, 'div'>(function Tabs(
    { className, orientation = TabsOrientation.Horizontal, ...rest },
    ref
) {
    const tabCls = classNames(
        'tabs border border-t-0 border-gray-300',
        'rounded-b-lg bg-white shadow',
        className,
        orientation,
        orientationStyles[orientation]
    )
    return (
        <TabContext.Provider value={{ orientation }}>
            <RTabs ref={ref} className={tabCls} orientation={orientation as any} {...rest} />
        </TabContext.Provider>
    )
})

export default Tabs

export type ITabListProps = TabListProps

interface ITabListStyle {
    container: string
    border?: string
}

const tabListStyles: Record<string, ITabListStyle> = {
    horizontal: {
        container: 'flex w-full divide-x divide-gray-300',
    },
    vertical: {
        container: 'flex flex-col divide-y divide-gray-300 rounded-bl-lg',
    },
}

export const TabList = forwardRefWithAs<ITabListProps, 'div'>(function TabList(
    { children, ...other },
    ref
) {
    const { orientation } = useContext(TabContext)
    const tabListStyle: ITabListStyle = tabListStyles[orientation]

    const tabListCls = classNames('box-border', tabListStyle.container)
    return (
        <RTabList ref={ref} className={tabListCls} {...other}>
            {children}
        </RTabList>
    )
})

export interface ITabProps extends TabProps {
    /**
     * Defines the min width of the Tab - it should be tailwind width class
     */
    minWidth?: string
    /**
     * Defines the appearnace of the Tab
     */
    className?: string
    /**
     * Defines the aligment of the Tab children
     */
    align?: string
}

export const alignStyles: Record<string, string> = {
    center: 'items-center text-center justify-center',
    left: 'items-center text-left justify-start',
    none: '',
    right: 'items-center text-right justify-end',
}

export const Tab = forwardRefWithAs<ITabProps, 'button'>(function Tab(props, ref) {
    const {
        isSelected,
        disabled,
        className,
        minWidth = 'w-10 sm:w-40',
        align = 'none',
        ...other
    }: any = props

    const { orientation } = useContext(TabContext)

    const tabCls = classNames(
        'tabs',
        'box-border inline-flex p-4 pb-6 outline-none focus:outline-none active:outline-none focus:border-none active:border-none',
        'transition motion-safe:transition-all duration-200',
        'font-medium truncate max-w-full text-xs sm:text-sm',
        !isSelected && 'border-transparent',
        isSelected && !disabled && 'bg-white text-gray-900',
        !isSelected && !disabled && 'bg-gray-50 text-gray-500 hover:bg-white hover:text-gray-900',
        disabled && 'cursor-not-allowed bg-gray-50 opacity-10',
        alignStyles[align],
        orientation === 'vertical' && 'last:rounded-bl-lg',
        minWidth,
        className
    )
    return <RTab ref={ref} className={tabCls} disabled={disabled} {...other} />
})

export const TabPanels = forwardRefWithAs<TabPanelsProps, 'div'>(function TabPanels(props, ref) {
    return <RTabPanels ref={ref} {...props} />
})

export const TabPanel = forwardRefWithAs<TabPanelProps, 'div'>(function TabPanel(
    { className, ...rest },
    ref
) {
    return (
        <RTabPanel
            ref={ref}
            className={classNames(
                'outline-none p-4 md:px-4 md:pt-6 md:pb-8 lg:p-8 lg:pt-6',
                className
            )}
            {...rest}
        />
    )
})
