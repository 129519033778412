import { METHOD_GET, METHOD_POST, METHOD_PUT } from '../constants'
import HttpException, { processError, notifyErrorResponse } from './httpException'


export interface IfetchBaseClient {
    baseUrlPrefix?: boolean
    body?: any
    method?: string
    url: string
}

export interface IfetchClient extends IfetchBaseClient {
    acquireToken: (isNoCompanyScope?: boolean) => Promise<string>
    errMessage?: string
    isNoCompanyScope?: boolean
    onError: (error: any) => void
    onSuccess: (data: any) => void
    showError?: any
}

export async function fetchClient({
    acquireToken,
    baseUrlPrefix = true,
    body,
    errMessage,
    method = METHOD_GET,
    isNoCompanyScope,
    onError,
    onSuccess,
    showError,
    url,
}: IfetchClient) {
    const token = await acquireToken(isNoCompanyScope)
    return fetch(`${baseUrlPrefix ? process.env.REACT_APP_API_URL : ''}${url}`, {
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type':
                method === METHOD_POST || method === METHOD_PUT
                    ? 'application/json'
                    : 'text/plain;charset=utf-8',
            'X-MONDRA-APP': 'CP',
        },
        method,
    })
        .then(async rsp => {
            if (rsp.ok && rsp.status === 200) {
                return rsp.json()
            }
            if (rsp.ok && rsp.status === 204) {
                return {}
            }
            const errorResponse = await processError(rsp, errMessage)
            throw new HttpException(errorResponse, errorResponse.message)
        })
        .then(data => {
            onSuccess(data)
        })
        .catch(error => {
            notifyErrorResponse(showError, error)
            onError(error)
        })
}
