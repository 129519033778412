import React from 'react'
import { ToggleSwitch } from '@mondra/ui-components'


interface IRequiredToggleProps {
    description?: string
    disabled?: boolean
    helpLabels?: any
    isRequired: boolean
    onChange: (is: boolean) => void
    title: string
}

export default function IsRequiredToggle({
    description,
    disabled,
    helpLabels = {
        left: 'No',
        right: 'Yes',
    },
    isRequired = false,
    onChange,
    title,
}: IRequiredToggleProps) {
    return (
        <ToggleSwitch
            disabled={disabled}
            checked={isRequired}
            helpLabels={helpLabels}
            groupClassName="px-4 md:px-6 py-4 flex items-center justify-between border-b border-gray-200"
            label={
                <div>
                    <div className="text-sm text-gray-700 font-medium">{title}</div>
                    <div className="text-xs text-gray-500">{description}</div>
                </div>
            }
            onChange={onChange}
        />
    )
}
