import getOr from 'lodash/fp/getOr'
import { useMemo } from 'react'
import { PAGE_SIZE, URLs } from '../constants'
import { ISite } from '../types/types'
import getURL from '../utils/getURL'
import { getQueryStringByFilter } from '../utils/queryString'
import useFetch from './useFetch'


interface IFetchDataParams {
    autoFetch?: boolean
    companyId: string
    filters?: any
    page?: number
    pageSize?: number
}

export default function useSites({
    autoFetch = true,
    companyId,
    page,
    filters,
    pageSize = PAGE_SIZE,
}: IFetchDataParams) {
    const queryString = useMemo(() => {
        return getQueryStringByFilter({
            filters: { ...filters, siteType: filters?.archived ? 'archive' : 'active' },
            page,
            pageSize,
        })
    }, [filters, page])

    const url = getURL(URLs.Site.GET_ALL, { companyId }, queryString)

    const { error, data, loading, refetch } = useFetch({
        autoFetch,
        errMessage: 'Failed to fetch categories',
        url,
    })

    const sites: ISite[] = getOr([], 'data', data)
    const pageNumber = getOr(0, 'pageNumber', data)
    const resPageSize = getOr(0, 'pageSize', data)
    const totalCount = getOr(0, 'totalCount', data)
    const totalPages = getOr(0, 'totalPages', data)

    return {
        error,
        loading,
        pageNumber,
        pageSize: resPageSize,
        refetch,
        sites,
        totalCount,
        totalPages,
    }
}
