import { ColDef } from 'ag-grid-community'
import { CategoryName } from './CategoryNameCell'
import { CategoryActions } from './CategoryActions'


export const categoryColumns = [
    {
        cellRenderer: CategoryName,
        field: 'name',
        headerName: 'Categories',
        sortable: true,
    },
    {
        cellClass: 'ag-center-cell !p-0',
        cellRenderer: CategoryActions,
        colId: 'actions',
        field: '',
        headerName: '',
        maxWidth: 40,
    },
] as Array<ColDef>
