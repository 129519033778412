import { useMemo } from 'react'
import { ASC, URLs } from '../constants'
import getURL from '../utils/getURL'
import { getQueryStringByFilter } from '../utils/queryString'
import { ILookupItem, IProductFilters, LookupTypeEnum } from '../types/types'
import useFetch from './useFetch'


interface IuseLookupApiProps {
    autoFetch?: boolean
    companyId: string
    filters?: IProductFilters
    page?: number
    pageSize?: number
    lookupType: LookupTypeEnum
}

const LOOKUP_URLS: Record<LookupTypeEnum, string> = {
    [LookupTypeEnum.INGREDIENTS]: URLs.IngredientCollection.LOOKUP,
    [LookupTypeEnum.PRODUCTS]: URLs.Product.PRODUCTS_LOOKUP,
    [LookupTypeEnum.SUPPLIED_PRODUCTS]: URLs.Product.SUPPLIED_PRODUCTS_LOOKUP,
    [LookupTypeEnum.COMPANY_CATEGORIES]: URLs.CompanyCategory.LIST_LOOK_UP,
    [LookupTypeEnum.SPECIAL_INGREDIENT]: URLs.Product.SUPPLIED_PRODUCTS_LOOKUP,
}

const defaultFiltes = {
    direction: ASC,
    Order: 'name',
}

export function useLookupApi<T = ILookupItem>({
    autoFetch = true,
    companyId,
    filters,
    lookupType,
    page,
    pageSize,
}: IuseLookupApiProps) {
    const queryString = useMemo(
        () => getQueryStringByFilter({ filters: { ...defaultFiltes, ...filters }, page, pageSize }),
        [filters, page, pageSize]
    )

    const url = useMemo(() => getURL(LOOKUP_URLS[lookupType], { companyId }, queryString), [
        companyId,
        lookupType,
        queryString,
    ])

    const { error, data, loading, refetch } = useFetch<Array<ILookupItem & T>>({
        autoFetch,
        defaultRes: [],
        errMessage: 'Failed to fetch records',
        url,
    })

    return {
        data,
        error,
        loading,
        refetch,
    }
}
