import React, { useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { Button, Icon } from '@mondra/ui-components'
import any from 'lodash/fp/any'
import { NotificationContext, IMessage } from '../contexts/SignalRNotificationProvider'
import { MESSAGE_TRIGGER_STATE, MESSAGE_TRIGGER_SUB_TYPE } from '../constants'
import { getInitials } from '../utils'


export const getAction = (pathname: string, title: string, newTab = false) => {
    const linkProps = newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {}
    return (
        <NavLink
            className="p-2 border border-transparent rounded-md text-sm leading-5 font-medium transition-all bg-transparent text-primary-500 hover:bg-primary-50 hover:text-primary-600"
            to={{ pathname }}
            {...linkProps}
        >
            {title}
        </NavLink>
    )
}

const getDisplayIcon = (message: IMessage) => {
    switch (message?.notificationType) {
        case MESSAGE_TRIGGER_SUB_TYPE.SUPPLYCHAIN:
            return (
                <span className="text-sm text-violet-500">
                    <Icon type="ingredients" />
                </span>
            )
        case MESSAGE_TRIGGER_SUB_TYPE.V2CALC:
            return (
                <span className="text-sm">
                    <Icon type="impact" />
                </span>
            )
        case MESSAGE_TRIGGER_SUB_TYPE.USERMANAGEMENT:
            return 'ingredients'
        case MESSAGE_TRIGGER_SUB_TYPE.CERTIFICATESTATECHANGE:
            return (
                <span className="h-8 w-8 flex items-center justify-center text-lg bg-primary-500 rounded-full text-white">
                    <Icon type="butterfly" size={20} />
                </span>
            )
        default:
            return getInitials(message?.supplierName)
    }
}

const Notification = ({ msg, title }: { msg: IMessage; title: string }) => {
    const fromNow = formatDistanceToNow(new Date(msg.createdAt), {
        includeSeconds: true,
    })
    return (
        <li className="relative bg-white hover:bg-gray-50 px-4 py-3">
            <div className="text-gray-800">
                <div className="flex justify-between items-center">
                    <div className="text-gray-400 text-sm font-medium flex justify-start items-center">
                        <span className="mr-1 text-emerald-500">
                            <Icon type="circleSolid" size={8} />
                        </span>
                        <span>{fromNow} ago</span>
                    </div>
                    <div className="flex items-center justify-center h-8 w-8 min-w-8 rounded-full">
                        {getDisplayIcon(msg)}
                    </div>
                </div>
                <div className="w-full">
                    <div className="w-full flex justify-between items-end">
                        <span className="text-sm font-bold mt-1 truncate">{msg.title}</span>
                    </div>
                    <p className="text-sm font-medium text-gray-400 line-clamp-3 mt-1">
                        {msg.longDesc}
                    </p>
                    <div className="mt-2 flex w-full justify-between items-center">
                        {getAction(msg?.url, title)}
                    </div>
                </div>
            </div>
        </li>
    )
}

const supplyChain = (msg: IMessage) => {
    return <Notification msg={msg} title="Optimise product" />
}

const UserManagement = () => {
    return <div />
}

const V2Calc = (msg: IMessage) => {
    return <Notification msg={msg} title="View product" />
}

const ProductCertificationStateChanges = (message: IMessage) => {
    if (message.notificationSubType === MESSAGE_TRIGGER_STATE.CERTIFIED) {
        return <Notification msg={message} title="View product" />
    }
    if (
        any(state => state === message.notificationSubType, [
            MESSAGE_TRIGGER_STATE.APPROVED,
            MESSAGE_TRIGGER_STATE.CERTIFIED,
            MESSAGE_TRIGGER_STATE.CERTIFICATEEXPIRED,
            MESSAGE_TRIGGER_STATE.CERTIFICATEEXPIRING,
            MESSAGE_TRIGGER_STATE.RECERTIFIED,
            MESSAGE_TRIGGER_STATE.REJECTED,
            MESSAGE_TRIGGER_STATE.REVOKED,
            MESSAGE_TRIGGER_STATE.REVIEWIMPACTS,
            MESSAGE_TRIGGER_STATE.CANCELLED,
        ])
    ) {
        return <Notification msg={message} title="View certificate" />
    }
}

export default function NotificationHistory() {
    const { messages, pageDetails, loadMore } = useContext(NotificationContext)
    const validMessages = useMemo(
        () =>
            messages?.filter(
                (msg: IMessage) => msg.notificationType !== MESSAGE_TRIGGER_STATE.IMPACTRECEIVED
            ),
        [messages]
    )
    const { pageNumber, totalPages } = pageDetails
    const getNotificationByType = (type: string, msg: IMessage) => {
        switch (type) {
            case MESSAGE_TRIGGER_SUB_TYPE.SUPPLYCHAIN:
                return supplyChain(msg)
            case MESSAGE_TRIGGER_SUB_TYPE.USERMANAGEMENT:
                return UserManagement()
            case MESSAGE_TRIGGER_SUB_TYPE.CERTIFICATESTATECHANGE:
            case MESSAGE_TRIGGER_SUB_TYPE.CERTIFICATE:
                return ProductCertificationStateChanges(msg)
            case MESSAGE_TRIGGER_SUB_TYPE.V2CALC:
                return V2Calc(msg)
            default:
                break
        }
    }

    const handleLoadMore = () => {
        loadMore(pageNumber + 1)
    }

    if (validMessages?.length === 0) {
        return <NoMessageView />
    }
    const today = validMessages?.filter((msg: IMessage) => !msg.isHistory)
    const ealier = validMessages?.filter((msg: IMessage) => msg.isHistory)

    return (
        <div>
            <h4 className="text-xl text-gray-900 font-semibold mb-2">Notifications</h4>
            <div className="bg-white shadow rounded-lg">
                <div className="w-full flex justify-between items-center py-2 top-0 px-5 leading-normal text-base font-bold text-gray-800 border-b border-gray-300">
                    <span className="">Today</span>
                    <Button variant="ghost" onClick={() => null}>
                        Mark as read
                    </Button>
                </div>
                <div className="relative scrollbar h-full min-h-180 max-h-200 overflow-auto">
                    <div className="overflow-hidden">
                        <ul className="divide-y divide-gray-300">
                            {today?.map((msg: any) => (
                                <React.Fragment key={msg?.notificationId}>
                                    {getNotificationByType(msg?.notificationType, msg)}
                                </React.Fragment>
                            ))}
                        </ul>
                        <div>
                            <div className="flex items-center p-4 bg-gray-50 border-t border-b border-gray-300">
                                <span className="text-base leading-6 font-medium text-gray-900">
                                    Earlier
                                </span>
                            </div>
                            <ul className="divide-y divide-gray-300">
                                {ealier?.map((msg: any) => (
                                    <React.Fragment key={msg?.notificationId}>
                                        {getNotificationByType(msg?.notificationType, msg)}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="relative text-center rounded-b-lg bottom-0 py-4 bg-white w-full border-t border-gray-200">
                    <div className="w-full h-4 pointer-events-none absolute -inset-y-1/4 z-10 bg-gradient-to-t from-gray-100" />
                    <Button
                        variant="ghost"
                        onClick={handleLoadMore}
                        disabled={pageNumber === totalPages}
                    >
                        Load more
                    </Button>
                </div>
            </div>
        </div>
    )
}

function NoMessageView() {
    return (
        <div className="">
            <h4 className="text-xl text-gray-900 font-semibold mb-2">Notifications</h4>
            <div className="flex flex-col justify-start items-center bg-white scrollbar h-full min-h-180 shadow rounded-lg">
                <div className="rounded-full bg-gray-100 mb-4 mt-10 h-14 w-14 pt-4 pl-3">
                    <Icon className="text-gray-500 ml-1" size={28} type="bellCutLeft" />
                </div>
                <span className="text-sm leading-5 font-medium text-gray-600">
                    Your notifications will appear here
                </span>
                <span className="text-sm leading-5 font-normal text-gray-500 text-center mt-2 w-4/5">
                    Receive important information about your products, ingredients, certifications
                    and other updates relevant to you.
                </span>
            </div>
        </div>
    )
}
