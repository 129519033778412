import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import find from 'lodash/fp/find'
import { Button, Icon, Input } from '@mondra/ui-components'
import { ITransportType, ITransportLeg } from '../../types/stages'
import { ICountry, ICustomEventProps } from '../../types/types'
import { Dropdown } from '../../components'
import { swapTransportLegs } from '../../utils/swapTransportLegs'
import Information from './Information'
import { TransportLegActions } from './TransportLegActions'


interface ITransportLegProps {
    countries: ICountry[]
    disabled: boolean
    leg: ITransportLeg
    legs: ITransportLeg[]
    setLegs: Dispatch<SetStateAction<ITransportLeg[]>>
    transportTypes: ITransportType[]
    transportTypesLoading?: boolean
    onChange: (leg: ITransportLeg) => void
    onRemove: (leg: ITransportLeg) => void
}

export default function TransportLeg({
    countries,
    disabled,
    leg,
    legs,
    setLegs,
    onChange,
    onRemove,
    transportTypesLoading = true,
    transportTypes,
}: ITransportLegProps) {
    const [state, setState] = useState(leg)
    const [selectedType, setSelectedType] = useState<any>()

    const selectedCountry = useMemo(() => {
        return find(['id', leg?.countryId], countries)
    }, [leg?.countryId, countries])

    const selectedTransportType = useMemo(() => {
        return find(['code', leg?.transportTypeCode], transportTypes)
    }, [leg?.transportTypeCode, transportTypes])

    useEffect(() => {
        setState(leg)
    }, [leg])

    const handleChange = (evt: ICustomEventProps) => {
        const name = evt.target.name
        const value = evt.target.value
        setState({ ...leg, [name]: value })
    }

    const handleRemove = () => {
        onRemove(state)
    }

    const handleTransTypeChange = (selectedItem?: any) => {
        onChange({
            ...leg,
            transportTypeCode: selectedItem?.value,
        })
        setSelectedType(selectedItem)
    }

    const handleCountryChange = (selectedItem?: any) => {
        onChange({ ...leg, countryId: selectedItem?.value })
    }

    const handleMoveUp = useCallback(() => {
        setLegs(swapTransportLegs(leg.legOrder, leg.legOrder - 1, legs))
    }, [legs])

    const handleMoveDown = useCallback(() => {
        setLegs(swapTransportLegs(leg.legOrder, leg.legOrder + 1, legs))
    }, [legs])

    useEffect(() => {
        if (state.transportTypeCode !== leg.transportTypeCode || state.distance !== leg.distance) {
            onChange(state)
        }
        const selected = find(type => type.code === state.transportTypeCode, transportTypes)
        setSelectedType(selected)
    }, [state, transportTypes])
    return (
        <div>
            <div className="grid grid-cols-6 mx-6 mt-4">
                {leg.legOrder === 0 && (
                    <>
                        <div className="col-span-2">Add transport leg:</div>
                        <div className="col-span-2 ml-4">Country:</div>
                        <div className="col-span-2 ml-4">Distance KM:</div>
                    </>
                )}
                <div className="col-span-2">
                    <Dropdown
                        disabled={disabled || transportTypesLoading}
                        placeholder={transportTypesLoading ? 'Loading' : 'Select an option'}
                        groupKey="medium"
                        options={transportTypes}
                        selected={selectedTransportType}
                        onChange={handleTransTypeChange}
                    />
                </div>
                <div className="col-span-2 ml-4">
                    <Dropdown
                        disabled={disabled}
                        placeholder="Select a country"
                        options={countries}
                        selected={selectedCountry}
                        onChange={handleCountryChange}
                    />
                </div>
                <div className="col-span-2 flex justify-between items-center ml-4">
                    <div className="">
                        <Input
                            disabled={disabled}
                            name="distance"
                            type="number"
                            value={state.distance}
                            onChange={handleChange}
                            className="w-32"
                        />
                    </div>
                    <Information transportType={selectedType} disabled={disabled} />
                    <Button
                        disabled={disabled}
                        className="px-0"
                        variant="ghost"
                        onClick={handleRemove}
                    >
                        <div className="text-gray-500">
                            <Icon type="close" />
                        </div>
                    </Button>
                    <TransportLegActions
                        onMoveUp={handleMoveUp}
                        onMoveDown={handleMoveDown}
                        disableUp={leg.legOrder === 0}
                        disableDown={leg.legOrder >= legs.filter(l => !l.isArchived)?.length - 1}
                    />
                </div>
            </div>
        </div>
    )
}
