import React, { useCallback, useEffect, useMemo } from 'react'
import 'ag-grid-enterprise'
import { ColDef, IsServerSideGroupOpenByDefaultParams } from 'ag-grid-community'
import debounce from 'lodash/fp/debounce'
import useAuth from '../../../hooks/useAuth'
import { EcoImpactIds } from '../../../types/ecoImpacts'
import { Grid } from '../../../components/grid/Grid'
import { createServerSideDatasource } from '../../../utils/contributionTreeUtils'
import { useGridControls } from '../../../hooks/useGridControls'
import { NodeType } from '../../../types/types'
import { ContributionColumnDefs } from './ColDefs'
import NameColumn from './NameColumn'


interface IContributionTreeGrid {
    impactCode: EcoImpactIds
    loading?: boolean
    companyId: string
    productId: string
    refreshId: number
}

const autoGroupColumnDef = {
    cellRendererParams: {
        innerRenderer: ({ data, node }: any) => {
            return (
                <NameColumn
                    row={{
                        ...data,
                        type:
                            node?.level > 1 && data.isWater
                                ? NodeType.SPECIAL_INGREDIENT
                                : data.type,
                    }}
                />
            )
        },
        suppressCount: true,
    },
    field: 'name',
    flex: 1,
    headerName: 'Product name',
    minWidth: 500,
}

const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 300,
}

export const ContributionTreeGrid = ({
    impactCode,
    companyId,
    productId,
    refreshId,
}: IContributionTreeGrid) => {
    const { acquireToken } = useAuth()
    const { gridApi, setGridApi, refreshGrid } = useGridControls()

    useEffect(() => {
        refreshGrid()
    }, [refreshId])

    useEffect(() => {
        const onResize = debounce(200, () => {
            if (gridApi) {
                gridApi.sizeColumnsToFit()
            }
        })

        onResize()

        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [gridApi])

    const isServerSideGroupOpenByDefault = useCallback(
        (params: IsServerSideGroupOpenByDefaultParams) => {
            return params.rowNode.level < 1
        },
        []
    )
    const isServerSideGroup = useCallback((dataItem: any) => {
        return dataItem.group
    }, [])

    const getServerSideGroupKey = useCallback((dataItem: any) => {
        return dataItem.id
    }, [])

    const serverSideDatasource = useMemo(
        () => createServerSideDatasource(acquireToken, companyId, productId, impactCode),
        [companyId, productId, impactCode]
    )

    useEffect(() => {
        gridApi?.refreshHeader()
    }, [impactCode])

    const context = useMemo(
        () => ({
            impactCode,
        }),
        [impactCode]
    )

    return (
        <div className="w-full overflow-auto mt-2 flex-grow flex flex-col">
            <Grid
                autoGroupColumnDef={autoGroupColumnDef}
                columnDefs={ContributionColumnDefs}
                context={context}
                defaultColDef={defaultColDef}
                isExternalFilter={false}
                isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
                isServerSideGroup={isServerSideGroup}
                getServerSideGroupKey={getServerSideGroupKey}
                treeData={true}
                serverSideDatasource={serverSideDatasource}
                onApiAvailable={setGridApi}
            />
        </div>
    )
}
