import classNames from 'classnames'
import React from 'react'
import { DUMMY_NODE } from '../../../utils/supplyChainUtils'


export default function SupplyChainLoader({ colClass, ingredientRows }: any) {
    return ingredientRows.map((i: any) => {
        return (
            <React.Fragment key={i.ingredient.id}>
                <div className={colClass}>
                    <LoaderTile />
                </div>
                {i.supplyChain.map((sup: any) => (
                    <div key={sup.id} className={colClass}>
                        <LoaderTile supplier={sup} isDummy={sup.isDummy} />
                    </div>
                ))}

                <div className={colClass}>
                    <LoaderTile isDummy={i.ingredient.type === DUMMY_NODE} />
                </div>
            </React.Fragment>
        )
    })
}

function LoaderTile({ isDummy }: any) {
    if (isDummy) {
        return null
    }

    return (
        <div
            className={classNames(
                'z-10 max-w-2xs w-56 h-24 box-border rounded',
                'border border-gray-200 bg-gray-100'
            )}
        >
            <div className="h-full animate-pulse flex flex-col items-start justify-center">
                <div>
                    <div className="flex flex-col justify-start flex-grow gap-y-2 w-full p-2">
                        <div className="h-full flex flex-row items-center justify-start gap-x-2">
                            <div className="bg-gray-200 rounded-full w-8 h-8" />
                            <div className="h-5 w-32 bg-gray-200 rounded" />
                        </div>
                        <div className="h-4 w-32 bg-gray-200 rounded" />
                    </div>
                </div>
                <div className="h-6 w-full bg-gray-200" />
            </div>
        </div>
    )
}
