import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IParamProps, NodeType } from '../../types/types'
import { ISlideoverProps } from '../../types/slideover-types'
import slideoverCloseStyles from '../../constants/slideoverCloseStyles'
import { URLs } from '../../constants'
import useFetch from '../../hooks/useFetch'
import getURL from '../../utils/getURL'
import Slideover from '../components/Slideover'
import AuditForm from './AuditForm'


export interface IAuditProps extends Omit<ISlideoverProps, 'onSaved' | 'type'> {
    productId?: string
}

export default function AuditSlideover({ productId, isOpen, onClose }: IAuditProps) {
    const [auditData, setAuditData] = useState()
    const { companyId = '' }: IParamProps = useParams()

    const { data, loading } = useFetch({
        autoFetch: isOpen,
        url: getURL(URLs.Product.AUDIT, { companyId, productId }),
    })

    useEffect(() => {
        setAuditData(data)
    }, [data])

    const handleClose = () => {
        onClose && onClose(NodeType.AUDIT)
        setAuditData(undefined)
    }

    return (
        <Slideover
            isOpen={isOpen}
            formId="product-audit-form"
            onClose={handleClose}
            type={NodeType.AUDIT}
            headerProps={{
                className: 'border-b border-gray-300',
                hideShadow: true,
                iconProps: slideoverCloseStyles as any,
            }}
            title="Audit"
            footerProps={{ hideSave: true }}
        >
            <AuditForm data={auditData} loading={loading} />
        </Slideover>
    )
}
