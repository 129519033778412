import React from 'react'
import { ColDef } from 'ag-grid-community'
import ProductStateCell from '../../components/ProductStateCell'
import { LastUpdatedColDef, SelectRowColDef } from '../../constants/grid'
import { EcoImpactIds } from '../../types/ecoImpacts'
import { CertificateStateEnum } from '../../types/types'
import { getColDefForImpact } from '../../utils/gridUtils'
import ProductActions from './ProductActions'
import ProductNameCell from './ProductNameCell'


export const productColumns = [
    SelectRowColDef,
    {
        cellRenderer: ProductNameCell,
        field: 'name',
        headerName: 'Product',
        minWidth: 300,
        sortable: true,
    },
    {
        cellRenderer: ({ data }: any) => <span>{data.companyCategoryName || 'Not set'}</span>,
        field: 'companyCategory',
        headerName: 'Category',
        minWidth: 150,
    },
    {
        cellRenderer: ({ data }: any) => (
            <ProductStateCell
                certifiedUntilDate={data.certificationEndDate}
                state={data.saState || CertificateStateEnum.Draft}
                isComplete={data.isComplete}
            />
        ),
        field: 'saState',
        headerName: 'State',
        minWidth: 250,
    },
    { ...getColDefForImpact(EcoImpactIds.EIID001), minWidth: 175 },
    { ...getColDefForImpact(EcoImpactIds.EIID002), minWidth: 185 },
    { ...getColDefForImpact(EcoImpactIds.EIID003), minWidth: 240 },
    { ...getColDefForImpact(EcoImpactIds.EIID004), minWidth: 165 },
    LastUpdatedColDef,
    {
        cellClass: 'ag-center-cell !p-0',
        cellRenderer: ProductActions,
        colId: 'actions',
        field: '',
        flex: 0,
        headerName: '',
        maxWidth: 40,
    },
] as Array<ColDef>
