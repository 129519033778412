import React from 'react'
import { AddNotes, NoNotes, NotesRow } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { INote, NOTE_TYPE, NodeType } from '../../types/types'
import { useCommentaryForm } from '../../hooks/useCommentaryForm'
import { FormLoader } from './FormLoader'
import { noData } from './notesTitles'


interface INotesFormProps {
    companyId: string
    productId: string
    refetchParent?: () => void
    stage: NodeType
}

export default function NotesForm({ companyId, productId, refetchParent, stage }: INotesFormProps) {
    const {
        checkOwnNote,
        handleNoteCreate,
        handleNoteDelete,
        handleNoteEdit,
        handleNoteReply,
        creating,
        isPrevCreating,
        updating,
        isPrevUpdating,
        loading,
        notes,
        users,
    } = useCommentaryForm({
        autoFetch: true,
        companyId,
        productId,
        refetchParent,
        stage,
    })

    if (loading && notes === null) {
        return <FormLoader />
    }

    return (
        <form id="notes-form" className="relative h-full flex flex-col justify-between">
            <div className="px-4 py-5 flex flex-col gap-y-5 overflow-x-hidden overflow-y-auto h-full">
                {isEmpty(notes) ? (
                    <NoNotes
                        title={noData.title[NOTE_TYPE.NOTE]}
                        description={noData.descr[NOTE_TYPE.NOTE]}
                    />
                ) : (
                    notes?.map((note: INote) => (
                        <NotesRow
                            key={note.id}
                            note={note}
                            isOwnNote={checkOwnNote}
                            onDelete={handleNoteDelete}
                            onEdit={handleNoteEdit}
                            onReply={handleNoteReply}
                            addNotesProps={{
                                enableMentions: true,
                                users,
                            }}
                            editingText={
                                updating || (isPrevUpdating && loading) ? 'Updating...' : ''
                            }
                            replyingText={
                                (updating || (isPrevUpdating && loading)) && !checkOwnNote
                                    ? 'Replying...'
                                    : ''
                            }
                        />
                    ))
                )}
            </div>
            <div className="bg-gray-50 border-t border-gray-300 p-6">
                <AddNotes
                    enableMentions
                    onSubmit={handleNoteCreate}
                    users={users}
                    loadingText={creating || (isPrevCreating && loading) ? 'Adding...' : ''}
                />
            </div>
        </form>
    )
}
