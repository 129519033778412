import React, { ReactChildren, ReactNode, useContext, useEffect } from 'react'
import {
    ISlideoverHeaderProps,
    Slideover as SlideoverComponent,
    SlideoverContent,
} from '@mondra/ui-components'

import { IChildrenProps, NodeType } from '../../types/types'
import { SlideoverCountContext } from '../../contexts/SlideoverCountProvider'
import SlideoverHeader from '../components/SlideoverHeader'
import SlideoverFooter, { ISlideoverFooterProps } from '../components/SlideoverFooter'
import usePrevious from '../../hooks/usePrevious'


interface ISlideoverComponent extends IChildrenProps {
    disableSave?: boolean
    footerChildren?: ReactNode | ReactChildren
    formId: string
    footerProps?: Partial<ISlideoverFooterProps>
    headerProps?: Omit<ISlideoverHeaderProps, 'title' | 'onClose'>
    isOpen: boolean
    level?: number
    onClose: (type: NodeType) => void
    onSaveButtonClick?: () => void
    saveButtonText?: string
    saving?: boolean
    showFooter?: boolean
    showHeader?: boolean
    title: string
    type: NodeType
    wider?: string
    contentClassName?: any
}

export default function Slideover({
    children,
    disableSave,
    footerChildren,
    formId,
    footerProps,
    headerProps,
    isOpen,
    level,
    onClose,
    onSaveButtonClick,
    saveButtonText,
    saving,
    showFooter = true,
    showHeader = true,
    title,
    type,
    wider = 'max-w-3xl',
    contentClassName,
}: ISlideoverComponent) {
    const { addLayer, removeLayer, totalLayers } = useContext(SlideoverCountContext)
    const lastIsOpen = usePrevious(isOpen)

    const handleClose = () => {
        onClose(type)
    }

    useEffect(() => {
        if (!lastIsOpen && isOpen) {
            addLayer()
        }
        if (lastIsOpen && !isOpen) {
            removeLayer()
        }
    }, [isOpen])

    return (
        <SlideoverComponent
            wider={wider}
            isOpen={isOpen}
            level={level === undefined ? totalLayers : level}
            totalLayers={totalLayers}
            onClose={handleClose}
            onDismiss={handleClose}
        >
            {showHeader && (
                <SlideoverHeader type={type} {...headerProps} title={title} onClose={handleClose} />
            )}
            <SlideoverContent className={contentClassName}>{children}</SlideoverContent>
            {showFooter && (
                <SlideoverFooter
                    formId={formId}
                    onClose={handleClose}
                    saving={saving}
                    disableSave={disableSave}
                    saveButtonText={saveButtonText}
                    onSaveButtonClick={onSaveButtonClick}
                    {...footerProps}
                >
                    {footerChildren}
                </SlideoverFooter>
            )}
        </SlideoverComponent>
    )
}
