import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { UserContext } from '../../contexts/UserProvider'
import {
    BrandTile,
    Card,
    Toolbar,
    Breadcrumbs,
    Breadcrumb,
    HomeBreadcrumb,
    CopyToClipboard,
} from '../../components'
import { GridPageLayout } from '../../components/grid/GridPageLayout'


export default function Settings() {
    const { company } = useContext(UserContext)

    if (!company) {
        return <Redirect to="/no-company" />
    }

    return (
        <GridPageLayout isPadding>
            <Toolbar
                title="Company settings"
                breadcrumb={
                    <Breadcrumbs>
                        <HomeBreadcrumb />
                        <Breadcrumb>{company.name} settings</Breadcrumb>
                    </Breadcrumbs>
                }
            />

            <div className="w-full my-4">
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 pt-4">
                    <Card title="Company Information" isExapanded disableExpand>
                        <div className="flex items-start">
                            <BrandTile
                                size="lg"
                                src={company.companyLogoUrl}
                                companyName={company.name}
                            />
                            <div className="flex flex-col ml-6">
                                <h4 className="leading-normal text-lg font-bold mb-2 text-gray-800">
                                    {company.name}
                                </h4>
                                <p className="mb-2 text-gray-800">{company.companyDescription}</p>
                                <a
                                    className="mdp-link font-medium mb-2"
                                    href={company.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {company.website}
                                </a>
                                <CopyToClipboard infoText="Company id copied" text={company.id}>
                                    Copy Company id
                                </CopyToClipboard>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </GridPageLayout>
    )
}
