import React, { ChangeEvent, useCallback } from 'react'
import { Checkbox } from '@mondra/ui-components'
import { ICellRendererParams, RowNode } from 'ag-grid-community'

import { IdType } from '../../hooks/useMultiSelect'

/**
 * NOTE: Ignore this component for now
 * TODO: Need to implement select all checkbox properly using grid APIS
 * Select All is working but grid rows individual checkboxes not refreshing
 * @param props
 * @returns
 */
export function SelectAllHeader(props: ICellRendererParams) {
    const { context, api } = props
    //Need to fix select all rows for server side model
    const { pageDataIds = [], selectedIds, toggleAllItems } = context
    const isSelectedAll
        = selectedIds.length > 0 && pageDataIds.every((elem: IdType) => selectedIds.includes(elem))
    const isSomeSelected = pageDataIds.some((elem: IdType) => selectedIds.includes(elem))
    const isPartial = !isSelectedAll && isSomeSelected
    // const selectedNodes = api.getSelectedNodes()
    const handleAllSelect = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target
            toggleAllItems(isPartial ? false : checked, pageDataIds)
            if (checked) {
                api.forEachNode((node: RowNode) => {
                    node.selectThisNode(true)
                })
            }
        },
        [isPartial]
    )

    return (
        <Checkbox
            checked={isSelectedAll}
            onChange={handleAllSelect}
            partial={isPartial}
            size={16}
            title="Select or Unselect all items in this page"
        />
    )
}
