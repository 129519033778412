import React from 'react'


export default function RecentLoader() {
    return (
        <div className="animate-pulse">
            <h4 className="h-5 w-40 bg-gray-200 mb-8">
                <span className="sr-only">heading</span>
            </h4>
            <div className="grid grid-cols-12 w-full gap-6">
                <StatLoader />
                <StatLoader />
                <StatLoader />
            </div>
        </div>
    )
}

function StatLoader() {
    return (
        <div className="col-span-12 lg:col-span-6 xxl:col-span-4 p-6 overflow-hidden border border-gray-200 rounded-sm motion-safe:transition-all bg-white min-h-[156px]">
            <div className="flex justify-start items-center gap-4">
                <div className="h-6 w-6 bg-gray-200 flex justify-start" />
                <p className="h-4 w-24 bg-gray-200" />
            </div>
            <dd className="h-6 mt-6 w-60 bg-gray-200 mb-3">
                <span className="sr-only">heading</span>
            </dd>
            <dd className="h-4 w-40 bg-gray-200 mb-3">
                <span className="sr-only">title</span>
            </dd>
            <dd className="h-3 w-56 bg-gray-200 mt-6">
                <span className="sr-only">info</span>
            </dd>
        </div>
    )
}
