import React from 'react'
import map from 'lodash/fp/map'
import { PageLoader } from '../../components'
import ChangeLog from './ChangeLog'
import ChangeDetails from './ChangeDetails'


interface auditProps {
    data: any
    loading?: boolean
}

export default function AuditForm({ data = [], loading = false }: auditProps) {
    return (
        <>
            <form id="product-audit-form" className="pt-2 divide-y divide-gray-200">
                <div>
                    {map(log => {
                        return (
                            <ChangeLog
                                details={{
                                    email: log.userEmail,
                                    name: log.userName,
                                    updated: log.updatedDate,
                                }}
                            >
                                <ChangeDetails changes={log.changes} />
                            </ChangeLog>
                        )
                    }, data)}
                </div>
            </form>
            <div className="">
                <PageLoader
                    show={loading}
                    sizeClass="w-20 h-20"
                    className="bg-white bg-opacity-80 p-4 rounded-md absolute left-1/2 top-24 -ml-10"
                />
            </div>
        </>
    )
}
