import React from 'react'
import { ConfirmDialog } from '@mondra/ui-components'
import { SUPPLIED_PRODUCT_LABELS } from '../../constants'


interface IRemoveSuppliedItemDialogProps {
    onClose: () => void
    onConfirm: () => void
    open: boolean
    saType: string
}

export default function RemoveSuppliedItemDialog({
    saType,
    open,
    onClose,
    onConfirm,
}: IRemoveSuppliedItemDialogProps) {
    const typeLabel = SUPPLIED_PRODUCT_LABELS[saType].text

    return (
        <ConfirmDialog
            variant="danger"
            title={`Remove supplied ${typeLabel}`}
            open={open}
            primaryBtnText="Remove"
            onClose={onClose}
            onConfirm={onConfirm}
        >
            You are removing supplied {typeLabel} from this ingredient. This will remove {typeLabel}{' '}
            percentage of share, you will need to re-adjust share percentages.
        </ConfirmDialog>
    )
}
