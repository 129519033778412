import React, { useCallback, useEffect, useState } from 'react'
import { Input, FormDialog, ErrorLabel } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { METHOD_POST, URLs } from '../../constants'
import { CLONE_FAIL, PRODUCTS } from '../../constants/toastNotifications'
import getURL from '../../utils/getURL'
import { IProduct } from '../../types/types'


interface IProductCloneDialogProps {
    product?: IProduct
    companyId: string
    open: boolean
    onClose: () => void
    onSaved: () => void
}

export default function ProductCloneDialog({
    product,
    companyId,
    open,
    onClose,
    onSaved,
}: IProductCloneDialogProps) {
    const [errors, setErrors] = useState({ name: false })
    const [productName, setProductName] = useState<string>('')
    const { showError, showSuccess } = useTopCenterToast()

    useEffect(() => {
        if (open) {
            setProductName(`${product?.name} - Cloned`)
            setErrors({ name: false })
        }
    }, [open])

    const handleNameChange = useCallback((event: any) => {
        const val = event.target.value
        setProductName(val)
        setErrors({ name: isEmpty(val) })
    }, [])

    const { saving, update } = useUpdateFormData({
        method: METHOD_POST,
        onError: () => {
            showError({ description: CLONE_FAIL })
        },
        onUpdate: () => {
            showSuccess({ description: PRODUCTS.CLONE })
            onClose()
            onSaved()
        },
        url: getURL(URLs.Product.CLONE, { companyId, productId: product?.id }),
    })

    const cloneProduct = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        if (isEmpty(product)) {
            showError({ description: PRODUCTS.INVALID })
            return
        }
        if (productName.trim()) {
            update({
                name: productName.trim(),
            })
        } else {
            setErrors({ name: true })
        }
    }

    return (
        <FormDialog
            formId="clone-product-form"
            title="Clone Product"
            open={open}
            onClose={onClose}
            primaryBtnDisabled={saving}
            primaryBtnText={saving ? 'Cloning...' : 'Clone'}
        >
            <form id="clone-product-form" onSubmit={cloneProduct}>
                <div className="flex flex-col mb-4">
                    <label htmlFor="name" className="mb-2">
                        Product name
                    </label>
                    <Input
                        disabled={saving}
                        className="col-span-2 mb-1"
                        name="name"
                        value={productName}
                        onChange={handleNameChange}
                    />
                    {errors.name && <ErrorLabel>Product name is required</ErrorLabel>}
                </div>
            </form>
        </FormDialog>
    )
}
