import React, { useCallback, createContext, useState } from 'react'
import noop from 'lodash/fp/noop'
import isEmpty from 'lodash/fp/isEmpty'
import SideLoadCertificate from '../components/SideLoadCertificate'


interface ISideloadOpenParams {
    url: string
    onSuccess?: any
    successText?: string
}

interface ISideLoadCertificateContext {
    openSideload(params: ISideloadOpenParams): void
}

export const SideloadCertificateContext = createContext<ISideLoadCertificateContext>({
    openSideload: noop,
})

export default function SideloadCertificateProvider({ children }: any) {
    const [url, setUrl] = useState('')
    const [successCallback, setSuccessCallback] = useState()
    const [successText, setSuccessText] = useState<string | undefined>()

    const openSideload = useCallback(({ onSuccess, successText, url }: ISideloadOpenParams) => {
        setSuccessCallback(() => onSuccess)
        setSuccessText(successText)
        setUrl(url)
    }, [])

    const handleClose = useCallback(() => {
        setUrl('')
        setSuccessText(undefined)
        setSuccessCallback(undefined)
    }, [])

    return (
        <SideloadCertificateContext.Provider value={{ openSideload }}>
            {children}
            <SideLoadCertificate
                isOpen={!isEmpty(url)}
                onClose={handleClose}
                onSuccess={successCallback}
                successText={successText}
                url={url}
            />
        </SideloadCertificateContext.Provider>
    )
}
