import { NOTE_TYPE } from '../types/types'


export default {
    Activity: {
        ADD: '/api/company/{companyId}/product/{productId}/activity',
        GET: '/api/company/{companyId}/product/{productId}/activity',
        LEGS: '/api/company/{companyId}/activity/{type}/all',
        UPDATE: '/api/company/{companyId}/product/{productId}/activity/{id}',
    },
    AddressLookup:
        'https://atlas.microsoft.com/search/address/json?subscription-key={key}&api-version=1.0&language=en-US&typeahead=true&limit=25',
    Archive: '/api/company/{companyId}/{apiNode}/{id}/archive',
    Category: {
        All: '/api/company/{companyId}/categories/{type}',
        SEARCH_BY_CODE: '/api/company/{companyId}/categories/{type}/{code}',
    },
    Certificate: {
        SIDELOAD: '/api/company/{companyId}/certificates/sideload',
        UPDATE_START_DATE: '/api/company/{companyId}/certificates/{id}/start/{value}',
        UPDATE_STATE: '/api/company/{companyId}/certificates/{id}/state',
    },
    Company: {
        ADD: '/api/companies',
        ALL: '/api/company/all',
        GENERATE_APIKEY: '/api/companies/{companyId}/generate-apikey',
        GET_ALL: '/api/companies',
        GET_APIKEY: '/api/companies/{companyId}/apikey',
        REVOKE_APIKEY: '/api/companies/{companyId}/revoke-apikey',
        SYNC_IS_DATA: '/api/company/{companyId}/sync-is-data',
        UPDATE: '/api/companies/{companyId}',
    },
    CompanyCategory: {
        ACTIVE: '/api/company/{companyId}/company-categories/{id}/active',
        ADD: '/api/company/{companyId}/company-categories',
        ARCHIVE: '/api/company/{companyId}/company-categories/{id}/archive',
        GET: '/api/company/{companyId}/company-categories/{id}',
        GET_PAGED: '/api/company/{companyId}/company-categories/paged',
        LIST_LOOK_UP: '/api/company/{companyId}/company-categories/lookup',
        UPDATE: '/api/company/{companyId}/company-categories/{id}',
    },
    Configurations: {
        TECHNOLOGY_SYSTEM: '/api/tech-systems',
    },
    Country: {
        GET_ALL: '/api/countries',
    },
    Dashboard: {
        NODE_DETAILS: '/api/company/{companyId}/dashboard-nodes-details',
    },
    DashboardStats: {
        COMPANY_PRODUCTS: '/api/company/{companyId}/products-stats',
        SUPPLY_CHAIN_STATS: '/api/company/{companyId}/supplychain-stats',
    },
    EmissionFactors: {
        ALL: '/api/company/{companyId}/emissionfactors',
    },
    Identity: {
        GET_USERS_BY_COMPANY: `${process.env.REACT_APP_IDENTITY_API_URL}/api/v1/companies/{companyId}/users`,
    },
    [NOTE_TYPE.HOTSPOT]: {
        ADD: '/api/company/{companyId}/hotspot',
        DELETE: '/api/company/{companyId}/hotspots/{id}/archive',
        GET: '/api/company/{companyId}/hotspots/{productId}',
        GET_COUNT: '/api/company/{companyId}/hotspots/{productId}/count',
        UPDATE: '/api/company/{companyId}/hotspot',
    },
    IngredientCollection: {
        ADD: '/api/company/{companyId}/ingredientcollections',
        DETAILS: '/api/company/{companyId}/ingredientcollections/{ingredientId}/details',
        GET: '/api/company/{companyId}/ingredientcollections/{ingredientId}',
        GET_ALL: '/api/company/{companyId}/ingredientcollections',
        LOOKUP: '/api/company/{companyId}/ingredientcollections/lookup',
        UPDATE: '/api/company/{companyId}/ingredientcollections/{ingredientId}',
    },
    [NOTE_TYPE.INSIGHT]: {
        ADD: '/api/company/{companyId}/insights',
        DELETE: '/api/company/{companyId}/insights/{id}/archive',
        GET: '/api/company/{companyId}/insights/{productId}',
        GET_COUNT: '/api/company/{companyId}/insights/{productId}/count',
        UPDATE: '/api/company/{companyId}/insights',
    },
    Notes: {
        ADD: '/api/company/{companyId}/note',
        COUNT: '/api/company/{companyId}/notes-stage-wise-count/{productId}',
        DELETE: '/api/company/{companyId}/note/{id}/archive',
        GET: '/api/company/{companyId}/notes/{productId}',
        GET_COUNT: '/api/company/{companyId}/notes/{productId}/count',
        GET_NOTE_THREAD: '/api/company/{companyId}/notes/{id}/thread',
        GET_ROOT_LIST: '/api/company/{companyId}/notes',
        NOTE_READ: '/api/company/{companyId}/notes/{id}/read',
        UPDATE: '/api/company/{companyId}/note',
    },
    Notification: {
        HISTORY: '/api/company/{companyId}/notifications',
        MY: '/api/notifications/my/paged',
    },
    Packaging: {
        ADD: '/api/company/{companyId}/packaging',
        GET: '/api/company/{companyId}/packaging/{productId}',
        GET_BY_ID: '/api/company/{companyId}/packagingbyid/{id}',
        PACKAGING_TYPES: '/api/company/{companyId}/packagingtypes',
        PACKAGING_WITH_REFCODES: '/api/company/{companyId}/packagingtypes/withrefcodes',
        UPDATE: '/api/company/{companyId}/packaging/{id}',
    },
    PagePaths: {
        CATEGORIES: '/categories/:companyId',
        CONTRIBUTION_TREE: '/supply-chain/:companyId/:productId/contribution-tree',
        HOME: '/company/:companyId/dashboard',
        INGREDIENTS: '/ingredients/:companyId',
        NOTES: '/company/:companyId/notes',
        PRODUCT_OVERVIEW: '/product/:companyId/:productId/overview',
        PRODUCTS: '/products/:companyId',
        SETTINGS: '/settings/:companyId',
        SITES: '/sites/:companyId',
        SUPPLIED_PRODUCTS: '/supplied-products/:companyId',
        SUPPLIERS: '/suppliers/:companyId',
        SUPPLY_CHAIN: '/supply-chain/:companyId/:productId',
    },
    Pages: {
        CONTRIBUTION_TREE: '/supply-chain/{companyId}/{productId}/contribution-tree',
        CUSTOMERS: '/customers/{companyId}',
        INGREDIENTS: '/ingredients/{companyId}',
        PRODUCT_OVERVIEW: '/product/{companyId}/{productId}/overview',
        PRODUCTS: '/products/{companyId}',
        SITES: '/sites/{companyId}',
        SUPPLIED_PRODUCT_OVERVIEW: '/supplied-products/{companyId}/{productId}/overview',
        SUPPLIED_PRODUCTS: '/supplied-products/{companyId}',
        SUPPLIERS: '/suppliers/{companyId}',
        SUPPLY_CHAIN: '/supply-chain/{companyId}/{productId}',
    },
    Product: {
        ADD: '/api/company/{companyId}/products',
        APPROVAL_REQUEST: '/api/company/{companyId}/products/approval-request',
        AUDIT: '/api/company/{companyId}/audit/{productId}',
        AWARD: {
            ADD: '/api/company/{companyId}/products/{productId}/awards',
            DELETE: '/api/company/{companyId}/products/{productId}/awards/{id}',
            GET: '/api/company/{companyId}/products/{productId}/awards',
            UPDATE: '/api/company/{companyId}/products/{productId}/awards/{id}',
        },
        CALCULATE: '/api/v3/company/{companyId}/products/{productId}/calculate-impacts',
        CERTIFICATE_CURRENT: '/api/company/{companyId}/products/{productId}/certificates/current',
        CERTIFICATES: '/api/company/{companyId}/products/{productId}/certificates',
        CLONE: '/api/company/{companyId}/products/{productId}/clone',
        DOWNLOAD_DATA: '/api/company/{companyId}/export-data',
        DOWNLOAD_GRADE: '/api/company/{companyId}/products/{productId}/grading',
        ECOIMPACTDETAILS: '/api/company/{companyId}/products/{productId}/ecoimpactdetails',
        ECOIMPACTS: '/api/company/{companyId}/products/{productId}/ecoipacts',
        EXPORT_CONTRIBUTION_TREE:
            '/api/company/{companyId}/supplychain/{productId}/contribution-tree/export',
        GET: '/api/company/{companyId}/products/{productId}',
        GET_ALL: '/api/company/{companyId}/products',
        GET_ALL_BY_SITE: '/api/company/{companyId}/site/{siteId}/products',
        GET_COMPACT: '/api/company/{companyId}/products/{productId}/compactproductdetails',
        GET_PRIMARY: '/api/company/{companyId}/products/{productId}/PrimaryProductDetails',
        IMAGE_UPLOAD: '/api/company/{companyId}/products/{productId}/images',
        IS_COMPLETE_BULK: '/api/company/{companyId}/products/update-complete/{isComplete}',
        ITEMCOUNTS: '/api/company/{companyId}/products/{productId}/productassociateditemscount',
        LOCK_UNLOCK: '/api/company/{companyId}/products/{productId}/lock',
        METADATA: '/api/company/{companyId}/products/{productId}/productmetadata',
        OPTIMIZE_PRODUCT: '/api/company/{companyId}/products/{productId}/optimize-product',
        PRODUCT_TREE: '/api/company/{companyId}/products/{productId}/product-tree',
        PRODUCTS_LOOKUP: '/api/company/{companyId}/products/lookup',
        SUPPLIED_PRODUCTS: '/api/company/{companyId}/suppliedproducts',
        SUPPLIED_PRODUCTS_LOOKUP: '/api/company/{companyId}/suppliedproducts/lookup',
        UPDATE: '/api/company/{companyId}/products/{productId}',
        UPDATE_BY_SITE: '/api/company/{companyId}/site/{siteId}/products',
        UPDATE_CUSTOM_PROPERTIES: '/api/company/{companyId}/products/{productId}/custom-properties',
        VALIDATE_SKU: '/api/company/{companyId}/products/{productId}/validate-sku/{sku}',
    },
    Region: {
        GET: '/api/country/{countryCode}/regions',
    },
    Sales: {
        ADD: '/api/company/{companyId}/sales',
        GET: '/api/company/{companyId}/sales/{productId}',
        GET_CHANNELS: '/api/company/{companyId}/sales/channels/{region}',
        GET_PROXIES: '/api/company/{companyId}/sales/productproxies/{region}/{channel}',
        GET_REGIONS: '/api/company/{companyId}/sales/regions',
        UPDATE: '/api/company/{companyId}/sales/{id}',
    },
    Site: {
        ADD: '/api/company/{companyId}/sites',
        GET: '/api/company/{companyId}/sites/{siteId}',
        GET_ALL: '/api/company/{companyId}/sites',
        UPDATE: '/api/company/{companyId}/sites/{siteId}',
    },
    SiteResource: {
        GET: '/api/company/{companyId}/sites/{siteId}/resource-source-usage',
        RESOURCES_WITH_NON_DEFUALT: '/api/company/{companyId}/resource/{id}/including-non-default',
        UPDATE: '/api/company/{companyId}/sites/{siteId}/resource-source-usage',
    },
    Supplier: {
        ADD: '/api/company/{companyId}/suppliers',
        ADD_LOGO: '/api/company/{companyId}/suppliers/{supplierId}/upload-logo',
        GET: '/api/company/{companyId}/suppliers/{supplierId}',
        GET_ALL: '/api/company/{companyId}/suppliers',
        PRODUCT: '/api/company/{companyId}/suppliers/{supplierId}/products/{productId}',
        SIDELOAD_PRODUCT_TREE: '/api/company/{companyId}/suppliers/sideload-product-tree',
        UPDATE: '/api/company/{companyId}/suppliers/{supplierId}',
    },
    SupplyChain: {
        CONTRITUTION_TREE_CHILDWISE:
            '/api/company/{companyId}/supplychain/{productId}/contribution-tree/childwise',
        SUPPLY_CHAIN: '/api/company/{companyId}/supplychain/{productId}',
    },
    Transport: {
        ADD: '/api/company/{companyId}/transportactivity',
        GET: '/api/company/{companyId}/transportactivity/{productId}/{id}',
        GET_TYPES: '/api/company/{companyId}/transporttypes',
        UPDATE: '/api/company/{companyId}/transportactivity/{id}',
    },
    UnArchive: '/api/company/{companyId}/{apiNode}/{id}/active',
    User: {
        ADD: '/api/users/{companyId}',
        DELETE: '/api/users/user/{userId}',
        GET_ALL: '/api/users/{companyId}',
        ME: '/api/users/me',
        UPDATE: '/api/users/user/{userId}',
        UPDATE_ME: '/api/users/me',
    },
}
