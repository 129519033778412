import { useContext, useEffect, useMemo } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import isArray from 'lodash/fp/isArray'
import { usePrevious } from 'react-use'
import { INote, INoteItem, NOTE_TYPE } from '../types/types'
import { UserContext } from '../contexts/UserProvider'
import getURL from '../utils/getURL'
import { METHOD_DELETE, METHOD_POST, METHOD_PUT, URLs } from '../constants'
import useFetch from './useFetch'
import useUpdateFormData from './useUpdateFormData'


interface IUseCommentaryFormProps {
    autoFetch?: boolean
    autoFetchThread?: boolean
    companyId: string
    noteId?: string
    noteType?: Exclude<NOTE_TYPE, NOTE_TYPE.NOTE> | 'Notes'
    productId?: string
    refetchParent?: () => void
    stage?: string
}

export const useCommentaryForm = ({
    autoFetch = true,
    autoFetchThread = false,
    companyId,
    noteId,
    productId,
    refetchParent,
    stage,
    noteType = 'Notes',
}: IUseCommentaryFormProps) => {
    const {
        currentUser: { displayName, email },
        fetchAllUsers,
        users,
    } = useContext(UserContext)

    useEffect(() => {
        if (!users || users.length === 0) {
            fetchAllUsers()
        }
    }, [users])

    const user = { productId, stage, userEmail: email, userName: displayName }

    const { data, loading, refetch } = useFetch<INote[]>({
        autoFetch: autoFetch && !isEmpty(companyId) && !isEmpty(productId) && !isEmpty(stage),
        defaultRes: [],
        url: getURL((URLs as any)[noteType].GET, { companyId, productId }, `?stage=${stage}`),
    })

    const { data: threadNote, loading: threadLoading, refetch: refetchThread } = useFetch<
        INoteItem
    >({
        autoFetch: autoFetchThread && !isEmpty(noteId),
        url: getURL(URLs.Notes.GET_NOTE_THREAD, { companyId, id: noteId }),
    })

    const refetchNotes = () => {
        if (autoFetch) {
            refetch()
        }
        if (autoFetchThread) {
            refetchThread()
        }
        refetchParent && refetchParent()
    }

    const { update: createNote, saving: creating } = useUpdateFormData({
        method: METHOD_POST,
        onUpdate: refetchNotes,
        url: getURL((URLs as any)[noteType].ADD, { companyId }),
    })

    const { update, saving: updating } = useUpdateFormData({
        method: METHOD_PUT,
        onUpdate: refetchNotes,
        url: getURL((URLs as any)[noteType].UPDATE, { companyId }),
    })

    const { update: deleteNote, saving: deleting } = useUpdateFormData({
        onUpdate: refetchNotes,
        url: '',
    })

    const { update: deleteThreadNote, saving: threadDeleting } = useUpdateFormData({
        onUpdate: () => refetchParent && refetchParent(),
        url: '',
    })

    const { update: readNote } = useUpdateFormData({
        method: METHOD_PUT,
        onUpdate: () => {
            refetchParent && refetchParent()
        },
        url: getURL(URLs.Notes.NOTE_READ, { companyId, id: noteId }),
    })

    const isPrevLoading = usePrevious(loading)
    const isPrevCreating = usePrevious(creating)
    const isPrevUpdating = usePrevious(updating)

    const notes = useMemo(() => {
        if ((isEmpty(data) || !isArray(data)) && isPrevLoading && !loading) {
            return []
        }

        const notesData = data.map(n => {
            return {
                ...n,
                priority: n.severity,
            }
        })
        const parentNotes = notesData.filter(n => isEmpty(n.parentId))

        if (!isEmpty(parentNotes)) {
            return parentNotes.map(note => ({
                ...note,
                subNotes: notesData.filter(n => note.id === n.parentId),
            }))
        }

        return []
    }, [data, isPrevLoading, loading])

    const threadNotes = useMemo(() => {
        if (!isEmpty(threadNote)) {
            const { replies, ...rest } = threadNote
            return [
                {
                    ...rest,
                    priority: rest.severity,
                    subNotes: replies?.map(reply => {
                        return {
                            ...reply,
                            priority: reply.severity,
                        }
                    }),
                    updatedDate: rest.threadUpdatedDate,
                },
            ]
        }
        return []
    }, [threadNote])

    const handleNoteCreate = (note: INote) => {
        const newNote = {
            ...note,
            ...user,
            severity: note.priority,
        } as INote

        createNote(newNote)
    }

    const handleNoteDelete = (note: INote) => {
        if (!isEmpty(note) && note.id) {
            const deleteUrl = getURL((URLs as any)[noteType].DELETE, { companyId, id: note.id })

            if (autoFetchThread && note.subNotes) {
                deleteThreadNote({}, deleteUrl, METHOD_DELETE)
            } else {
                deleteNote({}, deleteUrl, METHOD_DELETE)
            }
        }
    }

    const handleNoteEdit = (note: INote) => {
        if (!note.id) {
            return
        }
        const { subNotes, updatedBy, updatedDate, ...rest } = note
        const noteData = { ...rest, ...user, severity: note.priority }

        update(noteData)
    }

    const handleNoteReply = (parentId: string, note: INote) => {
        const replyNote = {
            ...note,
            ...user,
            parentId,
            severity: note.priority,
        } as INote

        createNote(replyNote)
    }

    const checkOwnNote = (note: INote) => {
        return note.userEmail === email
    }

    return {
        checkOwnNote,
        creating,
        deleting: deleting || threadDeleting,
        handleNoteCreate,
        handleNoteDelete,
        handleNoteEdit,
        handleNoteReply,
        isPrevCreating,
        isPrevUpdating,
        loading,
        notes,
        readNote,
        threadLoading,
        threadNotes,
        updating,
        users,
    }
}
