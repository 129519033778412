import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import isEmpty from 'lodash/fp/isEmpty'
import { Button, Icon, Input, Notification } from '@mondra/ui-components'
import useFetch from '../hooks/useFetch'
import useUpdateFormData from '../hooks/useUpdateFormData'
import { useTopCenterToast } from '../hooks/useTopCenterToast'

import { DeleteAward, UploadImage, LightTooltip, PageLoader } from '../components'
import getURL from '../utils/getURL'
import { URLs } from '../constants'
import { AWARDS } from '../constants/toastNotifications'


const imageTypes = ['image/png']

function getFileError(file: any, imageUrl: any) {
    const errors: any = {}
    if (!file && !imageUrl) {
        errors.file = 'File must be uploaded.'
    }

    if (file && file.size > 200000) {
        errors.size = 'File size must be less than 200 KB.'
    }
    if (file && !imageTypes.includes(file.type)) {
        errors.extension = 'File must be of a PNG.'
    }

    return errors
}

export default function ProductAwards({ readOnly, companyId, productId }: any) {
    const [newAward, setNewAward] = useState(false)
    const { loading, data: awards, refetch } = useFetch({
        autoFetch: !isEmpty(productId),
        url: getURL(URLs.Product.AWARD.GET, { companyId, productId }),
    })

    const showAdd = !loading && (!awards || (awards && awards.length < 3))

    return (
        <div>
            <div className="mb-4 mt-1">
                <Notification
                    type="info"
                    description={`Award images are must be PNG, dimensions are 100 (W) X 150 (H).${' '}`}
                />
            </div>
            {!isEmpty(awards)
                && awards.map((award: any) => (
                    <AwardRow
                        key={award.id}
                        award={award}
                        companyId={companyId}
                        productId={productId}
                        refetch={refetch}
                    />
                ))}
            {newAward && (
                <AwardRow
                    companyId={companyId}
                    productId={productId}
                    onCancel={() => setNewAward(false)}
                    refetch={refetch}
                    readOnly={readOnly}
                />
            )}
            {!readOnly && !newAward && showAdd && (
                <div>
                    <Button
                        type="button"
                        iconType="add"
                        variant="ghost"
                        onClick={() => setNewAward(true)}
                    >
                        Add award
                    </Button>
                </div>
            )}
        </div>
    )
}

const FORMAT = 'dd-MM-yyyy'

function AwardRow({ readOnly, award = {}, companyId, productId, onCancel, refetch }: any) {
    const [file, setFile] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)
    const [fileErrors, setFileErrors] = useState<any>({})
    const [selectedDay, setSelectedDay] = useState(award.expiryDate)
    const { register, errors, handleSubmit } = useForm()
    const [imageUrl, setImageUrl] = useState(award.imageUrl)

    const { showSuccess } = useTopCenterToast()
    const isEdit = award.id

    const { saving, update } = useUpdateFormData({
        contentType: false,
        method: isEdit ? 'PUT' : 'POST',
        onUpdate: () => {
            showSuccess({
                description: isEdit ? AWARDS.UPDATE : AWARDS.ADD,
            })
            refetch()
            onCancel && onCancel()
        },
        url: isEdit
            ? getURL(URLs.Product.AWARD.UPDATE, { companyId, id: award.id, productId })
            : getURL(URLs.Product.AWARD.ADD, { companyId, productId }),
    })

    const handleUpload = (event: any) => {
        const file = event.target.files[0]
        const url = URL.createObjectURL(file)
        setFile(file)
        setFileUrl(url as any)
        setFileErrors(getFileError(file, imageUrl))
    }

    const handleRemove = () => {
        setFileUrl(null)
        setFile(null)
        setImageUrl('')
    }

    function onSubmit(data: any) {
        const fErrors = getFileError(file, imageUrl)
        setFileErrors(fErrors)
        if (isEmpty(fErrors) && isEmpty(errors)) {
            const { name, url } = data
            const formData = new FormData()
            formData.append('name', name)
            formData.append('url', url)
            if (selectedDay) {
                formData.append('expiryDate', new Date(selectedDay).toISOString())
            }
            if (file) {
                formData.append('imageFile', file as any)
            } else {
                formData.append('imageUrl', imageUrl === undefined ? '' : imageUrl)
            }
            update(formData)
        }
    }

    function handleExpiryDate(selectedDay: any) {
        setSelectedDay(selectedDay)
    }

    return (
        <form
            onSubmit={event => {
                event.stopPropagation()
                event.preventDefault()
                !readOnly && !saving && handleSubmit(onSubmit)(event)
            }}
        >
            {(!isEmpty(errors) || !isEmpty(fileErrors)) && (
                <div className="mb-4 grid grid-cols-1">
                    <Notification type="error">
                        {errors.name && <span className="mr-2">Award name is required.</span>}
                        {fileErrors.file && <span>{fileErrors.file}</span>}
                        {fileErrors.size && <span>{fileErrors.size}</span>}
                        {fileErrors.extension && <span>{fileErrors.extension}</span>}
                    </Notification>
                </div>
            )}
            <div className="flex items-center border-b border-gray-200 pb-6 mb-6">
                <AwardLogo
                    readOnly={readOnly}
                    key={fileUrl || imageUrl}
                    register={register}
                    awardId={award.id}
                    awardImageUrl={fileUrl || imageUrl}
                    errors={errors}
                    onUpload={handleUpload}
                    onRemove={handleRemove}
                />
                <div className="grid sm:grid-cols-2 xl:grid-cols-12 items-center gap-y-6 gap-x-2 ml-4">
                    <div className="sm:col-span-1 xl:col-span-4 xxl:col-span-4 flex items-center">
                        <div className="w-full">
                            <label
                                htmlFor={isEdit ? `award-name-${award.id}` : 'award-name'}
                                className="mb-2"
                            >
                                Name
                            </label>
                            <Input
                                disabled={readOnly}
                                id={isEdit ? `award-name-${award.id}` : 'award-name'}
                                className="w-full"
                                name="name"
                                defaultValue={award.name}
                                ref={register({ required: true })}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-1 xl:col-span-4 xxl:col-span-4">
                        <label
                            htmlFor={isEdit ? `award-url-${award.id}` : 'award-url'}
                            className="mb-2"
                        >
                            URL
                        </label>
                        <Input
                            disabled={readOnly}
                            id={isEdit ? `award-url-${award.id}` : 'award-url'}
                            className="w-full"
                            name="url"
                            defaultValue={award.url}
                            ref={register}
                        />
                    </div>
                    <div className="sm:col-span-1 xl:col-span-2 xxl:col-span-2">
                        <label
                            htmlFor={isEdit ? `expirydate-${award.id}` : 'expiry-date'}
                            className="mb-2"
                        >
                            Expiry date
                        </label>

                        <DayPickerInput
                            component={(props: any) => (
                                <Input
                                    id={isEdit ? `expirydate-${award.id}` : 'expiry-date'}
                                    name="expiryDate"
                                    className="w-full"
                                    disabled={readOnly}
                                    {...props}
                                />
                            )}
                            formatDate={(date: any, format: any, locale: any) => {
                                return dateFnsFormat(date, format, { locale })
                            }}
                            format={FORMAT}
                            value={selectedDay ? new Date(selectedDay) : ''}
                            placeholder="DD-MM-YYYY"
                            parseDate={(str: any, format: any, locale: any) => {
                                const parsed = dateFnsParse(str, format, new Date(), {
                                    locale,
                                })
                                if (DateUtils.isDate(parsed)) {
                                    return parsed
                                }
                                return undefined
                            }}
                            onDayChange={handleExpiryDate}
                            overlayComponent={OveralyCompoent}
                            dayPickerProps={{
                                disabledDays: {
                                    daysOfWeek: [0, 6],
                                },
                                selectedDays:
                                    (selectedDay && new Date(selectedDay))
                                    || new Date(award.expiryDate),
                            }}
                        />
                    </div>
                    <div className="sm:col-span-1 xl:col-span-2 xxl:col-span-2 flex items-center justify-end">
                        {!isEdit ? (
                            <div className="mr-1">
                                <label className="mb-2 invisible">Cancel</label>
                                <Button
                                    disabled={saving || readOnly}
                                    title="Cancel"
                                    type="button"
                                    variant="ghost"
                                    className="ml-4 flex-shrink-0"
                                    onClick={onCancel}
                                >
                                    <Icon type="close" />
                                </Button>
                            </div>
                        ) : (
                            <DeleteAward
                                readOnly={readOnly}
                                award={award}
                                companyId={companyId}
                                productId={productId}
                                refetch={refetch}
                            />
                        )}
                        <div className="relative">
                            {saving && <PageLoader sizeClass="w-8 h-8" />}
                            {!saving && (
                                <Button
                                    disabled={saving}
                                    title="Save"
                                    type="submit"
                                    variant="ghost"
                                    className="ml-4 flex-shrink-0"
                                >
                                    <Icon type="checkmark" />
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

function AwardLogo({ readOnly, awardId = 'new', awardImageUrl, onUpload, onRemove }: any) {
    const [error, setError] = useState(false)
    const imageReady = awardImageUrl && !error

    return (
        <div className="flex-col items-center">
            <div
                className={`flex items-center justify-center border border-gray-200 bg-white rounded shadow-md ${
                    imageReady ? 'bg-transparent cursor-pointer' : ''
                } relative mx-auto`}
                style={{
                    height: '45px',
                    minHeight: '45px',
                    minWidth: '30px',
                    width: '30px',
                }}
            >
                {awardImageUrl && !error && (
                    <LightTooltip
                        title={
                            <div
                                className="border border-gray-200 bg-white rounded shadow-md w-8 h-12"
                                style={{
                                    height: '150px',
                                    minHeight: '150px',
                                    minWidth: '100px',
                                    width: '100px',
                                }}
                            >
                                <img
                                    src={awardImageUrl}
                                    alt="brand title"
                                    className="max-w-full max-h-full w-full h-full object-contain"
                                />
                            </div>
                        }
                    >
                        <img
                            src={awardImageUrl}
                            alt="brand title"
                            className="max-w-full max-h-full w-full h-full object-contain"
                            onError={() => setError(true)}
                        />
                    </LightTooltip>
                )}
                {(!awardImageUrl || error) && (
                    <span className="uppercase text-gray-800 leading-normal text-base">A</span>
                )}
                {!readOnly && onRemove && awardImageUrl && (
                    <div
                        className="flex justify-center items-center absolute right-0 top-0 -mt-3 -mr-3 h-4 w-4 leading-normal text-xs text-red-600  cursor-pointer rounded-full text-center bg-gray-100 hover:text-red-800 hover:ring"
                        role="button"
                        onClick={onRemove}
                    >
                        <Icon type="close" />
                    </div>
                )}
            </div>
            {!readOnly && (
                <UploadImage
                    name="awardLogo"
                    id={awardId}
                    onUpload={onUpload}
                    textSize="leading-normal text-xs"
                />
            )}
        </div>
    )
}

function OveralyCompoent({ classNames, children, ...props }: any) {
    return (
        <div className="relative -ml-4">
            <div className={classNames.overlay} {...props}>
                {children}
            </div>
        </div>
    )
}
