import React, { useMemo, useContext, useEffect, useState } from 'react'
import formatDate from 'date-fns/format'
import classNames from 'classnames'

import { Button, Dropdown } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import first from 'lodash/fp/first'
import getOr from 'lodash/fp/getOr'
import { Certificate, ProductDetailTypes, IProduct, CertificateStateEnum } from '../types/types'
import { significantFigureFormatter } from '../utils/numberFormatter'
import findImpact from '../utils/findImpact'
import getImpactMeasureUICopy from '../utils/getImpactMeasureUICopy'
import { toDate } from '../utils/dateUtils'
import useProduct from '../hooks/useProduct'
import useProductDetails from '../hooks/useProductDetails'
import { MESSAGE_TRIGGER_SUB_TYPE, PER_SERVING, PRODUCT_SIZES } from '../constants'
import { SLI_DESC } from '../constants/ecoImpactMeasures'
import getStageImpactFromProduct from '../utils/getStageImpactFromProduct'
import { NotificationContext } from '../contexts/SignalRNotificationProvider'
import { ProductSettingContext } from '../contexts/ProductSettingProvider'
import { getGradeHeaderColor } from '../constants/gradeHeaderColors'
import ImpactDetailsModal from './ImpactDetailsByStage/ImpactDetailsModal'
import ErrorDetails from './ErrorDetails'


interface ILatestCertCardProps {
    companyId: string
    refetchCertificate?: () => void
    refetchProduct?: () => void
    loadingData?: boolean
    cert: Certificate
    product?: IProduct
}

export default function LatestCertCard(props: ILatestCertCardProps) {
    const {
        cert: { saState },
    } = props

    const [validationErrors, setValidationErrors] = useState<string[]>([])

    return (
        <CertCardContext.Provider value={{ setValidationErrors, validationErrors }}>
            {getCard(saState, props)}
        </CertCardContext.Provider>
    )
}

function getCard(saState: CertificateStateEnum, props: ILatestCertCardProps) {
    switch (saState) {
        case CertificateStateEnum.Certified:
            return <CertifiedCard {...props} />
        case CertificateStateEnum.CertificationExpired:
            return <ExpiredCard {...props} />
        default:
            return <DraftCard {...props} />
    }
}

function Header({ colorKey, title, perServingSize = 0 }: any) {
    const { productSizeMeasure, setProductSizeMeasure } = useContext(ProductSettingContext)
    const headerColor = getGradeHeaderColor(colorKey)

    const sizeOptions = useMemo(() => {
        if (perServingSize && parseFloat(perServingSize) > 0) {
            return PRODUCT_SIZES
        }
        return PRODUCT_SIZES.filter(s => s.value !== PER_SERVING)
    }, [perServingSize])

    const handleSizeChange = (selected: any) => {
        setProductSizeMeasure(selected)
    }

    return (
        <div
            className={classNames(
                'flex-shrink-0 p-4 pt-3 sm:px-6 sm:pb-4 sm:pt-3 flex items-start justify-between rounded-t-lg space-x-4',
                headerColor.bg,
                headerColor.text
            )}
        >
            <div className="flex-1">
                <h5 className="font-medium text-lg">{title}</h5>
                <div className={classNames('text-sm', headerColor.desc)}>
                    <Dropdown
                        className="bg-transparent h-auto border-0 shadow-none hover:bg-gray-50 hover:bg-opacity-20 px-2 py-1 flex-grow-0 w-32"
                        labelClass={headerColor.desc}
                        labelContainerClass="text-sm leading-3"
                        optionsContainerClass="w-36"
                        triggerIconClass={classNames(
                            'w-4 h-4',
                            colorKey === 'Certified' ? '!text-white' : ''
                        )}
                        options={sizeOptions}
                        selected={productSizeMeasure}
                        onChange={handleSizeChange}
                    />
                </div>
            </div>
        </div>
    )
}

interface IEcoImpactsList {
    cert: Certificate
    companyId: string
    onUpdate: any
    product?: IProduct
}

function EcoImpactsList({ cert, companyId, onUpdate, product }: IEcoImpactsList) {
    const { productSizeMeasure } = useContext(ProductSettingContext)
    const { ecoImpacts, updatedAt } = cert
    const { ghg, water, eutro, bio } = findImpact(ecoImpacts)
    const { carbonMeasure, waterUsageMeasure, eutroMeasure, bioMeasure } = getImpactMeasureUICopy()
    const measure = productSizeMeasure.value

    return (
        <div className="flex flex-col w-1/2 px-4 pt-1 pb-4 sm:px-6">
            <div className="flex-grow">
                <ul className="divide-y">
                    <li className="flex items-center py-2 truncate">
                        <label className="text-gray-900 font-medium text-base leading-6">
                            {carbonMeasure.label}:
                        </label>
                        <label className="ml-1 font-medium leading-6 text-gray-500 truncate">
                            {significantFigureFormatter(ghg?.[measure])}
                            {carbonMeasure.unit} {carbonMeasure.measure}
                        </label>
                    </li>
                    <li className="flex items-center py-2 truncate">
                        <label className="text-gray-900 font-medium text-base leading-6">
                            {waterUsageMeasure.label}:
                        </label>
                        <label className="ml-1 font-medium leading-6 text-gray-500 truncate">
                            {significantFigureFormatter(water?.[measure])} {waterUsageMeasure.unit}{' '}
                            {waterUsageMeasure.measure}
                        </label>
                    </li>
                    <li className="flex items-center py-2 truncate">
                        <label className="text-gray-900 font-medium text-base leading-6">
                            {eutroMeasure.label}:
                        </label>
                        <label className="ml-1 font-medium leading-6 text-gray-500 truncate">
                            {significantFigureFormatter(eutro?.[measure])}
                            {eutroMeasure.unit} {eutroMeasure.measure}
                        </label>
                    </li>
                    <li className="flex items-center py-2 truncate">
                        <label className="text-gray-900 font-medium text-base leading-6">
                            {bioMeasure.label}:
                        </label>
                        <label className="ml-1 font-medium leading-6 text-gray-500 truncate">
                            {significantFigureFormatter(bio?.[measure])}{' '}
                            <span title={SLI_DESC}>{bioMeasure.measure}</span>
                        </label>
                    </li>
                </ul>
                <div className="text-xs font-medium text-gray-400">
                    <span>Last edited: </span>
                    <time>
                        {updatedAt ? formatDate(toDate(updatedAt as any), 'd/MM/yyyy, HH:mm') : '-'}
                    </time>
                </div>
            </div>
            <EcoImpactFooter
                companyId={companyId}
                productId={product?.id || ''}
                onUpdate={onUpdate}
            />
        </div>
    )
}

interface IEcoImpactFooter {
    companyId: string
    onUpdate: any
    productId: string
}

function EcoImpactFooter({ companyId, productId, onUpdate }: IEcoImpactFooter) {
    const [open, setOpen] = useState(false)
    const [impactRequested, setImpactRequested] = useState(false)
    const { messages = [] } = useContext(NotificationContext)
    const { productSizeMeasure, readOnly } = useContext(ProductSettingContext)
    const { validationErrors, setValidationErrors } = useContext(CertCardContext)

    useEffect(() => {
        if (messages?.length > 0) {
            const msg = first(messages) as any
            if (
                !msg.isHistory
                && msg.notificationType === MESSAGE_TRIGGER_SUB_TYPE.V2CALCIMPACTSRECEIVED
                && msg.productId === productId
            ) {
                onUpdate()
                setImpactRequested(false)
            }
        }
    }, [messages])

    useEffect(() => {
        if (!isEmpty(validationErrors)) {
            setImpactRequested(false)
        }
    }, [validationErrors])

    const handleCalcUpdate = (res: any) => {
        const errorMessages = getOr([], 'messages', res)
        setValidationErrors(errorMessages)
        if (isEmpty(errorMessages)) {
            onUpdate()
        }
    }

    const { calculate } = useProduct({
        autoFetch: false,
        companyId,
        onUpdate: handleCalcUpdate,
        productId,
    })

    const {
        details: ecoImpactDetails,
        loading: loadingImpacts,
        refetch: refetchImpacts,
    } = useProductDetails({
        autoFetch: false,
        companyId,
        productId: productId,
        type: ProductDetailTypes.ECOIMPACTDETAILS,
    })

    const handleCalculate = () => {
        setImpactRequested(true)
        calculate({})
    }

    const handleOpenImpact = () => {
        refetchImpacts()
        setOpen(true)
    }

    const stageImpacts = useMemo(() => {
        return getStageImpactFromProduct(ecoImpactDetails, productSizeMeasure.value)
    }, [ecoImpactDetails, productSizeMeasure.value])

    return (
        <div className="flex justify-between items-center gap-x-4 mt-2">
            <Button
                disabled={impactRequested || readOnly}
                variant="secondary"
                className="w-full justify-start mt-2"
                onClick={handleCalculate}
            >
                {impactRequested ? 'Wait...' : 'Calculate'}
            </Button>
            <Button
                variant="secondary"
                disabled={loadingImpacts}
                className="w-full justify-start mt-2"
                onClick={handleOpenImpact}
            >
                {loadingImpacts ? 'Loading...' : 'Impacts'}
            </Button>
            <ImpactDetailsModal
                open={open && !loadingImpacts}
                stageImpacts={stageImpacts}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

function DraftCard({ cert, companyId, product, refetchProduct }: ILatestCertCardProps) {
    const { validationErrors } = useContext(CertCardContext)
    return (
        <Wrapper>
            <Header
                colorKey="none"
                title="Uncertified impacts"
                perServingSize={product?.servingSize}
            />
            <div className="flex-grow flex">
                <EcoImpactsList
                    cert={cert}
                    companyId={companyId}
                    product={product}
                    onUpdate={refetchProduct}
                />
                <div className="flex flex-col flex-grow justify-end items-center">
                    {!isEmpty(validationErrors) && <ErrorDetails errors={validationErrors || []} />}
                </div>
            </div>
        </Wrapper>
    )
}

function CertifiedCard({ companyId, cert, product, refetchProduct }: ILatestCertCardProps) {
    const { validationErrors } = useContext(CertCardContext)
    const colorKey = 'Certified' || 'none'
    return (
        <Wrapper>
            <Header
                colorKey={colorKey}
                title="Certified impacts"
                perServingSize={product?.servingSize}
            />

            <div className="flex-grow flex">
                <EcoImpactsList
                    cert={cert}
                    companyId={companyId}
                    product={product}
                    onUpdate={refetchProduct}
                />
                <div className="flex flex-col flex-grow justify-end items-center">
                    {!isEmpty(validationErrors) && (
                        <ErrorDetails errors={validationErrors || []} background="bg-primary-50" />
                    )}
                </div>
            </div>
        </Wrapper>
    )
}

function ExpiredCard({ cert, companyId, product, refetchProduct }: ILatestCertCardProps) {
    const { validationErrors } = useContext(CertCardContext)
    return (
        <Wrapper>
            <Header
                colorKey="error"
                title="Uncertified impacts"
                perServingSize={product?.servingSize}
            />

            <div className="flex-grow flex">
                <EcoImpactsList
                    cert={cert}
                    companyId={companyId}
                    product={product}
                    onUpdate={refetchProduct}
                />
                <div className="flex flex-col flex-grow justify-end items-center">
                    {!isEmpty(validationErrors) && (
                        <ErrorDetails errors={validationErrors || []} background="bg-pink-50" />
                    )}
                </div>
            </div>
        </Wrapper>
    )
}

function Wrapper({ children, className }: any) {
    return (
        <div className={`h-full flex flex-col bg-white shadow rounded-lg min-h-80 ${className}`}>
            {children}
        </div>
    )
}

interface ICertCardContext {
    validationErrors: string[]
    setValidationErrors: (errors: string[]) => void
}

export const CertCardContext = React.createContext<ICertCardContext>({
    setValidationErrors: () => null,
    validationErrors: [],
})
