import React from 'react'
import classNames from 'classnames'
import { linearScale } from './dataQualityScoreUtils'


interface IDataQualityScoreProps {
    value: number
    small?: boolean
}

export function DataQualityScore({ value, small }: IDataQualityScoreProps) {
    const score: number | string = value || 0

    return (
        <div className="flex items-center">
            {value >= 1 && (
                <div
                    className={classNames(
                        small ? 'h-3 w-3' : 'mr-2 h-4 w-4',
                        'flex items-center justify-center rounded-full'
                    )}
                    style={{ backgroundColor: linearScale(score) }}
                />
            )}
            <div
                className={classNames(
                    small ? 'w-6 text-right text-sm' : 'text-xl',
                    'text-gray-800'
                )}
            >
                {value >= 1 ? score.toFixed(1) : '-'}
            </div>
        </div>
    )
}
