import { EcoImpactIds, IEcoImpactMeasure } from '../types/ecoImpacts'

import ecoImpactMeasures from '../constants/ecoImpactMeasures'


export default function getImpactMeasureUICopy() {
    const carbonMeasure: IEcoImpactMeasure = ecoImpactMeasures[EcoImpactIds.EIID001]
    const waterUsageMeasure: IEcoImpactMeasure = ecoImpactMeasures[EcoImpactIds.EIID002]
    const eutroMeasure: IEcoImpactMeasure = ecoImpactMeasures[EcoImpactIds.EIID003]
    const bioMeasure: IEcoImpactMeasure = ecoImpactMeasures[EcoImpactIds.EIID004]

    return {
        bioMeasure,
        carbonMeasure,
        eutroMeasure,
        waterUsageMeasure,
    }
}
