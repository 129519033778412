import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Toaster } from '@mondra/ui-components'
import { useAuth0 } from '@auth0/auth0-react'
import { AppHeader } from './components/AppHeader'
import RedirectToPath from './components/RedirectToPath'
import { APP_FEATURES_ENV, HOME_URL, URLs } from './constants'
import AuthProvider from './contexts/AuthProvider'
import UserProvider from './contexts/UserProvider'
import FeatureFlagProvider from './contexts/FeatureFlagProvider'

import ErrorPage from './pages/ErrorPage'
import Home from './pages/Home'
import Settings from './pages/Settings'
import Ingredients from './pages/Ingredients'
import Products from './pages/Products'
import Suppliers from './pages/Suppliers'
import Categories from './pages/Categories'
import NotFound from './pages/NotFound'
import NoCompany from './pages/NoCompany'
import SupplyChain from './pages/SupplyChain'
import ProductOverview from './pages/ProductOverview'
import SuppliedProducts from './pages/SuppliedProducts'
import SideloadCertificateProvider from './contexts/SideloadCertificateProvider'
import SlideoverCountProvider from './contexts/SlideoverCountProvider'
import Sites from './pages/Sites'


function App() {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname,
                },
            })
        }
    }, [isLoading, isAuthenticated])

    if (!isAuthenticated) {
        return null
    }

    return (
        <FeatureFlagProvider flags={APP_FEATURES_ENV}>
            <SlideoverCountProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <UserProvider>
                            <SideloadCertificateProvider>
                                <div className="h-full">
                                    <Toaster containerClassName="!z-toaster" />
                                    <AppHeader />
                                    <div className="flex flex-col contianer min-h-scroll max-h-scroll overflow-y-auto bg-pageBgColor">
                                        <Switch>
                                            <Redirect exact from="/" to={HOME_URL} />
                                            <Route
                                                exact
                                                path="/company"
                                                component={RedirectToPath}
                                            />
                                            <Route path="/company/:companyId" component={Home} />
                                            <Route
                                                path="/settings/:companyId"
                                                component={Settings}
                                            />
                                            <Route
                                                exact
                                                path="/products"
                                                component={RedirectToPath}
                                            />
                                            <Route
                                                exact
                                                path="/products/:companyId"
                                                component={Products}
                                            />
                                            <Route
                                                exact
                                                path="/suppliers/:companyId"
                                                component={Suppliers}
                                            />
                                            <Route
                                                exact
                                                path={URLs.PagePaths.CATEGORIES}
                                                component={Categories}
                                            />
                                            <Route
                                                path="/product/:companyId/:id"
                                                component={ProductOverview}
                                            />
                                            <Route
                                                path="/supplied-products/:companyId/:id"
                                                component={ProductOverview}
                                            />
                                            <Route
                                                exact
                                                path="/ingredients/:companyId"
                                                component={Ingredients}
                                            />
                                            <Route
                                                exact
                                                path="/supplied-products/:companyId"
                                                component={SuppliedProducts}
                                            />
                                            <Route
                                                path="/supply-chain/:companyId/:id"
                                                component={SupplyChain}
                                            />
                                            <Route
                                                exact
                                                path="/sites/:companyId"
                                                component={Sites}
                                            />

                                            <Route path="/no-company" component={NoCompany} />
                                            <Route path="/500" component={ErrorPage} />
                                            <Route path="*" component={NotFound} />
                                        </Switch>
                                    </div>
                                </div>
                            </SideloadCertificateProvider>
                        </UserProvider>
                    </AuthProvider>
                </BrowserRouter>
            </SlideoverCountProvider>
        </FeatureFlagProvider>
    )
}

export default App
