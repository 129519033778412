import isEmpty from 'lodash/fp/isEmpty'
import memoize from 'lodash/fp/memoize'
import map from 'lodash/fp/map'
import { ASC, URLs } from '../constants'
import getURL from '../utils/getURL'
import { IRegion } from '../types/types'
import useFetch from './useFetch'


const parse = memoize(
    map((c: IRegion) => ({
        ...c,
        label: c.name,
        value: c.name,
    }))
)

interface IuseRegionProps {
    autoFetch?: boolean
    countryCode: string
}

export default function useRegions({ autoFetch = true, countryCode }: IuseRegionProps) {
    const { data, refetch, loading } = useFetch({
        autoFetch: autoFetch && !isEmpty(countryCode),
        errMessage: 'Failed to fetch regions',
        url: getURL(URLs.Region.GET, { countryCode }, `?Order=name;${ASC}`),
    })

    return {
        loading,
        refetch,
        regions: parse(data?.data) as IRegion[],
    }
}
