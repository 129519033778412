import { useCallback, useState } from 'react'
import { GridApi } from 'ag-grid-community'


export function useGridControls() {
    const [gridApi, setGridApi] = useState<GridApi>()

    const refreshGrid = useCallback(() => {
        gridApi?.refreshServerSideStore()
    }, [gridApi])

    const deSelectAllRows = useCallback(() => {
        gridApi?.deselectAll()
        gridApi?.redrawRows()
    }, [gridApi])

    return {
        deSelectAllRows,
        gridApi,
        refreshGrid,
        setGridApi,
    }
}
