import React, { ReactElement, ReactNode, ReactChildren, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'

import { IChildrenProps, IClassNameProps } from '../types/types'
import ExpandContainer from './ExpandContainer'


interface ICardProps extends IChildrenProps, IClassNameProps {
    isExapanded?: boolean
    disableExpand?: boolean
    title: string
    right?: () => ReactNode | ReactElement | ReactChildren
    left?: () => ReactNode | ReactElement | ReactChildren
    visible?: boolean
}

const Card = ({
    isExapanded = false,
    disableExpand,
    className = '',
    title,
    right,
    left,
    children,
    visible = true,
}: ICardProps) => {
    const [expanded, setExpaned] = useState(isExapanded)

    useEffect(() => {
        !disableExpand && setExpaned(isExapanded)
    }, [isExapanded, disableExpand])

    return (
        <div
            className={classNames(
                'bg-white shadow rounded-lg overflow-hidden text-gray-800 mb-4',
                className,
                { hidden: !visible }
            )}
        >
            <div
                title={disableExpand ? '' : 'Click to view content'}
                className={classNames(
                    'relative flex justify-between items-center h-card-header py-2 border-b border-gray-300 leading-normal text-base font-bold bg-white',
                    {
                        'pl-6 pr-10 cursor-pointer': !disableExpand,
                        'px-6': disableExpand,
                    }
                )}
                role="button"
                onClick={() => !disableExpand && setExpaned(expanded => !expanded)}
            >
                <div className="flex justify-between items-center text-gray-900 font-medium">
                    {left && left()}
                    {title}
                </div>
                {right && right()}
                {!disableExpand && (
                    <Icon
                        type="chevronDown"
                        className={classNames(
                            'absolute right-0 top-0 transition-transform duration-75 ease-in-out text-gray-600 mr-4 mt-4 hover:ring focus:shadow-focus',
                            {
                                'transform rotate-180': expanded,
                            }
                        )}
                    />
                )}
            </div>
            <ExpandContainer expanded={expanded}>
                <div className="py-6 px-6">{children}</div>
            </ExpandContainer>
        </div>
    )
}

export default Card
