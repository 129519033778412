import React from 'react'
import MondraIcon from './MondraIcon'


export default function LcaLegend() {
    return (
        <div
            className="bg-primary-400 rounded-full p-1 ml-2 w-max inline-flex"
            aria-label="LCA"
            title="LCA"
        >
            <MondraIcon height="12px" width="12px" color="white" />
        </div>
    )
}
