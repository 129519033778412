import React, { useEffect, useState } from 'react'
import map from 'lodash/fp/map'
import isEmpty from 'lodash/fp/isEmpty'
import { Notification } from '@mondra/ui-components'
import { ISlideoverFormProps } from '../component-slideover/ComponentSlideover'
import Row from '../../components/Row'
import useFetch from '../../hooks/useFetch'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import { IPackagingLeg, IPackagingSet } from '../../types/stages'
import getURL from '../../utils/getURL'
import IsRequiredToggle from '../components/IsRequiredToggle'
import PackagingSet from './PackagingSet'


export interface IDistributorError {
    [key: number]: {
        itemsNumber: boolean
        packaging: boolean
    }
}

export interface IError {
    distributor: IDistributorError
    primary: boolean
}

export interface IPackagingSetData {
    distributorLegs: IPackagingLeg[]
    primaryLeg: IPackagingLeg
}

export interface IPackagingFormData {
    set: IPackagingSetData
}

const defaultPackagingForm: IPackagingFormData = {
    set: {
        distributorLegs: [],
        primaryLeg: {},
    },
}

export default function PackagingForm({
    companyId,
    formId,
    onClose,
    onFinished,
    product,
    setDisableSave,
    setSaving,
    updateProductCustomProps,
}: ISlideoverFormProps) {
    const [isRequired, setRequired] = useState<boolean>(product.packagingRequired)
    const [packagingFormData, setPackagingFormData] = useState<IPackagingFormData>(
        defaultPackagingForm
    )
    const [error, setError] = useState<IError>({
        distributor: {},
        primary: false,
    })
    const isSiteEmpty = isEmpty(product.site?.address)

    const onSaved = () => {
        if (isRequired !== product.packagingRequired) {
            updateProductCustomProps({ packagingRequired: isRequired })
        } else {
            onFinished && onFinished()
        }
    }

    // Packaging Set
    const { loading, data: packagingSet } = useFetch<IPackagingSet>({
        autoFetch: !isEmpty(product?.id),
        defaultRes: {},
        errMessage: 'Error while fetching packaging data',
        onError: onClose,
        url: getURL(URLs.Packaging.GET, { companyId, productId: product.id }),
    })
    const isSetEdit = !isEmpty(packagingSet) && !isEmpty(packagingSet.id)

    const { saving, update: saveSetData } = useUpdateFormData({
        method: isSetEdit ? METHOD_PUT : METHOD_POST,
        onUpdate: onSaved,
        url: isSetEdit
            ? getURL(URLs.Packaging.UPDATE, { companyId, id: packagingSet.id })
            : getURL(URLs.Packaging.ADD, { companyId }),
    })

    useEffect(() => {
        setRequired(product.packagingRequired)
    }, [product.packagingRequired])

    useEffect(() => {
        setDisableSave(loading)
    }, [loading])

    const disabled = !isRequired || isSiteEmpty || loading || saving

    const validateForm = () => {
        if (!isRequired) {
            return true
        }

        if (isEmpty(packagingFormData.set.primaryLeg?.selected) && isRequired) {
            setError((e: IError) => ({ ...e, primary: true }))
            return false
        }

        if (packagingFormData.set.distributorLegs.length > 0) {
            const distError = packagingFormData.set.distributorLegs.reduce(
                (acc: IDistributorError, leg: IPackagingLeg, index: number) => {
                    if (!leg.selected?.id || !leg.itemsNumber) {
                        return {
                            ...acc,
                            [index]: {
                                itemsNumber: !leg.itemsNumber,
                                packaging: !leg.selected?.id,
                            },
                        }
                    }
                    return acc
                },
                {}
            )

            if (!isEmpty(distError)) {
                setError((e: IError) => ({
                    ...e,
                    distributor: distError,
                }))
                return false
            }
        }
        return true
    }

    const handleSave = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        if (isSiteEmpty) {
            onSaved()
            return
        }

        if (!validateForm()) {
            return
        }

        setSaving(true)

        // Prepare Packaging Set Data
        const { selected, ...pLeg } = packagingFormData.set.primaryLeg
        const dLegs = map((leg: IPackagingLeg) => {
            const { selected: xSet, ...dLeg } = leg
            return dLeg
        }, packagingFormData.set.distributorLegs)

        let packagingLegs = []
        if (!isEmpty(pLeg)) {
            packagingLegs.push(pLeg)
        }
        if (!isEmpty(dLegs)) {
            packagingLegs = [...packagingLegs, ...dLegs]
        }

        const postDataSet = {
            ...packagingSet,
            packagingLegs,
            productId: product.id,
        }

        // Saving form data
        saveSetData(postDataSet)
    }

    return (
        <form id={formId} className="divide-y divide-gray-200" onSubmit={handleSave}>
            <IsRequiredToggle
                title="Is packaging required?"
                description="If you are not packaging your final product set as not required."
                isRequired={isRequired}
                onChange={setRequired}
            />
            <Row className="grid-cols-3 py-0">
                <div className="py-3">
                    <div className="text-sm font-medium text-gray-700">Packaging Site</div>
                    <div className="text-sm font-medium text-gray-900">
                        {product.site && product.site?.address ? (
                            <span>
                                {product.site?.name}, {product.site?.address?.country}
                            </span>
                        ) : (
                            <span>N/A</span>
                        )}
                    </div>
                </div>
                {isSiteEmpty && isRequired && (
                    <Notification
                        type="error"
                        className="col-span-2"
                        description="You must Specify a site for where your products are packaged."
                    />
                )}
            </Row>

            <PackagingSet
                companyId={companyId}
                disabled={disabled}
                error={error}
                packaging={packagingSet}
                packagingLoading={loading}
                setError={setError}
                formData={packagingFormData.set}
                productNetWeight={product.netWeight}
                setPackagingFormData={setPackagingFormData}
            />

            <div />
        </form>
    )
}
