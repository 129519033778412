import React from 'react'
import { Icon, Tabs } from '@mondra/ui-components'

import { DashboardItemList } from './DashboardItemList'


const PINNED_RECENT_TABS = [
    {
        id: '1',
        label: <TabHeader title="Recently viewed" />,
        panel: <DashboardItemList />,
    },
]

export function DashboardPinnedRecentItems() {
    return (
        <div className="bg-white w-full animate-fade-in mt-6">
            <Tabs
                headerText="Select a view"
                manual
                gapClass="border border-gray-200"
                tabs={PINNED_RECENT_TABS}
                vertical
            />
        </div>
    )
}

function TabHeader({ title }: { title: string }) {
    return (
        <div className="flex justify-between w-full">
            <div>{title}</div>
            <Icon type="chevronRight" size={16} />
        </div>
    )
}
