import React, { useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import startCase from 'lodash/fp/startCase'
import { IProduct, NodeType } from '../../types/types'
import { ISlideoverProps } from '../../types/slideover-types'
import useProduct from '../../hooks/useProduct'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { SAVE } from '../../constants/toastNotifications'
import { LockActivityContextProvider } from '../../contexts/LockActivityContextProvider'
import PackagingForm from '../packaging/PackagingForm'
import ProcessingForm from '../processing/ProcessingForm'
import RecipeForm from '../recipe/RecipeForm'
import SaleForm from '../sale/SaleForm'
import StorageForm from '../storage/StorageForm'
import { NotesButton, PageLoader } from '../../components'
import Slideover from '../components/Slideover'
import { ActivityLock } from './ActivityLock'


export interface IComponentProps extends ISlideoverProps {
    product?: IProduct
    productId?: string
}

export interface ISlideoverFormProps {
    companyId: string
    formId: string
    isOpen?: boolean
    onChange: (change: any) => void
    onClose: () => void
    onFinished?: (data?: any) => void
    onSubmit?: (event: any) => void
    patchProduct: (value: any, path?: string) => void
    product: IProduct
    saving?: boolean
    setDisableSave: React.Dispatch<React.SetStateAction<boolean>>
    setSaving: React.Dispatch<React.SetStateAction<boolean>>
    updateProductCustomProps: (payload: any) => void
    updateProductMetadata: (data: any) => void
}

const lockActionWhiteList = [NodeType.PROCESSING, NodeType.STORAGE]

export default function ComponentSlideover({
    companyId,
    isOpen,
    level = 1,
    onClose,
    onSaved,
    product: defaultProduct,
    productId,
    type,
    wider,
}: IComponentProps) {
    const [saving, setSaving] = useState(false)

    const [disableSave, setDisableSave] = useState(false)
    const formId = `${type}-form`

    const { showSuccess } = useTopCenterToast()

    const handleClose = () => {
        setSaving(false)
        onClose(type)
    }

    const onFinished = () => {
        handleClose()
        onSaved && onSaved({ type })
        showSuccess({ description: SAVE.SUCCESS })
    }

    const {
        loading,
        onChange,
        product,
        saving: productSaving,
        patchProduct,
        updateProduct,
        updateProductCustomProps,
        updateProductMetadata,
    } = useProduct({
        autoFetch: isOpen && isEmpty(defaultProduct) && !isEmpty(productId),
        companyId,
        defaultValue: defaultProduct,
        onError: () => setSaving(false),
        onUpdate: onFinished,
        productId: productId || defaultProduct?.id,
    })

    const onSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        updateProduct(product)
    }

    const formProps: ISlideoverFormProps = {
        companyId,
        formId,
        isOpen,
        onChange,
        onClose: handleClose,
        onFinished,
        onSubmit,
        patchProduct,
        product,
        saving,
        setDisableSave,
        setSaving,
        updateProductCustomProps,
        updateProductMetadata,
    }

    const disableButton = saving || productSaving

    return (
        <LockActivityContextProvider>
            <Slideover
                wider={wider}
                isOpen={isOpen}
                level={level}
                formId={formId}
                saving={disableButton}
                disableSave={disableSave}
                onClose={handleClose}
                type={type}
                title={loading ? 'Loading...' : `${product.name} ${startCase(type)}`}
                footerChildren={
                    <div className="flex items-center space-x-2">
                        <NotesButton
                            className="relative"
                            stage={type}
                            productId={productId || defaultProduct?.id || ''}
                        />
                        {lockActionWhiteList.includes(type) && <ActivityLock />}
                    </div>
                }
                footerProps={{
                    className: 'flex flex-row justify-between w-full',
                }}
            >
                {loading && <PageLoader centered className="mt-4" />}
                {!loading && type === NodeType.RECIPE && <RecipeForm {...formProps} />}
                {!loading && type === NodeType.PACKAGING && <PackagingForm {...formProps} />}
                {!loading && type === NodeType.PROCESSING && <ProcessingForm {...formProps} />}
                {!loading && type === NodeType.SALE && <SaleForm {...formProps} />}
                {!loading && type === NodeType.STORAGE && <StorageForm {...formProps} />}
            </Slideover>
        </LockActivityContextProvider>
    )
}
