import React from 'react'
import { Autocomplete, Dropdown } from '@mondra/ui-components'
import { IOptionProps } from '@mondra/ui-components/@types'
import { IDropdownProps } from '@mondra/ui-components/dist/components/dropdown/Dropdown'
import { IAutocompleteProps } from '@mondra/ui-components/dist/components/auto-complete/Autocomplete'


interface IDropdownComponentProps<T> extends IDropdownProps<T>, IAutocompleteProps<T> {
    groupKey?: string
    onChange: (selected?: T) => void
    type?: 'Autocomplete' | 'Dropdown'
}

export default function DropdownComponent<T extends IOptionProps>({
    groupKey,
    onChange,
    options,
    type,
    ...other
}: IDropdownComponentProps<T>) {
    const isDropdown = type === 'Dropdown' || (type === undefined && options.length <= 8)

    const group = (p: any) => <Autocomplete.GroupList groupKey={groupKey} {...p} />

    const handleOnChangeAutocomplete = (data: any) => {
        onChange(data?.selectedItem)
    }

    return isDropdown ? (
        <Dropdown
            options={options}
            onChange={onChange}
            {...other}
            className={`truncate ${other.className}`}
        />
    ) : (
        <Autocomplete
            options={options}
            onChange={handleOnChangeAutocomplete}
            renderList={groupKey ? group : undefined}
            {...other}
        />
    )
}
