import React, { ChangeEvent, useCallback, useState } from 'react'
import { Checkbox } from '@mondra/ui-components'
import { ICellRendererParams } from 'ag-grid-community'


export function SelectRow(props: ICellRendererParams) {
    const { context = {}, data, node } = props
    const isSelected = node.isSelected()
    const [selected, setSelected] = useState(Boolean(isSelected))
    const { toggleItem } = context
    const { id } = data

    const handleNodeSelect = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target
            node.setSelected(checked)
            setSelected(checked)
            toggleItem && toggleItem(checked, id, data)
        },
        [id, toggleItem]
    )

    return <Checkbox checked={selected} onChange={handleNodeSelect} size={16} />
}
