import React from 'react'
import { formatDistanceToNowStrict, format } from 'date-fns'

import { ICertCardProps } from '../types/types'

import { EcoImpactTable } from '../components/EcoImpactTable'
import { findImpactWithStats } from '../utils/findImpact'
import { formatIsoDate, toDate } from '../utils/dateUtils'
import getDisplayNameOfState from '../utils/getDisplayNameOfState'
import { DataQualityAttributes, DataQualityHeader } from '../widgets/data-quality-score'

import CertHistoryCards from '../product-overiew/CertHistoryCards'
import { ErrorStateIcon } from './CertificationStateIcon'


export default function CertificationExpiredCertCard({ cert }: ICertCardProps) {
    const { ghg, eutro, water, bio } = findImpactWithStats(cert?.ecoImpacts)

    return (
        <CertHistoryCards.Row>
            <CertHistoryCards.CertTimeline
                title={formatDistanceToNowStrict(toDate(cert.updatedAt))}
                dateTime={formatIsoDate(cert.updatedAt)}
                dateTimeDisplay={format(toDate(cert.updatedAt), 'EEEE dd MMMM yyyy')}
                icon={<ErrorStateIcon />}
            />
            <CertHistoryCards.Box>
                <CertHistoryCards.Header
                    title={getDisplayNameOfState(cert.saState)}
                    desc={`Expired on ${format(
                        toDate(cert.certificationEndDate || ''),
                        'dd MMMM yyyy'
                    )}`}
                    isSideLoaded={cert.isSideLoaded}
                />
                <CertHistoryCards.Body>
                    <EcoImpactTable ghg={ghg} eutro={eutro} water={water} bio={bio} />
                    <DataQualityAttributes
                        attributes={cert.dataQualityScore?.attributes}
                        header={<DataQualityHeader score={cert.dataQualityScore?.score} />}
                    />
                </CertHistoryCards.Body>
            </CertHistoryCards.Box>
        </CertHistoryCards.Row>
    )
}
