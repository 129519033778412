import { NodeType } from '../types/types'
import {
    ISCNodeColor,
    SCCertifiedStageColors,
    SupplyChainNodeStageColors,
} from '../types/supply-chain-types'


export interface INodeColorProps {
    bg: string
    border: string
    description?: string
    text: string
    title: string
}

export type INodeColorType = {
    [key in NodeType]: INodeColorProps
}

const commentaryStyles = {
    bg: 'bg-white',
    border: 'border-gray-600',
    description: 'text-gray-500',
    text: 'text-gray-600',
    title: 'text-gray-900',
}

const productNodeColor = {
    bg: 'bg-amber-700',
    border: 'border-amber-700',
    text: 'text-amber-700',
    title: 'text-white',
}

export const nodeColors: INodeColorType = {
    audit: {
        bg: 'bg-white',
        border: 'border-gray-600',
        description: 'text-gray-500',
        text: 'text-gray-600',
        title: 'text-gray-900',
    },
    complexingredient: productNodeColor,
    Hotspot: commentaryStyles,
    ingredient: {
        bg: 'bg-violet-600',
        border: 'border-violet-600',
        text: 'text-violet-600',
        title: 'text-white',
    },
    ingredientTable: {
        bg: 'bg-violet-600',
        border: 'border-violet-600',
        text: 'text-violet-600',
        title: 'text-white',
    },
    Insight: commentaryStyles,
    Note: commentaryStyles,
    notes: commentaryStyles,
    packaging: {
        bg: 'bg-amber-500',
        border: 'border-amber-500',
        text: 'text-amber-500',
        title: 'text-white',
    },
    processing: {
        bg: 'bg-cyan-500',
        border: 'border-cyan-500',
        text: 'text-cyan-500',
        title: 'text-white',
    },
    produce: {
        bg: 'bg-emerald-800',
        border: 'border-emerald-800',
        text: 'text-emerald-800',
        title: 'text-white',
    },
    product: productNodeColor,
    recipe: {
        bg: 'bg-violet-500',
        border: 'border-violet-500',
        text: 'text-violet-500',
        title: 'text-white',
    },
    sale: {
        bg: 'bg-pink-400',
        border: 'border-pink-400',
        text: 'text-pink-400',
        title: 'text-white',
    },
    salesTransport: {
        bg: 'bg-gray-500',
        border: 'border-gray-500',
        text: 'text-gray-800',
        title: 'text-white',
    },
    simpleingredient: productNodeColor,
    site: {
        bg: 'bg-gray-200',
        border: 'border-gray-500',
        text: 'text-gray-500',
        title: 'text-gray-900',
    },
    siteResource: {
        bg: 'bg-gray-200',
        border: 'border-gray-500',
        text: 'text-gray-500',
        title: 'text-gray-900',
    },
    specialingredient: {
        bg: 'bg-fuchsia-700',
        border: 'border-fuchsia-700',
        text: 'text-fuchsia-700',
        title: 'text-white',
    },
    storage: {
        bg: 'bg-red-500',
        border: 'border-red-500',
        text: 'text-red-500',
        title: 'text-white',
    },
    suppliedProduct: {
        bg: 'bg-amber-700',
        border: 'border-amber-700',
        text: 'text-amber-700',
        title: 'text-white',
    },
    supplier: {
        bg: 'bg-blue-800',
        border: 'border-blue-800',
        text: 'text-blue-800',
        title: 'text-white',
    },
    transport: {
        bg: 'bg-gray-500',
        border: 'border-gray-500',
        text: 'text-gray-800',
        title: 'text-white',
    },
}

export const impactBarColors: any = {
    bio: 'bg-eco-chart-bio text-eco-chart-bio',
    carbon: 'bg-eco-chart-carbon text-eco-chart-carbon',
    eutro: 'bg-eco-chart-eutro text-eco-chart-eutro ',
    water: 'bg-eco-chart-water text-eco-chart-water',
}

const productFlashIconColors = {
    bg: 'bg-blue-700',
    hover: 'bg-blue-900',
}

export const flashIconColors: any = {
    complexingredient: productFlashIconColors,
    ingredient: {
        bg: 'bg-violet-500',
        hover: 'bg-violet-700',
    },
    produce: {
        bg: 'bg-emerald-700',
        hover: 'bg-emerald-900',
    },
    product: productFlashIconColors,
    simpleingredient: productFlashIconColors,
    specialingredient: {
        bg: 'bg-fuchsia-500',
        hover: 'bg-fuchsia-900',
    },
    supplier: {
        bg: 'bg-blue-700',
        hover: 'bg-blue-900',
    },
}

export const ScNodeColor: ISCNodeColor = {
    bg: 'bg-white',
    pillBg: 'bg-gray-100',
    pillText: 'text-gray-600',
    subText: 'text-gray-600',
    text: 'text-gray-900',
}

const productCertifiedNodeColor = {
    bg: 'bg-blue-100',
    pillBg: 'bg-blue-200',
    pillText: 'text-blue-600',
    subText: 'text-blue-600',
    text: 'text-blue-800',
}

export const ScCertifiedNodeColors: SCCertifiedStageColors = {
    complexingredient: productCertifiedNodeColor,
    ingredient: {
        bg: 'bg-violet-100',
        pillBg: 'bg-violet-200',
        pillText: 'text-violet-600',
        subText: 'text-violet-600',
        text: 'text-violet-800',
    },
    packaging: {
        bg: 'bg-amber-50',
        pillBg: 'bg-amber-100',
        pillText: 'text-amber-600',
        subText: 'text-amber-600',
        text: 'text-gray-900',
    },
    processing: {
        bg: 'bg-cyan-50',
        pillBg: 'bg-cyan-100',
        pillText: 'text-cyan-600',
        subText: 'text-cyan-600',
        text: 'text-cyan-900',
    },
    produce: {
        bg: 'bg-emerald-50',
        pillBg: 'bg-emerald-100',
        pillText: 'text-emerald-700',
        subText: 'text-emerald-700',
        text: 'text-emerald-900',
    },
    product: productCertifiedNodeColor,
    sale: {
        bg: 'bg-pink-50',
        pillBg: 'bg-pink-100',
        pillText: 'text-pink-500',
        subText: 'text-pink-500',
        text: 'text-pink-800',
    },
    simpleingredient: productCertifiedNodeColor,
    specialingredient: {
        bg: 'bg-fuchsia-50',
        pillBg: 'bg-fuchsia-100',
        pillText: 'text-fuchsia-500',
        subText: 'text-fuchsia-500',
        text: 'text-fuchsia-900',
    },
    storage: {
        bg: 'bg-rose-50',
        pillBg: 'bg-rose-100',
        pillText: 'text-rose-500',
        subText: 'text-rose-500',
        text: 'text-rose-900',
    },
    transport: {
        bg: 'bg-gray-50',
        pillBg: 'bg-gray-100',
        pillText: 'text-gray-500',
        subText: 'text-gray-500',
        text: 'text-gray-900',
    },
}

const productStageColor = {
    bg: 'bg-blue-800',
    border: 'border-blue-800',
    icon: 'text-blue-800',
    lightIcon: 'text-blue-700',
}

export const ScStageWiseColors: SupplyChainNodeStageColors = {
    complexingredient: productStageColor,
    ingredient: {
        bg: 'bg-violet-600',
        border: 'border-violet-600',
        icon: 'text-violet-600',
        lightIcon: 'text-violet-400',
    },
    packaging: {
        bg: 'bg-amber-500',
        border: 'border-amber-500',
        icon: 'text-amber-500',
        lightIcon: 'text-amber-300',
    },
    processing: {
        bg: 'bg-cyan-500',
        border: 'border-cyan-500',
        icon: 'text-cyan-500',
        lightIcon: 'text-cyan-300',
    },
    produce: {
        bg: 'bg-emerald-800',
        border: 'border-emerald-800',
        icon: 'text-emerald-800',
        lightIcon: 'text-emerald-600',
    },
    product: productStageColor,
    sale: {
        bg: 'bg-pink-400',
        border: 'border-pink-400',
        icon: 'text-pink-400',
        lightIcon: 'text-pink-300',
    },
    simpleingredient: productStageColor,
    specialingredient: {
        bg: 'bg-fuchsia-700',
        border: 'border-fuchsia-700',
        icon: 'text-fuchsia-500',
        lightIcon: 'text-fuchsia-300',
    },
    storage: {
        bg: 'bg-rose-500',
        border: 'border-rose-500',
        icon: 'text-rose-500',
        lightIcon: 'text-rose-300',
    },
    transport: {
        bg: 'bg-gray-500',
        border: 'border-gray-500',
        icon: 'text-gray-500',
        lightIcon: 'text-gray-300',
    },
}
