import React, { useState } from 'react'
import { AlertDialog, Button } from '@mondra/ui-components'
import classnames from 'classnames'


interface IErrorDetails {
    errors: string[]
    background?: string
}

export default function ErrorDetails({ errors, background = 'bg-white' }: IErrorDetails) {
    const [open, isOpen] = useState(false)

    function handleClose() {
        isOpen(false)
    }

    function handleCheckDetails() {
        isOpen(true)
    }

    return (
        <div
            className={classnames(
                'h-full w-full flex flex-col justify-center items-center',
                background
            )}
        >
            <div>Cannot calculate impacts</div>
            <Button variant="danger" onClick={handleCheckDetails}>
                Check details
            </Button>
            <AlertDialog
                open={open}
                onClose={handleClose}
                primaryBtnText="Ok"
                title="Impact calculator - failed validation rules"
            >
                <div>
                    <ul>
                        {errors
                            && errors.length > 0
                            && errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                </div>
            </AlertDialog>
        </div>
    )
}
