import isEmpty from 'lodash/fp/isEmpty'
import { ISupplyChainNode, ISupplyChainRow } from '../types/supply-chain-types'


const lineProps = {
    _cpx1Offset: 0,
    _cpx2Offset: 0,
    color: '#627D98',
    endAnchor: 'left',
    extendSVGcanvas: 10,
    headSize: 0,
    lineColor: '#D1D5DB',
    path: 'grid',
    startAnchor: 'right',
    strokeWidth: 2,
}

export function getLines(
    rows: ISupplyChainRow[],
    product: ISupplyChainNode,
    columnWidth = 0,
    columnOffset = 0
) {
    if (isEmpty(rows)) {
        return [
            {
                end: product?.nodeId,
                start: 'no-ingredient',
                ...lineProps,
            },
        ]
    }
    const lines = rows.reduce((acc: any, row: ISupplyChainRow) => {
        const { produce, supplyChain, ingredient } = row

        const rowLines = [
            ...supplyChain
                .map((s: ISupplyChainNode, index: number) => ({
                    ...s,
                    colIndex: index,
                }))
                .filter((s: ISupplyChainNode) => !s.isDummy)
                .map((s: ISupplyChainNode) => ({
                    ...lineProps,
                    companyId: s.companyId,
                    end: s.parentId,
                    endColumn: (s.colIndex || 0) + 3,
                    rootParentId: s.rootParentId,
                    start: s.nodeId,
                    startColumn: (s.colIndex || 0) + 2,
                })),
            {
                ...lineProps,
                end: ingredient.parentId,
                endColumn: supplyChain.length + 3,
                start: ingredient.nodeId,
                startColumn: supplyChain.length + 2,
            },
        ]

        if (!produce.isDummy) {
            return [
                ...acc,
                {
                    ...lineProps,
                    companyId: produce.companyId,
                    end: produce.parentId,
                    endColumn: 2,
                    rootParentId: produce.rootParentId,
                    start: produce.nodeId,
                    startColumn: 1,
                },
                ...rowLines,
            ]
        }

        return [...acc, ...rowLines]
    }, [])

    const filteredLines = lines
        .filter((l: any) => l.end)
        .map((l: any, i: number, arr: any[]) => {
            const endColumn = arr.find((c: any) => c.start === l.end)?.startColumn || l.endColumn
            const gap = (endColumn - l.startColumn - 1) * (columnWidth / 2) + columnOffset / 2

            return {
                ...l,
                _cpx1Offset: gap,
                _cpx2Offset: gap,
                endColumn,
            }
        })
    return filteredLines
}

const summaryLineProps = {
    color: '#627D98',
    endAnchor: 'left',
    extendSVGcanvas: 10,
    headSize: 0,
    lineColor: '#6B7280',
    path: 'grid',
    startAnchor: 'right',
    strokeWidth: 1,
}

export function getSummaryLines() {
    return [
        {
            end: 'supply-chain',
            start: 'produce',
            ...summaryLineProps,
        },
        {
            end: 'ingredient',
            start: 'supply-chain',
            ...summaryLineProps,
        },
        {
            end: 'processing',
            start: 'ingredient',
            ...summaryLineProps,
        },
        {
            end: 'packaging',
            start: 'processing',
            ...summaryLineProps,
        },
        {
            end: 'storage',
            start: 'packaging',
            ...summaryLineProps,
        },
        {
            end: 'sale',
            start: 'storage',
            ...summaryLineProps,
        },
    ]
}
