import map from 'lodash/fp/map'
import isEmpty from 'lodash/fp/isEmpty'
import {
    ActivityStage,
    IActivityRefDataOption,
    IStageActivityResources,
    IStageActivity,
} from '../types/stages'
import { IProduct } from '../types/types'
import { defaultActivityData } from '../slideovers/components/ActivityForm'


interface IParams {
    activity: IStageActivity
    product: IProduct
    selectedActivity?: IActivityRefDataOption
    stage: ActivityStage
}

export const prepareActivityData = ({
    activity,
    product,
    selectedActivity,
    stage,
}: IParams): IStageActivity => {
    if (isEmpty(selectedActivity)) {
        return {
            ...defaultActivityData,
            siteId: product?.site?.id,
            stage,
        }
    }
    return {
        ...activity,
        resources: map((r: IStageActivityResources) => {
            return {
                ...r,
                usagePerKg: r.usagePerKg || Number(Number(r?.usagePerKgByDefault || 0).toFixed(5)),
            }
        }, activity?.resources),
        siteId: product?.site?.id,
        stage,
    }
}
