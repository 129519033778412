import React from 'react'
import { InputAddons } from '@mondra/ui-components'
import { mapWithIndex } from '../../utils/lodash-convert'
import { getProductSuppliedByName } from '../../utils/getProductSupplierName'
import { Row } from '../../components'
import ProductName from '../../components/ProductName'
import { IProduct } from '../../types/types'
import ProductInfoTooltip from './ProductInfoTooltip'


interface IResourcesProductsProps {
    companyId: string
    products: IProduct[]
    updateProducts: React.Dispatch<React.SetStateAction<IProduct[]>>
}

export default function ResourcesProductsTab({
    companyId,
    products,
    updateProducts,
}: IResourcesProductsProps) {
    const handlePercentChange = (index: number) => (event: any) => {
        updateProducts(
            mapWithIndex((p: IProduct, i: number) => {
                if (i === index) {
                    return {
                        ...p,
                        productionInPercent: event.target.value,
                    }
                }
                return p
            })
        )
    }

    return (
        <React.Fragment>
            <Row className="bg-gray-50 text-gray-500 font-normal text-sm">
                The following products are produced at this site. View what resources they consume.
                Optionally you can input percentage of total production to help with validation.
            </Row>

            <Row className="grid-cols-10 bg-gray-100 pt-2 pb-2">
                <div className="text-xs text-gray-500 font-medium col-span-2">Supplier name</div>
                <div className="text-xs text-gray-500 font-medium col-span-3">Product name</div>
                <div className="text-xs text-gray-500 font-medium col-span-2 flex justify-center">
                    Resources used
                </div>
                <div className="text-xs text-gray-500 font-medium col-span-3 flex justify-end">
                    % total production on site
                </div>
            </Row>

            {mapWithIndex(
                (product: IProduct, i: number) => (
                    <Row className="gap-y-4" key={product.id}>
                        <div className="grid items-center gap-x-4 grid-cols-10">
                            <div className="col-span-2 flex flex-row items-center text-sm font-medium text-gray-900">
                                {getProductSuppliedByName(product)}
                            </div>
                            <div className="col-span-3">
                                <ProductName companyId={companyId} product={product} newTab />
                            </div>
                            <div className="col-span-2 flex justify-center">
                                <ProductInfoTooltip product={product} />
                            </div>
                            <div className="col-span-3 flex justify-end">
                                <InputAddons
                                    className="w-24 text-right"
                                    type="number"
                                    rightAddon="%"
                                    value={product.productionInPercent}
                                    onChange={handlePercentChange(i)}
                                />
                            </div>
                        </div>
                    </Row>
                ),
                products
            )}
        </React.Fragment>
    )
}
