import React from 'react'


export function LoadingCell() {
    return (
        <div className="w-full animate-pulse px-4 py-1 space-x-3 flex items-center">
            <div className="w-full h-10 bg-gray-100" />
        </div>
    )
}
