import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'


const Auth0ProviderWithHistory = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN
    const clientId = process.env.REACT_APP_AUTH0_CLIENTID
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE

    const history = useHistory()

    const onRedirectCallback = appState => {
        history.push(appState?.returnTo || window.location.pathname)
    }
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience={audience}
            useRefreshTokens={true}
            redirectUri={`${window.location.origin}/`}
            onRedirectCallback={onRedirectCallback}
            scope="user profile email"
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
