import React from 'react'
import classNames from 'classnames'
import { MAX_DEC } from '../../constants'
import { getItemAppearProps } from '../../utils'
import { DropdownList } from '../../components'


interface IPackagingSetListItem {
    highlightedIndex: number
    option: any
    getItemProps: (props: any) => any
    index: number
    selected: any
}

function PackagingSetListItem({
    highlightedIndex,
    getItemProps,
    index,
    option,
    selected,
}: IPackagingSetListItem) {
    const { isHighlighted } = getItemAppearProps(highlightedIndex, index, option, selected)

    return (
        <li
            className={classNames(
                'cursor-pointer hover:bg-gray-100 px-4 py-2',
                'flex items-center',
                'text-gray-700 text-sm',
                isHighlighted && 'bg-gray-50'
            )}
            {...getItemProps({ index, item: option })}
        >
            <div className={classNames('block truncate w-full')} title={option.label}>
                <div className="flex mb-1">
                    <div className="truncate font-medium">{option.description}</div>
                </div>
                <div className="flex mb-1">
                    <div className="w-1/3 font-medium">Capacity:</div>
                    <div className="w-2/3 pl-4">{option.capacity?.toFixed(MAX_DEC)}kg</div>
                </div>
                <div className="flex mb-1">
                    <div className="w-1/3 font-medium">Empty mass:</div>
                    <div className="w-2/3 pl-4">{option.emptyMass?.toFixed(MAX_DEC)}kg</div>
                </div>
                <div className="flex mb-1">
                    <div className="w-1/3 font-medium">Dimension:</div>
                    <div className="w-2/3 pl-4">{option.dimensions}</div>
                </div>
                <div className="flex mb-1">
                    <div className="w-1/3 font-medium">Region:</div>
                    <div className="w-2/3 pl-4">{option.source}</div>
                </div>
            </div>
        </li>
    )
}

export const PackagingSetList = (props: any) => {
    return (
        <DropdownList
            {...props}
            listItem={(props: any) => <PackagingSetListItem {...props} />}
            groupByField="name"
        />
    )
}
