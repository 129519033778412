import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import isEmpty from 'lodash/fp/isEmpty'
import { Button, Dialog, Icon, Notification } from '@mondra/ui-components'
import useUpdateFormData from '../hooks/useUpdateFormData'
import { useTopCenterToast } from '../hooks/useTopCenterToast'


interface ISideLoadCertificateProps {
    isOpen: boolean
    onClose: () => void
    onSuccess?: (data: any) => void
    successText?: string
    url: string
}

export default function SideLoadCertificate({
    isOpen,
    onClose,
    onSuccess,
    successText = 'Certificate is uploaded successfully',
    url,
}: ISideLoadCertificateProps) {
    const [files, setFiles] = useState([])
    const [errors, setErrors] = useState('')
    const { showSuccess } = useTopCenterToast()

    const { saving, update } = useUpdateFormData({
        contentType: false,
        onError: details => {
            setErrors(details)
        },
        onUpdate: (data: any) => {
            showSuccess({ description: successText })
            setFiles([])
            onSuccess && onSuccess(data)
            onClose()
        },
        url,
    })

    const onUpload = () => {
        const formData = new FormData()
        const fileToUpload = head(files)
        if (files) {
            formData.append('file', fileToUpload as any)
        }
        update(formData)
    }

    const { getRootProps, getInputProps, open: OpenFileDialog, isDragActive } = useDropzone({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFiles: 1,
        noClick: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles as any)
            setErrors('')
        },
    })

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Dialog.Title>Sideload Impacts</Dialog.Title>
            <Dialog.Description>
                <div className="text-center py-6">
                    <div
                        {...getRootProps({
                            className: classNames(
                                'dropzone p-4 border rounded border-gray-500 border-dashed bg-gray-200 outline-none transition border .24s ease-in-out',
                                {
                                    'bg-gray-500': isDragActive,
                                }
                            ),
                        })}
                    >
                        <input {...getInputProps()} />
                        {files.length === 0 ? (
                            <div>
                                Drag and drop one impacts file here to sideload impacts and certify
                                this produce or product
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center">
                                <Icon
                                    type="fileExcel"
                                    size={64}
                                    className={classNames('animate-bounce mt-4 mr-6', {
                                        'text-emerald-600': isEmpty(errors),
                                        'text-red-600': !isEmpty(errors),
                                    })}
                                    aria-hidden="true"
                                />
                                <div className="mt-3">
                                    <span>File: {getOr('', 'name', head(files))}</span>
                                </div>
                            </div>
                        )}
                        <Button className="mt-8" variant="primary" onClick={OpenFileDialog}>
                            Select an impacts file
                        </Button>
                    </div>
                </div>
                {!isEmpty(errors) && (
                    <div className="mt-4 leading-normal text-sm">
                        <Notification
                            type="error"
                            description={`${getOr(
                                '',
                                'name',
                                head(files)
                            )} has following errors. Fix them and try again.`}
                        >
                            <div>
                                <ul className="list-disc m-4 ml-6">
                                    {errors.split('.').map((error: string) => {
                                        return error ? <li>{error}</li> : ''
                                    })}
                                </ul>
                            </div>
                        </Notification>
                    </div>
                )}
            </Dialog.Description>
            <Dialog.Footer>
                <Button
                    variant="white"
                    className="mr-4 ml-auto"
                    onClick={() => {
                        setFiles([])
                        onClose()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    disabled={files.length === 0 || saving || !isEmpty(errors)}
                    onClick={onUpload}
                >
                    Sideload and certify
                </Button>
            </Dialog.Footer>
        </Dialog>
    )
}
