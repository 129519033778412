import React, { useState } from 'react'
import { Button, Dialog, Icon } from '@mondra/ui-components'
import useAuth from '../hooks/useAuth'
import { useTopCenterToast } from '../hooks/useTopCenterToast'
import { AWARDS } from '../constants/toastNotifications'
import { fetchClient } from '../utils/fetchClient'
import { METHOD_DELETE } from '../constants'


export default function DeleteAward({ readOnly, companyId, productId, award, refetch }: any) {
    const [isOpen, setIsOpen] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const { acquireToken } = useAuth()
    const { showSuccess } = useTopCenterToast()

    const onDelete = async() => {
        setDeleting(true)
        fetchClient({
            acquireToken,
            method: METHOD_DELETE,
            onError: () => {
                setDeleting(false)
            },
            onSuccess: () => {
                showSuccess({ description: AWARDS.DELETE })
                setDeleting(false)
                setIsOpen(false)
                refetch()
            },
            url: `/api/company/${companyId}/products/${productId}/awards/${award.id}`,
        })
    }

    return (
        <>
            <div>
                <label className="mb-2 invisible">Cancel</label>
                <Button
                    title="Delete"
                    type="button"
                    variant="ghost"
                    size="md"
                    className="ml-4 flex-shrink-0"
                    onClick={() => !readOnly && setIsOpen(true)}
                    disabled={readOnly}
                >
                    <Icon type="trashCan" />
                </Button>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="max-w-[500px]">
                <Dialog.Title>Delete User</Dialog.Title>
                <Dialog.Footer>
                    <>
                        <Button
                            variant="white"
                            className="mr-4 ml-auto"
                            onClick={() => setIsOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button disabled={deleting} variant="primary" onClick={onDelete}>
                            {deleting ? 'Deleting...' : 'Delete'}
                        </Button>
                    </>
                </Dialog.Footer>
                Are you sure you want to delete an award <b>{award.name}</b>?
            </Dialog>
        </>
    )
}
