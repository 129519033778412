import React from 'react'
import { InputAddons } from '@mondra/ui-components'
import map from 'lodash/fp/map'
import { MAX_DEC } from '../../constants'
import { IStageActivityResources } from '../../types/stages'


interface IActivityResource {
    disabled: boolean | undefined
    resources: IStageActivityResources[]
    type: string
    usageUnitText: string
    handleUsagePerKgChange: (code: string) => (event: any) => void
}

export default function ActivityResource({
    disabled = false,
    handleUsagePerKgChange,
    resources = [],
    type,
    usageUnitText,
}: IActivityResource) {
    return (
        <div>
            <div className="px-4 md:px-6 py-2 grid grid-cols-6 gap-x-4 bg-gray-100 text-xs font-medium text-gray-500">
                <div className="col-span-2">{type}</div>
                <div className="col-span-2 text-center mr-4">Usage {usageUnitText}</div>
                <div className="col-span-2 text-right">Custom usage {usageUnitText}</div>
            </div>
            {map(
                (res: IStageActivityResources) => (
                    <div
                        key={res.id || res.dataPlatformResourceCode}
                        className="px-4 md:px-6 py-2 grid grid-cols-6 items-center gap-x-4 text-sm font-medium text-gray-700"
                    >
                        <div className="col-span-2">{res.resourceName}</div>
                        <div className="col-span-1 text-right truncate">
                            {Number(res?.usagePerKgByDefault || 0).toFixed(MAX_DEC)}
                        </div>
                        <div className="col-span-1 text-left">{res.unit}</div>
                        <div className="col-span-2">
                            <InputAddons
                                type="number"
                                step="any"
                                className="text-right"
                                placeholder={Number(res?.usagePerKgByDefault || 0).toFixed(MAX_DEC)}
                                value={res.usagePerKg}
                                rightAddon={res.unit}
                                disabled={disabled}
                                onChange={handleUsagePerKgChange(res.dataPlatformResourceCode)}
                            />
                        </div>
                    </div>
                ),
                resources
            )}
        </div>
    )
}
