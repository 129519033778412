import React, { ForwardedRef, forwardRef, Fragment, useCallback } from 'react'
import { Input, Label, Textarea } from '@mondra/ui-components'
import { CategoriesRows, FormRow, SupplierAutoComplete } from '../../components'
import SkuIdInput, { ISkuInputRef } from '../../components/SkuIdInput'
import IsRequiredToggle from '../components/IsRequiredToggle'
import {
    ICustomEventProps,
    IProduct,
    ISupplier,
    ITaxonomyCategory,
    IValue,
    SA_TYPE,
} from '../../types/types'
import { YOUR_COMPANY, YES_NO_TOGGLE_LABELS } from '../../constants'
import { SaTypeRadio, SaTypeRadioTypes } from '../../components/SaTypeRadio'
import { isYourCompanySupplier } from '../../utils'
import ProductAutocomplete from './ProductAutocomplete'
import { CategoriesAutocomplete } from './CategoriesAutocomplete'


interface IProductFormProps {
    autoFetch: boolean
    companyId: string
    companyName: string
    disableSaType?: boolean
    editMode?: boolean
    errors?: any
    formId?: string
    includeYourCompany?: boolean
    isSuppliedProduct?: boolean
    onChange: (changes: Record<string, IValue>, reset?: boolean) => void
    onProductNameChange: (changes: ICustomEventProps) => void
    onProductSelect: (selectedItem: any) => void
    onSubmit: (event: React.FormEvent) => void
    product: IProduct
    readOnly: boolean
}

function ProductForm(
    {
        autoFetch,
        companyId,
        companyName,
        disableSaType,
        editMode,
        errors = {},
        formId = 'supplied-product-form',
        includeYourCompany = false,
        product,
        onChange,
        onProductNameChange,
        onSubmit,
        onProductSelect,
        readOnly,
    }: IProductFormProps,
    ref: ForwardedRef<ISkuInputRef>
) {
    const isYourCompany = isYourCompanySupplier(product.supplierId)
    const handleChange = useCallback((event: ICustomEventProps) => {
        const { name, value } = event.target
        onChange({ [name]: value })
    }, [])

    const handleSupplierChange = useCallback(
        (supplier?: ISupplier) => {
            const { supplierId, companyId } = supplier || {}
            onChange(
                {
                    companyId,
                    name: product.name,
                    saType: isYourCompanySupplier(supplierId) ? SA_TYPE.Product : product.saType,
                    supplierId,
                },
                true
            )
        },
        [product.name]
    )

    const handleIsComplete = useCallback(
        (isComplete: boolean) => {
            onChange({ isComplete })
        },
        [onChange]
    )

    const handleSaTypeChange = useCallback(
        (saType: SA_TYPE) => {
            onChange({ saType })
        },
        [onChange]
    )

    const handleTaxonmyChange = useCallback(
        (category?: ITaxonomyCategory) => {
            onChange({ taxonomyCode: category?.code })
        },
        [onChange]
    )

    return (
        <form id={formId} className="pt-2 divide-y divide-gray-200" onSubmit={onSubmit}>
            <FormRow className={!editMode ? 'w-full' : 'grid items-center grid-cols-2 gap-x-4'}>
                {editMode ? (
                    <Fragment>
                        <Label
                            className="col-span-1"
                            helpText="Select supplier if doesn’t exist, create new one"
                        >
                            Supplier name
                        </Label>
                        <div className="col-span-1">
                            <Input
                                name="supplierName"
                                value={product.supplierName || `${YOUR_COMPANY} (${companyName})`}
                                disabled
                            />
                        </div>
                    </Fragment>
                ) : (
                    <SupplierAutoComplete
                        companyId={companyId}
                        companyName={companyName}
                        includeUnknownSupplier
                        includeYourCompany={includeYourCompany}
                        supplierCompanyId={product.companyId}
                        supplierId={product.supplierId}
                        onSelect={handleSupplierChange}
                        invalid={errors.supplier}
                        disabled={editMode}
                    />
                )}
            </FormRow>
            {!isYourCompany && (
                <FormRow className="space-y-4">
                    <SaTypeRadio
                        type={SaTypeRadioTypes.PRODUCT}
                        disabled={disableSaType}
                        error={errors.saType}
                        selected={product.saType}
                        onChange={handleSaTypeChange}
                    />
                </FormRow>
            )}
            <FormRow className={!editMode ? 'w-full' : 'grid items-center grid-cols-2 gap-x-4'}>
                {editMode ? (
                    <Fragment>
                        <Label className="col-span-1" helpText="e.g. Winter Weat" htmlFor="name">
                            Product name
                        </Label>
                        <div className="col-span-1">
                            <Input
                                name="name"
                                value={product.name}
                                invalid={errors.product}
                                onChange={onProductNameChange}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <ProductAutocomplete
                        autoFetch={autoFetch}
                        productId={product.id}
                        saType={product.saType}
                        supplierId={product.supplierId}
                        onChange={onProductNameChange}
                        onSelect={onProductSelect}
                        invalid={errors.product}
                    />
                )}
            </FormRow>
            <FormRow>
                <Label className="col-span-1" helpText="e.g. SKU ID" htmlFor="sku">
                    Product unique id
                </Label>
                <SkuIdInput
                    ref={ref}
                    className="col-span-1"
                    sku={product.sku || ''}
                    companyId={companyId}
                    productId={product.id}
                    onChange={handleChange}
                    id="sku"
                    name="sku"
                    disabled={readOnly}
                />
            </FormRow>
            <FormRow className="flex flex-col">
                <Label
                    containerClass="mb-2"
                    helpText="Write a few sentences about the product"
                    htmlFor="description"
                >
                    Product description
                </Label>
                <Textarea
                    id="description"
                    name="description"
                    value={product.description}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </FormRow>
            {isYourCompany && (
                <FormRow className="flex flex-col">
                    <CategoriesAutocomplete
                        autoFetch={autoFetch}
                        catergoryId={product.companyCategoryId}
                        onChange={handleChange}
                    />
                </FormRow>
            )}
            {!isYourCompany && (
                <CategoriesRows
                    autoFetch={autoFetch}
                    categoryCode={product.taxonomyCode}
                    onSelect={handleTaxonmyChange}
                    saType={product.saType}
                />
            )}
            {editMode && (
                <IsRequiredToggle
                    title="Is Product completed?"
                    description="If the product is all set for certification"
                    disabled={readOnly}
                    helpLabels={YES_NO_TOGGLE_LABELS}
                    isRequired={product.isComplete}
                    onChange={handleIsComplete}
                />
            )}
            <FormRow>
                <div className="col-span-1 flex flex-col">
                    <Label
                        containerClass="mb-2"
                        helpText="Product weight excluding packaging"
                        htmlFor="netWeight"
                    >
                        Weight (net)
                    </Label>
                    <Input
                        type="number"
                        id="netWeight"
                        name="netWeight"
                        min="0"
                        step="any"
                        className="text-right"
                        addonTitle="g"
                        value={product.netWeight}
                        onChange={handleChange}
                        disabled={readOnly}
                    />
                </div>
                <div className="col-span-1 flex flex-col">
                    <Label
                        containerClass="mb-2"
                        helpText="e.g. 50g. This is not mandatory for B2B products"
                        htmlFor="servingSize"
                    >
                        Serving size
                    </Label>
                    <Input
                        type="number"
                        id="servingSize"
                        name="servingSize"
                        min="0"
                        step="any"
                        className="text-right"
                        addonTitle="g"
                        value={product.servingSize}
                        onChange={handleChange}
                        disabled={readOnly}
                    />
                </div>
            </FormRow>
            <FormRow>
                <div className="col-span-1 flex flex-col">
                    <Label containerClass="mb-2" htmlFor="ltmPurchaseQuantity">
                        Annual purchase quantity
                    </Label>
                    <Input
                        type="number"
                        id="ltmPurchaseQuantity"
                        name="ltmPurchaseQuantity"
                        min="0"
                        step="any"
                        className="text-right"
                        addonTitle="kg"
                        value={product.ltmPurchaseQuantity}
                        onChange={handleChange}
                        disabled={readOnly}
                    />
                </div>
                <div className="col-span-1 flex flex-col">
                    <Label containerClass="mb-2" htmlFor="ltmSalesQuantity">
                        LTM sale quantity
                    </Label>
                    <Input
                        type="number"
                        id="ltmSalesQuantity"
                        name="ltmSalesQuantity"
                        min="0"
                        step="any"
                        className="text-right"
                        addonTitle="kg"
                        value={product.ltmSalesQuantity}
                        onChange={handleChange}
                        disabled={readOnly}
                    />
                </div>
            </FormRow>
            <FormRow>
                <Label
                    className="col-span-1"
                    helpText="e.g. Sandwich, half-tray, Serves one person"
                    htmlFor="servingSizeLabel"
                >
                    Serving description
                </Label>
                <Input
                    className="col-span-1"
                    id="servingSizeLabel"
                    name="servingSizeLabel"
                    value={product.servingSizeLabel}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </FormRow>
        </form>
    )
}

export default forwardRef<ISkuInputRef, IProductFormProps>(ProductForm)
