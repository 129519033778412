import { ServerSideStoreType, ValueFormatterParams } from 'ag-grid-community'
import { formatDistance } from 'date-fns'
import { SelectRow } from '../components/Table'
import formatDate from '../utils/dateUtils'


export const SelectRowColDef = {
    cellClass: 'ag-center-cell',
    cellRenderer: SelectRow,
    field: 'nameSelect',
    flex: 0,
    headerClass: 'w-4',
    headerName: '',
    sortable: false,
    width: 48,
}

export const LastUpdatedColDef = {
    field: 'updatedDate',
    headerName: 'Last Updated',
    maxWidth: 200,
    sortable: true,
    tooltipComponent: ({ value }: any) => formatDate(new Date(value)),
    valueFormatter: ({ value }: ValueFormatterParams) => {
        return value ? `${formatDistance(new Date(value), new Date())} ago` : '-'
    },
}

export const serverSideGridProps = {
    key: 'id',
    pagination: true,
    rowSelection: 'multiple',
    serverSideStoreType: 'partial' as ServerSideStoreType,
}
