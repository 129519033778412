import React from 'react'
import { formatDistanceToNowStrict, format } from 'date-fns'
import { ICertCardProps } from '../types/types'
import { EcoImpactTable } from '../components/EcoImpactTable'
import getDisplayNameOfState from '../utils/getDisplayNameOfState'
import { findImpactWithStats } from '../utils/findImpact'
import { formatIsoDate, toDate } from '../utils/dateUtils'
import CertHistoryCards from '../product-overiew/CertHistoryCards'
import { DataQualityAttributes, DataQualityHeader } from '../widgets/data-quality-score'
import CertifiedIcon from './CertificationStateIcon'


export default function CertifiedCertCard({ cert }: ICertCardProps) {
    const { ghg, eutro, water, bio } = findImpactWithStats(cert?.ecoImpacts)
    return (
        <CertHistoryCards.Row>
            <CertHistoryCards.CertTimeline
                title={formatDistanceToNowStrict(toDate(cert?.updatedAt))}
                dateTime={formatIsoDate(cert?.updatedAt)}
                dateTimeDisplay={format(toDate(cert?.updatedAt), 'EEEE dd MMMM yyyy')}
                icon={<CertifiedIcon />}
            />
            <CertHistoryCards.Box>
                <CertHistoryCards.Header
                    bgKey="Certified"
                    title={getDisplayNameOfState(cert.saState)}
                    desc={`Certified until ${format(
                        toDate(cert.certificationEndDate || ''),
                        'dd MMMM yyyy'
                    )}`}
                    isSideLoaded={cert.isSideLoaded}
                />
                <CertHistoryCards.Body>
                    <EcoImpactTable ghg={ghg} eutro={eutro} water={water} bio={bio} />
                    <DataQualityAttributes
                        attributes={cert.dataQualityScore?.attributes}
                        header={<DataQualityHeader score={cert.dataQualityScore?.score} />}
                    />
                </CertHistoryCards.Body>
            </CertHistoryCards.Box>
        </CertHistoryCards.Row>
    )
}
