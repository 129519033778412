import React, { useEffect, useMemo } from 'react'
import { Button, Input, Label, Notification, RadioGroup } from '@mondra/ui-components'
import { Row } from '../../components'
import { IBPaECByProduct, IBPaECConfig } from '../../types/stages'
import { ICustomEventProps, INumber, IProduct } from '../../types/types'
import { MAX_DEC } from '../../constants'
import { evaluateInputValue, hasError, setValidationError } from '../../utils'
import { mapWithIndex } from '../../utils/lodash-convert'
import { round } from '../../utils/numberFormatter'
import ByProducts from './ByProducts'


export enum UnitType {
    PERCENT = '%',
    POUND = '£',
}

const options = [
    {
        description: '',
        label: UnitType.PERCENT,
        value: UnitType.PERCENT,
    },
    {
        description: '',
        label: UnitType.POUND,
        value: UnitType.POUND,
    },
]

export interface IBatchProductError {
    netBatchOutputWeight?: boolean
    percentValuePerKg?: boolean
    priceValuePerKg?: boolean
}

interface IBatchProductTab {
    activityDisabled: boolean
    error: IBatchProductError
    foodLossesPercentage: INumber
    formData: IBPaECConfig
    onChange: (changes: Partial<IBPaECConfig>) => void
    product: IProduct
    setDisableSave: any
    setError: React.Dispatch<React.SetStateAction<IBatchProductError>>
}

export default function BatchProductTab({
    activityDisabled,
    error,
    formData,
    onChange,
    product,
    setDisableSave,
    setError,
}: IBatchProductTab) {
    const totalbyProductWeight = useMemo(() => {
        const total = formData?.bPaecByProducts?.reduce((total, item) => {
            return +item.byProductWeight + total
        }, 0)
        return round(total, MAX_DEC)
    }, [formData.bPaecByProducts])

    const totalValuePerKg = useMemo(() => {
        return formData?.bPaecByProducts?.reduce((total, item) => {
            return +item.byProductPriceValuePerKg * +item.byProductWeight + total
        }, formData?.priceValuePerKg * formData?.netBatchOutputWeight || 0)
    }, [formData])

    const totalPercentValue = useMemo(() => {
        return formData?.bPaecByProducts?.reduce((total, item) => {
            return +(item.byProductPercentValuePerKg || 0) + total
        }, +formData?.percentValuePerKg || 0)
    }, [formData])

    const disabled = activityDisabled || !formData.isEnabled

    useEffect(() => {
        setDisableSave(hasError(error))
    }, [error])

    const onInputChange = (event: ICustomEventProps) => {
        const { name } = event.target
        const value = evaluateInputValue(event, MAX_DEC)
        onChange({
            [name]: value,
        })

        setValidationError(value, name, error, setError)
    }

    const onAddByProduct = () => {
        onChange({
            bPaecByProducts: [
                ...formData.bPaecByProducts,
                {
                    byProductName: `By-Product ${formData.bPaecByProducts.length + 1}`,
                    byProductPercentValuePerKg: 0,
                    byProductPriceValuePerKg: 0,
                    byProductValueInPercentage: 0,
                    byProductWeight: 0,
                    isPriceValueInPercent: formData.isPriceValueInPercent,
                },
            ],
        })
    }

    const handleUnitChange = (selectedUnit: UnitType) => {
        const isPriceValueInPercent = selectedUnit === UnitType.PERCENT
        onChange({
            bPaecByProducts: mapWithIndex((p: IBPaECByProduct) => {
                return {
                    ...p,
                    isPriceValueInPercent,
                }
            }, formData.bPaecByProducts),
            isPriceValueInPercent,
        })
    }

    return (
        <div className="text-sm">
            <Row className="grid-cols-4 border-t border-gray-500">
                <div className="col-span-3">
                    <div className="text-gray-700 font-bold">
                        1. <span className="pl-1">Set batch input vs output</span>
                    </div>
                    <div className="text-gray-500 font-normal pl-4">
                        Start by adding the net batch output weight and its value per kg. Batch
                        input weight is set in the Recipe slideover.
                    </div>
                </div>

                <div className="col-span-1 flex justify-end">
                    <RadioGroup
                        orientation="horizontal"
                        optionContentClassName="px-4 py-2"
                        options={options}
                        disabled={disabled}
                        value={formData.isPriceValueInPercent ? UnitType.PERCENT : UnitType.POUND}
                        onChange={handleUnitChange}
                    />
                </div>
            </Row>

            <div>
                <RowHeader formData={formData} />
                <Row className="grid-cols-12">
                    <Label containerClass="col-span-5">Batch input weight</Label>
                    <div className="col-span-3 text-gray-900 font-normal text-right mr-10">
                        <span>{product?.batchInputWeight}</span>
                        <span className="text-gray-500 pl-2">kg</span>
                    </div>
                </Row>
                <Row className="grid-cols-12">
                    <Label containerClass="col-span-5">Net batch output weight</Label>
                    <div className="col-span-3">
                        <Input
                            className="w-11/12 text-right"
                            name="netBatchOutputWeight"
                            addonTitle="kg"
                            type="number"
                            invalid={error.netBatchOutputWeight}
                            disabled={disabled}
                            value={formData.netBatchOutputWeight}
                            onChange={onInputChange}
                        />
                    </div>
                    <div className={formData.isPriceValueInPercent ? 'col-span-1' : 'col-span-2'}>
                        {!formData.isPriceValueInPercent && (
                            <Input
                                name="priceValuePerKg"
                                className="w-11/12 text-right"
                                addonTitle="£"
                                type="number"
                                invalid={error.priceValuePerKg}
                                disabled={disabled}
                                value={formData.priceValuePerKg}
                                onChange={onInputChange}
                            />
                        )}
                    </div>
                    <div
                        className={`${
                            formData.isPriceValueInPercent ? 'col-span-3' : 'col-span-2'
                        } flex flex-row items-center justify-end`}
                    >
                        {formData.isPriceValueInPercent ? (
                            <Input
                                name="percentValuePerKg"
                                className="w-11/12 text-right"
                                addonTitle="%"
                                type="number"
                                disabled={disabled}
                                invalid={
                                    error.percentValuePerKg
                                    || formData.percentValuePerKg < 0
                                    || formData.percentValuePerKg > 100
                                }
                                value={formData.percentValuePerKg}
                                onChange={onInputChange}
                            />
                        ) : (
                            <div>
                                {round(
                                    ((formData?.priceValuePerKg * formData?.netBatchOutputWeight)
                                        / totalValuePerKg)
                                        * 100,
                                    1
                                ) || 100}
                                %
                            </div>
                        )}
                        <div className="w-6 h-6 ml-4" />
                    </div>

                    {!formData.isPriceValueInPercent && formData.priceValuePerKg <= 0 && (
                        <div className="col-span-full mt-2">
                            <Notification
                                type="warning"
                                description="Value per kg is set to £ zero, presumed donated. If waste, set under
                                activity."
                            />
                        </div>
                    )}
                </Row>
            </div>

            <Row className="border-b border-gray-300">
                <div className="text-gray-700 font-bold">
                    2. <span className="pl-1">Configure by-products</span>
                </div>
                <div className="text-gray-500 font-normal pl-4">
                    Add values for by-products. Must not exceed the total for other losses / outputs
                    above.
                </div>
            </Row>

            <Row className="grid-cols-2 border-b border-gray-300">
                <Label helpText="Add by-product name, weight and value per kg">
                    Valued by-products
                </Label>
                <div className="flex justify-end items-center gap-x-2">
                    Add a by-product
                    <Button
                        isOnlyIcon
                        variant="primary"
                        iconType="add"
                        disabled={disabled}
                        onClick={onAddByProduct}
                    />
                </div>
            </Row>

            {formData.bPaecByProducts && formData.bPaecByProducts.length > 0 && (
                <div>
                    <RowHeader formData={formData} title="By-products" />
                    <ByProducts
                        disabled={disabled}
                        bPaecByProducts={formData.bPaecByProducts}
                        onChange={onChange}
                        error={error}
                        setError={setError}
                        totalValuePerKg={totalValuePerKg}
                    />
                </div>
            )}

            <Row className="grid-cols-12 bg-gray-100 border-t border-gray-500">
                <Label className="text-gray-900 font-bold" containerClass="col-span-5">
                    Total of by-product
                </Label>
                <div className="col-span-3 text-gray-900 font-normal text-right mr-10">
                    <span className="text-gray-900 font-bold">{totalbyProductWeight}</span>
                    <span className="text-gray-700 pl-2">kg</span>
                </div>
                {formData.isPriceValueInPercent && (
                    <div className="col-span-2 text-right mr-6 font-bold">{totalPercentValue}%</div>
                )}
            </Row>
        </div>
    )
}

function RowHeader({ formData, title }: any) {
    return (
        <div className="grid grid-cols-12 px-4 md:px-6 py-2 gap-x-4 bg-gray-50 text-xs font-medium text-gray-500 border-t border-gray-300">
            <div className="col-span-5">{title}</div>
            <div className="col-span-3 text-right pr-16">Weight</div>
            <div className="col-span-2 text-left">
                {formData.isPriceValueInPercent ? '' : 'Value per kg'}
            </div>
            <div className="col-span-2 text-right mr-9">Value %</div>
        </div>
    )
}
