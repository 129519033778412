import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import isEmpty from 'lodash/fp/isEmpty'
import { Button, FormDialog, Icon, Notification } from '@mondra/ui-components'
import { MenuItem } from '../../components'

import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useControls } from '../../hooks/useControls'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { IClassNameProps } from '../../types/types'


interface IUploadProductTreeProps extends IClassNameProps {
    url: string
    successText?: string
    refresh?: (data: any) => void
}

export function UploadProductTree({
    url,
    successText = 'Product & its recipe is uploaded successfully',
    refresh,
}: IUploadProductTreeProps) {
    const { close, isOpened, open } = useControls()
    const [files, setFiles] = useState([])
    const [errors, setErrors] = useState('')
    const { showSuccess } = useTopCenterToast()

    const onUpdate = useCallback((data: any) => {
        showSuccess({ description: successText })
        setFiles([])
        refresh && refresh(data)
        close
    }, [])

    const onError = useCallback((details: any) => {
        setErrors(details)
    }, [])

    const { saving, update } = useUpdateFormData({
        contentType: false,
        onError,
        onUpdate,
        url,
    })

    const onUpload = () => {
        const formData = new FormData()
        const fileToUpload = head(files)
        if (files) {
            formData.append('file', fileToUpload as any)
        }
        update(formData)
    }

    const SideLoad = () => {
        const { getRootProps, getInputProps, open: OpenFileDialog, isDragActive } = useDropzone({
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            maxFiles: 1,
            noClick: true,
            onDrop: acceptedFiles => {
                setFiles(acceptedFiles as any)
                setErrors('')
            },
        })

        return (
            <FormDialog
                formId="upload-product-tree"
                title="Upload product & its recipe"
                open={isOpened}
                onClose={close}
                primaryBtnDisabled={files.length === 0 || saving || !isEmpty(errors)}
                primaryBtnText={saving ? 'Uploading..' : 'Upload'}
            >
                <form id="upload-product-tree" onSubmit={onUpload}>
                    <div className="text-center py-6">
                        <div
                            {...getRootProps({
                                className: classNames(
                                    'dropzone p-4 border rounded border-gray-500 border-dashed bg-gray-200 outline-none transition border .24s ease-in-out',
                                    {
                                        'bg-gray-500': isDragActive,
                                    }
                                ),
                            })}
                        >
                            <input {...getInputProps()} />
                            {files.length === 0 ? (
                                <div>
                                    Drag &lsquo;n&rsquo; drop product recipe file here, only one
                                    excel file is accepted
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center">
                                    <Icon
                                        type="fileExcel"
                                        size={64}
                                        className={classNames('animate-bounce mt-4 mr-6', {
                                            'text-emerald-600': isEmpty(errors),
                                            'text-red-600': !isEmpty(errors),
                                        })}
                                        aria-hidden="true"
                                    />
                                    <div className="mt-3">
                                        <span>File: {getOr('', 'name', head(files))}</span>
                                    </div>
                                </div>
                            )}
                            <Button className="mt-8" variant="primary" onClick={OpenFileDialog}>
                                Select a product recipe file
                            </Button>
                        </div>
                    </div>
                    {!isEmpty(errors) && (
                        <div className="mt-4 leading-normal text-sm">
                            <Notification
                                type="error"
                                description={`${getOr(
                                    '',
                                    'name',
                                    head(files)
                                )} has following errors. Fix them and try again.`}
                            >
                                <div>
                                    <ul className="list-disc m-4 ml-6">
                                        {errors.split('.').map((error: string) => {
                                            return error ? <li>{error}</li> : ''
                                        })}
                                    </ul>
                                </div>
                            </Notification>
                        </div>
                    )}
                </form>
            </FormDialog>
        )
    }

    return (
        <>
            <MenuItem onSelect={open}>Upload product</MenuItem>
            <SideLoad />
        </>
    )
}
