import { mapWithIndex } from './lodash-convert'


export function getMappedInsights(insights: any[], title = 'Interesting fact') {
    return mapWithIndex(
        ({ title: insightTitle, description }: any, index: any) => ({
            description,
            title: insightTitle || `${title} ${index + 1}`,
        }),
        insights || []
    )
}
