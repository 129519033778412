import React, { useCallback, useState } from 'react'
import { ConfirmDialog } from '@mondra/ui-components'

import { MenuItem } from '../../components'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import getURL from '../../utils/getURL'
import { URLs } from '../../constants'
import { EXPORT_DATA } from '../../constants/toastNotifications'
import { ICompany } from '../../types/legal-entity'


interface IExportISData {
    company: ICompany
}

export function ExportISData({ company }: IExportISData) {
    const { showSuccess } = useTopCenterToast()
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const onError = useCallback(() => {
        handleClose()
    }, [])
    const handleClose = useCallback(() => {
        setIsOpenConfirm(false)
    }, [])
    const onUpdate = useCallback(() => {
        showSuccess({ description: EXPORT_DATA })
        handleClose()
    }, [])

    const { saving, update } = useUpdateFormData({
        contentType: true,
        onError,
        onUpdate,
        url: getURL(URLs.Company.SYNC_IS_DATA, { companyId: company?.id }),
    })
    const handleConfirmOpen = useCallback(() => {
        setIsOpenConfirm(true)
    }, [])
    const handleConfirm = useCallback(() => {
        update({})
        setIsOpenConfirm(true)
    }, [company])

    return (
        <>
            <MenuItem onSelect={handleConfirmOpen}>Export data to Insights</MenuItem>
            <ConfirmDialog
                open={isOpenConfirm}
                onConfirm={handleConfirm}
                onClose={handleClose}
                primaryBtnText={saving ? 'Exporting' : 'Export'}
                title="Export data to Insights"
                variant="primary"
            >
                Are you sure you want to export data to Insights?
            </ConfirmDialog>
        </>
    )
}
