import React, { useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { ISite, NodeType } from '../../types/types'
import { ISlideoverProps } from '../../types/slideover-types'
import { SAVE } from '../../constants/toastNotifications'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import Slideover from '../components/Slideover'
import SiteResourcesForm from './SiteResourcesForm'


export interface IResourcesSlideoverProps extends Omit<ISlideoverProps, 'type'> {
    site?: ISite
}

export interface ISlideoverFormProps {
    companyId: string
    formId: string
    onClose: () => void
    onFinished?: () => void
    saving?: boolean
    setDisableSave: React.Dispatch<React.SetStateAction<boolean>>
    setSaving: (is: boolean) => void
    site?: ISite
}

const type = NodeType.SITE_RESOURCE

const formId = `${type}-form`

export default function SiteResourcesSlideover({
    isOpen,
    level,
    onClose,
    onSaved,
    site,
    wider,
    ...rest
}: IResourcesSlideoverProps) {
    const [saving, setSaving] = useState(false)
    const [disableSave, setDisableSave] = useState(false)
    const { showSuccess } = useTopCenterToast()

    const handleClose = () => {
        setSaving(false)
        onClose(type)
    }

    const onFinished = () => {
        showSuccess({ description: SAVE.SUCCESS })
        handleClose()
        onSaved && onSaved()
    }

    const formProps: ISlideoverFormProps = {
        formId,
        onClose: handleClose,
        onFinished,
        saving,
        setDisableSave,
        setSaving,
        site,
        ...rest,
    }

    return (
        <Slideover
            wider={wider}
            isOpen={isOpen}
            formId={formId}
            level={level}
            title={
                isEmpty(site?.name) ? 'Site not available' : `${site?.name} resources and products`
            }
            type={type}
            saving={saving}
            disableSave={disableSave}
            onClose={handleClose}
        >
            <SiteResourcesForm {...formProps} />
        </Slideover>
    )
}
