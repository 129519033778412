import React, { useMemo, useEffect } from 'react'

import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import { Autocomplete } from '@mondra/ui-components'

import useSuppliers from '../hooks/useSuppliers'
import { IString, ISupplier } from '../types/types'
import { isUnknownSupplier } from '../utils'
import { DEFAULT_SUPPLIER_ID } from '../constants'
import QuickAddSupplier from './QuickAddSupplier'


const labelProps = {
    containerClass: 'col-span-1',
    helpText: 'Select supplier if doesn’t exist, create new one',
}

interface ISupplierAutoCompleteProps {
    companyId: string
    companyName?: string
    disabled?: boolean
    includeUnknownSupplier?: boolean
    includeYourCompany?: boolean
    onSelect: (selectedItem?: any) => void
    supplierCompanyId: string
    supplierId: IString
    invalid?: boolean
}

export default function SupplierAutoComplete({
    companyId,
    companyName,
    disabled,
    includeUnknownSupplier = true,
    includeYourCompany = false,
    supplierCompanyId,
    supplierId,
    onSelect,
    invalid,
    ...rest
}: ISupplierAutoCompleteProps) {
    const { suppliers, suppliersLoading, refetchSuppliers } = useSuppliers({
        autoFetch: true,
        companyId,
        companyName,
        includeUnknownSupplier,
        includeYourCompany,
    })

    const supplierOptions: ISupplier[] = useMemo(() => {
        return map(
            (sup: ISupplier) => ({
                ...sup,
                id: sup.supplierId,
                label: sup.name,
            }),
            suppliers
        )
    }, [suppliers])

    const selected: ISupplier | undefined = useMemo(() => {
        if (supplierId === DEFAULT_SUPPLIER_ID && !includeYourCompany) {
            return find(isUnknownSupplier, supplierOptions)
        }
        return find(
            i => i.supplierId === supplierId && i.companyId === supplierCompanyId,
            supplierOptions
        )
    }, [supplierId, supplierOptions])

    useEffect(() => {
        if (
            !isEmpty(selected)
            && (supplierId !== selected?.supplierId || supplierCompanyId !== selected?.companyId)
        ) {
            onSelect({ ...selected })
        }
    }, [selected])

    const handleSelected = (changes: any) => {
        const { selectedItem } = changes
        onSelect({ ...selectedItem })
    }

    return (
        <div>
            <Autocomplete
                containerClass="grid grid-cols-2 gap-x-4 z-50"
                placeholder={
                    suppliersLoading ? 'Loading suppliers...' : 'Search with supplier name'
                }
                labelText="Supplier name"
                labelProps={labelProps}
                onChange={handleSelected}
                options={supplierOptions as any}
                showAvatar
                optionsContainerClass="pb-0"
                selected={selected as any}
                invalid={invalid}
                renderAddArea={({ options, searchText }: any) => (
                    <QuickAddSupplier
                        companyId={companyId}
                        name={searchText}
                        options={options}
                        refetch={refetchSuppliers}
                        onAdd={(supplier: ISupplier) => {
                            onSelect(supplier)
                        }}
                    />
                )}
                {...rest}
            />
        </div>
    )
}
