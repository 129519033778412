import React, { useMemo, useState } from 'react'
import { Button, Checkbox, Dialog, Icon } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import isFunction from 'lodash/fp/isFunction'
import some from 'lodash/fp/some'
import { mapWithIndex } from '../../utils/lodash-convert'
import { IPackagingLeg } from '../../types/stages'


interface IOptionsChangeCallback {
    distributions: IPackagingLeg[]
    primary: IPackagingLeg
}

interface IComponentProps {
    disabled?: boolean
    distributionPackaging: IPackagingLeg[]
    primaryPackaging: IPackagingLeg
    onOptionsChange?: (data: IOptionsChangeCallback) => void
}

export default function DistributorPackagingOptions({
    disabled,
    distributionPackaging,
    primaryPackaging,
    onOptionsChange,
}: IComponentProps) {
    const [openDistOptions, setOpenDistOptions] = useState<boolean>(false)
    const [primaryZeroCheck, setPrimaryZeroCheck] = useState<boolean>(false)
    const [distZerocheck, setDistZeroCheck] = useState<boolean[]>([])

    const isSet = useMemo(() => {
        return (
            primaryPackaging?.isZeroImpact
            || some((t: IPackagingLeg) => t.isZeroImpact, distributionPackaging)
        )
    }, [primaryPackaging?.isZeroImpact, distributionPackaging])

    const toggleDistOptionDialog = () => {
        if (!openDistOptions) {
            setPrimaryZeroCheck(primaryPackaging?.isZeroImpact || false)
            setDistZeroCheck(
                mapWithIndex((i: IPackagingLeg) => i.isZeroImpact || false, distributionPackaging)
            )
        }
        setOpenDistOptions(!openDistOptions)
    }

    const handleDistCheckChange = (index: number) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const checked = event.target.checked
        setDistZeroCheck(
            mapWithIndex((i: IPackagingLeg, idx: number) => {
                if (idx === index) {
                    return checked
                }
                return i
            })
        )
    }

    const handlePrimaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked
        setPrimaryZeroCheck(checked)
    }

    const handleSave = () => {
        if (isFunction(onOptionsChange)) {
            onOptionsChange({
                distributions: mapWithIndex((item: IPackagingLeg, idx: number) => {
                    return {
                        ...item,
                        isZeroImpact: distZerocheck[idx],
                    }
                }, distributionPackaging),
                primary: {
                    ...primaryPackaging,
                    isZeroImpact: primaryZeroCheck,
                },
            })
        }
        setOpenDistOptions(false)
    }

    return (
        <div className="flex flex-row items-center justify-start gap-2">
            <Button variant="ghost" disabled={disabled} onClick={toggleDistOptionDialog}>
                Distributor Packaging options
            </Button>
            {!disabled && !isSet && (
                <div className="bg-amber-50 text-amber-800 text-xs font-medium rounded-full px-2 py-1 flex flex-row items-center gap-1">
                    <span>Not Set</span>
                    <Icon type="information" size={16} className="text-amber-500" />
                </div>
            )}

            <Dialog
                open={openDistOptions}
                hideCloseBtn
                disableOverlayClose
                onClose={toggleDistOptionDialog}
            >
                <Dialog.Title>Distributor Packaging options</Dialog.Title>
                <Dialog.Description>
                    <p className="text-gray-500">
                        These options to zero the impacts of any re-used packaging pieces. The mass
                        of the pieces will be maintained.
                    </p>
                    <div className="mt-4">
                        <div className="py-2 grid grid-cols-8 items-center border-b border-gray-300">
                            <div className="text-sm text-gray-700 font-medium col-span-6">
                                Primary Packaging
                            </div>
                            <div className="text-sm text-gray-700 font-medium col-span-2 text-right">
                                Zero the impacts
                            </div>
                        </div>
                        {!isEmpty(primaryPackaging) && (
                            <div className="p-2 grid grid-cols-8 items-center border-b border-gray-300">
                                <div className="text-xs text-gray-500 font-normal col-span-6">
                                    {primaryPackaging?.selected?.label}
                                </div>
                                <div className="flex justify-center col-span-2">
                                    <Checkbox
                                        checked={primaryZeroCheck}
                                        onChange={handlePrimaryChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {!isEmpty(distributionPackaging) && (
                        <div className="mt-2 mb-8">
                            <div className="py-2 grid grid-cols-8 items-center border-b border-gray-300">
                                <div className="text-sm text-gray-700 font-medium col-span-6">
                                    Distribution Packaging
                                </div>
                                <div className="col-span-2" />
                            </div>
                            {mapWithIndex((item: IPackagingLeg, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className={`p-2 grid grid-cols-8 items-center border-b border-gray-300 ${
                                            isEmpty(item.selected) || item.isArchived
                                                ? 'hidden'
                                                : ''
                                        }`}
                                    >
                                        <div className="text-xs text-gray-500 font-normal col-span-6">
                                            {item.selected?.label}
                                        </div>
                                        <div className="flex justify-center col-span-2">
                                            <Checkbox
                                                checked={distZerocheck[index]}
                                                onChange={handleDistCheckChange(index)}
                                            />
                                        </div>
                                    </div>
                                )
                            }, distributionPackaging)}
                        </div>
                    )}
                </Dialog.Description>
                <Dialog.Footer>
                    <Button variant="white" onClick={toggleDistOptionDialog}>
                        Cancel
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                        Save &amp; Close
                    </Button>
                </Dialog.Footer>
            </Dialog>
        </div>
    )
}
