import React, { createContext, Dispatch, SetStateAction, useState } from 'react'
import noop from 'lodash/fp/noop'
import { IChildrenProps } from '../types/types'


interface ILockActivityContext {
    disabled: boolean
    isLocked: boolean
    isLoading: boolean
    setDisabled: Dispatch<SetStateAction<boolean>>
    setIsLocked: Dispatch<SetStateAction<boolean>>
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setIsSaving: Dispatch<SetStateAction<boolean>>
    isSaving: boolean
}

export const LockActivityContext = createContext<ILockActivityContext>({
    disabled: false,
    isLoading: true,
    isLocked: false,
    isSaving: false,
    setDisabled: noop,
    setIsLoading: noop,
    setIsLocked: noop,
    setIsSaving: noop,
})

export function LockActivityContextProvider({ children }: IChildrenProps) {
    const [isLocked, setIsLocked] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    return (
        <LockActivityContext.Provider
            value={{
                disabled,
                isLoading,
                isLocked,
                isSaving,
                setDisabled,
                setIsLoading,
                setIsLocked,
                setIsSaving,
            }}
        >
            {children}
        </LockActivityContext.Provider>
    )
}
