export const impactStages = [
    {
        description: 'Seeds, fertiliser and water, we measure everything at the Farm.',
        iconType: 'ingredients',
        stage: 'farming',
        title: 'Farming',
    },
    {
        description:
            'From washing to chopping to baking, we measure the impact at every stage of processing.',
        iconType: 'processing',
        stage: 'processing',
        title: 'Processing',
    },
    {
        description: 'By land, sea or air. Frozen, chilled or ambient. We measure all the way.',
        iconType: 'deliveryTruck',
        stage: 'transport',
        title: 'Transport',
    },
    {
        description:
            'Not just the packet in your hand, but every barrel, sack and pallet used in the supply chain.',
        iconType: 'packaging',
        stage: 'packaging',
        title: 'Packaging',
    },
    {
        description:
            'Whether sat at the distribution centre, or on the supermarket shelf, it takes energy to store this food.',
        iconType: 'storage',
        stage: 'storage',
        title: 'Storage',
    },
    {
        description:
            'Whether sat at the distribution centre, or on the supermarket shelf, it takes energy to sell you this food.',
        iconType: 'sale',
        stage: 'sale',
        title: 'Sale',
    },
]
