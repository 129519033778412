import React, { useCallback, useContext, useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import qs from 'qs'
import { isEmpty } from 'lodash'
import useAuth from '../hooks/useAuth'
import { isMondraAdmin } from '../hooks/userRoles'
import { UserContext } from '../contexts/UserProvider'
import getURL from '../utils/getURL'
import { URLs } from '../constants'


export interface IMessage {
    createdAt: string
    notificationId: string
    notificationSubType: string
    notificationType: string
    longDesc: string
    isHistory: boolean
    shortDesc: string
    supplierName: string
    title: string
    url: string
}

interface IPageDetails {
    totalCount: number
    pageNumber: number
    pageSize: number
    totalPages: number
}

interface INotification {
    messages: any
    pageDetails: IPageDetails
    loadMore: (x: number) => void
}

interface INotificationProps {
    token: string
    isAdmin: boolean
}

const defaultPageDetails = {
    pageNumber: 1,
    pageSize: 0,
    totalCount: 0,
    totalPages: 1,
}

export const NotificationContext = React.createContext<INotification>({
    loadMore: () => null,
    messages: [],
    pageDetails: defaultPageDetails,
})

export const Notification: React.FC<INotificationProps> = props => {
    const PAGE_SIZE = 5
    const [, setConnection] = useState<signalR.HubConnection>()
    const [messages, setMessages] = useState<any[]>([])
    const [pageDetails, setPageDetails] = useState<IPageDetails>(defaultPageDetails)
    const signalRUrl = `${process.env.REACT_APP_API_URL}/hubs/messaging` || ''
    const auth = useAuth()

    const onMessageReceived = useCallback((notification: any) => {
        const message = parseNotification(notification)
        addLatestMessage(message)
    }, [])

    const parseNotification = ({ notificationId, payload }: any) => {
        const {
            content,
            longDescription: longDesc,
            shortDescription: shortDesc,
            state: notificationSubType,
            subType: notificationType,
            timestamp: createdAt,
            title,
            url,
        } = payload

        const { SupplierName: supplierName } = validateContent(content)

        return {
            createdAt,
            isHistory: false,
            longDesc,
            notificationId,
            notificationSubType,
            notificationType,
            shortDesc,
            supplierName,
            title,
            url: isEmpty(url) ? '/' : url,
        }
    }

    function validateContent(str: string) {
        try {
            return JSON.parse(str)
        } catch (e) {
            return {
                SupplierName: '',
            }
        }
    }

    useEffect(() => {
        connectToService()
    }, [onMessageReceived])

    const connectToService = async() => {
        const token = auth.acquireToken()
        const _connection = new signalR.HubConnectionBuilder()
            .withUrl(signalRUrl, { accessTokenFactory: () => token })
            .withAutomaticReconnect()
            .build()
        _connection.on('NotificationCreated', onMessageReceived)
        _connection
            .start()
            .then(() => setConnection(_connection))
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        loadMore()
    }, [])

    const loadMore = (page = 1) => {
        const queryObj: any = {
            PageNumber: page,
            PageSize: PAGE_SIZE,
        }

        const queryString = qs.stringify(queryObj, {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
        })
        fetchNotificationHistory(queryString)
    }

    const fetchNotificationHistory = async(queryString = '') => {
        const url = getURL(URLs.Notification.MY, undefined, queryString)
        const token = await auth.acquireToken()

        fetch(`${process.env.REACT_APP_API_URL}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-MONDRA-APP': 'CP',
            },
            method: 'GET',
        })
            .then(rsp => {
                if (rsp.ok) {
                    return rsp.json()
                }
                console.log('HTTP ERROR ', rsp.status)
            })
            .then(response => {
                if (!isEmpty(response)) {
                    addHistoricalMessage(response)
                }
            })
    }

    const addHistoricalMessage = (response: any) => {
        const { data, ...rest } = response
        data?.map((msg: any) => {
            const parsedMsg = parseNotification(msg)
            if (!isEmpty(parsedMsg.notificationId)) {
                const exist = messages.find(
                    (m: any) => m.notificationId === parsedMsg.notificationId
                )
                if (!exist) {
                    setMessages(all => [...all, { ...parsedMsg, isHistory: true }])
                }
            }
        })
        setPageDetails({ ...rest })
    }

    const addLatestMessage = (message: IMessage) => {
        setMessages(all => [...all, message])
    }
    return (
        <NotificationContext.Provider
            value={{
                loadMore: loadMore,
                messages: messages,
                pageDetails: pageDetails,
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    )
}

export default function AppProvider(props: any) {
    const { currentUser } = useContext(UserContext)
    const isAdmin = isMondraAdmin(currentUser)

    return <Notification isAdmin={isAdmin} {...props} />
}
