import React from 'react'
import isFunction from 'lodash/fp/isFunction'

import { Button } from '@mondra/ui-components'


export default function SlideoverAlert({ title, description, onCancel, onConfirm }: any) {
    return (
        <div className="rounded-md bg-amber-50 p-4 mb-4 flex" aria-label="Product changes alert">
            <div className="flex-grow">
                <h4 className="text-sm font-medium text-amber-800">{title}</h4>
                <p className="mt-2 text-sm text-amber-700">{description}</p>
            </div>

            <div className="flex-shrink-0 flex items-end justify-end space-x-4">
                {isFunction(onCancel) && (
                    <Button variant="white" onClick={onCancel}>
                        Cancel
                    </Button>
                )}
                {isFunction(onConfirm) && (
                    <Button variant="secondary" onClick={onConfirm}>
                        Confirm
                    </Button>
                )}
            </div>
        </div>
    )
}
