import React from 'react'
import { Input } from '@mondra/ui-components'
import { filterWithIndex, mapWithIndex } from '../../utils/lodash-convert'
import { Row } from '../../components'
import { ISiteResource } from '../../types/stages'
import { ISite } from '../../types/types'
import { evaluateInputValue, setValidationError } from '../../utils'
import ResourcesList from './ResourcesList'
import { IResourceGroup, ISiteResourceData } from './SiteResourcesForm'


export interface IResourceActivityError {
    totalProductsProductionInKg?: boolean
}

interface IResourcesActivityProps {
    companyId: string
    disabled?: boolean
    error: IResourceActivityError
    setError: React.Dispatch<React.SetStateAction<IResourceActivityError>>
    setSiteResources: React.Dispatch<React.SetStateAction<ISiteResourceData>>
    setSiteResourcesGroup: React.Dispatch<React.SetStateAction<IResourceGroup>>
    site?: ISite
    siteResources: ISiteResourceData
    siteResourcesGroup: IResourceGroup
}

export default function ResourcesActivityTab({
    companyId,
    disabled,
    error,
    setError,
    setSiteResources,
    setSiteResourcesGroup,
    site,
    siteResources,
    siteResourcesGroup,
}: IResourcesActivityProps) {
    const handleTotalProductionChange = (event: any) => {
        const value = evaluateInputValue(event)
        setSiteResources(r => ({ ...r, totalProductsProductionInKg: value }))

        setValidationError(value, 'totalProductsProductionInKg', error, setError)
    }

    const addResourceSource = (groupId: string, source: ISiteResource) => {
        setSiteResourcesGroup((rg: IResourceGroup) => ({
            ...rg,
            [groupId]: [...rg[groupId], source],
        }))
    }

    const removeresourceSource = (groupId: string, index: number) => {
        setSiteResourcesGroup((rg: IResourceGroup) => ({
            ...rg,
            [groupId]: filterWithIndex((_: any, i: number) => i !== index, rg[groupId]),
        }))
    }

    const handleResourceChange = (groupId: string, index: number, change: any) => {
        setSiteResourcesGroup((rg: IResourceGroup) => ({
            ...rg,
            [groupId]: mapWithIndex((r: ISiteResource, i: number) => {
                if (i === index) {
                    return {
                        ...r,
                        ...change,
                    }
                }
                return r
            }, rg[groupId]),
        }))
    }

    const handleResourceUnitChange = (groupId: string, isUsageInPercent: boolean) => {
        setSiteResourcesGroup((rg: IResourceGroup) => ({
            ...rg,
            [groupId]: mapWithIndex((r: ISiteResource) => {
                return {
                    ...r,
                    isUsageInPercent,
                }
            }, rg[groupId]),
        }))
    }

    return (
        <React.Fragment>
            <Row className="bg-gray-50 text-gray-500 font-normal text-sm">
                As you add activities under Processing and Storage, required resources are added
                here. Add custom resource sourcing where required. Add total site usage figures here
                to help validation.
            </Row>

            <Row className="mb-2">
                <div className="text-gray-700 text-sm font-medium">
                    Total production volume at site
                </div>
                <div className="text-gray-500 text-xs font-normal">
                    Provide total production volume in kg to activate comparison features:
                </div>
                <div className="mt-4 flex flex-row gap-x-4 items-center">
                    <Input
                        className="w-36 text-right"
                        type="number"
                        addonTitle="kg"
                        disabled={disabled}
                        invalid={error?.totalProductsProductionInKg}
                        value={siteResources.totalProductsProductionInKg}
                        onChange={handleTotalProductionChange}
                    />
                    <div className="col-span-2 text-sm text-gray-500">
                        kg of total product production at this site
                    </div>
                </div>
            </Row>

            <Row className="bg-gray-50">
                <div className="text-lg text-gray-900 font-medium">Add resources</div>
                <div className="text-sm text-gray-500 font-normal">
                    Optionally set custom sourcing and total usage figures:
                </div>
            </Row>

            {mapWithIndex(
                (r: ISiteResource[], key: string) => (
                    <ResourcesList
                        companyId={companyId}
                        key={key}
                        disabled={disabled}
                        groupId={key}
                        countryCode={site?.address?.countryCode}
                        resources={r}
                        error={error}
                        setError={setError}
                        onAddSource={addResourceSource}
                        onChange={handleResourceChange}
                        onRemoveSource={removeresourceSource}
                        onUnitChange={handleResourceUnitChange}
                    />
                ),
                siteResourcesGroup
            )}
        </React.Fragment>
    )
}
