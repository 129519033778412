import React, { useContext } from 'react'
import { NotesButton as NotesButtonComponent } from '@mondra/ui-components'
import { SlideoverContext } from '../contexts/SlideoverProvider'
import { IClassNameProps, NodeType } from '../types/types'
import { useCountsFetch } from '../hooks/useCountsFetch'


interface INotesButtonProps extends IClassNameProps {
    productId: string
    stage: NodeType
}

export default function NotesButton({ className, productId, stage }: INotesButtonProps) {
    const { openSlideover } = useContext(SlideoverContext)

    const { refetchCounts, counts } = useCountsFetch({
        productId,
        stage,
    })

    const handleClick = (tabType: any) => {
        openSlideover(NodeType.NOTE, {
            productId,
            refetch: refetchCounts,
            stage,
            tabType,
        })
    }

    return (
        <NotesButtonComponent
            className={className}
            hotspotsCount={counts.hotspotsCount}
            insightsCount={counts.insightsCount}
            notesCount={counts.notesCount}
            onClick={handleClick}
        />
    )
}
