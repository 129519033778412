import React, { useCallback, useContext, useState, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'

import { Grid } from '../../components/grid/Grid'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { PAGE_TYPES, PageFilters } from '../../components/PageHeader/PageFilters'
import { PageHeader } from '../../components/PageHeader'
import { UserContext } from '../../contexts/UserProvider'
import { URLs } from '../../constants'
import IngredientSlideover from '../../slideovers/ingredient/IngredientSlideover'
import ProduceSlideover from '../../slideovers/produce/ProduceSlideover'
import { useFiltersByUrl } from '../../hooks/useFiltersByUrl'
import { useGridControls } from '../../hooks/useGridControls'
import { serverSideGridProps } from '../../constants/grid'
import { EntityTypeEnum } from '../../types/types'
import SpecialIngredient from '../../slideovers/special-ingredient/SpecialIngredientSlideover'
import { ingredientColumns } from './IngredientColumns'
import { IngredientSuppliedProductsRow } from './IngredientSuppliedProductsRow'


const DEFAULT_FILTERS = {
    includeSpecialIngredients: false,
}

export function Ingredients() {
    const { setGridApi, refreshGrid } = useGridControls()

    const { filters, setFilters } = useFiltersByUrl()

    const [ingredientId, setIngredientId] = useState()
    const [editProduceId, setEditProduceId] = useState()
    const [editSplIngId, setEditSplIngredientId] = useState<string>()
    const [slideoverOpen, setSlideoverOpen] = useState(false)

    const { company } = useContext(UserContext)
    const companyId = getOr('', 'id', company)
    const handleFilterChange = useCallback(
        (filter: any) => {
            setFilters({
                name: filter,
            })
        },
        [setFilters]
    )

    const handleRefetch = useCallback(() => {
        refreshGrid()
    }, [refreshGrid])

    const handleSlideoverOpen = useCallback(() => {
        setSlideoverOpen(true)
    }, [])

    const handleSlideoverClose = useCallback(() => {
        setSlideoverOpen(false)
        setIngredientId(undefined)
    }, [])

    const handleProduceClose = useCallback(() => {
        setEditProduceId(undefined)
    }, [])

    const handleSplIngClose = useCallback(() => {
        setEditSplIngredientId(undefined)
    }, [])

    const handleFilterSubmit = (filters: any) => {
        setFilters(filters)
    }

    if (isEmpty(companyId)) {
        return <Redirect to="/no-company" />
    }

    const urlOptions = useMemo(() => ({ companyId }), [companyId])

    const detailCellRenderer = useMemo<any>(() => IngredientSuppliedProductsRow, [])

    const allFilters = useMemo(() => ({ ...filters, ...DEFAULT_FILTERS }), [filters])

    return (
        <GridPageLayout isPadding>
            <PageHeader
                title={PAGE_TYPES.INGREDIENTS}
                primaryAction={handleSlideoverOpen}
                onSearch={handleFilterChange}
                filterList={<PageFilters pageType={PAGE_TYPES.INGREDIENTS} />}
                onFilterSubmit={handleFilterSubmit}
            />

            <GridPageLayout>
                <Grid
                    columnDefs={ingredientColumns}
                    context={{
                        companyId,
                        refetch: handleRefetch,
                        setEditProduceId,
                        setEditSplIngredientId,
                        setIngredientId,
                    }}
                    detailCellRenderer={detailCellRenderer}
                    detailRowAutoHeight={true}
                    entityType={EntityTypeEnum.INGREDIENT}
                    filters={allFilters}
                    onApiAvailable={setGridApi}
                    setFilters={setFilters}
                    url={URLs.IngredientCollection.GET_ALL}
                    urlOptions={urlOptions}
                    masterDetail={true}
                    {...serverSideGridProps}
                />
            </GridPageLayout>
            <IngredientSlideover
                companyId={companyId}
                isOpen={slideoverOpen || !isEmpty(ingredientId)}
                ingredientId={ingredientId}
                onClose={handleSlideoverClose}
                onSaved={handleRefetch}
            />
            <ProduceSlideover
                companyId={companyId}
                isOpen={!isEmpty(editProduceId)}
                productId={editProduceId}
                onClose={handleProduceClose}
                onSaved={handleRefetch}
            />
            <SpecialIngredient
                companyId={companyId}
                allowExisting={false}
                productId={editSplIngId}
                isOpen={!isEmpty(editSplIngId)}
                onClose={handleSplIngClose}
                onSaved={handleRefetch}
            />
        </GridPageLayout>
    )
}
