import React, { useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { Label, Button, Notification } from '@mondra/ui-components'
import useFetch from '../hooks/useFetch'
import useUpdateFormData from '../hooks/useUpdateFormData'
import { useTopCenterToast } from '../hooks/useTopCenterToast'
import UploadImage from '../components/UploadImage'
import Image from '../components/Image'
import getURL from '../utils/getURL'
import { URLs } from '../constants'
import { PRODUCTS } from '../constants/toastNotifications'


const imageTypes = ['image/jpg', 'image/jpeg']

function getFileError(file: any) {
    const errors: any = {}

    if (file.size > 500000) {
        errors.size = 'File size must be less than 500 KB.'
    }
    if (!imageTypes.includes(file.type)) {
        errors.extension = 'File must be of a JPG.'
    }

    return errors
}

export default function ProductImages({
    readOnly,
    productId,
    companyId,
    onProductImagesChange,
}: any) {
    const [bannerFileUrl, setBannerFileUrl] = useState(null)
    const [productFileUrl, setProductFileUrl] = useState(null)
    const [file1Errors, setFile1Errors] = useState({} as any)
    const [file2Errors, setFile2Errors] = useState({} as any)

    const { showSuccess } = useTopCenterToast()

    const { data: productImages, refetch } = useFetch({
        autoFetch: !isEmpty(productId),
        defaultRes: {},
        url: getURL(URLs.Product.IMAGE_UPLOAD, { companyId, productId }),
    })

    const { saving, update } = useUpdateFormData({
        contentType: false,
        method: 'PUT',
        onUpdate: (data: any) => {
            showSuccess({ description: PRODUCTS.IMAGE_UPDATE })
            setBannerFileUrl(data.bannerImageUrl)
            setProductFileUrl(data.imageUrl)
            onProductImagesChange(data)
            refetch()
        },
        url: getURL(URLs.Product.IMAGE_UPLOAD, { companyId, productId }),
    })

    const { bannerImageUrl, imageUrl } = productImages

    const handleUpload1 = (event: any) => {
        const file = event.target.files[0]
        setBannerFileUrl(URL.createObjectURL(file) as any)
        setFile1Errors(getFileError(file))
        onSubmit({
            bannerImageFileData: file,
            imageUrl,
        })
    }

    const handleUpload2 = (event: any) => {
        const file = event.target.files[0]
        setProductFileUrl(URL.createObjectURL(file) as any)
        setFile2Errors(getFileError(file))
        onSubmit({
            bannerImageUrl,
            productImageFileData: file,
        })
    }

    const handleRemove1 = () => {
        onSubmit({
            bannerImageUrl: '',
            imageUrl,
        })
    }

    const handleRemove2 = () => {
        onSubmit({
            bannerImageUrl,
            imageUrl: '',
        })
    }

    function onSubmit({
        bannerImageFileData,
        productImageFileData,
        bannerImageUrl,
        imageUrl,
    }: any) {
        if (isEmpty(file1Errors) && isEmpty(file2Errors)) {
            const formData = new FormData()
            if (bannerImageFileData) {
                formData.append('bannerImageFile', bannerImageFileData as any)
            }
            if (productImageFileData) {
                formData.append('imageFile', productImageFileData as any)
            }
            if (bannerImageUrl || bannerImageUrl === '') {
                formData.append('bannerImageUrl', bannerImageUrl)
            }
            if (imageUrl || imageUrl === '') {
                formData.append('imageUrl', imageUrl)
            }
            update(formData)
        }
    }

    return (
        <>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <Label htmlFor="topBanner" className="font-medium sm:mt-px sm:pt-2">
                    Top banner
                </Label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {(!isEmpty(file1Errors) || !isEmpty(file2Errors)) && (
                        <div className="mb-2">
                            <Notification type="error">
                                {file1Errors.size && (
                                    <span className="mb-1 mr-2">Top banner {file1Errors.size}</span>
                                )}
                                {file1Errors.extension && (
                                    <span className="mb-1 mr-2">
                                        Top banner {file1Errors.extension}
                                    </span>
                                )}
                            </Notification>
                        </div>
                    )}
                    <div>
                        <div className="flex">
                            <div className="w-3/6 h-20 max-h-full max-w-full rounded shadow-sm border border-gray-200 flex items-center">
                                <Image
                                    url={bannerFileUrl || bannerImageUrl}
                                    defaultUrl="/certificate_top_placeholder.png"
                                    alt="product top banner"
                                />
                            </div>
                            <Dimension>760 X 162</Dimension>
                        </div>
                        {!readOnly && (
                            <div className="mt-2 flex items-center space-x-2">
                                <UploadImage
                                    name="topBanner"
                                    className="text-left my-1 ml-2"
                                    id="productImage1"
                                    imageTypes={imageTypes}
                                    loading={bannerFileUrl && saving}
                                    onUpload={handleUpload1}
                                />
                                <div className="ml-1">
                                    <Button
                                        type="button"
                                        variant="danger"
                                        onClick={handleRemove1}
                                        iconType="close"
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <Label htmlFor="thumbnail" className="font-medium sm:mt-px sm:pt-2">
                    Thumbnail
                </Label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {(!isEmpty(file1Errors) || !isEmpty(file2Errors)) && (
                        <div className="mb-2">
                            <Notification type="error">
                                {file2Errors.size && (
                                    <span className="mb-1 mr-2">
                                        Product thumbnail {file2Errors.size}
                                    </span>
                                )}
                                {file2Errors.extension && (
                                    <span className="mb-1">
                                        Product thumbnail {file2Errors.extension}
                                    </span>
                                )}
                            </Notification>
                        </div>
                    )}
                    <div>
                        <div className="flex">
                            <div className="w-24 h-24 max-h-full max-w-full rounded border border-gray-200 shadow-sm flex items-center">
                                <Image
                                    url={productFileUrl || imageUrl}
                                    defaultUrl="/certificate_product_placeholder.png"
                                    alt="product thumbnail"
                                />
                            </div>
                            <Dimension>320 X 320</Dimension>
                        </div>

                        {!readOnly && (
                            <div className="mt-2 flex items-center space-x-2">
                                <UploadImage
                                    className="text-left my-1 ml-2"
                                    id="productImage2"
                                    imageTypes={imageTypes}
                                    loading={productFileUrl && saving}
                                    onUpload={handleUpload2}
                                />
                                <Button
                                    type="button"
                                    variant="danger"
                                    onClick={handleRemove2}
                                    iconType="close"
                                >
                                    Remove
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

function Dimension({ children }: any) {
    return (
        <div className="p-1 bg-gray-200 z-10 leading-normal text-gray-900 text-xs font-medium h-6 text-center">
            {children}
        </div>
    )
}
