import React from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'
import { IChildrenProps } from '../../../types/types'


interface IFilterSection extends IChildrenProps {
    title: string
    countProps?: IFilterCount
}

export const FilterSection = ({ title, children, countProps }: IFilterSection) => (
    <div className="pb-6 border-t border-gray-300 px-8">
        <div className="flex justify-between items-center py-5">
            <div className="text-gray-700 text-sm font-medium">{title}</div>
            {countProps?.count ? <FilterCount {...countProps} /> : ''}
        </div>
        {children}
    </div>
)

interface IFilterCount {
    count: number
    onClear: () => void
    sizeBig?: boolean
}

export const FilterCount = ({ count, onClear, sizeBig }: IFilterCount) => {
    return (
        <div
            role="button"
            className={classNames(
                'flex justify-between items-center bg-gray-900 rounded-xl cursor-pointer text-white text-xs px-2',
                sizeBig ? 'h-6 min-w-[48px]' : 'h-4 min-w-[42px]'
            )}
            onClick={onClear}
        >
            <div>{count}</div>
            <Icon className="text-white" size={16} type="close" />
        </div>
    )
}
