import React from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'

import { IChildrenProps, Element } from '../types/types'


const Sidebar = ({ children }: IChildrenProps) => {
    return (
        <aside className="relative flex flex-col min-w-sidebar-close w-sidebar-close max-w-sidebar-open xl:min-w-sidebar-open xl:w-sidebar-open h-full min-h-scroll bg-gray-900 pl-8 pt-8 border-t border-gray-600">
            <ul className="w-full flex-grow max-h-scroll overflow-y-auto space-y-2">{children}</ul>
        </aside>
    )
}

Sidebar.Group = function SidebarGroup({ children }: IChildrenProps) {
    return (
        <div className="px-4 pt-2">
            <label className="text-gray-400 font-medium text-sm leading-6">{children}</label>
        </div>
    )
}

interface ISidebarItemProps extends React.ComponentPropsWithoutRef<'div'> {
    as?: React.ElementType
    disabled?: boolean
    hover?: boolean
    iconType: string
    right?: Element
    selected?: boolean
    title: string
    to?: string
}

Sidebar.Item = function Item({
    as: Comp = 'a',
    className,
    children,
    disabled,
    iconType,
    right,
    selected,
    title,
    to,
    ...rest
}: ISidebarItemProps) {
    return (
        <li>
            <Comp
                className={classNames(
                    'nav-item relative box-border flex items-center transition-all duration-100 ease-in w-full px-4 py-2 text-sm  leading-6',
                    !disabled && 'rounded-l-sm hover:text-white hover:bg-gray-700 cursor-pointer',
                    !disabled && selected && 'rounded-l-sm bg-gray-700 text-white font-semibold',
                    !disabled && !selected && 'text-gray-300 font-medium',
                    disabled && 'text-gray-200 cursor-not-allowed',
                    className
                )}
                to={to}
                {...rest}
                title={title}
            >
                <Icon type={iconType} size={16} className="text-current" />
                <div className="ml-3 hidden lg:hidden xl:block w-full">{right}</div>
            </Comp>
        </li>
    )
}

export default Sidebar
