import React, { useCallback, useMemo } from 'react'
import { Autocomplete, Input, Icon, Label, RadioGroup } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import find from 'lodash/fp/find'
import useCountries from '../../hooks/useCountries'
import useRegions from '../../hooks/useRegions'
import { FormRow } from '../../components'
import { buildAddress } from '../../utils'
import { IAddress, IAddressMapApi, ICountry, IRegion, ISite, IValue } from '../../types/types'
import { DOWNSHIT_ITEM_SELECT_TYPE } from '../../constants'
import AddressLookup from './AddressLookup'


const buildStreetName = (address: IAddressMapApi) => {
    const name = []
    if (!isEmpty(address)) {
        if (address.streetNumber) {
            name.push(address.streetNumber)
        }
        if (address.streetName) {
            name.push(address.streetName)
        }
        if (address.municipalitySubdivision) {
            name.push(address.municipalitySubdivision)
        }
    }
    return name.join(', ')
}

interface IeProps {
    autoFetch: boolean
    companyName: string
    loading?: boolean
    editMode?: boolean
    errors?: any
    onChange: (changes: Record<string, IValue>, reset?: boolean) => void
    onSumbit: (event: React.FormEvent) => void
    site: ISite
    readOnly: boolean
}

export default function SiteForm({
    companyName = '',
    loading,
    errors,
    site,
    onChange,
    onSumbit,
}: IeProps) {
    const { countries, loading: loadingCountries } = useCountries({
        autoFetch: !site.address.isFullStreetAddress,
    })

    const selectedCountry: ICountry | undefined = useMemo(() => {
        const sCountry = find((c: ICountry) => c.countryName === site.address?.country, countries)
        if (isEmpty(sCountry)) {
            return undefined
        }
        return {
            ...sCountry,
            label: sCountry?.countryName,
            value: sCountry?.countryName,
        } as ICountry
    }, [site.address?.country, countries])

    const { regions, loading: loadingRegions } = useRegions({
        autoFetch: !site.address.isFullStreetAddress && !isEmpty(selectedCountry?.isoAlpha3),
        countryCode: selectedCountry?.isoAlpha3 || '',
    })
    const selectedRegion: IRegion | undefined = useMemo(() => {
        const sRegion = find((c: IRegion) => c.name === site.address?.state, regions)
        if (isEmpty(sRegion)) {
            return undefined
        }
        return {
            ...sRegion,
            label: sRegion?.name,
            value: sRegion?.name,
        } as IRegion
    }, [site.address?.state, regions])

    const selectedAddress = buildAddress(site?.address || {})

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = event.target
            onChange({ [name]: value })
        },
        []
    )

    const handleAddressTypeChange = useCallback(
        (value: number) => {
            const address = { ...site.address, isFullStreetAddress: value ? false : true }
            onChange({ address } as any)
        },
        [site]
    )

    const handleAddressChange = ({ address, position }: any) => {
        const addressObj: IAddress = {
            city: address?.municipality || '',
            country: address?.country || '',
            countryCode: address?.countryCodeISO3 || '',
            countryCodeISOAlpha2: address?.countryCode || '',
            countryCodeISOAlpha3: address?.countryCodeISO3 || '',
            isArchived: false,
            isFullStreetAddress: site?.address?.isFullStreetAddress,
            latitude: position?.lat,
            longitude: position?.lon,
            pinCode: address?.postalCode,
            state: address?.countrySubdivision || '',
            streetAddress: buildStreetName(address),
        }
        onChange({ address: addressObj } as any)
    }

    const handleCountrySelected = ({ selectedItem }: { selectedItem: ICountry }) => {
        const address: IAddress = {
            ...site.address,
            city: '',
            country: selectedItem?.countryName,
            countryCode: selectedItem?.isoAlpha3,
            countryCodeISOAlpha2: selectedItem?.isoAlpha2,
            countryCodeISOAlpha3: selectedItem?.isoAlpha3 || '',
            countryId: selectedItem?.id,
            pinCode: '',
            state: '',
            streetAddress: '',
        }
        onChange({ address } as any)
    }

    const handleCountryStateChange = (state: { selectedItem: ICountry; type: number }) => {
        const { type, selectedItem } = state
        if (type === DOWNSHIT_ITEM_SELECT_TYPE) {
            const address = {
                ...site.address,
                country: selectedItem?.countryName,
                countryCode: selectedItem?.isoAlpha3,
                countryId: selectedItem?.id,
            }
            onChange({ address } as any)
        }
    }

    const handleRegionSelected = ({ selectedItem }: { selectedItem: IRegion }) => {
        const address: IAddress = {
            ...site.address,
            city: '',
            pinCode: '',
            state: selectedItem?.name,
            streetAddress: '',
        }
        onChange({ address } as any)
    }

    const handleRegionStateChange = (state: { selectedItem: IRegion; type: number }) => {
        const { type, selectedItem } = state
        if (type === DOWNSHIT_ITEM_SELECT_TYPE) {
            const address = { ...site.address, state: selectedItem?.name }
            onChange({ address } as any)
        }
    }

    return (
        <form id="add-edit-site-form" className="pt-2 divide-y divide-gray-200" onSubmit={onSumbit}>
            <FormRow>
                <Label
                    className="col-span-1"
                    helpText="e.g. Main factory, storage, distribution centre etc."
                    htmlFor="name"
                >
                    Site name
                </Label>
                <Input
                    className="col-span-1"
                    id="name"
                    name="name"
                    disabled={loading}
                    invalid={errors.site}
                    value={loading ? 'Loading...' : site.name}
                    onChange={handleChange}
                />
            </FormRow>
            <FormRow>
                <Label
                    className="col-span-1"
                    helpText="The company that uses the site."
                    htmlFor="companyName"
                >
                    Company
                </Label>
                <Label className="col-span-1 ml-4" htmlFor="companyName">
                    {companyName}
                </Label>
            </FormRow>
            <FormRow>
                <Label
                    className="col-span-1"
                    helpText="If you have a code or reference you use for this site."
                    htmlFor="siteId"
                >
                    Site ID
                </Label>
                <Input
                    className="col-span-1"
                    id="siteId"
                    name="siteId"
                    disabled={loading}
                    value={site.siteId}
                    onChange={handleChange}
                />
            </FormRow>
            <FormRow>
                <div className="col-span-2">
                    <RadioGroup
                        onChange={handleAddressTypeChange}
                        disabled={loading}
                        options={[
                            {
                                description:
                                    'Start typing address of site and select from the dropdown.',
                                label: (
                                    <div className="flex flex-row flex-start items-center gap-2 mb-1">
                                        <Icon size={22} type="map" /> I know the full street address
                                    </div>
                                ),
                                value: 0,
                            },
                            {
                                description:
                                    'Start typing the country and region of the site by selecting from the dropdowns.',
                                label: (
                                    <div className="flex flex-row flex-start items-center gap-2 mb-1">
                                        <Icon size={22} type="mapBoundary" /> I know region/country
                                    </div>
                                ),
                                value: 1,
                            },
                        ]}
                        orientation="horizontal"
                        value={site.address?.isFullStreetAddress ? 0 : 1}
                        variant="stacked"
                    />
                </div>
            </FormRow>
            {site.address.isFullStreetAddress && (
                <FormRow>
                    <div className="col-span-2">
                        <Label
                            helpText="Type to select the address, NB: Address of the Ingredients/products are moving to/from. No PO boxes."
                            htmlFor="address"
                        >
                            Enter the address of the site
                        </Label>
                    </div>
                    <div className="col-span-2 mt-4">
                        <AddressLookup
                            selected={selectedAddress}
                            disabled={loading}
                            errors={errors}
                            onAddressChange={handleAddressChange}
                        />
                    </div>
                </FormRow>
            )}
            {!site.address.isFullStreetAddress && (
                <FormRow>
                    <div className="col-span-2">
                        <Label
                            helpText="Select from the drop down the country or region of your site."
                            htmlFor="address"
                        >
                            Enter the Region/Country
                        </Label>
                    </div>
                    <div className="col-span-1 mt-4">
                        <Label htmlFor="address">Country of the site</Label>
                    </div>
                    <div className="col-span-1 mt-4">
                        <Label htmlFor="address">Region/Province/Country/State</Label>
                    </div>
                    <div className="col-span-1 mt-1">
                        <Autocomplete
                            placeholder={loadingCountries ? 'Loading...' : 'Select a country'}
                            selected={selectedCountry}
                            invalid={Boolean(errors.address)}
                            disabled={loading || loadingCountries}
                            onStateChange={handleCountryStateChange}
                            onChange={handleCountrySelected}
                            options={countries}
                        />
                    </div>
                    <div className="col-span-1 mt-1">
                        <Autocomplete
                            placeholder={loadingRegions ? 'Loading...' : 'Select a region'}
                            selected={selectedRegion}
                            disabled={loading || loadingRegions}
                            onStateChange={handleRegionStateChange}
                            onChange={handleRegionSelected}
                            options={regions}
                        />
                    </div>
                </FormRow>
            )}
            <FormRow />
        </form>
    )
}
