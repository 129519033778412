import React from 'react'
import classNames from 'classnames'

import { formatNumberWithFractions } from '../../utils'
import { impactBarColors } from '../../constants/colors'


export interface IEcoImpactDetailChartProps extends React.ComponentPropsWithoutRef<'div'> {
    carbon: number
    water: number
    eutro: number
    bio: number
    heightClass?: string
}

export const EcoImpactDetailChart = ({
    carbon,
    water,
    eutro,
    bio,
    className,
    heightClass = 'h-60',
}: IEcoImpactDetailChartProps) => {
    return (
        <div
            className={classNames(
                'grid grid-cols-4 gap-x-1 md:gap-x-2 px-4',
                heightClass,
                className
            )}
        >
            <BarBg>
                <Bar percent={formatNumberWithFractions(carbon)} color={impactBarColors.carbon} />
            </BarBg>

            <BarBg>
                <Bar percent={formatNumberWithFractions(water)} color={impactBarColors.water} />
            </BarBg>

            <BarBg>
                <Bar percent={formatNumberWithFractions(eutro)} color={impactBarColors.eutro} />
            </BarBg>

            <BarBg>
                <Bar percent={formatNumberWithFractions(bio)} color={impactBarColors.bio} />
            </BarBg>
        </div>
    )
}

function BarBg({ children }: any) {
    return <div className="col-span-1 h-full bg-gray-50 relative flex items-end">{children}</div>
}

interface IBarProps {
    percent: string
    color: string
}

function Bar({ percent, color }: IBarProps) {
    const height = `${percent}%`
    return (
        <div
            className={classNames(
                'absolute w-full hover:shadow-md hover:translate-y-0.5 transition-all',
                color
            )}
            style={{ height: +percent > 100 ? '100%' : height }}
            title={`${height}`}
        />
    )
}

export default EcoImpactDetailChart
