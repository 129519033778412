import flattenDeep from 'lodash/fp/flattenDeep'
import last from 'lodash/fp/last'
import chunk from 'lodash/fp/chunk'
import find from 'lodash/fp/find'
import filter from 'lodash/fp/filter'
import head from 'lodash/fp/head'
import map from 'lodash/fp/map'
import max from 'lodash/fp/max'
import isEmpty from 'lodash/fp/isEmpty'
import random from 'lodash/fp/random'
import xor from 'lodash/fp/xor'
import {
    IProductNode,
    ISupplyChainAPI,
    ISupplyChainData,
    ISupplyChainNode,
    ISupplyChainRow,
    SupplyChainStage,
} from '../types/supply-chain-types'
import { NodeState, NodeType, Ownership, SA_TYPE } from '../types/types'
import { INGREDIENT, SUPPLY_CHAIN_NODE_TYPES, TYPE_APPROVED, TYPE_CERTIFIED } from '../constants'
import { ScStageWiseColors } from '../constants/colors'
import { getLines } from './supply-chain-lines'
import { isWaterSplIngredient } from '.'


export const DUMMY_NODE = 'dummy-node'
// TODO - Need to remove this
export const WATER_NODE = 'water-node'
export const SUPPLY_CHAIN_NODES = {
    ingredient: NodeType.INGREDIENT,
    produce: NodeType.PRODUCE,
    product: NodeType.PRODUCT,
    specialingredient: NodeType.SPECIAL_INGREDIENT,
}

function getDummyNode(i: string | number, originalId: string, type: string): any {
    return {
        id: `${i}-${type}-placeholder`,
        isDummy: true,
        nodeId: `${i}-${type}-placeholder`,
        originalId,
        type: DUMMY_NODE,
    }
}

export function getDestinationSupplier({
    supId,
    suppliers,
    chain,
    parentSuppliedIngredients,
}: any) {
    const supplier = find({ nodeId: supId }, suppliers)
    //Checking will be done at ingredient level
    //as it won't be available in supplier list
    if (supplier) {
        if (!parentSuppliedIngredients.includes(supplier.nodeId)) {
            chain.push(supplier)
        }
        getDestinationSupplier({
            chain,
            parentSuppliedIngredients,
            supId: supplier.parentId,
            suppliers,
        })
    }
    return chain
}

function getIngredientSuppliers({ supId, suppliers, parentSuppliedIngredients }: any) {
    const supplyChain = getDestinationSupplier({
        chain: [],
        parentSuppliedIngredients,
        supId,
        suppliers,
    })
    return {
        supplyChain,
        supplyChainCount: supplyChain.length,
    }
}

function findProduces(nodeId: string, productList: ISupplyChainNode[]) {
    return filter({ parentId: nodeId }, productList)
}

function getNextProduces(currentNode: ISupplyChainNode, mainIngredient: ISupplyChainNode): any[] {
    const nextNodes = filter({ parentId: currentNode.nodeId }, mainIngredient.suppliers)

    if (nextNodes.length > 0) {
        return nextNodes.map((node: ISupplyChainNode) => {
            const p = findProduces(node.nodeId, mainIngredient.produce)

            return [...p, ...getNextProduces(node, mainIngredient)]
        })
    }

    const p = findProduces(currentNode.nodeId, mainIngredient.produce)

    if (p.length > 0) {
        if (currentNode.saType === INGREDIENT) {
            return p
        }
        return []
    }

    return [
        {
            ...getDummyNode(random(0, 9999), '', SUPPLY_CHAIN_NODES.produce),
            parentId: currentNode.nodeId,
        },
    ]
}

function getReversedProduces(ingredient: ISupplyChainNode): ISupplyChainNode[] {
    if (isEmpty(ingredient) || (isEmpty(ingredient.suppliers) && isEmpty(ingredient.produce))) {
        return []
    }

    return flattenDeep(getNextProduces(ingredient, ingredient))
}

function fillInGaps(rows: any) {
    const maxCount: number = max(map('supplyChainCount', rows))

    const filledRows = rows.map((r: any) => {
        const fillCount = maxCount - r.supplyChain.length
        const lastNode: any = last(r.supplyChain)
        const fillNodes = new Array(fillCount)
            .fill(0)
            .map((f: any, index: number) =>
                getDummyNode(
                    `${r.ingredient.id}-${index}-supply-chain`,
                    '',
                    SUPPLY_CHAIN_NODES.product
                )
            )
        const chunks = chunk(fillNodes.length - 1, fillNodes)

        /**
         * TODO: Explain logic
         * This logic fills dummy nodes for each row
         * Case 1: If there are fill nodes that means uneven supply nodes exists
         * and overall depth of the supply chain is not matching with no of node in any row
         * if fill node count more than 1
         * Case 2: if fill nodes count is 1
         * Case 3: Some times produce can be added as direct into
         * ingredients as tier 2, then produce node
         * should be connected to ingredient node
         * */
        const isLastNode
            = lastNode
            && (r.ingredient.originalId
                ? r.ingredient.originalId === lastNode.parentId
                : r.ingredient.nodeId === lastNode.parentId)

        return {
            ...r,
            supplyChain:
                fillNodes.length > 0
                    ? fillNodes.length > 1
                        ? isLastNode
                            ? [...chunks[0], ...chunks[1], ...r.supplyChain]
                            : [...chunks[0], ...r.supplyChain, ...chunks[1]]
                        : isLastNode
                            ? [...fillNodes, ...r.supplyChain]
                            : [...r.supplyChain, ...fillNodes]
                    : r.supplyChain,
        }
    })
    return filledRows
}

export function getSupplyChainData(
    data: ISupplyChainAPI,
    rowHeight = 96,
    columnWidth = 0,
    columnOffset = 0
): ISupplyChainData {
    const product: ISupplyChainNode = data?.product
    const rows = (data?.ingredients || []).map((i: ISupplyChainNode, indexIng: number) => {
        //Write logic around produce array for each produce item when ingredient is expanded
        //Add all supply chain columns in each row based on the parent exist
        //in the suppliersList for each produce and dummy ingredient

        const parentSuppliedIngredients: any = []
        const prevParentSupId: string[] = []

        const finalProduces = getReversedProduces(i)
        const produces = !isEmpty(finalProduces) ? [...finalProduces] : []

        const produceRows = produces.map((produce: ISupplyChainNode, index: number) => {
            const { supplyChain, supplyChainCount } = getIngredientSuppliers({
                parentSuppliedIngredients,
                supId: produce.parentId,
                suppliers: i.suppliers,
            })
            const newSupplyChain = supplyChain.map((s: any) => {
                const newNode = {
                    ...s,
                    type:
                        s.saType === SA_TYPE.SpecialIngredient
                            ? SUPPLY_CHAIN_NODES.specialingredient
                            : SUPPLY_CHAIN_NODES.product,
                }

                if (prevParentSupId.includes(s.nodeId)) {
                    newNode.isDummy = true
                }
                if (!prevParentSupId.includes(s.nodeId)) {
                    prevParentSupId.push(s.nodeId)
                }
                return newNode
            })

            return {
                ingredient:
                    index === 0
                        ? {
                            ...i,
                            parentId: product?.nodeId,
                            type: SUPPLY_CHAIN_NODES.ingredient,
                        }
                        : getDummyNode(
                            `${index}-${indexIng}`,
                            i.nodeId,
                            SUPPLY_CHAIN_NODES.ingredient
                        ),
                produce: { ...produce, type: SUPPLY_CHAIN_NODES.produce },
                supplyChain: newSupplyChain,
                supplyChainCount,
            }
        })

        if (isWaterSplIngredient(i)) {
            produceRows.push({
                ingredient: {
                    ...i,
                    companyId: data?.product?.companyId,
                    companyName: data?.product?.companyName,
                    type: SUPPLY_CHAIN_NODES.ingredient,
                },
                produce: {
                    ...getDummyNode(0, '', SUPPLY_CHAIN_NODES.produce),
                    parentId: i.nodeId,
                },
                supplyChain: [],
                supplyChainCount: 0,
            })
        }

        return produceRows
    })

    const ingredientRows: ISupplyChainRow[] = fillInGaps(flattenDeep(rows))

    const gridRows = new Array(ingredientRows.length + 1)
        .fill(0)
        .map((_: any) => `${rowHeight}px`)
        .join(' ')

    const row: ISupplyChainRow | undefined = head(ingredientRows)
    const columnCount = row ? row.supplyChain.length : 1

    const supplyChainColumns = new Array(columnCount)
        .fill(0)
        .map((_, index) => ({
            id: index,
            name: `Supply chain tier ${index + 1}`,
        }))
        .reverse()

    return {
        columnCount,
        gridRows,
        ingredientRows,
        lines: getLines(ingredientRows, product, columnWidth, columnOffset),
        product,
        supplyChainColumns,
    }
}

export function getNextItemInChain(
    supplyChain: any = [],
    startIndex: number,
    defaultItem: any
): ISupplyChainNode {
    for (let i = startIndex; i < supplyChain.length; i++) {
        if (supplyChain[i].type !== DUMMY_NODE) {
            return supplyChain[i]
        }
    }
    return defaultItem
}

export function checkOwnership(ownership: Ownership, nodeOwnership: Ownership) {
    return (
        ownership.filter((o: any) => Boolean(o)).length > 0
        && xor(ownership, nodeOwnership).length === 0
    )
}

export function isCertifiedState(state: NodeState) {
    return [TYPE_CERTIFIED, TYPE_APPROVED].includes(state)
}

export function getRequiredStages(node: IProductNode) {
    const stages: SupplyChainStage[] = []

    if (
        node.type === SUPPLY_CHAIN_NODE_TYPES.ingredient
        || node.type === SUPPLY_CHAIN_NODE_TYPES.produce
        || node.type === SUPPLY_CHAIN_NODE_TYPES.specialingredient
    ) {
        return [node.type]
    }
    if (node.processingRequired) {
        stages.push(SUPPLY_CHAIN_NODE_TYPES.processing as SupplyChainStage)
    }
    if (node.packagingRequired) {
        stages.push(SUPPLY_CHAIN_NODE_TYPES.packaging as SupplyChainStage)
    }
    if (node.storageRequired) {
        stages.push(SUPPLY_CHAIN_NODE_TYPES.storage as SupplyChainStage)
    }
    if (node.salesRequired) {
        stages.push(SUPPLY_CHAIN_NODE_TYPES.sale as SupplyChainStage)
    }

    return stages
}

export function getIconByStage(stage: SupplyChainStage) {
    if (stage === SUPPLY_CHAIN_NODE_TYPES.specialingredient) {
        return 'specialIngredient'
    }
    return stage
}

export function getSupplyChainNodeColor(node: IProductNode) {
    return ScStageWiseColors[node.type]
}

export function isNodeAccessible(node: IProductNode, product?: IProductNode) {
    return !node.isSpecialIngredient && !node.isProductLocked && !product?.isProductLocked
}
