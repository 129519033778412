import React, { ReactChildren, ReactNode } from 'react'
import { Icon } from '@mondra/ui-components'
import classNames from 'classnames'


interface IAccordion {
    children?: ReactNode | ReactChildren
    className?: string
    heading: string
    onClick: any
    open?: boolean
}

function Accordion({ children, heading, onClick, open }: IAccordion) {
    return (
        <div>
            <div
                role="button"
                title={heading}
                onClick={onClick}
                className={classNames(
                    'flex items-center justify-between cursor-pointer',
                    'p-2 w-full',
                    'motion-safe:transition-all',
                    'text-sm font-medium text-gray-800',
                    'border-b border-gray-300',
                    'hover:bg-primary-50 hover:bg-opacity-50',
                    open ? 'bg-primary-50' : 'bg-gray-100'
                )}
            >
                <span className="truncate">{heading}</span>
                <Icon
                    type="chevronDown"
                    size={16}
                    className={classNames(
                        'motion-safe:transition-all',
                        open && 'transform rotate-180'
                    )}
                />
            </div>
            {open && <div className="text-gray-800 text-xs px-2">{children}</div>}
        </div>
    )
}

export default Accordion
