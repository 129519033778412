import React from 'react'
import groupBy from 'lodash/fp/groupBy'
import isEmpty from 'lodash/fp/isEmpty'
import classNames from 'classnames'
import { Autocomplete } from '@mondra/ui-components'
import { mapWithIndex } from '../utils/lodash-convert'


interface IDropdownList {
    listItem: (props: any) => React.ReactNode
    groupByField: string
    options: any
    highlightedIndex: number
    getItemProps: (props: any) => any
    index: number
    selected: any
}

const DropdownList = (props: IDropdownList) => {
    const { options, groupByField, getItemProps, highlightedIndex, listItem, selected } = props
    const groupedOptions: any = groupBy(
        groupByField,
        mapWithIndex((o: any, index: number) => ({ ...o, index }), options)
    )
    const groupKeys = Object.keys(groupedOptions)
    return (
        <Autocomplete.ListContainer
            as="div"
            zIndex="z-px"
            className={classNames('divide-y divide-gray-100 py-0')}
        >
            {groupKeys.map((key: string) => (
                <ul key={key} role="group" className="">
                    <li className="px-4 py-2 text-gray-700 text-md font-semibold sticky top-0 bg-white">
                        {key}
                    </li>
                    {groupedOptions[key].map((option: any) => {
                        const { index } = option
                        return (
                            <>
                                {listItem({
                                    getItemProps,
                                    highlightedIndex,
                                    index,
                                    key: option.id || option.value,
                                    option,
                                    selected,
                                })}
                            </>
                        )
                    })}
                </ul>
            ))}
            {isEmpty(options) && <div className="px-4">No record found</div>}
        </Autocomplete.ListContainer>
    )
}

export default DropdownList
