import { useEffect, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import toLower from 'lodash/fp/toLower'
import { URLs } from '../constants'
import { ProductDetailTypes, IString } from '../types/types'
import getURL from '../utils/getURL'
import useFetch from './useFetch'


interface IUseProductDetailProps {
    autoFetch?: boolean
    type: ProductDetailTypes
    companyId: string
    productId?: string
    supplierId?: IString
}

export default function useProductDetails({
    autoFetch = true,
    type,
    companyId,
    productId,
    supplierId,
}: IUseProductDetailProps) {
    const [details, setDetails] = useState<any>()
    const query = isEmpty(supplierId) ? '' : `?supplierId=${supplierId}`

    // Get Product details
    const { data, loading, refetch } = useFetch({
        autoFetch: autoFetch && !isEmpty(productId),
        errMessage: `Failed to fetch Product ${toLower(type)} details`,
        url: getURL(URLs.Product[type], { companyId, productId }, query),
    })

    useEffect(() => {
        if (!isEmpty(data)) {
            setDetails(data)
        }
    }, [data])

    return {
        details,
        loading,
        refetch,
    }
}
