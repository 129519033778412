import React, { useContext } from 'react'
import { isOwner, isMondraAdmin } from '../../hooks/userRoles'
import { useCompanyId } from '../../hooks/useCompanyId'
import { UserContext } from '../../contexts/UserProvider'
import { ISite } from '../../types/types'
import ArchiveProduct from '../../components/ArchiveProduct'
import { Menu, MenuList, MoreMenuButton } from '../../components'


interface ISitesActionsProps {
    component: ISite
    refresh: () => void
}

export default function SitesActions({ component, refresh }: ISitesActionsProps) {
    const companyId = useCompanyId()
    const { currentUser } = useContext(UserContext)
    const canShowArchived = isOwner(currentUser) || isMondraAdmin(currentUser)

    return (
        <Menu>
            <MoreMenuButton />
            <MenuList style={{ right: 0, width: 'max-content' }} ariaLabelledby="Sites action menu">
                {canShowArchived && (
                    <ArchiveProduct
                        apiNode="sites"
                        id={component.id}
                        name={component.name}
                        companyId={companyId}
                        isArchived={component.isArchived}
                        refresh={refresh}
                    />
                )}
            </MenuList>
        </Menu>
    )
}
