import { formatSignificantDigits } from '@mondra/ui-components'
import ecoImpactMeasures from '../constants/ecoImpactMeasures'
import { EcoImpactIds, IEcoImpact } from '../types/ecoImpacts'
import { CustomHeader } from '../components/grid/CustomHeader'
import { findImpactByCode } from './findImpact'


export function getColDefForImpact(
    impactType: EcoImpactIds,
    measureType: keyof IEcoImpact = 'per100g'
) {
    const measure = ecoImpactMeasures[impactType]
    return {
        headerComponent: CustomHeader,
        headerComponentParams: {
            description: 'per 100g',
        },
        headerName: `${measure.label} - ${measure.unit ? measure.unit + ' ' : ''}${
            measure.measure
        }`,
        type: 'rightAligned',
        valueFormatter: ({ value }: any) => {
            return `${formatSignificantDigits(value)} ${measure.unit}`
        },
        valueGetter: ({ data }: any) =>
            findImpactByCode(impactType, data.ecoImpacts)?.[measureType],
    }
}
