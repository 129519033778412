import React, { useCallback, useContext, useState, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'

import { UserContext } from '../../contexts/UserProvider'
import { URLs } from '../../constants'
import { useFiltersByUrl } from '../../hooks/useFiltersByUrl'
import { useGridControls } from '../../hooks/useGridControls'
import { Grid } from '../../components/grid/Grid'
import { PageHeader } from '../../components/PageHeader'
import { PAGE_TYPES, PageFilters } from '../../components/PageHeader/PageFilters'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { EntityTypeEnum } from '../../types/types'
import { serverSideGridProps } from '../../constants/grid'
import SupplierPopup from './SupplierPopup'
import { supplierColumns } from './SuppliersColumns'


export function Suppliers() {
    const { setGridApi, refreshGrid } = useGridControls()
    const [openPopup, setOpenPopup] = useState(false)
    const [editSupplier, setEditSupplier] = useState()
    const { filters, setFilters } = useFiltersByUrl()
    const { company } = useContext(UserContext)
    const companyId = getOr('', 'id', company)

    const handleFilterSubmit = (filters: any) => {
        setFilters(filters)
    }

    const handleRefetch = useCallback(() => {
        refreshGrid()
    }, [refreshGrid])

    const handleSupplierFilterChange = useCallback(
        (filter: any) => {
            setFilters({
                name: filter,
            })
        },
        [setFilters]
    )

    const handleAddSupplierClick = useCallback(() => {
        setOpenPopup(true)
    }, [])

    const handleSupplierDialogClose = useCallback(() => {
        setOpenPopup(false)
        setEditSupplier(undefined)
    }, [])

    if (isEmpty(companyId)) {
        return <Redirect to="/no-company" />
    }

    const urlOptions = useMemo(() => ({ companyId }), [companyId])

    return (
        <GridPageLayout isPadding>
            <PageHeader
                title={PAGE_TYPES.SUPPLIERS}
                primaryAction={handleAddSupplierClick}
                onSearch={handleSupplierFilterChange}
                filterList={<PageFilters pageType={PAGE_TYPES.SUPPLIERS} />}
                onFilterSubmit={handleFilterSubmit}
            />
            <GridPageLayout>
                <Grid
                    columnDefs={supplierColumns}
                    context={{
                        companyId,
                        companyName: company.name,
                        refetch: handleRefetch,
                        setEditSupplier,
                    }}
                    entityType={EntityTypeEnum.SUPPLIER}
                    filters={filters}
                    onApiAvailable={setGridApi}
                    setFilters={setFilters}
                    url={URLs.Supplier.GET_ALL}
                    urlOptions={urlOptions}
                    {...serverSideGridProps}
                />
            </GridPageLayout>
            <SupplierPopup
                companyId={companyId}
                edit={!isEmpty(editSupplier)}
                onClose={handleSupplierDialogClose}
                open={openPopup || !isEmpty(editSupplier)}
                refetch={handleRefetch}
                supplier={editSupplier}
            />
        </GridPageLayout>
    )
}
