import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { ICellRendererParams } from 'ag-grid-community'

import { ICategory } from '../../types/types'
import { useControls } from '../../hooks/useControls'
import { AddEditCategory } from './AddEditCategory'


interface ICategoryNameCellProps extends ICellRendererParams {
    data: ICategory
}

export function CategoryName({ data: category, context }: ICategoryNameCellProps) {
    const { refetch } = context
    const { open, isOpened, close } = useControls()
    const isEdit = !isEmpty(category) && !isEmpty(category.id)
    return (
        <div className="flex flex-row items-center">
            <div className="mdp-link font-medium underline" role="button" onClick={open}>
                {category.name}
            </div>
            <AddEditCategory
                isEdit={isEdit}
                close={close}
                isOpened={isOpened}
                category={category}
                refetch={refetch}
            />
        </div>
    )
}
