import React, { ReactElement, ReactNode, ReactChildren } from 'react'

import classNames from 'classnames'
import { IChildrenProps, IClassNameProps } from '../types/types'


interface IToolbarProps extends IChildrenProps, IClassNameProps {
    title?: string | ReactNode
    breadcrumb?: ReactNode | ReactElement | ReactChildren
    left?: ReactNode | ReactElement | ReactChildren
    right?: ReactNode | ReactElement | ReactChildren
}

const Toolbar = ({ breadcrumb, title, left, right, className, children }: IToolbarProps) => {
    return (
        <div
            className={classNames(
                'flex flex-wrap flex-col items-start justify-start space-y-2 sm:space-y-2 md:space-y-0',
                className
            )}
        >
            <div className="w-full">
                {breadcrumb && <div className="text-sm">{breadcrumb}</div>}
            </div>

            <div className="w-full flex flex-wrap items-start justify-between">
                <div className="flex flex-col flex-start justify-start">
                    {title && (
                        <h3 className="mt-2 text-xl leading-10 font-medium text-gray-700">
                            {title}
                        </h3>
                    )}
                    {left}
                </div>
                <div>
                    {children}
                    {right}
                </div>
            </div>
        </div>
    )
}

export default Toolbar
