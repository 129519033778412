import React, { useState, useCallback, useContext, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'

import { Grid } from '../../components/grid/Grid'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { MultiSelectNotification } from '../../components/MultiSelectNotification'
import { PageHeader } from '../../components/PageHeader'
import { PAGE_TYPES, PageFilters } from '../../components/PageHeader/PageFilters'

import { useMultiSelect } from '../../hooks/useMultiSelect'
import ProductSlideover from '../../slideovers/product/ProductSlideover'
import AuditSlideover from '../../slideovers/Audit/AuditSlideover'
import { SideloadCertificateContext } from '../../contexts/SideloadCertificateProvider'
import getURL from '../../utils/getURL'
import { useFiltersByUrl } from '../../hooks/useFiltersByUrl'
import { EntityTypeEnum, IProduct } from '../../types/types'
import { useGridControls } from '../../hooks/useGridControls'
import { URLs } from '../../constants'
import { serverSideGridProps } from '../../constants/grid'

import ProductCloneDialog from './ProductCloneDialog'
import { productColumns } from './ProductColumns'
import { ProductBulkActions } from './ProductBulkActions'
import { ProductDashboardActions } from './ProductDashboardActions'


export function Products() {
    const { ids: selectedIds, resetSelectedIds, toggleAllItems, toggleItem } = useMultiSelect()
    const { companyId }: any = useParams()
    const [openAddProduct, setOpenAddProduct] = useState(false)
    const [auditId, setAuditId] = useState('')
    const [editProductId, setEditProductId] = useState('')
    const [cloneProduct, setCloneProduct] = useState<IProduct>()

    const { openSideload } = useContext(SideloadCertificateContext)

    const { filters, setFilters } = useFiltersByUrl()
    const { deSelectAllRows, setGridApi, refreshGrid } = useGridControls()

    const handleRefetch = useCallback(() => {
        refreshGrid()
    }, [refreshGrid])

    const handleRefetchForBulk = useCallback(() => {
        resetSelectedIds()
        deSelectAllRows()
        handleRefetch()
    }, [handleRefetch, resetSelectedIds])

    const setSideLoadProductId = useCallback((productId: string) => {
        openSideload({
            onSuccess: handleRefetch,
            url: getURL(URLs.Certificate.SIDELOAD, { companyId }, `?productId=${productId}`),
        })
    }, [])

    const handleProductFilterChange = useCallback(
        (filter: any) => {
            setFilters({
                name: filter,
            })
        },
        [setFilters]
    )

    const handleFilterSubmit = (filters: any) => {
        setFilters(filters)
    }

    const onSlideoverOpen = useCallback(() => {
        setOpenAddProduct(true)
    }, [])

    const onSlideoverClose = useCallback(() => {
        setOpenAddProduct(false)
        setEditProductId('')
    }, [])

    const onAuditClose = useCallback(() => {
        setAuditId('')
    }, [])

    const handleCloneDialogClose = useCallback(() => {
        setCloneProduct(undefined)
    }, [])

    const handleCancelSelected = () => {
        toggleAllItems(false, selectedIds)
        deSelectAllRows()
        refreshGrid()
    }

    if (isEmpty(companyId)) {
        return <Redirect to="/no-company" />
    }

    const urlOptions = useMemo(() => ({ companyId }), [companyId])

    return (
        <GridPageLayout isPadding>
            <PageHeader
                title={PAGE_TYPES.PRODUCTS}
                primaryAction={onSlideoverOpen}
                onSearch={handleProductFilterChange}
                actionsComponent={<ProductDashboardActions refetch={handleRefetch} />}
                filterList={<PageFilters pageType={PAGE_TYPES.PRODUCTS} />}
                onFilterSubmit={handleFilterSubmit}
            />
            <GridPageLayout>
                <Grid
                    columnDefs={productColumns}
                    context={{
                        companyId,
                        onEditProduct: setEditProductId,
                        onProductClone: setCloneProduct,
                        refetch: handleRefetch,
                        setAuditId,
                        setSideLoadProductId,
                        toggleAllItems,
                        toggleItem,
                    }}
                    entityType={EntityTypeEnum.PRODUCT}
                    filters={filters}
                    onApiAvailable={setGridApi}
                    setFilters={setFilters}
                    url={URLs.Product.GET_ALL}
                    urlOptions={urlOptions}
                    {...serverSideGridProps}
                />
            </GridPageLayout>
            <ProductSlideover
                companyId={companyId}
                allowExisting={false}
                productId={editProductId}
                isSuppliedProduct={false}
                isOpen={openAddProduct || !isEmpty(editProductId)}
                onSaved={handleRefetch}
                onClose={onSlideoverClose}
            />
            <ProductCloneDialog
                companyId={companyId}
                product={cloneProduct}
                open={!isEmpty(cloneProduct)}
                onSaved={handleRefetch}
                onClose={handleCloneDialogClose}
            />
            <AuditSlideover
                companyId={companyId}
                productId={auditId}
                isOpen={!isEmpty(auditId)}
                onClose={onAuditClose}
            />
            {!!selectedIds.length && (
                <MultiSelectNotification
                    count={selectedIds.length}
                    onCancel={handleCancelSelected}
                    right={<ProductBulkActions ids={selectedIds} refetch={handleRefetchForBulk} />}
                    title="Product"
                />
            )}
        </GridPageLayout>
    )
}
