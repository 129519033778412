import React, { useCallback, useMemo, useState } from 'react'
import { Icon, MenuPopover, ConfirmDialog } from '@mondra/ui-components'
import { useProductIsCompleteBulk } from '../../hooks/useProductIsCompleteBulk'
import { IdsType } from '../../hooks/useMultiSelect'


interface IProductBulkActions {
    refetch: () => void
    ids: IdsType
}

export function ProductBulkActions({ ids, refetch }: IProductBulkActions) {
    const [open, setOpen] = useState(false)
    const { update: markAsComplete } = useProductIsCompleteBulk({
        isComplete: true,
        refetch,
    })
    const { saving: markingInComplete, update: markAsInComplete } = useProductIsCompleteBulk({
        isComplete: false,
        refetch: handleRefetch,
    })

    const handleMarkComplete = useCallback(() => {
        markAsComplete(ids)
    }, [ids])

    const handleMarkInCompleteConfirm = useCallback(() => {
        markAsInComplete(ids)
    }, [ids])

    function handleMarkInComplete() {
        setOpen(true)
    }
    function handleClose() {
        setOpen(false)
    }

    function handleRefetch() {
        handleClose()
        refetch()
    }

    const menuItems = useMemo(
        () => [
            {
                id: 'complete',
                onClick: handleMarkComplete,
                value: 'Mark as complete',
            },
            {
                id: 'incomplete',
                onClick: handleMarkInComplete,
                value: 'Mark as incomplete',
            },
        ],
        [handleMarkComplete, handleMarkInComplete]
    )

    return (
        <div className="dark">
            <MenuPopover
                className="text-white"
                menuItems={menuItems}
                trigger={<Icon type="overflowMenuHorizontal" className="text-current" />}
                triggerButtonClass="dark:hover:!bg-gray-100/20 dark:active:!bg-gray-100/50"
            />
            <ConfirmDialog
                onClose={handleClose}
                onConfirm={handleMarkInCompleteConfirm}
                open={open}
                title="Mark all incomplete"
                variant="danger"
                primaryBtnText={markingInComplete ? 'Updating...' : 'Confirm'}
            >
                Are you sure ? About to mark all records as incomplete.
            </ConfirmDialog>
        </div>
    )
}
