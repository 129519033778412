import find from 'lodash/fp/find'
import { EcoImpactIds, IEcoImpact } from '../types/ecoImpacts'


export function findImpactByCode(impactCode: EcoImpactIds, ecoImpacts: IEcoImpact[]) {
    return find({ code: impactCode }, ecoImpacts)
}

export default function findImpact(ecoImpacts: IEcoImpact[] = []) {
    const ghg = findImpactByCode(EcoImpactIds.EIID001, ecoImpacts)
    const water = findImpactByCode(EcoImpactIds.EIID002, ecoImpacts)
    const eutro = findImpactByCode(EcoImpactIds.EIID003, ecoImpacts)
    const bio = findImpactByCode(EcoImpactIds.EIID004, ecoImpacts)

    return {
        bio,
        eutro,
        ghg,
        water,
    }
}

export function findImpactWithStats(ecoImpacts: IEcoImpact[]) {
    const ghg = find({ code: EcoImpactIds.EIID001 }, ecoImpacts) as IEcoImpact
    const water = find({ code: EcoImpactIds.EIID002 }, ecoImpacts) as IEcoImpact
    const eutro = find({ code: EcoImpactIds.EIID003 }, ecoImpacts) as IEcoImpact
    const bio = find({ code: EcoImpactIds.EIID004 }, ecoImpacts) as IEcoImpact

    return {
        bio,
        eutro,
        ghg,
        water,
    }
}
