import classNames from 'classnames'
import React from 'react'
import { ScStageWiseColors } from '../../../constants/colors'
import { IProductNode } from '../../../types/supply-chain-types'
import DataManagedby from './DataManagedBy'


interface IMenuHeader {
    node: IProductNode
    right: React.ReactChild
}

export default function MenuHeader({ node, right }: IMenuHeader) {
    const { companyName, companyId, name, supplierName, type } = node

    return (
        <div className="border-b border-gray-300 rounded-tl-md rounded-tr-md" role="none">
            <div
                className={classNames(
                    'flex items-center justify-between p-4 rounded-tl-md rounded-tr-md',
                    ScStageWiseColors?.[type]?.bg
                )}
            >
                <div className="">
                    <div
                        className="max-w-4xs 4xs truncate text-sm font-bold text-white"
                        title={name}
                    >
                        {name}
                    </div>
                    <div
                        className="max-w-4xs truncate text-sm text-white text-opacity-95"
                        title={supplierName || companyName}
                    >
                        {supplierName || companyName}
                    </div>
                </div>
                <div className="">{right}</div>
            </div>
            <DataManagedby companyId={companyId} companyName={companyName} node={node} />
        </div>
    )
}
