import React from 'react'
import classNames from 'classnames'
import map from 'lodash/fp/map'
import { IClassNameProps } from '../../types/types'
import { NavTab, INavTabOption } from './NavTab'

/**
 * TODO: Upgrade react router version in platform and use this component from library
 */
export interface INavTabsProps extends IClassNameProps {
    /**
     * Defines ariaLabel for nav tag
     */
    ariaLabel?: string
    /**
     * Defines className for 2nd level menu `ul`
     */
    childWrapperClass?: string
    /**
     * Defines gap between nav tabs
     */
    gapClass?: string
    /**
     * Defines height class for navigation
     */
    heightClass?: string
    /**
     * Defines option array to configure nav tabs
     */
    options?: INavTabOption[]
}
export function NavTabs({
    ariaLabel = 'navigation',
    childWrapperClass,
    className,
    gapClass = 'gap-x-6',
    heightClass = 'h-14',
    options,
    ...rest
}: INavTabsProps) {
    return (
        <nav className="flex justify-start items-center" aria-label={ariaLabel} {...rest}>
            <ul className={classNames('flex justify-start items-center', gapClass, className)}>
                {map(
                    option => (
                        <NavTab
                            key={option.to.toString() || option.label.toString()}
                            heightClass={heightClass}
                            childWrapperClass={childWrapperClass}
                            option={option}
                        />
                    ),
                    options
                )}
            </ul>
        </nav>
    )
}
