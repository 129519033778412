import React from 'react'
import isFunction from 'lodash/fp/isFunction'
import { Avatar } from '@mondra/ui-components'
import { ICellRendererParams } from 'ag-grid-community'

import { ISupplier } from '../../types/types'


export interface ISupplierCellProps extends ICellRendererParams {
    data: ISupplier
}

export default function SupplierName({ data: supplier, context }: ISupplierCellProps) {
    const { setEditSupplier } = context
    const handleClick = () => {
        if (isFunction(setEditSupplier)) {
            setEditSupplier(supplier)
        }
    }

    return (
        <div className="flex flex-row items-center">
            <Avatar
                bgColor={supplier.avatarColor || 'bg-gray-500'}
                textColor={supplier.avatarTextColor || 'text-white'}
                name={supplier.name}
                size="sm"
            />
            <span className="mr-4" />
            <div
                className="mdp-link font-medium underline truncate flex flex-row items-center"
                role="button"
                onClick={handleClick}
            >
                {supplier.name}
            </div>
        </div>
    )
}
