import React, { useContext, useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { ICustomEventProps, IProduct, NodeType, SA_TYPE } from '../../types/types'
import slideoverCloseStyles from '../../constants/slideoverCloseStyles'
import {
    ASSESSMENT_DESCRIPTION,
    METHOD_POST,
    METHOD_PUT,
    URLs,
    DEFAULT_SUPPLIER_ID,
    YOUR_SUPPLIER_ID,
} from '../../constants'
import { PRODUCTS } from '../../constants/toastNotifications'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import useFetch from '../../hooks/useFetch'
import { UserContext } from '../../contexts/UserProvider'
import SlideoverAlert from '../../product-overiew/SlideoverAlert'
import getURL from '../../utils/getURL'
import { isYourCompanySupplier } from '../../utils'
import { ISlideoverProps } from '../../types/slideover-types'
import { PageLoader } from '../../components'
import Slideover from '../components/Slideover'
import { ISkuInputRef } from '../../components/SkuIdInput'
import ProductForm from './ProductForm'


const PRODUCT_DATA: any = {
    assessmentDescription: ASSESSMENT_DESCRIPTION,
    bannerImageUrl: process.env.REACT_APP_Certificate_Top_Placeholder,
    imageUrl: process.env.REACT_APP_Certificate_Product_Placeholder,
    ingredientsRequired: true,
    isExclusive: true,
    isPrivateCertificate: true,
    measureUnit: 'g',
    name: '',
    packagingRequired: false,
    processingRequired: false,
    retailRequired: false,
    saleRequired: false,
    saType: SA_TYPE.Product,
    storageRequired: false,
}

const getSaType = (isSuppliedProduct: boolean, saType?: SA_TYPE) =>
    !isSuppliedProduct ? SA_TYPE.Product : saType || SA_TYPE.SimpleIngredient

const formId = 'add-edit-product-form'

interface IProductSlideoverProps extends Omit<ISlideoverProps, 'type'> {
    allowExisting?: boolean
    disableSaType?: boolean
    isSuppliedProduct?: boolean
    onSaved: (product: IProduct) => void
    productId?: string
    saType?: SA_TYPE
}

export default function ProductSlideover({
    allowExisting = false,
    companyId,
    disableSaType,
    isOpen,
    isSuppliedProduct = false,
    onClose,
    onSaved,
    productId,
    saType,
}: IProductSlideoverProps) {
    const skuRef = useRef<ISkuInputRef>(null)
    const defaultSupplierId = isSuppliedProduct ? DEFAULT_SUPPLIER_ID : YOUR_SUPPLIER_ID
    const defaultProduct = {
        ...PRODUCT_DATA,
        saType: getSaType(isSuppliedProduct, saType),
        supplierId: defaultSupplierId,
    }
    const [product, setProduct] = useState<IProduct>(defaultProduct)
    const [errors, setErrors] = useState({})
    const [isExisting, setIsExisting] = useState(false)
    const { showError, showSuccess } = useTopCenterToast()

    const { company } = useContext(UserContext)
    const companyName = company?.name || ''

    const isEdit = !isEmpty(productId)

    const productLabel = isSuppliedProduct ? 'Supplied Product' : 'Product'
    const { data: editProductData, error, loading, refetch } = useFetch({
        autoFetch: isEdit && isOpen,
        url: getURL(URLs.Product.GET_PRIMARY, { companyId, productId }),
    })

    useEffect(() => {
        handleChange({
            saType: getSaType(isSuppliedProduct, saType),
        })
    }, [saType])

    useEffect(() => {
        if (!isEmpty(error)) {
            showError({ description: PRODUCTS.ERROR })
            handleClose()
        }
    }, [error])

    useEffect(() => {
        if (!isEmpty(editProductData)) {
            setProduct(editProductData)
        }
    }, [editProductData])

    const onUpdate = (data: any) => {
        showSuccess({
            description: `${productLabel} ${isEdit ? 'updated' : 'created'} successfully.`,
        })
        onSaved(data)
        handleClose()
    }

    const url = isEdit
        ? getURL(URLs.Product.UPDATE, { companyId, productId })
        : getURL(URLs.Product.ADD, { companyId })

    const { saving, update } = useUpdateFormData({
        method: isEdit ? METHOD_PUT : METHOD_POST,
        onUpdate,
        url,
    })

    const handleChange = (changes: any, reset = false) => {
        setProduct((productState: IProduct) =>
            reset ? { ...defaultProduct, ...changes } : { ...productState, ...changes }
        )
        setErrors({})
        if (reset) {
            setIsExisting(false)
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        const { name, sku, saType, supplierId } = product
        const isValidSku = skuRef?.current?.isValidSku()
        const isYourCompany = isYourCompanySupplier(supplierId)

        if (isEmpty(supplierId) && !isYourCompany) {
            setErrors({
                supplier: 'Supplier name should not be blank',
            })
            return
        }
        if (!isYourCompany && (isEmpty(saType) || saType === SA_TYPE.Product)) {
            setErrors({
                saType: 'Select ingredient Type',
            })
            return
        }
        if (isEmpty(name)) {
            setErrors({
                product: 'Product name should not be blank',
            })
            return
        }
        if (!isExisting && !isValidSku && !isEmpty(sku)) {
            setErrors({
                sku: 'Invalid SKU',
            })
            return
        }
        save()
    }

    function save() {
        if (isExisting && !isEmpty(product.id)) {
            onSaved(product)
            handleClose()
        } else {
            update({
                ...product,
                companyCategoryId:
                    product.supplierId === YOUR_SUPPLIER_ID ? product.companyCategoryId : undefined,
                id: isEdit ? productId : undefined,
                label: undefined,
                saType: isYourCompanySupplier(product.supplierId)
                    ? SA_TYPE.Product
                    : product.saType,
                site: isEdit ? product.site : undefined,
                updatedDate: undefined,
            })
        }
    }

    function handleSetProduct(option: IProduct) {
        if (option && option.id) {
            setErrors({})
            handleChange(option, true)
            refetch(getURL(URLs.Product.GET_PRIMARY, { companyId, productId: option.id }))
            setIsExisting(true)
        }
    }

    function handleClose() {
        setProduct(defaultProduct)
        setErrors({})
        setIsExisting(false)
        onClose && onClose(NodeType.PRODUCT)
    }

    function handleProductNameChange(event: ICustomEventProps) {
        const { name, value } = event.target

        if (isEmpty(value)) {
            handleChange({ id: undefined, [name]: value, sku: '' })
        } else {
            handleChange({ id: undefined, [name]: value })
        }

        if (isExisting) {
            setTimeout(() => {
                skuRef?.current?.validateSku()
            }, 100)
        }
        setIsExisting(false)
    }

    return (
        <Slideover
            wider="max-w-3xl"
            isOpen={isOpen}
            type={isSuppliedProduct ? NodeType.SUPPLIED_PRODUCT : NodeType.PRODUCT}
            title={isEdit ? `Update ${productLabel}` : `Add ${productLabel}`}
            headerProps={{
                iconProps: slideoverCloseStyles as any,
            }}
            formId={formId}
            saving={saving}
            disableSave={(!allowExisting && isExisting) || loading}
            onClose={handleClose}
            saveButtonText={
                saving
                    ? isEdit
                        ? 'Updating...'
                        : 'Creating...'
                    : isExisting
                        ? `Add ${productLabel}`
                        : isEdit
                            ? `Update ${productLabel}`
                            : `Create ${productLabel}`
            }
            footerChildren={
                !allowExisting
                && isExisting && (
                    <SlideoverAlert
                        title="Product already exists, can not save"
                        description="To add a new product you must change the name to proceed."
                    />
                )
            }
        >
            {loading && <PageLoader centered className="mt-4 absolute top-20 left-1/2 bg-white" />}
            {isOpen && (
                <ProductForm
                    ref={skuRef}
                    formId={formId}
                    editMode={isEdit}
                    readOnly={isExisting}
                    errors={errors}
                    companyId={companyId}
                    companyName={companyName}
                    autoFetch={isOpen}
                    product={product}
                    disableSaType={disableSaType}
                    includeYourCompany={!isSuppliedProduct}
                    isSuppliedProduct={isSuppliedProduct}
                    onChange={handleChange}
                    onProductNameChange={handleProductNameChange}
                    onProductSelect={handleSetProduct}
                    onSubmit={handleSubmit}
                />
            )}
        </Slideover>
    )
}
