import { Decimal } from 'decimal.js'


const numberFormatter = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 1,
})

export const formatNumber = (value: any) => {
    return numberFormatter.format(value)
}

/**
 * Decimals do not contain trailing fraction-digit zeros, and toSignificantsDigits returns a Decimal,
 * so the answer to your question is: It can't be done.
 * @param number
 * @returns string with 3 significant digits
 */
export const significantFigureFormatter = (number: any = 0) => {
    if (!number) {
        return 0
    }
    Decimal.set({ precision: 5, rounding: 4 })
    const x = new Decimal(number)
    return x.toSignificantDigits(3, Decimal.ROUND_HALF_UP).toString()
}

/**
 * Decimals do not contain trailing fraction-digit zeros, and toSignificantsDigits returns a Decimal,
 * so the answer to your question is: It can't be done. so need to use Precision method
 * issue#https://github.com/MikeMcl/decimal.js/issues/170
 * @param number
 * @returns string with 2 significant digits as default
 */
export const numberFormatterWithPrecision = (number: any, significantDigits = 2) => {
    if (!number) {
        return 0
    }
    Decimal.set({ precision: 5, rounding: 4 })
    const x = new Decimal(number)
    const numWord = String(number)
    const numBeforeDec = Number.isInteger(number) ? numWord : numWord.split('.')[0]
    if (numBeforeDec && numBeforeDec.length > 2) {
        return x.toSignificantDigits(significantDigits).toString()
    }
    return x.toPrecision(significantDigits)
}

export function formatPercentage(percentage: any) {
    const x = new Decimal(percentage)

    return x.isInt() ? x.toNumber() : x.toFixed(1)
}

export function parseNumber(number: string | number | undefined) {
    const num = Number(number || 0)
    if (!isFinite(num)) {
        return 0
    }
    return num
}

export function round(number: string | number | undefined, precision = 0) {
    const num = parseNumber(number)
    if (precision === 0) {
        return Math.round(num)
    }
    const dec = Math.pow(10, precision)
    return Math.round((num + Number.EPSILON) * dec) / dec
}
