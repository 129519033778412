import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
    useParams,
    Link,
    Redirect,
    Switch,
    Route,
    useRouteMatch,
    useLocation,
} from 'react-router-dom'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import isEmpty from 'lodash/fp/isEmpty'

import useProduct from '../../hooks/useProduct'
import useProductDetails from '../../hooks/useProductDetails'
import { UserContext } from '../../contexts/UserProvider'
import useQuery from '../../hooks/useQuery'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { NavTabs } from '../../components/NavTabs/NavTabs'
import { IParamProps, ProductDetailTypes, IProduct, NodeType, SA_TYPE } from '../../types/types'
import {
    Toolbar,
    Breadcrumbs,
    Breadcrumb,
    HomeBreadcrumb,
    PageLoader,
    NotesButton,
} from '../../components'
import { URLs } from '../../constants'
import { PRODUCTS } from '../../constants/toastNotifications'
import getURL from '../../utils/getURL'
import SlideoverProvider from '../../contexts/SlideoverProvider'
import ProductSettingProvider from '../../contexts/ProductSettingProvider'
import LatestCertCard from '../../product-overiew/LatestCertCard'
import SupplyChainSummary from '../../product-overiew/SupplyChainSummary'
import CertHistoryCards from '../../product-overiew/CertHistoryCards'
import CertSettings from '../../product-overiew/CertSettings'
import { useRecentViewed } from '../../hooks/useRecentViewed'
import DetailsCard from './DetailsCard'


const routes = {
    certHistory: 'certification-history',
    certSettings: 'certification-settings',
    default: 'overview',
    impacts: 'impacts',
}
const defaultProduct = {} as IProduct
const nonProducts = [SA_TYPE.Produce, SA_TYPE.SpecialIngredient]

export default function ProductOverview() {
    const [product, setProduct] = useState<IProduct>(defaultProduct)
    const { company, currentUser } = useContext(UserContext)
    const companyId = getOr('', 'id', company)
    const { updateRecentItems } = useRecentViewed({ companyId, userId: currentUser?.id })
    const { showSuccess } = useTopCenterToast()
    const { id = '' }: IParamProps = useParams()
    const { search } = useLocation()
    const { path: parentPath, url } = useRouteMatch()
    const queryParams = useQuery()
    const supplierId = queryParams.get('supplierId')

    if (isEmpty(companyId)) {
        return <Redirect to="/no-company" />
    }

    const onUpdate = () => {
        showSuccess({ description: PRODUCTS.UPDATE })
        refetch()
    }

    const onProductUpdate = (changes: Partial<IProduct>) => {
        setProduct((p: IProduct) => ({
            ...p,
            ...changes,
        }))
    }

    const navOptions = useMemo(() => {
        return [
            {
                label: 'Product Overview',
                search,
                to: `${url}/${routes.default}`,
            },
            {
                label: 'Certification History',
                search,
                to: `${url}/${routes.certHistory}`,
            },
            {
                label: 'Certification Settings',
                search,
                to: `${url}/${routes.certSettings}`,
            },
        ]
    }, [url, search])

    const { loading, product: productData, refetch, saving, updateProduct } = useProduct({
        autoFetch: true,
        companyId,
        onUpdate,
        productId: id,
        supplierId,
    })

    const { details: certificate } = useProductDetails({
        companyId,
        productId: id,
        supplierId,
        type: ProductDetailTypes.CERTIFICATE_CURRENT,
    })

    const { details: productMetadata, refetch: refetchMetadata } = useProductDetails({
        companyId,
        productId: id,
        type: ProductDetailTypes.METADATA,
    })

    useEffect(() => {
        updateRecentItems({
            nodeId: id,
            nodeType: SA_TYPE.Product,
        })
    }, [])

    useEffect(() => {
        let changes = {}
        if (!isEmpty(productData)) {
            changes = { ...productData }
        }
        if (!isEmpty(productMetadata)) {
            changes = { ...changes, productMetadata }
        }
        if (!isEmpty(changes)) {
            setProduct((p: IProduct) => ({ ...p, ...changes }))
        }
    }, [productData, productMetadata])

    const onRefetch = () => {
        refetch()
        refetchMetadata()
    }

    if (nonProducts.includes(product?.saType)) {
        return <Redirect to={getURL(URLs.Pages.SUPPLIED_PRODUCTS, { companyId })} />
    }

    const isSuppliedProduct = !isEmpty(product?.supplierId)
    const isReadOnlyMode = loading || isEmpty(product) || product.isProductLocked
    return (
        <SlideoverProvider companyId={companyId} product={product} onSaved={onRefetch}>
            <ProductSettingProvider readOnly={isReadOnlyMode}>
                <div className="flex-grow px-6 pt-8 pb-4">
                    <Toolbar
                        breadcrumb={
                            <Breadcrumbs>
                                <HomeBreadcrumb />
                                <Breadcrumb>
                                    <Link
                                        to={getURL(
                                            isSuppliedProduct
                                                ? URLs.Pages.SUPPLIED_PRODUCTS
                                                : URLs.Pages.PRODUCTS,
                                            { companyId }
                                        )}
                                    >
                                        {isSuppliedProduct ? 'Supplied Products' : 'Products'}
                                    </Link>
                                </Breadcrumb>
                                <Breadcrumb>
                                    {loading && isEmpty(product.name) ? 'Loading...' : product.name}
                                </Breadcrumb>
                            </Breadcrumbs>
                        }
                    />
                    <div className="w-full my-4 relative">
                        <div className="relative">
                            <NavTabs gapClass="gap-0" options={navOptions} />
                            <NotesButton
                                className="!absolute right-0 top-2"
                                stage={NodeType.PRODUCT}
                                productId={id}
                            />
                        </div>
                        <Switch>
                            <Route
                                exact
                                path={[
                                    `${parentPath}/${routes.default}`,
                                    `${parentPath}/`,
                                    `${parentPath}/${routes.default}/edit`,
                                ]}
                            >
                                <div className="grid grid-cols-12 gap-4 py-4">
                                    <div className="col-span-12 lg:col-span-6">
                                        <DetailsCard
                                            companyId={companyId}
                                            isSuppliedProduct={isSuppliedProduct}
                                            onProductUpdate={onProductUpdate}
                                            product={product}
                                            loading={loading}
                                        />
                                    </div>
                                    <div className="col-span-12 lg:col-span-6">
                                        <LatestCertCard
                                            companyId={companyId}
                                            refetchProduct={refetch}
                                            cert={head(certificate) || ({} as any)}
                                            product={product}
                                        />
                                    </div>

                                    <div className="col-span-12">
                                        <SupplyChainSummary
                                            companyId={companyId}
                                            productId={id}
                                            product={product}
                                        />
                                    </div>
                                </div>
                            </Route>
                            <Route exact path={`${parentPath}/${routes.certHistory}`}>
                                <CertHistoryCards
                                    productId={id}
                                    companyId={companyId}
                                    supplierId={supplierId}
                                />
                            </Route>
                            <Route exact path={`${parentPath}/${routes.certSettings}`}>
                                <CertSettings
                                    product={product}
                                    companyId={companyId}
                                    updateProduct={updateProduct}
                                />
                            </Route>
                        </Switch>
                        <PageLoader
                            show={saving}
                            className="bg-white p-4 fixed left-1/2 top-80"
                            sizeClass="w-32 h-32"
                        />
                    </div>
                </div>
            </ProductSettingProvider>
        </SlideoverProvider>
    )
}
