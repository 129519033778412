export interface IColorProps {
    color: string
    text: string
}

const colors: IColorProps[] = [
    { color: 'bg-avatar-primary-0', text: 'text-avatar-gray-0' },
    { color: 'bg-avatar-primary-1', text: 'text-avatar-gray-1' },
    { color: 'bg-avatar-primary-2', text: 'text-avatar-gray-2' },
    { color: 'bg-avatar-primary-3', text: 'text-avatar-gray-3' },
    { color: 'bg-avatar-primary-4', text: 'text-avatar-gray-4' },
    { color: 'bg-avatar-primary-5', text: 'text-avatar-gray-5' },
    { color: 'bg-avatar-primary-6', text: 'text-avatar-gray-6' },
    { color: 'bg-avatar-primary-7', text: 'text-avatar-gray-7' },
    { color: 'bg-avatar-primary-8', text: 'text-avatar-gray-8' },
    { color: 'bg-avatar-primary-9', text: 'text-avatar-gray-9' },
    { color: 'bg-avatar-primary-10', text: 'text-avatar-gray-10' },
    { color: 'bg-avatar-primary-11', text: 'text-avatar-gray-11' },
    { color: 'bg-avatar-primary-12', text: 'text-avatar-gray-12' },
    { color: 'bg-avatar-primary-13', text: 'text-avatar-gray-13' },
    { color: 'bg-avatar-primary-14', text: 'text-avatar-gray-14' },
    { color: 'bg-avatar-primary-15', text: 'text-avatar-gray-15' },
    { color: 'bg-avatar-primary-16', text: 'text-avatar-gray-16' },
    { color: 'bg-avatar-primary-17', text: 'text-avatar-gray-17' },
    { color: 'bg-avatar-primary-18', text: 'text-avatar-gray-18' },
    { color: 'bg-avatar-primary-19', text: 'text-avatar-gray-19' },
    { color: 'bg-avatar-primary-20', text: 'text-avatar-gray-20' },
    { color: 'bg-avatar-primary-21', text: 'text-avatar-gray-21' },
    { color: 'bg-avatar-primary-22', text: 'text-avatar-gray-22' },
    { color: 'bg-avatar-primary-23', text: 'text-avatar-gray-23' },
]

export function getDefaultColor(color?: string) {
    return colors.find((c: any) => c.color === color) || colors[Math.floor(Math.random() * 24)]
}
