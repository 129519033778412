import React, { useState } from 'react'
import { IChildrenProps } from '../types/types'


interface ISlideoverCountContext {
    addLayer: () => void
    removeLayer: () => void
    totalLayers: number
}

export const SlideoverCountContext = React.createContext<ISlideoverCountContext>({
    addLayer: () => null,
    removeLayer: () => null,
    totalLayers: 0,
})

export default function SlideoverCountProvider({ children }: IChildrenProps) {
    const [totalLayers, setTotalLayers] = useState(0)

    const addLayer = () => {
        setTotalLayers(t => t + 1)
    }

    const removeLayer = () => {
        setTotalLayers(t => (t > 0 ? t - 1 : 0))
    }

    return (
        <SlideoverCountContext.Provider value={{ addLayer, removeLayer, totalLayers }}>
            {children}
        </SlideoverCountContext.Provider>
    )
}
