import * as React from 'react'
import take from 'lodash/fp/take'
import upperFirst from 'lodash/fp/upperFirst'
import getOr from 'lodash/fp/getOr'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import some from 'lodash/fp/some'
import identity from 'lodash/fp/identity'
import { IAddress, ICustomEventProps, ISite, IString, ISupplier, SA_TYPE } from '../types/types'
import { MAX_DEC, UNKNOWN, YOUR_SUPPLIER_ID } from '../constants'


export const getInitials = (name = '') => {
    if (isEmpty(name)) {
        return ''
    }
    if (name.trim().split(' ').length >= 2) {
        return take(2, name.match(/\b\w/g)).join('').toUpperCase()
    }

    return upperFirst(name.substr(0, 2))
}

export const randomColor = () => '#' + ((Math.random() * 0xffffff) << 0).toString(16)

export function getHSLAsString(hsl: any) {
    return `hsl(${hsl.h}, ${hsl.s * 100}%, ${hsl.l * 100}%)`
}

const hslExp: any = /hsl\((\d+(?:\.\d+)?),\s*(\d+(?:\.\d+)?)%,\s*(\d+(?:\.\d+)?)%\)/

export function parseHslString(hsl: string) {
    const res = hsl ? hslExp.exec(hsl).slice(1) : [0, 0, 0]

    return {
        h: Number(res[0]),
        l: Number(res[2] / 100),
        s: Number(res[1] / 100),
    }
}

export function cloneValidElement<Props>(
    element: React.ReactElement<Props> | React.ReactNode,
    props?: Partial<Props> & React.Attributes,
    ...children: React.ReactNode[]
): React.ReactElement<Props> | React.ReactNode {
    return React.isValidElement(element) ? React.cloneElement(element, props, ...children) : element
}

export function getComponentImpacts({ components, selectedId }: any) {
    return getOr([], 'ecoImpacts', find({ certificateId: selectedId }, components))
}

export const isUnknownSupplier = (supplier: ISupplier) => supplier.displayId === UNKNOWN

export const isWaterSplIngredient = (node: { isWater?: boolean }) => {
    return node?.isWater
}

const numberFormatter = new Intl.NumberFormat('en-UK', {
    maximumFractionDigits: 0,
})

export function formatNumberWithFractions(number: number) {
    return numberFormatter.format(number)
}

export function buildAddress(address: IAddress, includeCountry = true) {
    const addr = []
    if (!isEmpty(address)) {
        if (address.streetAddress) {
            addr.push(address.streetAddress)
        }
        if (address.city) {
            addr.push(address.city)
        }
        if (address.state) {
            if (address.pinCode) {
                addr.push(`${address.state} ${address.pinCode}`)
            } else {
                addr.push(address.state)
            }
        }
        if (includeCountry && address.country) {
            addr.push(address.country)
        }
    }
    return addr.join(', ')
}

export function isValidSite(site?: ISite) {
    const { address, id } = site || {}
    return (
        !isEmpty(id) && !isEmpty(address) && !isEmpty(address?.id) && !isEmpty(address?.countryCode)
    )
}

export function isValidEmail(email: string) {
    const reg = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    return reg.test(String(email).toLowerCase())
}

export function isValidWebsite(website: string) {
    const reg = new RegExp(
        /^(http(s)?:\/\/)(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    )
    return reg.test(String(website).toLowerCase())
}

export function isValidNumber(number: any) {
    const reg = new RegExp(/^\d+(\.\d+)?$/)
    return reg.test(number)
}

export function isValidNumberInput(value: any) {
    return value !== ''
}

export function filterUniq(item: any, index: number, self: any[]) {
    return self.indexOf(item) === index
}

export function evaluateInputValue(event: ICustomEventProps, precision = MAX_DEC) {
    const { type, value } = event.target
    if (value === '' || value === undefined) {
        return ''
    }
    if (type === 'number') {
        if (value < 0) {
            return 0
        } else {
            const decIndex = value.indexOf('.')
            if (decIndex != -1) {
                return value.substr(0, decIndex + precision + 1)
            }
        }
    }
    return value
}

export function hasError(error: any) {
    return some(identity, error)
}

export function setValidationError(
    value: any,
    name: string,
    error: any,
    setError: React.Dispatch<React.SetStateAction<any>>
) {
    if (!isValidNumberInput(value)) {
        setError((e: any) => ({
            ...e,
            [name]: true,
        }))
    } else if (error?.[name]) {
        setError((e: any) => ({
            ...e,
            [name]: false,
        }))
    }
}

export function getItemAppearProps(
    highlightedIndex: number,
    index: number,
    option: any,
    selected: any
) {
    return {
        isHighlighted: highlightedIndex === index,
        isSelected:
            (selected?.id && selected.id === option.id)
            || (selected?.value && selected.value === option.value),
    }
}

export function padTensZero(value: string | number) {
    if (Number(value) === 0) {
        return 0
    }
    return Number(value) <= 9 ? `0${value}` : value
}

const suppliedProductTypes: SA_TYPE[] = [
    SA_TYPE.Product,
    SA_TYPE.ComplexIngredient,
    SA_TYPE.SimpleIngredient,
]

export function isSuppliedProductType(type?: SA_TYPE) {
    if (!type) return false
    return suppliedProductTypes.includes(type)
}

export function isYourCompanySupplier(supplierId: IString) {
    return supplierId === YOUR_SUPPLIER_ID
}

export function insertSpaces(str: string) {
    return str.replace(/([A-Z])/g, ' $1').trim()
}
