import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'


const LightTooltip: any = withStyles(() => ({
    arrow: {
        color: 'white',
    },
    tooltip: {
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
        padding: 0,
    },
}))(Tooltip)

export default LightTooltip
