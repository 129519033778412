import React, { useState } from 'react'

import { Tabs } from '@mondra/ui-components'
import { ISlideoverProps } from '../../types/slideover-types'
import Slideover from '../components/Slideover'
import { NodeType, NOTE_TYPE } from '../../types/types'
import { IIdentityUser } from '../../contexts/UserProvider'
import { useCountsFetch } from '../../hooks/useCountsFetch'
import NotesForm from './NotesForm'
import { HotspotsAndInsightsForm } from './HotspotsAndInsightsForm'
import { TABS_INDEX, TYPES_INDEX } from './types'
import { TabsCountLabel } from './TabsCountLabel'


type ICounts = {
    hotspotsCount: number
    insightsCount: number
    notesCount: number
}

export interface INotesSlideoverProps extends Omit<ISlideoverProps, 'onSaved' | 'type'> {
    onSaved?: () => void
    productId: string
    refetch?: () => void
    stage: NodeType
    counts: ICounts
    tabType: any
    users: IIdentityUser[]
    test?: any
}

export default function NotesSlideover({
    tabType,
    companyId,
    isOpen,
    level,
    onClose,
    productId,
    refetch: refetchParent,
    stage,
}: INotesSlideoverProps) {
    const [currentTab, setCurrentTab] = useState(tabType)

    const { refetchCounts, counts } = useCountsFetch({
        productId,
        stage,
    })

    const handleClose = () => {
        onClose && onClose(NodeType.NOTE)
    }

    const refetchCount = () => {
        refetchCounts()
        refetchParent && refetchParent()
    }

    const tabsData = [
        {
            id: '1',
            label: <TabsCountLabel title="Notes" count={counts.notesCount} />,
            panel: (
                <NotesForm
                    companyId={companyId}
                    productId={productId}
                    refetchParent={refetchCount}
                    stage={stage}
                />
            ),
        },
        {
            id: '2',
            label: <TabsCountLabel title="Hotspots" count={counts.hotspotsCount} />,
            panel: (
                <HotspotsAndInsightsForm
                    companyId={companyId}
                    productId={productId}
                    refetchParent={refetchCount}
                    stage={stage}
                    noteType={NOTE_TYPE.HOTSPOT}
                />
            ),
        },
        {
            id: '3',
            label: <TabsCountLabel title="Insights" count={counts.insightsCount} />,
            panel: (
                <HotspotsAndInsightsForm
                    companyId={companyId}
                    productId={productId}
                    refetchParent={refetchCount}
                    stage={stage}
                    noteType={NOTE_TYPE.INSIGHT}
                />
            ),
        },
    ]

    const handleOnChange = (index: number) => {
        setCurrentTab(TABS_INDEX[index])
    }

    return (
        <Slideover
            wider="w-[500px]"
            isOpen={isOpen}
            level={level}
            headerProps={{
                className: 'border-b border-gray-300',
                hideShadow: true,
            }}
            formId="add-edit-site-form"
            onClose={handleClose}
            type={currentTab}
            showFooter={false}
            title={`${currentTab}s`}
            contentClassName="flex flex-col"
        >
            <Tabs
                panelsClass="flex flex-col flex-1 [&>*]:flex-1"
                className="flex-1"
                tabs={tabsData}
                onChange={handleOnChange}
                selectedIndex={TYPES_INDEX[currentTab]}
            />
        </Slideover>
    )
}
