import memoize from 'lodash/fp/memoize'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import isEmpty from 'lodash/fp/isEmpty'
import { ASC, URLs } from '../constants'
import useFetch from '../hooks/useFetch'
import { ICountry } from '../types/types'


const parse = memoize(
    flow(
        filter((c: ICountry) => !isEmpty(c.isoAlpha3)),
        map((c: ICountry) => ({
            ...c,
            label: c.countryName,
            value: c.isoAlpha3,
        }))
    )
)

export default function useCountries({ autoFetch = true }: any) {
    const { data, refetch, loading } = useFetch({
        autoFetch: autoFetch,
        cache: true,
        errMessage: 'Failed to fetch countries',
        url: `${URLs.Country.GET_ALL}?Order=countryName;${ASC}`,
    })

    return {
        countries: parse(data?.data) as ICountry[],
        loading,
        refetch,
    }
}
