import React, { useCallback, useState } from 'react'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import { mapWithIndex } from '../utils/lodash-convert'
import ComponentSlideover from '../slideovers/component-slideover'
import SiteResourcesSlideover from '../slideovers/site-resources/SiteResourcesSlideover'
import SiteSlideover from '../slideovers/site/SiteSlideover'
import { IChildrenProps, IProduct, NodeType } from '../types/types'
import IngredientSlideover from '../slideovers/ingredient/IngredientSlideover'
import TransportSlideover from '../slideovers/transport'
import SalesTransportSlideover from '../slideovers/transport/SalesTransportSlideover'
import ProductSlideover from '../slideovers/product/ProductSlideover'
import ProduceSlideover from '../slideovers/produce/ProduceSlideover'
import NotesSlideover from '../slideovers/notes/NotesSlideover'
import SpecialIngredient from '../slideovers/special-ingredient/SpecialIngredientSlideover'


interface ISlideoverContext {
    openSlideover: (type: NodeType, props?: any) => void
}

export const SlideoverContext = React.createContext<ISlideoverContext>({
    openSlideover: () => null,
})

interface IOpenedSlideovers {
    open: boolean
    props?: any
    type: NodeType
}

interface ISlideoverProvider {
    companyId: string
    onSaved?: (data?: any) => void
    product?: IProduct
    productId?: string
}

export default function SlideoverProvider({
    children,
    companyId,
    onSaved,
    product,
}: ISlideoverProvider & IChildrenProps) {
    const [slideovers, setSlideovers] = useState<IOpenedSlideovers[]>([])

    const openSlideover = useCallback((type: NodeType, props?: any) => {
        setSlideovers(s => [
            ...s,
            {
                open: true,
                props,
                type,
            },
        ])
    }, [])

    const handleClose = useCallback((type: NodeType) => {
        setSlideovers(
            map(s => {
                if (s.type === type) {
                    return {
                        ...s,
                        open: false,
                    }
                }
                return s
            })
        )
        setTimeout(() => {
            setSlideovers(filter(s => s.open || s.type !== type))
        }, 1000)
    }, [])

    const slideoverProps = {
        companyId,
        onClose: handleClose,
        onSaved,
        product,
    }

    return (
        <SlideoverContext.Provider value={{ openSlideover }}>
            {children}
            {mapWithIndex(
                (s: IOpenedSlideovers, index: number) => (
                    <SlideoverComponent
                        key={index}
                        index={index}
                        slide={s}
                        slideoverProps={slideoverProps}
                    />
                ),
                slideovers
            )}
        </SlideoverContext.Provider>
    )
}

interface ISlideoverCompoent {
    index: number
    slide: IOpenedSlideovers
    slideoverProps: any
}

function SlideoverComponent({ index, slide, slideoverProps }: ISlideoverCompoent) {
    return (
        <>
            {slide.type === NodeType.RECIPE && (
                <ComponentSlideover
                    type={NodeType.RECIPE}
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.PROCESSING && (
                <ComponentSlideover
                    type={NodeType.PROCESSING}
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.PACKAGING && (
                <ComponentSlideover
                    type={NodeType.PACKAGING}
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.STORAGE && (
                <ComponentSlideover
                    type={NodeType.STORAGE}
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.SALE && (
                <ComponentSlideover
                    type={NodeType.SALE}
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.INGREDIENT && (
                <IngredientSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {(slide.type === NodeType.PRODUCT
                || slide.type === NodeType.COMPLEX_INGREDIENT
                || slide.type === NodeType.SIMPLE_INGREDIENT) && (
                <ProductSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.PRODUCE && (
                <ProduceSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.SPECIAL_INGREDIENT && (
                <SpecialIngredient
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.TRANSPORT && (
                <TransportSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.SALES_TRANSPORT && (
                <SalesTransportSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.SITE_RESOURCE && (
                <SiteResourcesSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {slide.type === NodeType.SITE && (
                <SiteSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
            {(slide.type === NodeType.NOTE
                || slide.type === NodeType.HOTSPOT
                || slide.type === NodeType.INSIGHT) && (
                <NotesSlideover
                    isOpen={slide.open}
                    level={index + 1}
                    {...slideoverProps}
                    {...slide.props}
                />
            )}
        </>
    )
}
