import React, { useEffect, useMemo } from 'react'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import { useParams } from 'react-router-dom'
import { AutocompleteLookup, useLookupReducer } from '@mondra/ui-components'
import {
    IParamProps,
    IString,
    ICustomEventProps,
    LookupTypeEnum,
    EntityTypeEnum,
    SA_TYPE,
} from '../../types/types'
import { PAGE_SIZE } from '../../constants'
import { useLookupApi } from '../../hooks/useLookupApi'
import { isYourCompanySupplier } from '../../utils'


const debounceTime = 500
const labelProps = {
    containerClass: 'col-span-1',
    helpText: 'e.g. Vegan burger or choose from existing',
}

const defaultFilters = {
    entityType: EntityTypeEnum.PRODUCT,
    saType: SA_TYPE.SimpleIngredient,
}

interface IProductAutocompleteProps {
    autoFetch: boolean
    onChange: (event: ICustomEventProps) => void
    onSelect: (selectedItem: any) => void
    productId: IString
    supplierId: IString
    invalid?: boolean
    saType?: SA_TYPE
}

export default function ProductAutocomplete({
    autoFetch,
    productId,
    supplierId,
    onChange,
    onSelect,
    invalid,
    saType,
    ...rest
}: IProductAutocompleteProps) {
    const { addMoreItems, onLoadMore, onSearchFetch, resetState, state } = useLookupReducer()

    useEffect(() => {
        resetState()
    }, [supplierId])

    const { companyId = '' }: IParamProps = useParams()
    const isYourProduct = isYourCompanySupplier(supplierId)

    const filters = useMemo(() => {
        if (isYourProduct) {
            return {
                ...defaultFilters,
                name: state.searchText,
                saType,
            }
        }
        return {
            ...defaultFilters,
            name: state.searchText,
            saType,
            supplierId,
        }
    }, [saType, supplierId, state.searchText, isYourProduct])

    const { loading, data: products, refetch } = useLookupApi({
        autoFetch: autoFetch && !isEmpty(saType) && (isYourProduct || !isEmpty(supplierId)),
        companyId,
        filters,
        lookupType: isYourProduct ? LookupTypeEnum.PRODUCTS : LookupTypeEnum.SUPPLIED_PRODUCTS,
        page: state.page,
        pageSize: PAGE_SIZE,
    })

    useEffect(() => {
        if (products.length === 0) {
            resetState()
        } else {
            addMoreItems(products, { hasMore: products.length === PAGE_SIZE })
        }
    }, [products])

    const selected = useMemo(() => {
        return find(i => i.id === productId, state.items)
    }, [productId, state.items])

    function onProductSelect(p: any) {
        onSelect({ ...p, isValidSku: true, supplierId })
    }

    function onInputChange(inputValue?: string) {
        onChange({ target: { name: 'name', value: inputValue } })
        if (isEmpty(inputValue)) {
            setTimeout(() => {
                resetState()
                refetch()
            }, debounceTime + 200)
        }
    }

    return (
        <AutocompleteLookup
            containerClass="grid grid-cols-2 gap-x-4 z-1"
            placeholder={loading ? 'Loading products...' : 'Search with product name'}
            labelText="Product name"
            debounceTime={debounceTime}
            labelProps={labelProps}
            onSearchFetch={onSearchFetch}
            onChange={onProductSelect}
            onInputValueChange={onInputChange}
            onLoadMore={onLoadMore}
            loading={loading}
            hasMore={state.hasMore}
            options={state.items}
            selected={selected}
            invalid={invalid}
            {...rest}
        />
    )
}
