import React, { useContext } from 'react'
import { Button } from '@mondra/ui-components'
import { AuthContext } from '../../contexts/AuthProvider'


export default function ErrorPage() {
    const { login } = useContext(AuthContext)
    return (
        <div className="flex flex-col items-center justify-center min-h-scroll">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Oops! something went wrong.
            </h2>
            <Button variant="primary" onClick={login}>
                Try again
            </Button>
        </div>
    )
}
