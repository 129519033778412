import React, { useCallback, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { ICellRendererParams } from 'ag-grid-community'

import { CopyToClipboard, Menu, MenuList, MenuItem, MoreMenuButton } from '../../components'
import ArchiveProduct from '../../components/ArchiveProduct'
import { UserContext } from '../../contexts/UserProvider'
import { isOwner, isMondraAdmin } from '../../hooks/userRoles'
import { getProductPageUrl } from '../../utils/getURL'
import { SA_TYPE } from '../../types/types'


export default function SuppliedProductsActions({ data: node, context }: ICellRendererParams) {
    const suppliedProductId = node.id
    const {
        onProductClone,
        refetch,
        setEditProduceId,
        setEditSpecialIngredientId,
        setSideLoadProductId,
    } = context
    const { company } = useContext(UserContext)
    const { id: companyId } = company
    const { currentUser } = useContext(UserContext)
    const canShowArchived = isOwner(currentUser) || isMondraAdmin(currentUser)
    const isProduce = node.saType === SA_TYPE.Produce
    const isSplIngredient = node.saType === SA_TYPE.SpecialIngredient

    const onEdit = useCallback(() => {
        if (isSplIngredient) {
            setEditSpecialIngredientId(suppliedProductId)
        } else {
            setEditProduceId(suppliedProductId)
        }
    }, [isSplIngredient, suppliedProductId])

    const handleSideLoadCertificate = useCallback(() => {
        setSideLoadProductId(suppliedProductId)
    }, [suppliedProductId])

    const productMenuItem = () => {
        if (node.isProductLocked) return null
        if (isProduce) return <MenuItem onSelect={onEdit}>Edit Produce</MenuItem>
        if (isSplIngredient) return <MenuItem onSelect={onEdit}>Edit Special Ingredient</MenuItem>
        return (
            <MenuItem
                as={NavLink}
                to={getProductPageUrl({
                    companyId,
                    isSuppliedProduct: true,
                    productId: suppliedProductId,
                })}
            >
                View product
            </MenuItem>
        )
    }

    const handleProductClone = () => {
        onProductClone && onProductClone(node)
    }

    return (
        <>
            <Menu>
                <MoreMenuButton />
                <MenuList
                    style={{ right: 0, width: 'max-content' }}
                    ariaLabelledby={isProduce ? 'Produce action menu' : 'Product action menu'}
                >
                    {canShowArchived && !node.isProductLocked ? (
                        <ArchiveProduct
                            apiNode="products"
                            id={suppliedProductId}
                            name={node.name}
                            companyId={companyId}
                            isArchived={node.isArchived}
                            refresh={refetch}
                        />
                    ) : null}
                    {productMenuItem()}
                    {!isProduce && !isSplIngredient && (
                        <MenuItem onSelect={handleProductClone}>Clone</MenuItem>
                    )}
                    {isMondraAdmin(currentUser) && !node.isProductLocked && (
                        <MenuItem onSelect={handleSideLoadCertificate}>
                            Sideload certificate
                        </MenuItem>
                    )}
                    <MenuItem>
                        <CopyToClipboard
                            infoText="Supplied product id copied"
                            text={suppliedProductId}
                        >
                            Copy supplied product id
                        </CopyToClipboard>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}
