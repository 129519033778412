import React from 'react'
import classNames from 'classnames'


interface IGridPageLayoutProps extends React.ComponentPropsWithoutRef<'div'> {
    isPadding?: boolean
}

export function GridPageLayout({ className, children, isPadding }: IGridPageLayoutProps) {
    return (
        <div
            className={classNames(
                'relative flex-grow flex flex-col h-full',
                isPadding && 'px-6 pt-8 pb-4',
                className
            )}
        >
            {children}
        </div>
    )
}
