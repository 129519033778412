import { IProductSizeOption } from '../types/ecoImpacts'
import { NodeState, SA_TYPE } from '../types/types'


export const APP_FEATURES_ENV = ['Local', 'Dev', 'Staging', 'Prod']
export { default as URLs } from './urls'
export const ASC = 'asc'
export const DESC = 'desc'
export const IS_INGREDIENT = 'isIngredient'
export const PRODUCT_NAME = 'productName'
export const COMPANY_NAME = 'companyName'
export const ECO_SCORE = 'ecoImpactMetascore'
export const UPDATED_AT = 'updatedAt'
export const UPDATED_DATE = 'updatedDate'
export const SA_STATE = 'saState'
export const TYPE_DEFAULT = 'Default'
export const TYPE_OTHER = 'Other'
export const TYPE_APPROVED = 'approved'
export const TYPE_CERTIFIED = 'certified'
export const TYPE_COMPLETED = 'completed'
export const TYPE_PROGRESS = 'progress'
export const TYPE_REVIEW = 'review'
export const MULTIPLE = 'multiple'
export const PAGE_SIZE = 25
export const PAGE_MAX_SIZE = 100
export const PAGE_START = 1
export const STATE_ARCHIVE = 'Archive'
export const STATE_UNARCHIVE = 'Unarchive'
export const HOME_URL = '/company'
export const METHOD_GET = 'GET'
export const METHOD_POST = 'POST'
export const METHOD_PUT = 'PUT'
export const METHOD_DELETE = 'DELETE'
export const METHOD_PATCH = 'PATCH'
export const UNKNOWN = 'unknown'
export const YOUR_COMPANY = 'Your company'
export const YOUR_SUPPLIER_ID = null // yes, the value is null
export const DEFAULT_SUPPLIER_ID = ''
export const DEFAULT_UID = '00000000-0000-0000-0000-000000000000'
export type Direction = typeof ASC | typeof DESC
export const MAX_DEC = 5
export const PERCENT = 'percent'
export const PERCENT_SYMB = '%'
export const SELECTED_COMPANY = 'selectedCompany'
export const WASTE_WATER = 'Wastewater'
export const WATER = 'Water'
export const MAX_RECENT_ITEMS = 6

export const ROLES = {
    MondraAdmin: 'MondraAdmin',
    MondraLCAAssessor: 'MondraLCAAssessor',
    MondraLCAViewer: 'MondraLCAViewer',
    Owner: 'Owner',
    SUPPLIER: 'Supplier',
    User: 'User',
    Viewer: 'Viewer',
}

export const GRADES = ['A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G']
export const GRADES_OPTIONS = [
    {
        label: 'A+',
        value: 'A+',
    },
    {
        label: 'A',
        value: 'A',
    },
    {
        label: 'B',
        value: 'B',
    },
    {
        label: 'C',
        value: 'C',
    },
    {
        label: 'D',
        value: 'D',
    },
    {
        label: 'E',
        value: 'E',
    },
    {
        label: 'F',
        value: 'F',
    },
    {
        label: 'G',
        value: 'G',
    },
]

export const MEDIA_SCREENS = {
    lg: 1024,
    md: 768,
    sm: 640,
    xl: 1280,
    xs: 320,
}

export const INGREDIENT = 'Ingredient'
export const FOR_ALL = 'ForAll'
export const SPECIFIC = 'Specific'

export const ASSESSMENT_DESCRIPTION
    = 'Having our product certified by Mondra is the first step in our journey to reducing our product\'s impact on the world.'

/**
 * PATTERNS
 */

export const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i

export const COMPONENT_TYPE_LABELS: any = {
    ingredient: 'Ingredient',
    packaging: 'packaging component',
    processing: 'processing component',
    produce: 'Produce',
    product: 'Product',
    retail: 'sale component',
    supplier: 'Supplier',
}

export const SUPPLY_CHAIN_NODE_TYPES = {
    complexingredient: 'complexingredient',
    ingredient: 'ingredient',
    packaging: 'packaging',
    processing: 'processing',
    produce: 'produce',
    product: 'product',
    retail: 'sale',
    sale: 'sale',
    simpleingredient: 'simpleingredient',
    specialingredient: 'specialingredient',
    storage: 'storage',
    supplyChain: 'supplyChain',
    transport: 'transport',
}

export const NO_LCA_ERROR
    = 'No-LCA ingredients may not exceed 2% of composition. Reduce their quantity or mark as LCA to save.'
export const PRODUCE_TITLE = 'Produce - farm stage products in your supply chain.'
export const SUPPLIER_TITLE = 'Supplied products - processed products in your supply chain.'

export const GRADE_TITLE = 'Export grading file'
export const GRADE_DESC_COMPLETED
    = 'grade file is successfully generated, you can see the file using below link.'
export const GRADE_DESC_REQUESTING = 'grade file is being generated, it may take a few moment.'
export const EXPORT_COMPLETED
    = 'data file is successfully exported, you can see the file using below link.'
export const EXPORT_REQUESTING = 'Products data is being exported, it may take a few moment.'

export const EXPORT_CONTRIBUTION_TREE = 'Export contribution tree'
export const CONTRIBUTION_TREE_REQUESTING
    = 'contribution tree file is being generated, it may take a few moment.'
export const CONTRIBUTION_TREE_COMPLETED
    = 'contribution file is successfully generated, you can see the file using below link.'
export const RECENT_VIEWED_LABEL = 'Your recently viewed items will be shown here.'
export const PINNED_ITEMS_LABEL = 'You have no pinned items.'
export const PINNED_ITEMS_DESC
    = 'You can pin a product or Ingredient so that you can quickly view and edit.'

export const TRENDS = {
    DECREASED: 'Decreased',
    INCREASED: 'Increased',
    UNCHANGED: 'Unchanged',
}

export const MESSAGE_TRIGGER_STATE = {
    APPROVALREQUEST: 'ApprovalRequest',
    APPROVED: 'Approved',
    CALCREQUESTFAILED: 'CalcRequestFailed',
    CALCREQUESTTIMEOUT: 'CalcRequestTimedOut',
    CANCELLED: 'Cancelled',
    CERTIFICATEEXPIRED: 'CertificateExpired',
    CERTIFICATEEXPIRING: 'CertificateExpiring',
    CERTIFIED: 'Certified',
    DOWNSTREAM: 'Downstream',
    IMPACTRECEIVED: 'ImpactsReceived',
    RECERTIFIED: 'Recertified',
    REJECTED: 'Rejected',
    REVIEWIMPACTS: 'ReviewImpacts',
    REVOKED: 'Revoked',
    UPSTREAM: 'Upstream',
}
export const MESSAGE_TRIGGER_SUB_TYPE = {
    CERTIFICATE: 'Certificate',
    CERTIFICATESTATECHANGE: 'CertificateStateChange',
    FILEGENERATED: 'FileGenerated',
    PRODUCT: 'Product',
    SUPPLYCHAIN: 'SupplyChain',
    USERMANAGEMENT: 'UserManagement',
    V2CALC: 'V2Calc',
    V2CALCIMPACTSRECEIVED: 'V2CalcImpactsReceived',
}

export const DOWNSHIT_CHANGE_TYPE = 6
export const DOWNSHIT_ITEM_SELECT_TYPE = 10
export const DOWNSHIT_ITEM_CLEAR_TYPE = 16

export const SUPPLIED_PRODUCT_LABELS: Record<
    any,
    {
        title: string
        text: string
        plural: string
    }
> = {
    ComplexIngredient: {
        plural: 'complex ingredients',
        text: 'complex ingredient',
        title: 'Complex Ingredient',
    },
    Ingredient: {
        plural: 'products',
        text: 'product',
        title: 'Product',
    },
    Produce: {
        plural: 'Produce',
        text: 'produce',
        title: 'Produce',
    },
    Product: {
        plural: 'products',
        text: 'product',
        title: 'Product',
    },
    SimpleIngredient: {
        plural: 'simple ingredients',
        text: 'simple ingredient',
        title: 'Simple Ingredient',
    },
    SpecialIngredient: {
        plural: 'special ingredients',
        text: 'special ingredient',
        title: 'Special Ingredient',
    },
}

export const statusIcons: Record<NodeState, string> = {
    approved: 'butterfly',
    blank: 'overflowMenuHorizontal',
    certified: 'butterfly',
    none: 'errorOutline',
    produce: 'produce',
    progress: 'overflowMenuHorizontal',
    review: 'checkmark',
    suppliers: 'suppliers',
}

export const STAT_ICONS = {
    ...statusIcons,
    ingredients: 'ingredients',
    product: 'product',
    review: 'checkmarkOutline',
}

export const PER_100G = 'per100g'
export const PER_KG = 'perKg'
export const PER_PIECE = 'perPiece'
export const PER_PRODUCT = 'perProduct'
export const PER_SERVING = 'perServing'

export const PRODUCT_SIZES: IProductSizeOption[] = [
    { label: 'Per 100g', value: PER_100G },
    { label: 'Per 1Kg', value: PER_KG },
    { label: 'Per Product', value: PER_PRODUCT },
    { label: 'Per Serving', value: PER_SERVING },
]

export const SUPPLY_CHAIN_SORT_OPTIONS = [
    { id: 'biodiversity', label: 'Biodiversity' },
    { id: 'carbon', label: 'carbon' },
    { id: 'eutrophication', label: 'Eutrophication' },
    { id: 'waterUsage', label: 'WaterUsage' },
    { id: 'weight', label: 'Weight' },
]

export const YES_NO_TOGGLE_LABELS = {
    left: 'No',
    right: 'Yes',
}

export const TOGGLE_YES_LABELS = {
    left: '',
    right: 'Yes',
}

export const ENTITY_ACTIVE_ARCHIVE = {
    ACTIVE: 'active',
    ALL: 'all',
    ARCHIVE: 'archive',
}

export const HTTP_404_RESPONSE = {
    detail: 'Please verify the resource URL that you are trying to access',
    message: 'Invalid URL',
    status: 404,
}

export const SaTypeIcons: Record<SA_TYPE, string> = {
    [SA_TYPE.Produce]: 'produce',
    [SA_TYPE.Product]: 'product',
    [SA_TYPE.ComplexIngredient]: 'complexIngredient',
    [SA_TYPE.SimpleIngredient]: 'simpleIngredient',
    [SA_TYPE.SpecialIngredient]: 'specialIngredient',
}
