import React, { useState } from 'react'
import { PageLoader } from '../components'


export default function Image({ url, defaultUrl, alt }: any) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    return (
        <div className="w-full h-full relative rounded flex items-center">
            {error ? (
                <img
                    className="max-h-full max-w-full w-full object-cover rounded"
                    src={defaultUrl}
                    alt={alt}
                />
            ) : (
                <img
                    className="max-h-full max-w-full w-full object-cover rounded"
                    src={url || defaultUrl}
                    alt={alt}
                    onLoad={() => setLoading(false)}
                    onError={() => setError(true)}
                />
            )}
            {loading && (
                <div className="h-full w-full absolute left-0 top-0 flex items-center justify-center">
                    <PageLoader sizeClass="w-12 h-12" />
                </div>
            )}
        </div>
    )
}
