import React, { useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { ConfirmDialog, Button, Input } from '@mondra/ui-components'
import Pluralize from 'pluralize'
import { Dropdown, ImpactScoreCell, Row } from '../../components'
import { PER_PIECE } from '../../constants'
import { mapWithIndex, reduceWithIndex } from '../../utils/lodash-convert'
import { CertificateStateEnum } from '../../types/types'
import { IPackagingLeg, IPackagingOption } from '../../types/stages'
import { buildImpact } from '../../utils/impactUtils'
import { getImpacts } from './packagingUtils'
import PackagingInfoTooltip from './PackagingInfoTooltip'
import { IDistributorError } from './PackagingForm'
import { PackagingSetList } from './PackagingSetList'


interface IDistributionLegs {
    disabled?: boolean
    errors?: IDistributorError
    legs: IPackagingLeg[]
    loading?: boolean
    options: IPackagingOption[]
    primaryLeg?: IPackagingOption
    setDistributionPackaging: any
    setError: any
}

export default function DistributionLegs({
    disabled,
    errors = {},
    legs = [],
    loading,
    options,
    primaryLeg,
    setDistributionPackaging,
    setError,
}: IDistributionLegs) {
    const [removeIndex, setRemoveIndex] = useState<number>(-1)

    const handleSelect = (index: number) => (selected?: IPackagingOption) => {
        setDistributionPackaging(
            mapWithIndex((leg: IPackagingLeg, idx: number) => {
                if (idx === index) {
                    return {
                        ...leg,
                        isZeroImpact: false,
                        itemsNumber: 0,
                        packagingRefCode: selected?.code,
                        selected,
                    }
                }
                return leg
            }, legs)
        )
        setError({
            ...errors,
            [index]: {
                ...errors[index],
                packaging: false,
            },
        })
    }

    const handleChangeItems = (index: number) => (event: any) => {
        const itemsNumber = parseInt(event.target.value)
        setDistributionPackaging(
            mapWithIndex((leg: IPackagingLeg, idx: number) => {
                if (idx === index) {
                    return {
                        ...leg,
                        itemsNumber,
                    }
                }
                return leg
            }, legs)
        )
        setError({
            ...errors,
            [index]: {
                ...errors[index],
                itemsNumber: itemsNumber <= 0,
            },
        })
    }

    const handleRemove = () => {
        setDistributionPackaging(
            reduceWithIndex(
                (acc: IPackagingLeg[], leg: IPackagingLeg, idx: number) => {
                    if (idx === removeIndex) {
                        if (!isEmpty(leg.id)) {
                            return [
                                ...acc,
                                {
                                    ...leg,
                                    isArchived: true,
                                },
                            ]
                        }
                        return acc
                    }
                    return [...acc, leg]
                },
                [],
                legs
            )
        )
        setRemoveIndex(-1)
    }

    if (isEmpty(legs)) {
        return <div />
    }

    return (
        <div className="mb-4">
            <Row className="grid-cols-12">
                <div className="col-span-7">
                    <div className="text-sm text-gray-700 font-medium">Distribution Packaging:</div>
                    <div className="text-xs text-gray-500 font-normal">
                        How you pack your product for Distribution
                    </div>
                </div>
                <div className="col-span-5">
                    <div className="text-sm text-gray-700 font-medium">Contains:</div>
                    <div className="text-xs text-gray-500 font-normal">Number previous items</div>
                </div>
                <div />
            </Row>
            {mapWithIndex((leg: IPackagingLeg, index: number) => {
                const readOnly = disabled || isEmpty(leg.selected)

                const prevPackageName
                    = index === 0 ? primaryLeg?.name : legs[index - 1]?.selected?.name
                const previousPackaging = Pluralize(prevPackageName || '', leg?.itemsNumber)

                return (
                    <Row className={`grid-cols-12 ${leg.isArchived ? 'hidden' : ''}`} key={index}>
                        <div className="col-span-7 flex flex-row items-center gap-4">
                            <div className="flex flex-shrink-0 w-10 h-10 bg-gray-300">
                                {false && <img src="#" alt="package" className="w-10 h-10" />}
                            </div>
                            <Dropdown
                                containerClass="flex-grow"
                                selected={leg?.selected}
                                disabled={disabled}
                                options={options}
                                renderList={(props: any) => <PackagingSetList {...props} />}
                                valid={!errors[index]?.packaging}
                                placeholder={loading ? 'Loading...' : 'Select a packaging option'}
                                onChange={handleSelect(index)}
                            />
                        </div>
                        <div className="col-span-5 flex flex-row items-center justify-between gap-2">
                            <Input
                                type="number"
                                className="w-full w-20"
                                disabled={readOnly}
                                invalid={errors[index]?.itemsNumber}
                                value={leg?.itemsNumber}
                                onChange={handleChangeItems(index)}
                            />
                            <div
                                className="overflow-x-hidden overflow-ellipsis whitespace-nowrap w-24"
                                title={previousPackaging}
                            >
                                {previousPackaging}
                            </div>
                            <div className="flex flex-row items-center">
                                <ImpactScoreCell
                                    hideGrade
                                    disabled={readOnly}
                                    iconColor={'text-amber-500'}
                                    measureType={PER_PIECE}
                                    state={CertificateStateEnum.Draft}
                                    score={''}
                                    ecoImpacts={buildImpact(getImpacts(leg.selected))}
                                />
                                <PackagingInfoTooltip
                                    packageInfo={leg?.selected}
                                    disabled={readOnly}
                                />
                                <Button
                                    isOnlyIcon
                                    disabled={disabled}
                                    title="Remove"
                                    className="w-7"
                                    iconType="close"
                                    onClick={() => setRemoveIndex(index)}
                                />
                            </div>
                        </div>
                    </Row>
                )
            }, legs)}
            <ConfirmDialog
                open={removeIndex > -1}
                title="Deletion will remove dependent packaging"
                variant="danger"
                onConfirm={handleRemove}
                onClose={() => setRemoveIndex(-1)}
            >
                <p>Deleting this packaging will remove all linked packaging below.</p>
            </ConfirmDialog>
        </div>
    )
}
