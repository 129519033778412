import { useEffect, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import isArray from 'lodash/fp/isArray'
import { METHOD_PATCH, METHOD_POST, METHOD_PUT, URLs } from '../constants'
import { IProduct, IString } from '../types/types'
import getURL from '../utils/getURL'
import useFetch from './useFetch'
import useUpdateFormData from './useUpdateFormData'


interface IUseProductProps {
    autoFetch?: boolean
    companyId: string
    defaultValue?: any
    onError?: (error: any) => void
    onCustomPropsUpdate?: (product: IProduct) => void
    onMetadataUpdate?: (product?: IProduct) => void
    onProductAdded?: (product?: IProduct) => void
    onUpdate?: (product: IProduct) => void
    productId?: string
    supplierId?: IString
}

export default function useProduct({
    autoFetch = true,
    companyId,
    defaultValue,
    onCustomPropsUpdate,
    onError,
    onProductAdded,
    onMetadataUpdate,
    onUpdate,
    productId,
    supplierId,
}: IUseProductProps) {
    const [product, setProduct] = useState<IProduct>(defaultValue || {})
    const query = isEmpty(supplierId) ? '' : `?supplierId=${supplierId}`

    // Add Product
    const { saving: creating, update: createProduct } = useUpdateFormData({
        method: METHOD_POST,
        onError,
        onUpdate: onProductAdded || onUpdate,
        url: getURL(URLs.Product.ADD, { companyId }),
    })

    // Get Product
    const { data, loading, refetch } = useFetch({
        autoFetch: autoFetch && !isEmpty(productId),
        errMessage: 'Failed to fetch Product',
        url: getURL(URLs.Product.GET_COMPACT, { companyId, productId }, query),
    })

    const url = getURL(URLs.Product.GET, { companyId, productId })

    // Update Product
    const { saving: updating, update: updateProduct } = useUpdateFormData({
        method: METHOD_PUT,
        onError,
        onUpdate,
        url,
    })

    // Patch Product
    const { saving: patching, update: patch } = useUpdateFormData({
        method: METHOD_PATCH,
        onError,
        onUpdate,
        url,
    })

    // Update Metadata
    const { saving: updatingMetadata, update: updateProductMetadata } = useUpdateFormData({
        method: METHOD_PUT,
        onError,
        onUpdate: onMetadataUpdate || onUpdate,
        url: getURL(URLs.Product.METADATA, { companyId, productId }),
    })

    // Update Custom Properties
    const { saving: updatingCustomProps, update: updateProductCustomProps } = useUpdateFormData({
        method: METHOD_PUT,
        onError,
        onUpdate: onCustomPropsUpdate || onUpdate,
        url: getURL(URLs.Product.UPDATE_CUSTOM_PROPERTIES, { companyId, productId }),
    })

    // Calculate Product
    const { saving: calculating, update: calculate } = useUpdateFormData({
        method: METHOD_POST,
        onError,
        onUpdate,
        url: getURL(URLs.Product.CALCULATE, { companyId, productId }),
    })

    useEffect(() => {
        if (!isEmpty(data)) {
            setProduct(data)
        }
    }, [data])

    useEffect(() => {
        if (!isEmpty(defaultValue)) {
            setProduct(defaultValue)
        }
    }, [defaultValue])

    const onChange = (change: any, reset = false) => {
        setProduct((p: IProduct) => (reset ? { ...defaultValue, ...change } : { ...p, ...change }))
    }

    const patchProduct = (value: any, path?: string) => {
        if (isArray(value)) {
            patch(value)
        } else {
            patch([
                {
                    op: 'replace',
                    path,
                    value,
                },
            ])
        }
    }

    return {
        calculate,
        calculating,
        createProduct,
        loading,
        onChange,
        patchProduct,
        product,
        refetch,
        saving: creating || updating || patching || updatingMetadata || updatingCustomProps,
        updateProduct,
        updateProductCustomProps,
        updateProductMetadata,
    }
}
