/* eslint-disable @typescript-eslint/ban-ts-comment */
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import forEach from 'lodash/fp/forEach'
import reduce from 'lodash/fp/reduce'

// @ts-ignore
export const mapWithIndex = map.convert({ cap: false })

// @ts-ignore
export const filterWithIndex = filter.convert({ cap: false })

// @ts-ignore
export const forEachWithIndex = forEach.convert({ cap: false })

// @ts-ignore
export const reduceWithIndex = reduce.convert({ cap: false })
