import React, { useCallback, useState } from 'react'
import toUpper from 'lodash/fp/toUpper'
import isEmpty from 'lodash/fp/isEmpty'
import { Button, ConfirmDialog } from '@mondra/ui-components'
import useUpdateFormData from '../hooks/useUpdateFormData'
import { useTopCenterToast } from '../hooks/useTopCenterToast'
import { METHOD_DELETE, METHOD_PUT, URLs } from '../constants'
import { ARCHIVE_ERROR } from '../constants/toastNotifications'
import getURL from '../utils/getURL'
import { MenuItem } from '.'


interface IArchiveProductProps {
    apiNode: string
    companyId: string
    id: string
    isArchived: boolean
    isMenu?: boolean
    name: string
    refresh?(): void
}

export default function ArchiveProduct({
    apiNode,
    companyId,
    id,
    isArchived,
    isMenu = true,
    name,
    refresh,
}: IArchiveProductProps) {
    const [confirmArchive, setConfirmArchive] = useState(false)
    const { showError, showSuccess } = useTopCenterToast()

    const url = isArchived
        ? getURL(URLs.UnArchive, { apiNode, companyId, id })
        : getURL(URLs.Archive, { apiNode, companyId, id })

    const method = isArchived ? METHOD_PUT : METHOD_DELETE

    const { saving, update } = useUpdateFormData({
        contentType: true,
        method,
        onError: (error: any) => {
            setConfirmArchive(false)
            showError({
                description: `Failed to ${isArchived ? 'Unarchive' : 'Archive'} (${error.message})`,
            })
            refresh && refresh()
        },
        onUpdate: () => {
            refresh && refresh()
            setConfirmArchive(false)
            showSuccess({
                description: `"${name}" ${isArchived ? 'Unarchived' : 'Archived'} successfully`,
            })
        },
        url,
    })

    const handleOpen = useCallback(() => {
        setConfirmArchive(true)
    }, [])

    const handleClose = useCallback(() => {
        setConfirmArchive(false)
    }, [])

    const handleArchiveActions = useCallback(() => {
        if (isEmpty(apiNode) || isEmpty(id)) {
            showError({ description: ARCHIVE_ERROR })
            setConfirmArchive(false)
            return
        }

        update({})
    }, [apiNode, id])

    const trigger = isMenu ? (
        <MenuItem onSelect={handleOpen}>{isArchived ? 'Unarchive' : 'Archive'}</MenuItem>
    ) : (
        <Button
            className="!text-gray-700"
            isOnlyIcon
            onClick={handleOpen}
            iconType={isArchived ? 'trashUndo' : 'trashCan'}
            variant="white"
            size="sm"
        />
    )

    return (
        <>
            {trigger}
            <ConfirmDialog
                open={confirmArchive}
                variant={isArchived ? 'warn' : 'danger'}
                title={isArchived ? 'Unarchive' : 'Archive'}
                primaryBtnText={
                    saving
                        ? isArchived
                            ? 'Unarchiving'
                            : 'Archiving'
                        : isArchived
                            ? 'Unarchive'
                            : 'Archive'
                }
                onClose={handleClose}
                onConfirm={handleArchiveActions}
            >
                {`Are you sure you want to ${isArchived ? 'Unarchive' : 'Archive'}`}{' '}
                <b>{toUpper(name)}</b>
            </ConfirmDialog>
        </>
    )
}
