import React from 'react'
import { Button } from '@mondra/ui-components'
import { FilterCount } from './FilterSection'


interface IResetAllFilters {
    handleClearAll: () => void
    allSelectedCount: number
}

export const ResetAllFilters = ({ handleClearAll, allSelectedCount }: IResetAllFilters) => {
    return (
        <div className="flex items-center justify-between px-8 mb-2">
            <Button
                disabled={!allSelectedCount}
                onClick={handleClearAll}
                variant="ghost"
                className="text-primary-600"
            >
                Reset filters
            </Button>
            {Boolean(allSelectedCount) && (
                <FilterCount count={allSelectedCount} onClear={handleClearAll} sizeBig />
            )}
        </div>
    )
}
