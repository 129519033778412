import React, { useContext } from 'react'
import { Button, Icon } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import { ProductSettingContext } from '../../contexts/ProductSettingProvider'
import { SlideoverContext } from '../../contexts/SlideoverProvider'
import { IProduct, NodeType, CertificateStateEnum } from '../../types/types'
import {
    getProductManagedByName,
    getProductSuppliedByName,
} from '../../utils/getProductSupplierName'
import { LightTooltip } from '../../components'
import { CompleteState } from '../../components/CompleteState'
import SiteDetail from './SiteDetail'
import LockStatus from './LockStatus'


interface IDetailsCardProps {
    companyId: string
    isSuppliedProduct?: boolean
    loading?: boolean
    onProductUpdate: (changes: Partial<IProduct>) => void
    product: IProduct
}

function DetailItem({ title, value }: { title: string; value: React.ReactNode }) {
    return (
        <li className="flex items-center py-1 truncate">
            <label className="text-gray-900 font-medium leading-6">{title || '-'}</label>
            <label className="ml-1 font-semibold leading-6 text-gray-500 truncate">
                {value || '-'}
            </label>
        </li>
    )
}

export default function DetailsCard({
    companyId,
    isSuppliedProduct,
    loading,
    onProductUpdate,
    product,
}: IDetailsCardProps) {
    const { readOnly } = useContext(ProductSettingContext)
    const { openSlideover } = useContext(SlideoverContext)

    const {
        companyCategoryName,
        imageUrl,
        isComplete,
        measureUnit,
        name,
        netWeight,
        servingSize,
        site,
        sku,
    } = product

    const cert = find(
        (cert: any) => cert.saState === CertificateStateEnum.Certified,
        product.certificates
    )

    const handleEditProduct = () => {
        openSlideover(NodeType.PRODUCT, {
            isSuppliedProduct,
            productId: product.id,
        })
    }

    return (
        <div className="bg-white shadow rounded-lg min-h-80 flex flex-row">
            <div className="flex flex-col flex-grow p-4">
                <div className="mb-4 flex items-center gap-x-4">
                    {!isEmpty(cert) && cert?.id !== product.currentCertificateId && (
                        <LightTooltip
                            title={
                                <div className="text-gray-800 p-3 text-sm">
                                    Produc is certified, see Certification History for details.
                                </div>
                            }
                        >
                            <span className="bg-grade-A text-white font-bold rounded-lg px-3 flex gap-x-4 justify-start items-center">
                                <Icon type="butterfly" size={22} />
                                <span className="text-xs">{cert?.saState}</span>
                                <span className="text-xs">{cert?.ecoImpactMetascore}</span>
                            </span>
                        </LightTooltip>
                    )}
                </div>

                <h3 className="text-2xl leading-7 text-gray-900 font-semibold line-clamp-2 h-16">
                    {loading ? 'Loading...' : name}
                </h3>

                <div className="mb-4 flex flex-row gap-2">
                    <LockStatus
                        className="w-10 h-10 flex items-center justify-center rounded-md"
                        companyId={companyId}
                        isProductLocked={product.isProductLocked}
                        productId={product.id}
                        onProductUpdate={onProductUpdate}
                    />
                    <div className="flex flex-col justify-center gap-y-1.5 text-xs">
                        <div>
                            <span className="text-gray-500">Supplied By: </span>
                            <span className="text-gray-900">
                                {getProductSuppliedByName(product)}
                            </span>
                        </div>
                        <div>
                            <span className="text-gray-500">Managed By: </span>
                            <span className="text-gray-900">
                                {getProductManagedByName(product)}
                            </span>
                        </div>
                    </div>
                </div>

                <ul className="border-t border-gray-300 divide-y text-sm">
                    <DetailItem title="ID:" value={sku || '-'} />
                    <DetailItem
                        title="Weight (net):"
                        value={!loading && `${netWeight}${measureUnit}`}
                    />
                    <DetailItem
                        title="Serving size:"
                        value={!loading && `${servingSize}${measureUnit}`}
                    />
                    {!loading && !isSuppliedProduct && (
                        <DetailItem
                            title="Category:"
                            value={`${companyCategoryName || 'Not set'}`}
                        />
                    )}
                    <DetailItem
                        title="Data complete:"
                        value={<CompleteState isComplete={isComplete} />}
                    />
                </ul>
                <div className="mt-2">
                    <Button
                        disabled={readOnly}
                        variant="ghost"
                        iconType="edit"
                        size="md"
                        onClick={handleEditProduct}
                    >
                        Edit Details
                    </Button>
                </div>
            </div>
            <div className="bg-gray-50 w-44 rounded-r-lg flex flex-col">
                <img
                    src={imageUrl || '/certificate_product_placeholder.png'}
                    alt={name}
                    className="h-44 w-44 object-cover rounded-tr-lg"
                />
                <SiteDetail
                    companyId={companyId}
                    site={site}
                    productId={product.id}
                    onProductUpdate={onProductUpdate}
                />
            </div>
        </div>
    )
}
