import React, { useState } from 'react'
import { Autocomplete, Icon, Label } from '@mondra/ui-components'
import map from 'lodash/fp/map'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import useFetch from '../../hooks/useFetch'
import getURL from '../../utils/getURL'
import { DOWNSHIT_CHANGE_TYPE, URLs } from '../../constants'
import { IAddressMapApi } from '../../types/types'


interface IAddressLookupProps {
    errors: any
    disabled?: boolean
    onAddressChange: (address: any) => void
    selected?: string
}

const AddressLookup = ({ errors, disabled, onAddressChange, selected }: IAddressLookupProps) => {
    const [selectedAddress, setSelectedAddress] = useState<IAddressMapApi>()
    const [searchText, setSearchText] = useState<string>('')
    const url = getURL(
        URLs.AddressLookup,
        { key: process.env.REACT_APP_AZURE_MAPS_KEY },
        `&query=${searchText}`
    )
    const { data: response } = useFetch({
        autoFetch: searchText.length > 2,
        baseUrlPrefix: false,
        url,
    })
    const addresses = isEmpty(searchText) ? [] : getOr([], 'results', response)

    function handleSelected({ selectedItem }: any) {
        const { address, position } = selectedItem || {}
        setSelectedAddress(address)
        onAddressChange({ address, position })
    }

    function handleStateChange(state: any) {
        const { type, inputValue } = state
        if (type === DOWNSHIT_CHANGE_TYPE) {
            setSearchText(inputValue)
        }
    }

    function handleRemove() {
        setSelectedAddress({})
    }

    const getLabel = (address: IAddressMapApi = {}) => {
        return address?.freeformAddress
    }

    return (
        <div>
            {!isEmpty(selected) && (
                <div className="mb-4 p-4 bg-primary-50 rounded-lg border border-primary-500 text-base text-gray-600">
                    {selected}
                </div>
            )}
            {isEmpty(selectedAddress) ? (
                <Autocomplete
                    onStateChange={handleStateChange}
                    onChange={handleSelected}
                    disabled={disabled}
                    invalid={Boolean(errors.address)}
                    options={map(
                        (address: any) => ({
                            id: address.id,
                            label: getLabel(address?.address),
                            ...address,
                        }),
                        addresses
                    )}
                />
            ) : (
                <div className="flex justify-between items-center w-full bg-gray-200 rounded p-4">
                    <Label htmlFor="address">{getLabel(selectedAddress)}</Label>

                    <Icon
                        onClick={handleRemove}
                        className="text-right cursor-pointer"
                        type="close"
                    />
                </div>
            )}
        </div>
    )
}

export default AddressLookup
