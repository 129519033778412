import React from 'react'
import { ConfirmDialog } from '@mondra/ui-components'

import { SUPPLIED_PRODUCT_LABELS } from '../../constants'


interface ISwitchIngredientTypeDialogProps {
    onClose: () => void
    onConfirm: () => void
    open: boolean
    saType: string
}

export default function SwitchIngredientTypeDialog({
    saType,
    open,
    onClose,
    onConfirm,
}: ISwitchIngredientTypeDialogProps) {
    const currentTypeLabel = SUPPLIED_PRODUCT_LABELS[saType].plural

    return (
        <ConfirmDialog
            variant="warn"
            title={`Will remove all ${currentTypeLabel}`}
            open={open}
            primaryBtnText="Ok"
            onClose={onClose}
            onConfirm={onConfirm}
        >
            Ingredients can not contain both products and produce. Changing ingredient type will
            remove all added {currentTypeLabel}.
        </ConfirmDialog>
    )
}
