import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '@mondra/ui-components'
import map from 'lodash/fp/map'
import pullAt from 'lodash/fp/pullAt'
import some from 'lodash/fp/some'
import filter from 'lodash/fp/filter'
import sortBy from 'lodash/fp/sortBy'
import isEmpty from 'lodash/fp/isEmpty'
import { ICountry, ISite, NodeType } from '../../types/types'
import { ITransportLeg, ITransportType } from '../../types/stages'
import { FormRow } from '../../components'
import getURL from '../../utils/getURL'
import { DEFAULT_UID, METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import { SAVE } from '../../constants/toastNotifications'
import useFetch from '../../hooks/useFetch'
import useCountries from '../../hooks/useCountries'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { mapWithIndex } from '../../utils/lodash-convert'
import TransportLeg from './Transportleg'
import Site from './Site'


interface ITransportFormProps {
    isEdit: boolean
    formId: string
    originSite: ISite
    refetch: () => void
    rdcSite?: string
    sale: any
    companyId: string
    productId: string
    onClose: (type: NodeType) => void
}

const defaultLeg = {
    distance: '',
    distanceUnit: 'KM',
    id: '',
    isArchived: false,
    legOrder: 0,
    transportTypeCode: '',
}

export default function SalesTransportForm({
    isEdit,
    refetch,
    originSite,
    formId,
    sale,
    companyId,
    productId,
    onClose,
}: ITransportFormProps) {
    const [transportLegs, setTransportLegs] = useState<ITransportLeg[]>([defaultLeg])
    const { countries } = useCountries({ autoFetch: true })
    const { showSuccess } = useTopCenterToast()
    const url = getURL(URLs.Transport.GET_TYPES, { companyId })
    const { data: transportTypes, loading } = useFetch({
        errMessage: 'Failed to fetch transport types',
        url,
    })

    const onUpdate = () => {
        refetch && refetch()
        onClose(NodeType.SALES_TRANSPORT)
        showSuccess({ description: SAVE.SUCCESS })
    }
    const { update } = useUpdateFormData({
        method: isEdit ? METHOD_PUT : METHOD_POST,
        onUpdate,
        url: isEdit
            ? getURL(URLs.Sales.UPDATE, { companyId, id: sale.id })
            : getURL(URLs.Sales.ADD, { companyId, productId }),
    })

    useEffect(() => {
        if (!isEmpty(sale)) {
            setTransportLegs(
                sortBy(['legOrder'], sale?.transportLegs)?.map(
                    (leg: ITransportLeg, index: number) => ({
                        ...leg,
                        legOrder: index,
                    })
                ) as any
            )
        }
    }, [sale])

    const transportTypesOptions = useMemo(() => {
        return map(
            (type: ITransportType) => ({
                ...type,
                label: type.name,
                value: type.code,
            }),
            transportTypes
        )
    }, [transportTypes])

    const countryList = useMemo(() => {
        return map(
            (country: ICountry) => ({
                ...country,
                label: `${country.isoAlpha3} - ${country.countryName}`,
                value: country.id,
            }),
            countries
        )
    }, [countries])

    const handleTransportChange = (index: number) => (updatedLeg: ITransportLeg) => {
        const allLegs = [...transportLegs]
        allLegs[index] = updatedLeg
        setTransportLegs(allLegs)
    }

    const handleTransportRemove = (index: number) => (updatedLeg: ITransportLeg) => {
        const allLegs = [...transportLegs]
        let rest = null
        const existing = some(s => s.code === updatedLeg.transportTypeCode, sale?.transportLegs)
        if (existing) {
            allLegs[index].isArchived = true
            setTransportLegs(allLegs)
        } else {
            rest = pullAt(index, allLegs)
            setTransportLegs(rest)
        }
    }

    const handleAddLeg = () => {
        setTransportLegs((legs: ITransportLeg[] = []) => [
            ...legs,
            { ...defaultLeg, legOrder: legs?.length },
        ])
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        update({ ...sale, transportLegs })
    }
    const disabled = isEmpty(originSite?.id) || originSite?.id == DEFAULT_UID
    return (
        <form id={formId} className="divide-y divide-gray-200" onSubmit={handleSubmit}>
            <FormRow>
                <Site site={originSite as any} isTransporting={true} type="Origin" />
                <Site
                    rdcSite={originSite?.address?.country || ''}
                    isTransporting={true}
                    type="Destination"
                />
            </FormRow>
            <div>
                {mapWithIndex(
                    (leg: ITransportLeg) => (
                        <TransportLeg
                            disabled={disabled}
                            key={leg.legOrder}
                            leg={leg}
                            legs={transportLegs}
                            setLegs={setTransportLegs}
                            countries={countryList}
                            transportTypes={transportTypesOptions}
                            transportTypesLoading={loading}
                            onChange={handleTransportChange(leg.legOrder)}
                            onRemove={handleTransportRemove(leg.legOrder)}
                        />
                    ),
                    filter(leg => !leg.isArchived, transportLegs)
                )}
                <div className="text-right mt-6 mx-6">
                    <Button
                        disabled={disabled}
                        variant="secondary"
                        onClick={handleAddLeg}
                        iconType="add"
                    >
                        Add Transport leg
                    </Button>
                </div>
            </div>
        </form>
    )
}
