import React from 'react'
import { IClassNameProps } from '@mondra/ui-components/@types'
import classNames from 'classnames'
import isEmpty from 'lodash/fp/isEmpty'
import { INoteItem } from '../../../types/types'
import { NotesListItem } from './NotesListItem'
import { NotesLoading } from './NotesLoading'


interface INotesListPanelProps extends IClassNameProps {
    loading?: boolean
    notes?: INoteItem[]
    onClick: (note: INoteItem) => void
    selected?: INoteItem
}

export function NotesListPanel({
    className,
    loading,
    notes,
    onClick,
    selected,
}: INotesListPanelProps) {
    return (
        <div className={classNames('flex flex-col', className)}>
            <div className="overflow-y-auto h-full max-h-dashboard-notes-list">
                <ul className="h-full flex flex-col list-none divide-y">
                    {isEmpty(notes)
                        && (loading ? (
                            new Array(3).fill(1).map((_, i: number) => (
                                <li key={i} className="p-4 text-gray-500">
                                    <NotesLoading />
                                </li>
                            ))
                        ) : (
                            <li className="p-4 text-gray-500">You have no notes to display</li>
                        ))}

                    {notes
                        && notes.map((note: INoteItem) => (
                            <NotesListItem
                                key={note.id}
                                note={note}
                                selected={selected}
                                onClick={onClick}
                            />
                        ))}
                </ul>
            </div>
        </div>
    )
}
