import React from 'react'
import { Notification } from '@mondra/ui-components'


export function NoRowsOverlay() {
    return (
        <Notification
            className="w-full"
            description="Currently there is no data available, please refresh the view or adjust the filters."
            label="No data available:"
            type="info"
        />
    )
}
