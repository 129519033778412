import React, { useMemo } from 'react'
import { Autocomplete } from '@mondra/ui-components'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import useFetch from '../../hooks/useFetch'
import ImpactScoreCell from '../ImpactScoreCell'
import { buildImpact } from '../../utils/impactUtils'
import getURL from '../../utils/getURL'
import { URLs } from '../../constants'
import { IEmissionFactor } from '../../types/stages'
import { CertificateStateEnum, SA_TYPE } from '../../types/types'
import { getImpacts } from './EmissionFactorUtils'
import { EmissionFactorRenderList } from './EmissionFactorRenderList'


interface IEmissionFactorProps {
    companyId: string
    disabled?: boolean
    onChange?: ({ selectedItem }: { selectedItem: IEmissionFactor }) => void
    selected?: string
    type?: SA_TYPE
}

export function EmissionFactor({
    companyId,
    disabled,
    onChange,
    selected,
    type,
}: IEmissionFactorProps) {
    const { data, loading: emissionFactorsLoading } = useFetch({
        errMessage: 'Failed to fetch emission factors',
        url: getURL(URLs.EmissionFactors.ALL, { companyId }, `?productType=${type}`),
    })

    const emissionFactors: IEmissionFactor[] = useMemo(
        () =>
            flow(
                map((ef: IEmissionFactor) => ({ ...ef, label: ef.productName })),
                sortBy(i => i.label)
            )(data),
        [data]
    )

    const emissionFactor = useMemo(() => {
        return find(i => i.code === selected, emissionFactors)
    }, [selected, emissionFactors])

    return (
        <div className="flex justify-between items-center gap-2 mt-2">
            <Autocomplete
                containerClass="flex-grow"
                selected={emissionFactor}
                disabled={disabled || emissionFactorsLoading}
                options={emissionFactors}
                placeholder={emissionFactorsLoading ? 'Loading...' : 'Select emission factor'}
                onChange={onChange}
                renderList={(props: any) => <EmissionFactorRenderList {...props} />}
            />
            <ImpactScoreCell
                hideGrade
                disabled={disabled}
                iconColor={'text-amber-500'}
                state={CertificateStateEnum.Certified}
                score={''}
                ecoImpacts={buildImpact(getImpacts(emissionFactor))}
            />
        </div>
    )
}
