import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@mondra/ui-components'
import { ICellRendererParams } from 'ag-grid-community'

import LcaLegend from '../../components/LcaLegend'
import ComponentErrorState from '../../components/ComponentErrorState'
import { getProductPageUrl } from '../../utils/getURL'
import { IProduct, SA_TYPE } from '../../types/types'
import { SaTypeIcons } from '../../constants'


interface ISuppliedProductsNameCellProps extends ICellRendererParams {
    data: IProduct
}

const SuppliedProductsNameCell = ({ context, data, value }: ISuppliedProductsNameCellProps) => {
    const { companyId, setEditProduceId, setEditSpecialIngredientId } = context
    const isProduce = data.saType === SA_TYPE.Produce
    const isSplIngredient = data.saType === SA_TYPE.SpecialIngredient

    const onEdit = useCallback(() => {
        if (isSplIngredient) {
            setEditSpecialIngredientId(data.id)
        } else {
            setEditProduceId(data.id)
        }
    }, [isSplIngredient])

    return (
        <div className="flex flex-row items-center">
            <Icon className="text-primary-800 mr-2" type={SaTypeIcons[data.saType]} size={16} />
            {isProduce || isSplIngredient ? (
                <>
                    <div
                        className="cursor-pointer mdp-link font-medium overflow-ellipsis overflow-hidden"
                        role="button"
                        onClick={onEdit}
                    >
                        {value}
                    </div>
                </>
            ) : (
                <>
                    <Link
                        title={value}
                        className="mdp-link font-medium underline overflow-ellipsis overflow-hidden"
                        to={getProductPageUrl({
                            companyId,
                            isSuppliedProduct: true,
                            productId: data.id,
                        })}
                    >
                        {value}
                        <ComponentErrorState isArchivedSupplier={data.isSupplierArchived} />
                    </Link>
                </>
            )}

            {data.isLCA && <LcaLegend />}
            {data.isProductLocked && (
                <Icon
                    type="locked"
                    size={16}
                    className="ml-2 text-gray-500"
                    title="This product is locked"
                />
            )}
        </div>
    )
}

export default SuppliedProductsNameCell
