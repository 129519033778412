import React from 'react'
import classNames from 'classnames'
import { getItemAppearProps } from '../../utils'
import DropdownList from '../DropdownList'


interface IEmissionFactorListItem {
    highlightedIndex: number
    option: any
    getItemProps: (props: any) => any
    index: number
    selected: any
}

function EmissionFactorListItem({
    highlightedIndex,
    getItemProps,
    index,
    option,
    selected,
}: IEmissionFactorListItem) {
    const { isHighlighted } = getItemAppearProps(highlightedIndex, index, option, selected)
    const clsValues = 'font-bold col-span-3'
    return (
        <li
            className={classNames(
                'cursor-pointer hover:bg-gray-100 px-4 py-2',
                'flex items-center',
                'text-gray-700 text-sm',
                isHighlighted && 'bg-gray-50 border-b-2'
            )}
            {...getItemProps({ index, item: option })}
        >
            <div
                className={classNames(
                    'truncate w-full flex flex-grow justify-between items-center'
                )}
                title={option.label}
            >
                <div className="w-full grid grid-cols-4 gap-2 font-medium">
                    <div>Produce:</div>
                    <span className={clsValues}>{option.productName}</span>
                    <div>Country:</div>
                    <span className={clsValues}>{option.countryISO3Code}</span>
                    <div>Region:</div>
                    <span className={clsValues}>{option.region}</span>
                </div>
                <div className="w-full grid grid-cols-4 gap-2 font-medium">
                    <div>Organic:</div>
                    <span className={clsValues}>
                        {option.ProductionSystem === 'Organic' ? 'Yes' : 'No'}
                    </span>
                    <div>Tech:</div>
                    <span className={clsValues}>{option.productNameatSource}</span>
                    <div>Version:</div>
                    <span className={clsValues}>{option.version}</span>
                </div>
            </div>
        </li>
    )
}

export const EmissionFactorRenderList = (props: any) => {
    return (
        <DropdownList
            {...props}
            listItem={(props: any) => <EmissionFactorListItem {...props} />}
            groupByField="productName"
        />
    )
}
