import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { IAddress, IParamProps, ISite, NodeType } from '../../types/types'
import { ISlideoverProps } from '../../types/slideover-types'
import slideoverCloseStyles from '../../constants/slideoverCloseStyles'
import { METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import { SITE } from '../../constants/toastNotifications'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import useFetch from '../../hooks/useFetch'
import getURL from '../../utils/getURL'
import { UserContext } from '../../contexts/UserProvider'
import Slideover from '../components/Slideover'
import SiteForm from './SiteForm'


export interface ISiteSlideoverProps extends Omit<ISlideoverProps, 'onSaved' | 'type'> {
    isEdit?: boolean
    onSaved?: (site: ISite) => void
    siteId?: string
}

const defaultSite: ISite = {
    address: {
        city: '',
        country: '',
        countryCode: '',
        countryCodeISOAlpha2: '',
        countryCodeISOAlpha3: '',
        countryId: 0,
        isArchived: false,
        isFullStreetAddress: true,
        latitude: '',
        longitude: '',
        pinCode: '',
        state: '',
        streetAddress: '',
    },
    companyId: '',
    id: '',
    isArchived: false,
    linkedCount: 0,
    name: '',
    siteId: '',
}

function isValidAddress(address: IAddress) {
    if (!isEmpty(address) && !isEmpty(address.countryCode)) {
        return true
    }
    return false
}

export default function SiteSlideover({
    isEdit = false,
    isOpen,
    level,
    onClose,
    onSaved,
    siteId,
}: ISiteSlideoverProps) {
    const [site, setSite] = useState<ISite>(defaultSite)
    const [errors, setErrors] = useState({})
    const [isExisting, setIsExisting] = useState(false)
    const { companyId = '' }: IParamProps = useParams()

    const { company } = useContext(UserContext)
    const { showSuccess } = useTopCenterToast()

    const { data: siteData, loading } = useFetch({
        autoFetch: isEdit && isOpen && !isEmpty(siteId),
        url: isEdit ? getURL(URLs.Site.GET, { companyId, siteId }) : '',
    })

    useEffect(() => {
        if (!isEmpty(siteData)) {
            setSite(siteData)
        }
    }, [siteData])

    const onUpdate = (data: any) => {
        showSuccess({ description: isEdit ? SITE.UPDATE : SITE.CREATE })
        onSaved && onSaved(data)
        handleClose()
    }

    const url = isEdit
        ? getURL(URLs.Site.UPDATE, { companyId, siteId })
        : getURL(URLs.Site.ADD, { companyId })

    const { saving, update } = useUpdateFormData({
        method: isEdit ? METHOD_PUT : METHOD_POST,
        onUpdate,
        url,
    })

    const handleChange = useCallback((changes: any, reset = false) => {
        setSite((siteState: ISite) => ({ ...siteState, ...changes }))
        setErrors({})
        if (reset) {
            setIsExisting(false)
        }
    }, [])

    const handleSubmit = (event: any) => {
        const { name, address } = site
        event.preventDefault()
        event.stopPropagation()

        if (isEmpty(name)) {
            setErrors({
                site: true,
            })
            return
        }

        if (!isValidAddress(address)) {
            setErrors({
                address: true,
            })
            return
        }

        update({
            ...site,
            companyId,
            id: isEdit ? site.id : undefined,
        })
    }

    const handleClose = () => {
        setSite(defaultSite)
        setErrors({})
        setIsExisting(false)
        onClose && onClose(NodeType.SITE)
    }

    return (
        <Slideover
            isOpen={isOpen}
            level={level}
            formId="add-edit-site-form"
            onClose={handleClose}
            type={NodeType.SITE}
            headerProps={{
                iconProps: slideoverCloseStyles as any,
            }}
            title={isEdit ? 'Update site' : 'Create site'}
            saving={saving}
            disableSave={saving || !isEmpty(errors)}
        >
            <SiteForm
                readOnly={isExisting}
                companyName={company?.name || ''}
                errors={errors}
                autoFetch={isOpen}
                site={site}
                loading={loading}
                onChange={handleChange}
                onSumbit={handleSubmit}
            />
        </Slideover>
    )
}
