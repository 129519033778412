import React from 'react'
import {
    ISlideoverHeaderProps as IHeaderProps,
    SlideoverHeader as Header,
} from '@mondra/ui-components'
import { descriptions } from '../../constants/slideover'
import { nodeColors } from '../../constants/colors'
import { NodeType } from '../../types/types'


export interface ISlideoverHeaderProps extends IHeaderProps {
    type: NodeType
}

export default function SlideoverHeader({ type, ...rest }: ISlideoverHeaderProps) {
    const brandColors = {
        description: nodeColors[type].description || nodeColors[type].title,
        header: nodeColors[type].bg,
        title: nodeColors[type].title,
    }
    return <Header description={descriptions?.[type] || ''} brandColors={brandColors} {...rest} />
}
