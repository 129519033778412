import React from 'react'
import { Card, Icon } from '@mondra/ui-components'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { getProductPageUrl } from '../utils/getURL'
import { CertificateStateEnum } from '../types/types'
import { useCompanyId } from '../hooks/useCompanyId'


interface IPinnedItem {
    id: string
    name: string
    state: string
    supplierId: string
    type: string
}

interface IHeader {
    state: string
    type: string
}

export function PinnedItem({ id, type, name, supplierId, ...rest }: IPinnedItem) {
    const companyId = useCompanyId()
    return (
        <Card
            className="bg-white"
            header={<Header type={type} {...rest} />}
            shadow="border border-gray-200"
        >
            <div className="relative p-6 pt-0 flex flex-col justify-start items-start">
                <p className="font-bold text-base leading-6 truncate w-60 lg:w-full">{name}</p>
                <span className="font-normal text-sm leading-5">{type}</span>
                <div className="mt-8">
                    <Link
                        className="mdp-link font-medium text-sm"
                        to={getProductPageUrl({
                            companyId,
                            isSuppliedProduct: !isEmpty(supplierId),
                            productId: id,
                        })}
                    >
                        View more information
                    </Link>
                </div>
            </div>
        </Card>
    )
}

function Header({ state }: IHeader) {
    return (
        <div className="w-full h-18 flex items-center justify-between px-2 pt-3">
            <div className="flex justify-start items-center gap-2">
                <Icon
                    type={
                        state === CertificateStateEnum.Certified
                            ? 'butterfly'
                            : 'overflowMenuHorizontal'
                    }
                />
                <span className="font-normal text-sm leading-5">
                    {state || CertificateStateEnum.Draft}
                </span>
            </div>
        </div>
    )
}
