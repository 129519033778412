import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import pick from 'lodash/fp/pick'
import some from 'lodash/fp/some'
import { IProduct, NodeType } from '../../types/types'
import { ISlideoverProps } from '../../types/slideover-types'
import useIngredient, { showSingleSuppliedProductPercentage } from '../../hooks/useIngredient'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { INGREDIENT } from '../../constants/toastNotifications'
import slideoverCloseStyles from '../../constants/slideoverCloseStyles'
import SlideoverAlert from '../../product-overiew/SlideoverAlert'
import Slideover from '../components/Slideover'
import IngredientForm from './IngredientForm'
import SingleSuppliedProductPercentageAlert from './SingleSuppliedProductPercentageAlert'


interface IIngredientSlideoverProps extends Omit<ISlideoverProps, 'type'> {
    extraData?: any
    ingredientId?: string
    name?: string
}

const type = NodeType.INGREDIENT

const formId = `${type}-form`

export default function IngredientSlideover({
    companyId,
    extraData,
    ingredientId,
    isOpen,
    level = 1,
    name,
    onClose,
    onSaved,
    wider = 'max-w-3xl',
}: IIngredientSlideoverProps) {
    const { showSuccess, showWarning } = useTopCenterToast()

    const isEdit = !isEmpty(ingredientId)

    const onCreate = (data: any) => {
        showSuccess({ description: isEdit ? INGREDIENT.UPDATE : INGREDIENT.CREATE })
        handleClose()
        onSaved && onSaved({ data, extraData })
    }

    const {
        addIngredientCollectionItem,
        creating,
        createIngredient,
        errors,
        ingredient,
        loading,
        onChange,
        reset,
        updating,
        updateIngredient,
    } = useIngredient({
        defaultName: name,
        ingredientId,
        isEdit,
        onCreate,
        onUpdate: onCreate,
    })

    const handleClose = () => {
        reset()
        onClose(type)
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        const { description, ingredientCollectionMetadata, name, saType } = ingredient
        const payload = {
            description,
            ingredientCollectionMetadata: ingredientCollectionMetadata.map(
                pick(['inputQuantityPercentage', 'productId'])
            ),
            name,
            saType,
        }

        if (isEdit) {
            updateIngredient(payload)
        } else {
            createIngredient(payload)
        }
    }

    const onAddSuppliedProduct = (supplied: IProduct) => {
        const {
            companyId,
            companyName,
            id: productId,
            name: productName,
            supplierId,
            supplierName,
        } = supplied

        if (some(['productId', productId], ingredient.ingredientCollectionMetadata)) {
            showWarning(
                <div>
                    <p>
                        {ingredient.saType} already exists as a Supplied {ingredient.saType} for
                        this Ingredient.
                    </p>
                    <p>
                        To add as a new Supplied {ingredient.saType} for this ingredient you must
                        change the name to proceed.
                    </p>
                </div>
            )
        } else {
            addIngredientCollectionItem({
                inputQuantityPercentage: 0,
                productId,
                productName,
                supplierId: supplierId || companyId,
                supplierName: supplierName || companyName,
            })
        }
    }

    const suppliedProductPercentageWarning = showSingleSuppliedProductPercentage(
        ingredient.ingredientCollectionMetadata
    )

    return (
        <Slideover
            wider={wider}
            isOpen={isOpen}
            level={level}
            formId={formId}
            headerProps={{
                iconProps: slideoverCloseStyles as any,
            }}
            title={`${isEdit ? 'Edit' : 'Add'} Ingredient`}
            type={type}
            saving={creating || updating}
            disableSave={!isEmpty(errors)}
            onClose={handleClose}
            footerChildren={
                <>
                    {errors.noProduct && (
                        <SlideoverAlert
                            title={`Supplied ${ingredient.saType} list is empty`}
                            description={errors.noProduct}
                        />
                    )}
                    {errors.totalPercentage && (
                        <SingleSuppliedProductPercentageAlert
                            saType={ingredient.saType}
                            description={errors.totalPercentage}
                        />
                    )}
                    {!isEmpty(suppliedProductPercentageWarning) && (
                        <SingleSuppliedProductPercentageAlert
                            saType={ingredient.saType}
                            description={suppliedProductPercentageWarning}
                        />
                    )}
                </>
            }
        >
            <IngredientForm
                formId={formId}
                errors={errors}
                loading={loading}
                companyId={companyId}
                ingredient={ingredient}
                onAddSuppliedProduct={onAddSuppliedProduct}
                onChange={onChange}
                onSubmit={handleSubmit}
            />
        </Slideover>
    )
}
