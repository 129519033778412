import React from 'react'
import { Row } from '../../components'
import { IPackagingOption } from '../../types/stages'
import InfoTooltip from '../components/InfoTooltip'
import { MAX_DEC } from '../../constants'


export interface PackagingInfoTooltipProps {
    disabled?: boolean
    packageInfo?: IPackagingOption
}

export default function PackagingInfoTooltip({
    disabled = false,
    packageInfo = {} as IPackagingOption,
}: PackagingInfoTooltipProps) {
    return (
        <InfoTooltip disabled={disabled} className="w-60">
            <form className="divide-y divide-gray-200 text-gray-700">
                <div className="rounded-t-lg p-4 bg-white">
                    <div className="ml-2 rounded-t-lg text-base leading-6 font-semibold">
                        {packageInfo?.name}
                    </div>
                </div>
                {packageInfo?.dimensions && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Dimensions</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {packageInfo?.dimensions}
                        </div>
                    </Row>
                )}
                {packageInfo?.description && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Description</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {packageInfo?.description}
                        </div>
                    </Row>
                )}
                {packageInfo?.source && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Geography</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {packageInfo?.source}
                        </div>
                    </Row>
                )}
                {packageInfo?.capacity && (
                    <Row className="grid-cols-3">
                        <div className="col-span-1 text-sm leading-5 font-semibold whitespace-nowrap">
                            Capacity
                        </div>
                        <div className="ml-8 col-span-2 text-sm leading-5 font-normal whitespace-nowrap">
                            {`${packageInfo?.capacity?.toFixed(MAX_DEC)}kg`}
                        </div>
                        {packageInfo?.maxCapacity !== null && (
                            <>
                                <div className="col-span-1 text-sm leading-5 font-semibold whitespace-nowrap">
                                    Max Capacity
                                </div>
                                <div className="ml-8 col-span-2 text-sm leading-5 font-normal whitespace-nowrap">
                                    {`${packageInfo?.maxCapacity?.toFixed(MAX_DEC)}kg`}
                                </div>
                            </>
                        )}
                    </Row>
                )}
                {packageInfo?.emptyMass && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Empty mass</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {`${packageInfo?.emptyMass?.toFixed(MAX_DEC)}kg`}
                        </div>
                    </Row>
                )}
                {packageInfo?.uses && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Uses</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {packageInfo?.uses}
                        </div>
                    </Row>
                )}
            </form>
        </InfoTooltip>
    )
}
