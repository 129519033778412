import React from 'react'
import { Menu, MoreMenuButton, MenuList, MenuItem } from '../../components'


interface ITransportLegActions {
    onMoveDown: () => void
    onMoveUp: () => void
    disableUp?: boolean
    disableDown?: boolean
}

export function TransportLegActions({
    disableUp,
    disableDown,
    onMoveDown,
    onMoveUp,
}: ITransportLegActions) {
    return (
        <>
            <Menu>
                <MoreMenuButton />
                <MenuList
                    style={{ width: 'max-content', zIndex: 100 }}
                    ariaLabelledby="Product action menu"
                >
                    <MenuItem onSelect={onMoveUp} disabled={disableUp}>
                        Move up
                    </MenuItem>
                    <MenuItem onSelect={onMoveDown} disabled={disableDown}>
                        Move down
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}
