import React, { useMemo } from 'react'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import { Autocomplete } from '@mondra/ui-components'
import { IString, ICustomEventProps, LookupTypeEnum, EntityTypeEnum } from '../../types/types'
import { useLookupApi } from '../../hooks/useLookupApi'
import { useCompanyId } from '../../hooks/useCompanyId'


const labelProps = {
    containerClass: 'col-span-1',
    helpText: 'Set your category based on how you group your products',
}

interface ICategoriesAutocompleteProps {
    autoFetch: boolean
    onChange: (event: ICustomEventProps) => void
    catergoryId: IString
    labelText?: string
    valid?: boolean
}

const filters = {
    entityType: EntityTypeEnum.CATEGORY,
}

export function CategoriesAutocomplete({
    autoFetch,
    catergoryId,
    labelText = 'Client Category',
    onChange,
    ...rest
}: ICategoriesAutocompleteProps) {
    const companyId = useCompanyId()

    const { loading, data: categoriesRes } = useLookupApi({
        autoFetch: autoFetch,
        companyId,
        filters,
        lookupType: LookupTypeEnum.COMPANY_CATEGORIES,
    })

    //TODO: Need to remove this mapping for all auto completes
    //Update AutoComplete props to support name
    const categories = useMemo(() => {
        return map(
            (cat: any) => ({
                ...cat,
                label: cat.name,
            }),
            categoriesRes
        )
    }, [categoriesRes])

    const selected = useMemo(() => {
        return find(i => i.id === catergoryId, categories)
    }, [catergoryId, categories])

    const handleSelected = (changes: any) => {
        const { selectedItem } = changes
        onChange({ target: { name: 'companyCategoryId', value: selectedItem && selectedItem.id } })
    }

    return (
        <Autocomplete
            containerClass="grid grid-cols-2 gap-x-4 z-60"
            placeholder={loading ? 'Loading categories...' : 'Search with category name'}
            labelText={labelText}
            labelProps={labelProps}
            onChange={handleSelected}
            options={categories}
            selected={selected}
            {...rest}
        />
    )
}
