import { useCallback, useState } from 'react'


export type IdType = string | number
export type IdsType = IdType[]

//TODO: Need to extend this multi select for pagination
//Decide whether select all with in page or for all items in all page
export function useMultiSelect(defaultIds: IdsType = []) {
    const [ids, setIds] = useState<IdsType>(defaultIds)
    const mapIds = useCallback((oldIds: IdsType, id: IdType, checked) => {
        const newIds = [...oldIds]
        if (!checked || newIds.includes(id)) {
            return newIds.filter((oldId: IdType) => oldId != id)
        } else {
            newIds.push(id)
            return newIds
        }
    }, [])
    const toggleItem = useCallback((checked, id: IdType) => {
        setIds((oldIds: IdsType) => mapIds(oldIds, id, checked))
    }, [])

    const toggleAllItems = useCallback((checked: boolean, ids: IdsType) => {
        setIds((oldIds: IdsType) =>
            ids.reduce((currentIds, id) => mapIds(currentIds, id, checked), oldIds)
        )
    }, [])

    const resetSelectedIds = useCallback(() => {
        setIds([])
    }, [])

    return {
        ids,
        resetSelectedIds,
        toggleAllItems,
        toggleItem,
    }
}
