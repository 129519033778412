export const ARCHIVE_ERROR = 'ID or API node is not defined'

export const CATEGORY = {
    CREATE: 'Category created successfully',
    UPDATE: 'Category updated successfully',
}

export const SITE = {
    CREATE: 'Site created successfully',
    UPDATE: 'Site updated successfully',
    USUAGE: 'Total site usage should be 100%',
}

export const PRODUCTS = {
    CLONE: 'Product cloned successfully',
    ERROR: 'Error while fetching product data',
    IMAGE_UPDATE: 'Product images are updated successfully',
    INVALID: 'Invalid product or product details not found!',
    LOCKED: 'Product locked successfully',
    UNLOCKED: 'Product unlocked successfully',
    UPDATE: 'Product updated successfully',
}

export const SUPPLIER = {
    ADD: 'New supplier added successfully',
    UPDATE: 'Supplier updated successfully',
}

export const AWARDS = {
    ADD: 'Award is added successfully',
    DELETE: 'Award deleted successfully',
    UPDATE: 'Award is updated successfully',
}

export const INGREDIENT = {
    ADD: 'Please add at least one ingredient',
    CREATE: 'Ingredient created successfully',
    UPDATE: 'Ingredient updated successfully',
}

export const SUPPLY_PRODUCE = {
    CREATE: 'Supplied produce created successfully',
    ERROR: 'Error while fetching produce data',
    UPDATE: 'Supplied produce updated successfully',
}

export const SPECIAL_INGREDIENT = {
    CREATE: 'Special ingredient created successfully',
    ERROR: 'Error while fetching special ingredient data',
    UPDATE: 'Special ingredient updated successfully',
}

export const TRANSPORT = {
    CREATE: 'Transport created successfully',
    UPDATE: 'Transport updated successfully',
}

export const SAVE = {
    SUCCESS: 'Saved successfully',
}

export const CLONE_FAIL = 'Failed to clone'
export const BATCH_INPUT_WEIGHT = 'Batch input weight should be greater than 0'
export const RESOURCE_EMPTY = 'Resource cannot be empty'
export const EXPORT_DATA = 'Successfully exported data to Insights.'
