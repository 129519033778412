import { useCallback, useState } from 'react'


export function useControls() {
    const [isOpened, setIsOpened] = useState(false)

    const open = useCallback(() => setIsOpened(true), [])

    const close = useCallback(() => setIsOpened(false), [])

    return {
        close,
        isOpened,
        open,
    }
}
