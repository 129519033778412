import React, { useContext } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import noop from 'lodash/fp/noop'
import { useForm } from 'react-hook-form'
import { ErrorLabel, FormDialog, Input } from '@mondra/ui-components'
import useAuth from '../../hooks/useAuth'
import { UserContext } from '../../contexts/UserProvider'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { ICategory } from '../../types/types'
import getURL from '../../utils/getURL'
import { METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import { CATEGORY } from '../../constants/toastNotifications'
import { useCompanyId } from '../../hooks/useCompanyId'


interface IAddEditCategoryProps {
    category?: ICategory
    refetch: () => void
    close: () => void
    isOpened: boolean
    isEdit?: boolean
}

type ValidationRecord = Record<string, string | boolean>
const CATEGORY_FORM_VALIDATIONS: Record<string, ValidationRecord> = {
    name: {
        required: true,
    },
}

export function AddEditCategory({
    category,
    refetch,
    close,
    isOpened,
    isEdit,
}: IAddEditCategoryProps) {
    const { showSuccess } = useTopCenterToast()

    const { errors, handleSubmit, register, reset } = useForm({
        reValidateMode: 'onChange',
    })

    const auth = useAuth()
    const { currentUser } = useContext(UserContext)
    const companyId = useCompanyId()

    const { saving, update } = useUpdateFormData({
        method: isEdit ? METHOD_PUT : METHOD_POST,
        onUpdate: () => {
            showSuccess({
                description: isEdit ? CATEGORY.UPDATE : CATEGORY.CREATE,
            })
            close()
            refetch && refetch()
        },
        url: isEdit
            ? getURL(URLs.CompanyCategory.UPDATE, { companyId, id: category?.id })
            : getURL(URLs.CompanyCategory.ADD, { companyId }),
    })

    if (!auth || !currentUser) {
        return null
    }

    function onSubmit(data: any) {
        update({ ...category, ...data })
    }

    function handleClose() {
        reset()
        close()
    }

    const categoryNotValid = !isEmpty(errors.name)

    return (
        <>
            <FormDialog
                formId="add-supplier-form"
                onClose={handleClose}
                open={isOpened}
                primaryBtnText={saving ? 'Saving...' : 'Save'}
                title={isEdit ? 'Edit Category' : 'Add Category'}
            >
                <form id="add-supplier-form" onSubmit={!saving ? handleSubmit(onSubmit) : noop}>
                    <div className="flex flex-col mb-4 space-y-2">
                        <label htmlFor="name">
                            Category name <sup>*</sup>
                        </label>
                        <Input
                            type="text"
                            className="col-span-2"
                            id="name"
                            invalid={categoryNotValid}
                            name="name"
                            placeholder="Category name"
                            defaultValue={category?.name || ''}
                            ref={register(CATEGORY_FORM_VALIDATIONS.name)}
                        />
                        {categoryNotValid && <ErrorLabel>Please enter category name</ErrorLabel>}
                    </div>
                </form>
            </FormDialog>
        </>
    )
}
