import React from 'react'
import { Icon } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'

import { COMPONENT_TYPE_LABELS } from '../constants'
import { NodeType } from '../types/types'
import LightTooltip from './LightTooltip'


export default function ComponentErrorState({
    type = NodeType.INGREDIENT,
    isProduct,
    isArchivedSupplier,
    isNotAvailable,
    isExpired,
    isExpiring,
}: any) {
    const errors: any = {}
    const typeLabel = COMPONENT_TYPE_LABELS[type]

    if (isProduct) {
        errors.isProduct = `This ${typeLabel} has been marked as a Product, if removed you cannot re-add. Check this Product is configured correctly.`
    }
    if (isArchivedSupplier) {
        errors.isArchivedSupplier = `Supplier is archived. This ${typeLabel} can not be used on new Products.`
    }
    if (isNotAvailable) {
        errors.isNotAvailable = `You have ${typeLabel}s which are no longer available as selectable ${typeLabel}s. You may still build and certify but should check these Products.`
    }

    if (isExpired) {
        errors.isExpired = `Component has expired. This ${typeLabel} can not be used on new Products.`
    }

    if (isExpiring) {
        errors.isExpiring = `Component is expiring. Once expired this ${typeLabel} can not be used on new Products.`
    }

    if (isEmpty(errors)) {
        return null
    }

    return (
        <span className="ml-1 text-red-700 flex flex-row items-center">
            !{' '}
            <LightTooltip
                placement="right"
                title={
                    <ol className="text-gray-800 p-3 list-inside">
                        {Object.entries(errors).map(([key, value]: any, _) => (
                            <li key={key} className="mb-1">
                                {value}
                            </li>
                        ))}
                    </ol>
                }
            >
                <Icon type="warningFilled" className="px-1 leading-normal text-base" />
            </LightTooltip>
        </span>
    )
}
