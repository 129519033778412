import React, { createContext } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { URLs } from '../constants'
import useFetch from '../hooks/useFetch'
import { IChildrenProps, INoteItem } from '../types/types'
import getURL from '../utils/getURL'


interface INotesContext {
    loading?: boolean
    notes: INoteItem[]
    notesUnreadCount: number
    refetch: () => void
}

export const NotesContext = createContext<INotesContext>({
    loading: false,
    notes: [],
    notesUnreadCount: 0,
    refetch: () => null,
})

interface INotesProviderProps extends IChildrenProps {
    companyId: string
}

export function NotesProvider({ children, companyId }: INotesProviderProps) {
    const { data: notes, loading, refetch } = useFetch<INoteItem[]>({
        autoFetch: !isEmpty(companyId),
        defaultRes: [],
        url: getURL(URLs.Notes.GET_ROOT_LIST, { companyId }),
    })

    const notesUnreadCount = notes?.filter(n => !n.isThreadRead).length

    return (
        <NotesContext.Provider value={{ loading, notes, notesUnreadCount, refetch }}>
            {children}
        </NotesContext.Provider>
    )
}
