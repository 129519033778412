import { IPackagingOption } from '../../types/stages'


export const getPackageLabel = (packaging: IPackagingOption) => {
    if (!packaging) return ''

    let label = packaging.name
    if (packaging.dimensions && packaging.dimensions !== '-') {
        label += ` - ${packaging.dimensions}`
    }
    if (packaging.source) {
        label += ` - ${packaging.source}`
    }
    if (packaging.description) {
        label += ` - ${packaging.description}`
    }
    return label
}

export const getImpacts = (packaging?: IPackagingOption) => {
    const eutro = Number(packaging?.eutrophication || 0)
    const ghg = Number(packaging?.carbon || 0)
    const water = Number(packaging?.waterUsage || 0)
    return {
        bio: {},
        eutro: {
            perPiece: eutro,
        },
        ghg: {
            perPiece: ghg,
        },
        water: {
            perPiece: water,
        },
    }
}
