import React from 'react'
import classNames from 'classnames'


export function NotesLoading({ className, ...rest }: React.ComponentPropsWithoutRef<'div'>) {
    return (
        <div className={classNames('flex flex-col animate-pulse', className)} {...rest}>
            <div className="w-full">
                <div className="flex flex-row justify-between items-start mb-3">
                    <div className="flex flex-row justify-start gap-x-2">
                        <div className="flex items-center justify-center h-6 w-6 text-xs bg-gray-200" />
                        <div className="flex flex-col">
                            <div className="h-3 w-28 bg-gray-200 mb-1" />
                            <div className="h-2 w-20 bg-gray-200" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="h-2 w-full bg-gray-200 mb-1" />
                    <div className="h-2 w-20 bg-gray-200" />
                </div>
            </div>
        </div>
    )
}
