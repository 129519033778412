import React, { ReactNode, ReactElement, ReactChildren } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TabContext from '@material-ui/lab/TabContext'


export interface ITabContainerProps {
    value: string
    children?: ReactNode | ReactElement | ReactChildren
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .Mui-selected': {
            outline: 'none',
        },
        '& .MuiTabPanel-root': {
            padding: 0,
            paddingTop: theme.spacing(3),
        },
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
}))

export default function TabContainer(props: ITabContainerProps) {
    const classes = useStyles()
    const { value, children, ...rest } = props
    return (
        <div className={classes.root}>
            <TabContext value={value} {...rest}>
                {children}
            </TabContext>
        </div>
    )
}
