import { MAX_DEC } from '../constants'


export const unknownTofixDecimal = (value: any) => {
    if (!isNaN(value) && value !== null && value !== 0) {
        const dec = value.split('.')[1]?.length || 0
        if (dec !== 0 && dec > MAX_DEC) {
            return Number.parseFloat(value).toFixed(MAX_DEC)
        }
        return value
    }
    return value
}
