import React, { useState } from 'react'
import { Icon, RadioGroup } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { IContributionTreeNode } from '../../../types/contribution-tree-types'
import { IEcoImpact, IEcoImpactIdType } from '../../../types/ecoImpacts'
import { round } from '../../../utils/numberFormatter'
import Tooltip from '../../../components/LightTooltip'
import { MAX_DEC, PERCENT, PERCENT_SYMB } from '../../../constants'
import ecoImpactMeasures from '../../../constants/ecoImpactMeasures'


const options = [
    {
        description: '',
        label: PERCENT_SYMB,
        value: PERCENT,
    },
    {
        description: '',
        label: 'Value',
        value: 'value',
    },
]

interface IDataCellProps {
    data: IContributionTreeNode
    impactCode: IEcoImpactIdType
}

export default function ImpactColumn({ data, impactCode }: IDataCellProps) {
    if (data.isSpecialIngredient || data.isStageNode) {
        return (
            <div className="align-center cursor-default">
                <Icon type="impact" size={20} className="text-gray-300" />
            </div>
        )
    }
    return (
        <Tooltip
            interactive
            enterDelay={500}
            enterNextDelay={500}
            leaveDelay={200}
            disableFocusListener
            title={
                <ImpactTooltipContent impacts={data?.ecoImpactStagewise} impactCode={impactCode} />
            }
        >
            <div className="align-center cursor-pointer">
                <Icon type="impact" size={20} className="text-primary-600" />
            </div>
        </Tooltip>
    )
}

interface IImpactTooltipContentProps {
    impacts: IEcoImpact[]
    impactCode: IEcoImpactIdType
}
function ImpactTooltipContent({ impacts, impactCode }: IImpactTooltipContentProps) {
    const [valueType, setValueType] = useState(PERCENT)

    const handleChange = (value: string) => {
        setValueType(value)
    }

    return (
        <div className="bg-white text-gray-500 border-gray-300 border text-sm rounded-md shadow-lg w-60">
            <div className="flex flex-col items-start bg-gray-100 rounded-t-md px-4 py-2 gap-2">
                <div className="text-gray-900 text-lg font-bold">Stage Impacts</div>
                <RadioGroup
                    className="w-52"
                    optionContentClassName="!px-2 !py-1 w-full justify-center"
                    options={options}
                    value={valueType}
                    onChange={handleChange}
                />
            </div>
            <div className="flex flex-col pb-2">
                {isEmpty(impacts) && <div className="p-4">No stage wise impacts</div>}
                {!isEmpty(impacts)
                    && impacts.map(impact => (
                        <div
                            key={impact.stage}
                            className="text-gray-700 flex flex-row items-center justify-between px-4 py-2 border-b border-gray-50 last:border-b-0 hover:bg-gray-50"
                        >
                            <div className="font-medium">{impact.stage}</div>
                            {valueType === PERCENT ? (
                                <div>{`${round(impact.perStagePercentage, 2)}%`}</div>
                            ) : (
                                <div>{`${round(impact.impactScore, MAX_DEC)}${
                                    ecoImpactMeasures[impactCode]?.unit
                                }`}</div>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    )
}
