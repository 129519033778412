import React, { useContext } from 'react'
import { Redirect, useRouteMatch, Switch, Route } from 'react-router-dom'
import { useMount } from 'react-use'
import { Button } from '@mondra/ui-components'

import { UserContext } from '../../contexts/UserProvider'
import { PageTitle } from '../../components/PageTitle'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { NotesProvider } from '../../contexts/NotesProvider'
import { DashboardNotes } from './DashboardNotes'
import { DashboardStats } from './DashboardStats'
import { DashboardPinnedRecentItems } from './DashboardPinnedRecentItems'
import { HomeSidebar } from './HomeSidebar'


export function Home() {
    const { company } = useContext(UserContext)
    const { path } = useRouteMatch()

    useMount(() =>
        window.scrollTo({
            behavior: 'smooth',
            top: 0,
        })
    )

    if (!company) {
        return <Redirect to="/no-company" />
    }

    return (
        <NotesProvider companyId={company.id}>
            <div className="flex flex-row flex-grow">
                <HomeSidebar />
                <GridPageLayout className="max-h-scroll h-scroll overflow-y-auto" isPadding>
                    <Switch>
                        <Redirect exact path={path} to={`${path}/dashboard`} />
                        <Route path={`${path}/dashboard`}>
                            <div className="pt-2 flex items-center justify-between">
                                <PageTitle className="!m-0" title={company.name} />
                                <a
                                    href={process.env.REACT_APP_COMMUNITY_PORTAL_LINK}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-medium text-sm"
                                >
                                    <Button variant="primary">View Insights Suite</Button>
                                </a>
                            </div>
                            <DashboardStats />
                            <DashboardPinnedRecentItems />
                        </Route>
                        <Route path={`${path}/notes`}>
                            <DashboardNotes />
                        </Route>
                    </Switch>
                </GridPageLayout>
            </div>
        </NotesProvider>
    )
}
