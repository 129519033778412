import React, { useMemo } from 'react'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import { useParams } from 'react-router-dom'
import { Autocomplete } from '@mondra/ui-components'
import {
    IParamProps,
    IString,
    ICustomEventProps,
    LookupTypeEnum,
    EntityTypeEnum,
    SA_TYPE,
} from '../../types/types'
import { DOWNSHIT_CHANGE_TYPE, DOWNSHIT_ITEM_SELECT_TYPE } from '../../constants'
import { useLookupApi } from '../../hooks/useLookupApi'


const labelProps = {
    containerClass: 'col-span-1',
    helpText: 'e.g. Winter Weat',
}

const defaultFilters = {
    entityType: EntityTypeEnum.PRODUCT,
    saType: SA_TYPE.SpecialIngredient,
}

interface ISpecialIngredientAutocompleteProps {
    autoFetch: boolean
    onChange: (event: ICustomEventProps) => void
    onSelect: (selectedItem?: any) => void
    productId: IString
    supplierId: IString
    invalid?: boolean
}

export default function SpecialIngredientAutocomplete({
    autoFetch,
    productId,
    supplierId,
    onChange,
    onSelect,
    invalid,
    ...rest
}: ISpecialIngredientAutocompleteProps) {
    const { companyId = '' }: IParamProps = useParams()

    const filters = {
        ...defaultFilters,
        supplierId,
    }

    const { loading, data: ingredientRes } = useLookupApi({
        autoFetch: autoFetch && !isEmpty(supplierId),
        companyId,
        filters,
        lookupType: LookupTypeEnum.SPECIAL_INGREDIENT,
    })

    function handleSelected(changes: any) {
        const { selectedItem } = changes
        onSelect({ ...selectedItem })
    }

    const ingredientOptions = useMemo(() => {
        return map(
            (ing: any) => ({
                ...ing,
                label: ing.name,
            }),
            ingredientRes
        )
    }, [ingredientRes])

    const selected = useMemo(() => {
        return find(i => i.id === productId, ingredientOptions)
    }, [productId, ingredientOptions])

    function handleStateChange(state: any) {
        const { type, inputValue, selectedItem } = state
        if (type === DOWNSHIT_CHANGE_TYPE) {
            onChange({ target: { name: 'name', value: inputValue } })
        }
        if (type === DOWNSHIT_ITEM_SELECT_TYPE) {
            onSelect(selectedItem || selected)
        }
    }

    return (
        <Autocomplete
            containerClass="grid grid-cols-2 gap-x-4 z-1"
            placeholder={
                loading ? 'Loading special ingredient...' : 'Search with special ingredient'
            }
            labelText="Special ingredient name"
            labelProps={labelProps}
            onStateChange={handleStateChange}
            onChange={handleSelected}
            options={ingredientOptions}
            defaultSelected={selected}
            invalid={invalid}
            {...rest}
        />
    )
}
