import { formatISO, formatDistance } from 'date-fns'


export const dateFormatter = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
})

export function toDate(date?: string) {
    if (!date) {
        return new Date()
    }
    return new Date(date)
}

export default function formatDate(date: any) {
    return dateFormatter.format(date)
}

export function formatIsoDate(date: any) {
    return formatISO(toDate(date), {
        representation: 'date',
    })
}

export function hourToDays(hour = 0): string {
    hour = hour || 0

    if (hour < 24) {
        return `${hour} ${hour <= 1 ? 'hour' : 'hours'}`
    }

    const days = Math.floor(hour / 24)
    const hours = Math.floor(hour % 24)

    if (hours === 0) {
        return `${days} ${days <= 1 ? 'day' : 'days'}`
    }

    return `${days} ${days <= 1 ? 'day' : 'days'} and ${hours} ${hours <= 1 ? 'hour' : 'hours'}`
}

export function formatDateDistance(date: string | number, baseDate?: Date) {
    const now = baseDate || new Date()

    return formatDistance(new Date(date), now, {
        addSuffix: true,
        includeSeconds: true,
    })
}
