import React, { useContext, useEffect, useState } from 'react'
import { Input, Label, Notification } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { Row } from '../../components'
import { MAX_DEC } from '../../constants'
import { IStageActivity } from '../../types/stages'
import useProductActivity from '../../hooks/useProductActivity'
import { evaluateInputValue, hasError, setValidationError } from '../../utils'
import { hourToDays } from '../../utils/dateUtils'
import { prepareActivityData } from '../../utils/activity'
import { INumber, NodeType } from '../../types/types'
import { LockActivityContext } from '../../contexts/LockActivityContextProvider'
import { ISlideoverFormProps } from '../component-slideover/ComponentSlideover'
import IsRequiredToggle from '../components/IsRequiredToggle'
import ActivityLoss, { IActivityLossError } from '../components/ActivityLoss'
import ActivityForm, { IActivityFormError, defaultActivityData } from '../components/ActivityForm'


interface IStorageError extends IActivityFormError, IActivityLossError {
    durationInHours?: boolean
}

export default function StorageForm({
    companyId,
    formId,
    onClose,
    onFinished,
    product,
    setDisableSave,
    setSaving,
    updateProductCustomProps,
}: ISlideoverFormProps) {
    const [activityLosses, setActivityLosses] = useState(true)
    const [isRequired, setRequired] = useState<boolean>(product.storageRequired)
    const [duration, setDuration] = useState<string>('')
    const [formData, setFormData] = useState<IStageActivity>(defaultActivityData)
    const isSiteEmpty = isEmpty(product.site?.address)
    const [error, setError] = useState<IStorageError>({
        activity: false,
        durationInHours: false,
        foodLossesPercentage: false,
    })
    const { isLocked, setIsLoading, setIsLocked, setIsSaving, setDisabled } = useContext(
        LockActivityContext
    )

    useEffect(() => {
        setRequired(product.storageRequired)
    }, [product.storageRequired])

    useEffect(() => {
        setDisableSave(hasError(error))
    }, [error])

    const onSaved = () => {
        if (isRequired !== product.storageRequired) {
            updateProductCustomProps({ storageRequired: isRequired })
        } else {
            onFinished && onFinished()
        }
    }

    // Storage Activity
    const { loading, activity, saving, saveActivity } = useProductActivity({
        activityCode: formData?.dataPlatformActivityCode,
        autoFetch: !isEmpty(product?.id),
        companyId,
        onError: onClose,
        onSaved,
        productId: product.id,
        stage: NodeType.STORAGE,
    })

    useEffect(() => {
        setDisableSave(loading)
    }, [loading])

    useEffect(() => {
        setIsLoading(loading)
        setIsLocked(Boolean(activity.isLocked))
    }, [loading, activity.isLocked])

    useEffect(() => {
        handleActivityChange({ isLocked })
    }, [isLocked])

    useEffect(() => {
        setSaving(saving)
        setIsSaving(saving)
    }, [saving])

    useEffect(() => {
        setDisabled(!isRequired)
    }, [isRequired])

    useEffect(() => {
        setDisabled(isEmpty(formData.selectedActivity))
    }, [formData.selectedActivity])

    useEffect(() => {
        handleActivityChange({
            ...activity,
        })
        setDuration(hourToDays(Number(activity.durationInHours)))
    }, [activity])

    const validateForm = () => {
        if (!isRequired) {
            return true
        }

        if (isSiteEmpty) {
            onSaved()
            return
        }

        if (hasError(error)) {
            return false
        }

        return true
    }

    const handleSave = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        if (!validateForm()) {
            return
        }

        setSaving(true)

        // Prepare Storage Activity Data
        const { selectedActivity, ...activityData } = formData
        const postDataActivity: IStageActivity = prepareActivityData({
            activity: activityData,
            product,
            selectedActivity,
            stage: NodeType.STORAGE,
        })
        saveActivity(postDataActivity)
    }

    const disabled = !isRequired || loading || saving || isSiteEmpty

    const handleActivityChange = (changes: Partial<IStageActivity>) => {
        setFormData((data: IStageActivity) => ({
            ...data,
            ...changes,
        }))
    }

    const handleDurationChange = (event: any) => {
        const durationInHours = evaluateInputValue(event, MAX_DEC)
        handleActivityChange({
            durationInHours,
        })
        setDuration(hourToDays(durationInHours))

        setValidationError(durationInHours, 'durationInHours', error, setError)
    }

    const handleLossChange = (foodLossesPercentage: INumber) => {
        handleActivityChange({
            foodLossesPercentage,
        })
    }

    const handleDisposalChange = (disposal: string) => {
        handleActivityChange({
            disposal,
        })
    }

    return (
        <form id={formId} className="divide-y divide-gray-200" onSubmit={handleSave}>
            <IsRequiredToggle
                title="Is storage required?"
                description="If you do not store your final product before sale, set as not required."
                isRequired={isRequired}
                onChange={setRequired}
            />
            <Row className="grid-cols-3 py-0">
                <div className="py-3">
                    <div className="text-sm font-medium text-gray-700">Storage Site</div>
                    <div className="text-sm font-medium text-gray-900">
                        {product.site && product.site?.address ? (
                            <span>
                                {product.site?.name}, {product.site?.address?.country}
                            </span>
                        ) : (
                            <span>N/A</span>
                        )}
                    </div>
                </div>
                {isSiteEmpty && isRequired && (
                    <Notification
                        type="error"
                        className="col-span-2"
                        description="You must Specify a site for where your products are stored."
                    />
                )}
            </Row>

            <ActivityForm
                companyId={companyId}
                title="Site resource usage"
                description="Tell us how much product you package per batch and the energy used."
                resourcesHeading="Selected activity consumes the following site resources per hour of storage:"
                usageUnitText="per kg-hr"
                activity={activity}
                product={product}
                disabled={disabled}
                isLocked={isLocked}
                error={error}
                setActivityLosses={setActivityLosses}
                setError={setError}
                formData={formData}
                onChange={handleActivityChange}
                stage={NodeType.STORAGE}
            />

            <Row className="grid-cols-2">
                <div>
                    <Label helpText="The average time this product is stored before onward sale">
                        Storage duration:
                    </Label>
                </div>
                <div className="flex justify-start items-center mt-2 gap-x-4">
                    <Input
                        type="number"
                        className="w-24 text-right"
                        addonTitle="hours"
                        disabled={disabled || isLocked}
                        value={formData.durationInHours}
                        invalid={error.durationInHours}
                        onChange={handleDurationChange}
                    />
                    <Label>{duration}</Label>
                </div>
            </Row>
            {activityLosses && (
                <ActivityLoss
                    disabled={disabled || isLocked}
                    disposal={formData.disposal}
                    loss={formData.foodLossesPercentage || ''}
                    error={error}
                    setError={setError}
                    title="Food losses due to storage activity:"
                    description="Total percentage of food (un-packaged weight) damaged in the storage process, resulting in it being treated as waste."
                    onDisposalChange={handleDisposalChange}
                    onLossChange={handleLossChange}
                />
            )}
        </form>
    )
}
