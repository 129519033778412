import qs from 'qs'
import isArray from 'lodash/fp/isArray'
import isString from 'lodash/fp/isString'
import compact from 'lodash/fp/compact'
import isEmpty from 'lodash/fp/isEmpty'
import pickBy from 'lodash/fp/pickBy'
import identity from 'lodash/fp/identity'
import { IProductFilters, CertificateStateEnum } from '../types/types'
import { DESC, TYPE_CERTIFIED, TYPE_COMPLETED, TYPE_PROGRESS, UPDATED_DATE } from '../constants'


const qsStringifyOptions: qs.IStringifyOptions = {
    arrayFormat: 'comma',
    encodeValuesOnly: true,
}

const qsParseOptions: qs.IParseOptions = {
    comma: true,
    ignoreQueryPrefix: true,
}

export function getQueryObject(search: any) {
    return qs.parse(search, qsParseOptions)
}

export function stringifyQuery(search: string, newSearch: any) {
    const obj: any = getQueryObject(search)
    const newQueryObj = {
        ...obj,
        ...newSearch,
    }
    return qs.stringify(pickBy(identity, newQueryObj), qsStringifyOptions)
}

export function joinQueryString(qs1 = '', qs2 = '') {
    const delim = qs1.indexOf('?') > -1 ? '&' : '?'
    return `${qs1}${delim}${qs2}`
}

export const createQueryStringFromFilter = (filterSet: any) => {
    const filter = filterSet as Record<string, any>
    return compact(
        Object.keys(filterSet).map((key: string) => {
            const compare = key === 'productName' ? '~=' : '=='
            return !isEmpty(filter[key]) ? `${key}${compare}${filter[key]}` : ''
        })
    ).join(';')
}

interface IgetQueryStringByFilter {
    filters?: IProductFilters
    page?: number
    pageSize?: number
}

const apiQsStringifyOptions: qs.IStringifyOptions = {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
}

export function getQueryStringByFilter({ filters, page, pageSize }: IgetQueryStringByFilter) {
    const q: string[] = []
    const {
        archived,
        direction,
        ecoImpactMetascore,
        filter,
        name,
        Order,
        saState,
        entityType,
        types,
        ...rest
    } = filters || {}

    if (!isEmpty(filters)) {
        if (!isEmpty(name)) {
            q.push(`name~=${name}`)
        }
        if (!isEmpty(saState)) {
            q.push(`saState==${saState === 'null' ? CertificateStateEnum.Draft : saState}`)
        }
        if (!isEmpty(ecoImpactMetascore)) {
            q.push(`ecoImpacts==${ecoImpactMetascore}`)
        }
        if (!isEmpty(filter)) {
            q.push(parseProductFilter(filter as any))
        }
        if (!isEmpty(types)) {
            const saTypes = isArray(types) ? types : isString(types) ? [types] : []
            q.push(`${saTypes.map((t: any) => `saType==${t}`).join(';')};or` as string)
        }
    }

    const Filter = q.join('$')

    return qs.stringify(
        {
            ...(Filter && { Filter }),
            isComplete: getIsCompleteFilter(filter as any),
            Order: Order ? `${Order};${direction}` : `${UPDATED_DATE};${DESC}`,
            PageNumber: page,
            PageSize: pageSize,
            ...(entityType && { [entityType as any]: archived ? 'archive' : 'active' }),
            ...rest,
        },
        apiQsStringifyOptions
    )
}

export function parseProductFilter(filter?: string[]) {
    const certifiedSelected = filter?.includes(TYPE_CERTIFIED)
    const completedSelected = filter?.includes(TYPE_COMPLETED)
    const progressSelected = filter?.includes(TYPE_PROGRESS)

    if (certifiedSelected && !(completedSelected || progressSelected)) {
        return `saState==${CertificateStateEnum.Certified}`
    } else if (!certifiedSelected && (completedSelected || progressSelected)) {
        return `saState!=${CertificateStateEnum.Certified}`
    } else if (certifiedSelected && completedSelected && progressSelected) {
        return ''
    } else if (certifiedSelected && completedSelected) {
        return `saState==${CertificateStateEnum.Certified};isComplete==true;or`
    } else if (certifiedSelected && progressSelected) {
        return `saState==${CertificateStateEnum.Certified};isComplete==false;or`
    } else {
        return ''
    }
}

export function getIsCompleteFilter(filter?: string[]) {
    const certifiedSelected = filter?.includes(TYPE_CERTIFIED)
    const completedSelected = filter?.includes(TYPE_COMPLETED)
    const progressSelected = filter?.includes(TYPE_PROGRESS)

    if (completedSelected && !progressSelected && !certifiedSelected) {
        return true
    }
    if (progressSelected && !completedSelected && !certifiedSelected) {
        return false
    }
    return undefined
}
