import React from 'react'
import { Icon } from '@mondra/ui-components'
import { CertificateStateEnum } from '../types/types'
import getDisplayNameOfState from '../utils/getDisplayNameOfState'
import MondraIcon from './MondraIcon'


interface ICertificateStatus {
    hideIcon?: boolean
    hideLabel?: boolean
    status: CertificateStateEnum
}

const CertificateStatus = ({ hideIcon = false, hideLabel = false, status }: ICertificateStatus) => {
    return (
        <span className="flex flex-row items-center gap-2">
            {!hideIcon && getCertificateStateIcon(status)}
            {!hideLabel && getDisplayNameOfState(status)}
        </span>
    )
}

export const getCertificateStateIcon = (status: CertificateStateEnum) => {
    switch (status) {
        case CertificateStateEnum.Certified:
            return (
                <span className="rounded-full bg-primary-500 p-1.5">
                    <MondraIcon color="#ffffff" width={12} height={12} />
                </span>
            )
        case CertificateStateEnum.CertificationExpired:
        case CertificateStateEnum.Draft:
            return (
                <span className="rounded-full border border-gray-500 w-5 h-5 flex items-center justify-center">
                    <Icon type="overflowMenuHorizontal" className="text-lg" />
                </span>
            )
        default:
            return ''
    }
}

export default CertificateStatus
