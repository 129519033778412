import React from 'react'
import classNames from 'classnames'
import { Button, Icon } from '@mondra/ui-components'

import {
    Menu as ReachMenu,
    MenuButton as ReachMenuButton,
    MenuItem as ReachMenuItem,
    MenuPopover,
} from '@reach/menu-button'


export default function Menu(props: any) {
    return <ReachMenu className="relative inline-block text-left right-0" {...props} />
}

export function MenuButton(props: any) {
    return (
        <ReachMenuButton
            className="rounded-sm outline-none border-none focus:outline-none relative ring-offset-2 focus:ring-1 ring-gray-100 ring-offset-gray-900"
            {...props}
        />
    )
}

export function MoreMenuButton(props: any) {
    return (
        <ReachMenuButton as={Button} variant="ghost" type="button" {...props}>
            <Icon type="overflowMenuVertical" size={16} className="text-current" />
        </ReachMenuButton>
    )
}

export function MenuItem(props: any) {
    const { as, className, disabled, ...rest } = props

    if (disabled) {
        return (
            <div
                className={classNames('flex text-left px-4 py-2 text-sm text-gray-400', className)}
                role="menuitem"
                data-reach-menu-item
                aria-disabled
                style={{ pointerEvents: 'none' }}
                {...rest}
            />
        )
    }

    return (
        <ReachMenuItem
            as={as}
            className={classNames(
                'flex text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none cursor-pointer',
                className
            )}
            role="menuitem"
            {...rest}
        />
    )
}

export function MenuHeader({ title, desc }: any) {
    return (
        <div className="p-4 border-b border-gray-100" role="none">
            <div className="font-medium text-gray-900">{title}</div>
            <div className="text-sm truncate">{desc}</div>
        </div>
    )
}

export function MenuDevider() {
    return <div className="border-b border-gray-200" role="none" />
}

export function MenuList({ className, portal = true, children, ariaLabelledby, ...props }: any) {
    return (
        <MenuPopover
            portal={portal}
            className={classNames(
                'animate-slide-down origin-top-right absolute right-0 mt-2 w-56 rounded-md',
                'shadow-2xl bg-white ring-1 ring-black ring-opacity-5 z-50 focus:outline-none',
                className
            )}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby={ariaLabelledby}
            {...props}
        >
            <div>{children}</div>
        </MenuPopover>
    )
}
