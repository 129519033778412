import { NodeType } from '../types/types'


const productDesc
    = 'Set up your product. You can also use this feature to create compound ingredients or sub-recipes which are only used internally.'

export const descriptions: Record<NodeType, string> = {
    audit: 'View all historic changes to this record. Click an entry to expand for more detail.',
    complexingredient: productDesc,
    Hotspot: 'Add or edit hotspots',
    ingredient:
        'Set up your ingredient. You can also use this feature to create compound ingredients or sub-recipes which are only used internally.',
    ingredientTable: 'Select ingredients to add',
    Insight: 'Add or edit insights',
    Note: 'Add or edit notes and manage priorities here.',
    notes: 'Add or edit notes and manage priorities here.',
    packaging: 'Build your packaging set.',
    processing: 'Select processing activity and configure by-products and losses.',
    produce:
        'Add a produce from one of your suppliers. Specify your own company as supplier to create sub-recipes.',
    product: productDesc,
    recipe: 'Add or edit ingredients to prepare your recipes.',
    sale: 'For products sold to consumers configure your sale method here.',
    salesTransport: 'Set the various transport legs and input the kilometres travelled.',
    simpleingredient: productDesc,
    site:
        'When entering site locations data, we also take into account energy use, water use, as well as waste and by-products.',
    siteResource: 'Manage resources and view products generated at this site.',
    specialingredient: 'Add a special ingredient from one of your suppliers.',
    storage: 'Select storage activity below and set duration.',
    suppliedProduct:
        'Add a product from one of your suppliers. Specify your own company as supplier to create sub-recipes.',
    supplier: '',
    transport:
        'Based on the locations listed and the conditions requirements of the product (ambient, chilled or frozen) set the various transport legs and input the kilometres travelled.',
}
