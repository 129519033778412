import React, { ForwardedRef, forwardRef, Fragment, useCallback, useMemo } from 'react'
import { Autocomplete, Button, Input, Label } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import useCountries from '../../hooks/useCountries'
import { FormRow, SupplierAutoComplete, CategoriesRows } from '../../components'
import SkuIdInput, { ISkuInputRef } from '../../components/SkuIdInput'
import {
    ICountry,
    ICustomEventProps,
    IProduct,
    ISupplier,
    ITaxonomyCategory,
    IValue,
    SA_TYPE,
} from '../../types/types'
import IsRequiredToggle from '../components/IsRequiredToggle'
import { YES_NO_TOGGLE_LABELS } from '../../constants'
import { EmissionFactor } from '../../components/EmissionFactor/EmissionFactor'
import { TechnologySystemsRow } from '../components/TechnologySystemsRow'
import SpecialIngredientAutocomplete from './SpecialIngredientAutocomplete'


interface ISpecialIngredientForm {
    autoFetch: boolean
    companyId: string
    companyName: string
    editMode?: boolean
    errors?: any
    formId?: string
    onChange: (changes: Record<string, IValue>, reset?: boolean) => void
    onSplIngNameChange: (changes: ICustomEventProps) => void
    onSplIngSelect: (selectedItem: any) => void
    onSideloadClick: () => void
    onSumbit: (event: React.FormEvent) => void
    splIngredient: IProduct
    readOnly: boolean
    setSplIngredientChanged: React.Dispatch<React.SetStateAction<boolean>>
}

function SpecialIngredient(
    {
        autoFetch,
        companyId,
        companyName,
        editMode = false,
        errors = {},
        formId = 'supplied-splIngredient-form',
        splIngredient,
        onChange,
        onSplIngNameChange,
        onSideloadClick,
        onSumbit,
        onSplIngSelect,
        readOnly,
        setSplIngredientChanged,
    }: ISpecialIngredientForm,
    ref: ForwardedRef<ISkuInputRef>
) {
    const { countries, loading: loadingCountries } = useCountries({ autoFetch })

    const selectedCountry = useMemo(() => {
        return find({ isoAlpha3: splIngredient.originCountryCode }, countries)
    }, [splIngredient.originCountryCode, countries])

    const handleChange = useCallback((event: ICustomEventProps) => {
        const { name, value } = event.target
        onChange({ [name]: value })
    }, [])

    const handleTechSystemChange = useCallback((techSystem: string) => {
        onChange({ techSystem })
        setSplIngredientChanged(true)
    }, [])

    const handleIsComplete = useCallback(
        (isComplete: boolean) => {
            onChange({ isComplete })
        },
        [onChange]
    )

    const handleCategoryChange = useCallback((category?: ITaxonomyCategory) => {
        onChange({ taxonomyCode: category?.code })
        setSplIngredientChanged(true)
    }, [])

    const handleEmissionFactorChange = useCallback(({ selectedItem }) => {
        onChange({
            emissionFactorCode: selectedItem?.code,
            emissionFactorCountryCode: selectedItem?.countryISO3Code,
        })
        setSplIngredientChanged(true)
    }, [])

    const handleSupplierChange = useCallback((supplier?: ISupplier) => {
        const { supplierId, companyId } = supplier || {}
        onChange(
            {
                companyId,
                supplierId,
            },
            true
        )
    }, [])

    const handleCountryChange = useCallback(({ selectedItem }: { selectedItem: ICountry }) => {
        onChange({
            countryId: selectedItem?.id,
            originCountryCode: selectedItem?.isoAlpha3,
        })
    }, [])

    return (
        <form id={formId} className="pt-2 divide-y divide-gray-200" onSubmit={onSumbit}>
            <FormRow className={!editMode ? 'w-full' : 'grid items-center grid-cols-2 gap-x-4'}>
                {editMode ? (
                    <Fragment>
                        <Label
                            className="col-span-1"
                            helpText="Select supplier if doesn’t exist, create new one"
                            htmlFor="name"
                        >
                            Supplier name
                        </Label>
                        <div className="col-span-1">
                            <Input
                                name="supplierName"
                                value={splIngredient.supplierName}
                                disabled
                            />
                        </div>
                    </Fragment>
                ) : (
                    <SupplierAutoComplete
                        companyId={companyId}
                        companyName={companyName}
                        includeUnknownSupplier
                        includeYourCompany={false}
                        supplierCompanyId={splIngredient.companyId}
                        supplierId={splIngredient.supplierId}
                        onSelect={handleSupplierChange}
                        invalid={errors.supplier}
                        disabled={editMode}
                    />
                )}
            </FormRow>
            <FormRow className={!editMode ? 'w-full' : 'grid items-center grid-cols-2 gap-x-4'}>
                {editMode ? (
                    <Fragment>
                        <Label className="col-span-1" helpText="e.g. Winter Wheat" htmlFor="name">
                            Special ingredient name
                        </Label>
                        <div className="col-span-1">
                            <Input
                                name="name"
                                value={splIngredient.name}
                                invalid={errors.splIngredient}
                                onChange={onSplIngNameChange}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <SpecialIngredientAutocomplete
                        autoFetch={autoFetch}
                        productId={splIngredient.id}
                        supplierId={splIngredient.supplierId}
                        onChange={onSplIngNameChange}
                        onSelect={onSplIngSelect}
                        invalid={errors.splIngredient}
                    />
                )}
            </FormRow>
            <CategoriesRows
                autoFetch={autoFetch}
                categoryCode={splIngredient.taxonomyCode}
                onSelect={handleCategoryChange}
                disabled={readOnly}
                invalid={errors.taxonomyCode}
                saType={SA_TYPE.SpecialIngredient}
            />
            <TechnologySystemsRow
                autoFetch={autoFetch}
                readOnly={readOnly}
                invalid={Boolean(errors.techSystem)}
                onChange={handleTechSystemChange}
                value={splIngredient.techSystem}
            />
            {editMode && (
                <IsRequiredToggle
                    title="Is special ingredient completed?"
                    description="If the special ingredient is all set for certification"
                    disabled={readOnly}
                    helpLabels={YES_NO_TOGGLE_LABELS}
                    isRequired={splIngredient.isComplete}
                    onChange={handleIsComplete}
                />
            )}
            <FormRow>
                <Label className="col-span-1" helpText="e.g. Raw material or SKU ID" htmlFor="sku">
                    Unique id
                </Label>
                <SkuIdInput
                    ref={ref}
                    className="col-span-1"
                    sku={splIngredient.sku || ''}
                    companyId={companyId}
                    productId={splIngredient.id}
                    onChange={handleChange}
                    id="sku"
                    name="sku"
                    disabled={readOnly}
                />
            </FormRow>
            <FormRow>
                <Label
                    className="col-span-1"
                    helpText="Select where your special ingredient is grown"
                >
                    Origin Country
                </Label>
                <Autocomplete
                    placeholder={loadingCountries ? 'Loading...' : 'Select a country'}
                    selected={selectedCountry}
                    disabled={readOnly || loadingCountries}
                    onChange={handleCountryChange}
                    options={countries}
                    invalid={Boolean(errors.originCountryCode)}
                />
            </FormRow>
            <FormRow>
                <Label containerClass="mb-2" htmlFor="ltmPurchaseQuantity">
                    Annual purchase quantity
                </Label>
                <Input
                    type="number"
                    id="ltmPurchaseQuantity"
                    name="ltmPurchaseQuantity"
                    min="0"
                    step="any"
                    className="text-right"
                    addonTitle="kg"
                    value={splIngredient.ltmPurchaseQuantity || ''}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </FormRow>
            <FormRow>
                <Label containerClass="mb-2" htmlFor="ltmSalesQuantity">
                    LTM sale quantity
                </Label>
                <Input
                    type="number"
                    id="ltmSalesQuantity"
                    name="ltmSalesQuantity"
                    min="0"
                    step="any"
                    className="text-right"
                    addonTitle="kg"
                    value={splIngredient.ltmSalesQuantity || ''}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </FormRow>
            {editMode && (
                <div className="flex justify-end px-4 md:px-6 py-4">
                    <Button variant="secondary" onClick={onSideloadClick}>
                        Sideload certificate
                    </Button>
                </div>
            )}
            <FormRow className="w-full">
                <div>
                    <Label
                        className="col-span-1"
                        helpText="Select your emission factor"
                        htmlFor="name"
                    >
                        Emission Factor
                    </Label>
                    <EmissionFactor
                        companyId={companyId}
                        disabled={readOnly}
                        onChange={handleEmissionFactorChange}
                        selected={splIngredient.emissionFactorCode}
                        type={SA_TYPE.SpecialIngredient}
                    />
                </div>
            </FormRow>
        </form>
    )
}

export default forwardRef<ISkuInputRef, ISpecialIngredientForm>(SpecialIngredient)
