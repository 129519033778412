import { CertificateStateEnum } from '../types/types'


const displayNameOfState: Record<CertificateStateEnum, string> = {
    [CertificateStateEnum.Draft]: 'Draft',
    [CertificateStateEnum.Certified]: 'Certified',
    [CertificateStateEnum.CertificationExpired]: 'Certification Expired',
}

export default function getDisplayNameOfState(state: CertificateStateEnum) {
    return displayNameOfState[state]
}
