import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import { Menu, MoreMenuButton, MenuList } from '../../components'
import ArchiveProduct from '../../components/ArchiveProduct'
import { useCompanyId } from '../../hooks/useCompanyId'
import { ICategory } from '../../types/types'


interface ICategoryActionsProps extends ICellRendererParams {
    data: ICategory
}

export function CategoryActions({ data: category, context }: ICategoryActionsProps) {
    const { refetch } = context
    const companyId = useCompanyId()

    return (
        <Menu>
            <MoreMenuButton />
            <MenuList style={{ width: 'max-content' }} ariaLabelledby="Supplier action menu">
                <ArchiveProduct
                    apiNode="company-categories"
                    id={category.id}
                    name={category.name}
                    companyId={companyId}
                    isArchived={category.isArchived}
                    refresh={refetch}
                />
            </MenuList>
        </Menu>
    )
}
