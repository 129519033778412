import React, { useState } from 'react'
import { PRODUCT_SIZES } from '../constants'
import { IProductSizeOption } from '../types/ecoImpacts'
import { IChildrenProps } from '../types/types'


interface IProductSettingContext {
    productSizeMeasure: IProductSizeOption
    readOnly: boolean
    setProductSizeMeasure: (size: IProductSizeOption) => void
}

export const ProductSettingContext = React.createContext<IProductSettingContext>({
    productSizeMeasure: PRODUCT_SIZES[0],
    readOnly: false,
    setProductSizeMeasure: () => null,
})

interface IProductSettingProvider {
    readOnly: boolean
}

export default function ProductSettingProvider({
    children,
    readOnly,
}: IProductSettingProvider & IChildrenProps) {
    const [productSizeMeasure, setProductSizeMeasure] = useState(PRODUCT_SIZES[0])
    return (
        <ProductSettingContext.Provider
            value={{ productSizeMeasure, readOnly, setProductSizeMeasure }}
        >
            {children}
        </ProductSettingContext.Provider>
    )
}
