import React, { useCallback, useEffect, useState } from 'react'
import { Button, InputAddons, Icon, Notification } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { ImpactScoreCell } from '../../components'
import ImpactScoreMultiple from '../../components/ImpactScoreMultiple'
import { MAX_DEC, URLs } from '../../constants'
import useFetch from '../../hooks/useFetch'
import getURL from '../../utils/getURL'
import { round } from '../../utils/numberFormatter'
import { IIngredientCollection, CertificateStateEnum } from '../../types/types'
import { BatchInputType, IIngredientItem } from './RecipeForm'


interface IIngredientListItemProps {
    companyId: string
    index: number
    ingredient: IIngredientItem
    inPercent: boolean
    loading: boolean
    onRemove: (index: number) => () => void
    onWeightChange: any
}

export function IngredientListItem({
    companyId,
    index,
    ingredient,
    inPercent,
    loading,
    onRemove,
    onWeightChange,
}: IIngredientListItemProps) {
    const [ingredientDetailCopy, setIngredientDetailCopy] = useState<IIngredientCollection>()

    const { data: ingredientDetail, loading: loadingDetail, refetch: fetchDetails } = useFetch({
        autoFetch: false,
        url: getURL(URLs.IngredientCollection.DETAILS, {
            companyId,
            ingredientId: ingredient.ingredientId,
        }),
    })

    useEffect(() => {
        if (!loading && !isEmpty(ingredientDetail)) {
            setIngredientDetailCopy(ingredientDetail)
        }
    }, [ingredient, loading, ingredientDetail])

    const onImpactHover = useCallback(() => {
        if (!loadingDetail && !isEmpty(ingredient.ingredientId) && !ingredientDetailCopy) {
            fetchDetails()
        }
    }, [ingredient, ingredientDetailCopy, loadingDetail])

    return (
        <div className="px-4 md:px-6 py-2 grid grid-cols-12 items-center text-sm font-medium text-gray-700">
            <div className="col-span-1">
                {isValidIngredient(ingredient) && (
                    <Icon className="text-primary-500" type="checkmarkOutline" />
                )}
            </div>
            <div className="col-span-5 flex flex-col">
                <div className="text-gray-800 truncate">{ingredient.ingredientName}</div>
                {ingredient.isIngredientArchived && (
                    <Notification type="error" description="Ingredient is archived." />
                )}
            </div>
            <div className="col-span-1 px-1">
                {ingredientDetailCopy?.isSpecialIngredient ? (
                    <ImpactScoreCell
                        className="!justify-end !w-full"
                        ecoImpacts={ingredientDetailCopy.ecoImpacts}
                        score={''}
                        state={CertificateStateEnum.Draft}
                        loading={loadingDetail}
                        onMouseEnter={onImpactHover}
                    />
                ) : (
                    <ImpactScoreMultiple
                        className="!justify-end !w-full"
                        hideGrade
                        impactCollection={ingredientDetailCopy?.suppliedProducts || []}
                        name={ingredientDetailCopy?.name || ''}
                        loading={loadingDetail}
                        onMouseEnter={onImpactHover}
                    />
                )}
            </div>
            {inPercent && (
                <>
                    <div className="col-span-2 flex items-center justify-end pr-4">
                        <div className="text-left truncate" title={`${ingredient?.inputQuantity}`}>
                            {round(ingredient?.inputQuantity, MAX_DEC)}
                        </div>
                        <div className="ml-1 text-left">{BatchInputType.KG}</div>
                    </div>
                    <div className="col-span-2">
                        <InputAddons
                            onChange={onWeightChange(index, BatchInputType.PERCENT)}
                            type="number"
                            className="w-24 text-right"
                            value={ingredient?.inputQuantityPercentage}
                            invalid={
                                !(
                                    ingredient?.inputQuantityPercentage <= 100
                                    && ingredient?.inputQuantityPercentage > 0
                                )
                            }
                            rightAddon={BatchInputType.PERCENT}
                        />
                    </div>
                </>
            )}
            {!inPercent && (
                <>
                    <div className="col-span-2">
                        <InputAddons
                            onChange={onWeightChange(index, BatchInputType.KG)}
                            type="number"
                            className="w-24 text-right"
                            value={ingredient?.inputQuantity}
                            invalid={!(ingredient?.inputQuantity > 0)}
                            rightAddon={BatchInputType.KG}
                        />
                    </div>

                    <div className="col-span-2 text-right">{`${round(
                        ingredient?.inputQuantityPercentage,
                        2
                    )} ${BatchInputType.PERCENT}`}</div>
                </>
            )}
            <div className="col-span-1 text-right">
                <Button
                    variant="secondary"
                    isOnlyIcon
                    iconType="close"
                    onClick={onRemove(index)}
                    size="md"
                />
            </div>
        </div>
    )
}

function isValidIngredient(ingredient: IIngredientItem) {
    return (
        ingredient.inputQuantity > 0
        && ingredient.inputQuantityPercentage <= 100
        && !isEmpty(ingredient.ingredientId)
    )
}
