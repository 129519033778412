import React, { useEffect } from 'react'
import { NotesRow } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { useCommentaryForm } from '../../../hooks/useCommentaryForm'
import { INoteItem, INote } from '../../../types/types'
import { NotesLoading } from './NotesLoading'


interface INotesContentPanelProps extends React.ComponentPropsWithoutRef<'div'> {
    companyId: string
    refetchParent?: () => void
    selectedNote?: INoteItem
}

export function NotesContentPanel({
    className,
    companyId,
    refetchParent,
    selectedNote,
}: INotesContentPanelProps) {
    const {
        creating,
        checkOwnNote,
        handleNoteDelete,
        handleNoteEdit,
        handleNoteReply,
        isPrevCreating,
        isPrevUpdating,
        readNote,
        threadLoading: loading,
        threadNotes: notes,
        updating,
        users,
    } = useCommentaryForm({
        autoFetch: false,
        autoFetchThread: !isEmpty(selectedNote?.id),
        companyId,
        noteId: selectedNote?.id,
        productId: selectedNote?.productId,
        refetchParent,
        stage: selectedNote?.stage,
    })

    useEffect(() => {
        if (!isEmpty(selectedNote?.id) && !selectedNote?.isThreadRead) {
            readNote({})
        }
    }, [selectedNote])

    return (
        <div className={className}>
            {isEmpty(selectedNote) ? (
                <div className="text-gray-500 w-full h-full flex justify-center items-center">
                    No notes selected
                </div>
            ) : (
                <div className="h-full">
                    <div className="flex gap-2 flex-wrap border-b border-gray-300 text-gray-500 text-sm p-4">
                        <div className="flex gap-x-1">
                            <span>From:</span>
                            <span className="text-gray-900">{selectedNote?.userName}</span>
                        </div>
                        <div className="flex gap-x-1">
                            <span>/ Product:</span>
                            <span className="text-gray-900">{selectedNote?.productName}</span>
                        </div>
                        <div className="flex gap-x-1">
                            <span>/ Area:</span>
                            <span className="text-gray-900">{selectedNote?.stage}</span>
                        </div>
                    </div>
                    <div className="bg-gray-50 h-full overflow-y-auto">
                        {loading ? (
                            <NotesLoading className="p-4" />
                        ) : (
                            notes?.map((note: INote) => (
                                <NotesRow
                                    className="!border-0"
                                    key={note.id}
                                    note={note}
                                    defaultExpanded
                                    isOwnNote={checkOwnNote}
                                    onDelete={handleNoteDelete}
                                    onEdit={handleNoteEdit}
                                    onReply={handleNoteReply}
                                    addNotesProps={{
                                        enableMentions: true,
                                        users,
                                    }}
                                    editingText={
                                        updating || (isPrevUpdating && loading) ? 'Updating...' : ''
                                    }
                                    replyingText={
                                        creating || (isPrevCreating && loading) ? 'Replying...' : ''
                                    }
                                />
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
