import { useMemo } from 'react'
import getURL from '../utils/getURL'
import { URLs } from '../constants'
import { useTopCenterToast } from './useTopCenterToast'
import { useCompanyId } from './useCompanyId'
import useUpdateFormData from './useUpdateFormData'


interface IuseProductIsCompleteBulk {
    isComplete: boolean
    refetch: () => void
}

export function useProductIsCompleteBulk({ isComplete, refetch }: IuseProductIsCompleteBulk) {
    const companyId = useCompanyId()
    const { showError, showSuccess } = useTopCenterToast()

    const messageTitle = isComplete ? 'Complete' : 'In Complete'
    const url = useMemo(() => getURL(URLs.Product.IS_COMPLETE_BULK, { companyId, isComplete }), [
        companyId,
        isComplete,
    ])
    const { saving, update } = useUpdateFormData({
        contentType: true,
        method: 'PUT',
        onError: (error: any) => {
            showError({ description: `Failed to Mark as ${messageTitle} - (${error.message})` })
            refetch && refetch()
        },
        onUpdate: () => {
            refetch && refetch()
            showSuccess({ description: `Successfully marked as ${messageTitle}` })
        },
        url,
    })

    return {
        saving,
        update,
    }
}
