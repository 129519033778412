import React from 'react'
import keys from 'lodash/fp/keys'
import map from 'lodash/fp/map'

import { ecoImpactMeasures } from '../../constants/ecoImpactMeasures'
import { IEcoImpactIdType, IEcoImpactItem } from '../../types/ecoImpacts'
import { getImpactValue, getMeasureUnitLabel } from '../../utils/impactUtils'


interface IEcoImpactList {
    ecoImpacts: IEcoImpactItem[]
}

export const EcoImpactList = ({ ecoImpacts }: IEcoImpactList) => {
    return (
        <div className="w-full bg-white text-xs lg:text-sm font-medium">
            {map(
                (key: IEcoImpactIdType) => (
                    <div key={key} className="flex items-center border-t border-gray-300 space-x-4">
                        <div className="pr-2 py-2 text-gray-900 w-2/5">
                            {ecoImpactMeasures[key].label}
                        </div>
                        <div className="pr-2 py-2 font-semibold text-gray-900 text-right flex-grow">
                            {getImpactValue(ecoImpacts, key)}
                        </div>
                        <div className="py-2 text-gray-500 max-w-[76px] w-[76px] flex-shrink-0">
                            {getMeasureUnitLabel(key)}
                        </div>
                    </div>
                ),
                keys(ecoImpactMeasures)
            )}
        </div>
    )
}

export default EcoImpactList
