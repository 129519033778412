import React from 'react'
import { Icon } from '@mondra/ui-components'
import { ColDef } from 'ag-grid-community'
import startCase from 'lodash/fp/startCase'
import CertificateStatus from '../../components/CertificateStatus'
import ImpactScoreCell from '../../components/ImpactScoreCell'
import ImpactScoreMultiple from '../../components/ImpactScoreMultiple'
import { getImpactState } from '../../utils/getImpactFromCollection'
import { filterUniq } from '../../utils'
import { getProductSuppliedByName } from '../../utils/getProductSupplierName'
import { CertificateStateEnum } from '../../types/types'

import IngredientActions from './IngredientActions'
import IngredientNameCell from './IngredientNameCell'


function getUniqSupplierCount(nodes: any[]) {
    if (nodes.length === 0) return 0
    return nodes.map((n: any) => n.supplierId).filter(filterUniq).length
}

export const ingredientColumns = [
    {
        cellRenderer: IngredientNameCell,
        field: 'name',
        headerName: 'Ingredient',
        sortable: true,
    },
    {
        field: 'saType',
        headerName: 'Type',
        maxWidth: 250,
        sortable: true,
        valueFormatter: ({ value }) => startCase(value),
    },
    {
        cellRenderer: ({ data, node }: any) => {
            if (data.isSpecialIngredient) {
                return ''
            }
            if (node.level === 0) {
                const count = getUniqSupplierCount(data.suppliedProducts)
                return `${count} ${count > 1 ? 'Suppliers' : 'Supplier'}`
            }
            const value = getProductSuppliedByName(data)
            return (
                <div className="flex items-center truncate">
                    {value}
                    {data.isLinkedSupplier && <Icon type="link" size={16} className="ml-2" />}
                </div>
            )
        },
        field: 'supplierName',
        headerName: 'Supplier Name',
        maxWidth: 350,
        width: 350,
    },
    {
        cellRenderer: ({ data, value, node }: any) => {
            if (data.isSpecialIngredient) {
                return ''
            }
            const val = node.level > 0 ? `${value}%` : '100%'
            return (
                <div className="flex flex-row items-center">
                    {data?.suppliedProducts?.length > 0 && <Icon type="pie" size={16} />}
                    <span className="">{val}</span>
                </div>
            )
        },
        field: 'inputQuantityPercentage',
        headerName: 'Sourcing',
        maxWidth: 160,
    },
    {
        cellRenderer: ({ data }: any) =>
            data.isSpecialIngredient ? (
                <div />
            ) : data.suppliedProducts?.length > 0 ? (
                <ImpactScoreMultiple
                    impactCollection={data.suppliedProducts}
                    name={data.name}
                    iconSize={16}
                />
            ) : (
                <ImpactScoreCell
                    state={data.saState}
                    score={data.ecoImpactMetascore || ''}
                    ecoImpacts={data.ecoImpacts}
                    iconSize={16}
                />
            ),
        field: 'ecoImpactMetascore',
        headerName: 'Impact',
        maxWidth: 220,
    },
    {
        cellRenderer: ({ data, value }: any) => {
            if (data.isSpecialIngredient) {
                return <div />
            }
            const state
                = data.suppliedProducts?.length > 0 ? getImpactState(data.suppliedProducts) : value
            return <CertificateStatus status={state || CertificateStateEnum.Draft} />
        },
        field: 'saState',
        headerName: 'State',
        maxWidth: 220,
    },
    {
        cellClass: 'ag-center-cell !p-0',
        cellRenderer: ({ node, data, context }: any) =>
            node.level === 0
            && !data.isSpecialIngredient && (
                <IngredientActions node={data} refetch={context.refetch} />
            ),
        colId: 'actions',
        field: '',
        flex: 0,
        headerName: '',
        maxWidth: 40,
    },
] as Array<ColDef>
