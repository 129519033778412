import React from 'react'
import { Icon } from '@mondra/ui-components'
import { Link } from 'react-router-dom'
import { IProduct } from '../types/types'
import { getProductPageUrl } from '../utils/getURL'


interface IProductNameProps {
    companyId: string
    newTab?: boolean
    product: IProduct
}

export default function ProductName({ companyId, newTab, product }: IProductNameProps) {
    const linkProps = newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {}
    return (
        <div className="flex flex-row items-center">
            {product.isArchived ? (
                <span className="font-medium  overflow-ellipsis overflow-hidden">
                    {product.name}
                </span>
            ) : (
                <Link
                    title={product.name}
                    className="mdp-link font-medium underline overflow-ellipsis overflow-hidden"
                    to={getProductPageUrl({ companyId, productId: product.id })}
                    {...linkProps}
                >
                    {product.name}
                </Link>
            )}
            {product.isProductLocked && (
                <Icon
                    type="locked"
                    size={16}
                    className="ml-2 text-gray-500"
                    title="This product is locked"
                />
            )}
        </div>
    )
}
