import { IEmissionFactor } from '../../types/stages/emission-factor-types'


export const getImpacts = (ecoImpacts?: IEmissionFactor) => {
    const eutro = Number(ecoImpacts?.eutrophication_kg_PO43_eq || 0)
    const ghg = Number(ecoImpacts?.carbon_kg_CO2_eq || 0)
    const bio = Number(ecoImpacts?.biodiversity_sp_yr_10_14 || 0)
    const water = Number(ecoImpacts?.wtr_Sc_Farm_Farm_L_eq || 0)
    return {
        bio: {
            per100g: bio / 10,
            perKg: bio,
        },
        eutro: {
            per100g: eutro / 10,
            perKg: eutro,
        },
        ghg: {
            per100g: ghg / 10,
            perKg: ghg,
        },
        water: {
            per100g: water / 10,
            perKg: water,
        },
    }
}
