import React, { useState } from 'react'
import { Button, Checkbox, ConfirmDialog } from '@mondra/ui-components'
import classNames from 'classnames'
import { IClassNameProps, IProduct } from '../../types/types'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { METHOD_PUT, URLs } from '../../constants'
import { PRODUCTS } from '../../constants/toastNotifications'
import getURL from '../../utils/getURL'
import { LightTooltip } from '../../components'


interface ILockStatusProps extends IClassNameProps {
    companyId: string
    disabled?: boolean
    isProductLocked?: boolean
    productId: string
    onProductUpdate?: (changes: Partial<IProduct>) => void
}

export default function LockStatus({
    className,
    companyId,
    disabled,
    isProductLocked,
    onProductUpdate,
    productId,
}: ILockStatusProps) {
    const [open, setOpen] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [checkChild, setCheckChild] = useState(false)
    const { showSuccess } = useTopCenterToast()

    const onUpdate = () => {
        showSuccess({
            description: isProductLocked ? PRODUCTS.UNLOCKED : PRODUCTS.LOCKED,
        })
        onProductUpdate && onProductUpdate({ isProductLocked: !isProductLocked })
    }

    const { saving, update } = useUpdateFormData({
        method: METHOD_PUT,
        onUpdate,
        url: getURL(URLs.Product.LOCK_UNLOCK, { companyId, productId }),
    })

    const handleTooltipOpen = () => {
        setTooltipOpen(true)
    }

    const handleTooltipClose = () => {
        setTooltipOpen(false)
    }

    const handleOpen = () => {
        handleTooltipClose()
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setCheckChild(false)
    }

    const handleConfirm = () => {
        update({
            isProductLocked: !isProductLocked,
            shouldCascadeToChildren: checkChild,
        })
        handleClose()
    }

    const handleCheckChildChange = (event: any) => {
        setCheckChild(event.target.checked)
    }

    const classes = classNames({
        '!rounded-full !bg-gray-100 text-gray-500 hover:!bg-gray-300 hover:!text-gray-500': isProductLocked,
        '!rounded-full bg-emerald-50 text-emerald-500 hover:!bg-emerald-100 hover:!text-emerald-500': !isProductLocked,
    })

    return (
        <div className={className}>
            <LightTooltip
                className={disabled ? 'pointer-events-none' : ''}
                placement="right-start"
                open={tooltipOpen}
                onOpen={handleTooltipOpen}
                onClose={handleTooltipClose}
                title={
                    <div className="text-gray-800 p-3 text-sm">
                        {`Click to ${isProductLocked ? 'unlock' : 'lock'} this product`}
                    </div>
                }
            >
                <Button
                    iconType={saving ? 'spinnerThird' : isProductLocked ? 'locked' : 'unlocked'}
                    iconClass={classNames({ 'animate-spin': saving })}
                    isOnlyIcon
                    className={classes}
                    disabled={disabled || saving}
                    onClick={handleOpen}
                />
            </LightTooltip>
            <ConfirmDialog
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
                variant="warn"
                title="Warning!"
            >
                <div className="mb-2">{`Are you sure to ${
                    isProductLocked ? 'unlock' : 'lock'
                } this product?`}</div>
                <label className="flex justify-start items-center gap-x-1 -ml-0.5">
                    <Checkbox checked={checkChild} onChange={handleCheckChildChange} />{' '}
                    {isProductLocked ? 'Unlock' : 'Lock'} children
                </label>
            </ConfirmDialog>
        </div>
    )
}
