import React from 'react'
import PageLoader from '../PageLoader'


export function LoadingOverlay() {
    return (
        <div>
            <div className="flex justify-center items-center">
                <PageLoader className="mr-2" sizeClass="w-6 h-6" />
                <span>Loading...</span>
            </div>
        </div>
    )
}
