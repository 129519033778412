import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { useCompanyId } from '../hooks/useCompanyId'
import { ITaxonomyCategory, SA_TYPE } from '../types/types'
import useFetch from '../hooks/useFetch'
import { URLs } from '../constants'
import getURL from '../utils/getURL'
import { CategoryAutocomplete } from './CategoryAutocomplete'
import { FormRow } from '.'


const HelpTexts = {
    [SA_TYPE.ComplexIngredient]: { category: '', subcategory: '', taxonomy: '' },
    [SA_TYPE.Produce]: {
        category: 'e.g. Fruit and nuts',
        subcategory: 'e.g. Citrus fruits',
        taxonomy: 'e.g. Grapefruit',
    },
    [SA_TYPE.Product]: { category: '', subcategory: '', taxonomy: '' },
    [SA_TYPE.SimpleIngredient]: {
        category: 'e.g. Fruit and nuts',
        subcategory: 'e.g. Pome fruits and stone',
        taxonomy: 'e.g. Apple juice',
    },
    [SA_TYPE.SpecialIngredient]: {
        category: 'e.g. Raising agent',
        subcategory: 'e.g. Baking soda',
        taxonomy: 'e.g. Sodium bicarbonate',
    },
}

interface ICategoriesRowsProps {
    autoFetch: boolean
    categoryCode?: string
    disabled?: boolean
    invalid?: boolean
    onSelect: (category?: ITaxonomyCategory) => void
    saType: SA_TYPE
}

export function CategoriesRows({
    autoFetch,
    categoryCode: code,
    disabled,
    onSelect,
    saType: type,
    ...rest
}: ICategoriesRowsProps) {
    const companyId = useCompanyId()

    const [l1Category, setL1Category] = useState<ITaxonomyCategory>()
    const [l2Category, setL2Category] = useState<ITaxonomyCategory>()
    const [l3Category, setL3Category] = useState<ITaxonomyCategory>()

    const { data } = useFetch({
        autoFetch: autoFetch && !isEmpty(code),
        url: getURL(URLs.Category.SEARCH_BY_CODE, { code, companyId, type }),
    })

    useEffect(() => {
        if (!isEmpty(data)) {
            handleL1Change({ code: data.l1Code, name: data.l1Name })
            handleL2Change({ code: data.l2Code, name: data.l2Name })
            handleL3Change({ code: data.code, id: data.id, name: data.name })
        }
    }, [data])

    const handleL1Change = (selected: ITaxonomyCategory) => {
        setL1Category(selected)
        handleL2Change(undefined)
    }
    const handleL2Change = (selected?: ITaxonomyCategory) => {
        setL2Category(selected)
        handleL3Change(undefined)
    }
    const handleL3Change = (selected?: ITaxonomyCategory) => {
        setL3Category(selected)
        onSelect(selected)
    }

    return (
        <>
            <FormRow className="flex flex-col">
                <CategoryAutocomplete
                    autoFetch={autoFetch}
                    companyId={companyId}
                    categoryCode={l1Category?.code}
                    disabled={disabled}
                    queryString="?level=1"
                    type={type}
                    labelText="Category"
                    placeholder="Search with category name"
                    helpText={HelpTexts[type]?.category}
                    onSelect={handleL1Change}
                    {...rest}
                />
            </FormRow>
            <FormRow className="flex flex-col">
                <CategoryAutocomplete
                    autoFetch={autoFetch && !isEmpty(l1Category)}
                    companyId={companyId}
                    categoryCode={l2Category?.code}
                    queryString={`?level=2&parentCode=${l1Category?.code}`}
                    type={type}
                    disabled={disabled || isEmpty(l1Category)}
                    labelText="Sub Category"
                    placeholder="Search with sub category name"
                    helpText={HelpTexts[type]?.subcategory}
                    onSelect={handleL2Change}
                    {...rest}
                />
            </FormRow>
            <FormRow className="flex flex-col">
                <CategoryAutocomplete
                    autoFetch={autoFetch && !isEmpty(l2Category)}
                    companyId={companyId}
                    categoryCode={l3Category?.code}
                    queryString={`?level=3&parentCode=${l2Category?.code}`}
                    type={type}
                    disabled={disabled || isEmpty(l2Category)}
                    labelText="Taxonomy Name"
                    placeholder="Search with taxonomy name"
                    helpText={HelpTexts[type]?.taxonomy}
                    onSelect={handleL3Change}
                    {...rest}
                />
            </FormRow>
        </>
    )
}
