import { useEffect, useState } from 'react'

import { fetchClient, IfetchClient } from '../utils/fetchClient'
import { useTopCenterToast } from './useTopCenterToast'
import useAuth from './useAuth'
import useCache from './useCache'


interface IUseFetchParams extends Omit<IfetchClient, 'acquireToken' | 'onError' | 'onSuccess'> {
    autoFetch?: boolean
    cache?: boolean
    defaultRes?: any
    mock?: boolean
    mockData?: any
    onError?: (err: any) => void
}

export default function useFetch<T = any>({
    autoFetch = true,
    baseUrlPrefix,
    body,
    cache = false,
    defaultRes = {},
    isNoCompanyScope,
    errMessage,
    method,
    mock,
    mockData,
    onError,
    url,
}: IUseFetchParams) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<T>(defaultRes)
    const [error, setError] = useState()

    const { acquireToken } = useAuth()
    const { showError } = useTopCenterToast()
    const { getFromCache, hasCache, setCache } = useCache()

    useEffect(() => {
        if (autoFetch && url) {
            run(url)
        }
    }, [url, autoFetch])

    const refetch = (_url?: string) => {
        run(_url || url, false)
    }

    function handleError(err: any) {
        setError(err?.message)
        setLoading(false)
        onError && onError(err)
    }

    function handleSuccess(data: any) {
        if (cache) {
            setCache(url, data)
        }
        setData(data)
        setLoading(false)
    }

    const run = (url: string, cached = true) => {
        if (mock) {
            setTimeout(() => {
                setData(mockData || defaultRes)
            }, 1000)
            return
        }
        if (cached && cache && hasCache(url)) {
            setData(getFromCache(url, []))
            return
        }
        setLoading(true)
        setError(undefined)
        fetchClient({
            acquireToken,
            baseUrlPrefix,
            body,
            errMessage,
            isNoCompanyScope,
            method,
            onError: handleError,
            onSuccess: handleSuccess,
            showError,
            url,
        })
    }

    return { data, error, loading, refetch }
}
