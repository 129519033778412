import React from 'react'
import { Dialog } from '@mondra/ui-components'
import ImpactDetailsByStage from './ImpactDetailsByStage'


interface ImpactDetailsModalProps {
    facts?: any
    onClose: any
    open: boolean
    stageImpacts: any
}

export default function ImpactDetailsModal({ onClose, open, ...rest }: ImpactDetailsModalProps) {
    return (
        <Dialog open={open} onClose={onClose} className="md:max-w-[1000px]">
            <Dialog.Description>
                <ImpactDetailsByStage {...rest} />
            </Dialog.Description>
        </Dialog>
    )
}
