import { NOTE_TYPE } from '../../types/types'


export const noData = {
    descr: {
        [NOTE_TYPE.NOTE]: 'You can add a new note from the bottom input and set its priority.',
        [NOTE_TYPE.INSIGHT]: 'You can add a new insight from the bottom input',
        [NOTE_TYPE.HOTSPOT]: 'You can add a new hotspot from the bottom input',
    },
    title: {
        [NOTE_TYPE.NOTE]: 'No notes added',
        [NOTE_TYPE.INSIGHT]: 'No insights added',
        [NOTE_TYPE.HOTSPOT]: 'No hotspots added',
    },
}

export const commentaryPlaceholders = {
    [NOTE_TYPE.INSIGHT]: 'an insight',
    [NOTE_TYPE.HOTSPOT]: 'a hotspot',
}
