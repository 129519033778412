import React from 'react'
import { Icon } from '@mondra/ui-components'
import { CertificateStateEnum } from '../types/types'
import { STAT_ICONS } from '../constants'
import formatDate from '../utils/dateUtils'
import { CompleteState } from './CompleteState'


interface IProductStateCellProps {
    certifiedUntilDate?: string
    isComplete: boolean
    state: CertificateStateEnum
}

export default function ProductStateCell({
    certifiedUntilDate,
    isComplete,
    state = CertificateStateEnum.Draft,
}: IProductStateCellProps) {
    if (state === 'Certified') {
        return (
            <div className="flex flex-row items-center gap-2">
                <Icon type={STAT_ICONS['certified']} className="text-primary-500" size={16} />{' '}
                <div className="text-gray-700">Certified</div>
                {certifiedUntilDate && <div>({formatDate(new Date(certifiedUntilDate))})</div>}
            </div>
        )
    }

    return (
        <div>
            <CompleteState isComplete={isComplete} />
        </div>
    )
}
