import React, { useContext, useEffect } from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import head from 'lodash/fp/head'

import { resolveRouteURL } from '../utils/getURL'
import { SELECTED_COMPANY } from '../constants'
import urls from '../constants/urls'
import { UserContext } from '../contexts/UserProvider'
import useAuth from '../hooks/useAuth'


export default function RedirectToPath() {
    const routeMatch = useRouteMatch()
    const { companies } = useContext(UserContext)
    const { setCompanyId } = useAuth()
    const company = head(companies)
    const selectedCompany = window.localStorage.getItem(SELECTED_COMPANY)

    if (!company) {
        return <Redirect to="/no-company" />
    }

    useEffect(() => {
        setCompanyId(selectedCompany || company?.id)
    }, [company])

    return (
        <Redirect
            to={
                selectedCompany
                    ? resolveRouteURL(urls.PagePaths.HOME, { companyId: selectedCompany })
                    : `${routeMatch.path}/${company?.id}`
            }
        />
    )
}
