import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from '@mondra/ui-components'
import { NodeState } from '../../types/types'
import getURL from '../../utils/getURL'
import { STAT_ICONS, TYPE_CERTIFIED, TYPE_COMPLETED, TYPE_PROGRESS, URLs } from '../../constants'

import { useProductStats } from '../../hooks/useProductStats'
import { useSupplyChainStats } from '../../hooks/useSupplyChainStats'
import { useCompanyId } from '../../hooks/useCompanyId'
import { joinQueryString } from '../../utils/queryString'
import StatsLoader from './StatsLoader'


export function DashboardStats() {
    const companyId = useCompanyId()
    const { productStats, productStatsLoading } = useProductStats()
    const { supplyChainStats, supplyChainStatsLoading } = useSupplyChainStats()

    if (productStatsLoading || supplyChainStatsLoading) {
        return <StatsLoader />
    }

    return (
        <div>
            <div className="bg-white w-full animate-fade-in p-6">
                <h4 className="text-base text-gray-800 font-bold mb-3">Products</h4>
                <dl className="flex flex-wrap items-center mt-2">
                    <OverviewStats
                        {...productStats}
                        linkUrl={getURL(URLs.Pages.PRODUCTS, { companyId })}
                    />
                </dl>
                <hr className="mb-6" />
                <h4 className="text-base text-gray-800 font-bold mb-3">Supply chain</h4>
                <dl className="flex flex-wrap items-center mt-2">
                    <LinkedStats
                        {...supplyChainStats}
                        linkUrl={getURL(URLs.Pages.SUPPLIED_PRODUCTS, { companyId })}
                    />
                </dl>
            </div>
        </div>
    )
}

interface IStatDataProps {
    linkUrl: string
    total: number
    certified: number
    completed: number
    inProgress: number
}

function OverviewStats({ linkUrl, total, certified, completed, inProgress }: IStatDataProps) {
    return (
        <>
            <Stat type="" count={total} title="Products" iconType="product" link={linkUrl} />
            <Stat
                type={TYPE_CERTIFIED}
                count={certified}
                title="Certified"
                iconType="certified"
                link={linkUrl}
            />
            <Stat
                type={TYPE_COMPLETED}
                count={completed}
                title="Data complete"
                iconType="review"
                link={linkUrl}
            />
            <Stat
                type={TYPE_PROGRESS}
                count={inProgress}
                title="In progress"
                iconType="progress"
                link={linkUrl}
            />
        </>
    )
}

function LinkedStats({ linkUrl, total, certified, completed, inProgress }: IStatDataProps) {
    return (
        <>
            <Stat type="" count={total} title="Ingredients" iconType="ingredients" link={linkUrl} />
            <Stat
                type={TYPE_CERTIFIED}
                count={certified}
                title="Certified"
                iconType="certified"
                textColor="text-violet-500 hover:text-violet-800"
                link={linkUrl}
            />
            <Stat
                type={TYPE_COMPLETED}
                count={completed}
                title="Data complete"
                iconType="review"
                textColor="text-violet-500 hover:text-violet-800"
                link={linkUrl}
            />
            <Stat
                type={TYPE_PROGRESS}
                count={inProgress}
                title="In progress"
                iconType="progress"
                textColor="text-violet-500 hover:text-violet-800"
                link={linkUrl}
            />
        </>
    )
}

interface IStatsProps {
    count?: number
    title?: string
    iconType?: NodeState | 'product' | 'ingredients'
    textColor?: string
    link?: string
    type: string
}

function Stat({ count = 0, title = 'Products', iconType = 'product', link, type }: IStatsProps) {
    return (
        <NavLink to={joinQueryString(link, `filter=${type}`)}>
            <div className="pt-6 px-6 pb-8 overflow-hidden motion-safe:transition-all bg-white hover:bg-primary-500 text-gray-700 hover:text-white min-w-[156px] min-h-[156px] max-w-[156px] max-h-[156px]">
                <dt>
                    <div className="mb-7">
                        <Icon type={STAT_ICONS[iconType]} className="text-current" />
                    </div>
                    <p className="text-sm font-medium">{title}</p>
                </dt>
                <dd className="flex items-baseline mt-1">
                    <p className="text-3xl font-medium">{count}</p>
                </dd>
            </div>
        </NavLink>
    )
}
