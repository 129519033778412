import React from 'react'


export default function StatsLoader() {
    return (
        <div className="bg-white w-full p-6 animate-pulse">
            <h4 className="h-5 w-40 bg-gray-200 mb-3">
                <span className="sr-only">heading</span>
            </h4>
            <dl className="flex flex-wrap items-center mt-2">
                <StatLoader />
                <StatLoader />
                <StatLoader />
                <StatLoader />
            </dl>
            <hr className="mb-6" />
            <h4 className="h-5 w-40 bg-gray-200 mb-3">
                <span className="sr-only">heading</span>
            </h4>
            <dl className="flex flex-wrap items-center mt-2">
                <StatLoader />
                <StatLoader />
                <StatLoader />
                <StatLoader />
            </dl>
        </div>
    )
}

function StatLoader() {
    return (
        <div className="pt-6 px-6 pb-8 overflow-hidden motion-safe:transition-all bg-white  min-w-[156px] min-h-[156px] max-w-[156px] max-h-[156px]">
            <dt>
                <div className="h-6 w-6 bg-gray-200 mb-7" />
                <p className="h-4 w-24 bg-gray-200" />
            </dt>
            <dd className="flex items-baseline mt-1">
                <p className="h-10 w-14 bg-gray-200" />
            </dd>
        </div>
    )
}
