import React from 'react'
import { formatDistanceToNowStrict, format } from 'date-fns'
import getDisplayNameOfState from '../utils/getDisplayNameOfState'
import { EcoImpactTable } from '../components/EcoImpactTable'
import { findImpactWithStats } from '../utils/findImpact'
import { DataQualityAttributes, DataQualityHeader } from '../widgets/data-quality-score'
import CertHistoryCards from '../product-overiew/CertHistoryCards'
import { ICertCardProps } from '../types/types'

import { formatIsoDate, toDate } from '../utils/dateUtils'
import { UnderReviewIcon } from './CertificationStateIcon'


export default function DraftCertCard({ cert }: ICertCardProps) {
    const { ghg, eutro, water, bio } = findImpactWithStats(cert?.ecoImpacts)

    return (
        <CertHistoryCards.Row>
            <CertHistoryCards.CertTimeline
                title={formatDistanceToNowStrict(toDate(cert.updatedAt))}
                dateTime={formatIsoDate(cert.updatedAt)}
                dateTimeDisplay={format(toDate(cert.updatedAt), 'EEEE dd MMMM yyyy')}
                icon={<UnderReviewIcon />}
            />
            <CertHistoryCards.Box>
                <CertHistoryCards.Header
                    bgKey="requested"
                    title={getDisplayNameOfState(cert.saState)}
                    desc="Uncertified impacts"
                />
                <CertHistoryCards.Body>
                    <EcoImpactTable ghg={ghg} eutro={eutro} water={water} bio={bio} />
                    <DataQualityAttributes
                        attributes={cert.dataQualityScore?.attributes}
                        header={<DataQualityHeader score={cert.dataQualityScore?.score} />}
                    />
                </CertHistoryCards.Body>
            </CertHistoryCards.Box>
        </CertHistoryCards.Row>
    )
}
