import isEmpty from 'lodash/fp/isEmpty'
import { useContext } from 'react'
import getOr from 'lodash/fp/getOr'
import { URLs } from '../constants'
import getURL from '../utils/getURL'
import { UserContext } from '../contexts/UserProvider'
import useFetch from './useFetch'


export const useCountsFetch = ({ productId, stage }: any) => {
    const { company } = useContext(UserContext)
    const companyId = getOr('', 'id', company)
    const doAutoFetch = !isEmpty(companyId) && !isEmpty(productId) && !isEmpty(stage)

    const { data: counts, refetch: refetchCounts } = useFetch({
        autoFetch: doAutoFetch,
        defaultRes: 0,
        url: getURL(URLs.Notes.COUNT, { companyId, productId }, `?stage=${stage}`),
    })

    return {
        counts: {
            hotspotsCount: counts.hotspots,
            insightsCount: counts.insights,
            notesCount: counts.notes,
        },
        refetchCounts,
    }
}
