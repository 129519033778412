import React, { useContext } from 'react'
import classNames from 'classnames'
import { Button, Tooltip } from '@mondra/ui-components'
import { LockActivityContext } from '../../contexts/LockActivityContextProvider'


export function ActivityLock() {
    const { disabled, isLocked, isLoading, isSaving, setIsLocked } = useContext(LockActivityContext)
    const showLoader = isLoading || isSaving

    const handleLockIcon = () => {
        setIsLocked(!isLocked)
    }

    return (
        <Tooltip
            className={disabled || showLoader ? 'pointer-events-none' : ''}
            content={
                <div className="text-white">
                    <div className="text-sm font-medium">{`Click to ${
                        isLocked ? 'unlock' : 'lock'
                    } this activity`}</div>
                    <p className="text-xs mt-2">When locked data will update from Data Platform.</p>
                </div>
            }
        >
            <Button
                iconType={showLoader ? 'spinnerThird' : isLocked ? 'locked' : 'unlocked'}
                iconClass={classNames({ 'animate-spin': showLoader })}
                isOnlyIcon
                className={classNames('!rounded-full', {
                    '!bg-gray-100 text-gray-500 hover:enabled:!bg-gray-300 hover:enabled:!text-gray-500': isLocked,
                    'bg-emerald-50 text-emerald-500 hover:enabled:!bg-emerald-100 hover:enabled:!text-emerald-500': !isLocked,
                })}
                disabled={disabled || showLoader}
                onClick={handleLockIcon}
            />
        </Tooltip>
    )
}
