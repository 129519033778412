import React from 'react'


interface ITabsCountLabel {
    title: string
    count: number
}

export const TabsCountLabel = ({ title, count }: ITabsCountLabel) => (
    <div className="flex">
        {title}
        <div className="bg-primary-600 text-white text-xs rounded-full w-6 h-5 ml-2 flex justify-center items-center">
            {' '}
            {count}
        </div>
    </div>
)
