import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Icon } from '@mondra/ui-components'
import classNames from 'classnames'
import isEmpty from 'lodash/fp/isEmpty'
import startCase from 'lodash/fp/startCase'
import { NodeState, NodeType } from '../../../types/types'
import { ISCNodeColor, ISupplyChainNode, SupplyChainStage } from '../../../types/supply-chain-types'
import {
    checkOwnership,
    getIconByStage,
    getRequiredStages,
    getSupplyChainNodeColor,
    isCertifiedState,
    isNodeAccessible,
} from '../../../utils/supplyChainUtils'
import { isWaterSplIngredient } from '../../../utils'
import { ScCertifiedNodeColors, ScNodeColor, ScStageWiseColors } from '../../../constants/colors'
import WaterIconTooltip from '../../../components/WaterIconTooltip'
import { SlideoverContext } from '../../../contexts/SlideoverProvider'
import { TileMenuContext } from '../TileMenuProvider'
import SelfManagedTooltip from '../self-managed-tooltip/SelfManagedTooltip'
import { TYPE_PROGRESS, statusIcons } from '../../../constants'
import AnimatedDiv from './AnimatedDiv'


interface ISupplyChainNodeProps {
    node: ISupplyChainNode
    noIngredient?: boolean
}

export default function SupplyChainNode({ node, noIngredient = false }: ISupplyChainNodeProps) {
    const { name, originCountryCode, supplierName, companyName, companyId, rootParentId } = node

    if (node.isDummy) {
        return null
    }

    const { ownership, setOwnership } = useContext(TileMenuContext)

    const [state, setState] = useState<NodeState>(TYPE_PROGRESS)
    const [stages, setStages] = useState<SupplyChainStage[]>([])
    const [color, setColor] = useState<ISCNodeColor>()

    useEffect(() => {
        setState(node.state)
        const isCertified = isCertifiedState(node.state)
        const allStages = getRequiredStages(node)
        setStages(allStages)

        setColor(isCertified ? ScCertifiedNodeColors[node.type] : ScNodeColor)
    }, [node])

    const showOwnership = useMemo(() => checkOwnership(ownership, [companyId, rootParentId]), [
        ownership,
        companyId,
        rootParentId,
    ])

    return (
        <AnimatedDiv
            id={node.nodeId || node.id}
            className={classNames(
                'z-10 max-w-2xs w-56 min-h-[96px] box-border rounded',
                'relative flex flex-col items-center justify-between',
                showOwnership && 'ring-4 ring-sky-500',
                color?.bg
            )}
            onMouseEnter={() => {
                setOwnership([companyId, rootParentId])
            }}
            onMouseLeave={() => {
                setOwnership([])
            }}
        >
            <SelfManagedTooltip
                className="mt-8"
                node={node}
                isDummy={node.isDummy}
                noIngredient={noIngredient}
            >
                <div className="flex flex-col justify-start flex-grow gap-y-2 w-full p-2 rounded-t  border border-b-0 border-gray-300">
                    <div className="flex flex-row items-center justify-start gap-x-2">
                        <SCStatusIcon
                            state={state}
                            stages={stages}
                            node={node}
                            className="w-8 h-8"
                        />
                        <div
                            className={classNames(
                                'text-sm font-medium text-left flex-grow line-clamp-2',
                                color?.text
                            )}
                            title={name}
                        >
                            {name}
                        </div>
                        {isWaterSplIngredient(node) && (
                            <WaterIconTooltip className="rounded-full hover:bg-violet-100" />
                        )}
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className={classNames('text-xs truncate', color?.subText)}>
                            {supplierName || companyName || 'N/A'}
                        </div>
                        {!isEmpty(originCountryCode) && (
                            <CountryCode bgColor={color?.pillBg} textColor={color?.pillText}>
                                {originCountryCode.substr(0, 3)}
                            </CountryCode>
                        )}
                    </div>
                </div>
            </SelfManagedTooltip>
            <NodeFooter node={node} stages={stages} noIngredient={noIngredient} />
        </AnimatedDiv>
    )
}

interface INodeFooterProps {
    node: ISupplyChainNode
    noIngredient?: boolean
    stages: SupplyChainStage[]
}

function NodeFooter({ node, noIngredient = false, stages }: INodeFooterProps) {
    const { openSlideover } = useContext(SlideoverContext)
    const { product } = useContext(TileMenuContext)

    const isAccessible = isNodeAccessible(node, product)

    const handleStageClick = (stage: SupplyChainStage) => () => {
        if (isAccessible) {
            if (noIngredient) {
                openSlideover(NodeType.RECIPE, { productId: node.id })
                return
            }
            const params
                = node.type === NodeType.INGREDIENT
                    ? {
                        ingredientId: node.id,
                    }
                    : {
                        productId: node.id,
                    }
            openSlideover(stage as NodeType, params)
        }
    }

    return (
        <div className="flex flex-row items-center w-full rounded-b">
            {stages.length > 0 ? (
                stages.map(s => (
                    <div
                        key={s}
                        role={isAccessible ? 'button' : undefined}
                        title={noIngredient ? 'Recipe' : startCase(s)}
                        onClick={handleStageClick(s)}
                        className={classNames(
                            ScStageWiseColors[s].bg,
                            'h-6 flex justify-center items-center flex-grow first:rounded-bl last:rounded-br',
                            isAccessible && 'cursor-pointer',
                            !isAccessible && 'cursor-not-allowed'
                        )}
                    >
                        <Icon
                            type={getIconByStage(s)}
                            className={ScStageWiseColors[s].lightIcon}
                            size={14}
                        />
                    </div>
                ))
            ) : (
                <div className="bg-gray-200 h-6 flex justify-center items-center flex-grow rounded-b cursor-not-allowed" />
            )}
        </div>
    )
}

function CountryCode({ bgColor, children, className, textColor }: any) {
    return (
        <div
            className={classNames(
                'flex items-center justify-center w-8 h-4 px-1 rounded-3xl',
                'bg-gray-100 uppercase text-2xs font-medium',
                bgColor,
                textColor,
                className
            )}
        >
            {children}
        </div>
    )
}

interface ISCStatusIcon {
    className?: string
    node: ISupplyChainNode
    stages: SupplyChainStage[]
    state: NodeState
}

function SCStatusIcon({ className, node, state }: ISCStatusIcon) {
    const iconColors = getSupplyChainNodeColor(node)
    const isCertified = isCertifiedState(state)

    const iconColor = isCertified ? 'text-white' : iconColors.icon
    const bgColor = isCertified ? iconColors.bg : 'bg-transparent'

    return (
        <div
            className={classNames(
                'rounded-full flex flex-shrink-0 items-center justify-center border',
                className,
                bgColor,
                iconColors.border
            )}
        >
            <Icon type={statusIcons[state || 'blank']} size={20} className={iconColor} />
        </div>
    )
}
