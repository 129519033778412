import React from 'react'
import classNames from 'classnames'
import FormRow from './FormRow'


export default function FormLoader() {
    return (
        <div className="pt-2 animate-pulse">
            <FormRow>
                <LoderElement className="h-10 col-span-1" />
                <LoderElement className="h-10 col-span-1" />
            </FormRow>
            <FormRow>
                <LoderElement className="col-span-2 h-4 max-w-xs mb-2" />
                <LoderElement className="h-24 col-span-1 rounded-md" />
                <LoderElement className="h-24 col-span-1 rounded-md" />
            </FormRow>
        </div>
    )
}

export function LoderElement({ className }: any) {
    return <div className={classNames('bg-gray-200', className)} />
}
