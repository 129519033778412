import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Icon } from '@mondra/ui-components'
import toLower from 'lodash/fp/toLower'
import isEmpty from 'lodash/fp/isEmpty'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { Divider, MenuItem } from '../../../components'
import { SlideoverContext } from '../../../contexts/SlideoverProvider'
import { IProductNode, StageDetailType } from '../../../types/supply-chain-types'
import {
    IChildrenProps,
    IClassNameProps,
    IParamProps,
    NodeType,
    SA_TYPE,
} from '../../../types/types'
import { getProductPageUrl } from '../../../utils/getURL'
import { isNodeAccessible } from '../../../utils/supplyChainUtils'
import { TileMenuContext } from '../TileMenuProvider'
import useProduct from '../../../hooks/useProduct'
import NodeTileMenu from './NodeTileMenu'


interface ISelfManagedTooltipProps extends IChildrenProps, IClassNameProps {
    isDummy?: boolean
    node: IProductNode & { productId?: string }
    noIngredient?: boolean
    noTooltip?: boolean
}

export default function SelfManagedTooltip({
    children,
    className,
    isDummy = false,
    node,
    noIngredient = false,
    noTooltip = false,
}: ISelfManagedTooltipProps) {
    const { product } = useContext(TileMenuContext)
    const { openSlideover } = useContext(SlideoverContext)

    if (isDummy) {
        return null
    }

    function handleDetailsSelect(type: NodeType) {
        openSlideover(type, { productId: node.productId })
    }

    if (noTooltip) {
        return (
            <div
                role={node.isProductLocked ? '' : 'button'}
                className="justify-between"
                onClick={
                    node.isProductLocked
                        ? undefined
                        : () => handleDetailsSelect(toLower(node.name) as NodeType)
                }
            >
                {children}
            </div>
        )
    }

    const isAccessible = isNodeAccessible(node, product)

    return (
        <NodeTileMenu
            className={className}
            node={node}
            items={
                isAccessible ? (
                    noIngredient ? (
                        <NoIngredientMenuItems node={node} />
                    ) : node.type === NodeType.INGREDIENT ? (
                        <IngredientMenuItems node={node} />
                    ) : node.saType === SA_TYPE.Produce ? (
                        <ProduceMenuItems node={node} />
                    ) : node.saType === SA_TYPE.SpecialIngredient ? (
                        <SplIngredientMenuItems node={node} />
                    ) : (
                        <ProductMenuItems node={node} />
                    )
                ) : null
            }
        >
            {children}
        </NodeTileMenu>
    )
}

interface IProductMenuItemsProps {
    node: IProductNode
}

export function ProductMenuItems({ node }: IProductMenuItemsProps) {
    const [calculated, setCalculated] = useState<boolean>(false)
    const { companyId = '' } = useParams<IParamProps>()
    const { openSlideover } = useContext(SlideoverContext)
    const {
        ingredientDetails,
        packagingDetails,
        packagingRequired,
        processingDetails,
        processingRequired,
        productDetails,
        salesDetails,
        salesRequired,
        storageDetails,
        storageRequired,
    } = node

    const isReadOnlyMode = useMemo(() => {
        return isEmpty(node) || node.isProductLocked
    }, [node])

    const { calculate } = useProduct({
        autoFetch: false,
        companyId,
        productId: node.id,
    })

    function handleDetailsSelect(type: NodeType) {
        openSlideover(type, { productId: node.id })
    }

    function handleProductOpen() {
        const url = getProductPageUrl({
            companyId,
            isSuppliedProduct: !isEmpty(node.supplierId),
            productId: node.id,
        })
        window.open(url, '_blank')
    }

    const handleCalculate = useCallback(() => {
        calculate({})
        setCalculated(true)
    }, [])

    return (
        <>
            <MenuItem
                className="justify-between"
                onSelect={() => handleDetailsSelect(NodeType.PRODUCT)}
            >
                Product details <DetailStatus enabled fillState={productDetails} />
            </MenuItem>
            <MenuItem
                className="justify-between"
                onSelect={() => handleDetailsSelect(NodeType.RECIPE)}
            >
                Recipe <DetailStatus enabled fillState={ingredientDetails} />
            </MenuItem>
            <MenuItem
                className="justify-between"
                onSelect={() => handleDetailsSelect(NodeType.PROCESSING)}
            >
                Processing{' '}
                <DetailStatus enabled={processingRequired} fillState={processingDetails} />
            </MenuItem>
            <MenuItem
                className="justify-between"
                onSelect={() => handleDetailsSelect(NodeType.PACKAGING)}
            >
                Packaging <DetailStatus enabled={packagingRequired} fillState={packagingDetails} />
            </MenuItem>
            <MenuItem
                className="justify-between"
                onSelect={() => handleDetailsSelect(NodeType.STORAGE)}
            >
                Storage <DetailStatus enabled={storageRequired} fillState={storageDetails} />
            </MenuItem>
            <MenuItem
                className="justify-between"
                onSelect={() => handleDetailsSelect(NodeType.SALE)}
            >
                Sale <DetailStatus enabled={salesRequired} fillState={salesDetails} />
            </MenuItem>
            <Divider />
            <MenuItem
                className="justify-between items-center text-gray-900"
                onSelect={handleProductOpen}
            >
                View as product <Icon type="externalLink" size={20} className="text-gray-700" />
            </MenuItem>
            <MenuItem
                className="justify-between"
                onSelect={handleCalculate}
                disabled={isReadOnlyMode || calculated}
            >
                Calculate{' '}
                <Icon
                    type="edit"
                    size={20}
                    className={classNames({
                        'text-gray-400': isReadOnlyMode || calculated,
                        'text-gray-700': !isReadOnlyMode && !calculated,
                    })}
                />
            </MenuItem>
        </>
    )
}

export function ProduceMenuItems({ node }: IProductMenuItemsProps) {
    const { openSlideover } = useContext(SlideoverContext)

    function handleDetailsSelect() {
        openSlideover(NodeType.PRODUCE, { productId: node.id })
    }

    return (
        <>
            <MenuItem className="justify-between" onSelect={handleDetailsSelect}>
                Produce details <DetailStatus enabled fillState={node.productDetails} />
            </MenuItem>
        </>
    )
}

export function SplIngredientMenuItems({ node }: IProductMenuItemsProps) {
    const { openSlideover } = useContext(SlideoverContext)

    function handleDetailsSelect() {
        openSlideover(NodeType.SPECIAL_INGREDIENT, { productId: node.id })
    }

    return (
        <>
            <MenuItem className="justify-between" onSelect={handleDetailsSelect}>
                Special ingredient details <DetailStatus enabled fillState={node.productDetails} />
            </MenuItem>
        </>
    )
}

export function IngredientMenuItems({ node }: IProductMenuItemsProps) {
    const { openSlideover } = useContext(SlideoverContext)

    function handleDetailsSelect() {
        openSlideover(NodeType.INGREDIENT, { ingredientId: node.id })
    }

    return (
        <>
            <MenuItem className="justify-between" onSelect={handleDetailsSelect}>
                Edit Ingredient <DetailStatus enabled fillState={node.ingredientDetails} />
            </MenuItem>
        </>
    )
}

export function NoIngredientMenuItems({ node }: IProductMenuItemsProps) {
    const { openSlideover } = useContext(SlideoverContext)

    function handleCreateRecipe() {
        openSlideover(NodeType.RECIPE, { productId: node.id })
    }

    return (
        <>
            <MenuItem className="justify-between" onSelect={handleCreateRecipe}>
                Create recipe
            </MenuItem>
        </>
    )
}

interface IDetailStatus {
    enabled?: boolean
    fillState?: StageDetailType
}

function DetailStatus({ enabled, fillState }: IDetailStatus) {
    if (!enabled) {
        return <Icon type="errorOutline" title="Not enabled" size={20} className="text-gray-700" />
    }

    return fillState === 'complete' ? (
        <Icon
            type="checkmarkFilled"
            title="Complete details"
            className="text-primary-500"
            size={20}
        />
    ) : (
        <Icon type="pending" title="Incomplete details" size={20} className="text-gray-700" />
    )
}
