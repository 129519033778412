import isEmpty from 'lodash/fp/isEmpty'
import { METHOD_POST, METHOD_PUT, URLs } from '../constants'
import { ActivityStage, IStageActivity } from '../types/stages'
import getURL from '../utils/getURL'
import useFetch from './useFetch'
import useUpdateFormData from './useUpdateFormData'


interface IProps {
    activityCode?: string
    autoFetch?: boolean
    companyId: string
    onError: any
    onSaved: any
    productId: string
    stage: ActivityStage
}

export default function useProductActivity<T>({
    activityCode,
    autoFetch = true,
    companyId,
    onError,
    onSaved,
    productId,
    stage,
}: IProps) {
    const { loading, data: activity } = useFetch<T & IStageActivity>({
        autoFetch,
        defaultRes: {},
        errMessage: `Error while fetching ${stage} activity data`,
        onError,
        url: getURL(
            URLs.Activity.GET,
            { companyId, productId },
            `?stage=${stage}${!isEmpty(activityCode) ? `&activityCode=${activityCode}` : ''}`
        ),
    })

    const isEdit = !isEmpty(activity.id)

    const { saving, update: saveActivity } = useUpdateFormData({
        method: isEdit ? METHOD_PUT : METHOD_POST,
        onError,
        onUpdate: onSaved,
        url: isEdit
            ? getURL(URLs.Activity.UPDATE, {
                companyId,
                id: activity.id,
                productId,
            })
            : getURL(URLs.Activity.ADD, { companyId, productId }),
    })

    return {
        activity,
        loading,
        saveActivity,
        saving,
    }
}
