import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { ICustomEventProps, IProduct, NodeType, SA_TYPE } from '../../types/types'
import slideoverCloseStyles from '../../constants/slideoverCloseStyles'
import { DEFAULT_SUPPLIER_ID, METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import { SUPPLY_PRODUCE } from '../../constants/toastNotifications'
import { UserContext } from '../../contexts/UserProvider'
import { SideloadCertificateContext } from '../../contexts/SideloadCertificateProvider'
import getURL from '../../utils/getURL'
import useFetch from '../../hooks/useFetch'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { ISlideoverProps } from '../../types/slideover-types'
import SlideoverAlert from '../../product-overiew/SlideoverAlert'
import { NotesButton, PageLoader } from '../../components'
import Slideover from '../components/Slideover'
import { ISkuInputRef } from '../../components/SkuIdInput'
import SlideoverProvider from '../../contexts/SlideoverProvider'
import ProduceForm from './ProduceForm'


const PRODUCE_DATA: any = {
    emissionFactorCode: '',
    emissionFactorCountryCode: '',
    originCountryCode: '',
    supplierId: DEFAULT_SUPPLIER_ID,
}

const formId = 'add-edit-produce-form'

interface IProduceSlideoverProps extends Omit<ISlideoverProps, 'type'> {
    allowExisting?: boolean
    onSaved: (produce: IProduct) => void
    productId?: string
}

export default function ProduceSlideover({
    allowExisting = false,
    companyId,
    isOpen,
    onClose,
    onSaved,
    productId,
}: IProduceSlideoverProps) {
    const { openSideload } = useContext(SideloadCertificateContext)
    const skuRef = useRef<ISkuInputRef>(null)
    const [produce, setProduce] = useState<IProduct>(PRODUCE_DATA)
    const [errors, setErrors] = useState({})
    const [isExisting, setIsExisting] = useState(false)
    const [produceChanged, setProduceChanged] = useState(false)
    const { showError, showSuccess } = useTopCenterToast()

    const { company } = useContext(UserContext)
    const companyName = company?.name || ''

    const isEdit = !isEmpty(productId)

    const { data: defaultProduct, error, loading, refetch } = useFetch({
        autoFetch: isEdit && isOpen,
        url: getURL(URLs.Product.GET_PRIMARY, { companyId, productId }),
    })

    useEffect(() => {
        if (!isEmpty(error)) {
            showError({ description: SUPPLY_PRODUCE.ERROR })
            handleClose()
        }
    }, [error])

    useEffect(() => {
        if (!isEmpty(defaultProduct)) {
            setProduce(defaultProduct)
        }
    }, [defaultProduct])

    const onUpdate = (data: any) => {
        showSuccess({
            description:
                isEdit || (isExisting && produceChanged)
                    ? SUPPLY_PRODUCE.UPDATE
                    : SUPPLY_PRODUCE.CREATE,
        })
        onSaved(data)
        handleClose()
    }

    const url
        = isEdit || (isExisting && produceChanged)
            ? getURL(URLs.Product.UPDATE, { companyId, productId: productId || produce.id })
            : getURL(URLs.Product.ADD, { companyId })

    const { saving, update } = useUpdateFormData({
        method: isEdit || (isExisting && produceChanged) ? METHOD_PUT : METHOD_POST,
        onUpdate,
        url,
    })

    const handleChange = (changes: any, reset = false) => {
        setProduce((produceState: any) =>
            reset ? { ...PRODUCE_DATA, ...changes } : { ...produceState, ...changes }
        )
        setErrors({})
        if (reset) {
            setIsExisting(false)
        }
    }
    const handleSubmit = (event: any) => {
        const { taxonomyCode, name, originCountryCode, sku, supplierId } = produce
        const isValidSku = skuRef?.current?.isValidSku()
        event.preventDefault()
        event.stopPropagation()

        if (isEmpty(supplierId)) {
            setErrors({
                supplier: 'Supplier should not be blank',
            })
            return
        }
        if (isEmpty(taxonomyCode)) {
            setErrors({
                taxonomyCode: 'Category should not be blank',
            })
            return
        }
        if (isEmpty(name)) {
            setErrors({
                produce: 'Produce name should not be blank',
            })
            return
        }
        if (!isExisting && !isValidSku && !isEmpty(sku)) {
            setErrors({
                sku: 'Invalid SKU',
            })
            return
        }
        if (isEmpty(originCountryCode)) {
            setErrors({
                originCountryCode: 'Country should not be blank',
            })
            return
        }
        save()
    }

    function save() {
        if (isExisting) {
            onSaved(produce)
            handleClose()
        } else {
            update({
                ...produce,
                id: isEdit ? productId : undefined,
                label: undefined,
                saType: SA_TYPE.Produce,
                updatedDate: undefined,
            })
        }
    }

    const handleSetProduce = useCallback((option: any) => {
        if (option && option.id) {
            setIsExisting(true)
            setErrors({})
            setProduce(option)
            refetch(getURL(URLs.Product.GET_PRIMARY, { companyId, productId: option.id }))
        }
    }, [])

    function handleClose() {
        setProduce(PRODUCE_DATA)
        setErrors({})
        setIsExisting(false)
        onClose && onClose(NodeType.PRODUCE)
    }

    function handleProduceNameChange(event: ICustomEventProps) {
        const { name, value } = event.target

        if (isEmpty(value)) {
            handleChange({ id: undefined, [name]: value, sku: '' })
        } else {
            handleChange({ id: undefined, [name]: value })
        }

        if (isExisting) {
            setTimeout(() => {
                skuRef?.current?.validateSku()
            }, 100)
        }
        setIsExisting(false)
    }

    function handleSideloadClick() {
        openSideload({
            onSuccess: onSaved,
            url: getURL(URLs.Certificate.SIDELOAD, { companyId }, `?productId=${productId}`),
        })
    }
    return (
        <SlideoverProvider companyId={companyId}>
            <Slideover
                wider="max-w-3xl"
                isOpen={isOpen}
                type={NodeType.PRODUCE}
                headerProps={{
                    iconProps: slideoverCloseStyles as any,
                }}
                title={isEdit ? 'Update Supplied Produce' : 'Add Supplied Produce'}
                formId={formId}
                saving={saving}
                disableSave={(!allowExisting && isExisting) || loading}
                onClose={handleClose}
                saveButtonText={
                    saving
                        ? isEdit
                            ? 'Updating...'
                            : 'Creating...'
                        : isExisting
                            ? 'Add supplied produce'
                            : isEdit
                                ? 'Update supplied produce'
                                : 'Create supplied produce'
                }
                footerChildren={
                    <div className="flex items-center">
                        <NotesButton
                            className="relative mr-2"
                            stage={NodeType.PRODUCT}
                            productId={productId || defaultProduct?.id || ''}
                        />
                        {!allowExisting && isExisting && (
                            <SlideoverAlert
                                title="Produce already exists, can not save"
                                description="To add a new produce you must change the name to proceed."
                            />
                        )}
                    </div>
                }
                footerProps={{
                    className: 'flex flex-row justify-between w-full',
                }}
            >
                {loading && (
                    <PageLoader centered className="mt-4 absolute top-20 left-1/2 bg-white" />
                )}
                {isOpen && (
                    <ProduceForm
                        ref={skuRef}
                        formId={formId}
                        editMode={isEdit}
                        readOnly={isExisting}
                        errors={errors}
                        companyId={companyId}
                        companyName={companyName}
                        autoFetch={isOpen}
                        produce={produce}
                        onChange={handleChange}
                        onProduceNameChange={handleProduceNameChange}
                        onProduceSelect={handleSetProduce}
                        onSumbit={handleSubmit}
                        onSideloadClick={handleSideloadClick}
                        setProduceChanged={setProduceChanged}
                    />
                )}
            </Slideover>
        </SlideoverProvider>
    )
}
