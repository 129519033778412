import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import { Button, ConfirmDialog, Dialog } from '@mondra/ui-components'
import { IProduct, ISite } from '../../types/types'
import useSites from '../../hooks/useSites'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { isValidSite } from '../../utils'
import { ProductSettingContext } from '../../contexts/ProductSettingProvider'
import SiteSlideover from '../../slideovers/site/SiteSlideover'
import { Dropdown } from '../../components'
import { DEFAULT_UID } from '../../constants'
import { SITE } from '../../constants/toastNotifications'
import useProduct from '../../hooks/useProduct'


interface ISiteDetailProps {
    companyId: string
    onProductUpdate: (changes: Partial<IProduct>) => void
    productId: string
    site: ISite
}

interface ISiteOption extends ISite {
    label: string
}

function getSiteAddress(site?: ISiteOption) {
    if (!isValidSite(site)) return 'No site selected'
    return `${site?.address?.streetAddress}, ${site?.address?.city}, ${site?.address?.state}, ${site?.address?.country}`
}

function siteWithLabel(site: ISite) {
    return { ...site, label: site?.name }
}

export default function SiteDetail({
    companyId,
    onProductUpdate,
    productId,
    site = {} as ISite,
}: ISiteDetailProps) {
    const { readOnly } = useContext(ProductSettingContext)
    const [isOpen, setOpen] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [selected, setSelected] = useState<ISiteOption>()
    const [siteOpen, setSiteOpen] = useState<boolean>(false)
    const { showSuccess } = useTopCenterToast()
    const isSiteSet = isValidSite(site)

    const onUpdate = () => {
        if (!selected?.id) {
            return
        }
        handleDialogClose()
        onProductUpdate && onProductUpdate({ site: selected })
        showSuccess({ description: SITE.UPDATE })
    }

    const { saving, updateProductCustomProps } = useProduct({
        autoFetch: false,
        companyId,
        onUpdate,
        productId,
    })

    useEffect(() => {
        setDefaultSite()
    }, [site?.id])

    const { loading, sites } = useSites({
        autoFetch: isOpen,
        companyId,
    })

    const setDefaultSite = () => {
        if (!isEmpty(site?.id) && site?.id !== DEFAULT_UID) {
            setSelected(siteWithLabel(site))
        }
    }

    const siteOptions = useMemo(() => {
        return map(siteWithLabel, filter(isValidSite, sites))
    }, [sites])

    const handleDialogClose = useCallback(() => {
        setOpenConfirm(false)
        setOpen(false)
        setDefaultSite()
    }, [site])

    const handleDialogOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleSiteSelect = useCallback((selected: any) => {
        setSelected(selected)
    }, [])

    const handleConfirm = useCallback(() => {
        if (!selected?.id) {
            return
        }
        const payload = {
            siteId: selected?.id,
        }
        updateProductCustomProps && updateProductCustomProps(payload)
    }, [selected])

    const handleSiteSave = useCallback(() => {
        if (isEmpty(selected)) {
            return
        }
        if (isSiteSet) {
            setOpenConfirm(true)
        } else {
            handleConfirm()
        }
    }, [selected, isSiteSet])

    const handleAddNewSite = useCallback(() => {
        setOpen(false)
        setSiteOpen(true)
    }, [])

    const handleSiteClose = useCallback(() => {
        setSiteOpen(false)
        setOpen(true)
    }, [])

    const handleSiteSaved = useCallback(
        (site: ISite) => {
            setSelected(siteWithLabel(site))
        },
        [sites]
    )

    return (
        <div className="flex-grow flex flex-col px-4 pt-2 pb-4">
            <ul className="flex-grow text-sm font-medium divide-gray-300 divide-y">
                <li className="py-1">
                    Site:&nbsp;
                    {!isSiteSet && <span className="font-semibold text-amber-500">Not Set</span>}
                    {!site?.isArchived && isSiteSet && (
                        <span className="font-semibold text-emerald-500">Set</span>
                    )}
                    {site?.isArchived && (
                        <span className="font-semibold text-red-500">Archived</span>
                    )}
                </li>
                {isSiteSet ? (
                    <>
                        <li
                            className="py-1 overflow-ellipsis overflow-x-hidden whitespace-nowrap truncate"
                            title={site?.name}
                        >
                            {site?.name}
                        </li>
                        <li className="py-1 truncate" title={site?.address?.country}>
                            {site?.address?.country}
                        </li>
                    </>
                ) : (
                    <li className="py-1 font-normal text-xs h-13 line-clamp-3">
                        Site not set, add or edit your site
                    </li>
                )}
            </ul>
            <Button
                disabled={readOnly}
                variant="secondary"
                iconType="edit"
                className="w-full justify-start mt-2"
                onClick={handleDialogOpen}
            >
                {isSiteSet ? 'Edit Site' : 'Set Site'}
            </Button>
            <Dialog
                open={isOpen}
                disableOverlayClose
                hideCloseBtn
                onClose={handleDialogClose}
                className="overflow-visible"
            >
                <Dialog.Title>Select a Site</Dialog.Title>
                <Dialog.Description className="h-32">
                    <Dropdown
                        selected={selected}
                        options={siteOptions}
                        valid={loading || !isEmpty(selected)}
                        placeholder={loading ? 'Loading...' : 'Select a site'}
                        onChange={handleSiteSelect}
                    />
                    {!isEmpty(selected) && (
                        <div className="mt-4 text-gray-500">
                            <div>
                                Name:{' '}
                                <span className="text-gray-800 font-medium">{selected?.name}</span>
                            </div>
                            <div className="mt-2">
                                Address:{' '}
                                <span className="text-gray-800 font-medium">
                                    {getSiteAddress(selected)}
                                </span>
                            </div>
                        </div>
                    )}
                </Dialog.Description>
                <Dialog.Footer>
                    <Button variant="secondary" onClick={handleAddNewSite}>
                        Add new
                    </Button>
                    <Button variant="secondary" onClick={handleDialogClose} disabled={saving}>
                        Close
                    </Button>
                    {site?.id !== selected?.id && (
                        <Button onClick={handleSiteSave} disabled={isEmpty(selected) || saving}>
                            Save
                        </Button>
                    )}

                    <ConfirmDialog
                        onClose={() => setOpenConfirm(false)}
                        onConfirm={handleConfirm}
                        open={openConfirm}
                    >
                        If you change the current site, you will lose the transport and other
                        activities impacts.
                        <br />
                        Are you sure you wish to change it?
                    </ConfirmDialog>
                </Dialog.Footer>
            </Dialog>
            <SiteSlideover
                companyId={companyId}
                isOpen={siteOpen}
                onClose={handleSiteClose}
                onSaved={handleSiteSaved}
            />
        </div>
    )
}
