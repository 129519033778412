import React from 'react'
import { Tooltip } from '@mondra/ui-components'
import { ColDef, ValueFormatterParams } from 'ag-grid-community'
import startCase from 'lodash/fp/startCase'

import ProductStateCell from '../../components/ProductStateCell'
import { getProductSuppliedByName } from '../../utils/getProductSupplierName'
import { getColDefForImpact } from '../../utils/gridUtils'
import { EcoImpactIds } from '../../types/ecoImpacts'
import { SelectRowColDef, LastUpdatedColDef } from '../../constants/grid'
import SuppliedProductsActions from './SuppliedProductsActions'
import SuppliedProductsNameCell from './SuppliedProductsNameCell'


export const suppliedProductColumns = [
    SelectRowColDef,
    {
        cellRenderer: SuppliedProductsNameCell,
        field: 'name',
        headerName: 'Ingredients',
        minWidth: 300,
        sortable: true,
    },
    {
        field: 'saType',
        headerName: 'Type',
        sortable: true,
        valueFormatter: ({ value }) => startCase(value),
    },
    {
        cellRenderer: ({ valueFormatted }: any) => {
            const suppliers: string[] = valueFormatted.split(/[;,]+/)
            return (
                <Tooltip
                    interactive
                    placement="left"
                    size="md"
                    content={
                        <div className="max-h-96 overflow-y-auto">
                            {suppliers.map(s => (
                                <div key={s}>{s}</div>
                            ))}
                        </div>
                    }
                >
                    <div className="truncate">{valueFormatted}</div>
                </Tooltip>
            )
        },
        field: 'supplierName',
        headerName: 'Supplier Name',
        maxWidth: 360,
        minWidth: 240,
        valueFormatter: ({ data }: ValueFormatterParams) => getProductSuppliedByName(data),
    },
    {
        cellRenderer: ({ data }: any) => (
            <ProductStateCell
                certifiedUntilDate={data.certificationEndDate}
                state={data.saState}
                isComplete={data.isComplete}
            />
        ),
        enableSort: false,
        field: 'saState',
        headerName: 'State',
        minWidth: 220,
    },
    { ...getColDefForImpact(EcoImpactIds.EIID001), minWidth: 175 },
    { ...getColDefForImpact(EcoImpactIds.EIID002), minWidth: 185 },
    { ...getColDefForImpact(EcoImpactIds.EIID003), minWidth: 240 },
    { ...getColDefForImpact(EcoImpactIds.EIID004), minWidth: 165 },
    LastUpdatedColDef,
    {
        cellClass: 'ag-center-cell ag-p-0',
        cellRenderer: SuppliedProductsActions,
        colId: 'actions',
        field: '',
        flex: 0,
        headerName: '',
        maxWidth: 40,
    },
] as Array<ColDef>
