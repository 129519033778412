import React from 'react'


interface IMondraLogoProps {
    className?: string
    width?: number | string
    height?: number | string
    color?: string
    style?: React.CSSProperties
}

const MondraIcon = ({
    width = 32,
    height = 32,
    color = 'black',
    style,
    ...props
}: IMondraLogoProps) => (
    <svg viewBox="0 0 54 35" fill={color} width={width} height={height} {...(props as any)}>
        <g xmlns="http://www.w3.org/2000/svg" transform="matrix(1,0,0,1,-4751.41,-550.584)">
            <g transform="matrix(0.165625,0,0,0.055,4751.41,545.584)">
                <g>
                    <g transform="matrix(4.24615,0,0,12.7867,-59.1199,-235.416)">
                        <path d="M88.83,32.24C85.66,24.735 78.268,19.833 70.121,19.833C63.373,19.833 57.052,23.195 53.28,28.79C53.14,28.99 53.01,29.2 52.88,29.4C51.367,26.981 49.364,24.907 47,23.31C43.648,21.052 39.697,19.846 35.655,19.846C24.513,19.846 15.345,29.014 15.345,40.156C15.345,49.999 22.5,58.496 32.2,60.17C30.83,62.27 30.101,64.723 30.1,67.23C30.094,68.947 30.434,70.648 31.1,72.23C33.1,77.033 37.816,80.178 43.018,80.178C46.817,80.178 50.428,78.502 52.88,75.6C53.645,76.511 54.526,77.318 55.5,78C57.643,79.458 60.175,80.238 62.767,80.238C67.072,80.238 71.104,78.086 73.5,74.51C74.95,72.379 75.721,69.858 75.71,67.28C75.71,65.564 75.37,63.864 74.71,62.28C74.402,61.583 74.034,60.914 73.61,60.28C83.316,58.607 90.478,50.105 90.478,40.255C90.478,37.5 89.917,34.772 88.83,32.24ZM23.92,48.06C21.258,44.095 20.768,39.043 22.62,34.64C23.692,32.114 25.474,29.953 27.75,28.42C30.087,26.845 32.842,26.003 35.66,26.003C43.421,26.003 49.809,32.389 49.81,40.15L49.81,54.3L35.66,54.3C33.767,54.306 31.893,53.929 30.15,53.19C27.621,52.119 25.457,50.336 23.92,48.06ZM49.28,69.86C48.764,71.075 47.91,72.116 46.82,72.86C45.692,73.619 44.359,74.016 43,74C40.745,74.004 38.631,72.876 37.38,71C36.638,69.887 36.242,68.578 36.242,67.24C36.242,63.529 39.289,60.472 43,60.46L49.77,60.46L49.77,67.23C49.785,68.131 49.618,69.025 49.28,69.86ZM68.97,69.86C68.461,71.076 67.61,72.119 66.52,72.86C64.625,74.139 62.207,74.376 60.1,73.49C58.883,72.978 57.841,72.123 57.1,71.03C56.359,69.903 55.976,68.578 56,67.23L56,60.46L62.77,60.46C63.673,60.459 64.568,60.639 65.4,60.99C66.617,61.502 67.659,62.357 68.4,63.45C69.663,65.348 69.889,67.76 69,69.86L68.97,69.86ZM83.16,45.66C80.957,50.877 75.823,54.289 70.16,54.3L56,54.3L56,40.15C56,32.388 62.388,26 70.15,26C77.912,26 84.3,32.388 84.3,40.15C84.292,42.044 83.904,43.918 83.16,45.66Z" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default MondraIcon
