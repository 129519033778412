import React, { useContext } from 'react'
import classNames from 'classnames'
import LockStatus from '../../ProductOverview/LockStatus'
import { IProductNode } from '../../../types/supply-chain-types'
import { TileMenuContext } from '../TileMenuProvider'
import { NodeType } from '../../../types/types'


interface IDataManagedbyProps {
    companyId: string
    companyName: string
    node: IProductNode
}

export default function DataManagedby({ companyId, companyName, node }: IDataManagedbyProps) {
    const { product, refetch } = useContext(TileMenuContext)

    const isLocked = product?.isProductLocked || node.isProductLocked

    const handleProductUpdate = () => {
        refetch && refetch()
    }

    return (
        <div
            className="flex items-center p-4 w-full"
            title={
                product?.isProductLocked
                    ? 'Product is locked'
                    : 'This product is managed by you, you may edit.'
            }
        >
            <div
                className={classNames(
                    'flex justify-center items-center flex-shrink-0 w-10 h-10 rounded-full',
                    isLocked ? 'bg-gray-100 text-gray-400' : 'bg-emerald-50 text-emerald-500'
                )}
            >
                <LockStatus
                    className="w-10 h-10 flex items-center justify-center"
                    companyId={companyId}
                    disabled={node.type === NodeType.INGREDIENT}
                    productId={node.id}
                    isProductLocked={isLocked}
                    onProductUpdate={handleProductUpdate}
                />
            </div>
            <div className="ml-2 overflow-hidden">
                <div className="truncate text-xs text-gray-500">Data is managed by:</div>
                <div className="truncate text-sm text-gray-900" title={companyName}>
                    {companyName} <span>(YOU)</span>
                </div>
            </div>
        </div>
    )
}
