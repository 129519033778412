import reduce from 'lodash/fp/reduce'
import toLower from 'lodash/fp/toLower'
import { PER_100G } from '../constants'
import { impactNameByCode } from '../constants/ecoImpactMeasures'
import { IEcoImpact, ImpactMeasureType } from '../types/ecoImpacts'


export default function getStageImpactFromProduct(
    ecoImpactDetails: IEcoImpact[],
    measureSize: ImpactMeasureType = PER_100G
) {
    return reduce(
        (acc: any, impact: IEcoImpact) => {
            const stage = toLower(impact.stage || '')
            return {
                ...acc,
                [stage]: {
                    impacts: [
                        ...(acc[stage]?.impacts || []),
                        {
                            id: impact.code,
                            label: impactNameByCode[impact.code],
                            value: impact?.[measureSize],
                        },
                    ],
                    percentages: {
                        ...acc[stage]?.percentages,
                        [impactNameByCode[impact.code]]: impact.perStagePercentage,
                    },
                },
            }
        },
        {},
        ecoImpactDetails || []
    )
}
