import React, { useContext } from 'react'
import { Button, SearchInput, Slideover, SlideoverHeader } from '@mondra/ui-components'
import classNames from 'classnames'
import isEmpty from 'lodash/fp/isEmpty'
import omit from 'lodash/fp/omit'
import { useControls } from '../../hooks/useControls'
import { useFiltersByUrl } from '../../hooks/useFiltersByUrl'
import { FilterContext, FilterProvider } from '../../contexts/FilterProvider'


export interface IPageFilterBarProps {
    title: string
    onSearch: (text: string) => void
    actionsComponent?: React.ReactElement
    onFilterSubmit: (filters: any) => void
    filterList: React.ReactElement
}

export const PageFilterBar = ({
    title,
    onSearch,
    actionsComponent,
    onFilterSubmit,
    filterList,
}: IPageFilterBarProps) => {
    return (
        <div className="bg-white">
            <div className="w-full flex flex-row justify-start items-center mb-px">
                <SearchInput
                    name="customer"
                    className="!h-12 !max-h-12 !border-transparent z-10 !text-sm"
                    placeholder={`Search ${title.toLowerCase()}...`}
                    onSearch={onSearch}
                />
                {actionsComponent && actionsComponent}
                <FilterProvider>
                    <FilterSlideover onFilterSubmit={onFilterSubmit}>{filterList}</FilterSlideover>
                </FilterProvider>
            </div>
        </div>
    )
}

export const FilterSlideover = ({ children, onFilterSubmit }: any) => {
    const { filters: appliedFilters } = useFiltersByUrl()
    const { close, isOpened, open } = useControls()
    const { filters } = useContext(FilterContext)

    const handleSubmit = () => {
        onFilterSubmit(filters)
        close()
    }
    return (
        <>
            <Button
                className={classNames(
                    'mx-2',
                    !isEmpty(omit(['order'], appliedFilters)) && 'text-primary-600'
                )}
                variant="ghost"
                iconType="filter"
                onClick={open}
            >
                Filters
            </Button>
            <Slideover isOpen={isOpened} onClose={close} wider="max-w-md">
                <SlideoverHeader
                    className="!bg-white"
                    title="Set Filters"
                    onClose={close}
                    hideShadow
                />
                <div className="h-full">{children}</div>
                <div className="py-5 px-6 flex justify-between items-center border-t border-gray-300">
                    <Button className="text-primary-600" variant="ghost" onClick={close}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit}>Apply filters</Button>
                </div>
            </Slideover>
        </>
    )
}
