import React from 'react'
import { ButterflyLoader } from '../../components'


export default function Loading() {
    return (
        <div className="flex h-screen justify-center items-center">
            <div className="m-auto">
                <div className="transition-all flex-col flex items-center justify-center text-gray-500 group-hover:text-primary-600">
                    <ButterflyLoader title="Page loader animation" />
                    <div className="transition-all mt-2 p-2 rounded-md bg-gray-50 text-xs text-center text-gray-800 font-medium group-hover:text-primary-600">
                        Fetching your data
                    </div>
                </div>
            </div>
        </div>
    )
}
