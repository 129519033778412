import React from 'react'
import PageLoader from './PageLoader'


const UploadImage = React.forwardRef(
    (
        {
            textSize = 'leading-normal text-base',
            className = 'text-center mt-1',
            id,
            name,
            imageTypes = ['image/png'],
            loading,
            onUpload,
        }: any,
        ref
    ) => (
        <div className={className}>
            <input
                name={name}
                id={id}
                type="file"
                className="absolute hidden"
                onChange={onUpload}
                accept={imageTypes.join(', ')}
                ref={ref as any}
            />
            <label
                className={`mb-2 cursor-pointer mdp-link ${textSize} hover:text-primary-800 active:text-primary-800`}
                htmlFor={id}
            >
                {loading ? 'Uploading' : 'Upload'}
            </label>
            {loading && <PageLoader sizeClass="w-6 h-6" className="ml-2" />}
        </div>
    )
)

export default UploadImage
