import React from 'react'
import { IClassNameProps } from '@mondra/ui-components/@types'
import { Checkbox } from '@mondra/ui-components'
import classNames from 'classnames'
import isFunction from 'lodash/fp/isFunction'

import { getMappedInsights } from '../../utils/insightsMappings'
import { NoDataPlaceholder } from '../../components/NoDataPlaceholder'


interface IFactProps {
    bgColor?: string
    border?: string
    description: string
    index: number
    onSelect?: (index: number, checked: boolean) => void
    selected?: boolean
    title: string
}

interface IFactsProps extends IClassNameProps {
    facts: IFactProps[]
    space?: string
    onSelect?: (index: number, checked: boolean) => void
}

export const Facts = ({
    className,
    facts,
    space = 'space-y-6 lg:space-y-0 lg:space-x-6',
    onSelect,
}: IFactsProps) => {
    const mappedFacts = getMappedInsights(facts)
    return (
        <div className={classNames('flex flex-col lg:flex-row items-center', className, space)}>
            {mappedFacts.map((fact: IFactProps, index: number) => (
                <Fact
                    key={`${index}-${fact.title}`}
                    {...fact}
                    bgColor="bg-gray-50"
                    index={index}
                    onSelect={onSelect}
                />
            ))}
        </div>
    )
}

export const Fact = ({
    bgColor = 'bg-white',
    border = 'border-none',
    description,
    index,
    onSelect,
    selected,
    title,
}: IFactProps) => {
    const showSelect = isFunction(onSelect)

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked
        onSelect && onSelect(index, checked)
    }

    return (
        <div
            className={classNames(
                'relative p-4 pb-6 rounded w-full h-full',
                bgColor,
                border,
                showSelect && '!pl-12'
            )}
        >
            {showSelect && (
                <Checkbox
                    className="absolute left-4 top-[18px]"
                    checked={Boolean(selected)}
                    onChange={handleCheck}
                />
            )}
            <h5 className="text-gray-900 font-semibold mb-2 sml">{title}</h5>
            <p className="text-gray-700 font-normal sml">{description}</p>
        </div>
    )
}

interface IHotspots extends IClassNameProps {
    errorMessage?: string
    hotspots: IFactProps[]
    onSelect?: (index: number, checked: boolean) => void
    selected?: any
    space?: string
    title: string
}

export const Hotspots = ({
    errorMessage = 'There are none currently available',
    className = 'col-span-2 sm:col-span-2 md:col-span-4',
    hotspots,
    selected = [],
    title,
    onSelect,
}: IHotspots) => {
    const mappedHotspots = getMappedInsights(hotspots, title)
    return (
        <>
            {mappedHotspots.length > 0 ? (
                mappedHotspots.map((fact: IFactProps, index: number) => (
                    <div className={classNames('h-full', className)} key={`${index}-${fact.title}`}>
                        <Fact
                            {...fact}
                            border="border border-gray-300"
                            index={index}
                            onSelect={onSelect}
                            selected={selected?.includes(index)}
                        />
                    </div>
                ))
            ) : (
                <div className="pt-2 col-span-2 sm:col-span-4 md:col-span-8 lg:col-span-12">
                    <NoDataPlaceholder placeholderContainer={errorMessage} />
                </div>
            )}
        </>
    )
}
