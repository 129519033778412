import React from 'react'
import classNames from 'classnames'
import Pluralize from 'pluralize'
import { Button } from '@mondra/ui-components'


interface IMultiSelectNotificationProps extends React.ComponentPropsWithoutRef<'div'> {
    count: number
    onCancel?: () => void
    right?: React.ReactNode
    title: string
}

export function MultiSelectNotification({
    className,
    count,
    onCancel,
    right,
    title,
}: IMultiSelectNotificationProps) {
    return (
        <div
            className={classNames(
                'sticky bottom-0 left-0 text-white font-medium text-sm bg-primary-500 py-1 px-4 transform -mx-5 xl:-mx-6 w-[calc(100%+40px)] xl:w-[calc(100%+48px)] flex items-center justify-between animate-slide-up',
                className
            )}
        >
            <div>
                {count} {Pluralize(title, count)} selected
            </div>
            <div className="flex items-center space-x-2">
                <div>{right}</div>
                <div>
                    {onCancel && (
                        <Button variant="white" onClick={onCancel}>
                            Cancel
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
