import React from 'react'


const LoaderCard = () => (
    <div className="border border-gray-300 flex flex-col p-4 m-4">
        <div className="w-full">
            <div className="flex flex-row justify-between items-start mb-3">
                <div className="flex flex-row justify-start gap-x-2">
                    <div className="flex items-center justify-center h-8 w-8 text-xs bg-gray-200" />
                    <div className="flex flex-col">
                        <div className="h-3 w-20 bg-gray-200 mb-1" />
                        <div className="h-2 w-24 bg-gray-200" />
                    </div>
                </div>
                <div className="w-6 h-6 bg-gray-200" />
            </div>
            <div>
                <div className="h-2 w-full bg-gray-200 mb-1" />
                <div className="h-2 w-20 bg-gray-200" />
            </div>
        </div>
    </div>
)

export const FormLoader = () => (
    <>
        <LoaderCard />
        <LoaderCard />
        <LoaderCard />
    </>
)
