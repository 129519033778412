import React, { useContext, useMemo } from 'react'
import { Header } from '@mondra/ui-components'

import { UserContext } from '../contexts/UserProvider'
import { NotificationContext } from '../contexts/SignalRNotificationProvider'
import UserAvatar from '../users/UserAvatar'
import getURL, { resolveRouteURL } from '../utils/getURL'
import urls from '../constants/urls'
import { useCompanyId } from '../hooks/useCompanyId'
import { NavTabs } from './NavTabs/NavTabs'
import NotificationBell from './NotificationBell'
import SwitchCompany from './SwitchCompany'


export const AppHeader = () => {
    const companyId = useCompanyId()
    const { messages } = useContext(NotificationContext)
    const { currentUser } = useContext(UserContext)
    const appRouteOptions = useMemo(
        () => [
            {
                label: 'Home',
                matchPaths: ['/dashboard', '/notes'],
                to: resolveRouteURL(urls.PagePaths.HOME, { companyId }),
            },
            {
                label: 'Products',
                matchPaths: ['/product', '/supply-chain'],
                to: resolveRouteURL(urls.PagePaths.PRODUCTS, { companyId }),
            },
            {
                label: 'Suppliers',
                to: resolveRouteURL(urls.PagePaths.SUPPLIERS, { companyId }),
            },
            {
                label: 'Sites',
                to: resolveRouteURL(urls.PagePaths.SITES, { companyId }),
            },
            {
                label: 'Categories',
                to: resolveRouteURL(urls.PagePaths.CATEGORIES, { companyId }),
            },
            {
                children: [
                    {
                        label: 'Ingredients',
                        to: getURL(urls.Pages.INGREDIENTS, { companyId }),
                    },
                    {
                        label: 'Supplied Products',
                        to: getURL(urls.Pages.SUPPLIED_PRODUCTS, { companyId }),
                    },
                ],
                label: 'Ingredients',
                matchPaths: ['/supplied-products', '/ingredients'],
                to: '',
            },
        ],
        [companyId]
    )
    return (
        <Header
            className="dark"
            gapClass=""
            appName="Core"
            left={<SwitchCompany />}
            right={
                <div className="flex items-center justify-between h-full">
                    <NotificationBell
                        color="#10B981"
                        messages={messages}
                        showNotification={currentUser.allowNotification}
                    />
                    <UserAvatar />
                </div>
            }
        >
            <div className="flex flex-1 items-center justify-start border-l border-gray-700">
                <NavTabs options={appRouteOptions} gapClass="gap-x-0" />
            </div>
        </Header>
    )
}
