import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { IContributionTreeNode } from '../types/contribution-tree-types'
import getURL from '../utils/getURL'
import { DEFAULT_UID, METHOD_POST, URLs } from '../constants'
import { EcoImpactIds } from '../types/ecoImpacts'
import { fetchClient } from '../utils/fetchClient'
import { NodeType } from '../types/types'


const groupTypes: any[] = [
    NodeType.PRODUCT,
    NodeType.COMPLEX_INGREDIENT,
    NodeType.SIMPLE_INGREDIENT,
]

export function getContributionData(data: IContributionTreeNode[]) {
    if (isEmpty(data)) {
        return []
    }
    return map(d => {
        return {
            ...d,
            group: groupTypes.includes(d.type) && !d.isSpecialIngredient,
            id: d.id === DEFAULT_UID ? d.type : d.id,
        }
    }, data)
}

export function createServerSideDatasource(
    acquireToken: () => Promise<string>,
    companyId: string,
    productId: string,
    impactCode: EcoImpactIds
) {
    return {
        getRows: (params: IServerSideGetRowsParams) => {
            const { success, parentNode } = params
            const {
                economicAllocation,
                netWeight,
                batchInputWeight,
                id,
                impactPercentage,
                impactValue,
            } = parentNode?.data || {}
            const body = {
                batchInputWeight,
                contributionPercentage: impactPercentage,
                ecoImpactCode: impactCode,
                economicAllocation,
                impactValue,
                netWeight,
            }
            const url = getURL(URLs.SupplyChain.CONTRITUTION_TREE_CHILDWISE, {
                companyId,
                productId: id || productId,
            })

            return fetchClient({
                acquireToken,
                body,
                method: METHOD_POST,
                onError: (err: any) => {
                    console.log('error', err)
                },
                onSuccess: (data: any) => {
                    success({ rowData: getContributionData(data) })
                },
                url: url,
            })
        },
    }
}
