import { scaleLinear } from '@visx/scale'
import colors from '@mondra/ui-components/styles/colors'


export const linearScale = scaleLinear({
    domain: [1, 5],
    range: [colors.cyan[400], colors.violet[600]],
})

export const widthScale = scaleLinear({
    domain: [1, 5],
    range: [0, 100],
})
