import { Button, Input, Notification } from '@mondra/ui-components'
import React from 'react'
import { Row } from '../../components'
import { MAX_DEC } from '../../constants'
import { IBPaECByProduct, IBPaECConfig } from '../../types/stages'
import { ICustomEventProps } from '../../types/types'
import { evaluateInputValue, setValidationError } from '../../utils'
import { filterWithIndex, mapWithIndex } from '../../utils/lodash-convert'
import { round } from '../../utils/numberFormatter'


interface IByProductsProps {
    bPaecByProducts: IBPaECByProduct[]
    disabled?: boolean
    error: any
    onChange: (changes: Partial<IBPaECConfig>) => void
    setError: any
    totalValuePerKg: number
}

export default function ByProducts({
    bPaecByProducts,
    disabled,
    error,
    onChange,
    setError,
    totalValuePerKg,
}: IByProductsProps) {
    const onByProductChange = (index: number) => (event: ICustomEventProps) => {
        const { name } = event.target
        const value = evaluateInputValue(event, MAX_DEC)
        onChange({
            bPaecByProducts: mapWithIndex((p: IBPaECByProduct, i: number) => {
                if (i === index) {
                    return {
                        ...p,
                        [name]: value,
                    }
                }
                return p
            }, bPaecByProducts),
        })

        setValidationError(value, `${name}${index}`, error, setError)
    }

    const onRemoveByProduct = (index: number) => () => {
        onChange({
            bPaecByProducts: filterWithIndex((_: any, i: number) => {
                return i !== index
            }, bPaecByProducts),
        })
    }

    return mapWithIndex(
        (p: IBPaECByProduct, i: number) => (
            <Row key={i} className="grid-cols-12">
                <div className="col-span-5">
                    <Input
                        name="byProductName"
                        disabled={disabled}
                        value={p.byProductName}
                        invalid={error[`byProductName${i}`]}
                        onChange={onByProductChange(i)}
                    />
                </div>
                <div className="col-span-3">
                    <Input
                        name="byProductWeight"
                        className="w-11/12 text-right"
                        disabled={disabled}
                        addonTitle="kg"
                        type="number"
                        value={p.byProductWeight}
                        invalid={error[`byProductWeight${i}`]}
                        onChange={onByProductChange(i)}
                    />
                </div>
                <div className={p.isPriceValueInPercent ? 'col-span-1' : 'col-span-2'}>
                    {!p.isPriceValueInPercent && (
                        <Input
                            name="byProductPriceValuePerKg"
                            className="w-11/12 text-right"
                            disabled={disabled}
                            addonTitle="£"
                            type="number"
                            value={p.byProductPriceValuePerKg}
                            invalid={error[`byProductPriceValuePerKg${i}`]}
                            onChange={onByProductChange(i)}
                        />
                    )}
                </div>
                <div
                    className={`${
                        p.isPriceValueInPercent ? 'col-span-3' : 'col-span-2'
                    } flex flex-row items-center justify-end`}
                >
                    {p.isPriceValueInPercent ? (
                        <Input
                            name="byProductPercentValuePerKg"
                            className="w-11/12 text-right"
                            disabled={disabled}
                            addonTitle="%"
                            type="number"
                            invalid={
                                error[`byProductPercentValuePerKg${i}`]
                                || p.byProductPercentValuePerKg < 0
                                || p.byProductPercentValuePerKg > 100
                            }
                            value={p.byProductPercentValuePerKg}
                            onChange={onByProductChange(i)}
                        />
                    ) : (
                        <div>
                            {round(
                                ((p.byProductPriceValuePerKg * p.byProductWeight)
                                    / totalValuePerKg)
                                    * 100,
                                1
                            )}
                            %
                        </div>
                    )}
                    <div className="ml-4">
                        <Button
                            isOnlyIcon
                            variant="ghost"
                            iconType="close"
                            title="Remove"
                            disabled={disabled}
                            onClick={onRemoveByProduct(i)}
                        />
                    </div>
                </div>
                {!p.isPriceValueInPercent && p.byProductPriceValuePerKg <= 0 && (
                    <div className="col-span-full mt-2">
                        <Notification
                            type="warning"
                            description="Value set to £ zero per kg, presumed donated. If waste, set under
                            activity."
                        />
                    </div>
                )}
            </Row>
        ),
        bPaecByProducts
    )
}
