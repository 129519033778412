import React from 'react'
import map from 'lodash/fp/map'
import { unknownTofixDecimal } from '../../utils/unknownTofixDecimal'


interface IChangeData {
    email: string
    name: string
    updatedAt: string
    fields: any[]
    stage: string
    operationType: string
}

interface IChangeDetails {
    changes: IChangeData[]
}

function ChangeDetails({ changes = [] }: IChangeDetails) {
    return (
        <div className="my-4 mx-6">
            {map(
                change => (
                    <div>
                        <div className="bg-gray-100 my-2 px-2 py-1 rounded border-b border-gray-300 flex justify-between items-center">
                            <div>Stage: {change.stage} </div>
                            <div className="mr-2 border border-gray-200 rounded-md bg-gray-200 px-2">
                                {change.operationType !== 'Delete'
                                    ? `${change.operationType}ed`
                                    : 'Deleted'}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 text-gray-400 text-xs font-normal leading-4 px-2">
                            <span>From:</span>
                            <span>To:</span>
                        </div>
                        {map(
                            field => (
                                <div className="mt-2 grid grid-cols-2 gap-x-2 text-xs font-normal leading-4 px-2">
                                    <div
                                        className="border border-gray-300 rounded-md p-1 w-min max-w-full flex justify-start gap-2"
                                        title={unknownTofixDecimal(field.previous) || 'Null'}
                                    >
                                        <div className="whitespace-nowrap">{field.field}:</div>
                                        <div className="font-bold truncate">
                                            {unknownTofixDecimal(field.previous) || 'Null'}
                                        </div>
                                    </div>
                                    <div
                                        className="border border-gray-300 rounded-md p-1 w-min max-w-full flex justify-start gap-2"
                                        title={unknownTofixDecimal(field.current) || 'Null'}
                                    >
                                        <div className="whitespace-nowrap">{field.field}:</div>
                                        <div className="font-bold truncate">
                                            {unknownTofixDecimal(field.current) || 'Null'}
                                        </div>
                                    </div>
                                </div>
                            ),
                            change.fields
                        )}
                    </div>
                ),
                changes
            )}
        </div>
    )
}

export default ChangeDetails
