import React from 'react'
import { Input, Label, Select } from '@mondra/ui-components'
import { Row } from '../../components'
import { evaluateInputValue } from '../../utils'
import { MAX_DEC } from '../../constants'
import { INumber } from '../../types/types'


export interface IActivityLossError {
    foodLossesPercentage?: boolean
    disposal?: boolean
}

interface IActivityLoss {
    description: string
    disabled?: boolean
    disposal?: string
    error?: IActivityLossError
    loss?: INumber
    onDisposalChange?: (disposal: string) => void
    onLossChange?: (loss: number) => void
    setError: React.Dispatch<React.SetStateAction<IActivityLossError>>
    title: string
}

const disposalTypes = [
    {
        label: 'Solid waste',
        value: 'Solid',
    },
    {
        label: 'Liquid waste to drain',
        value: 'Liquid',
    },
]

export default function ActivityLoss({
    description,
    disabled,
    disposal,
    error,
    loss = 0,
    onDisposalChange,
    onLossChange,
    title,
}: IActivityLoss) {
    const handleLossChange = (event: any) => {
        onLossChange && onLossChange(evaluateInputValue(event, MAX_DEC))
    }

    const handleDisposalChange = (event: any) => {
        onDisposalChange && onDisposalChange(event.target.value)
    }

    return (
        <Row className="grid-cols-2">
            <Label helpText={description}>{title}</Label>
            <div className="flex justify-start items-center gap-x-4">
                <Input
                    type="number"
                    className="w-24 text-right flex-shrink-0"
                    addonTitle="%"
                    value={loss}
                    disabled={disabled}
                    onChange={handleLossChange}
                />
                <Select
                    disabled={disabled}
                    name="disposalId"
                    className="w-full"
                    noLabel={'Select disposal'}
                    value={disposal}
                    invalid={Boolean(error?.disposal)}
                    onChange={handleDisposalChange}
                    options={disposalTypes}
                />
            </div>
        </Row>
    )
}
