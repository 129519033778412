import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, ToggleSwitch } from '@mondra/ui-components'
import isArray from 'lodash/fp/isArray'
import isString from 'lodash/fp/isString'
import isEmpty from 'lodash/fp/isEmpty'
import {
    TOGGLE_YES_LABELS,
    TYPE_CERTIFIED,
    TYPE_COMPLETED,
    TYPE_PROGRESS,
    SUPPLIED_PRODUCT_LABELS,
} from '../../../constants'
import { FilterContext } from '../../../contexts/FilterProvider'
import { useFiltersByUrl } from '../../../hooks/useFiltersByUrl'
import { SA_TYPE } from '../../../types/types'
import { FilterSection } from './FilterSection'
import { ResetAllFilters } from './ResetAllFilters'


const PRODUCT_STATE_KEY = 'filter'
const INGREDIENT_TYPE_KEY = 'types'

export const STATES_LABEL: any = {
    [TYPE_CERTIFIED]: 'Certified',
    [TYPE_COMPLETED]: 'Data Complete',
    [TYPE_PROGRESS]: 'Data Incomplete',
}

export enum PAGE_TYPES {
    CATEGORIES = 'categories',
    INGREDIENTS = 'ingredients',
    PRODUCTS = 'products',
    SUPPLIED_PRODUCTS = 'supplied products',
    SUPPLIERS = 'suppliers',
    SITES = 'sites',
}

interface IPageFilters {
    pageType: PAGE_TYPES
}

function getFilterCount(filter: any) {
    return isArray(filter) ? filter?.length : isString(filter) && !isEmpty(filter) ? 1 : 0
}
const stateTypes = [TYPE_CERTIFIED, TYPE_COMPLETED, TYPE_PROGRESS]
const INGREDIENT_FILTERS = [
    SA_TYPE.SimpleIngredient,
    SA_TYPE.ComplexIngredient,
    SA_TYPE.Produce,
    SA_TYPE.SpecialIngredient,
]

export const PageFilters = ({ pageType }: IPageFilters) => {
    const { filters, setFilters } = useContext(FilterContext)
    const [allSelectedCount, setAllSelectedCount]: any = useState(0)
    const { filters: URLfilters } = useFiltersByUrl()
    useEffect(() => {
        const archivedSelected = URLfilters?.archived ? 1 : 0
        const statesSelected = getFilterCount(URLfilters?.[PRODUCT_STATE_KEY])
        const typesSelected = getFilterCount(URLfilters?.[INGREDIENT_TYPE_KEY])
        setFilters(URLfilters)
        setAllSelectedCount(archivedSelected + statesSelected + typesSelected)
    }, [URLfilters])

    const handleSetFilterCollection = (type: any, dataKey: string) => () => {
        let currentChecked = []
        /**
         * The purpose of checking isArray for filter query param is
         * If the query param filter=1,2,3 then, parsed filter is [1, 2,3]
         * if the query param filter=1 then, parase filter is 1
         */
        if (filters?.[dataKey]?.includes(type)) {
            currentChecked = isArray(filters?.[dataKey])
                ? filters?.[dataKey]?.filter((item: any) => item !== type)
                : []
        } else {
            currentChecked = [
                ...(isArray(filters?.[dataKey])
                    ? filters[dataKey]
                    : isString(filters?.[dataKey])
                        ? [filters?.[dataKey]]
                        : []),
                type,
            ]
        }
        setFilters({
            ...filters,
            [dataKey]: currentChecked,
        })
    }

    const handleShowArchived = (archived: boolean) => {
        setFilters({
            ...filters,
            archived,
        })
    }

    const clearValue = (prop: string, value: any) => {
        setFilters({
            ...filters,
            [prop]: value,
        })
    }

    const handleClearAll = () => {
        setAllSelectedCount(0)
        setFilters({
            archived: false,
            filter: [],
            types: [],
        })
    }

    return (
        <>
            <ResetAllFilters allSelectedCount={allSelectedCount} handleClearAll={handleClearAll} />
            {(pageType === PAGE_TYPES.PRODUCTS || pageType === PAGE_TYPES.SUPPLIED_PRODUCTS) && (
                <FilterSection
                    title="Product Data States"
                    countProps={{
                        count: getFilterCount(filters?.[PRODUCT_STATE_KEY]),
                        onClear: () => clearValue(PRODUCT_STATE_KEY, []),
                    }}
                >
                    {stateTypes.map((type: string) => {
                        return (
                            <Checkbox
                                key={type}
                                checked={filters?.[PRODUCT_STATE_KEY]?.includes(type)}
                                onChange={handleSetFilterCollection(type, PRODUCT_STATE_KEY)}
                                label={STATES_LABEL[type]}
                                groupClassName="mb-2 items-center"
                            />
                        )
                    })}
                </FilterSection>
            )}

            {(pageType === PAGE_TYPES.PRODUCTS
                || pageType === PAGE_TYPES.SUPPLIED_PRODUCTS
                || pageType === PAGE_TYPES.INGREDIENTS
                || pageType === PAGE_TYPES.SUPPLIERS
                || pageType === PAGE_TYPES.CATEGORIES
                || pageType === PAGE_TYPES.SITES) && (
                <FilterSection
                    title={`Archived ${pageType}`}
                    countProps={{
                        count: +filters?.archived,
                        onClear: () => clearValue('archived', false),
                    }}
                >
                    <div className="flex justify-between">
                        <div>
                            <div className="text-gray-800 text-sm font-medium">
                                View archived {pageType} only
                            </div>
                            <div className="text-gray-600 text-xs font-medium">
                                Toggle on to view only archived {pageType}
                            </div>
                        </div>
                        <ToggleSwitch
                            groupClassName="inline-flex flex-col items-start gap-y-2"
                            helpLabels={TOGGLE_YES_LABELS}
                            checked={filters?.archived}
                            onChange={handleShowArchived}
                        />
                    </div>
                </FilterSection>
            )}

            {(pageType === PAGE_TYPES.SUPPLIED_PRODUCTS || pageType === PAGE_TYPES.INGREDIENTS) && (
                <FilterSection
                    title="Types"
                    countProps={{
                        count: getFilterCount(filters?.[INGREDIENT_TYPE_KEY]),
                        onClear: () => clearValue(INGREDIENT_TYPE_KEY, []),
                    }}
                >
                    {INGREDIENT_FILTERS.map((ingredientType: string) => {
                        return (
                            <Checkbox
                                key={ingredientType}
                                checked={filters?.[INGREDIENT_TYPE_KEY]?.includes(ingredientType)}
                                onChange={handleSetFilterCollection(
                                    ingredientType,
                                    INGREDIENT_TYPE_KEY
                                )}
                                label={SUPPLIED_PRODUCT_LABELS[ingredientType]?.title}
                                groupClassName="mb-2 items-center"
                            />
                        )
                    })}
                </FilterSection>
            )}
        </>
    )
}
