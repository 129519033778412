import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Pill } from '@mondra/ui-components'
import urls from '../../constants/urls'
import { resolveRouteURL } from '../../utils/getURL'
import Sidebar from '../../components/Sidebar'
import { useCompanyId } from '../../hooks/useCompanyId'
import { useFeature } from '../../hooks/useFeature'
import { padTensZero } from '../../utils'
import { FEATURES } from '../../constants/features'
import { NotesContext } from '../../contexts/NotesProvider'


export function HomeSidebar() {
    const location = useLocation()
    const companyId = useCompanyId()
    const { pathname } = location
    const showNotes = useFeature(FEATURES.NOTES_DASHBOARD)

    const { notesUnreadCount } = useContext(NotesContext)

    return (
        <Sidebar>
            <Sidebar.Item
                as={NavLink}
                to={resolveRouteURL(urls.PagePaths.HOME, { companyId })}
                selected={pathname.includes('/dashboard')}
                iconType="activity"
                right="Overview"
                title="Overview"
            />
            <Sidebar.Group>Notifications</Sidebar.Group>
            {showNotes && (
                <Sidebar.Item
                    as={NavLink}
                    className="w-full"
                    to={resolveRouteURL(urls.PagePaths.NOTES, { companyId })}
                    selected={pathname.includes('/notes')}
                    iconType="addComment"
                    right={
                        <div className="flex justify-between items-center">
                            <span>Notes</span>
                            <Pill className="font-normal" color="white">
                                {padTensZero(notesUnreadCount)}
                            </Pill>
                        </div>
                    }
                    title="Notes"
                />
            )}
        </Sidebar>
    )
}
