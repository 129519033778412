import React, { useCallback, useState, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'

import { useGridControls } from '../../hooks/useGridControls'
import { useCompanyId } from '../../hooks/useCompanyId'
import { useFiltersByUrl } from '../../hooks/useFiltersByUrl'

import { Grid } from '../../components/grid/Grid'
import { PageHeader } from '../../components/PageHeader'
import { PAGE_TYPES, PageFilters } from '../../components/PageHeader/PageFilters'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { EntityTypeEnum } from '../../types/types'
import AddEditSiteSlideover from '../../slideovers/site/SiteSlideover'
import { serverSideGridProps } from '../../constants/grid'
import { URLs } from '../../constants'
import { useControls } from '../../hooks/useControls'
import { siteColumns } from './SitesColumns'


export function Sites() {
    const { setGridApi, refreshGrid } = useGridControls()
    const { close, isOpened, open } = useControls()
    const [editSiteId, setEditSiteId] = useState('')
    const { filters, setFilters } = useFiltersByUrl()
    const companyId = useCompanyId()

    const handleRefetch = useCallback(() => {
        refreshGrid()
    }, [refreshGrid])

    const handleFilterChange = (filter: any) => {
        setFilters({
            name: filter,
        })
    }

    const onEditSite = useCallback((id: string) => {
        setEditSiteId(id)
        open()
    }, [])

    const onSlideoverClose = useCallback(() => {
        close()
        setEditSiteId('')
    }, [])

    const handleFilterSubmit = (filters: any) => {
        setFilters(filters)
    }

    //TODO: Check the below redirect and do at the top level of the routes
    if (isEmpty(companyId)) {
        return <Redirect to="/no-company" />
    }

    const urlOptions = useMemo(() => ({ companyId }), [companyId])

    return (
        <GridPageLayout isPadding>
            <PageHeader
                title={PAGE_TYPES.SITES}
                primaryAction={open}
                onSearch={handleFilterChange}
                filterList={<PageFilters pageType={PAGE_TYPES.SITES} />}
                onFilterSubmit={handleFilterSubmit}
            />

            <GridPageLayout>
                <Grid
                    columnDefs={siteColumns}
                    context={{
                        onEditSite,
                        refetch: handleRefetch,
                    }}
                    entityType={EntityTypeEnum.SITE}
                    filters={filters}
                    onApiAvailable={setGridApi}
                    setFilters={setFilters}
                    url={URLs.Site.GET_ALL}
                    urlOptions={urlOptions}
                    {...serverSideGridProps}
                />
            </GridPageLayout>
            <AddEditSiteSlideover
                companyId={companyId}
                isEdit={!isEmpty(editSiteId)}
                siteId={editSiteId}
                isOpen={isOpened}
                onSaved={handleRefetch}
                onClose={onSlideoverClose}
            />
        </GridPageLayout>
    )
}
