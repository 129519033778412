import React from 'react'
import { CertificateStateEnum } from '../types/types'
import { PER_PIECE } from '../constants'
import { getGradeHeaderColor } from '../constants/gradeHeaderColors'
import { measureTitles, SLI_DESC } from '../constants/ecoImpactMeasures'
import { significantFigureFormatter } from '../utils/numberFormatter'
import getImpactMeasureUICopy from '../utils/getImpactMeasureUICopy'
import { ImpactMeasureType } from '../types/ecoImpacts'


interface IEcoImpactTooltipContent {
    bioImpact: any
    carbonImpact: any
    customMeasureType?: string
    measureType: ImpactMeasureType
    pollutionImpact: any
    score: string
    state: CertificateStateEnum
    waterImpact: any
}

export default function EcoImpactTooltipContent({
    bioImpact = 0,
    carbonImpact = 0,
    customMeasureType,
    measureType = 'per100g',
    pollutionImpact = 0,
    score,
    state,
    waterImpact = 0,
}: IEcoImpactTooltipContent) {
    const color = getGradeHeaderColor(score)
    const impactTitle = state === CertificateStateEnum.Certified ? state : 'Un-certified'

    const { carbonMeasure, waterUsageMeasure, eutroMeasure, bioMeasure } = getImpactMeasureUICopy()
    const unitKey = measureType === PER_PIECE ? 'pieceUnit' : 'unit'

    return (
        <div className="bg-white rounded-lg shadow-lg w-72">
            <div className={`rounded-t-lg p-4 ${color.bg}`}>
                <div className={`text-lg font-medium ${color.text}`}>
                    {impactTitle} impacts {score}
                </div>
                <div className={`text-xs font-medium ${color.desc}`}>
                    {customMeasureType || measureTitles[measureType] || ''}
                </div>
            </div>
            <div className="px-4 py-2">
                <div className="border-b-2 py-2">
                    <div className="grid grid-cols-3 text-sm font-medium text-gray-900">
                        <div className="w-24">{carbonMeasure.label}:</div>
                        <div className="text-right w-24">
                            {significantFigureFormatter(carbonImpact)}
                        </div>
                        <div className="ml-4 text-xs text-gray-500">
                            {carbonMeasure[unitKey]} {carbonMeasure.measure}
                        </div>
                    </div>
                </div>

                <div className="border-b-2 py-2">
                    <div className="grid grid-cols-3 text-sm font-medium text-gray-900">
                        <div className="w-24">{waterUsageMeasure.label}:</div>
                        <div className="text-right w-24">
                            {significantFigureFormatter(waterImpact)}
                        </div>
                        <div className="ml-4 text-xs text-gray-500">
                            {waterUsageMeasure[unitKey]} {waterUsageMeasure.measure}
                        </div>
                    </div>
                </div>

                <div className="border-b-2 py-2">
                    <div className="grid grid-cols-3 text-sm font-medium text-gray-900">
                        <div className="w-28">{eutroMeasure.label}:</div>
                        <div className="text-right w-24">
                            {significantFigureFormatter(pollutionImpact)}
                        </div>
                        <div className="ml-4 text-xs text-gray-500 whitespace-nowrap">
                            {eutroMeasure[unitKey]} {eutroMeasure.measure}
                        </div>
                    </div>
                </div>

                <div className="py-2">
                    <div className="grid grid-cols-3 text-sm font-medium text-gray-900">
                        <div className="w-28">{bioMeasure.label}:</div>
                        <div className="w-24 text-right">
                            {significantFigureFormatter(bioImpact)}
                        </div>
                        <div
                            className="ml-4 text-xs text-gray-500"
                            title={SLI_DESC}
                            style={{ marginTop: '2px' }}
                        >
                            {bioMeasure.measure}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
