import React, { useContext, useEffect, useState } from 'react'
import { ImpactType } from '@mondra/ui-components/@types'
import { ImpactRadioGroup, ImpactValueEnum } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import useFetch from '../../../hooks/useFetch'
import SlideoverProvider from '../../../contexts/SlideoverProvider'
import { TileMenuContext } from '../TileMenuProvider'
import { METHOD_GET, URLs } from '../../../constants'
import { impactCodeByName } from '../../../constants/ecoImpactMeasures'
import getURL from '../../../utils/getURL'
import { IProduct } from '../../../types/types'
import { ContributionTreeGrid } from './ContributionTreeGrid'


interface IProps {
    companyId: string
    productId?: string
    setProduct: (product: any) => void
}

export default function ContributionTree({ companyId, productId, setProduct }: IProps) {
    const [refreshId, setRefreshId] = useState(Date.now())
    const [impactTab, setTab] = useState<ImpactType>(ImpactValueEnum.CARBON)
    const { setRefetch } = useContext(TileMenuContext)

    const { data, loading, refetch } = useFetch<IProduct>({
        autoFetch: true,
        method: METHOD_GET,
        url: getURL(URLs.Product.GET_PRIMARY, { companyId, productId }),
    })

    function handleRefetch() {
        refetch()
        setRefreshId(Date.now())
    }

    useEffect(() => {
        if (!loading && !isEmpty(data)) {
            setRefetch(() => handleRefetch)
            setProduct(data)
        }
    }, [data, loading])

    return (
        <SlideoverProvider companyId={companyId || ''} onSaved={handleRefetch}>
            <div className="flex-grow bg-white flex flex-col">
                <ImpactRadioGroup selected={impactTab} onChange={setTab} />
                <ContributionTreeGrid
                    refreshId={refreshId}
                    companyId={companyId}
                    productId={productId || ''}
                    impactCode={impactCodeByName[impactTab]}
                />
            </div>
        </SlideoverProvider>
    )
}
