import React, { useState } from 'react'
import { IProductNode } from '../../types/supply-chain-types'
import { Ownership } from '../../types/types'


interface IEditDetails {
    node: any
    other?: any
    type: string
}

interface ITileMenuContextProps {
    product?: IProductNode
    ownership: Ownership
    refetch?: () => void
    setOwnership: (ownership: Ownership) => void
    setRefetch: React.Dispatch<any>
}

interface ITileMenuProviderProps {
    product?: IProductNode
    children: React.ReactNode
}

export const DEFAULT_DETAILS: IEditDetails = {
    node: {
        productMetadata: {
            ingredients: [],
            packaging: {},
            processing: {},
            retail: {},
        },
    },
    other: {},
    type: 'none',
}

export const TileMenuContext = React.createContext<ITileMenuContextProps>({
    ownership: [],
    product: undefined,
    refetch: () => null,
    setOwnership: () => null,
    setRefetch: () => null,
})

export default function TileMenuProvider({ product, children }: ITileMenuProviderProps) {
    //This ownership is going to hold [] companyId and rootParentId
    const [ownership, setOwnership] = useState<Ownership>([])
    const [refetch, setRefetch] = useState<any>()

    return (
        <TileMenuContext.Provider
            value={{
                ownership,
                product,
                refetch,
                setOwnership,
                setRefetch,
            }}
        >
            {children}
        </TileMenuContext.Provider>
    )
}
