import React, { useContext } from 'react'

import { Button } from '@mondra/ui-components'
import { UserContext } from '../contexts/UserProvider'


const TO_EMAIL = 'ops@mondra.com'
const getSubject = ({ productName, companyName }: any) =>
    `Please upload Nutritional Information for ${productName} from ${companyName}`
const BODY = `Hi There,

Please provide your product's nutritional information, as it is for the composition you have loaded into Mondra Platform for this Certificate. Ideally provide this in digital format (txt, csv, excel etc).

Here's how it works:

1. You attach your nutritional information to this email and press send
2. We convert it to our format and upload
3. Your certificate page will show your nutritional details! (We'll email you to tell you it's up and running)

Many thanks!
	`

export function NutritionEmailLink({ productName, readOnly }: any) {
    const { company } = useContext(UserContext)

    const subject = getSubject({
        companyName: company.name,
        productName,
    })

    if (readOnly) {
        return (
            <Button variant="secondary" type="button" disabled={readOnly} aria-disabled={readOnly}>
                Email your nutritional information
            </Button>
        )
    }

    return (
        <a
            href={`mailto:${TO_EMAIL}?Subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(BODY)}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Button variant="secondary" type="button">
                Email your nutritional information
            </Button>
        </a>
    )
}
