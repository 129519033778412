import { URLs } from '../constants'
import getURL from '../utils/getURL'
import useFetch from './useFetch'
import { useCompanyId } from './useCompanyId'


export function useProductStats() {
    const companyId = useCompanyId()
    const { data: productStats, loading, refetch: refetchProductStats } = useFetch({
        errMessage: 'Failed to fetch product stats',
        url: getURL(URLs.DashboardStats.COMPANY_PRODUCTS, { companyId }),
    })
    return {
        productStats,
        productStatsLoading: loading,
        refetchProductStats,
    }
}
