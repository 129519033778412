import React from 'react'
import { IProduct, NodeType } from '../../types/types'
import { IStageActivity } from '../../types/stages'
import ActivityForm, { IActivityFormError } from '../components/ActivityForm'
import IsRequiredToggle from '../components/IsRequiredToggle'


export type IActivityError = IActivityFormError

interface IActivityTab {
    activity?: IStageActivity
    companyId: string
    disabled: boolean
    error: IActivityError
    formData: IStageActivity
    isLocked: boolean
    isRequired: boolean
    onChange: (changes: Partial<IStageActivity>) => void
    product: IProduct
    setError: React.Dispatch<React.SetStateAction<IActivityError>>
    setRequired: any
}

export default function ActivityTab({
    activity,
    companyId,
    disabled,
    error,
    formData,
    isLocked,
    isRequired,
    onChange,
    product,
    setError,
    setRequired,
}: IActivityTab) {
    return (
        <React.Fragment>
            <IsRequiredToggle
                title="Processing activity and overall losses"
                description="Select activity and define any batch level losses which occur."
                helpLabels={{
                    left: '',
                    right: 'On',
                }}
                isRequired={isRequired}
                onChange={setRequired}
            />
            <ActivityForm
                activity={activity}
                companyId={companyId}
                disabled={disabled}
                isLocked={isLocked}
                error={error}
                setError={setError}
                formData={formData}
                onChange={onChange}
                product={product}
                stage={NodeType.PROCESSING}
            />
        </React.Fragment>
    )
}
