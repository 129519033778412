import React, { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { Icon } from '@mondra/ui-components'
import { Transition } from '@headlessui/react'
import { getInitials } from '../utils'
import { MESSAGE_TRIGGER_STATE, MESSAGE_TRIGGER_SUB_TYPE } from '../constants'
import { getAction } from '../components/NotificationHistory'


export interface ISnackBarProps {
    show: boolean
    setShow: (x: boolean) => void
    message: any
}

export default function NotificationBar(props: ISnackBarProps) {
    const { show, message = {}, setShow } = props
    const { url } = message

    const getDisplayIcon = () => {
        switch (message?.notificationType) {
            case MESSAGE_TRIGGER_SUB_TYPE.SUPPLYCHAIN:
                return (
                    <span className="text-sm text-violet-500">
                        <Icon type="ingredients" />
                    </span>
                )
            case MESSAGE_TRIGGER_SUB_TYPE.FILEGENERATED:
                return (
                    <span className="text-sm">
                        <Icon type="book" />
                    </span>
                )
            case MESSAGE_TRIGGER_SUB_TYPE.V2CALC:
                return (
                    <span className="text-sm">
                        <Icon type="impact" />
                    </span>
                )
            case MESSAGE_TRIGGER_SUB_TYPE.USERMANAGEMENT:
                return 'ingredients'
            case MESSAGE_TRIGGER_SUB_TYPE.CERTIFICATESTATECHANGE:
                return (
                    <span className="h-8 w-8 flex items-center justify-center text-lg bg-primary-500 rounded-full text-white">
                        <Icon type="butterfly" size={20} />
                    </span>
                )
            default:
                return getInitials(message?.supplierName)
        }
    }

    const getDisplayAction = () => {
        switch (message.notificationType) {
            case MESSAGE_TRIGGER_SUB_TYPE.FILEGENERATED:
                return url && getAction(url, 'View File', true)
            case MESSAGE_TRIGGER_SUB_TYPE.SUPPLYCHAIN:
                return getAction(url, 'Optimise product')
            case MESSAGE_TRIGGER_SUB_TYPE.USERMANAGEMENT:
                return 'ingredients'
            case MESSAGE_TRIGGER_SUB_TYPE.V2CALC:
                return getAction(url, 'View product')
            case MESSAGE_TRIGGER_SUB_TYPE.CERTIFICATESTATECHANGE:
                if (message.notificationSubType === MESSAGE_TRIGGER_STATE.CERTIFIED) {
                    return getAction(url, 'View product')
                }
                if (message.notificationSubType === MESSAGE_TRIGGER_STATE.RECERTIFIED) {
                    return getAction(url, 'View certificate')
                }
                return <div />
            default:
                return getInitials(message?.supplierName)
        }
    }

    return createPortal(
        <div
            aria-live="assertive"
            className="fixed inset-0 top-10 flex items-start justify-center pointer-events-none sm:p-6 sm:items-start sm:justify-end z-toast"
        >
            <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="max-w-sm w-full shadow-2xl bg-white rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-4 pt-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <span className="flex items-center justify-center h-8 w-8 min-w-8 -mt-1">
                                    {getDisplayIcon()}
                                </span>
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm leading-5 font-medium text-gray-900">
                                    {message?.title}
                                </p>
                                <p className="mt-1 text-sm text-gray-500 whitespace-normal">
                                    {message?.shortDesc}
                                </p>
                                <div className="py-2 mb-2 rounded">{getDisplayAction()}</div>
                            </div>
                            <div className="">
                                <Icon
                                    type="close"
                                    className="font-thin text-lg -mt-1 text-gray-500 hover:text-primary-600 h-5 w-5"
                                    role="button"
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        setShow(false)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>,
        document.body
    )
}
