import React from 'react'
import { Icon } from '@mondra/ui-components'

import { Tabs, TabList, Tab, TabPanels, TabPanel } from './Tabs'
import { EcoImpactDetailChart } from './EcoImpactDetailChart'
import { EcoImpactList } from './EcoImpactList'
import { Measure } from './Measure'
import { Facts } from './Facts'
import { impactStages } from './impactConstants'


function getMeasures(percentages: any) {
    const { carbon, water, eutro, bio } = percentages
    return [
        { bgColor: 'bg-eco-chart-carbon', percentage: carbon, title: 'Carbon' },
        { bgColor: 'bg-eco-chart-water', percentage: water, title: 'Water Usage' },
        { bgColor: 'bg-eco-chart-eutro', percentage: eutro, title: 'Water Pollution' },
        { bgColor: 'bg-eco-chart-bio', percentage: bio, title: 'Biodiversity' },
    ]
}

interface IImpactDetailsByStageProps {
    stageImpacts: any
    facts?: any
}
export default function ImpactDetailsByStage({
    stageImpacts = {},
    facts = {},
}: IImpactDetailsByStageProps) {
    return (
        <div className="col-span-2 sm:col-span-4 md:col-span-8 lg:col-span-12">
            <div className="flex w-full rounded-t-lg border border-b-0 border-gray-300 bg-white pt-4 pb-2 divide-x divide-gray-300">
                {impactStages.map(stage => (
                    <EcoImpactDetailChart
                        key={stage.stage}
                        className="w-1/6"
                        {...(stageImpacts[stage.stage]?.percentages || {})}
                    />
                ))}
            </div>
            <Tabs defaultIndex={0}>
                <TabList>
                    {impactStages.map(stage => (
                        <StageTab
                            key={stage.stage}
                            minWidth="w-1/6"
                            title={stage.title}
                            iconType={stage.iconType}
                        />
                    ))}
                </TabList>
                <TabPanels>
                    {impactStages.map(stage => (
                        <StageTabPanel
                            key={stage.stage}
                            description={stage.description}
                            ecoImpacts={stageImpacts[stage.stage]?.impacts || []}
                            percentages={stageImpacts[stage.stage]?.percentages || {}}
                            facts={facts[stage.stage] || []}
                            title={stage.title}
                        />
                    ))}
                </TabPanels>
            </Tabs>
        </div>
    )
}

interface IStageTabProps {
    iconType: string
    minWidth: string
    title: string
}
function StageTab({ iconType, minWidth, title, ...rest }: IStageTabProps) {
    return (
        <Tab minWidth={minWidth} className="flex flex-col items-center" {...rest}>
            <Icon type={iconType} />
            <div className="hidden sm:block text-xs sm:text-sm md:text-base lg:text-xl">
                {title}
            </div>
        </Tab>
    )
}

function StageTabPanel({ title, description, ecoImpacts, percentages, facts }: any) {
    const measures = getMeasures(percentages)
    const showFacts = facts?.length

    return (
        <TabPanel>
            <h4 className="text-gray-900 text-sm sm:text-base lg:text-lg font-semibold">{title}</h4>
            <p className="text-gray-700 text-xs sm:text-sm lg:text-base">{description}</p>
            <div className="flex flex-col md:flex-row md:space-x-4 mt-5 pb-2 border-b border-gray-300">
                <div className="w-full md:w-1/2">
                    <EcoImpactList ecoImpacts={ecoImpacts} />
                </div>
                <div className="w-full md:w-1/2 grid grid-cols-2 sm:grid-cols-4 gap-y-2 border-t border-gray-300 md:mt-0">
                    {measures.map((measure: any) => (
                        <div className="col-span-1" key={measure.title}>
                            <Measure {...measure} />
                        </div>
                    ))}
                </div>
            </div>
            {!!showFacts && (
                <div className="mt-6">
                    <Facts facts={facts} />
                </div>
            )}
        </TabPanel>
    )
}
