import React from 'react'
import { Button } from '@mondra/ui-components'
import classNames from 'classnames'
import { IClassNameProps } from '../types/types'
import { LightTooltip } from '.'


interface IWaterIconTooltip extends IClassNameProps {
    iconOnly?: boolean
}

export default function WaterIconTooltip({ className, iconOnly }: IWaterIconTooltip) {
    const Icon = (
        <Button
            variant="ghost"
            isOnlyIcon
            iconType="water"
            className={classNames('p-1 text-primary-500', className)}
        />
    )
    if (iconOnly) {
        return Icon
    }
    return (
        <LightTooltip
            enterDelay={500}
            enterNextDelay={500}
            title={
                <div className="bg-white rounded-lg shadow-lg border border-gray-50 p-4 text-black text-sm">
                    The impacts associated with water as an ingredient are derived from the site
                    where your product is processed. It cannot have suppliers.
                </div>
            }
        >
            {Icon}
        </LightTooltip>
    )
}
