import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { ICellRendererParams } from 'ag-grid-community'

import {
    CopyToClipboard,
    Menu,
    MoreMenuButton,
    MenuList,
    MenuItem,
    MenuDevider,
    NotificationBar,
} from '../../components'
import useFetch from '../../hooks/useFetch'
import ArchiveProduct from '../../components/ArchiveProduct'
import { UserContext } from '../../contexts/UserProvider'
import { isOwner, isMondraAdmin } from '../../hooks/userRoles'
import getURL from '../../utils/getURL'
import {
    CONTRIBUTION_TREE_COMPLETED,
    CONTRIBUTION_TREE_REQUESTING,
    EXPORT_CONTRIBUTION_TREE,
    METHOD_POST,
    MESSAGE_TRIGGER_SUB_TYPE,
    GRADE_DESC_COMPLETED,
    GRADE_DESC_REQUESTING,
    GRADE_TITLE,
    URLs,
} from '../../constants'


export default function ProductActions({ data: product, context }: ICellRendererParams) {
    const { onEditProduct, onProductClone, refetch, setAuditId, setSideLoadProductId } = context
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState({})
    const productId = product.id
    const { company } = useContext(UserContext)
    const { id: companyId } = company

    const { currentUser } = useContext(UserContext)
    const canShowArchived = isOwner(currentUser) || isMondraAdmin(currentUser)

    const { data: gradingFileLocation, refetch: fetchGrading } = useFetch({
        autoFetch: false,
        defaultRes: [],
        method: METHOD_POST,
        url: getURL(URLs.Product.DOWNLOAD_GRADE, { companyId, productId }),
    })

    const { data: contributionTreeFileLocation, refetch: exportContributionTree } = useFetch({
        autoFetch: false,
        defaultRes: [],
        method: METHOD_POST,
        url: getURL(URLs.Product.EXPORT_CONTRIBUTION_TREE, { companyId, productId }),
    })

    const handleEditProduct = () => {
        onEditProduct && onEditProduct(productId)
    }

    const handleOpenAudit = () => {
        setAuditId(productId)
    }

    const handleExportGrading = () => {
        fetchGrading()
        setMessage({
            notificationType: MESSAGE_TRIGGER_SUB_TYPE.FILEGENERATED,
            shortDesc: `${product?.name} ${GRADE_DESC_REQUESTING}`,
            title: GRADE_TITLE,
        })
        setShow(true)
    }

    const handleExportCT = () => {
        exportContributionTree()
        setMessage({
            notificationType: MESSAGE_TRIGGER_SUB_TYPE.FILEGENERATED,
            shortDesc: `${product?.name} ${CONTRIBUTION_TREE_REQUESTING}`,
            title: EXPORT_CONTRIBUTION_TREE,
        })
        setShow(true)
    }

    useEffect(() => {
        if (!isEmpty(gradingFileLocation)) {
            setMessage({
                notificationType: MESSAGE_TRIGGER_SUB_TYPE.FILEGENERATED,
                shortDesc: `${product?.name} ${GRADE_DESC_COMPLETED}`,
                title: GRADE_TITLE,
                url: gradingFileLocation?.url,
            })
            setShow(true)
        }
        if (!isEmpty(contributionTreeFileLocation)) {
            setMessage({
                notificationType: MESSAGE_TRIGGER_SUB_TYPE.FILEGENERATED,
                shortDesc: `${product?.name} ${CONTRIBUTION_TREE_COMPLETED}`,
                title: EXPORT_CONTRIBUTION_TREE,
                url: contributionTreeFileLocation?.url,
            })
            setShow(true)
        }
    }, [contributionTreeFileLocation, gradingFileLocation])

    const handleProductClone = () => {
        onProductClone && onProductClone(product)
    }

    return (
        <>
            <Menu>
                <MoreMenuButton />
                <MenuList style={{ width: 'max-content' }} ariaLabelledby="Product action menu">
                    <MenuItem onSelect={handleOpenAudit}>Audit</MenuItem>
                    {!product.isProductLocked && (
                        <MenuItem onSelect={handleEditProduct}>Edit Product</MenuItem>
                    )}
                    <MenuItem onSelect={handleProductClone}>Clone</MenuItem>
                    {canShowArchived && !product.isProductLocked && (
                        <ArchiveProduct
                            apiNode="products"
                            id={productId}
                            name={product.name}
                            companyId={companyId}
                            isArchived={product.isArchived}
                            refresh={refetch}
                        />
                    )}
                    <MenuDevider />
                    <MenuItem
                        as={NavLink}
                        to={getURL(URLs.Pages.CONTRIBUTION_TREE, { companyId, productId })}
                    >
                        Contribution tree
                    </MenuItem>
                    {isMondraAdmin(currentUser) && !product.isProductLocked && (
                        <MenuItem onSelect={() => setSideLoadProductId(productId)}>
                            Sideload certificate
                        </MenuItem>
                    )}
                    <MenuDevider />

                    <MenuItem onSelect={handleExportCT}>Export contribution tree</MenuItem>
                    <MenuItem onSelect={handleExportGrading}>Export grading file</MenuItem>
                    <MenuItem>
                        <CopyToClipboard infoText="Product id copied" text={productId}>
                            Copy product id
                        </CopyToClipboard>
                    </MenuItem>
                </MenuList>
            </Menu>
            <NotificationBar show={show} setShow={setShow} message={message} />
        </>
    )
}
