import React, { useContext, useMemo } from 'react'
import { isEmpty } from 'lodash'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import { Notification } from '@mondra/ui-components'
import { PinnedItem } from '../../components/PinnedItem'
import {
    METHOD_POST,
    PINNED_ITEMS_DESC,
    PINNED_ITEMS_LABEL,
    RECENT_VIEWED_LABEL,
    URLs,
} from '../../constants'
import { UserContext } from '../../contexts/UserProvider'
import useFetch from '../../hooks/useFetch'
import { useRecentViewed } from '../../hooks/useRecentViewed'
import getURL from '../../utils/getURL'
import RecentLoader from './RecentLoader'


interface IDashboardItemList {
    certifiedState: string
    isCompleted: boolean
    nodeId: string
    nodeName: string
    nodeType: string
    supplierId: string
}

export function DashboardItemList({ pinnedItems = false }: { pinnedItems?: boolean }) {
    const { company, currentUser } = useContext(UserContext)
    const { getRecentItems } = useRecentViewed({ companyId: company?.id, userId: currentUser?.id })
    const recentItems = !pinnedItems ? getRecentItems() : []

    const { data, loading } = useFetch<IDashboardItemList[]>({
        autoFetch: !isEmpty(company?.id) && !pinnedItems,
        body: recentItems,
        defaultRes: [],
        errMessage: 'Error while fetching recent activities',
        method: METHOD_POST,
        url: getURL(URLs.Dashboard.NODE_DETAILS, { companyId: company?.id }),
    })

    const orderedItems = useMemo(() => {
        return map(rit => {
            const item = find({ nodeId: rit.nodeId }, data) || {}
            return { ...rit, ...item }
        }, recentItems)
    }, [data, recentItems, pinnedItems])

    if (loading) {
        return <RecentLoader />
    }
    return (
        <div className="bg-white w-full animate-fade-in pr-6 pb-6">
            <h4 className="text-base text-gray-800 font-bold mb-3">
                {pinnedItems ? 'Pinned' : 'Recently viewed'}
            </h4>
            <div className="grid grid-cols-12 mt-6 gap-6">
                {map((item: IDashboardItemList) => {
                    return (
                        <div className="col-span-12 lg:col-span-6 xxl:col-span-4" key={item.nodeId}>
                            <PinnedItem
                                id={item.nodeId}
                                name={item.nodeName}
                                state={item.certifiedState}
                                supplierId={item.supplierId}
                                type={item.nodeType}
                            />
                        </div>
                    )
                }, orderedItems)}
                {isEmpty(orderedItems) && (
                    <div className="col-span-12">
                        <Notification
                            type="info"
                            label={pinnedItems ? PINNED_ITEMS_LABEL : RECENT_VIEWED_LABEL}
                            description={pinnedItems ? PINNED_ITEMS_DESC : ''}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
