import { generatePath } from 'react-router'
import keys from 'lodash/fp/keys'
import forEach from 'lodash/fp/forEach'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import qs from 'qs'
import { IString } from '../types/types'
import { URLs } from '../constants'


interface IGetURL {
    apiNode?: IString
    channel?: IString
    code?: IString
    companyId?: IString
    countryCode?: IString
    id?: IString
    ingredientId?: IString
    isComplete?: boolean
    productId?: IString
    key?: IString
    region?: IString
    sku?: IString
    siteId?: IString
    supplierId?: IString
    type?: IString
    userId?: IString
    value?: any
}

function UrlParser(path: string, params: IGetURL): string {
    let url = path
    forEach((key: string) => {
        url = url.replace(new RegExp(`{${key}}`, 'gi'), getOr('', key, params))
    }, keys(params))
    return url
}

export default function getURL(path: string, params: IGetURL = {}, queryString = ''): string {
    return `${UrlParser(path, params)}${queryString}`
}

interface IProductPageUrl {
    companyId: string
    productId: string
    supplierId?: string | boolean
    isSuppliedProduct?: boolean
}

export function getProductPageUrl({
    companyId,
    productId,
    supplierId,
    isSuppliedProduct,
}: IProductPageUrl) {
    return getURL(
        isSuppliedProduct ? URLs.Pages.SUPPLIED_PRODUCT_OVERVIEW : URLs.Pages.PRODUCT_OVERVIEW,
        { companyId, productId },
        qs.stringify(
            {
                supplierId: !isEmpty(supplierId) ? supplierId : undefined,
            },
            { addQueryPrefix: true }
        )
    )
}

export function resolveRouteURL(path: string, params: any) {
    return generatePath(path, params)
}
