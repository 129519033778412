import React from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'
import { Link } from 'react-router-dom'
import { cloneValidElement } from '../utils'
import { HOME_URL } from '../constants'


export default function BreadCrumbs({
    space = 'space-x-4',
    ariaLabel = 'breadcrumbs',
    children,
    ...rest
}: any) {
    return (
        <nav className="flex" aria-label={ariaLabel} {...rest}>
            <ol className={classNames('flex items-center', space)}>
                {React.Children.map(children, (child, index) => {
                    return cloneValidElement(child, {
                        index,
                    })
                })}
            </ol>
        </nav>
    )
}

export function Breadcrumb({ index, className, children }: any) {
    const isFirstCrumb = index === 0

    return (
        <li>
            <div className={classNames('flex items-center', className)}>
                {!isFirstCrumb && <Icon type="chevronRight" size={14} className="text-gray-400" />}
                <div
                    className={classNames(
                        'text-sm font-medium text-gray-700',
                        !isFirstCrumb && 'ml-2'
                    )}
                >
                    {children}
                </div>
            </div>
        </li>
    )
}

export function HomeBreadcrumb(props: any) {
    return (
        <Breadcrumb {...props}>
            <Link to={HOME_URL}>
                <Icon type="home" className="inline-block -mt-1" size={18} />
            </Link>
        </Breadcrumb>
    )
}
