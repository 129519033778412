import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import classNames from 'classnames'


enum SortOrderEnum {
    ASC = 'asc',
    DESC = 'desc',
}

enum ColumnTypeEnum {
    RIGHT_ALIGNED = 'rightAligned',
}

export interface ICustomHeaderParams extends IHeaderParams {
    description: string
    titleClassName?: string
    descriptionClassName?: string
}

export function CustomHeader({
    showColumnMenu,
    column,
    setSort,
    enableMenu,
    description,
    enableSorting,
    displayName,
    descriptionClassName,
    titleClassName,
}: ICustomHeaderParams) {
    const { type } = column.getColDef()

    const [ascSort, setAscSort] = useState<boolean>(column.isSortAscending())
    const [descSort, setDescSort] = useState<boolean>(column.isSortDescending())
    const [noSort, setNoSort] = useState<boolean>(
        !column.isSortAscending() && !column.isSortDescending()
    )
    const refButton = useRef<HTMLSpanElement>(null)

    const onSortChanged = () => {
        setAscSort(column.isSortAscending())
        setDescSort(column.isSortDescending())
        setNoSort(!column.isSortAscending() && !column.isSortDescending())
    }

    const getCurrentOrder = useCallback(() => {
        if (noSort) {
            return SortOrderEnum.ASC
        } else if (ascSort) {
            return SortOrderEnum.DESC
        } else {
            return null
        }
    }, [ascSort, noSort])

    const getCurrentOrderIcon = useCallback(() => {
        if (ascSort) {
            return `ag-icon-${SortOrderEnum.ASC}`
        } else if (descSort) {
            return `ag-icon-${SortOrderEnum.DESC}`
        } else {
            return ''
        }
    }, [ascSort, descSort])

    const onSortRequested = useCallback(
        (order: 'asc' | 'desc' | null, event: any) => {
            setSort(order, event.shiftKey)
        },
        [setSort]
    )

    const handleHeaderClick = useCallback(
        event => {
            if (enableSorting) {
                onSortRequested(getCurrentOrder(), event)
            }
        },
        [enableSorting, getCurrentOrder, onSortRequested]
    )

    const handleMenuClick = useCallback(() => {
        if (refButton.current) {
            showColumnMenu(refButton.current)
        }
    }, [showColumnMenu])

    useEffect(() => {
        column.addEventListener('sortChanged', onSortChanged)
        onSortChanged()

        return () => {
            column.removeEventListener('sortChanged', onSortChanged)
        }
    })

    return (
        <div
            className={classNames(
                'flex h-full w-full items-center justify-between',
                type === ColumnTypeEnum.RIGHT_ALIGNED ? 'flex-row-reverse' : ''
            )}
        >
            <div
                className={classNames(
                    'flex w-full flex-1 items-center overflow-hidden',
                    type === ColumnTypeEnum.RIGHT_ALIGNED ? 'flex-row-reverse text-right' : ''
                )}
                onClick={handleHeaderClick}
                role="presentation"
            >
                <div
                    className={classNames(
                        'flex flex-col overflow-hidden',
                        type === ColumnTypeEnum.RIGHT_ALIGNED ? 'w-auto' : 'w-full'
                    )}
                >
                    <div className={classNames('truncate', titleClassName)}>{displayName}</div>
                    {description && (
                        <div
                            className={classNames(
                                'truncate text-xs text-gray-500',
                                descriptionClassName
                            )}
                        >
                            {description}
                        </div>
                    )}
                </div>
                {enableSorting ? (
                    <div className="flex">
                        <div>
                            <span className={classNames('ag-icon px-2', getCurrentOrderIcon())} />
                        </div>
                    </div>
                ) : null}
            </div>
            {enableMenu && (
                <span
                    ref={refButton}
                    className="ag-header-icon ag-header-cell-menu-button cursor-pointer"
                >
                    <div
                        className="ag-icon ag-icon-menu"
                        onClick={handleMenuClick}
                        role="presentation"
                    />
                </span>
            )}
        </div>
    )
}
