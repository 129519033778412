import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import head from 'lodash/fp/head'
import find from 'lodash/fp/find'
import sortBy from 'lodash/fp/sortBy'
import { useAuth0 } from '@auth0/auth0-react'
import { ICompany, IUser } from '../types/legal-entity'
import { getCurrentUser } from '../../src/utils/getCurrentUser'
import Loading from '../pages/Loading'
import getCompanyId from '../utils/getCompanyId'
import useFetch from '../hooks/useFetch'
import getURL from '../utils/getURL'
import { URLs } from '../constants'


export interface IIdentityUser {
    id: string
    email: string
    name: string
}

interface ICurrentUser {
    companies: ICompany[]
    company: ICompany
    currentUser: IUser
    fetchAllUsers: () => void
    findCompany: (companyId: string) => ICompany | undefined
    users: IIdentityUser[]
}

const findCompanyById = (companies: ICompany[]) => (companyId: string) =>
    find((cb: ICompany) => cb.id === companyId, companies)

export const UserContext = React.createContext<ICurrentUser>({
    companies: [],
    company: {} as ICompany,
    currentUser: {} as IUser,
    fetchAllUsers: () => null,
    findCompany: findCompanyById([]),
    users: [],
})

export default function UserProvider({ children }: any) {
    const { pathname } = useLocation()
    const companyId = getCompanyId()
    const { data: companies, loading } = useFetch({
        baseUrlPrefix: false,
        defaultRes: [],
        isNoCompanyScope: true,
        url: process.env.REACT_APP_IDENTITY_COMPANY_URL as string,
    })

    const { user: userProfile } = useAuth0()

    const { data: users, refetch } = useFetch({
        autoFetch: false,
        baseUrlPrefix: false,
        defaultRes: [],
        url: getURL(URLs.Identity.GET_USERS_BY_COMPANY, { companyId }),
    })

    if (!userProfile || isEmpty(companies)) {
        return <Loading />
    }

    const sortedCompanies = sortBy(c => c.name, companies)

    const findCompany = findCompanyById(companies)
    const company = !isEmpty(companyId) ? findCompany(companyId) : head(sortedCompanies)
    const currentUser = getCurrentUser(userProfile)

    if ((!loading || companies?.length > 0) && isEmpty(company) && pathname !== '/no-company') {
        return <Redirect to="/no-company" />
    }

    return (
        <UserContext.Provider
            value={{
                companies: sortedCompanies,
                company,
                currentUser,
                fetchAllUsers: refetch,
                findCompany,
                users,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}
