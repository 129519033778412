import memoize from 'lodash/fp/memoize'
import map from 'lodash/fp/map'
import { URLs } from '../constants'
import { ITechnologySystem } from '../types/types'
import { insertSpaces } from '../utils'
import useFetch from './useFetch'


const parse = memoize(
    map((value: string) => ({
        label: insertSpaces(value),
        value,
    }))
)

export default function useTechnologySystems({ autoFetch = true }: any) {
    const { data, refetch, loading } = useFetch({
        autoFetch: autoFetch,
        cache: true,
        errMessage: 'Failed to fetch technology systems',
        url: URLs.Configurations.TECHNOLOGY_SYSTEM,
    })

    return {
        loading,
        refetch,
        technologySystems: parse(data) as ITechnologySystem[],
    }
}
