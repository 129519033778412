import React from 'react'
import { NoNotes, CommentaryRow, AddCommentary } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { INote, NOTE_TYPE, NodeType } from '../../types/types'
import { useCommentaryForm } from '../../hooks/useCommentaryForm'
import { FormLoader } from './FormLoader'
import { commentaryPlaceholders, noData } from './notesTitles'


interface INotesFormProps {
    companyId: string
    productId: string
    refetchParent?: () => void
    stage: NodeType
    noteType: Exclude<NOTE_TYPE, NOTE_TYPE.NOTE>
}

export function HotspotsAndInsightsForm({
    companyId,
    productId,
    refetchParent,
    stage,
    noteType,
}: INotesFormProps) {
    const {
        checkOwnNote,
        handleNoteCreate,
        handleNoteDelete,
        handleNoteEdit,
        creating,
        isPrevCreating,
        updating,
        isPrevUpdating,
        loading,
        notes,
    } = useCommentaryForm({
        companyId,
        noteType,
        productId,
        refetchParent,
        stage,
    })

    if (loading && notes === null) {
        return <FormLoader />
    }

    return (
        <form id="notes-form" className="relative h-full flex flex-col justify-between">
            <div className="px-4 py-5 flex flex-col gap-y-5 overflow-x-hidden overflow-y-auto h-full">
                {isEmpty(notes) ? (
                    <NoNotes title={noData.title[noteType]} description={noData.descr[noteType]} />
                ) : (
                    notes?.map((note: INote) => (
                        <CommentaryRow
                            key={note.id}
                            note={note}
                            isOwnNote={checkOwnNote}
                            onDelete={handleNoteDelete}
                            onEdit={handleNoteEdit}
                            loadingText={
                                updating || (isPrevUpdating && loading) ? 'Updating...' : ''
                            }
                        />
                    ))
                )}
            </div>
            <div className="bg-gray-50 border-t border-gray-300 p-6">
                <AddCommentary
                    onSubmit={handleNoteCreate}
                    title={commentaryPlaceholders[noteType] as any}
                    loadingText={creating || (isPrevCreating && loading) ? 'Adding...' : ''}
                />
            </div>
        </form>
    )
}
