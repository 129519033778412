import React from 'react'
import { ColDef, ValueFormatterParams } from 'ag-grid-community'

import { ISite } from '../../types/types'
import { buildAddress } from '../../utils'
import SitesActions from './SitesActions'


interface ISiteValueFormatterProps extends ValueFormatterParams {
    data: ISite
}

export const siteColumns = [
    {
        cellRenderer: ({ data, context }: any) => {
            const { onEditSite } = context
            return (
                <div
                    role="button"
                    className="mdp-link font-medium underline truncate"
                    onClick={() => onEditSite(data.id)}
                >
                    {data.name}
                </div>
            )
        },
        field: 'name',
        headerName: 'Site Name',
        sortable: true,
    },
    {
        field: 'siteId',
        headerName: 'Site ID',
        sortable: true,
    },
    {
        cellClass: 'truncate',
        field: 'address.streetAddress',
        headerClass: 'flex gap-x-2',
        headerName: 'Street Address',
        //TODO: Once the Custom header with Sorting implemented, will uncomment below code
        // headerComponent: () => (
        //     <div className="flex items-center gap-x-2">
        //         <Icon size={18} type="map" />
        //         <div>Street Address</div>
        //     </div>
        // ),
        maxWidth: 320,
        sortable: true,
        valueFormatter: ({ data }: ISiteValueFormatterProps) => {
            return buildAddress(data.address, false)
        },
    },
    {
        field: 'address.country',
        headerClass: 'flex gap-x-2',
        headerName: 'Country/Region',
        //TODO: Once the Custom header with Sorting implemented, will uncomment below code
        // headerComponent: () => (
        //     <>
        //         <Icon size={18} type="mapBoundary" />
        //         <span>Country/Region</span>
        //     </>
        // ),
        sortable: true,
    },
    {
        field: 'linkedCount',
        headerName: 'Status',
        sortable: true,
        valueFormatter: ({ data, value }: ISiteValueFormatterProps) =>
            data.isArchived ? 'Archived' : `Used ${value} time(s)`,
    },
    {
        cellClass: 'ag-center-cell !p-0',
        cellRenderer: ({ data, context }: ISiteValueFormatterProps) => (
            <SitesActions component={data} refresh={context.refetch} />
        ),
        colId: 'actions',
        field: '',
        headerName: '',
        maxWidth: 40,
    },
] as Array<ColDef>
