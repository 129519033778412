import React from 'react'
import classNames from 'classnames'


interface IRowProps extends React.ComponentPropsWithoutRef<'div'> {
    showBottomBorder?: boolean
}
export default function Row({ className = 'grid-cols-1', showBottomBorder, children }: IRowProps) {
    return (
        <div
            className={classNames(
                'px-4 md:px-6 py-4 grid items-center gap-x-4',
                showBottomBorder && 'border-b border-gray-200',
                className
            )}
        >
            {children}
        </div>
    )
}
