import React, { useEffect, useImperativeHandle, useState } from 'react'

import isEmpty from 'lodash/fp/isEmpty'
import { Input, ErrorLabel } from '@mondra/ui-components'

import { IClassNameProps, ICustomEventProps } from '../types/types'
import useFetch from '../hooks/useFetch'
import { DEFAULT_UID, METHOD_POST, URLs } from '../constants'
import getURL from '../utils/getURL'


const SKU_VALID = 'valid'
const SKU_INVALID = 'invalid'

interface ISkuIdInputProps extends IClassNameProps, React.ComponentPropsWithoutRef<'input'> {
    companyId: string
    onChange: (event: ICustomEventProps) => void
    productId?: string
    sku: string | undefined
}

export interface ISkuInputRef {
    isValidSku: () => boolean
    sku: string
    validateSku: () => void
}

const SkuIdInput = React.forwardRef<ISkuInputRef, ISkuIdInputProps>(
    (
        {
            className,
            companyId,
            onChange,
            productId = DEFAULT_UID,
            sku = '',
            disabled,
            ...rest
        }: ISkuIdInputProps,
        ref
    ) => {
        const [skuError, setSkuError] = useState('')

        const { data, error, loading, refetch } = useFetch({
            autoFetch: false,
            defaultRes: '',
            method: METHOD_POST,
            url: getURL(URLs.Product.VALIDATE_SKU, { companyId, productId, sku }),
        })

        useImperativeHandle(
            ref,
            () => ({
                isValidSku: () => (loading ? false : skuError !== SKU_INVALID),
                sku,
                validateSku: handleCheck,
            }),
            [loading, skuError, sku, productId]
        )

        useEffect(() => {
            if (data === false || !isEmpty(error)) {
                setSkuError(SKU_INVALID)
            } else if (data === true) {
                setSkuError(SKU_VALID)
            }
        }, [data, error])

        function handleCheck() {
            if (isEmpty(sku)) {
                setSkuError('')
            } else {
                refetch()
            }
        }

        function handleChange(event: ICustomEventProps) {
            const { value: str, name } = event.target
            const value = str.replace(/[^a-z0-9-]+/gi, '')
            onChange({
                target: {
                    name,
                    value,
                },
            })
        }

        return (
            <div className={className}>
                <Input
                    invalid={
                        disabled
                            ? isEmpty(skuError) || (!isEmpty(skuError) && skuError === SKU_VALID)
                            : undefined
                    }
                    value={sku || ''}
                    onChange={handleChange}
                    className="w-full"
                    onBlur={handleCheck}
                    iconType={
                        !loading && sku && skuError === SKU_VALID
                            ? 'checkmarkFilled'
                            : loading
                                ? 'butterfly'
                                : ''
                    }
                    iconClassName="text-primary-500"
                    disabled={disabled}
                    {...rest}
                />
                {skuError === SKU_INVALID && (
                    <ErrorLabel className="mt-2">
                        Try different one, SKU ID already exists in the system
                    </ErrorLabel>
                )}
            </div>
        )
    }
)

export default SkuIdInput
