import React from 'react'
import {
    Button,
    ISlideoverFooterProps as IFooterProps,
    SlideoverFooter as Footer,
} from '@mondra/ui-components'
import { IChildrenProps, IClassNameProps } from '../../types/types'


export interface ISlideoverFooterProps
    extends Omit<IFooterProps, 'children'>,
        IChildrenProps,
        IClassNameProps {
    disableSave?: boolean
    hideSave?: boolean
    formId: string
    onClose: () => void
    onSaveButtonClick?: () => void
    saveButtonText?: string
    saving?: boolean
}

export default function SlideoverFooter({
    children,
    className = 'flex flex-col w-full',
    disableSave,
    hideSave = false,
    formId,
    onClose,
    onSaveButtonClick,
    saveButtonText,
    saving,
    ...rest
}: ISlideoverFooterProps) {
    return (
        <Footer {...rest}>
            <div className={className}>
                {children}
                <div className="flex items-center justify-end space-x-4">
                    <Button
                        className="!py-2.5 !text-base !max-h-10"
                        variant="ghost"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    {!hideSave && (
                        <Button
                            type="submit"
                            form={formId}
                            variant="primary"
                            disabled={saving || disableSave}
                            onClick={onSaveButtonClick}
                        >
                            {saveButtonText
                                ? saveButtonText
                                : saving
                                    ? 'Saving...'
                                    : 'Save & Close'}
                        </Button>
                    )}
                </div>
            </div>
        </Footer>
    )
}
