import React from 'react'
import { PER_100G, PER_KG, PER_PIECE, PER_PRODUCT, PER_SERVING } from '../constants'


export enum EcoImpactIds {
    /**
     * Carbon or ghg
     */
    EIID001 = 'EIID001',
    /**
     * Water usage
     */
    EIID002 = 'EIID002',
    /**
     * Eutrophication or water pollution
     */
    EIID003 = 'EIID003',
    /**
     * Bio
     */
    EIID004 = 'EIID004',
}

export type IEcoImpactIdType = keyof typeof EcoImpactIds

export type IEcoImpactMeasures = {
    [key in keyof typeof EcoImpactIds]: IEcoImpactMeasure
}

export interface IEcoImpactItem {
    id: string
    label: string
    value: number
}

export interface IEcoImpactMeasure {
    /**
     * Defines the label of the measure to show in table or tooltip
     */
    label: string
    /**
     * Defines the measure used for each eco impact
     */
    measure: React.ReactNode
    /**
     * Defines the qunatity unit for each impact per unit
     */
    unit?: string
    /**
     * Defines the qunatity unit for each impact per piece
     */
    pieceUnit?: string
}

export type ImpactStage =
    | 'Ingredients'
    | 'Packaging'
    | 'Processing'
    | 'Storage'
    | 'Retail'
    | 'Transport'

export type Trends = 'Increased' | 'Decreased' | 'Unchanged'
export type ImpactMeasureType =
    | typeof PER_100G
    | typeof PER_KG
    | typeof PER_PIECE
    | typeof PER_PRODUCT
    | typeof PER_SERVING

export interface IProductSizeOption {
    label: string
    value: ImpactMeasureType
}

export interface IEcoImpactMeasureTypes {
    per100g?: number
    perKg?: number
    perPiece?: number
    perProduct?: number
    perServing?: number
}

export interface IEcoImpact extends IEcoImpactMeasureTypes {
    code: EcoImpactIds
    contributionPercentage?: number
    impactScore: number
    measureUnit: string
    name: string
    perStagePercentage?: number
    scientificTerm: string
    stage?: ImpactStage
}
