import React from 'react'
import { Icon } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import lowerCase from 'lodash/fp/lowerCase'
import classNames from 'classnames'
import { ISite } from '../../types/types'


interface ISiteProps {
    site?: ISite
    rdcSite?: string
    isTransporting: boolean
    type: string
}

export default function Site({ isTransporting, rdcSite, site, type }: ISiteProps) {
    const { address } = site || {}
    let textClass = ''
    if (!isTransporting) {
        textClass = 'text-gray-300'
    } else if (isTransporting && (!isEmpty(address) || !isEmpty(rdcSite))) {
        textClass = 'text-gray-500'
    } else if (isTransporting && isEmpty(address) && isEmpty(rdcSite)) {
        textClass = 'text-amber-600'
    }
    return (
        <div
            className={classNames('col-span-1  p-6 min-h-52 h-52 rounded-md', {
                'bg-amber-50': isTransporting && isEmpty(address) && isEmpty(rdcSite),
                'bg-gray-50': !isTransporting || !isEmpty(address) || !isEmpty(rdcSite),
            })}
        >
            <Icon className={classNames(`mt-2 ${textClass}`)} size={22} type="map" />
            <label
                className={classNames(
                    `mt-2 inline-flex text-base leading-6 font-medium ${textClass}`,
                    {
                        'text-amber-900': isTransporting && isEmpty(address) && isEmpty(rdcSite),
                    }
                )}
            >
                {type}
            </label>
            {!isEmpty(address) && (
                <div className="mt-2">
                    <div
                        className={classNames(
                            'mt-1  flex flex-col justify-start items-start text-sm leading-5 font-normal',
                            {
                                'text-gray-300': !isTransporting,
                                'text-gray-500': isTransporting,
                            }
                        )}
                    >
                        <label>{address?.streetAddress}</label>
                        <label>{address?.city}</label>
                        <label>{address?.state}</label>
                        <label>{address?.country}</label>
                        <label>{address?.pinCode}</label>
                    </div>
                </div>
            )}
            {!isEmpty(rdcSite) && (
                <div className="mt-2">
                    <div
                        className={classNames(
                            'mt-1  flex flex-col justify-start items-start text-sm leading-5 font-normal text-gray-500'
                        )}
                    >
                        <label>{rdcSite}</label>
                    </div>
                </div>
            )}
            {isEmpty(address) && isEmpty(rdcSite) && (
                <div className="mt-2 flex justify-start items-center">
                    <Icon className={textClass} size={14} type="warningAltFilled" />
                    <label
                        className={classNames(
                            `ml-1 mt-1 text-sm leading-5 font-normal ${textClass}`
                        )}
                    >
                        {`There is no ${lowerCase(type)} transport data set`}
                    </label>
                </div>
            )}
        </div>
    )
}
