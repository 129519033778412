import React, { useContext, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Avatar, MenuPopover, AlertDialog, List } from '@mondra/ui-components'
import { UserContext } from '../contexts/UserProvider'
import useAuth from '../hooks/useAuth'
import { useCompanyId } from '../hooks/useCompanyId'
import { resolveRouteURL } from '../utils/getURL'
import urls from '../constants/urls'
import { useControls } from '../hooks/useControls'


const versionList = [
    {
        id: 'app',
        label: 'App Name',
        value: 'Mondra Core Platform',
    },
    {
        id: 'ui',
        label: 'UI',
        value: process.env.REACT_APP_UI_VERSION,
    },
    {
        id: 'api',
        label: 'API',
        value: process.env.REACT_APP_API_VERSION,
    },
]

export default function UserAvatar() {
    const { logout } = useAuth()
    const { isOpened, close, open } = useControls()
    const { currentUser } = useContext(UserContext)
    const companyId = useCompanyId()
    const location = useLocation()

    const options = useMemo(() => {
        return [
            { id: 'about', onClick: open, value: 'About' },
            {
                as: NavLink,
                className: location.pathname.includes('/settings')
                    ? '!bg-gray-200 !text-gray-900'
                    : '',
                id: 'settings',
                to: resolveRouteURL(urls.PagePaths.SETTINGS, { companyId }),
                value: 'Settings',
            },
            {
                id: 'logout',
                onClick: () => logout(),
                value: 'Logout',
            },
        ]
    }, [logout, companyId, location.pathname])

    return (
        <>
            <MenuPopover
                triggerButtonClass="h-14 w-14"
                showBorder
                menuItems={options}
                trigger={
                    <div className="flex items-center space-x-1">
                        <Avatar
                            name={`${currentUser?.givenName} ${currentUser?.surname}`}
                            size="xs"
                            bgColor="bg-cyan-600"
                            singleChar
                            textColor="text-white"
                        />
                    </div>
                }
            />
            <AlertDialog open={isOpened} onClose={close}>
                <h6 className="font-medium text-gray-900">About this application</h6>
                <List className="my-4 rounded border border-gray-300" items={versionList} />
            </AlertDialog>
        </>
    )
}
