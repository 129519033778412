import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { IDataQualityScoreAttributes } from '../../types/contribution-tree-types'
import { DataQualityScore } from './DataQualityScore'


interface IDataQualityAttributesProps {
    attributes: IDataQualityScoreAttributes[]
    header?: React.ReactNode
}

export function DataQualityAttributes({ attributes, header }: IDataQualityAttributesProps) {
    return (
        <div className="w-full">
            {header}
            <div className="flex w-full flex-col text-xs divide-y divide-gray-200">
                {!isEmpty(attributes)
                    && attributes.map(a => (
                        <div key={a.name} className="flex h-9 items-center justify-between">
                            <div className="truncate text-gray-900">{a.name}</div>
                            <DataQualityScore value={a.score} small />
                        </div>
                    ))}
            </div>
        </div>
    )
}
