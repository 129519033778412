import { matchPath, useLocation } from 'react-router-dom'
import getOr from 'lodash/fp/getOr'
import { URLs } from '../constants'


export default function getCompanyId() {
    const { pathname } = useLocation()

    const match = matchPath(pathname, {
        path: [
            '/company/:companyId',
            '/settings/:companyId',
            '/ingredients/:companyId',
            '/processing/:companyId',
            '/packaging/:companyId',
            '/sale/:companyId',
            '/suppliers/:companyId',
            URLs.PagePaths.CATEGORIES,
            '/products/:companyId',
            '/products/:companyId/:id',
            '/product-view/:companyId/:supplierId/:id',
            '/product/:companyId/:id',
            '/supply-chain/:companyId/:id',
            '/component-editor/:type/:companyId/:id',
            '/supplied-products/:companyId',
            '/sites/:companyId',
            '/invite-supplier/:linkId/:companyId?',
        ],
    })

    const matchParams: any = getOr({}, 'params', match)
    const { companyId } = matchParams

    return companyId || undefined
}
