import React from 'react'
import { Row } from '../../components'
import { IActivityRefDataOption } from '../../types/stages'
import InfoTooltip from './InfoTooltip'


export interface ActivityInfoTooltipProps {
    activity?: IActivityRefDataOption
    disabled?: boolean
}

export default function ActivityInfoTooltip({
    activity = {} as IActivityRefDataOption,
    disabled = false,
}: ActivityInfoTooltipProps) {
    return (
        <InfoTooltip disabled={disabled} className="w-72">
            <form className="divide-y divide-gray-200 text-gray-700">
                <div className="rounded-t-lg p-4 bg-white">
                    <div className="ml-2 rounded-t-lg text-base leading-6 font-semibold">
                        {activity?.activityName}
                    </div>
                </div>
                {activity?.country && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Country</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {activity?.country}
                        </div>
                    </Row>
                )}
                {activity?.geographicalLocation && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Geographical Location</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {activity?.geographicalLocation}
                        </div>
                    </Row>
                )}
                {activity?.foodCategoryAppliesTo && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Food Category</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {activity?.foodCategoryAppliesTo}
                        </div>
                    </Row>
                )}
                {activity?.referenceDescription && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Description</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {activity?.referenceDescription}
                        </div>
                    </Row>
                )}
                {activity?.researchComments && (
                    <Row>
                        <div className="text-sm leading-5 font-semibold">Comments</div>
                        <div className="mt-2 text-sm leading-5 font-normal">
                            {activity?.researchComments}
                        </div>
                    </Row>
                )}
                <Row>
                    <div className="text-sm leading-5 font-semibold">Resources consumed:</div>
                    {activity?.resources?.map(r => (
                        <div key={r.resourceCode} className="mt-2 text-sm leading-5 font-normal">
                            {r.resourceName}
                        </div>
                    ))}
                </Row>
            </form>
        </InfoTooltip>
    )
}
