import isEmpty from 'lodash/fp/isEmpty'
import flatten from 'lodash/fp/flatten'
import flow from 'lodash/fp/flow'
import join from 'lodash/fp/join'
import values from 'lodash/fp/values'
import { HTTP_404_RESPONSE } from '../constants'


export interface IErrorResponse {
    detail: string
    errors?: Record<string, string[]>
    message: string
    status: number
    traceId: string
    title: string
}

export default class HttpException extends Error {
    status: number
    detail: string
    errors: Record<string, string[]> | undefined
    title: string
    traceId: string

    constructor(errResponse: IErrorResponse, ...params: any) {
        super(...params)
        this.name = 'HttpExcpetion'
        this.status = errResponse.status
        this.detail = errResponse.detail
        this.errors = errResponse.errors
        this.title = errResponse.title
        this.traceId = errResponse.traceId
    }
}

export async function processError(rsp: Response, errMessage: string | undefined) {
    const errorResponse
        = rsp.status === 404
            ? HTTP_404_RESPONSE
            : await rsp.json().then(r => {
                return r
            })

    return { ...errorResponse, title: errMessage || errorResponse.title }
}

export function notifyErrorResponse(showError: any, error: IErrorResponse) {
    showError
        && showError({
            description: !isEmpty(error.errors)
                ? `Validation errors: ${flow([values, flatten, join(',')])(error.errors)}`
                : error.detail,
            label: error.title
                ? `${error.status} - ${error.title}: ${error.message}`
                : `${error.status}: ${error.message}`,
        })
}
