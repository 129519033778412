import React, { useContext } from 'react'
import { Menu, MoreMenuButton, MenuList } from '../../components'
import getURL from '../../utils/getURL'
import { isMondraAdmin } from '../../hooks/userRoles'
import { URLs } from '../../constants'
import { UserContext } from '../../contexts/UserProvider'
import { UploadProductTree } from './UploadProductTree'
import { ExportISData } from './ExportISData'


interface IProductDashboardActionsProps {
    refetch?: () => void
}

export function ProductDashboardActions({ refetch }: IProductDashboardActionsProps) {
    const { currentUser, company } = useContext(UserContext)

    return (
        <div>
            <Menu>
                <MoreMenuButton className="min-h-[48px] !min-w-[48px]" />
                <MenuList style={{ width: 'max-content' }} ariaLabelledby="Product action menu">
                    {isMondraAdmin(currentUser) && (
                        <>
                            <UploadProductTree
                                url={getURL(URLs.Supplier.SIDELOAD_PRODUCT_TREE, {
                                    companyId: company?.id,
                                })}
                                refresh={refetch}
                            />
                            <ExportISData company={company} />
                        </>
                    )}
                </MenuList>
            </Menu>
        </div>
    )
}
