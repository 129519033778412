import React, { Fragment, useEffect, useMemo } from 'react'
import { Button, Input } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import { Dropdown, ImpactScoreCell, Row } from '../../components'
import { CertificateStateEnum } from '../../types/types'
import { IPackagingSet, IPackagingLeg, IPackagingOption } from '../../types/stages'
import useFetch from '../../hooks/useFetch'
import { MAX_DEC, METHOD_GET, URLs, PER_PIECE } from '../../constants'
import { buildImpact } from '../../utils/impactUtils'
import { evaluateInputValue } from '../../utils'
import getURL from '../../utils/getURL'
import { getImpacts, getPackageLabel } from './packagingUtils'
import PackagingInfoTooltip from './PackagingInfoTooltip'
import DistributionLegs from './DistributionLegs'
import DistributorPackagingOptions from './DistributorPakagingOptions'
import { IDistributorError, IError, IPackagingSetData, IPackagingFormData } from './PackagingForm'
import CapacityErrorState from './CapacityErrorState'
import { PackagingSetList } from './PackagingSetList'


const TYPE_PRIMARY = 'Primary'
const TYPE_DISTRIBUTOR = 'Distribution'
const CAPACITY_UNIT = 'kg'

interface IPackagingSetProps {
    companyId: string
    disabled?: boolean
    error: IError
    formData: IPackagingSetData
    packaging: IPackagingSet
    packagingLoading: boolean
    productNetWeight: number
    setError: any
    setPackagingFormData: React.Dispatch<React.SetStateAction<IPackagingFormData>>
}

export default function PackagingSet({
    companyId,
    disabled,
    error,
    formData,
    packaging,
    packagingLoading,
    productNetWeight,
    setError,
    setPackagingFormData,
}: IPackagingSetProps) {
    const { loading, data: packagingOptions } = useFetch<IPackagingOption[]>({
        autoFetch: !packagingLoading,
        defaultRes: [],
        method: METHOD_GET,
        url: getURL(URLs.Packaging.PACKAGING_TYPES, { companyId }),
    })

    const packages: IPackagingOption[] = useMemo(
        () =>
            flow(
                map((m: IPackagingOption) => ({ ...m, label: getPackageLabel(m) })),
                sortBy(i => i.label)
            )(packagingOptions),
        [packagingOptions]
    )

    useEffect(() => {
        if (!loading && !isEmpty(packaging) && !isEmpty(packages)) {
            const primaryLeg = find(
                (l: IPackagingLeg) => l.legType === TYPE_PRIMARY,
                packaging.packagingLegs
            )
            const dLegs = filter(
                (l: IPackagingLeg) => l.legType === TYPE_DISTRIBUTOR,
                packaging.packagingLegs
            )

            if (primaryLeg) {
                primaryLeg.selected = find(
                    (op: IPackagingOption) => op.code === primaryLeg?.packagingRefCode,
                    packages
                )
                setPackagingFormData((data: IPackagingFormData) => ({
                    ...data,
                    set: {
                        ...data.set,
                        primaryLeg,
                    },
                }))
            }

            if (dLegs.length > 0) {
                setPackagingFormData((data: IPackagingFormData) => ({
                    ...data,
                    set: {
                        ...data.set,
                        distributorLegs: map(
                            (l: IPackagingLeg) => ({
                                ...l,
                                selected: find(
                                    (op: IPackagingOption) => op.code === l?.packagingRefCode,
                                    packages
                                ),
                            }),
                            dLegs
                        ),
                    },
                }))
            }
        }
    }, [loading, packaging, packages])

    const handleSelectPrimaryLeg = (selected?: IPackagingOption) => {
        setPackagingFormData((data: IPackagingFormData) => ({
            ...data,
            set: {
                ...data.set,
                primaryLeg: {
                    ...data.set.primaryLeg,
                    fillCapacity: selected?.capacity,
                    fillCapacityUnit: CAPACITY_UNIT,
                    isZeroImpact: false,
                    itemsNumber: 0,
                    legType: TYPE_PRIMARY,
                    packagingRefCode: selected?.code,
                    selected,
                },
            },
        }))
        setError((e: any) => ({ ...e, primary: false }))
    }

    const handleFillCapacityChange = (event: any) => {
        setPackagingFormData((data: IPackagingFormData) => ({
            ...data,
            set: {
                ...data.set,
                primaryLeg: {
                    ...data.set.primaryLeg,
                    fillCapacity: evaluateInputValue(event, MAX_DEC),
                    fillCapacityUnit: data.set.primaryLeg.fillCapacityUnit || CAPACITY_UNIT,
                },
            },
        }))
    }

    const handleAddDistributionLeg = () => {
        if (formData.distributorLegs.length < 10) {
            setPackagingFormData((data: IPackagingFormData) => ({
                ...data,
                set: {
                    ...data.set,
                    distributorLegs: [
                        ...data.set.distributorLegs,
                        {
                            isZeroImpact: false,
                            itemsNumber: 0,
                            legType: TYPE_DISTRIBUTOR,
                        },
                    ],
                },
            }))
        }
    }

    const handleDistPackagingChange = (distributorLegs: IPackagingLeg[]) => {
        setPackagingFormData((data: IPackagingFormData) => ({
            ...data,
            set: {
                ...data.set,
                distributorLegs,
            },
        }))
    }

    const handleDistributionOptionsChange = ({ distributions, primary }: any) => {
        setPackagingFormData((data: IPackagingFormData) => ({
            ...data,
            set: {
                ...data.set,
                distributorLegs: distributions,
                primaryLeg: primary,
            },
        }))
    }

    const handleSetDistError = (errors: IDistributorError) => {
        setError((e: IError) => ({
            ...e,
            distributor: errors,
        }))
    }

    return (
        <Fragment>
            <Row className="bg-gray-50">
                <div className="text-lg font-medium text-gray-900">Build packaging set</div>
                <div className="text-sm text-gray-500 font-normal">
                    Tell us how your product is packaged, as well as what packaging you add for
                    distribution.
                </div>
            </Row>

            <Row>
                <div className="grid grid-cols-12 gap-x-4">
                    <div className="col-span-7 text-sm font-medium text-gray-700 mb-2">
                        Primary Packaging:
                    </div>
                    <div className="col-span-5 text-sm font-medium text-gray-700 mb-2">
                        Fill Capacity:
                    </div>
                </div>

                <div className="grid grid-cols-12 items-center gap-x-4">
                    <div className="col-span-7 flex flex-row items-center gap-4">
                        <div className="w-10 h-10 bg-gray-300 flex-shrink-0">
                            {false && <img src="#" alt="package" className="w-10 h-10" />}
                        </div>
                        <Dropdown
                            containerClass="flex-grow"
                            selected={formData.primaryLeg?.selected}
                            disabled={disabled}
                            groupKey="type"
                            valid={error?.primary ? false : undefined}
                            options={packages}
                            placeholder={loading ? 'Loading...' : 'Select primary packaging'}
                            onChange={handleSelectPrimaryLeg}
                            renderList={(props: any) => <PackagingSetList {...props} />}
                        />
                    </div>
                    <div className="col-span-5 flex flex-row items-center justify-between gap-2">
                        <Input
                            className="w-10/12 text-right"
                            addonTitle={formData.primaryLeg?.fillCapacityUnit || CAPACITY_UNIT}
                            type="number"
                            disabled={disabled}
                            value={formData.primaryLeg?.fillCapacity}
                            onChange={handleFillCapacityChange}
                        />
                        <CapacityErrorState
                            disabled={disabled}
                            fillCapacityUnit={
                                formData.primaryLeg?.fillCapacityUnit || CAPACITY_UNIT
                            }
                            fillCapacity={formData.primaryLeg?.fillCapacity}
                            maxCapacity={
                                formData.primaryLeg.selected?.maxCapacity
                                || formData.primaryLeg.selected?.capacity
                            }
                            productNetWeight={productNetWeight}
                        />
                        <div className="flex flex-row items-center mr-7">
                            <ImpactScoreCell
                                hideGrade
                                disabled={disabled || isEmpty(formData.primaryLeg?.selected)}
                                iconColor={'text-amber-500'}
                                measureType={PER_PIECE}
                                state={CertificateStateEnum.Draft}
                                score={''}
                                ecoImpacts={buildImpact(getImpacts(formData.primaryLeg.selected))}
                            />
                            <PackagingInfoTooltip
                                packageInfo={formData.primaryLeg.selected}
                                disabled={disabled || isEmpty(formData.primaryLeg?.selected)}
                            />
                        </div>
                    </div>
                </div>
            </Row>

            <DistributionLegs
                disabled={disabled}
                loading={loading}
                options={packages}
                legs={formData.distributorLegs}
                primaryLeg={formData.primaryLeg.selected}
                errors={error.distributor}
                setError={handleSetDistError}
                setDistributionPackaging={handleDistPackagingChange}
            />

            <Row>
                <div>
                    <div className="flex flex-row justify-between">
                        <DistributorPackagingOptions
                            disabled={disabled}
                            primaryPackaging={formData.primaryLeg}
                            distributionPackaging={formData.distributorLegs}
                            onOptionsChange={handleDistributionOptionsChange}
                        />
                        <Button
                            variant="secondary"
                            iconType="add"
                            disabled={disabled || formData.distributorLegs.length >= 10}
                            onClick={handleAddDistributionLeg}
                        >
                            Add Distribution packaging
                        </Button>
                    </div>
                </div>
            </Row>
        </Fragment>
    )
}
