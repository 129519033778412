/* eslint-disable */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import getCompanyId from '../utils/getCompanyId'
import { SELECTED_COMPANY } from '../constants'

interface IAuth {
    logout(): void
    acquireToken(isNoCompanyScope?: boolean): Promise<string>
    login(): void
    setCompanyId: Dispatch<SetStateAction<string>>
}

interface IAuthProvider {
    children: any
}

export const AuthContext = React.createContext<IAuth>({
    acquireToken: () => Promise.resolve(''),
    login: () => {},
    logout: () => {},
    setCompanyId: () => {},
})

const SCOPE = 'user profile email'

export default function AuthProvider({ children }: IAuthProvider) {
    const [companyId, setCompanyId] = useState<string>('')
    const id = getCompanyId()
    const { getAccessTokenSilently, logout: logoutWithRedirect, loginWithRedirect } = useAuth0()

    useEffect(() => {
        if (companyId) {
            localStorage.setItem(SELECTED_COMPANY, companyId)
        }
    }, [companyId])

    function acquireToken(isNoCompanyScope?: boolean) {
        return getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: isNoCompanyScope ? SCOPE : `user profile email c_${companyId || id} `,
        })
            .then((token: any) => {
                if (token) {
                    return token
                } else {
                    throw { error: 'consent_required' }
                }
            })
            .catch((error: any) => {
                console.log(error)
            })
    }
    const logout = () => {
        logoutWithRedirect({ returnTo: window.location.origin })
    }

    return (
        <AuthContext.Provider
            value={{
                acquireToken,
                login: loginWithRedirect,
                logout,
                setCompanyId,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
