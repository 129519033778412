import React, { useContext, useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Icon, MenuPopover } from '@mondra/ui-components'
import { UserContext } from '../contexts/UserProvider'
import getURL, { resolveRouteURL } from '../utils/getURL'
import useAuth from '../hooks/useAuth'
import urls from '../constants/urls'


const PAGE_URLS: Record<string, string> = {
    categories: urls.PagePaths.CATEGORIES,
    company: urls.PagePaths.HOME,
    ingredients: urls.PagePaths.INGREDIENTS,
    products: urls.PagePaths.PRODUCTS,
    sites: urls.PagePaths.SITES,
    'supplied-products': urls.Pages.SUPPLIED_PRODUCTS,
    suppliers: urls.PagePaths.SUPPLIERS,
}

export default function SwitchCompany() {
    const { company, companies } = useContext(UserContext)
    const location = useLocation()
    const history = useHistory()
    const { setCompanyId } = useAuth()
    const { pathname } = location

    const handleCompanyChange = (companyId: string) => {
        setCompanyId(companyId)
        const basePath = pathname.split('/')[1]
        const path
            = basePath === 'supplied-products'
                ? getURL(PAGE_URLS[basePath], { companyId })
                : resolveRouteURL(PAGE_URLS[basePath], { companyId })
        history.replace(path)
    }

    useEffect(() => {
        if (company) {
            setCompanyId(company.id)
        }
    }, [company])

    const companyOptions = useMemo(() => {
        return companies.map((companyBrand: any) => {
            return {
                id: companyBrand.id,
                onClick: () => handleCompanyChange(companyBrand.id),
                value: companyBrand.name,
            }
        })
    }, [companies])

    return (
        <div className="relative flex-shrink-0">
            <MenuPopover
                placement="bottom-start"
                triggerButtonClass="h-14 min-w-[211px] max-w-[211px]"
                itemsWrapperClass="max-h-[300px] overflow-y-scroll shadow-lg rounded-b-sm"
                showBorder
                menuItems={companyOptions}
                trigger={
                    <div
                        className="w-full flex items-center justify-between -mb-1"
                        title={company.name}
                    >
                        <div className="max-w-[164px] truncate">{company && company.name}</div>
                        <Icon type="chevronDown" size={16} className="text-current" />
                    </div>
                }
            />
        </div>
    )
}
