import React from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'
import { nodeColors } from '../constants/colors'
import { IChildrenProps, NodeState, NodeType } from '../types/types'
import { statusIcons } from '../constants'


interface IStatusIconProps {
    bgColor?: string
    className?: string
    iconBgClass?: string
    iconColor?: string
    iconSize?: number
    iconSizeClass?: string
    roundedClass?: string
    sizeClass?: string
    state?: NodeState
    type: NodeType
}

export default function StatusIcon({
    bgColor,
    className,
    iconBgClass = 'bg-white',
    iconColor,
    iconSize = 24,
    iconSizeClass = 'h-10 w-10',
    roundedClass = 'rounded',
    sizeClass = 'h-16 w-16',
    state = 'blank',
    type,
}: IStatusIconProps) {
    const typeDetails = nodeColors[type]
    const { bg, text } = typeDetails
    return (
        <div
            className={classNames(
                sizeClass,
                roundedClass,
                className,
                'text-base font-medium flex items-center justify-center',
                bgColor || bg,
                iconColor || text
            )}
        >
            <NodeProgress
                type={type}
                state={state}
                sizeClass={iconSizeClass}
                iconColor={iconColor}
                iconSize={iconSize}
                bgClass={iconBgClass}
            />
        </div>
    )
}

interface INodeProgressProps extends IChildrenProps {
    bgClass?: string
    iconColor?: string
    iconSize?: number
    sizeClass?: string
    state: NodeState
    type: NodeType
}

export function NodeProgress({
    bgClass = 'bg-white',
    children,
    iconColor,
    iconSize,
    sizeClass = 'h-10 w-10',
    state,
    type,
}: INodeProgressProps) {
    const iconColorClass = iconColor || nodeColors[type].text
    return (
        <div
            className={classNames(
                'rounded-full flex items-center justify-center',
                sizeClass,
                bgClass
            )}
        >
            <Icon type={statusIcons[state]} size={iconSize} className={iconColorClass} />
            {children}
        </div>
    )
}
