import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'


export default function AnimatedDiv({
    animateProps = {
        animate: {
            x: 0,
        },
        initial: { x: 0 },
    },
    children,
    className,
    ...props
}: any) {
    return (
        <motion.div
            initial={{ opacity: 0, ...animateProps.initial }}
            animate={{ opacity: 1, ...animateProps.animate }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={classNames('motion-safe:transition-all duration-200', className)}
            {...props}
        >
            {children}
        </motion.div>
    )
}
