import { IServerSideGetRowsParams } from 'ag-grid-community'
import isEmpty from 'lodash/fp/isEmpty'
import omit from 'lodash/fp/omit'
import getURL from '../../utils/getURL'
import { getQueryStringByFilter } from '../../utils/queryString'
import { fetchClient, IfetchClient } from '../../utils/fetchClient'


interface IcreateDataSource extends Omit<IfetchClient, 'onError' | 'onSuccess'> {
    filters: any
    pageSize: number
    urlOptions: any
}

export function createDataSource({
    acquireToken,
    baseUrlPrefix,
    body,
    filters,
    method,
    pageSize,
    showError,
    url,
    urlOptions,
}: IcreateDataSource) {
    return {
        getRows: (params: IServerSideGetRowsParams) => {
            const { request, success, api: gridApi } = params
            const { endRow } = request
            const page = !endRow ? 1 : endRow / pageSize
            //TODO: Need to check API how to support multiple column sorts}
            const sortModel = !isEmpty(filters.order)
                ? { direction: filters.order[1], Order: filters.order[0] }
                : {}
            //TODO: Change this order logic inside getQueryStringByFilter by using order instead of Order
            const finalFilters = omit(['order'], filters)
            const finalAPIQsString = getQueryStringByFilter({
                filters: {
                    ...sortModel,
                    ...finalFilters,
                },
                page,
                pageSize,
            })
            gridApi?.showLoadingOverlay()
            const apiUrl = getURL(url, urlOptions, finalAPIQsString)
            return fetchClient({
                acquireToken,
                baseUrlPrefix,
                body,
                method,
                onError: err => {
                    gridApi?.hideOverlay()
                    console.log('error', err)
                },
                onSuccess: (data: any) => {
                    gridApi?.hideOverlay()
                    if (data?.data?.length === 0) {
                        gridApi?.showNoRowsOverlay()
                    }
                    success({ rowCount: data.totalCount, rowData: data.data })
                },
                showError,
                url: apiUrl,
            })
        },
    }
}
