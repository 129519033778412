import contains from 'lodash/fp/contains'
import { ROLES } from '../constants'


export function isMondraAdmin(user: any): any {
    return contains(user?.role, [ROLES.MondraAdmin, ROLES.MondraLCAAssessor, ROLES.MondraLCAViewer])
}

export function isOwner(user: any): any {
    return user?.role === ROLES.Owner
}

export function isUser(user: any): any {
    return user?.role === ROLES.User
}
