import React from 'react'
import classNames from 'classnames'
import { formatNumberWithFractions } from '../../utils'


export interface IMeasureProps {
    bgColor: string
    percentage: number
    title: string
}

export const Measure = ({ bgColor, percentage, title }: IMeasureProps) => {
    const percentageLabel
        = percentage <= 0 || percentage >= 1 ? formatNumberWithFractions(percentage) : '<1'

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="mb-2 pt-2 text-gray-900 text-xs sm:text-sm font-medium capitalize">
                {title}
            </div>
            <div
                className={classNames(
                    'h-20 w-20 rounded-md',
                    'text-xl font-semibold text-white',
                    'flex items-center justify-center',
                    bgColor
                )}
            >
                {percentageLabel}%
            </div>
        </div>
    )
}

export default Measure
