import { IClassNameProps } from '@mondra/ui-components/@types'
import React from 'react'
import { ImpactScoreCell, Menu, MenuButton, MenuList } from '../../../components'
import { flashIconColors } from '../../../constants/colors'
import { IProductNode } from '../../../types/supply-chain-types'
import { IChildrenProps } from '../../../types/types'
import MenuHeader from './MenuHeader'


interface INodeTileMenuProps extends IChildrenProps, IClassNameProps {
    items: React.ReactNode | React.ReactElement
    node: IProductNode
}

export default function NodeTileMenu({ className, node, items, children }: INodeTileMenuProps) {
    const { type, saState, ecoImpactMetascore, ecoImpacts } = node

    const iconBgColor = flashIconColors[type]

    return (
        <Menu>
            <MenuButton
                className={
                    'w-full group outline-none focus:outline-none active:outline-none active:shadow-2xl focus:shadow-2xl'
                }
            >
                {children}
            </MenuButton>
            <MenuList className={className} ariaLabelledby="Supply chain tile options">
                <MenuHeader
                    node={node}
                    right={
                        <ImpactScoreCell
                            hideGrade
                            bgColor={iconBgColor?.bg}
                            bgHoverColor={iconBgColor?.hover}
                            iconColor="text-white"
                            state={saState}
                            score={ecoImpactMetascore || ''}
                            ecoImpacts={ecoImpacts}
                        />
                    }
                />
                {items}
            </MenuList>
        </Menu>
    )
}
