import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import reduce from 'lodash/fp/reduce'
import { mapWithIndex } from '../../utils/lodash-convert'
import { MAX_DEC } from '../../constants'
import { INumber } from '../../types/types'
import { evaluateInputValue } from '../../utils'
import { parseNumber, round } from '../../utils/numberFormatter'
import { IIngredientItem, BatchInputType } from './RecipeForm'
import { IngredientListItem } from './IngredientListItem'


interface IIngredientsListProps {
    addedIngredients: IIngredientItem[]
    batchInputWeight: number
    companyId: string
    setBatchInputWeight: React.Dispatch<React.SetStateAction<number>>
    loading?: boolean
    inPercent: boolean
    onRemove: (index: number) => () => void
    setAddedIngredients: React.Dispatch<React.SetStateAction<IIngredientItem[]>>
}

export default function IngredientsList({
    addedIngredients,
    companyId,
    batchInputWeight,
    setBatchInputWeight,
    loading,
    inPercent,
    onRemove,
    setAddedIngredients,
}: IIngredientsListProps) {
    const [weighChanged, setWeightChanged] = useState<INumber>(0)

    useEffect(() => {
        setAddedIngredients(ingredients => {
            return mapWithIndex((ing: IIngredientItem) => {
                if (!inPercent) {
                    return {
                        ...ing,
                        inputQuantityPercentage: round(
                            (ing.inputQuantity / batchInputWeight) * 100,
                            MAX_DEC
                        ),
                    }
                } else {
                    return {
                        ...ing,
                        inputQuantity: round(
                            batchInputWeight * (ing.inputQuantityPercentage / 100),
                            MAX_DEC
                        ),
                    }
                }
            }, ingredients)
        })
    }, [batchInputWeight, weighChanged])

    const handleWeightChange = (rowIndex: number, type: BatchInputType) => (event: any) => {
        const num = evaluateInputValue(event, MAX_DEC)
        setAddedIngredients(ingredients => {
            const updatedIngredients = mapWithIndex((ing: IIngredientItem, index: number) => {
                if (index === rowIndex) {
                    if (type === BatchInputType.KG) {
                        return {
                            ...ing,
                            inputQuantity: num,
                        }
                    }
                    if (type === BatchInputType.PERCENT) {
                        setWeightChanged(num)
                        return {
                            ...ing,
                            inputQuantityPercentage: num,
                        }
                    }
                }
                return ing
            }, ingredients)

            if (!inPercent) {
                const total = reduce(
                    (acc: number, i: IIngredientItem) => {
                        return acc + parseNumber(i.inputQuantity)
                    },
                    0,
                    updatedIngredients
                )
                setBatchInputWeight(round(total, MAX_DEC))
            }
            return updatedIngredients
        })
    }

    return (
        <React.Fragment>
            {!isEmpty(addedIngredients) && (
                <div className="px-4 md:px-6 py-2 grid grid-cols-12 bg-gray-100 text-xs font-medium text-gray-500">
                    <div className="col-span-1">State</div>
                    <div className="col-span-5">Ingredient</div>
                    <div className="col-span-1"> </div>
                    <div className="col-span-2 text-right pr-4">Weight</div>
                    <div className="col-span-3" />
                </div>
            )}
            {!isEmpty(addedIngredients)
                && mapWithIndex(
                    (ing: IIngredientItem, index: number) => (
                        <IngredientListItem
                            key={`${ing.ingredientId}-${index}`}
                            companyId={companyId}
                            index={index}
                            ingredient={ing}
                            inPercent={inPercent}
                            loading={Boolean(loading)}
                            onRemove={onRemove}
                            onWeightChange={handleWeightChange}
                        />
                    ),
                    addedIngredients
                )}
        </React.Fragment>
    )
}
