import React from 'react'
import { Link } from 'react-router-dom'
import { ColDef } from 'ag-grid-community'

import getURL from '../../utils/getURL'
import { URLs } from '../../constants'
import SuppliersActions from './SuppliersActions'
import SupplierNameCell from './SupplierNameCell'


export const supplierColumns = [
    {
        cellRenderer: SupplierNameCell,
        field: 'name',
        headerName: 'Supplier',
        minWidth: 340,
        sortable: true,
    },
    {
        cellRenderer: ({ data, context, value }: any) => {
            const { companyId } = context
            return value > 0 ? (
                <Link
                    className="mdp-link font-medium"
                    to={getURL(
                        URLs.Pages.SUPPLIED_PRODUCTS,
                        { companyId },
                        `?supplierId=${
                            data.isSharedProductsCompany ? data.companyId : data.supplierId
                        }`
                    )}
                >
                    {value}
                </Link>
            ) : (
                0
            )
        },
        field: 'productsCount',
        headerName: 'Products',
    },
    {
        cellClass: 'ag-center-cell !p-0',
        cellRenderer: SuppliersActions,
        colId: 'actions',
        field: '',
        flex: 0,
        headerName: '',
        maxWidth: 40,
    },
] as Array<ColDef>
