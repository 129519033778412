import React, { Fragment, useCallback, useState } from 'react'
import { Button, Input } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import { filter } from 'lodash/fp'

import { IChildrenProps, SA_TYPE, IString, IIngredientCollectionMetadata } from '../../types/types'
import RemoveSuppliedItemDialog from './RemoveSuppliedItemDialog'


interface IIngredientCollectionItemsProps {
    ingredients: IIngredientCollectionMetadata[]
    onChange: (changes: any) => void
    saType: SA_TYPE
}

export default function IngredientCollectionItems({
    saType,
    ingredients,
    onChange,
}: IIngredientCollectionItemsProps) {
    const [deleteItemId, setDeleteItemId] = useState<IString>()

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { id, value } = event.target
            const mappedIngs = map(item => {
                if (item.productId === id) {
                    return { ...item, inputQuantityPercentage: value }
                }
                return item
            }, ingredients)
            onChange({ ingredientCollectionMetadata: mappedIngs })
        },
        [ingredients]
    )

    const handleShowDeleteDialog = (deleteItemId: string) => () => {
        setDeleteItemId(deleteItemId)
    }

    const handleCloseDeleteDialog = () => {
        setDeleteItemId(undefined)
    }

    const handleConfirmDeleteDialog = () => {
        const filteredIngs = filter(item => item.productId !== deleteItemId, ingredients)
        onChange({ ingredientCollectionMetadata: filteredIngs })
        setDeleteItemId(undefined)
    }

    return (
        <div className="space-y-2">
            <p className="text-xs text-gray-500">
                Total of % of use must equal 100% whilst the total is lower than 100%, adjust the
                values.
            </p>
            <div className="grid grid-cols-3 gap-x-4 gap-y-2 items-center">
                <CollectionItemHeader>Supplier name</CollectionItemHeader>
                <CollectionItemHeader>Product name</CollectionItemHeader>
                <CollectionItemHeader>% of use</CollectionItemHeader>
                {isEmpty(ingredients) ? (
                    <div className="col-span-3 text-sm text-gray-400">
                        Add supplied products or produce using below action
                    </div>
                ) : (
                    <>
                        {ingredients.map((ing: IIngredientCollectionMetadata) => (
                            <Fragment key={ing.productId}>
                                <div className="col-span-1 text-sm text-gray-900 font-medium">
                                    {ing.supplierName}
                                </div>
                                <div className="col-span-1 truncate">{ing.productName}</div>
                                <div className="col-span-1 flex items-center space-x-2">
                                    <Input
                                        type="number"
                                        name="inputQuantityPercentage"
                                        min={0}
                                        max={100}
                                        id={ing.productId}
                                        value={ing.inputQuantityPercentage}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        variant="danger"
                                        onClick={handleShowDeleteDialog(ing.productId)}
                                        title="delete product"
                                        isOnlyIcon
                                        iconType="trashCan"
                                    />
                                </div>
                            </Fragment>
                        ))}
                    </>
                )}
            </div>
            <RemoveSuppliedItemDialog
                saType={saType}
                open={!isEmpty(deleteItemId)}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleConfirmDeleteDialog}
            />
        </div>
    )
}

function CollectionItemHeader({ children }: IChildrenProps) {
    return <span className="col-span-1 text-sm text-gray-700 font-medium">{children}</span>
}
