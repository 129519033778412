import React, { useContext, useMemo } from 'react'
import { Icon } from '@mondra/ui-components'
import isNil from 'lodash/fp/isNil'
import classNames from 'classnames'
import { SlideoverContext } from '../../../contexts/SlideoverProvider'
import { ISupplyChainNode } from '../../../types/supply-chain-types'
import { NodeType } from '../../../types/types'
import { checkOwnership, getSupplyChainNodeColor } from '../../../utils/supplyChainUtils'
import { TileMenuContext } from '../TileMenuProvider'
import AnimatedDiv from './AnimatedDiv'


const ICON_ERROR = 'errorOutline'
const ICON_CHECKMARK = 'checkmarkOutline'
const ICON_PENDING = 'pending'
const ICON_DELIVERY = 'deliveryTruck'

interface ITransportNodePorps {
    companyId: string
    destination: ISupplyChainNode
    rootParentId: string
    source: ISupplyChainNode
}

export default function TransportNode({
    companyId,
    destination,
    rootParentId,
    source,
}: ITransportNodePorps) {
    const { openSlideover } = useContext(SlideoverContext)
    const { ownership, product } = useContext(TileMenuContext)
    const showOwnership = checkOwnership(ownership, [companyId, rootParentId])

    const transportColor = useMemo(() => getSupplyChainNodeColor(source), [source])
    const isLocked
        = product?.isProductLocked || source?.isProductLocked || destination?.isProductLocked

    const handleTransportClick = () => {
        if (!isLocked) {
            openSlideover(NodeType.TRANSPORT, {
                destinationProduct: destination,
                originProduct: source,
            })
        }
    }

    const statusIcon = useMemo(() => {
        if (isNil(source.hasTransport)) {
            return ICON_ERROR
        } else if (source.hasTransport) {
            return ICON_CHECKMARK
        } else {
            return ICON_PENDING
        }
    }, [source.hasTransport])

    return (
        <AnimatedDiv
            role="button"
            onClick={handleTransportClick}
            className={classNames(
                'absolute right-6 w-12 h-full z-12 text-gray-500 bg-white rounded flex flex-col justify-between items-center',
                showOwnership && 'ring-4 ring-sky-500',
                isLocked && 'cursor-not-allowed'
            )}
        >
            <div
                className={classNames(
                    'w-full flex flex-grow items-center justify-center rounded-t',
                    {
                        [transportColor?.bg]: statusIcon === ICON_CHECKMARK,
                        'border border-b-0 border-gray-300 rounded-t':
                            statusIcon !== ICON_CHECKMARK,
                    }
                )}
            >
                <Icon
                    className={classNames({
                        'text-white': statusIcon === ICON_CHECKMARK,
                    })}
                    type={statusIcon}
                    size={22}
                />
            </div>
            <div className="w-full flex flex-grow items-center justify-center border border-t-0 border-gray-300 rounded-b">
                <Icon type={ICON_DELIVERY} size={22} />
            </div>
        </AnimatedDiv>
    )
}
