import React, { useState, useEffect, useMemo } from 'react'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import { Icon } from '@mondra/ui-components'
import { NavLink } from 'react-router-dom'
import first from 'lodash/fp/first'
import isEmpty from 'lodash/fp/isEmpty'
import { NotificationBar } from '../components'
import { MESSAGE_TRIGGER_SUB_TYPE } from '../constants'


export interface INotificationBellProps {
    classes: any
    color?: string
    messages: any
    showNotification: boolean
}

const styles = () => ({
    customBadge: {
        backgroundColor: (props: any) => props.color || '#F97316',
        right: '25%',
        top: '20%',
    },
})

function NotificationBell(props: INotificationBellProps) {
    const { classes, messages, showNotification } = props
    const validMessages = useMemo(
        () =>
            messages?.filter(
                (msg: any) =>
                    msg.notificationType !== MESSAGE_TRIGGER_SUB_TYPE.V2CALCIMPACTSRECEIVED
            ),
        [messages]
    )
    const [show, setShow] = useState(false)
    const [latestMessage, setLatestMessage] = useState()

    useEffect(() => {
        if (validMessages?.length > 0) {
            const msg = first(validMessages) as any
            if (
                !msg.isHistory
                && showNotification
                && msg.notificationType !== MESSAGE_TRIGGER_SUB_TYPE.V2CALCIMPACTSRECEIVED
            ) {
                setLatestMessage(msg as any)
                setShow(true)
            }
        }
    }, [validMessages])

    return (
        <div>
            <NavLink className="inline-flex items-center justify-center h-14 w-14" to="/company">
                <Badge
                    classes={{ badge: classes.customBadge }}
                    color="primary"
                    variant="dot"
                    overlap="circular"
                    invisible={isEmpty(validMessages)}
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                    }}
                >
                    <Icon
                        className="text-gray-400 hover:text-white"
                        size={26}
                        type={isEmpty(validMessages) ? 'notification' : 'notificationNew'}
                    />
                </Badge>
            </NavLink>
            <NotificationBar show={show} setShow={setShow} message={latestMessage} />
        </div>
    )
}

export default withStyles(styles)(NotificationBell)
