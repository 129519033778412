import classNames from 'classnames'
import React from 'react'


interface IPageTitleProps extends React.ComponentPropsWithoutRef<'div'> {
    right?: React.ReactNode
    title: string
}

export function PageTitle({ className, right, title }: IPageTitleProps) {
    return (
        <div className="flex items-center justify-between mt-2 mb-6">
            <h1 className={classNames('text-xl leading-10 font-medium text-gray-700', className)}>
                {title}
            </h1>
            <div className="flex items-center flex-shrink-0">{right}</div>
        </div>
    )
}
