import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, ConfirmDialog, Label, LottieLoader, ToggleSwitch } from '@mondra/ui-components'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import { Dropdown, Row } from '../../components'
import { ISlideoverFormProps } from '../component-slideover/ComponentSlideover'
import useFetch from '../../hooks/useFetch'
import { SlideoverContext } from '../../contexts/SlideoverProvider'
import { METHOD_POST, METHOD_PUT, URLs } from '../../constants'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import getURL from '../../utils/getURL'
import { NodeType } from '../../types/types'


interface IOptions {
    label: string
    name: string
}

interface IProxy {
    id: string
    code: string
    recordID: number
    isCurrent: boolean
    channel: string
    category: string
    categoryDefinition: string
    country: string
}

interface ISale {
    id: string
    referenceCode: string
    region: string
    channel: string
    productProxy: string
    rdcDestination: string
    rdcTransportDetails: string
    rdcDistanceInKm: number
    isRDCTransportEnabled: true
    productId: string
    transportLegs: [
        {
            id: string
            transportMode: string
            distance: number
            distanceUnit: string
            transportTypeCode: string
            countryId: number
            isArchived: false
        }
    ]
}

export interface IError {
    region: boolean
    channel: boolean
    proxy: boolean
}

export interface ITransport {
    id: string
    code: string
    distance: number
    countryId: number
}

export default function SaleForm({
    companyId,
    formId,
    onFinished,
    onClose,
    product,
    setDisableSave,
    updateProductCustomProps,
}: ISlideoverFormProps) {
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [isRequired, setRequired] = useState<boolean>(product.saleRequired)
    const [selectedRegion, setSelectedRegion] = useState<IOptions>()
    const [selectedRegionTemp, setSelectedRegionTemp] = useState<IOptions>()
    const [selectedChannel, setSelectedChannel] = useState<IOptions>()
    const [selectedProxy, setSelectedProxy] = useState<IProxy>()

    const { openSlideover } = useContext(SlideoverContext)

    useEffect(() => {
        setRequired(product.saleRequired)
    }, [product.saleRequired])

    // Sale
    const { data: sale, refetch } = useFetch<ISale>({
        autoFetch: !isEmpty(product?.id),
        defaultRes: {},
        errMessage: 'Error while fetching sale data',
        onError: onClose,
        url: getURL(URLs.Sales.GET, { companyId, productId: product.id }),
    })
    const isEdit = !isEmpty(sale?.id)

    const onSaved = () => {
        if (product.saleRequired !== isRequired) {
            updateProductCustomProps({ saleRequired: isRequired })
        } else {
            refetch()
            onFinished && onFinished()
        }
    }

    const { update, saving } = useUpdateFormData({
        method: isEdit ? METHOD_PUT : METHOD_POST,
        onUpdate: onSaved,
        url: isEdit
            ? getURL(URLs.Sales.UPDATE, { companyId, id: sale.id })
            : getURL(URLs.Sales.ADD, { companyId, productId: product?.id }),
    })

    const { loading: loadingRegion, data: regions } = useFetch<string[]>({
        autoFetch: isRequired,
        defaultRes: [],
        url: getURL(URLs.Sales.GET_REGIONS, { companyId }),
    })

    const { loading: loadingChannels, data: channels } = useFetch<string[]>({
        autoFetch: !isEmpty(selectedRegion),
        defaultRes: [],
        url: getURL(URLs.Sales.GET_CHANNELS, { companyId, region: selectedRegion?.name }),
    })

    const { loading: loadingProxies, data: proxies } = useFetch<IProxy[]>({
        autoFetch: !isEmpty(selectedRegion) && !isEmpty(selectedChannel),
        defaultRes: [],
        url: getURL(URLs.Sales.GET_PROXIES, {
            channel: selectedChannel?.name,
            companyId,
            region: selectedRegion?.name,
        }),
    })

    const regionOptions: IOptions[] = useMemo(
        () =>
            flow(
                map((region: string) => ({ label: region, name: region })),
                sortBy(i => i.label)
            )(regions),
        [regions]
    )

    const channelOptions: IOptions[] = useMemo(
        () =>
            flow(
                map((channel: string) => ({ label: channel, name: channel })),
                sortBy(i => i.label)
            )(channels),
        [channels]
    )

    const proxyOptions: IProxy[] = useMemo(
        () =>
            flow(
                map((proxy: IProxy) => ({
                    ...proxy,
                    label: proxy.category,
                    name: proxy.channel,
                })),
                sortBy(i => i.label)
            )(proxies),
        [proxies]
    )

    useEffect(() => {
        if (!isEmpty(sale?.id) && isEmpty(selectedRegionTemp) && isRequired) {
            const reg = find(reg => reg.name === sale?.region, regionOptions)
            if (reg) {
                setSelectedRegion(reg)
            }
            const ch = find(ch => ch.name === sale?.channel, channelOptions)
            if (ch) {
                setSelectedChannel(ch)
            }
            const prx = find(pr => pr.code === sale?.referenceCode, proxyOptions)
            if (prx) {
                setSelectedProxy(prx)
            }
        }
    }, [sale, regionOptions, channelOptions, proxyOptions])

    const handleSave = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        update({
            channel: selectedChannel?.name,
            productId: product?.id,
            referenceCode: selectedProxy?.code,
            region: selectedRegion?.name,
            transportLegs: sale?.transportLegs,
        })
    }

    const onRegionChange = useCallback(
        (selected?: IOptions) => {
            if (!isEmpty(selectedChannel) || !isEmpty(selectedProxy)) {
                setSelectedRegionTemp(selected)
                setConfirmOpen(true)
            } else {
                setSelectedRegion(selected)
            }
        },
        [selectedChannel, selectedProxy]
    )
    const onChannelChange = useCallback((selected?: IOptions) => {
        setSelectedChannel(selected)
    }, [])

    const onProxyChange = useCallback((selected?: IProxy) => {
        setSelectedProxy(selected)
    }, [])

    const handleConfirmClose = useCallback(() => {
        setConfirmOpen(false)
    }, [])

    const handleConfirmSave = useCallback(() => {
        setSelectedRegion(selectedRegionTemp)
        setSelectedChannel(undefined)
        setSelectedProxy(undefined)
        setConfirmOpen(false)
    }, [selectedRegionTemp])

    useEffect(() => {
        setDisableSave(
            isEmpty(selectedRegion) || isEmpty(selectedChannel) || isEmpty(selectedProxy)
        )
    }, [selectedRegion, selectedChannel, selectedProxy])

    const handleTransportOpen = () => {
        const props = {
            companyId,
            isEdit,
            originSite: product?.site,
            productId: product?.id,
            rdcSite: 'TEMP',
            refetch,
            sale: {
                channel: selectedChannel?.name,
                id: sale?.id,
                productId: product?.id,
                referenceCode: selectedProxy?.code,
                region: selectedRegion?.name,
                transportLegs: sale?.transportLegs,
            },
            saving,
            update,
        }
        openSlideover(NodeType.SALES_TRANSPORT, props)
    }

    return (
        <form id={formId} className="divide-y divide-gray-200 " onSubmit={handleSave}>
            <ToggleSwitch
                checked={isRequired}
                helpLabels={{
                    left: 'No',
                    right: 'Yes',
                }}
                groupClassName="px-6 py-4 flex items-center justify-between"
                label={
                    <div>
                        <div className="text-sm text-gray-700 font-medium">
                            Is this product sold to consumers?
                        </div>
                        <div className="text-xs text-gray-500">
                            If this product is sold B2B set to no. We only account for consume sale.
                        </div>
                    </div>
                }
                onChange={setRequired}
            />
            <Row>
                <div className="text-sm font-medium text-gray-700 mb-2">
                    <Label helpText="Select the country you sell your product in">
                        Select sale region
                    </Label>
                </div>
                <div className="flex flex-row items-center gap-4">
                    <Dropdown
                        containerClass="flex-grow"
                        disabled={!isRequired || loadingRegion || isEmpty(regionOptions)}
                        selected={selectedRegion}
                        options={regionOptions}
                        onChange={onRegionChange}
                        placeholder={loadingRegion ? 'Loading...' : 'Select sales region'}
                    />
                </div>
            </Row>
            <Row>
                <div className="text-sm font-medium text-gray-700 mb-2">
                    <Label helpText="Select the type of sale for this product">
                        Select sale channel
                    </Label>
                </div>
                <div className="flex flex-row items-center gap-4">
                    <Dropdown
                        containerClass="flex-grow"
                        disabled={
                            !isRequired
                            || loadingChannels
                            || isEmpty(channelOptions)
                            || isEmpty(selectedRegion)
                        }
                        selected={selectedChannel}
                        options={channelOptions}
                        placeholder={loadingChannels ? 'Loading...' : 'Select sales channel'}
                        noOptionText={'No channel'}
                        onChange={onChannelChange}
                        loader={loadingChannels && <LottieLoader lottieType="butterflyLottie" />}
                    />
                </div>
            </Row>
            <Row>
                <div className="text-sm font-medium text-gray-700 mb-2">
                    <Label helpText="Select the type of final product">Select product proxy</Label>
                </div>
                <div className="flex flex-row items-center gap-4">
                    <Dropdown
                        containerClass="flex-grow"
                        disabled={
                            !isRequired
                            || loadingProxies
                            || isEmpty(proxyOptions)
                            || isEmpty(selectedRegion)
                            || isEmpty(selectedChannel)
                        }
                        selected={selectedProxy}
                        options={proxyOptions}
                        placeholder={loadingProxies ? 'Loading...' : 'Select product proxy'}
                        onChange={onProxyChange}
                        loader={loadingProxies && <LottieLoader lottieType="butterflyLottie" />}
                    />
                </div>
            </Row>
            <Row>
                <div className="flex flex-row justify-between items-center gap-4 border-2 rounded-md p-4 bg-gray-100">
                    <Label helpText="Set your final stage transport here">
                        Configure final stage transport
                    </Label>
                    <Button
                        variant="secondary"
                        onClick={handleTransportOpen}
                        disabled={
                            !isRequired
                            || loadingProxies
                            || isEmpty(selectedProxy)
                            || isEmpty(selectedRegion)
                            || isEmpty(selectedChannel)
                        }
                    >
                        Configure transport
                    </Button>
                </div>
            </Row>
            <ConfirmDialog
                open={confirmOpen}
                variant="primary"
                onClose={handleConfirmClose}
                onConfirm={handleConfirmSave}
                primaryBtnText="Confirm"
                secondaryBtnText="Cancel"
                title="Reset sales form"
            >
                This will reset selected channel and proxy. Are you sure to change?{' '}
            </ConfirmDialog>
        </form>
    )
}
