import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import concat from 'lodash/fp/concat'
import reduce from 'lodash/fp/reduce'
import some from 'lodash/fp/some'

import { METHOD_POST, URLs } from '../constants'
import getURL from '../utils/getURL'
import {
    IFormChanges,
    IIngredientCollection,
    IIngredientCollectionMetadata,
    IParamProps,
    IString,
    SA_TYPE,
} from '../types/types'
import useUpdateFormData from './useUpdateFormData'
import useFetch from './useFetch'


interface IIngredientErrorProps {
    name?: string
    noProduct?: string
    totalPercentage?: string
}

const newIngredient: any = {
    ingredientCollectionMetadata: [],
    name: '',
    saType: SA_TYPE.SimpleIngredient,
}

interface IUseIngredientProps {
    defaultName?: string
    ingredientId?: IString
    isEdit?: boolean
    onCreate?: (data?: IIngredientCollection) => void
    onUpdate?: (data?: IIngredientCollection) => void
}

export default function useIngredient({
    defaultName = '',
    ingredientId,
    isEdit,
    onCreate,
    onUpdate,
}: IUseIngredientProps) {
    const [errors, setErrors] = useState<Partial<IIngredientErrorProps>>({})
    const { companyId }: IParamProps = useParams()
    const [ingredient, setIngredient] = useState<IIngredientCollection>({
        ...newIngredient,
        name: defaultName,
    })
    const loadIngredient = isEdit && !isEmpty(companyId) && !isEmpty(ingredientId)
    const { data: ingredientData, loading, refetch } = useFetch({
        autoFetch: false,
        defaultRes: isEdit ? {} : { ...newIngredient, name: defaultName },
        errMessage: 'Failed to fetch ingredient data',
        url: getURL(URLs.IngredientCollection.GET, { companyId, ingredientId }),
    })

    const handleonCreate = (data?: IIngredientCollection) => {
        setIngredient(newIngredient)
        onCreate && onCreate(data)
    }

    const { saving: creating, update: createIngredient } = useUpdateFormData({
        method: METHOD_POST,
        onUpdate: handleonCreate,
        url: getURL(URLs.IngredientCollection.ADD, { companyId }),
    })

    const { saving: updating, update: updateIngredient } = useUpdateFormData({
        method: 'PUT',
        onUpdate,
        url: getURL(URLs.IngredientCollection.UPDATE, { companyId, ingredientId }),
    })

    const onChange = (changes: IFormChanges) => {
        setIngredient((ingState: any) => ({ ...ingState, ...changes }))
        setErrors({})
    }

    const addIngredientCollectionItem = (suppliedProduct: IIngredientCollectionMetadata) => {
        onChange({
            ingredientCollectionMetadata: concat(
                suppliedProduct,
                ingredient.ingredientCollectionMetadata
            ),
        })
        setErrors({})
    }

    const reset = () => {
        setIngredient(isEdit ? {} : (newIngredient as any))
    }

    const checkValidations = (callApi: any) => (data: Partial<IIngredientCollection>) => {
        const err = runIngredientValidation(data)
        if (isEmpty(err)) {
            setErrors({})
            callApi(data)
        } else {
            setErrors(err)
        }
    }

    useEffect(() => {
        setIngredient((ingState: any) => ({ ...ingState, name: defaultName }))
    }, [defaultName])

    useEffect(() => {
        if (loadIngredient) {
            refetch()
        }
    }, [loadIngredient])

    useEffect(() => {
        setIngredient(ingredientData)
    }, [ingredientData])

    return {
        addIngredientCollectionItem,
        createIngredient: checkValidations(createIngredient),
        creating,
        errors,
        ingredient,
        loading,
        onChange,
        refetch,
        reset,
        updateIngredient: checkValidations(updateIngredient),
        updating,
    }
}

const getCollectionMetadataPercentage = reduce((t: number, im: any) => {
    return t + Number(im.inputQuantityPercentage)
}, 0)

function runIngredientValidation(data: any): Partial<IIngredientErrorProps> {
    const { name, ingredientCollectionMetadata } = data
    const errors: IIngredientErrorProps = {}
    if (isEmpty(name)) {
        errors.name = 'Ingredient name is required'
    } else if (ingredientCollectionMetadata?.length === 0) {
        errors.noProduct = 'Add at least one product or produce.'
    } else if (
        some((i: any) => Number(i.inputQuantityPercentage) < 0, ingredientCollectionMetadata)
    ) {
        errors.totalPercentage
            = 'The % of use for supplied product or produce must be a positive number.'
    } else {
        const productsPercentage = getCollectionMetadataPercentage(ingredientCollectionMetadata)

        if (productsPercentage > 100 && ingredientCollectionMetadata?.length === 1) {
            errors.totalPercentage
                = 'The total % of use for supplied product or produce must be 100%.'
        } else if (productsPercentage !== 100 && ingredientCollectionMetadata?.length > 1) {
            errors.totalPercentage
                = 'The total % of use for supplied products or produce must be 100%. Please adjust % of use for each on accordingly.'
        }
    }
    return errors
}

export function showSingleSuppliedProductPercentage(ingredientCollectionMetadata: any[]) {
    const productsPercentage = getCollectionMetadataPercentage(ingredientCollectionMetadata)
    if (productsPercentage < 100 && ingredientCollectionMetadata?.length === 1) {
        return 'The supplied product or produce % of use will be adjusted to 100% while saving.'
    }
    return ''
}
