import React from 'react'
import { Button, Notification } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { LightTooltip } from '../../components'


interface ICapacityErrorStateProps {
    disabled?: boolean
    fillCapacity?: number
    fillCapacityUnit: string
    maxCapacity?: number
    productNetWeight: number
}

function getErrorStateMessage({
    fillCapacity = 0,
    fillCapacityUnit,
    maxCapacity = 0,
    productNetWeight,
}: ICapacityErrorStateProps) {
    if (fillCapacity <= 0) {
        return 'Fill capacity can not be zero or less.'
    }
    if (fillCapacity > maxCapacity) {
        return `Capacity exceeded for this packaging (max ${maxCapacity} ${fillCapacityUnit}). Please review.`
    }
    if (fillCapacity * 1000 < productNetWeight) {
        return 'Load capacity is less than Product net weight. Please review.'
    }
    return ''
}

export default function CapacityErrorState({
    disabled,
    fillCapacity = 0,
    fillCapacityUnit,
    maxCapacity = 0,
    productNetWeight,
}: ICapacityErrorStateProps) {
    const message = getErrorStateMessage({
        fillCapacity,
        fillCapacityUnit,
        maxCapacity,
        productNetWeight,
    })

    if (isEmpty(message)) {
        return <div />
    }

    return (
        <LightTooltip
            interactive
            className={disabled ? 'pointer-events-none' : ''}
            title={<Notification type="warning" description={message} />}
        >
            <Button
                variant="ghost"
                isOnlyIcon
                iconType="warning"
                iconClass="text-amber-500 active:text-amber-500 hover:text-amber-600"
            />
        </LightTooltip>
    )
}
