import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@mondra/ui-components'
import { ICellRendererParams, RowEvent, RowNode } from 'ag-grid-community'
import classNames from 'classnames'

import LcaLegend from '../../components/LcaLegend'
import WaterIconTooltip from '../../components/WaterIconTooltip'
import { getProductPageUrl } from '../../utils/getURL'
import { IIngredientCollection, IProduct, SA_TYPE } from '../../types/types'
import { isSuppliedProductType, isWaterSplIngredient } from '../../utils'
import { SaTypeIcons } from '../../constants'


interface IIngredientNameCell extends ICellRendererParams {
    data: IIngredientCollection & IProduct
    group?: boolean
}

const IngredientName = (props: IIngredientNameCell) => {
    const { context, data, value, node, group = true } = props

    const { companyId, setEditProduceId, setEditSplIngredientId, setIngredientId } = context
    const [expanded, setExpanded] = useState(node.expanded)

    const isProduce = data.saType === SA_TYPE.Produce
    const isSplIngredient = data.saType === SA_TYPE.SpecialIngredient
    const isGroup = group && !data.isWater
    const isClickable = !data.isSpecialIngredient && !data.isWater

    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node.expanded)
        if (isGroup) {
            node?.addEventListener(RowNode.EVENT_EXPANDED_CHANGED, expandListener)
        }

        return () => {
            if (isGroup) {
                node?.removeEventListener(RowNode.EVENT_EXPANDED_CHANGED, expandListener)
            }
        }
    }, [])

    const onClick = useCallback(() => node?.setExpanded(!node.expanded), [node])

    const showIngredient = useCallback(() => {
        if (isClickable) {
            setIngredientId && setIngredientId(data.id)
        }
    }, [])

    const editItem = useCallback(() => {
        if (isSplIngredient && setEditSplIngredientId) setEditSplIngredientId(data.id)
        if (isProduce && setEditProduceId) setEditProduceId(data.id)
    }, [isProduce, isSplIngredient])

    return (
        <div className="flex flex-row items-center">
            {isGroup ? (
                <Icon
                    type="chevronUp"
                    className={classNames(
                        'hover:text-gray-800 transition-all shrink-0',
                        expanded ? 'rotate-180' : 'rotate-90'
                    )}
                    size={18}
                    role="button"
                    onClick={onClick}
                />
            ) : (
                <div className="w-[18px]" />
            )}
            <Icon
                className="text-primary-800 ml-2 shrink-0"
                type={SaTypeIcons[data.saType]}
                size={16}
            />
            {node.level === 0 && (
                <div className="flex flex-col justify-center ml-2 truncate">
                    <div
                        className={classNames(
                            'font-medium overflow-ellipsis overflow-hidden',
                            isClickable && 'mdp-link cursor-pointer',
                            data.knownAs && '-mt-2'
                        )}
                        role={isClickable ? 'button' : undefined}
                        onClick={showIngredient}
                    >
                        {value}
                    </div>
                    {data.knownAs && (
                        <div className="text-xs font-medium text-gray-400 -mt-3">
                            {data.knownAs}
                        </div>
                    )}
                </div>
            )}
            {node.level > 0 && isSuppliedProductType(data.saType) && (
                <Link
                    title={value}
                    className="mdp-link font-medium ml-2 underline overflow-ellipsis overflow-hidden"
                    to={getProductPageUrl({
                        companyId,
                        isSuppliedProduct: true,
                        productId: data.id,
                    })}
                >
                    {value}
                </Link>
            )}
            {node.level > 0 && (isProduce || isSplIngredient) && (
                <div
                    className="cursor-pointer mdp-link font-medium ml-2 overflow-ellipsis overflow-hidden"
                    role="button"
                    onClick={editItem}
                >
                    {value}
                </div>
            )}
            {isWaterSplIngredient(data) && <WaterIconTooltip />}
            {data.isLCA && <LcaLegend />}
        </div>
    )
}

export default IngredientName
