import React, { useMemo } from 'react'
import { Button, Input } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import classNames from 'classnames'
import { Dropdown, ImpactScoreCell, Row } from '../../components'
import { IResourceSource, ISiteResource } from '../../types/stages'
import { CertificateStateEnum } from '../../types/types'
import { PERCENT_SYMB, TYPE_DEFAULT } from '../../constants'
import { buildImpact } from '../../utils/impactUtils'
import ResourcesInfoTooltip from './ResourcesInfoTooltip'


const getImpacts = (resource?: IResourceSource) => {
    const eutro = Number(resource?.eutrophication_kg_PO43_eq || 0)
    const ghg = Number(resource?.carbon_kg_CO2_eq || 0)
    const water = Number(resource?.water_Usage_m3 || 0)
    return {
        bio: {},
        eutro: {
            per100g: eutro > 0 ? eutro / 10 : 0,
            perKg: eutro,
        },
        ghg: {
            per100g: ghg > 0 ? ghg / 10 : 0,
            perKg: ghg,
        },
        water: {
            per100g: water > 0 ? water / 10 : 0,
            perKg: water,
        },
    }
}

export interface ResourceRowProps {
    disabled?: boolean
    handleRemove: () => void
    handleResourceChange: (resource?: IResourceSource) => void
    handleUsageChange: (event: any) => void
    isValidResource: boolean
    isValidUsage: boolean
    resource: ISiteResource
    resourceSources: IResourceSource[]
    unit: string
}

export default function ResourceRow({
    disabled,
    handleRemove,
    handleResourceChange,
    handleUsageChange,
    isValidResource,
    isValidUsage,
    resource,
    resourceSources,
    unit,
}: ResourceRowProps) {
    const isDefaultType = resource.siteResourceSourceUsageType === TYPE_DEFAULT

    const selectedResource = useMemo(() => {
        return find({ resourceSourceRefCode: resource?.resourceSourceRefCode }, resourceSources)
    }, [resource.resourceSourceRefCode, resourceSources])

    const usageByUnit = unit === PERCENT_SYMB ? resource.usageInPercent : resource.usage
    const usage = usageByUnit === undefined ? 0 : usageByUnit

    return (
        <Row className="gap-y-4">
            <div className="grid items-center gap-x-4 grid-cols-12">
                <div className="col-span-9 flex flex-row items-center justify-between gap-x-4">
                    <div className="text-gray-900 w-full">
                        <Dropdown
                            className="w-full"
                            placeholder="Select a source"
                            disabled={disabled}
                            valid={isValidResource}
                            selected={selectedResource}
                            options={resourceSources}
                            onChange={handleResourceChange}
                        />
                    </div>
                    <div className="flex flex-row items-center">
                        <ImpactScoreCell
                            hideGrade
                            disabled={disabled || isEmpty(selectedResource)}
                            iconColor="text-amber-500"
                            customMeasureType={`Per ${unit}`}
                            measureType="perKg"
                            state={CertificateStateEnum.Draft}
                            score=""
                            ecoImpacts={buildImpact(getImpacts(selectedResource))}
                        />
                        <ResourcesInfoTooltip disabled={disabled} resource={selectedResource} />
                    </div>
                </div>
                <div className="col-span-3 flex justify-end">
                    <Input
                        className={classNames(
                            'text-right',
                            unit.length > 2 && 'w-24',
                            unit.length < 3 && 'w-28'
                        )}
                        type="number"
                        disabled={disabled}
                        addonTitle={unit}
                        invalid={!isValidUsage}
                        value={usage}
                        onChange={handleUsageChange}
                    />
                    <Button
                        title="Remove"
                        className={isDefaultType ? 'invisible' : ''}
                        iconType="close"
                        isOnlyIcon
                        onClick={handleRemove}
                    />
                </div>
            </div>
        </Row>
    )
}
