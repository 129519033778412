import React, { useCallback, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'

import { PAGE_TYPES, PageFilters } from '../../components/PageHeader/PageFilters'
import { useFiltersByUrl } from '../../hooks/useFiltersByUrl'
import { useGridControls } from '../../hooks/useGridControls'
import { useControls } from '../../hooks/useControls'
import { useCompanyId } from '../../hooks/useCompanyId'

import { PageHeader } from '../../components/PageHeader'
import { Grid } from '../../components/grid/Grid'
import { GridPageLayout } from '../../components/grid/GridPageLayout'
import { EntityTypeEnum } from '../../types/types'
import { URLs } from '../../constants'
import { serverSideGridProps } from '../../constants/grid'

import { categoryColumns } from './CategoryColumns'
import { AddEditCategory } from './AddEditCategory'


export function Categories() {
    const { setGridApi, refreshGrid } = useGridControls()
    const { close, isOpened, open } = useControls()
    const { filters, setFilters } = useFiltersByUrl()
    const companyId = useCompanyId()

    const handleFilterChange = (filter: any) => {
        setFilters({
            name: filter,
        })
    }

    const handleFilterSubmit = (filters: any) => {
        setFilters(filters)
    }

    const handleRefetch = useCallback(() => {
        refreshGrid()
    }, [refreshGrid])

    if (isEmpty(companyId)) {
        return <Redirect to="/no-company" />
    }

    const urlOptions = useMemo(() => ({ companyId }), [companyId])

    return (
        <GridPageLayout isPadding>
            <PageHeader
                title={PAGE_TYPES.CATEGORIES}
                primaryAction={open}
                onSearch={handleFilterChange}
                filterList={<PageFilters pageType={PAGE_TYPES.CATEGORIES} />}
                onFilterSubmit={handleFilterSubmit}
            />
            <GridPageLayout>
                <Grid
                    columnDefs={categoryColumns}
                    context={{
                        refetch: handleRefetch,
                    }}
                    entityType={EntityTypeEnum.CATEGORY}
                    filters={filters}
                    onApiAvailable={setGridApi}
                    setFilters={setFilters}
                    url={URLs.CompanyCategory.GET_PAGED}
                    urlOptions={urlOptions}
                    {...serverSideGridProps}
                />
            </GridPageLayout>
            <AddEditCategory
                isEdit={false}
                close={close}
                isOpened={isOpened}
                refetch={handleRefetch}
            />
        </GridPageLayout>
    )
}
