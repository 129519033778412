import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Icon, RadioGroup } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import groupBy from 'lodash/fp/groupBy'
import isEmpty from 'lodash/fp/isEmpty'
import reduce from 'lodash/fp/reduce'
import some from 'lodash/fp/some'
import useFetch from '../../hooks/useFetch'
import { useTopCenterToast } from '../../hooks/useTopCenterToast'
import { hasError } from '../../utils'
import getURL from '../../utils/getURL'
import { METHOD_PUT, URLs } from '../../constants'
import { RESOURCE_EMPTY, SITE } from '../../constants/toastNotifications'
import { PageLoader, Row } from '../../components'
import { IActivityRefDataOption, ISiteResource } from '../../types/stages'
import { IProduct } from '../../types/types'
import useUpdateFormData from '../../hooks/useUpdateFormData'
import { ISlideoverFormProps } from './SiteResourcesSlideover'
import ResourcesActivityTab, { IResourceActivityError } from './ResourcesActivityTab'
import ResourcesProductsTab from './ResourcesProductsTab'


const TAB_RESOURCES = 0
const TAB_PRODUCTS = 1

const tabOptions = [
    {
        description: 'Manage your resources and activites',
        label: (
            <div className="flex flex-row flex-start items-center gap-2 mb-1">
                <Icon size={22} type="resources" /> Resources
            </div>
        ),
        value: TAB_RESOURCES,
    },
    {
        description: 'View products and resources for this site',
        label: (
            <div className="flex flex-row flex-start items-center gap-2 mb-1">
                <Icon size={22} type="products" /> Products
            </div>
        ),
        value: TAB_PRODUCTS,
    },
]

export interface ISiteResourceData {
    siteResourceSourcesUsage: ISiteResource[]
    totalProductsProductionInKg: number
}

export interface IResourceGroup {
    [key: string]: ISiteResource[]
}

export default function SiteResourcesForm({
    formId,
    onFinished,
    setDisableSave,
    setSaving,
    site,
}: ISlideoverFormProps) {
    const [formType, setFormType] = useState<number>(0)
    const [siteResources, setSiteResources] = useState<ISiteResourceData>({} as ISiteResourceData)
    const [siteResourcesGroup, setSiteResourcesGroup] = useState<IResourceGroup>({})
    const [siteProducts, setSiteProducts] = useState<IProduct[]>([])
    const [error, setError] = useState<IResourceActivityError>({})
    const { showError } = useTopCenterToast()

    const { companyId }: any = useParams()

    const { data, loading: loadingData } = useFetch<ISiteResourceData>({
        autoFetch: !isEmpty(site?.id),
        errMessage: 'Error occured while loading site resources',
        url: getURL(URLs.SiteResource.GET, { companyId, siteId: site?.id }),
    })

    const { data: products, loading: loadingProducts } = useFetch<IProduct[]>({
        autoFetch: !isEmpty(site?.id) && isEmpty(siteProducts) && formType === TAB_PRODUCTS,
        url: getURL(URLs.Product.GET_ALL_BY_SITE, { companyId, siteId: site?.id }),
    })

    const { data: activityLegs, loading: loadingActivityLegs } = useFetch<IActivityRefDataOption[]>(
        {
            autoFetch: !isEmpty(site?.id) && isEmpty(siteProducts) && formType === TAB_PRODUCTS,
            cache: true,
            defaultRes: [],
            url: getURL(URLs.Activity.LEGS, { companyId, type: 'all' }),
        }
    )

    const onUpdate = () => {
        onFinished && onFinished()
    }

    const { update, saving } = useUpdateFormData({
        method: METHOD_PUT,
        onUpdate,
        url: getURL(URLs.SiteResource.UPDATE, { companyId, siteId: site?.id }),
    })

    const { update: updateProducts, saving: savingProducts } = useUpdateFormData({
        method: METHOD_PUT,
        url: getURL(URLs.Product.UPDATE_BY_SITE, { companyId, siteId: site?.id }),
    })

    useEffect(() => {
        setDisableSave(hasError(error))
    }, [error])

    useEffect(() => {
        if (!isEmpty(data)) {
            const resourceUsage = data.siteResourceSourcesUsage.map((item: ISiteResource) => ({
                ...item,
                usage: item.isUsageInPercent ? 0 : item.usage,
                usageInPercent: item.isUsageInPercent ? item.usage : 0,
            }))
            const groupData = groupBy(
                (r: ISiteResource) => r.dataPlatformResourceCode,
                resourceUsage
            )
            setSiteResources(data)
            setSiteResourcesGroup(groupData)
        }
    }, [data])

    useEffect(() => {
        if (!isEmpty(products) && !isEmpty(activityLegs)) {
            const prods: IProduct[] = map(
                p => ({
                    ...p,
                    activities: map(a => {
                        const act: IActivityRefDataOption
                            = find(
                                d => d?.id?.toString() === a?.dataPlatformActivityCode?.toString(),
                                activityLegs
                            ) || ({} as IActivityRefDataOption)
                        return {
                            ...a,
                            dataPlatformActivityName: act.activityName || '',
                        }
                    }, p.activities),
                }),
                products
            )
            setSiteProducts(prods)
        }
    }, [products, activityLegs])

    const loading = loadingData || loadingProducts || loadingActivityLegs
    const disabled = loading || saving || savingProducts

    const validateResources = () => {
        if (hasError(error)) {
            return false
        }

        return !some((resources: ISiteResource[]) => {
            if (some(r => isEmpty(r.dataPlatformResourceSourceCode), resources)) {
                showError({ description: RESOURCE_EMPTY })
                return true
            }
            if (resources[0]?.isUsageInPercent) {
                const total = reduce(
                    (acc: number, i: ISiteResource) => {
                        return +(i.usageInPercent || 0) + acc
                    },
                    0,
                    resources
                )
                if (total !== 100) {
                    showError({ description: SITE.USUAGE })
                    return true
                }
            }
            return false
        }, siteResourcesGroup)
    }

    const handleSave = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        if (!validateResources()) {
            return
        }

        setSaving(true)

        const flattenResources: ISiteResource[] = reduce(
            (acc: ISiteResource[], item: ISiteResource[]) => {
                return [...acc, ...item]
            },
            [],
            siteResourcesGroup
        )

        const updatedUsageData = flattenResources.map((item: ISiteResource) => {
            const { usageInPercent, ...siteResource } = item

            if (item.isUsageInPercent) {
                return {
                    ...siteResource,
                    usage: usageInPercent,
                }
            }
            return siteResource
        })

        const postData = {
            ...siteResources,
            siteResourceSourcesUsage: updatedUsageData,
        }
        update(postData)
        if (!isEmpty(siteProducts)) {
            updateProducts(siteProducts)
        }
    }

    if (loading) {
        return <PageLoader centered className="mt-4" />
    }

    return (
        <form id={formId} className="text-sm divide-y divide-gray-200 mb-4" onSubmit={handleSave}>
            <Row>
                <RadioGroup
                    onChange={setFormType}
                    options={tabOptions}
                    orientation="horizontal"
                    value={formType}
                    variant="stacked"
                />
            </Row>

            {formType === 0 && (
                <ResourcesActivityTab
                    companyId={companyId}
                    disabled={disabled}
                    error={error}
                    setError={setError}
                    setSiteResources={setSiteResources}
                    setSiteResourcesGroup={setSiteResourcesGroup}
                    site={site}
                    siteResources={siteResources}
                    siteResourcesGroup={siteResourcesGroup}
                />
            )}
            {formType === 1 && (
                <ResourcesProductsTab
                    companyId={companyId}
                    products={siteProducts}
                    updateProducts={setSiteProducts}
                />
            )}
        </form>
    )
}
