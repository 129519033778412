import React from 'react'
import { Icon } from '@mondra/ui-components'
import classNames from 'classnames'
import { statusIcons } from '../../../constants'
import { getSupplyChainNodeColor } from '../../../utils/supplyChainUtils'
import SelfManagedTooltip from '../self-managed-tooltip/SelfManagedTooltip'
import { IProductNode } from '../../../types/supply-chain-types'
import { IEcoImpact } from '../../../types/ecoImpacts'
import { IContributionTreeNode } from '../../../types/contribution-tree-types'
import { SA_TYPE } from '../../../types/types'


export interface IContributionGridNode extends IProductNode {
    ecoImpactStagewise: IEcoImpact[]
    inputQuantityPercentage: number
    isIngredient: boolean
    isLinkedSupplier?: boolean
    isStageNode?: boolean
    impactPercentage: number
    impactValue: number
    hierarchy: string[]
    supplierCompany: string
}

interface IColumnProps {
    row: IContributionTreeNode
}

export default function NameColumn({ row }: IColumnProps) {
    const {
        name,
        originCountryCode,
        isIngredient,
        isSpecialIngredient,
        isStageNode,
        isWater,
        saType,
    } = row || {}

    const isFullRounded
        = isSpecialIngredient
        || isWater
        || ((saType === SA_TYPE.Produce || saType === SA_TYPE.SpecialIngredient) && !isIngredient)

    return (
        <SelfManagedTooltip node={row} noTooltip={isStageNode}>
            <div className="flex flex-row items-center justify-start gap-2">
                <CTStatusIcon
                    className="flex-shrink-0"
                    isRounded={isFullRounded}
                    node={row as IContributionTreeNode}
                />
                <div className="flex flex-row items-center justify-start gap-x-2 truncate">
                    <div className="productname" title={name}>
                        {name}
                    </div>
                    <div className="bg-gray-100 text-gray-600 rounded-2xl text-xs px-1 py-px flex flex-shrink-0 truncate max-w-[50px]">
                        {originCountryCode}
                    </div>
                </div>
            </div>
        </SelfManagedTooltip>
    )
}

interface ICTStatusIcon {
    className?: string
    isRounded?: boolean
    node: IContributionGridNode
}

function CTStatusIcon({ className, isRounded, node }: ICTStatusIcon) {
    const iconColors = getSupplyChainNodeColor(node)

    return (
        <div
            className={classNames(
                'w-8 h-8 text-base font-medium flex items-center justify-center',
                isRounded ? 'rounded-full' : 'rounded-sm',
                iconColors?.bg,
                className
            )}
        >
            <div className="rounded-full flex flex-shrink-0 items-center justify-center w-5 h-5 bg-white">
                <Icon
                    type={statusIcons[node?.state || 'blank']}
                    size={16}
                    className={iconColors?.icon}
                />
            </div>
        </div>
    )
}
