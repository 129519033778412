import React from 'react'
import { Icon } from '@mondra/ui-components'
import { STAT_ICONS } from '../constants'


interface ICompleteStateProps {
    isComplete: boolean
}

export function CompleteState({ isComplete }: ICompleteStateProps) {
    return (
        <>
            {isComplete ? (
                <div className="flex items-center gap-x-2">
                    <Icon className="text-gray-700" type={STAT_ICONS['review']} size={16} />{' '}
                    <div>Data complete</div>
                </div>
            ) : (
                <div className="flex items-center gap-x-2">
                    <Icon className="text-gray-700" type={STAT_ICONS['progress']} size={16} />{' '}
                    <div>In progress</div>
                </div>
            )}
        </>
    )
}
