import { ITransportLeg } from '../types/stages'


export const swapTransportLegs = (
    from: number,
    to: number,
    legs: ITransportLeg[]
): ITransportLeg[] => {
    const legsAll = [...legs]
    const legFrom = legsAll[from]
    const legTo = legsAll[to]
    legsAll[from] = { ...legTo, legOrder: legFrom.legOrder }
    legsAll[to] = { ...legFrom, legOrder: legTo.legOrder }
    return legsAll
}
