import React from 'react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { ingredientColumns } from './IngredientColumns'


const NODE_DATA = {
    level: 1,
}

export function IngredientSuppliedProductsRow(props: ICellRendererParams) {
    const { data, context } = props
    return (
        <div className="py-2 bg-gray-50 divide-y divide-gray-200">
            {data.suppliedProducts?.map((node: any) => (
                <div key={node.id} className="flex items-center py-2">
                    {ingredientColumns.map((ingCol: ColDef) => (
                        <div
                            key={ingCol.field}
                            className="flex-1 px-4"
                            style={{
                                maxWidth: ingCol.maxWidth,
                                minWidth: ingCol.minWidth,
                                width: ingCol.width || '100%',
                            }}
                        >
                            {ingCol.cellRenderer
                                ? ingCol.cellRenderer({
                                    context,
                                    data: node,
                                    group: false,
                                    node: NODE_DATA,
                                    value: ingCol.field ? node[ingCol.field] : '',
                                })
                                : node.value}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}
