import remove from 'lodash/fp/remove'
import take from 'lodash/fp/take'
import { MAX_RECENT_ITEMS } from '../constants'


interface IUseRecentViewed {
    companyId: string
    userId: string
}

interface IRecentItem {
    nodeId: string
    nodeType: string
}

export function useRecentViewed({ companyId, userId }: IUseRecentViewed) {
    const key = `${companyId}-${userId}`

    const getRecentItems = (): IRecentItem[] => {
        return JSON.parse(localStorage.getItem(key) || '[]')
    }

    const updateRecentItems = (item: IRecentItem) => {
        const recent = take(MAX_RECENT_ITEMS - 1, remove({ nodeId: item.nodeId }, getRecentItems()))
        localStorage.setItem(key, JSON.stringify([item, ...recent]))
    }

    const clearRecentItems = () => {
        return localStorage.removeItem(key)
    }

    return {
        clearRecentItems,
        getRecentItems,
        updateRecentItems,
    }
}
