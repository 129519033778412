import isEmpty from 'lodash/fp/isEmpty'


interface ICacheDB {
    [key: string]: {
        timestamp: number
        value: any
    }
}

interface ICacheDBOptions {
    expiryInSeconds?: number
}

const cacheDB: ICacheDB = {}
const defaultOptions: ICacheDBOptions = { expiryInSeconds: 3600 }

export default function useCache(options: ICacheDBOptions = defaultOptions) {
    const { expiryInSeconds = 3600 } = { ...defaultOptions, ...options }

    const now = () => Math.floor(Date.now() / 1000)

    const hasCache = (key: string) => {
        return !isEmpty(cacheDB[key]) && now() - cacheDB[key].timestamp < expiryInSeconds
    }

    const getFromCache = (key: string, defaultValue?: any) => {
        if (hasCache(key)) {
            return cacheDB[key].value || defaultValue
        }
        return defaultValue
    }

    const setCache = (key: string, value: any) => {
        cacheDB[key] = {
            timestamp: now(),
            value,
        }
    }

    const removeFromCache = (key: string) => {
        delete cacheDB[key]
    }

    return {
        getFromCache,
        hasCache,
        removeFromCache,
        setCache,
    }
}
