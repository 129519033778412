import React, { Dispatch, SetStateAction, useState } from 'react'
import { IChildrenProps } from '../types/types'


interface IFilterContext {
    filters: Record<string, any>
    setFilters: Dispatch<SetStateAction<Record<string, any>>>
}

export const FilterContext = React.createContext<IFilterContext>({
    filters: {},
    setFilters: () => null,
})

export const FilterProvider = ({ children }: IChildrenProps) => {
    const [filters, setFilters] = useState({})
    return (
        <FilterContext.Provider value={{ filters, setFilters }}>{children}</FilterContext.Provider>
    )
}
