import React, { useState, useEffect } from 'react'

import { IChildrenProps } from '../types/types'
import { getInitials } from '../utils'
import MondraIcon from './MondraIcon'


type FillMode = 'object-cover' | 'object-contain'

type Size = 'sm' | 'md' | 'lg'

const dimensions = {
    lg: {
        height: '150px',
        width: '180px',
    },
    md: {
        height: '90px',
        width: '90px',
    },
    sm: {
        height: '32px',
        width: '32px',
    },
}

const textSizes = {
    lg: 'text-6xl',
    md: 'text-2xl',
    sm: 'text-xs',
}

interface IBrandTileProps extends IChildrenProps {
    companyName?: string
    src?: string
    size: Size
    fill?: FillMode
    upload?: React.ReactNode
}

const BrandTile = ({
    companyName,
    src,
    size,
    fill = 'object-cover',
    children,
    upload,
}: IBrandTileProps) => {
    const [error, setError] = useState(false)
    useEffect(() => {
        setError(false)
    }, [src])
    const imageReady = src && !error
    const { width, height } = dimensions[size]

    return (
        <div
            className={`group relative flex items-center justify-center ${
                companyName ? 'bg-violet-100' : 'bg-white'
            } rounded ${imageReady ? 'bg-transparent' : ''}`}
            style={{ height, minHeight: height, minWidth: width, width }}
        >
            {src && !error && (
                <img
                    src={src}
                    alt="brand title"
                    className={`max-w-full max-h-full w-full h-full ${fill}`}
                    onError={() => {
                        setError(true)
                    }}
                />
            )}
            {(!src || error) && (
                <>
                    {companyName ? (
                        <span
                            className={`text-violet-900 leading-normal ${textSizes[size]} font-medium`}
                        >
                            {getInitials(companyName)}
                        </span>
                    ) : (
                        <MondraIcon width={height} height={height} />
                    )}
                    {upload}
                </>
            )}
            {children}
        </div>
    )
}

export default BrandTile
