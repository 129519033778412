import { NOTE_TYPE } from '../../types/types'


export const TABS_INDEX: any = {
    0: NOTE_TYPE.NOTE,
    1: NOTE_TYPE.HOTSPOT,
    2: NOTE_TYPE.INSIGHT,
}

export const TYPES_INDEX: any = {
    [NOTE_TYPE.NOTE]: 0,
    [NOTE_TYPE.HOTSPOT]: 1,
    [NOTE_TYPE.INSIGHT]: 2,
}
