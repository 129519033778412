import React, { useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import classNames from 'classnames'

import { getSupplyChainData, getNextItemInChain } from '../../../utils/supplyChainUtils'
import { supplyChainVizData } from '../../../mock-data/supply-chain-viz'

import {
    ISupplyChainAPI,
    ISupplyChainData,
    ISupplyChainNode,
    ISupplyChainRow,
} from '../../../types/supply-chain-types'
import SlideoverProvider from '../../../contexts/SlideoverProvider'
import { TileMenuContext } from '../TileMenuProvider'
import { URLs } from '../../../constants'
import useFetch from '../../../hooks/useFetch'
import getURL from '../../../utils/getURL'
import SupplyChainLoader from './SupplyChainLoader'
import TransportNode from './TransportNode'
import SupplyChainNode from './SupplyChainNode'
import ConnectingLines from './ConnectingLines'


const SC_ROW_HEIGHT = 96
const SC_COLUMN_SIZE = 340
const SC_COLUMN_GAP = 24
const SC_COLUMN_WIDTH = SC_COLUMN_SIZE + SC_COLUMN_GAP
const SC_COLUMN_OFFSET = 20 + 48 // transport node width + gap

const loaderData = getSupplyChainData(
    supplyChainVizData['data1'],
    SC_ROW_HEIGHT,
    SC_COLUMN_WIDTH,
    SC_COLUMN_OFFSET
)

const noIngredients = {
    companyName: 'You',
    id: 'no-ingredient',
    name: 'No ingredients',
    state: 'blank',
    supplierName: 'You',
    type: 'ingredient',
} as ISupplyChainNode

interface IProps {
    companyId?: string
    productId?: string
    setProduct: (product: any) => void
    sortOption: string
}

const colClass = 'relative text-left flex justify-start items-center ml-6'

export default function SupplyChainViz({ companyId, productId, setProduct, sortOption }: IProps) {
    const [scData, setScData] = useState<ISupplyChainData>(loaderData)
    const [isDataLoaded, setDataLoaded] = useState(false)
    const { setRefetch } = useContext(TileMenuContext)

    const { data, loading, refetch } = useFetch<ISupplyChainAPI>({
        autoFetch: true,
        url: getURL(
            URLs.SupplyChain.SUPPLY_CHAIN,
            { companyId, productId },
            `?supplyChainSortOption=${sortOption}`
        ),
    })

    useEffect(() => {
        if (!loading && !isEmpty(data)) {
            setRefetch(() => refetch)
            setProduct({ ...data?.product })
            setScData(getSupplyChainData(data, SC_ROW_HEIGHT, SC_COLUMN_WIDTH, SC_COLUMN_OFFSET))
            setDataLoaded(true)
        } else {
            setDataLoaded(false)
        }
    }, [loading, data])

    function handleRefetch() {
        refetch()
    }

    return (
        <SlideoverProvider companyId={companyId || ''} onSaved={handleRefetch}>
            <div>
                <div className="sticky top-0 flex flex-row w-full bg-white border-b border-gray-300 z-20">
                    <div
                        className="grid gap-6 items-center"
                        style={{
                            gridAutoRows: '56px',
                            gridTemplateColumns: `repeat(${
                                2 + (scData?.columnCount || 0)
                            }, minmax(${SC_COLUMN_SIZE}px, 1fr))`,
                        }}
                    >
                        <Title className={colClass}>Produce</Title>
                        {scData?.supplyChainColumns?.map(({ id, name }: any) => (
                            <Title key={id} className={colClass}>
                                {name}
                            </Title>
                        ))}

                        <Title className={colClass}>Ingredients</Title>
                    </div>
                    <InvisibleTile />
                </div>
                <div className="flex flex-row gap-y-6 w-full py-6">
                    <div
                        className="grid gap-6 items-center"
                        style={{
                            gridAutoRows: scData?.gridRows,
                            gridTemplateColumns: `repeat(${
                                2 + (scData?.columnCount || 0)
                            }, minmax(${SC_COLUMN_SIZE}px, 1fr))`,
                        }}
                    >
                        {!isDataLoaded ? (
                            <SupplyChainLoader
                                colClass={colClass}
                                ingredientRows={scData?.ingredientRows}
                            />
                        ) : (
                            <VizRows
                                ingredientRows={scData?.ingredientRows}
                                product={scData?.product}
                            />
                        )}
                    </div>
                    <InvisibleTile id={scData?.product?.nodeId} className="mt-5" />
                </div>

                {isDataLoaded
                    && scData?.lines.map((line: any) => (
                        <ConnectingLines key={`${line.start}-${line.end}`} line={line} />
                    ))}
            </div>
            {/* <RemoveScNode /> */}
        </SlideoverProvider>
    )
}

function VizRows({
    ingredientRows,
    product,
}: {
    ingredientRows?: ISupplyChainRow[]
    product: ISupplyChainNode
}) {
    return (
        <>
            {ingredientRows && ingredientRows.length > 0 ? (
                ingredientRows.map((i: ISupplyChainRow) => {
                    return (
                        <React.Fragment key={i.ingredient.id}>
                            <div className={classNames(colClass, { invisible: i.produce.isDummy })}>
                                <SupplyChainNode node={i.produce} />
                                <TransportNode
                                    companyId={i.produce.companyId}
                                    rootParentId={i.produce.rootParentId}
                                    source={i.produce}
                                    destination={getNextItemInChain(i.supplyChain, 0, product)}
                                />
                            </div>
                            {i.supplyChain.map((sup: ISupplyChainNode, idx: number) => (
                                <div key={sup.id} className={colClass}>
                                    <SupplyChainNode node={sup} />
                                    {!sup.isDummy && (
                                        <TransportNode
                                            companyId={sup.companyId}
                                            rootParentId={sup.rootParentId}
                                            source={sup}
                                            destination={getNextItemInChain(
                                                i.supplyChain,
                                                idx + 1,
                                                product
                                            )}
                                        />
                                    )}
                                </div>
                            ))}

                            <div className={colClass}>
                                <SupplyChainNode node={i.ingredient} />
                            </div>
                        </React.Fragment>
                    )
                })
            ) : (
                <>
                    <div className={colClass} />
                    <div className={colClass} />
                    <div className={colClass}>
                        <SupplyChainNode
                            node={{ ...noIngredients, id: product.id, nodeId: 'no-ingredient' }}
                            noIngredient
                        />
                    </div>
                </>
            )}
        </>
    )
}

function Title({ className, children }: any) {
    return (
        <div className={`${className} bg-white text-gray-700 text-xs uppercase font-semibold h-14`}>
            {children}
        </div>
    )
}

const InvisibleTile = ({ className, id }: any) => {
    return (
        <div className={classNames('invisible flex-grow text-right', className)}>
            <div className="h-14 w-px ml-auto" id={id}>
                invisible
            </div>
        </div>
    )
}
