import React, { useContext } from 'react'
import { Button } from '@mondra/ui-components'
import capitalize from 'lodash/fp/capitalize'
import startCase from 'lodash/fp/startCase'
import Pluralize from 'pluralize'
import { PageTitle } from '../PageTitle'
import { UserContext } from '../../contexts/UserProvider'
import { IPageFilterBarProps, PageFilterBar } from './PageFilterBar'


interface IPageHeaderProps extends IPageFilterBarProps {
    primaryAction: () => void
    secondaryAction?: () => void
    thirdBtnAction?: () => void
}

export const PageHeader = ({
    title,
    primaryAction,
    secondaryAction,
    thirdBtnAction,
    onSearch,
    actionsComponent,
    filterList,
    onFilterSubmit,
}: IPageHeaderProps) => {
    const { company } = useContext(UserContext)

    return (
        <div className="flex-shrink-0">
            <PageTitle
                title={`${company.name} ${capitalize(title)}`}
                right={
                    <>
                        <Button onClick={primaryAction}>
                            Add {Pluralize(startCase(title.toLowerCase()), 1)}
                        </Button>
                        {secondaryAction && (
                            <Button className="ml-2" onClick={secondaryAction}>
                                Add Produce
                            </Button>
                        )}
                        {thirdBtnAction && (
                            <Button className="ml-2" onClick={thirdBtnAction}>
                                Add Special Ingredient
                            </Button>
                        )}
                    </>
                }
            />
            <PageFilterBar
                title={title}
                onSearch={onSearch}
                actionsComponent={actionsComponent}
                onFilterSubmit={onFilterSubmit}
                filterList={filterList}
            />
        </div>
    )
}
