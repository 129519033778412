import React, { useMemo } from 'react'
import { Icon, Label, RadioGroup } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { SA_TYPE } from '../types/types'
import { SUPPLIED_PRODUCT_LABELS } from '../constants'


const productTypeOptions: any = [
    {
        description:
            'A simple ingredient can have one produce or one simple ingredient as an input.',
        label: (
            <div className="flex flex-row flex-start items-center gap-1 mb-2">
                <Icon size={16} type="simpleIngredient" />
                {SUPPLIED_PRODUCT_LABELS[SA_TYPE.SimpleIngredient].title}
            </div>
        ),
        value: SA_TYPE.SimpleIngredient,
    },
    {
        description:
            'A complex ingredient has multiple produce and/or simple ingredients as inputs.',
        label: (
            <div className="flex flex-row flex-start items-center gap-1 mb-2">
                <Icon size={16} type="complexIngredient" />
                {SUPPLIED_PRODUCT_LABELS[SA_TYPE.ComplexIngredient].title}
            </div>
        ),
        value: SA_TYPE.ComplexIngredient,
    },
]

const produceTypeOptions = [
    {
        description: 'Special Ingredient.',
        label: (
            <div className="flex flex-row flex-start items-center gap-1 mb-2">
                <Icon size={16} type="specialIngredient" />
                {SUPPLIED_PRODUCT_LABELS.SpecialIngredient.title}
            </div>
        ),
        value: SA_TYPE.SpecialIngredient,
    },
    {
        description:
            'Produce includes fruit, vegetables, grains, meat and dairy directly from a farm / grower.',
        label: (
            <div className="flex flex-row flex-start items-center gap-1 mb-2">
                <Icon size={16} type="produce" />
                {SUPPLIED_PRODUCT_LABELS.Produce.title}
            </div>
        ),
        value: SA_TYPE.Produce,
    },
]

export enum SaTypeRadioTypes {
    ALL = 'All',
    PRODUCE = 'Produce',
    PRODUCT = 'Product',
}

interface ISaTypeRadio {
    disabled?: boolean
    error?: boolean
    id?: string
    label?: string
    name?: string
    onChange: (type: SA_TYPE) => void
    selected: SA_TYPE
    type?: SaTypeRadioTypes
}

export function SaTypeRadio({
    error,
    name = 'saType',
    onChange,
    selected,
    type,
    ...rest
}: ISaTypeRadio) {
    const options = useMemo(() => {
        switch (type) {
            case SaTypeRadioTypes.PRODUCT:
                return productTypeOptions
            case SaTypeRadioTypes.PRODUCE:
                return produceTypeOptions
            default:
                return [...productTypeOptions, ...produceTypeOptions]
        }
    }, [type])

    return (
        <div className="flex flex-col gap-y-4">
            <RadioGroup
                {...rest}
                name={name}
                orientation="horizontal"
                variant="stacked"
                className="flex-wrap"
                optionClassName="flex-[0_0_48%]"
                options={options}
                value={selected}
                onChange={onChange}
            />
            {!isEmpty(error) && <Label valid={false}>{error}</Label>}
        </div>
    )
}
