import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Icon } from '@mondra/ui-components'
import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import map from 'lodash/fp/map'
import { IClassNameProps } from '../../types/types'
import { INavTabOption } from './NavTab'
import { MenuItem } from './MenuItem'


interface ISubNavTabProps extends IClassNameProps {
    children?: React.ReactElement
    options: INavTabOption[]
}
export function SubNavTab({ className, options }: ISubNavTabProps) {
    const { pathname } = useLocation()
    return (
        <Menu as="div" className={classNames('relative text-left', className)}>
            <Menu.Items
                as="ul"
                static
                className={classNames(
                    'whitespace-nowrap min-w-[240px] py-2 mt-px list-none',
                    'bg-white rounded-sm border border-gray-100 shadow-lg',
                    'focus:outline-none'
                )}
            >
                {map(({ className, label, ...rest }: any) => {
                    const item = rest.to.toString().includes(pathname) ? (
                        <div className="w-full flex flex-row items-center justify-between">
                            {label} <Icon type="checkmark" size={20} className="mx-2" />
                        </div>
                    ) : (
                        <>{label}</>
                    )
                    return (
                        <MenuItem
                            key={rest.to.toString()}
                            as={NavLink}
                            id={label as any}
                            value={item}
                            className={className?.toString()}
                            {...rest}
                        />
                    )
                }, options)}
            </Menu.Items>
        </Menu>
    )
}
