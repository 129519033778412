import React, { useCallback, useContext, useMemo } from 'react'
import some from 'lodash/fp/some'
import { Button, Icon } from '@mondra/ui-components'
import useAuth from '../hooks/useAuth'
import { UserContext } from '../contexts/UserProvider'
import useUpdateFormData from '../hooks/useUpdateFormData'
import { useTopCenterToast } from '../hooks/useTopCenterToast'

import { getDefaultColor } from '../utils/colorUtils'
import getURL from '../utils/getURL'
import { URLs } from '../constants'
import { SUPPLIER } from '../constants/toastNotifications'


interface IQuickAddSupplierProps {
    companyId: string
    name?: string
    onAdd: (data: any) => void
    options: any[]
    refetch: () => void
}

export default function QuickAddSupplier({
    name = '',
    companyId,
    options,
    refetch,
    onAdd,
}: IQuickAddSupplierProps) {
    const { showSuccess } = useTopCenterToast()
    const auth = useAuth()
    const { currentUser } = useContext(UserContext)

    const isNameExists = useMemo(() => {
        return some({ label: name }, options)
    }, [name, options])

    const onUpdate = useCallback((data: any) => {
        showSuccess({ description: SUPPLIER.ADD })
        refetch && refetch()
        onAdd(data)
    }, [])

    const { saving, update } = useUpdateFormData({
        onUpdate,
        url: getURL(URLs.Supplier.ADD, { companyId }),
    })

    function handleSubmit(event: any) {
        event && event.preventDefault()
        event && event.stopPropagation()
        if (name && name.trim()) {
            const avatarColor = getDefaultColor()
            update({
                avatarColor: avatarColor.color,
                avatarTextColor: avatarColor.text,
                companyId: companyId,
                name,
            })
        }
    }

    if (!auth || !currentUser || isNameExists) {
        return null
    }

    return (
        <div className="flex items-center justify-between w-full">
            <div className="mr-1">
                Add supplier <b>&ldquo;{name}&rdquo;</b>
            </div>
            <Button variant="primary" disabled={saving} onClick={handleSubmit}>
                <Icon type="add" className="leading-none w-8 h-8" />
            </Button>
        </div>
    )
}
