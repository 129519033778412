import React from 'react'

import SlideoverAlert from '../../product-overiew/SlideoverAlert'
import { SUPPLIED_PRODUCT_LABELS } from '../../constants'


interface ISingleSuppliedProductPercentageAlertProps {
    description: string
    saType: string
}

export default function SingleSuppliedProductPercentageAlert({
    description,
    saType,
}: ISingleSuppliedProductPercentageAlertProps) {
    const labels: any = SUPPLIED_PRODUCT_LABELS[saType]

    return <SlideoverAlert title={`${labels?.title} share percentage`} description={description} />
}
