import React, { useState, useEffect, useMemo } from 'react'
import { Button, Label, Textarea, ToggleSwitch } from '@mondra/ui-components'
import map from 'lodash/fp/map'
import isEmpty from 'lodash/fp/isEmpty'
import filter from 'lodash/fp/filter'
import findIndex from 'lodash/fp/findIndex'
import find from 'lodash/fp/find'
import { ICountry, IProduct, SA_TYPE } from '../../types/types'
import { ITransport, ITransportLeg, ITransportType } from '../../types/stages'
import { FormRow } from '../../components'
import { mapWithIndex } from '../../utils/lodash-convert'
import getURL from '../../utils/getURL'
import useFetch from '../../hooks/useFetch'
import useCountries from '../../hooks/useCountries'
import { URLs } from '../../constants'
import TransportLeg from './Transportleg'
import Site from './Site'


const produceSaTypeGroup = [SA_TYPE.Produce, SA_TYPE.SpecialIngredient]

interface ITransportFormProps {
    companyId: string
    isEdit: boolean
    formId: string
    onSubmit: (event: any) => void
    origin: IProduct
    destination: IProduct
    transport: ITransport
}

const defaultLeg = {
    distance: '',
    distanceUnit: 'KM',
    id: '',
    isArchived: false,
    legOrder: 0,
    transportTypeCode: '',
}

export default function TransportForm({
    companyId,
    destination,
    formId,
    onSubmit,
    origin,
    isEdit,
    transport,
}: ITransportFormProps) {
    const [isTransporting, setIsTransporting] = useState(false)
    const [reason, setReason] = useState('')
    const [legs, setLegs] = useState<ITransportLeg[]>([defaultLeg])
    const { countries } = useCountries({ autoFetch: true })
    const isProduceType = produceSaTypeGroup.includes(origin?.saType)

    const url = getURL(URLs.Transport.GET_TYPES, { companyId })
    const { data: transportTypes, loading } = useFetch({
        errMessage: 'Failed to fetch transport types',
        url,
    })

    useEffect(() => {
        if (isEdit) {
            setIsTransporting(transport?.transportingProduct)
            setReason(transport?.noTransportReason)
            if (!isEmpty(transport.transportLegs)) {
                setLegs(
                    transport.transportLegs?.map((leg: ITransportLeg, index: number) => ({
                        ...leg,
                        legOrder: index,
                    })) as any
                )
            }
            if (isEmpty(transport.transportLegs)) {
                setLegs([defaultLeg])
            }
        }
    }, [isEdit, transport])

    const transportTypesOptions = useMemo(() => {
        return map(
            (type: ITransportType) => ({
                ...type,
                label: type.name,
                value: type.code,
            }),
            transportTypes
        )
    }, [transportTypes])

    const countryList = useMemo(() => {
        return map(
            (country: ICountry) => ({
                ...country,
                label: `${country.isoAlpha3} - ${country.countryName}`,
                value: country.id,
            }),
            countries
        )
    }, [countries])

    const handleToggle = (checked: boolean) => {
        setIsTransporting(checked)
    }

    const handleTransportChange = (legOrder: number) => (upatedLeg: ITransportLeg) => {
        const allLegs = [...legs]
        const index = findIndex((leg: ITransportLeg) => leg.legOrder === legOrder, legs)
        allLegs[index] = upatedLeg
        setLegs(allLegs)
    }

    const handleTransportRemove = (legOrder: number) => () => {
        const allLegs = [...legs]
        const leg = find((leg: ITransportLeg) => leg.legOrder === legOrder, legs)
        if (leg) {
            leg.isArchived = true
            allLegs[legOrder] = leg
            setLegs(allLegs)
        }
    }

    const handleAddLeg = () => {
        setLegs((legs: ITransportLeg[]) => [...legs, { ...defaultLeg, legOrder: legs.length + 1 }])
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        onSubmit({
            ...transport,
            destinationSiteId: destination?.site?.id,
            linkedProductId: destination.id,
            noTransportReason: reason,
            sourceSiteId: origin?.site?.id,
            transportingProduct: isTransporting,
            transportLegs: isTransporting ? map(leg => ({ ...leg, tempId: undefined }), legs) : [],
            transportProductId: origin.id,
        })
    }
    const disabled
        = !isTransporting
        || (isEmpty(origin?.site?.id) && !isProduceType)
        || (isProduceType && isEmpty(origin?.originCountryCode))
        || isEmpty(destination?.site?.id)

    const originSite = useMemo(() => {
        if (isProduceType && !isEmpty(origin?.originCountryCode)) {
            return {
                address: {
                    country: origin?.originCountryCode,
                },
            }
        }
        return origin?.site
    }, [origin])

    return (
        <form id={formId} className="divide-y divide-gray-200" onSubmit={handleSubmit}>
            <FormRow>
                <div className="font-medium">
                    <div className="text-sm text-gray-700">Transport of product</div>
                    <div className="text-base text-gray-900">
                        <span>{origin.name}</span>
                        <span className="text-primary-500">&nbsp; by {origin.companyName}</span>
                    </div>
                </div>
            </FormRow>
            <FormRow>
                <div className="col-span-1">
                    <Label
                        htmlFor="reason"
                        helpText="If you are not transporting your product select no and give reason."
                    >
                        Are you transporting?
                    </Label>
                </div>
                <div className="col-span-1">
                    <ToggleSwitch
                        checked={isTransporting}
                        helpLabels={{
                            left: 'No',
                            right: 'Yes',
                        }}
                        groupClassName="flex items-center justify-end"
                        onChange={handleToggle}
                    />
                </div>
            </FormRow>
            {!isTransporting && (
                <FormRow>
                    <div className="col-span-2">
                        <Label htmlFor="reason">Reason</Label>
                    </div>
                    <div className="col-span-2">
                        <Textarea
                            value={reason}
                            onChange={(event: any) => setReason(event.target.value)}
                            className="w-full"
                        />
                    </div>
                    <div className="text-xs text-gray-500 col-span-2 mt-4">
                        Brief description why transport is not needed
                    </div>
                </FormRow>
            )}
            <FormRow>
                <Site site={originSite as any} isTransporting={isTransporting} type="Origin" />
                <Site site={destination?.site} isTransporting={isTransporting} type="Destination" />
            </FormRow>
            <div>
                {mapWithIndex(
                    (leg: ITransportLeg) => (
                        <TransportLeg
                            disabled={disabled}
                            key={leg.legOrder}
                            leg={leg}
                            legs={legs}
                            setLegs={setLegs}
                            countries={countryList}
                            transportTypes={transportTypesOptions}
                            transportTypesLoading={loading}
                            onChange={handleTransportChange(leg.legOrder)}
                            onRemove={handleTransportRemove(leg.legOrder)}
                        />
                    ),
                    filter(leg => !leg.isArchived, legs)
                )}
                <div className="text-right mt-6 mx-6">
                    <Button
                        disabled={disabled}
                        variant="secondary"
                        onClick={handleAddLeg}
                        iconType="add"
                    >
                        Add Transport leg
                    </Button>
                </div>
            </div>
        </form>
    )
}
