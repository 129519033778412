import { useState } from 'react'
import { mutate } from 'swr'
import HttpException, { processError, notifyErrorResponse } from '../utils/httpException'
import { METHOD_POST } from '../constants'
import { useTopCenterToast } from './useTopCenterToast'
import useAuth from './useAuth'


interface IUseUpdateFormDataProps {
    contentType?: boolean
    method?: string
    mutateUrl?: string
    onError?: (error: any) => void
    onUpdate?: (product: any) => void
    url: string
}

export default function useUpdateFormData({
    contentType = true,
    method = METHOD_POST,
    url,
    mutateUrl,
    onUpdate,
    onError,
}: IUseUpdateFormDataProps) {
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)

    const { acquireToken } = useAuth()
    const { showError } = useTopCenterToast()

    const update = async(data: any, _url?: string, _method?: string) => {
        setSaving(true)
        const token = await acquireToken()
        const headers: any = {
            Authorization: `Bearer ${token}`,
            'X-MONDRA-APP': 'CP',
        }
        let body = data
        if (contentType) {
            headers['Content-Type'] = 'application/json'
            body = JSON.stringify(data)
        }
        fetch(`${process.env.REACT_APP_API_URL}${_url || url}`, {
            body,
            headers,
            method: _method || method,
        })
            .then(async rsp => {
                if (rsp.ok || rsp.status === 200) {
                    return rsp.text()
                }
                const errorResponse = await processError(rsp, method)

                throw new HttpException(errorResponse, errorResponse.message)
            })
            .then(data => (data ? JSON.parse(data) : {}))
            .then(data => {
                mutateUrl && mutate(mutateUrl)
                return data
            })
            .then(data => {
                onUpdate && onUpdate(data)
            })
            .catch(error => {
                notifyErrorResponse(showError, error)
                setError(error)
                onError && onError(error)
            })
            .finally(() => {
                setSaving(false)
            })
    }

    return { error, saving, update }
}
