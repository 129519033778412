import React from 'react'
import { DataQualityScore } from './DataQualityScore'


interface IDataQualityHeaderProps {
    score: number
}
export function DataQualityHeader({ score }: IDataQualityHeaderProps) {
    return (
        <div className="flex items-center justify-between">
            <div className="font-medium text-gray-900 ">Data Quality Score</div>{' '}
            <DataQualityScore value={score} />
        </div>
    )
}
