import React from 'react'
import { Button } from '@mondra/ui-components'
import classNames from 'classnames'
import { IChildrenProps, IClassNameProps } from '../../types/types'
import Tooltip from '../../components/LightTooltip'


export interface IInfoTooltipProps extends IChildrenProps, IClassNameProps {
    disabled?: boolean
    iconColor?: string
    iconSize?: number
    iconType?: string
    title?: string
}

export default function InfoTooltip({
    children,
    className,
    disabled = false,
    iconType = 'information',
    title,
}: IInfoTooltipProps) {
    return (
        <Tooltip
            className={disabled ? 'pointer-events-none' : ''}
            interactive={true}
            enterDelay={500}
            enterNextDelay={500}
            title={
                disabled ? (
                    <React.Fragment />
                ) : (
                    <div
                        className={classNames(
                            'bg-white rounded-lg shadow-lg border border-gray-50 text-black text-sm',
                            title && 'p-4',
                            className
                        )}
                    >
                        {title}
                        {children}
                    </div>
                )
            }
        >
            <Button variant="ghost" isOnlyIcon iconType={iconType} />
        </Tooltip>
    )
}
