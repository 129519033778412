import React, { useMemo } from 'react'
import { Autocomplete, Label } from '@mondra/ui-components'
import find from 'lodash/fp/find'

import { FormRow } from '../../components'
import useTechnologySystems from '../../hooks/useTechnologySystems'


interface ITechnologySystemsRowProps {
    autoFetch: boolean
    onChange: (value: string) => void
    value: string
    readOnly: boolean
    invalid: boolean
}

export function TechnologySystemsRow({
    autoFetch,
    invalid,
    onChange,
    readOnly,
    value,
}: ITechnologySystemsRowProps) {
    const { technologySystems, loading: loadingTechSystems } = useTechnologySystems({ autoFetch })

    const selectedTechSystem = useMemo(() => {
        return find({ value }, technologySystems)
    }, [value, technologySystems])

    const handleChange = (changes: any) => {
        const { selectedItem } = changes
        onChange(selectedItem?.value)
    }
    return (
        <FormRow>
            <Label className="col-span-1" helpText="e.g. Organic, Conventional or Wild Caught">
                Technology System
            </Label>
            <Autocomplete
                placeholder={loadingTechSystems ? 'Loading...' : 'Select a Technology System'}
                selected={selectedTechSystem}
                disabled={readOnly || loadingTechSystems}
                onChange={handleChange}
                options={technologySystems}
                invalid={invalid}
            />
        </FormRow>
    )
}
