import { ReactNode, ReactChildren, ReactElement } from 'react'
import { INote as INoteLib, Priority } from '@mondra/ui-components/@types'
import { IEcoImpact } from './ecoImpacts'
import { IStageActivity } from './stages'
import { IDataQualityScoreAttributes } from './contribution-tree-types'


export type IString = string | undefined | null

export type INumber = number | ''

export type IValue = IString | number | boolean | any[]

export type IFormChanges = Record<string, IValue>

interface ICustomTargetProps {
    name: string
    value: any
    type?: string
}

export interface ICustomEventProps {
    target: ICustomTargetProps
}

export interface IChildrenProps {
    /**
     * Defines the Children DOM for any react component
     */
    children?: ReactNode | ReactChildren
}

export interface IParamProps {
    /**
     * This property is for company id from the URL
     */
    companyId?: string
    /**
     * This propery is for any entity id from the URL for example product, certificate & component etc..
     */
    id?: string
    /**
     * This property is for tabpath from the URL
     */
    tabPath?: string
}

export type Element = ReactNode | ReactElement | ReactChildren

export enum CertificateStateEnum {
    CertificationExpired = 'CertificationExpired',
    Certified = 'Certified',
    Draft = 'Draft',
}

export type ComponentType = 'produce' | 'ingredient' | 'processing' | 'packaging' | 'retail'

export type GRADES = 'A+' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G'

export enum SA_TYPE {
    ComplexIngredient = 'ComplexIngredient',
    Produce = 'Produce',
    Product = 'Product',
    SimpleIngredient = 'SimpleIngredient',
    SpecialIngredient = 'SpecialIngredient',
}

export interface IIngredientMetadata {
    companyId: string
    dataPlatformRefId?: string
    description: string
    ecoImpacts: IEcoImpact[]
    ingredientId: string
    ingredientName: string
    inputQuantity: number
    inputQuantityPercentage: number
    isDPSpecialIngredient: boolean
    isIngredientArchived: boolean
    isSpecialIngredient: boolean
    productId: string
}

export type ProductMetadata = {
    ingredientStatus: string
    ingredients: IIngredientMetadata[]
    packaging: string
    processing: string
    storage: string
    retail: string
    sale: string
}

export type HistoryItem = {
    dateTime: string
    newValue: CertificateStateEnum
    reviewer: string
    comments: string
}

export type Certificate = {
    bannerImageUrl: string
    biodiversityGrade?: string
    carbonGrade?: string
    certificationEndDate?: string
    certificationStartDate?: string
    companyId: string
    dataQualityScore: {
        score: number
        attributes: IDataQualityScoreAttributes[]
    }
    ecoImpactMetascore?: GRADES | 'none' | 'requested' | 'error'
    ecoImpacts?: any
    ecoImpactTableDescription?: string
    id: string
    isPrivateCertificate: boolean
    isProductArchived: boolean
    isSideLoaded: boolean
    measureUnit: string
    metascoreGrades: string
    netWeight: number
    noGrade: boolean
    productDescription: string
    productId: string
    productName: string
    productMetadata: ProductMetadata
    saState: CertificateStateEnum
    saType: SA_TYPE
    saStateHistory?: HistoryItem[]
    servingSize: number
    servingSizeLabel: string
    sku: string
    updatedAt: string
    waterPollutionGrade?: string
    waterUsageGrade?: string
}

export interface IClassNameProps {
    /**
     * Defines the appearance or layout of the element
     */
    className?: string
}

export interface ICertCardProps {
    cert: Certificate
}

export interface IRegion {
    countryId: number
    gadM_Country: string
    gadM_FullName: string
    gadM_ID: string
    gadM_Level: number
    gadM_Name: string
    id: string
    isO_31662Code: string
    latitude: number
    longitude: number
    misc: number
    name: string
    label: string
    value: any
}

export interface ICountry {
    continent: string
    countryName: string
    id: number
    isArchived: boolean
    isoAlpha2: string
    isoAlpha3: string
    isoContinentCode: number
    isoIntermediateContinent: string
    isoIntermediateContinentCode: number
    isoSubContinent: string
    isoSubContinentCode: number
    label: string
    value: any
}

export interface IAddressMapApi {
    streetNumber?: string
    streetName?: string
    municipalitySubdivision?: string
    municipality?: string
    countrySecondarySubdivision?: string
    countrySubdivision?: string
    countrySubdivisionName?: string
    postalCode?: string
    extendedPostalCode?: string
    countryCode?: string
    country?: string
    countryCodeISO3?: string
    freeformAddress?: string
    localName?: string
}

export interface IAddress {
    city: string
    country: string
    countryCode: string
    countryCodeISOAlpha2: string
    countryCodeISOAlpha3: string
    countryId?: number
    id?: string
    isArchived: boolean
    isFullStreetAddress: boolean
    latitude: string
    longitude: string
    pinCode: string
    state: string
    streetAddress: string
}

export interface ISite {
    id: string
    name: string
    companyId: string
    address: IAddress
    linkedCount: number
    isArchived: boolean
    siteId: string
}

export interface ICategory {
    companyId: string
    id: string
    isArchived: boolean
    linkedProducts: number
    name: string
    parentId?: string
}

export interface ISupplier {
    address: {
        id: string
        streetAddress: string
        city: string
        state: string
        country: string
        countryCode: string
        countryCodeISOAlpha2: string
        countryCodeISOAlpha3: string
        countryId: number
        isArchived: boolean
        isFullStreetAddress: boolean
        latitude: string
        longitude: string
    }
    avatarColor: string
    avatarTextColor: string
    companyId: string
    companyName: string
    companyNumber: string
    description: string
    createdBy: string
    createdDate: string
    displayId: string
    isArchived: boolean
    productsCount: number
    legalName: string
    logoUrl?: string
    name: string
    supplierId: string
    supplierState: string
    updatedBy: string
    updatedDate: string
}

export interface IProduct {
    activities?: IStageActivity[]
    assessmentDescription?: string
    bannerImageUrl: string
    batchInputWeight: number
    categoryId: string
    certificates: Certificate[]
    certificationEndDate: string
    certificationStartDate: string
    certifiedProduceCount: number
    certifiedSupplierCount: number
    companyCategoryId?: string
    companyCategoryName?: string
    companyId: string
    companyName: string
    createdBy?: string
    createdDate?: string
    currentCertificateId: string
    description: string
    ecoImpactDetails: IEcoImpact[]
    ecoImpactMetascore?: GRADES | 'none' | 'requested' | 'error'
    ecoImpacts: IEcoImpact[]
    emissionFactorCode: string
    emissionFactorCountryCode: string
    gradesExcelFile: string //Grade Spreadsheet URL
    id: string
    imageUrl: string
    ingredientsRequired: boolean
    inputQuantityPercentage: number
    isArchived: boolean
    isComplete: boolean
    isLCA: boolean
    isLCASideloaded: boolean
    isPrivateCertificate: boolean
    isProductLocked: boolean
    isReadyForCertification: boolean
    isSupplierArchived: boolean
    ltmPurchaseQuantity: number
    ltmSalesQuantity: number
    measureUnit: string
    name: string
    netWeight: number
    originCountryCode: string
    packagingRequired: boolean
    processingRequired: boolean
    produceCategoryCode: string
    produceCount: number
    productAwards: any
    productBatchInputType: string
    productionInPercent: number
    productMetadata: ProductMetadata
    productMetadataStats: any[]
    productUrl: string
    retailRequired: boolean
    saleRequired: boolean
    saState: CertificateStateEnum
    saType: SA_TYPE
    servingSize: number
    servingSizeLabel: string
    site: ISite
    sku: string
    storageRequired: boolean
    supplierCount: number
    supplierId: string
    supplierName: string
    taxonomyCode: string
    transferredCompanyId: string
    transferredProductId: string
    techSystem: string
    updatedBy: string
    updatedDate: string
}

export interface IIngredientCollectionMetadata {
    inputQuantityPercentage: number
    isLinkedSupplier?: boolean
    productId: string
    productName?: string
    supplierId?: string
    supplierName?: string
}

export interface IIngredientSuppliedProduct {
    certificationEndDate: string
    companyId: string
    companyName: string
    description: string
    ecoImpactMetascore?: GRADES | 'none' | 'requested' | 'error'
    ecoImpacts: any
    id: string
    inputQuantityPercentage: number
    isArchived: boolean
    isSupplied: boolean
    isSupplierArchived: boolean
    name: string
    saState: CertificateStateEnum
    saType: SA_TYPE
    supplierId: string
    supplierName: string
    updatedDate: string
}

export interface IIngredientCollection {
    certificateId?: string
    companyId: string
    companyName: string
    description: string
    ecoImpacts: IEcoImpact[]
    id: string
    ingredientCollectionMetadata: IIngredientCollectionMetadata[]
    isArchived: boolean
    isLCA?: boolean
    isSpecialIngredient: boolean
    isWater: boolean
    knownAs?: string
    label?: string
    name: string
    saState: CertificateStateEnum
    saType: SA_TYPE
    suppliedProducts: IIngredientSuppliedProduct[]
    suppliersCount: number
}

export interface IIngredientLookup {
    id: string
    isArchived: boolean
    isWater: boolean
    label?: string
    name: string
    saType: SA_TYPE
}

export interface INodeProps {
    name: string
    avatarTextColor: string
    avatarColor: string
    id: string
    link: string
    isProduce: boolean
    supplierName: string
    supplierId: string
    supplierCompany: string
    children?: INodeProps[]
    ecoImpacts: []
    saState?: CertificateStateEnum
}

export type Ownership = [string?, string?]

export enum NOTE_TYPE {
    NOTE = 'Note',
    HOTSPOT = 'Hotspot',
    INSIGHT = 'Insight',
}

export enum NodeType {
    AUDIT = 'audit',
    INGREDIENT = 'ingredient',
    INGREDIENT_TABLE = 'ingredientTable',
    NOTES = 'notes',
    PACKAGING = 'packaging',
    PROCESSING = 'processing',
    PRODUCE = 'produce',
    PRODUCT = 'product',
    RECIPE = 'recipe',
    SALE = 'sale',
    SITE = 'site',
    SITE_RESOURCE = 'siteResource',
    STORAGE = 'storage',
    SUPPLIED_PRODUCT = 'suppliedProduct',
    SUPPLIER = 'supplier',
    TRANSPORT = 'transport',
    SALES_TRANSPORT = 'salesTransport',
    SIMPLE_INGREDIENT = 'simpleingredient',
    COMPLEX_INGREDIENT = 'complexingredient',
    SPECIAL_INGREDIENT = 'specialingredient',
    NOTE = 'Note',
    INSIGHT = 'Insight',
    HOTSPOT = 'Hotspot',
}

export type NodeState =
    | 'blank'
    | 'progress'
    | 'review'
    | 'certified'
    | 'approved'
    | 'none'
    | 'suppliers'
    | 'produce'

export type CategoryType = SA_TYPE | 'FarmlessIngredients' | 'ProcessedIngredients'

export type Placement =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'

export interface IUser {
    id?: string
    displayName: string
    email: string
    companyId?: string
    surname?: string
    givenName: string
    accountEnabled: boolean
    role?: string
    allowNotification: boolean
}

export enum ProductDetailTypes {
    CERTIFICATE_CURRENT = 'CERTIFICATE_CURRENT',
    CERTIFICATES = 'CERTIFICATES',
    ECOIMPACTDETAILS = 'ECOIMPACTDETAILS',
    ECOIMPACTS = 'ECOIMPACTS',
    ITEMCOUNTS = 'ITEMCOUNTS',
    METADATA = 'METADATA',
}
export enum EntityTypeEnum {
    CATEGORY = 'categoryType',
    INGREDIENT = 'ingredientCollectionType',
    PRODUCT = 'productType',
    SITE = 'siteType',
    SUPPLIER = 'supplierType',
}
export interface IProductFilters {
    archived?: boolean
    direction?: string
    ecoImpactMetascore?: string
    entityType?: EntityTypeEnum
    filter?: string
    name?: string
    Order?: string
    saState?: string
    types?: string[]
}

export interface ILookupItem {
    id: string
    name: string
    [x: string]: any
}

export enum LookupTypeEnum {
    INGREDIENTS = 'INGREDIENTS',
    PRODUCTS = 'PRODUCTS',
    SUPPLIED_PRODUCTS = 'SUPPLIED_PRODUCTS',
    COMPANY_CATEGORIES = 'COMPANY_CATEGORIES',
    SPECIAL_INGREDIENT = 'SPECIAL_INGREDIENT',
}

export type INote = INoteLib<{
    productId: string
    severity: Priority
    stage: string
    userEmail: string
    threadUpdatedDate: string
    title?: string
}>

export interface INoteItem extends INote {
    isThreadRead?: boolean
    productName: string
    replies: INoteItem[]
    stage: string
}

export interface ITaxonomyCategory {
    code: string
    id?: string | number
    name: string
}

export interface ITechnologySystem {
    label: string
    value: string
}
