import React, { useMemo } from 'react'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import { Autocomplete } from '@mondra/ui-components'
import { CategoryType } from '../types/types'
import { DOWNSHIT_ITEM_SELECT_TYPE, URLs } from '../constants'
import useFetch from '../hooks/useFetch'
import getURL from '../utils/getURL'


interface ICategoryAutocomplete {
    autoFetch: boolean
    categoryCode?: string
    companyId: string
    disabled?: boolean
    helpText?: string
    invalid?: boolean
    labelText?: string
    onSelect: (selectedItem?: any) => void
    placeholder?: string
    queryString?: string
    type: CategoryType
}

export function CategoryAutocomplete({
    autoFetch,
    categoryCode,
    companyId,
    disabled,
    helpText = 'e.g. Winter Weat',
    labelText = 'Category Name',
    placeholder = 'Search with a category name',
    queryString,
    onSelect,
    type,
    ...rest
}: ICategoryAutocomplete) {
    const { data, loading } = useFetch({
        autoFetch: autoFetch && !isEmpty(type),
        defaultRes: [],
        errMessage: 'Failed to fetch Ingredient categories',
        url: getURL(URLs.Category.All, { companyId, type }, queryString),
    })

    function handleSelected(changes: any) {
        const { selectedItem } = changes
        onSelect({ ...selectedItem })
    }

    const categoryOptions = useMemo(() => {
        return map(
            (cat: any) => ({
                ...cat,
                label: cat.name,
            }),
            data
        )
    }, [data])

    const selected = useMemo(() => {
        return find({ code: categoryCode }, categoryOptions) || null
    }, [categoryCode, categoryOptions])

    function handleStateChange(state: any) {
        const { type, selectedItem } = state
        if (type === DOWNSHIT_ITEM_SELECT_TYPE) {
            onSelect(selectedItem || selected)
        }
    }

    return (
        <Autocomplete
            containerClass="grid grid-cols-2 gap-x-4"
            disabled={disabled || loading}
            placeholder={loading ? 'Loading categories...' : placeholder}
            labelText={labelText}
            labelProps={{
                containerClass: 'col-span-1',
                helpText,
            }}
            onStateChange={handleStateChange}
            onChange={handleSelected}
            options={categoryOptions}
            selected={selected}
            {...rest}
        />
    )
}
