import React, { useEffect, useMemo, useState } from 'react'
import { Button, Icon, RadioGroup } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import reduce from 'lodash/fp/reduce'
import { evaluateInputValue, setValidationError } from '../../utils'
import { mapWithIndex } from '../../utils/lodash-convert'
import { formatNumber } from '../../utils/numberFormatter'
import getURL from '../../utils/getURL'
import { TYPE_DEFAULT, TYPE_OTHER, URLs, PERCENT, PERCENT_SYMB, MAX_DEC } from '../../constants'
import useFetch from '../../hooks/useFetch'
import { IResourceSource, ISiteResource } from '../../types/stages'
import { ICustomEventProps } from '../../types/types'
import { Row } from '../../components'
import ResourceRow from './ResourceRow'


interface IResourcesListProps {
    companyId: string
    countryCode?: string
    disabled?: boolean
    groupId: string
    error: any
    setError: React.Dispatch<React.SetStateAction<any>>
    onAddSource: (groupId: string, source: ISiteResource) => void
    onChange: (groupId: string, index: number, change: any) => void
    onRemoveSource: (groupId: string, index: number) => void
    onUnitChange: (groupId: string, isUsageInPercent: boolean) => void
    resources: ISiteResource[]
}

export default function ResourcesList({
    companyId,
    countryCode,
    disabled,
    error,
    groupId,
    onAddSource,
    onChange,
    onRemoveSource,
    onUnitChange,
    resources,
    setError,
}: IResourcesListProps) {
    const [resourceName, setResourceName] = useState<string>('')
    const [resourceSources, setResourceSources] = useState<IResourceSource[]>([])

    const defaultResource = useMemo(() => {
        return find(['siteResourceSourceUsageType', TYPE_DEFAULT], resources)
    }, [resources])

    const totalUsage = useMemo(() => {
        return reduce(
            (acc: number, i: ISiteResource) => {
                if (i.isUsageInPercent) {
                    return acc + Number(i.usageInPercent || 0)
                }
                return acc + Number(i.usage || 0)
            },
            0,
            resources
        )
    }, [resources])

    const resourceCode = defaultResource?.dataPlatformResourceCode || ''
    const defaultUnit = defaultResource?.unit.trim() || ''
    const unitSymbol = defaultResource?.isUsageInPercent ? PERCENT_SYMB : defaultUnit

    const unitOptions = useMemo(() => {
        return [
            {
                description: '',
                label: defaultUnit,
                value: defaultUnit,
            },
            {
                description: '',
                label: PERCENT_SYMB,
                value: PERCENT,
            },
        ]
    }, [defaultUnit])

    const { data: mainResources, loading } = useFetch<IResourceSource[]>({
        autoFetch: !isEmpty(resources) && !isEmpty(resourceCode),
        defaultRes: [],
        url: getURL(
            URLs.SiteResource.RESOURCES_WITH_NON_DEFUALT,
            { companyId, id: resourceCode },
            `?location=${countryCode}`
        ),
    })

    useEffect(() => {
        if (!isEmpty(mainResources)) {
            setResourceName(mainResources[0].resourceName)
            setResourceSources(
                mainResources.map(i => ({
                    ...i,
                    label: `${i.resourceSourceName} (${i.location})`,
                    value: i.code,
                }))
            )
        }
    }, [mainResources])

    const handleAddSource = () => {
        onAddSource(groupId, {
            dataPlatformResourceCode: resourceCode,
            dataPlatformResourceSourceCode: '',
            id: '',
            isUsageInPercent: defaultResource?.isUsageInPercent || false,
            resourceSourceRefCode: '',
            siteId: defaultResource?.siteId || '',
            siteResourceSourceUsageType: TYPE_OTHER,
            unit: defaultUnit,
            usage: 0,
            usageInPercent: 0,
        })
    }

    const handleRemove = (index: number) => () => {
        onRemoveSource(groupId, index)
    }

    const handleResourceChange = (index: number) => (selected?: IResourceSource) => {
        const code = selected?.code
        console.log('handle resource change')
        onChange(groupId, index, {
            dataPlatformResourceSourceCode: code,
            location: selected?.location,
            resourceSourceRefCode: selected?.resourceSourceRefCode,
        })

        setValidationError(code || '', `${groupId}-selectedResource${index}`, error, setError)
    }

    const handleUsageChange = (index: number) => (event: ICustomEventProps) => {
        const value = evaluateInputValue(event, MAX_DEC)
        if (defaultResource?.isUsageInPercent) {
            onChange(groupId, index, {
                usageInPercent: value,
            })
        } else {
            onChange(groupId, index, {
                usage: value,
            })
        }

        setValidationError(value, `${groupId}-siteUsage${index}`, error, setError)
    }

    const handleUnitChange = (value: any) => {
        onUnitChange(groupId, value === PERCENT)
    }

    return (
        <React.Fragment>
            <Row className="grid-cols-12 bg-gray-100 py-1">
                <div className="flex flex-row items-center gap-x-2 col-span-9">
                    <Icon type="resources" size={20} className="text-primary-500" />
                    <div className="text-sm text-gray-700 font-medium">
                        {loading ? 'Loading...' : resourceName}
                    </div>
                </div>
                <div className="col-span-3 flex flex-row items-center justify-between">
                    <div className="text-sm text-gray-700 font-medium">Site Usage</div>
                    <RadioGroup
                        optionContentClassName="!px-2 !py-1"
                        options={unitOptions}
                        value={defaultResource?.isUsageInPercent ? PERCENT : defaultUnit}
                        onChange={handleUnitChange}
                    />
                </div>
            </Row>

            {loading && <Row>Loading...</Row>}

            {!loading
                && mapWithIndex(
                    (resource: ISiteResource, i: number) => (
                        <ResourceRow
                            key={i}
                            disabled={disabled}
                            resource={resource}
                            isValidResource={
                                !error?.[`${groupId}-selectedResource${i}`]
                                && !isEmpty(resource.dataPlatformResourceSourceCode)
                            }
                            isValidUsage={
                                !(defaultResource?.isUsageInPercent && totalUsage !== 100)
                                && !error?.[`${groupId}-siteUsage${i}`]
                            }
                            unit={unitSymbol}
                            resourceSources={resourceSources}
                            handleResourceChange={handleResourceChange(i)}
                            handleUsageChange={handleUsageChange(i)}
                            handleRemove={handleRemove(i)}
                        />
                    ),
                    resources
                )}

            <Row className="bg-gray-100 flex-row justify-end pt-3 pb-3">
                <div className="flex flex-row gap-x-2">
                    <div className="font-medium text-gray-700 font-sm">Total site usage:</div>
                    <div className="font-bold text-gray-900 font-sm">
                        {formatNumber(totalUsage)} {unitSymbol}
                    </div>
                    {defaultResource?.isUsageInPercent && totalUsage !== 100 && (
                        <Icon
                            type="warningFilled"
                            className="text-red-600"
                            size={20}
                            title="Total site usage should be 100%"
                        />
                    )}
                </div>
            </Row>

            <Row className="justify-end mb-4">
                <Button variant="secondary" iconType="add" onClick={handleAddSource}>
                    Add source
                </Button>
            </Row>
        </React.Fragment>
    )
}
