import flow from 'lodash/fp/flow'
import head from 'lodash/fp/head'
import intersection from 'lodash/fp/intersection'
import map from 'lodash/fp/map'
import min from 'lodash/fp/min'
import isEmpty from 'lodash/fp/isEmpty'
import { GRADES } from '../constants'
import { IIngredientSuppliedProduct, CertificateStateEnum } from '../types/types'


const grades = GRADES.slice().reverse()
grades.unshift('')

const intersect = (arr1: any[]) => (arr2: any[]) => intersection(arr2, arr1)

const getStateGroup = (state: string) => {
    if (isEmpty(state)) {
        return CertificateStateEnum.Draft
    }
    return state
}

/**
 * Priority: 1- Draft, 2-CertificationExpired, 3-Certified
 * @param states Collection of certificate states
 * @returns Certificate State on the priority basis.
 */
const getLowestState = (states: string[]) => {
    if (isEmpty(states)) {
        return CertificateStateEnum.Draft
    }
    if (states.indexOf(CertificateStateEnum.Draft) > -1) {
        return CertificateStateEnum.Draft
    }
    if (states.indexOf(CertificateStateEnum.CertificationExpired) > -1) {
        return CertificateStateEnum.CertificationExpired
    }
    return CertificateStateEnum.Certified
}

/**
 * Get Certificate Expiry from the collection
 * @param impactCollection Collection of impact rows
 * @returns Nearest expiry date (Date object)
 */
export const getCertificateExpiry = flow(
    map('certificationEndDate'),
    map(r => new Date(r)),
    min
)

/**
 * Get Impact Score from the collection
 * @param impactCollection Collection of impact rows
 * @returns Lowest impact grade
 */
export const getImpactScore = flow(map('ecoImpactMetascore'), intersect(grades), head)

/**
 * Get Certificate Status from the collection
 * @param impactCollection Collection of impact rows
 * @returns Certificate Status on the priority basis.
 */
export const getImpactState = flow(map('saState'), map(getStateGroup), getLowestState)

export default (impactCollection: IIngredientSuppliedProduct[]) => {
    return {
        expiry: getCertificateExpiry(impactCollection),
        score: getImpactScore(impactCollection),
        state: getImpactState(impactCollection),
    }
}
