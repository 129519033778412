import find from 'lodash/fp/find'
import isNumber from 'lodash/fp/isNumber'
import isString from 'lodash/fp/isString'
import reduce from 'lodash/fp/reduce'
import { Decimal } from 'decimal.js'

import {
    IEcoImpactItem,
    EcoImpactIds,
    IEcoImpactIdType,
    IEcoImpact,
    IEcoImpactMeasureTypes,
} from '../types/ecoImpacts'
import { defaultImpacts, ecoImpactMeasures } from '../constants/ecoImpactMeasures'


export function getImpactValue(ecoImpacts: IEcoImpactItem[], key: string) {
    const impact = find({ id: key }, ecoImpacts)
    return numberFormatterWithPrecision((isNumber(impact?.value) && impact?.value) || 0)
}

/**
 * Decimals do not contain trailing fraction-digit zeros, and toSignificantsDigits returns a Decimal,
 * so the answer to your question is: It can't be done. so need to use Precision method
 * issue#https://github.com/MikeMcl/decimal.js/issues/170
 * @param number
 * @returns string with 2 significant digits as default
 */
export const numberFormatterWithPrecision = (number: any, significantDigits = 2) => {
    if (isString(number) && number === '-') {
        return '-'
    }
    if (number === 0) {
        return number
    }
    Decimal.set({ precision: 5, rounding: 4 })
    const x = new Decimal(number)
    const numWord = String(number)
    const numBeforeDec = Number.isInteger(number) ? numWord : numWord.split('.')[0]
    if (numBeforeDec && numBeforeDec.length > 2) {
        return x.toSignificantDigits(significantDigits).toString()
    }
    return x.toPrecision(significantDigits)
}

export function getMeasureUnitLabel(impactId: IEcoImpactIdType) {
    const impactLabels = ecoImpactMeasures[impactId]
    return impactLabels.unit ? `${impactLabels.unit} ${impactLabels.measure}` : impactLabels.measure
}

export function getMeasureUnitLabels() {
    const carbonMeasure = getMeasureUnitLabel(EcoImpactIds.EIID001)
    const waterMeasure = getMeasureUnitLabel(EcoImpactIds.EIID002)
    const eutroMeasure = getMeasureUnitLabel(EcoImpactIds.EIID003)
    const bioMeasure = getMeasureUnitLabel(EcoImpactIds.EIID004)
    return {
        bioMeasure,
        carbonMeasure,
        eutroMeasure,
        waterMeasure,
    }
}

export function getImpactDetails(impact: any) {
    const carbonMeasure = find(imp => imp.id === EcoImpactIds.EIID001, impact)
    const waterMeasure = find(imp => imp.id === EcoImpactIds.EIID002, impact)
    const eutroMeasure = find(imp => imp.id === EcoImpactIds.EIID003, impact)
    const bioMeasure = find(imp => imp.id === EcoImpactIds.EIID004, impact)
    return {
        bio: bioMeasure?.value,
        eutro: eutroMeasure?.value,
        ghg: carbonMeasure?.value,
        water: waterMeasure?.value,
    }
}

export function getResolvedImpactValue(impactValue: string | number) {
    return impactValue === '-' ? 0 : impactValue
}

export function getAggregateSubIngredients(rows: any[], per100: boolean) {
    const convert = per100 ? 10 : 1
    return reduce(
        (acc: any, r: any) => {
            const { ghg, water, eutro, bio } = getImpactDetails(
                per100 ? r.ecoImpactsPer100 : r.ecoImpacts
            )
            const composition = r?.composition / convert
            acc.carbon += +numberFormatterWithPrecision((ghg * composition) / 100)
            acc.water += +numberFormatterWithPrecision((water * composition) / 100)
            acc.eutro += +numberFormatterWithPrecision((eutro * composition) / 100)
            acc.bio += +numberFormatterWithPrecision((bio * composition) / 100)
            return acc
        },
        { ...defaultImpacts },
        rows
    )
}

export function getFormatterCompanyImpacts(impacts: any) {
    const { carbon, water, eutro, bio } = impacts
    return [
        {
            subTitle: 'g CO₂ eq.',
            title: 'Carbon',
            trend: 'none',
            value: carbon,
        },
        {
            subTitle: 'L eq.',
            title: 'Water Usage',
            trend: 'none',
            value: water,
        },
        {
            subTitle: 'g PO₄³⁻ eq.',
            title: 'Water Pollution',
            trend: 'none',
            value: eutro,
        },
        {
            subTitle: 'Species Loss Index (SLI)',
            title: 'Biodiversity',
            trend: 'none',
            value: bio,
        },
    ]
}

interface IBuildImpactParams {
    bio?: IEcoImpactMeasureTypes
    eutro?: IEcoImpactMeasureTypes
    ghg?: IEcoImpactMeasureTypes
    water?: IEcoImpactMeasureTypes
}

export function buildImpact(impacts?: IBuildImpactParams): IEcoImpact[] {
    const { bio = {}, eutro = {}, ghg = {}, water = {} } = impacts || {}
    return [
        {
            code: EcoImpactIds.EIID001,
            impactScore: ghg.perKg || 0,
            measureUnit: 'g',
            name: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
            scientificTerm: 'Greenhouse Gasses (CO<sub>2</sub> eq)',
            ...ghg,
        },
        {
            code: EcoImpactIds.EIID002,
            impactScore: water.perKg || 0,
            measureUnit: 'L',
            name: 'Water Scarcity (eq)',
            scientificTerm: 'Water Scarcity (eq)',
            ...water,
        },
        {
            code: EcoImpactIds.EIID003,
            impactScore: eutro.perKg || 0,
            measureUnit: 'g',
            name: 'Eutrophication (PO43-)',
            scientificTerm: 'Eutrophication (PO43-)',
            ...eutro,
        },
        {
            code: EcoImpactIds.EIID004,
            impactScore: bio.perKg || 0,
            measureUnit: '',
            name: 'Biodiversity (sp.yr10<sup>14</sup>)',
            scientificTerm: 'Biodiversity (sp.yr10<sup>14</sup>)',
            ...bio,
        },
    ]
}
