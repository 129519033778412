import React, { ReactNode, useState, useRef, useMemo } from 'react'
import { Avatar, Icon } from '@mondra/ui-components'
import getOr from 'lodash/fp/getOr'
import upperFirst from 'lodash/fp/upperFirst'
import classNames from 'classnames'
import formatDate from 'date-fns/format'
import { toDate } from '../../utils/dateUtils'
import { getDefaultColor } from '../../utils/colorUtils'


interface IDetails {
    email: string
    name: string
    updated: string
}

interface IChangeLog {
    children?: ReactNode
    className?: string
    details: IDetails
}

function ChangeLog({ children, details }: IChangeLog) {
    const [isOpened, setOpened] = useState<boolean>()
    const [height, setHeight] = useState<string>('0px')
    const contentElement = useRef(null)
    const { email, name, updated } = details

    const handleOpen = () => {
        setOpened(!isOpened)
        setHeight(!isOpened ? `${getOr(0, 'current.scrollHeight', contentElement)}px` : '0px')
    }

    const avatarColor = useMemo(() => {
        return getDefaultColor()
    }, [])

    return (
        <div>
            <div
                role="button"
                onClick={handleOpen}
                className={classNames(
                    'flex items-center justify-between cursor-pointer',
                    'px-6 w-full',
                    'text-sm font-medium text-gray-800',
                    'border-b border-gray-300'
                )}
            >
                <div className="flex items-center justify-start overflow-auto">
                    <div>
                        <Avatar
                            name={upperFirst(name || email)}
                            singleChar
                            bgColor={avatarColor.color}
                            textColor={avatarColor.text}
                        />
                    </div>
                    <div className="flex flex-col items-start justify-start m-4">
                        <span className="text-gray-900 text-sm font-bold">
                            {upperFirst(name || email)}
                        </span>
                        <span className="text-gray-400 text-xs font-normal leading-3">
                            {updated ? formatDate(toDate(updated as any), 'd/MM/yyyy, HH:mm') : '-'}
                        </span>
                    </div>
                </div>
                <Icon
                    type="chevronDown"
                    size={16}
                    className={classNames(
                        'motion-safe:transition-all',
                        isOpened && 'transform rotate-180'
                    )}
                />
            </div>
            <div
                ref={contentElement}
                style={{ height: height }}
                className="text-gray-800 text-xs overflow-hidden transition-all duration-500"
            >
                {children}
            </div>
        </div>
    )
}

export default ChangeLog
