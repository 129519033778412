import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import Tooltip from '../../../components/LightTooltip'
import { DataQualityScore } from '../../../widgets/data-quality-score/DataQualityScore'
import { DataQualityAttributes } from '../../../widgets/data-quality-score/DataQualityAttributes'


export function DataQualityScoreCell({ data }: ICellRendererParams) {
    return (
        <Tooltip
            interactive
            enterDelay={500}
            enterNextDelay={500}
            leaveDelay={200}
            disableFocusListener
            title={
                <div className="bg-white rounded-sm px-4 shadow-lg min-w-[230px]">
                    <DataQualityAttributes attributes={data.dataQualityScore?.attributes} />
                </div>
            }
        >
            <div className="cursor-pointer">
                <DataQualityScore small value={data.dataQualityScore?.score} />
            </div>
        </Tooltip>
    )
}
